import React from "react";
import ShareholderListBanner from "../ShareholderListBanner";
import ShareholderListTable from "../ShareholderListTable";
import useTitle from "../../../hooks/useTitle";

function ShareholderList() {
  useTitle("Shareholders List");

  return (
    <>
      <ShareholderListBanner />
      <ShareholderListTable />
    </>
  );
}

export default ShareholderList;
