import { FiEye } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import React, { useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { CiCircleRemove } from 'react-icons/ci';
import { IoIosAddCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function DashboardPartnerChannels() {
    const [channelName, setChannelName] = useState('');
    const [channelNameBn, setChannelNameBn] = useState('');
    const [partnerDescription, setPartnerDescription] = useState('');
    const [partnerDescriptionBn, setPartnerDescriptionBn] = useState('');
    const [policyLifeDesc, setPolicyLifeDesc] = useState('');
    const [policyLifeDescBn, setPolicyLifeDescBn] = useState('');
    const [policyHealthDesc, setPolicyHealthDesc] = useState('');
    const [policyHealthDescBn, setPolicyHealthDescBn] = useState('');
    const [ourPolicyTitle, setOurPolicyTitle] = useState('');
    const [ourPolicyTitleBn, setOurPolicyTitleBn] = useState('');
    const [policyOurPolicyDesc, setPolicyOurPolicyDesc] = useState('');
    const [policyOurPolicyDescBn, setPolicyOurPolicyDescBn] = useState('');
    const [purchaseTitle, setPurchaseTitle] = useState('');
    const [purchaseTitleBn, setPurchaseTitleBn] = useState('');
    const [purchaseDescription, setPurchaseDescription] = useState('');
    const [purchaseDescriptionBn, setPurchaseDescriptionBn] = useState('');
    const [productVdLink, setProductVdLink] = useState(['']);
    const [logo, setLogo] = useState(null);
    const [policyImg, setPolicyImg] = useState(null);
    const [purchaseImage, setPurchaseImage] = useState(null);
    const [lifeTitle, setLifeTitle] = useState(false);
    const [lifeTitleBn, setLifeTitleBn] = useState(false);
    const [healthTitle, setHealthTitle] = useState(false);
    const [healthTitleBn, setHealthTitleBn] = useState(false);

    const navigate = useNavigate();

    const editor = useRef(null);

    const handleLogo = (e) => {
        setLogo(e.target.files[0]);
    };

    const handleOurPolicyImg = (e) => {
        setPolicyImg(e.target.files[0]);
    };

    const handlePurchaseImg = (e) => {
        setPurchaseImage(e.target.files[0]);
    };

    // // Product Video
    const handleServiceChange = (e, index) => {
        const { value } = e.target;
        const list = [...productVdLink];
        list[index] = value;
        setProductVdLink(list);
    };

    const handleServiceRemove = (index) => {
        const list = [...productVdLink];
        list.splice(index, 1);
        setProductVdLink(list);
    };

    const handleServiceAdd = () => {
        setProductVdLink([...productVdLink, { productVdLink: '' }]);
    };

    const handelAddPartnerChannels = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append('partnerName', channelName);
            formData.append('partnerNameBn', channelNameBn);
            formData.append('partnerDesc', partnerDescription);
            formData.append('partnerDescBn', partnerDescriptionBn);
            formData.append('lifeTitle', lifeTitle ? 'Life Insurance' : '');
            formData.append(
                'lifeTitleBn',
                lifeTitleBn ? 'জীবন বীমা প্ল্যান' : ''
            );
            formData.append(
                'healthTitle',
                healthTitle ? 'Health Insurance' : ''
            );
            formData.append(
                'healthTitleBn',
                healthTitleBn ? 'স্বাস্থ্য বীমা প্ল্যান' : ''
            );
            formData.append('lifeDesc', policyLifeDesc);
            formData.append('lifeDescBn', policyLifeDescBn);
            formData.append('healthDesc', policyHealthDesc);
            formData.append('healthDescBn', policyHealthDescBn);
            formData.append('ourPolicyTitle', ourPolicyTitle);
            formData.append('ourPolicyTitleBn', ourPolicyTitleBn);
            formData.append('ourPolicyDesc', policyOurPolicyDesc);
            formData.append('ourPolicyDescBn', policyOurPolicyDescBn);
            formData.append('purchaseTitle', purchaseTitle);
            formData.append('purchaseTitleBn', purchaseTitleBn);
            formData.append('purchaseDesc', purchaseDescription);
            formData.append('purchaseDescBn', purchaseDescriptionBn);

            [...productVdLink].forEach((feature) => {
                formData.append('vedioLink', feature);
            });

            formData.append('image', logo);
            formData.append('image', policyImg);
            formData.append('image', purchaseImage);

            for (const value of formData.values()) {
                console.log(value);
            }

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };
            await axios
                .post(
                    `${process.env.REACT_APP_host}/api/v1/adc`,
                    formData,
                    config
                )
                .then((res) => {
                    if (res.status) {
                        toast.success('Partner Channels Created successfully');
                        navigate(
                            '/dashboard/partner-channel/partner-channel-view'
                        );
                        console.log(res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.error('Failed to Create Partner Channels');
                });
        } catch (err) {
            console.error(err);
            toast.error('Failed to Create Partner Channels');
        }
    };

    return (
        <div className="home-content">
            <div className="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/partner-channel/partner-channel-view"
                    className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Details </span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center mb-4">
                    ADC/Partner Channels
                </h1>
                {/* <DashboardPartnerChannelsForm btnTitle="Save" /> */}
                <>
                    <form onSubmit={handelAddPartnerChannels}>
                        {/* Partner Channel Name English  */}
                        <div className="mb-3">
                            <label className="form-label  fw-bold">
                                Partner Channel Name{' '}
                                <span className="text-danger">*</span>{' '}
                            </label>
                            <input
                                placeholder="Type Partner channel name"
                                type="text"
                                value={channelName}
                                onChange={(e) => setChannelName(e.target.value)}
                                name="area"
                                className="form-control"
                                required
                            />
                        </div>
                        {/*Partner Channel Name Bangla  */}
                        <div className="mb-3">
                            <label className="form-label  fw-bold">
                                Partner Channel Name(Bangla)
                                <span className="text-danger">*</span>{' '}
                            </label>
                            <input
                                placeholder="Type Partner channel name"
                                type="text"
                                value={channelNameBn}
                                onChange={(e) =>
                                    setChannelNameBn(e.target.value)
                                }
                                name="area"
                                className="form-control"
                                required
                            />
                        </div>

                        {/* Partner Channel Logo */}
                        <div className="mb-3">
                            <label className="form-label fw-bold">
                                Partner Channel Logo{' '}
                                <span className="text-danger">*</span>{' '}
                            </label>
                            <input
                                type="file"
                                accept="image/*"
                                name="image"
                                onChange={handleLogo}
                                className="form-control"
                                required
                            />
                        </div>

                        {/* Description English  */}
                        <div className="mb-3">
                            <label className="form-label fw-bold">
                                Description
                            </label>
                            <JoditEditor
                                ref={editor}
                                value={partnerDescription}
                                onChange={(newContent) => {
                                    setPartnerDescription(newContent);
                                }}
                            />
                        </div>
                        {/*Description: Bangla  */}
                        <div className="mb-3">
                            <label className="form-label fw-bold">
                                Description (Bangla)
                            </label>
                            <JoditEditor
                                ref={editor}
                                value={partnerDescriptionBn}
                                onChange={(newContent) => {
                                    setPartnerDescriptionBn(newContent);
                                }}
                            />
                        </div>
                        {/* Our Insurance Policies */}
                        <div>
                            <label className="form-label fw-bold">
                                Our Insurance Policies
                            </label>
                            <div className="">
                                <div className="p-3 mb-4 shadow rounded">
                                    <div className="mb-3">
                                        <label className="form-label  fw-bold">
                                            Policy Name
                                        </label>
                                        <br />
                                        <input
                                            className="me-2"
                                            type="checkbox"
                                            name="lifeTitle"
                                            id=""
                                            checked={lifeTitle}
                                            onChange={() =>
                                                setLifeTitle(!lifeTitle)
                                            }
                                        />
                                        <label>Life Insurance</label>
                                    </div>
                                    <div className="my-3">
                                        <label className="form-label fw-bold">
                                            Policy Description
                                        </label>
                                        <JoditEditor
                                            ref={editor}
                                            value={policyLifeDesc}
                                            onChange={(newContent) => {
                                                setPolicyLifeDesc(newContent);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label  fw-bold">
                                            Policy Name(Bangla)
                                        </label>
                                        <br />
                                        <input
                                            className="me-2"
                                            type="checkbox"
                                            name="lifeTitleBn"
                                            id=""
                                            checked={lifeTitleBn}
                                            onChange={() =>
                                                setLifeTitleBn(!lifeTitleBn)
                                            }
                                        />
                                        <label>Life Insurance</label>
                                    </div>
                                    <div className="my-3">
                                        <label className="form-label fw-bold">
                                            Policy Description (Bangla)
                                        </label>
                                        <JoditEditor
                                            ref={editor}
                                            value={policyLifeDescBn}
                                            onChange={(newContent) => {
                                                setPolicyLifeDescBn(newContent);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="p-3 mb-4 shadow rounded">
                                    <div className="mb-3">
                                        <label className="form-label  fw-bold">
                                            Policy Name
                                        </label>
                                        <br />
                                        <input
                                            className="me-2"
                                            type="checkbox"
                                            name="healthTitle"
                                            id=""
                                            checked={healthTitle}
                                            onChange={() =>
                                                setHealthTitle(!healthTitle)
                                            }
                                        />
                                        <label>Health Insurance</label>
                                    </div>
                                    <div className="my-3">
                                        <label className="form-label fw-bold">
                                            Policy Description:
                                        </label>
                                        <JoditEditor
                                            ref={editor}
                                            value={policyHealthDesc}
                                            onChange={(newContent) => {
                                                setPolicyHealthDesc(newContent);
                                            }}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label  fw-bold">
                                            Policy Name(bangla)
                                        </label>
                                        <br />
                                        <input
                                            className="me-2"
                                            type="checkbox"
                                            name="healthTitleBn"
                                            id=""
                                            checked={healthTitleBn}
                                            onChange={() =>
                                                setHealthTitleBn(!healthTitleBn)
                                            }
                                        />
                                        <label>Health Insurance</label>
                                    </div>
                                    <div className="my-3">
                                        <label className="form-label fw-bold">
                                            Policy Description (Bangla)
                                        </label>
                                        <JoditEditor
                                            ref={editor}
                                            value={policyHealthDescBn}
                                            // config={config}
                                            // tabIndex={1} // tabIndex of textarea
                                            onChange={(newContent) => {
                                                setPolicyHealthDescBn(
                                                    newContent
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-3 mb-4 shadow rounded">
                            {/* section-1:  */}

                            <div className="mb-3">
                                <label className="form-label  fw-bold">
                                    Section-1
                                </label>
                            </div>
                            <div className="mb-3">
                                <label className="form-label  fw-bold">
                                    Title
                                </label>
                                <input
                                    placeholder="Type title name"
                                    type="text"
                                    value={ourPolicyTitle}
                                    onChange={(e) =>
                                        setOurPolicyTitle(e.target.value)
                                    }
                                    name="area"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label  fw-bold">
                                    Title (Bangla)
                                </label>
                                <input
                                    placeholder="Type title name bangla"
                                    type="text"
                                    value={ourPolicyTitleBn}
                                    onChange={(e) =>
                                        setOurPolicyTitleBn(e.target.value)
                                    }
                                    name="area"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Description
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={policyOurPolicyDesc}
                                    onChange={(newContent) => {
                                        setPolicyOurPolicyDesc(newContent);
                                    }}
                                />
                            </div>
                            {/* section-1 bangla */}
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Description(Bangla)
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={policyOurPolicyDescBn}
                                    onChange={(newContent) => {
                                        setPolicyOurPolicyDescBn(newContent);
                                    }}
                                />
                            </div>
                            {/* Upload Image:  */}
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Upload Image
                                </label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    name="ourPolicyImg"
                                    onChange={handleOurPolicyImg}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="p-3 mb-4 shadow rounded">
                            {/*section-2:  */}
                            <div className="mb-3">
                                <label className="form-label  fw-bold">
                                    Section-2
                                </label>
                            </div>
                            <div className="mb-3">
                                <label className="form-label  fw-bold">
                                    Title
                                </label>
                                <input
                                    placeholder="Type title name"
                                    type="text"
                                    value={purchaseTitle}
                                    onChange={(e) =>
                                        setPurchaseTitle(e.target.value)
                                    }
                                    name="area"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label  fw-bold">
                                    Title (Bangla)
                                </label>
                                <input
                                    placeholder="Type title name bangla"
                                    type="text"
                                    value={purchaseTitleBn}
                                    onChange={(e) =>
                                        setPurchaseTitleBn(e.target.value)
                                    }
                                    name="area"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Description
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={purchaseDescription}
                                    onChange={(newContent) => {
                                        setPurchaseDescription(newContent);
                                    }}
                                />
                            </div>
                            {/* How To Purchase: Bangal  */}
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Description (Bangla)
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={purchaseDescriptionBn}
                                    onChange={(newContent) => {
                                        setPurchaseDescriptionBn(newContent);
                                    }}
                                />
                            </div>
                            {/* Upload Image:  */}
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Upload Image
                                </label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    name="image"
                                    onChange={handlePurchaseImg}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        {/* Product Video  */}
                        <div className="mb-3">
                            <label className="form-label  fw-bold">
                                Product Video (Embed Link)
                            </label>
                            {productVdLink.map((singleService, index) => (
                                <div
                                    key={index}
                                    className="d-flex align-items-center gap-2 mb-3"
                                >
                                    <div className="d-flex align-items-center gap-2 w-100 ">
                                        <input
                                            placeholder="Link"
                                            name="productVdLink"
                                            type="url"
                                            id="service"
                                            value={singleService?.productVdLink}
                                            className="form-control"
                                            onChange={(e) =>
                                                handleServiceChange(e, index)
                                            }
                                        />
                                        {productVdLink.length - 1 === index &&
                                            productVdLink.length < 2 && (
                                                <button
                                                    type="button"
                                                    onClick={handleServiceAdd}
                                                    className="btn bg-primary text-white"
                                                >
                                                    <span>
                                                        <IoIosAddCircle className="feature-btn" />
                                                    </span>
                                                </button>
                                            )}
                                    </div>
                                    <div className="second-division">
                                        {productVdLink.length !== 1 && (
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    handleServiceRemove(index)
                                                }
                                                className="btn bg-danger text-white"
                                            >
                                                <span>
                                                    <CiCircleRemove className="feature-btn" />
                                                </span>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button type="submit" className="btn btn-primary px-4">
                            save
                        </button>
                    </form>
                </>
            </div>
        </div>
    );
}

export default DashboardPartnerChannels;
