import axios from 'axios';
import React from 'react'
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

function DashboardReviewEditModal({ editModal, handleEditClose, updateReviewVideo, setUpdateReviewVideo, getPaginationList }) {

    const handleEditChange = (e) => {
        setUpdateReviewVideo({ ...updateReviewVideo, [e.target.name]: e.target.value });
    };

    const handleUpdateAction = async (e) => {
        e.preventDefault();
        handleEditClose();

        // const formData = new FormData();
        // formData.append("title", updateReviewVideo.title);
        // formData.append("videoLink", updateReviewVideo.videoLink);

        // for (const value of formData.values()) {
        //     console.log(value);
        // }

        // const config = {
        //     headers: {
        //         "Content-Type": "multipart/form-data",
        //     },
        // };


        const updatedReview = {
            title: updateReviewVideo.title,
            videoLink: updateReviewVideo.videoLink
        }

        // await axios
        //     .patch(
        //         `${process.env.REACT_APP_host}/api/v1/media-review/${updateReviewVideo?._id}`,
        //         formData,
        //         config
        //     )
        //     .then((res) => {
        //         if (res.status) {
        //             getPaginationList();
        //             toast.success("Press updated successfully");
        //         }
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     });


        fetch(
            `${process.env.REACT_APP_host}/api/v1/media-review/${updateReviewVideo?._id}`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedReview),
            }
        )
            .then((res) => res.json())
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    toast.success("Press updated successfully");
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    return (
        <Modal
            show={editModal}
            onHide={handleEditClose}
            scrollable={true}
            //   size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Edit Review Content
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction} className="row">
                    <div className="mb-3 col-12">
                        <label className="form-label fw-bold">Review Title</label>
                        <input
                            type="text"
                            name="title"
                            onChange={handleEditChange}
                            defaultValue={updateReviewVideo?.title}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label  fw-bold">Video (Embedded) Link</label>
                        <input
                            type="text"
                            name="videoLink"
                            onChange={handleEditChange}
                            defaultValue={updateReviewVideo?.videoLink}
                            className="form-control"
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default DashboardReviewEditModal