import React from "react";
import BgBannerImg from "../../assets/BG/bgLeft.png";
import BannerText from "../Home/Banner/BannerText";
import BannerImg from "../../assets/taxrebate/tax_percent.jpeg";
import TaxRebateBannerText from "./TaxRebateBannerText";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useEffect } from "react";

const BgStyle = {
  backgroundImage: `url(${BgBannerImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};

function TaxRebateBanner() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const bannerItems = [
    {
      id: 1,
      title: `${t("Introduction")}`,
      desc: `${t(
        "Life insurance is the only wise option to financially protect your life while still having a worry-free and rewarding investment. If you invest in any of the Guardian Life products, you will receive a maximum income tax rebate of 15% with insurance benefits. At the same time, financial security will be preserved. Invest in any of Guardian Life's policies by June 30th to receive numerous benefits"
      )}`,
      img: BannerImg,
    },
  ];
  return (
    <div style={BgStyle}>
      <div className="body-container secondary-container banner-container">
        <div className="banner-content">
          <>
            {bannerItems.map((bannerItem) => (
              <TaxRebateBannerText
                bannerItem={bannerItem}
                key={bannerItem.id}
              />
            ))}
          </>
        </div>
      </div>
    </div>
  );
}

export default TaxRebateBanner;
