import React, { useEffect, useState } from 'react';
import './AbboutEasyLifeMedia.css';
import mediaImg1 from '../../../assets/media/easyMedia/media-1.jpg';
import mediaImg2 from '../../../assets/media/easyMedia/media-2.jpg';
import mediaImg3 from '../../../assets/media/easyMedia/media-3.jpg';
import EasyLifeMediaDetails from './EasyLifeMediaDetails';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Loading from '../../../components/Loading/Loading';
import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';

function AboutEasyLifeMedia() {
    const [banglatranslate, setBanglaTraslate] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    const title = `${t('easylifeAboutUsMediaTitle')}`;
    const menuTitle = `${t('easylifeAboutUsMediaMenuTitle')}`;

    const mediaContents = [
        {
            _id: 1,
            title: `${t('easylifeAboutUsMediaCardTitle1')}`,
            details: `${t('easylifeAboutUsMediaCardDetails1')}`,
            postedDate: `${t('easylifeAboutUsMediaCardPostDate1')}`,
            postedTime: `${t('easylifeAboutUsMediaCardPostTime1')}`,
            image: mediaImg1,
        },
        {
            _id: 2,
            title: `${t('easylifeAboutUsMediaCardTitle2')}`,
            details: `${t('easylifeAboutUsMediaCardDetails2')}`,
            postedDate: `${t('easylifeAboutUsMediaCardPostDate2')}`,
            postedTime: `${t('easylifeAboutUsMediaCardPostTime2')}`,
            image: mediaImg2,
        },
        {
            _id: 3,
            title: `${t('easylifeAboutUsMediaCardTitle3')}`,
            details: `${t('easylifeAboutUsMediaCardDetails3')}`,
            postedDate: `${t('easylifeAboutUsMediaCardPostDate3')}`,
            postedTime: `${t('easylifeAboutUsMediaCardPostTime3')}`,
            image: mediaImg3,
        },
    ];

    const [limit, setLimit] = useState(50);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [allMedia, setAllMedia] = useState([]);
    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/easy-life-media?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllMedia(data?.data?.easyLifeMediaList);
                setIsLoading(false);
            });
    }

    return (
        <div className="service-container bg-white pt-0">
            <div className="body-container secondary-container">
                <h1 className="title banner-title text-center">{title}</h1>
                <div>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={22}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        autoplay={{ delay: 2000 }}
                        breakpoints={{
                            '@0.00': {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            '@0.75': {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            '@1.00': {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            '@1.50': {
                                slidesPerView: 3,
                                spaceBetween: 22,
                            },
                        }}
                        modules={[Navigation, Pagination, Autoplay]}
                        className="mySwiper easylife-media-swiper"
                    >
                        {allMedia?.map((data) => (
                            <SwiperSlide key={data._id}>
                                <EasyLifeMediaDetails
                                    data={data}
                                    menuTitle={menuTitle}
                                    banglatranslate={banglatranslate}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                {/* <div className="blog-content mt-5">
          {allMedia?.map((data) => (
            <EasyLifeMediaDetails
              data={data}
              key={data._id}
              menuTitle={menuTitle}
              banglatranslate={banglatranslate}
            />
          ))}
        </div> */}
                <div className=" text-center mt-5">
                    <Link
                        to={`/easylife/about-easy-life/all-media`}
                        className="link-learnMore border-0 bg-transparent"
                    >
                        {t('viewAllBtn')}{' '}
                        <BsArrowRight className="learn-icon" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default AboutEasyLifeMedia;
