import React, { useState } from 'react';
import image1 from '../../../assets/staffList/s-1.jpeg';
import image2 from '../../../assets/staffList/s-2.jpeg';
import image3 from '../../../assets/staffList/s-3.jpeg';
import image4 from '../../../assets/staffList/s-4.jpeg';
import image5 from '../../../assets/staffList/s-5.jpeg';
import { BsSearch } from 'react-icons/bs';
import OurPlansTitle from '../../CatagoryPlans/OurPlans/OurPlansTitle';
import AgentList from '../../../components/AgentList/AgentList';
import { useRef } from 'react';
import { useEffect } from 'react';
import Loading from '../../../components/Loading/Loading';
import ReactPaginate from 'react-paginate';

function AllAgentList() {
    const [newAllAgentList, setAllAgentLists] = useState([]);
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(20);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    // pagination
    function handlePageClick(e) {
        console.log(e);
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function changeLimit() {
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/agent?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllAgentLists(data?.data?.agentLists);
                setIsLoading(false);
            });
    }

    return (
        <div className="body-container secondary-container message-contaner banner-container">
            <div>
                <h1 className="title text-center list-titles">
                    All Agent LIST
                </h1>
            </div>

            <div className="list-contaner">
                <div className="input-group mb-2">
                    <span className="input-group-text search-icon" id="search">
                        <BsSearch />
                    </span>
                    <input
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        className="form-control search-field"
                        placeholder="Search by name, liscense no, working area"
                        aria-label="sarech"
                        aria-describedby="search"
                    />
                </div>

                {newAllAgentList?.length === 0 ? (
                    <div className="min-vh-100 d-flex align-items-center justify-content-center">
                        No Agent Avaliable
                    </div>
                ) : (
                    <>
                        <div className="table-responsive">
                            <table className="table table-light table-bordered">
                                <thead>
                                    <tr className="table-tr">
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            id
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Contact
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            FA Code
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            UM Code
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Liscense No.
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Issue of License
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Expiry License
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Working Area
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newAllAgentList
                                        .filter((item) => {
                                            return search.toLowerCase() === ''
                                                ? item
                                                : item.name
                                                      .toLowerCase()
                                                      .includes(search) ||
                                                      item.liscenseNo
                                                          .toLowerCase()
                                                          .includes(search) ||
                                                      item.workingArea
                                                          .toLowerCase()
                                                          .includes(search);
                                        })
                                        .map((agentList, i) => (
                                            <AgentList
                                                agentList={agentList}
                                                i={
                                                    (currentPage.current - 1) *
                                                        limit +
                                                    i +
                                                    1
                                                }
                                                key={agentList._id}
                                            />
                                        ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-4">
                            <ReactPaginate
                                breakLabel="......."
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                previousLabel="< previous"
                                renderOnZeroPageCount={null}
                                marginPagesDisplayed={2}
                                containerClassName="pagination justify-content-center"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                activeClassName="active"
                            />

                            {/* <input
              placeholder="limit"
              onChange={(e) => setLimit(e.target.value)}
            />
            <button onClick={changeLimit}>Set Limit</button> */}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default AllAgentList;
