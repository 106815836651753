import React from "react";
import "./agentList.css";
import AgentBanner from "../AgentBanner/AgentBanner";
import AllAgentList from "../AllAgentList/AllAgentList";
import AgentListPDF from "../AllAgentList/AgentListPDF";
import useTitle from "../../../hooks/useTitle";

function AgentList() {
  useTitle("Agent List");

  return (
    <>
      {/* <AgentBanner /> */}
      {/* <AllAgentList /> */}
      <AgentListPDF />
    </>
  );
}

export default AgentList;
