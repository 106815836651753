import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { getImageUrl } from '../../../../hooks/imageUpload';

function EditAgentModal({
    show,
    handleClose,
    updateAgent,
    setUpdateAgent,
    getPaginationList,
}) {
    const handleChange = (e) => {
        setUpdateAgent({ ...updateAgent, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setUpdateAgent({ ...updateAgent, image: e.target.files[0] });
    };

    const handleUpdateAction = async (e) => {
        e.preventDefault();
        handleClose();

        const formData = new FormData();
        formData.append('name', updateAgent.name);
        formData.append('address', updateAgent.address);
        formData.append('contactNumber', updateAgent.contactNumber);
        formData.append('nid', updateAgent.nid);
        formData.append('faCode', updateAgent.faCode);
        formData.append('umCode', updateAgent.umCode);
        formData.append('liscenseNo', updateAgent.liscenseNo);
        formData.append('issueOfLicense', updateAgent.issueOfLicense);
        formData.append('expiryLicense', updateAgent.expiryLicense);
        formData.append('workingArea', updateAgent.workingArea);
        formData.append('image', updateAgent.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        await axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/agent/${updateAgent?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    console.log(res);
                    toast.success('Agent updated successfully');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not a update agent!!!!');
            });
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Update Agent
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction} className="row">
                    <div className="mb-3 col-6">
                        <label className="form-label">Name</label>
                        <input
                            type="text"
                            name="name"
                            onChange={handleChange}
                            defaultValue={updateAgent?.name}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3 col-6">
                        <label className="form-label">Contact Number</label>
                        <input
                            type="text"
                            name="contactNumber"
                            className="form-control"
                            defaultValue={updateAgent?.contactNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">NID</label>
                        <input
                            type="text"
                            name="nid"
                            onChange={handleChange}
                            defaultValue={updateAgent?.nid}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">FA Code</label>
                        <input
                            type="text"
                            name="faCode"
                            className="form-control"
                            onChange={handleChange}
                            defaultValue={updateAgent?.faCode}
                            required
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">UM Code</label>
                        <input
                            type="text"
                            name="umCode"
                            onChange={handleChange}
                            defaultValue={updateAgent?.umCode}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Liscense No</label>
                        <input
                            type="text"
                            name="liscenseNo"
                            onChange={handleChange}
                            defaultValue={updateAgent?.liscenseNo}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Issue Of License</label>
                        <input
                            type="date"
                            name="issueOfLicense"
                            onChange={handleChange}
                            defaultValue={updateAgent?.issueOfLicense}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Expiry License</label>
                        <input
                            type="date"
                            name="expiryLicense"
                            onChange={handleChange}
                            defaultValue={updateAgent?.expiryLicense}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Working Area</label>
                        <input
                            type="text"
                            name="workingArea"
                            onChange={handleChange}
                            defaultValue={updateAgent?.workingArea}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Image</label>
                        <input
                            type="file"
                            onChange={handleImage}
                            name="image"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Address</label>

                        <textarea
                            className="form-control"
                            onChange={handleChange}
                            defaultValue={updateAgent?.address}
                            name="address"
                        ></textarea>
                    </div>

                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default EditAgentModal;
