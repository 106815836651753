import React, { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player';

const DashboardPartnerChannelsViewModal = ({
    viewShow,
    handleViewClose,
    partnerChannelView,
    setPartnerChannelView,
}) => {
    const playerRef = useRef(null);

    const {
        partnerName,
        partnerNameBn,
        partnerLogo,
        partnerDesc,
        partnerDescBn,
        lifeTitle,
        lifeTitleBn,
        lifeDesc,
        lifeDescBn,
        healthTitle,
        healthTitleBn,
        healthDesc,
        healthDescBn,
        ourPolicyImg,
        ourPolicyDesc,
        ourPolicyDescBn,
        purchaseImg,
        purchaseDesc,
        purchaseDescBn,
        vedioLink,
        ourPolicyTitle,
        ourPolicyTitleBn,
        purchaseTitle,
        purchaseTitleBn,
    } = partnerChannelView;

    return (
        <>
            <Modal
                show={viewShow}
                onHide={handleViewClose}
                scrollable={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        className="book-title ps-2"
                        id="contained-modal-title-vcenter"
                    >
                        {partnerName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4 form-body">
                    <div>
                        <div>
                            <img
                                src={partnerLogo}
                                alt="blog"
                                className="w-100"
                                style={{
                                    height: '270px',
                                    objectFit: 'contain',
                                }}
                            />
                        </div>
                        <h5 className="fw-bold mt-4 pb-2 border-bottom">
                            {partnerName}{' '}
                            {partnerNameBn && ` (${partnerNameBn})`}
                        </h5>
                        <p className="mt-3">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: partnerDesc,
                                }}
                            ></span>
                        </p>
                        <p className="mt-3">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: partnerDescBn,
                                }}
                            ></span>
                        </p>
                    </div>
                    <div>
                        <h5
                            className={`fw-bold mt-4 ${
                                (lifeTitle || lifeDescBn) &&
                                'border-bottom pb-2'
                            }`}
                        >
                            {lifeTitle}
                            {lifeTitleBn && ` (${lifeTitleBn})`}
                        </h5>
                        <p className="mt-3">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: lifeDesc,
                                }}
                            ></span>
                        </p>
                        <p className="mt-3">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: lifeDescBn,
                                }}
                            ></span>
                        </p>
                    </div>
                    <div>
                        <h5
                            className={`fw-bold mt-4 ${
                                (healthTitle || healthTitleBn) &&
                                'border-bottom pb-2'
                            }`}
                        >
                            {healthTitle}
                            {healthTitleBn && ` (${healthTitleBn})`}
                        </h5>
                        <p className="mt-3 adc-partner-channel-table">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: healthDesc,
                                }}
                            ></span>
                        </p>
                        <p className="mt-3 adc-partner-channel-table">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: healthDescBn,
                                }}
                            ></span>
                        </p>
                    </div>
                    <div className="mt-3">
                        <div>
                            {ourPolicyImg && (
                                <img
                                    src={ourPolicyImg}
                                    alt="blog"
                                    className="w-100"
                                    style={{
                                        height: '270px',
                                        objectFit: 'contain',
                                    }}
                                />
                            )}
                        </div>
                        <p className="mt-3">{ourPolicyTitle}</p>
                        <p className="mt-3">{ourPolicyTitleBn}</p>
                        <p className="mt-3">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: ourPolicyDesc,
                                }}
                            ></span>
                        </p>
                        <p className="mt-3">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: ourPolicyDescBn,
                                }}
                            ></span>
                        </p>
                    </div>
                    <div className="mt-3">
                        <div>
                            {purchaseImg && (
                                <img
                                    src={purchaseImg}
                                    alt="blog"
                                    className="w-100"
                                    style={{
                                        height: '270px',
                                        objectFit: 'contain',
                                    }}
                                />
                            )}
                        </div>
                        <p className="mt-3">{purchaseTitle}</p>
                        <p className="mt-3">{purchaseTitleBn}</p>
                        <p className="mt-3">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: purchaseDesc,
                                }}
                            ></span>
                        </p>
                        <p className="mt-3">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: purchaseDescBn,
                                }}
                            ></span>
                        </p>
                    </div>
                    {!vedioLink || vedioLink.every((link) => link === '') ? (
                        <></>
                    ) : (
                        vedioLink.map((link, index) => (
                            <div key={index}>
                                <p className="mt-4">
                                    <strong>Video Link:</strong>
                                    <span> {link}</span>
                                </p>

                                <div className="mt-2">
                                    <ReactPlayer
                                        className="w-100"
                                        ref={playerRef}
                                        url={link}
                                        controls={true}
                                    />
                                </div>
                            </div>
                        ))
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DashboardPartnerChannelsViewModal;
