import React, { useRef } from 'react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import axios from 'axios';

function AddProduct() {
    const navigate = useNavigate();
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [contentBn, setContentBn] = useState('');
    const [addProduct, setAddProduct] = useState({
        category: '',
        categoryBn: '',
        productTitle: '',
        productTitleBn: '',
        coverage: '',
        coverageBn: '',
        term: '',
        termBn: '',
        premium: '',
        premiumBn: '',
        image: '',
        maturityBenefit: '',
        maturityBenefitBn: '',
        deathBenefit: '',
        deathBenefitBn: '',
        sumAssured: '',
        sumAssuredBn: '',
        paymentMode: '',
        paymentModeBn: '',
        productTerm: '',
        productTermBn: '',
        age: '',
        ageBn: '',
        surrenderOption: '',
        surrenderOptionBn: '',
        medicalTest: '',
        medicalTestBn: '',
        rider: '',
        riderBn: '',
        conservationOption: '',
        conservationOptionBn: '',
    });

    const handleChange = (e) => {
        setAddProduct({ ...addProduct, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setAddProduct({ ...addProduct, image: e.target.files[0] });
    };

    // post
    const handleAddProduct = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('productCategory', addProduct?.category);
        formData.append('productCategoryBn', addProduct?.categoryBn);
        formData.append('productTitle', addProduct?.productTitle);
        formData.append('productTitleBn', addProduct?.productTitleBn);
        formData.append('description', content);
        formData.append('descriptionBn', contentBn);
        formData.append('coverage', addProduct?.coverage);
        formData.append('coverageBn', addProduct?.coverageBn);
        formData.append('term', addProduct?.term);
        formData.append('termBn', addProduct?.termBn);
        formData.append('premium', addProduct?.premium);
        formData.append('premiumBn', addProduct?.premiumBn);
        formData.append('maturityBenefit', addProduct?.maturityBenefit);
        formData.append('maturityBenefitBn', addProduct?.maturityBenefitBn);
        formData.append('deathBenefit', addProduct?.deathBenefit);
        formData.append('deathBenefitBn', addProduct?.deathBenefitBn);
        formData.append('sumAssured', addProduct?.sumAssured);
        formData.append('sumAssuredBn', addProduct?.sumAssuredBn);
        formData.append('premiumPaymentMode', addProduct?.paymentMode);
        formData.append('premiumPaymentModeBn', addProduct?.paymentModeBn);
        formData.append('productTerm', addProduct?.productTerm);
        formData.append('productTermBn', addProduct?.productTermBn);
        formData.append('age', addProduct?.age);
        formData.append('ageBn', addProduct?.ageBn);
        formData.append('surrenderOption', addProduct?.surrenderOption);
        formData.append('surrenderOptionBn', addProduct?.surrenderOptionBn);
        formData.append('medicalTest', addProduct?.medicalTest);
        formData.append('medicalTestBn', addProduct?.medicalTestBn);
        formData.append('rider', addProduct?.rider);
        formData.append('riderBn', addProduct?.riderBn);
        formData.append('conservationOption', addProduct?.conservationOption);
        formData.append(
            'conservationOptionBn',
            addProduct?.conservationOptionBn
        );
        formData.append('image', addProduct?.image);

        for (const value of formData.values()) {
            console.log(value);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/easy-life-product`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    console.log(res);
                    toast.success('Product added successfully');
                    setAddProduct('');
                    navigate('/dashboard/easylife/all-products');
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error('Not added successfully');
            });
    };
    return (
        <div className="home-content">
            <div class="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/easylife/all-products"
                    class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Product</span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center mb-4">
                    Add A Product
                </h1>
                <form onSubmit={handleAddProduct}>
                    <div className="row gy-5 gx-5">
                        <section className="col-12 col-md-6">
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Select Category
                                </label>
                                <select
                                    onChange={handleChange}
                                    name="category"
                                    class="form-select"
                                >
                                    <option value="" selected>
                                        Choses a category
                                    </option>
                                    <option value="Term Life Insurance">
                                        Term Life Insurance
                                    </option>
                                    <option value="Savings Plan">
                                        Savings Plan
                                    </option>
                                    <option value="Women's Savings Plan">
                                        Women's Savings Plan
                                    </option>
                                    {/* <option value="Cancer Care Plan">Cancer Care Plan</option> */}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Product Title
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.productTitle}
                                    name="productTitle"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Product Description
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={content}
                                    // config={config}
                                    // tabIndex={1} // tabIndex of textarea
                                    onChange={(newContent) => {
                                        setContent(newContent);
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Coverage
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.coverage}
                                    name="coverage"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Term
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.term}
                                    name="term"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Premium
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.premium}
                                    name="premium"
                                    className="form-control"
                                />
                            </div>
                            <h5 className="dashboard-title text-center fs-6 mt-4 mb-3">
                                Add Details of The Product
                            </h5>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Maturity Benefit
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.maturityBenefit}
                                    name="maturityBenefit"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Death/TPD Benefit
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.deathBenefit}
                                    name="deathBenefit"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Sum Assured
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.sumAssured}
                                    name="sumAssured"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Premium Payment Mode
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.paymentMode}
                                    name="paymentMode"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Product Term
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.productTerm}
                                    name="productTerm"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Age
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.age}
                                    name="age"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Surrender Option
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.surrenderOption}
                                    name="surrenderOption"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Medical Test
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.medicalTest}
                                    name="medicalTest"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Rider
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.rider}
                                    name="rider"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Conservation Option
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.conservationOption}
                                    name="conservationOption"
                                    className="form-control"
                                />
                            </div>
                        </section>

                        <section className="col-12 col-md-6">
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Select Category
                                </label>
                                <select
                                    onChange={handleChange}
                                    name="categoryBn"
                                    class="form-select"
                                >
                                    <option value="" selected>
                                        একটি ক্যাটাগরি বেছে নিন
                                    </option>
                                    <option value="টার্ম লাইফ ইন্স্যুরেন্স">
                                        টার্ম লাইফ ইন্স্যুরেন্স
                                    </option>
                                    <option value="মাসিক সঞ্চয় প্ল্যান">
                                        মাসিক সঞ্চয় প্ল্যান
                                    </option>
                                    <option value="মহিলাদের মাসিক সঞ্চয় প্ল্যান">
                                        মহিলাদের মাসিক সঞ্চয় প্ল্যান
                                    </option>
                                    {/* <option value="ক্যান্সার কেয়ার প্ল্যান">
                    ক্যান্সার কেয়ার প্ল্যান
                  </option> */}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Product Title (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.productTitleBn}
                                    name="productTitleBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Product Description (Bangla)
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={contentBn}
                                    // config={config}
                                    // tabIndex={1} // tabIndex of textarea
                                    onChange={(newContent) => {
                                        setContentBn(newContent);
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Coverage (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.coverageBn}
                                    name="coverageBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Term (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.termBn}
                                    name="termBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Premium (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.premiumBn}
                                    name="premiumBn"
                                    className="form-control"
                                />
                            </div>
                            <h5 className="dashboard-title text-center fs-6 mt-4 mb-3">
                                পণ্যের বিবরণ যোগ করুন
                            </h5>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Maturity Benefit (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.maturityBenefitBn}
                                    name="maturityBenefitBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Death/TPD Benefit (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.deathBenefitBn}
                                    name="deathBenefitBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Sum Assured (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.sumAssuredBn}
                                    name="sumAssuredBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Premium Payment Mode (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.paymentModeBn}
                                    name="paymentModeBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Product Term (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.productTermBn}
                                    name="productTermBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Age (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.ageBn}
                                    name="ageBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Surrender Option (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.surrenderOptionBn}
                                    name="surrenderOptionBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Medical Test (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.medicalTestBn}
                                    name="medicalTestBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Rider (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.riderBn}
                                    name="riderBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Conservation Option (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    value={addProduct.conservationOptionBn}
                                    name="conservationOptionBn"
                                    className="form-control"
                                />
                            </div>
                        </section>
                    </div>

                    <div className="mb-5">
                        <label className="form-label fw-bold">
                            Product Image
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            onChange={handleImage}
                            className="form-control"
                        />
                    </div>

                    <div className="d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary px-4">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddProduct;
