import React from 'react';
import BannerImg from '../../assets/easylife/Accidental/Features.jpg';
import { FaCheckCircle } from 'react-icons/fa';

function AcceidentalCareFeature() {
    return (
        <div className="service-container" style={{ paddingTop: '0rem' }}>
            <div className="body-container secondary-container">
                <div>
                    <div className="banner-content">
                        <div className="left-content">
                            <h1 class="title banner-title text-start">
                                FEATURES
                            </h1>
                            <p className="pt-5 fw-bold">
                                Guardian Accident Care is a Comprehensive
                                Protection for any individual by ensurin.
                            </p>
                            <ul className="text banner-text pb-0 pt-4">
                                <li>
                                    <div className="corporate-coverage-list">
                                        <div className="corporate-coverage-check">
                                            <FaCheckCircle />
                                        </div>
                                        <p style={{ fontSize: '14px' }}>
                                            <span className="fw-bold">
                                                Financial Shield:{' '}
                                            </span>{' '}
                                            <span>
                                                Ensures stability for
                                                policyholder and nominee in
                                                times of unforeseen accidents.
                                            </span>
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="corporate-coverage-list">
                                        <div className="corporate-coverage-check">
                                            <FaCheckCircle />
                                        </div>
                                        <p style={{ fontSize: '14px' }}>
                                            <span className="fw-bold">
                                                Extensive Coverage:
                                            </span>{' '}
                                            <span>
                                                Protects against a wide range of
                                                accidental injuries and
                                                disabilities.
                                            </span>
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="corporate-coverage-list">
                                        <div className="corporate-coverage-check">
                                            <FaCheckCircle />
                                        </div>
                                        <p style={{ fontSize: '14px' }}>
                                            <span className="fw-bold">
                                                Affordable Pricing:
                                            </span>{' '}
                                            <span>
                                                Premium Starting from as low as
                                                BDT 10 Per Day to BDT 200 for
                                                30Days.
                                            </span>
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="corporate-coverage-list">
                                        <div className="corporate-coverage-check">
                                            <FaCheckCircle />
                                        </div>
                                        <p style={{ fontSize: '14px' }}>
                                            <span className="fw-bold">
                                                Policy Terms:
                                            </span>{' '}
                                            <span>
                                                Minimum 1Day to Maximum 30 Days
                                                in a single row.
                                            </span>
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="corporate-coverage-list">
                                        <div className="corporate-coverage-check">
                                            <FaCheckCircle />
                                        </div>
                                        <p style={{ fontSize: '14px' }}>
                                            <span className="fw-bold">
                                                Medical Test:
                                            </span>{' '}
                                            <span>
                                                Prior Medical test is not
                                                required.
                                            </span>
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="corporate-coverage-list">
                                        <div className="corporate-coverage-check">
                                            <FaCheckCircle />
                                        </div>
                                        <p style={{ fontSize: '14px' }}>
                                            <span className="fw-bold">
                                                Vital Support:
                                            </span>{' '}
                                            <span>
                                                Offers essential financial aid
                                                to beneficiaries in case of an
                                                insured person's unfortunate
                                                event.
                                            </span>
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="corporate-coverage-list">
                                        <div className="corporate-coverage-check">
                                            <FaCheckCircle />
                                        </div>
                                        <p style={{ fontSize: '14px' }}>
                                            <span className="fw-bold">
                                                Instant Policy Activation:
                                            </span>{' '}
                                            <span>
                                                Policy Buyers will receive
                                                immediate confirmation of your
                                                policy purchase in real-time.
                                            </span>
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="corporate-coverage-list">
                                        <div className="corporate-coverage-check">
                                            <FaCheckCircle />
                                        </div>
                                        <p style={{ fontSize: '14px' }}>
                                            <span className="fw-bold">
                                                Claim Process:
                                            </span>{' '}
                                            <span>
                                                Easy and Paperless digital claim
                                                process.
                                            </span>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="right-img">
                            <img src={BannerImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AcceidentalCareFeature;
