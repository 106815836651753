import React from "react";
import { toast } from "react-hot-toast";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

function AgentListTable({
  agentList,
  handleShow,
  setUpdateAgent,
  getPaginationList,
}) {
  const {
    _id,
    name,
    imageURL,
    address,
    contactNumber,
    expiryLicense,
    faCode,
    issueOfLicense,
    liscenseNo,
    nid,
    umCode,
    workingArea,
  } = agentList;

  const handleDeleteAction = (id) => {
    const agree = window.confirm(
      `Are you sure, you want to delete: "${name}?"`
    );
    if (agree) {
      fetch(`${process.env.REACT_APP_host}/api/v1/agent/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            getPaginationList();
            toast.success(`${name} is a Deleted`);
          }
        });
    }
  };

  return (
    <tr>
      <td className="text-center align-middle table-text">
        <div className="d-flex  align-items-center gap-2">
          <div className="list-img">
            <img src={imageURL} />
          </div>
          <div>
            <p className="fw-bold table-text">{name}</p>
          </div>
        </div>
      </td>
      <td className="text-center align-middle table-text">{address}</td>
      <td className="text-center align-middle table-text">{contactNumber}</td>
      <td className="text-center align-middle table-text">{nid}</td>
      <td className="text-center align-middle table-text">{faCode}</td>
      <td className="text-center align-middle table-text">{umCode}</td>
      <td className="text-center align-middle table-text">{liscenseNo}</td>
      <td className="text-center align-middle table-text">{issueOfLicense}</td>
      <td className="text-center align-middle table-text">{expiryLicense}</td>
      <td className="text-center align-middle table-text">{workingArea}</td>
      <td className="text-center align-middle table-text">
        <div className="d-flex align-items-center justify-content-center gap-2">
          <button
            onClick={() => {
              handleShow();
              setUpdateAgent(agentList);
            }}
            className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center "
          >
            <BiEdit /> <span>Edit</span>
          </button>
          <button
            onClick={() => handleDeleteAction(_id)}
            className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center"
          >
            <MdDelete /> <span>Delete</span>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default AgentListTable;
