import React, { useEffect, useState } from "react";
import "./ADCpartner.css";
import adcImg from "../../../assets/image/adc-partner.jpg";
import robiImg from "../../../assets/adcIcon/card_design-01.png";
import bkashImg from "../../../assets/adcIcon/card_design-02.png";
import nagadImg from "../../../assets/adcIcon/card_design-04.png";
import upayImg from "../../../assets/adcIcon/card_design-05.png";
import walletImg from "../../../assets/adcIcon/card_design-06.png";
import carnivalImg from "../../../assets/adcIcon/card_design-07.png";
import jotnoImg from "../../../assets/adcIcon/card_design-08.png";
import robishopImg from "../../../assets/adcIcon/card_design-09.png";
import truckImg from "../../../assets/adcIcon/card_design-10.png";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Loading from "../../../components/Loading/Loading";


const ADCpartner = () => {
  const [banglatranslate, setBanglaTraslate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [allADC, setAllADC] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setBanglaTraslate(currentLang);
  }, []);

  const adcPartners = [
    // {
    //   id: 1,
    //   companyName: `${t("easylifeADCRobiName")}`,
    //   img: robiImg,
    //   link: `/partnerchannels/robi`,
    //   des: `${t("easylifeADCRobiDetails")}`,
    //   purchasePolicy: [
    //     {
    //       id: 1,
    //       text: `${t("easylifeADCRobiWhyDetails")}`,
    //     },
    //     {
    //       id: 2,
    //       text: `${t("easylifeADCRobiWhyL1")}`,
    //     },
    //     {
    //       id: 3,
    //       text: `${t("easylifeADCRobiWhyL2")}`,
    //     },
    //     {
    //       id: 4,
    //       text: `${t("easylifeADCRobiWhyL3")}`,
    //     },
    //     {
    //       id: 5,
    //       text: `${t("easylifeADCRobiWhyL4")}`,
    //     },
    //     {
    //       id: 6,
    //       text: `${t("easylifeADCRobiWhyL5")}`,
    //     },
    //   ],
    //   howPurchase: [
    //     {
    //       id: 1,
    //       text: `${t("easylifeADCRobiHowPolicyDetails")}`,
    //     },
    //     {
    //       id: 2,
    //       text: `${t("easylifeADCRobiHowPolicyL1")}`,
    //     },
    //     {
    //       id: 3,
    //       text: `${t("easylifeADCRobiHowPolicyL2")}`,
    //     },
    //     {
    //       id: 4,
    //       text: `${t("easylifeADCRobiHowPolicyL3")}`,
    //     },
    //   ],
    //   keyUSP: [
    //     {
    //       id: 1,
    //       text: `${t("easylifeADCRobiKeyUSPDetails")}`,
    //     },
    //   ],
    //   videoLink: [
    //     {
    //       id: 1,
    //       title:
    //         "গার্ডিয়ান লাইফের সঞ্চয় ও সুরক্ষা সেবা নিতে পারবেন রবি টাচ পয়েন্ট থেকে",
    //       link: "https://www.youtube.com/embed/28gxRA9XCh8",
    //     },
    //     {
    //       id: 2,
    //       title: "Robi R- Store এর সাথে যুক্ত হয়ে ইজিলাইফ এখন ৬৪ জেলায়",
    //       link: "https://www.youtube.com/embed/UY3NDiRQg2E",
    //     },
    //   ],
    //   insurance: [
    //     {
    //       id: "life",
    //       PlanName: `${t("easylifeADCPlanLifeInsurance")}`,
    //       plans: [
    //         {
    //           id: 1,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "desctription",
    //             },
    //             {
    //               id: 2,
    //               tr: "Guardian Sheba",
    //             },
    //             {
    //               id: 3,
    //               tr: "Guardian Shripha",
    //             },
    //             {
    //               id: 4,
    //               tr: "Guardian spondon",
    //             },
    //             {
    //               id: 5,
    //               tr: "Guardian Akangkha",
    //             },
    //             {
    //               id: 6,
    //               tr: "Guardian Ashshash",
    //             },
    //           ],
    //         },
    //         {
    //           id: 2,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Premium",
    //             },
    //             {
    //               id: 2,
    //               tr: "BDT 99",
    //             },
    //             {
    //               id: 3,
    //               tr: "BDT 125",
    //             },
    //             {
    //               id: 4,
    //               tr: "BDT 225",
    //             },
    //             {
    //               id: 5,
    //               tr: "BDT 375",
    //             },
    //             {
    //               id: 6,
    //               tr: "BDT 575",
    //             },
    //           ],
    //         },
    //         {
    //           id: 3,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Life Coverage",
    //             },
    //             {
    //               id: 2,
    //               tr: "BDT 10,000",
    //             },
    //             {
    //               id: 3,
    //               tr: "BDT 25,000",
    //             },
    //             {
    //               id: 4,
    //               tr: "BDT 50,000",
    //             },
    //             {
    //               id: 5,
    //               tr: "BDT 100,000",
    //             },
    //             {
    //               id: 6,
    //               tr: "BDT 125,000",
    //             },
    //           ],
    //         },
    //         {
    //           id: 4,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Hospicash Coverage",
    //             },
    //             {
    //               id: 2,
    //               tr: "BDT 5,000",
    //             },
    //             {
    //               id: 3,
    //               tr: "BDT 5,000",
    //             },
    //             {
    //               id: 4,
    //               tr: "BDT 10,000",
    //             },
    //             {
    //               id: 5,
    //               tr: "BDT 15,000",
    //             },
    //             {
    //               id: 6,
    //               tr: "BDT 25,000",
    //             },
    //           ],
    //         },
    //         {
    //           id: 5,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Telemedicine Consultation Service",
    //             },
    //             {
    //               id: 2,
    //               tr: "24/7 Unlimited",
    //             },
    //             {
    //               id: 3,
    //               tr: "N/A",
    //             },
    //             {
    //               id: 4,
    //               tr: "N/A",
    //             },
    //             {
    //               id: 5,
    //               tr: "N/A",
    //             },
    //             {
    //               id: 6,
    //               tr: "N/A",
    //             },
    //           ],
    //         },
    //         {
    //           id: 6,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Term",
    //             },
    //             {
    //               id: 2,
    //               tr: "1 Year",
    //             },
    //           ],
    //         },
    //         {
    //           id: 7,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Payment Mood",
    //             },
    //             {
    //               id: 2,
    //               tr: "Yearly",
    //             },
    //           ],
    //         },
    //         {
    //           id: 8,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Age",
    //             },
    //             {
    //               id: 2,
    //               tr: "18 to 60",
    //             },
    //           ],
    //         },
    //       ],
    //     },

    //     {
    //       id: "month",
    //       PlanName: `${t("easylifeADCPlanMonthlySaving")}`,
    //       plans: [
    //         {
    //           id: 1,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "desctription",
    //             },
    //             {
    //               id: 2,
    //               tr: "MSP Vorosha",
    //             },
    //           ],
    //         },
    //         {
    //           id: 2,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Monthly Premium",
    //             },
    //             {
    //               id: 2,
    //               tr: "BDT 500",
    //             },
    //           ],
    //         },
    //         {
    //           id: 3,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Maximum Life Coverage",
    //             },
    //             {
    //               id: 2,
    //               tr: "BDT 171,000",
    //             },
    //           ],
    //         },
    //         {
    //           id: 4,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Critical Illness Coverage",
    //             },
    //             {
    //               id: 2,
    //               tr: "Same as life Coverage",
    //             },
    //           ],
    //         },
    //         {
    //           id: 5,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Maturity Value",
    //             },
    //             {
    //               id: 2,
    //               tr: "Total Sum Assured with Bonus",
    //             },
    //           ],
    //         },
    //         {
    //           id: 6,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Policy Term",
    //             },
    //             {
    //               id: 2,
    //               tr: "10, 15, 20 & 25 Years",
    //             },
    //           ],
    //         },
    //         {
    //           id: 7,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Surrender Option",
    //             },
    //             {
    //               id: 2,
    //               tr: "After two years",
    //             },
    //           ],
    //         },
    //         {
    //           id: 8,
    //           planTableRow: [
    //             {
    //               id: 1,
    //               tr: "Age Range",
    //             },
    //             {
    //               id: 2,
    //               tr: "18-50 years ",
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },

    {
      id: 2,
      companyName: `${t("easylifeADCBkashName")}`,
      img: bkashImg,
      link: `/partnerchannels/bkash`,
      des: `${t("easylifeADCBkashDetails")}`,
      purchasePolicy: [
        {
          id: 1,
          text: `${t("easylifeADCBkashWhyPolicyDetails")}`,
        },
        {
          id: 2,
          text: `${t("easylifeADCBkashWhyPolicyL1")}`,
        },
        {
          id: 3,
          text: `${t("easylifeADCBkashWhyPolicyL2")}`,
        },
        {
          id: 4,
          text: `${t("easylifeADCBkashWhyPolicyL3")}`,
        },
        {
          id: 5,
          text: `${t("easylifeADCBkashWhyPolicyL4")}`,
        },
      ],
      howPurchase: [
        {
          id: 1,
          text: `${t("easylifeADCBkashHowPolicyDetails")}`,
        },
        {
          id: 2,
          text: `${t("easylifeADCBkashHowPolicyL1")}`,
        },
        {
          id: 3,
          text: `${t("easylifeADCBkashHowPolicyL2")}`,
        },
        {
          id: 4,
          text: `${t("easylifeADCBkashHowPolicyL3")}`,
        },
        {
          id: 5,
          text: `${t("easylifeADCBkashHowPolicyL4")}`,
        },
        {
          id: 6,
          text: `${t("easylifeADCBkashHowPolicyL5")}`,
        },
      ],
      keyUSP: [],
      videoLink: [
        {
          id: 1,
          title: "বিকাশ অ্যাপে গার্ডিয়ান লাইফের ইজিলাইফ পলিসি",
          link: "https://www.youtube.com/embed/6t45MjzFjdY",
        },
        {
          id: 2,
          title:
            "দেশের মানুষের কাছে ডিজিটাল ইন্স্যুরেন্স সেবা পৌঁছে দিতে গার্ডিয়ান লাইফ এক হয়েছে বিকাশ-এর সাথে",
          link: "https://www.youtube.com/embed/EBal62lYLnA",
        },
      ],
      insurance: [
        // {
        //   id: "life",
        //   PlanName: `${t("easylifeADCPlanLifeInsurance")}`,
        //   plans: [
        //     {
        //       id: 1,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "desctription",
        //         },
        //         {
        //           id: 2,
        //           tr: "Proyash",
        //         },
        //         {
        //           id: 3,
        //           tr: "Prottoy",
        //         },
        //         {
        //           id: 4,
        //           tr: "Prottasha",
        //         },
        //         {
        //           id: 5,
        //           tr: "Protishruti",
        //         },
        //       ],
        //     },
        //     {
        //       id: 2,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Premium",
        //         },
        //         {
        //           id: 2,
        //           tr: "BDT 150",
        //         },
        //         {
        //           id: 3,
        //           tr: "BDT 250",
        //         },
        //         {
        //           id: 4,
        //           tr: "BDT 300",
        //         },
        //         {
        //           id: 5,
        //           tr: "BDT 400",
        //         },
        //       ],
        //     },
        //     {
        //       id: 3,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Life Coverage",
        //         },
        //         {
        //           id: 2,
        //           tr: "BDT 100,000",
        //         },
        //         {
        //           id: 3,
        //           tr: "BDT 150,000",
        //         },
        //         {
        //           id: 4,
        //           tr: "BDT 200,000",
        //         },
        //         {
        //           id: 5,
        //           tr: "BDT 250,000",
        //         },
        //       ],
        //     },
        //     {
        //       id: 4,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Term",
        //         },
        //         {
        //           id: 2,
        //           tr: "1 Year",
        //         },
        //       ],
        //     },
        //     {
        //       id: 5,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Payment Mood",
        //         },
        //         {
        //           id: 2,
        //           tr: "Yearly",
        //         },
        //       ],
        //     },
        //     {
        //       id: 6,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Age",
        //         },
        //         {
        //           id: 2,
        //           tr: "18 to 60",
        //         },
        //       ],
        //     },
        //   ],
        // },

        {
          id: "health",
          PlanName: `${t("easylifeADCPlanHealthInsurance")}`,
          plans: [
            {
              id: 1,
              planTableRow: [
                {
                  id: 1,
                  tr: "desctription",
                },
                {
                  id: 2,
                  tr: "Guardian Bisshash",
                },
                {
                  id: 3,
                  tr: "Guardian Nirbhar",
                },
                {
                  id: 4,
                  tr: "Guardian Nishchoyota",
                },
                {
                  id: 5,
                  tr: "Nirbhorota",
                },
                {
                  id: 6,
                  tr: "Nirapotta",
                },
                {
                  id: 7,
                  tr: "Nirbhabona",
                },
              ],
            },
            {
              id: 2,
              planTableRow: [
                {
                  id: 1,
                  tr: "Premium",
                },
                {
                  id: 2,
                  tr: "BDT 125",
                },
                {
                  id: 3,
                  tr: "BDT 225",
                },
                {
                  id: 4,
                  tr: "BDT 349",
                },
                {
                  id: 5,
                  tr: "BDT 600",
                },
                {
                  id: 6,
                  tr: "BDT 1,349",
                },
                {
                  id: 7,
                  tr: "BDT 2,599",
                },
              ],
            },
            {
              id: 3,
              planTableRow: [
                {
                  id: 1,
                  tr: "Life Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 25,000",
                },
                {
                  id: 3,
                  tr: "BDT 50,000",
                },
                {
                  id: 4,
                  tr: "BDT 50,000",
                },
                {
                  id: 5,
                  tr: "BDT 100,000",
                },
                {
                  id: 6,
                  tr: "BDT 200,000",
                },
                {
                  id: 7,
                  tr: "BDT 300,000",
                },
              ],
            },
            {
              id: 4,
              planTableRow: [
                {
                  id: 1,
                  tr: "Hospicash Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 5,000",
                },
                {
                  id: 3,
                  tr: "BDT 10,000",
                },
                {
                  id: 4,
                  tr: "BDT 20,000",
                },
                {
                  id: 5,
                  tr: "BDT 10,000",
                },
                {
                  id: 6,
                  tr: "BDT 20,000",
                },
                {
                  id: 7,
                  tr: "BDT 50,000",
                },
              ],
            },
            {
              id: 5,
              planTableRow: [
                {
                  id: 1,
                  tr: "OPD Coverage",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "N/A",
                },
                {
                  id: 4,
                  tr: "N/A",
                },
                {
                  id: 5,
                  tr: "BDT 2,000",
                },
                {
                  id: 6,
                  tr: "BDT 5,000",
                },
                {
                  id: 7,
                  tr: "BDT 10,000",
                },
              ],
            },
            {
              id: 6,
              planTableRow: [
                {
                  id: 1,
                  tr: "Term",
                },
                {
                  id: 2,
                  tr: "1 Year",
                },
              ],
            },
            {
              id: 7,
              planTableRow: [
                {
                  id: 1,
                  tr: "Payment Mood",
                },
                {
                  id: 2,
                  tr: "Yearly",
                },
              ],
            },
            {
              id: 8,
              planTableRow: [
                {
                  id: 1,
                  tr: "Age",
                },
                {
                  id: 2,
                  tr: "18 to 60",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      id: 3,
      companyName: `${t("easylifeADCBkashRMGName")}`,
      img: bkashImg,
      link: `/partnerchannels/bkash-rmg`,
      des: `${t("easylifeADCBkashRMGDetails")}`,
      purchasePolicy: [],
      howPurchase: [],
      keyUSP: [],
      videoLink: [
        {
          id: 1,
          title: "তৈরি পোশাক কর্মীদের জন্য সাশ্রয়ী প্রিমিয়ামে ইন্স্যুরেন্স পলিসি",
          link: "https://www.youtube.com/embed/rVxH6gqxMl8",
        },
      ],
      insurance: [
        {
          id: "health",
          PlanName: `${t("easylifeADCPlanHealthInsurance")}`,
          plans: [
            {
              id: 1,
              planTableRow: [
                {
                  id: 1,
                  tr: "desctription",
                },
                {
                  id: 2,
                  tr: "GUARDIAN BONDHU SILVER",
                },
                {
                  id: 3,
                  tr: "GUARDIAN BONDHU GOLD ",
                },
                {
                  id: 4,
                  tr: "GUARDIAN BONDHU PLATINUM",
                },
              ],
            },
            {
              id: 2,
              planTableRow: [
                {
                  id: 1,
                  tr: "Premium",
                },
                {
                  id: 2,
                  tr: "BDT 125",
                },
                {
                  id: 3,
                  tr: "BDT 199",
                },
                {
                  id: 4,
                  tr: "BDT 299",
                },
              ],
            },
            {
              id: 3,
              planTableRow: [
                {
                  id: 1,
                  tr: "Life Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 25,000",
                },
                {
                  id: 3,
                  tr: "BDT 50,000",
                },
                {
                  id: 4,
                  tr: "BDT 50,000",
                },
              ],
            },
            {
              id: 4,
              planTableRow: [
                {
                  id: 1,
                  tr: "ADB Coverage",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "N/A",
                },
                {
                  id: 4,
                  tr: "BDT 50,000",
                },
              ],
            },
            {
              id: 5,
              planTableRow: [
                {
                  id: 1,
                  tr: "HI Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 5,000",
                },
                {
                  id: 3,
                  tr: "BDT 5,000",
                },
                {
                  id: 4,
                  tr: "BDT 10,000",
                },
              ],
            },
            {
              id: 6,
              planTableRow: [
                {
                  id: 1,
                  tr: "Telemedicine Service",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "24/7 unlimited",
                },
                {
                  id: 4,
                  tr: "24/7 unlimited",
                },
              ],
            },
            {
              id: 7,
              planTableRow: [
                {
                  id: 1,
                  tr: "Payment Mood",
                },
                {
                  id: 2,
                  tr: "Yearly",
                },
              ],
            },
            {
              id: 8,
              planTableRow: [
                {
                  id: 1,
                  tr: " Age Range",
                },
                {
                  id: 2,
                  tr: "18 to 60",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      id: 4,
      companyName: `${t("easylifeADCNagadName")}`,
      img: nagadImg,
      link: `/partnerchannels/nagad`,
      des: `${t("easylifeADCNagadDetails")}`,
      purchasePolicy: [
        {
          id: 1,
          text: `${t("easylifeADCNagadWhyPolicyDetails")}`,
        },
        {
          id: 2,
          text: `${t("easylifeADCNagadWhyPolicyL1")}`,
        },
        {
          id: 3,
          text: `${t("easylifeADCNagadWhyPolicyL2")}`,
        },
        {
          id: 4,
          text: `${t("easylifeADCNagadWhyPolicyL3")}`,
        },
        {
          id: 5,
          text: `${t("easylifeADCNagadWhyPolicyL4")}`,
        },
      ],
      howPurchase: [
        {
          id: 1,
          text: `${t("easylifeADCNagadHowPolicyDetails")}`,
        },
        {
          id: 2,
          text: `${t("easylifeADCNagadHowPolicyL1")}`,
        },
        {
          id: 3,
          text: `${t("easylifeADCNagadHowPolicyL2")}`,
        },
        {
          id: 4,
          text: `${t("easylifeADCNagadHowPolicyL3")}`,
        },
        {
          id: 5,
          text: `${t("easylifeADCNagadHowPolicyL4")}`,
        },
        {
          id: 6,
          text: `${t("easylifeADCNagadHowPolicyL5")}`,
        },
      ],
      keyUSP: [],
      videoLink: [
        {
          id: 1,
          title:
            "নগদ অ্যাপ থেকে পাওয়া যাবে গার্ডিয়ান লাইফ-এর বিভিন্ন ইন্স্যুরেন্স প্যাকেজ",
          link: "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FEasyLifeGLIL%2Fvideos%2F1222938455138172%2F&show_text=false&width=476&t=0",
        },
        {
          id: 2,
          title:
            "এখন ভবিষ্যতের পরিকল্পনা হোক নগদ-এ! অ্যাপ থেকে সুবিধামতো EasyLife-এর ইন্স্যুরেন্স পলিসি বেছে নিতে ক্লিক করুন: https://nagad.com.bd/pg/?n=insurance",
          link: "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FGLIL.bd%2Fvideos%2F1104282207091768%2F&show_text=false&width=476&t=0",
        },
      ],
      insurance: [
        {
          id: "health",
          PlanName: `${t("easylifeADCPlanHealthInsurance")}`,
          plans: [
            {
              id: 1,
              planTableRow: [
                {
                  id: 1,
                  tr: "desctription",
                },
                {
                  id: 2,
                  tr: "Guardian Bisshash",
                },
                {
                  id: 3,
                  tr: "Guardian Nirbhar",
                },
                {
                  id: 4,
                  tr: "Guardian Obhoy",
                },
                {
                  id: 5,
                  tr: "Guardian Obolombon",
                },
                {
                  id: 6,
                  tr: "Guardian Shombhabona",
                },
                {
                  id: 7,
                  tr: "Guardian Shadhinota",
                },
                {
                  id: 8,
                  tr: "Guardian Shonirbhorota",
                },
                {
                  id: 9,
                  tr: "Guardian Shokkhomota",
                },
              ],
            },
            {
              id: 2,
              planTableRow: [
                {
                  id: 1,
                  tr: "Premium",
                },
                {
                  id: 2,
                  tr: "BDT 125",
                },
                {
                  id: 3,
                  tr: "BDT 225",
                },
                {
                  id: 4,
                  tr: "BDT 349",
                },
                {
                  id: 5,
                  tr: "BDT 449",
                },
                {
                  id: 6,
                  tr: "BDT 549",
                },
                {
                  id: 7,
                  tr: "BDT 999",
                },
                {
                  id: 8,
                  tr: "BDT 1,849",
                },
                {
                  id: 9,
                  tr: "BDT 2,999",
                },
              ],
            },
            {
              id: 3,
              planTableRow: [
                {
                  id: 1,
                  tr: "Critical Illness Coverage",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "N/A",
                },
                {
                  id: 4,
                  tr: "BDT 100,000",
                },
                {
                  id: 5,
                  tr: "N/A",
                },
                {
                  id: 6,
                  tr: "N/A",
                },
                {
                  id: 7,
                  tr: "N/A",
                },
                {
                  id: 8,
                  tr: "N/A",
                },
                {
                  id: 9,
                  tr: "N/A",
                },
              ],
            },
            {
              id: 4,
              planTableRow: [
                {
                  id: 1,
                  tr: "Life Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 25,000",
                },
                {
                  id: 3,
                  tr: "BDT 50,000",
                },
                {
                  id: 4,
                  tr: "BDT 100,000",
                },
                {
                  id: 5,
                  tr: "BDT 100,000",
                },
                {
                  id: 6,
                  tr: "BDT 50,000",
                },
                {
                  id: 7,
                  tr: "BDT 100,000",
                },
                {
                  id: 8,
                  tr: "BDT 350,000",
                },
                {
                  id: 9,
                  tr: "BDT 500,000",
                },
              ],
            },
            {
              id: 5,
              planTableRow: [
                {
                  id: 1,
                  tr: "Hospicash Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 5,000",
                },
                {
                  id: 3,
                  tr: "BDT 10,000",
                },
                {
                  id: 4,
                  tr: "N/A",
                },
                {
                  id: 5,
                  tr: "BDT 20,000",
                },
                {
                  id: 6,
                  tr: "BDT 10,000",
                },
                {
                  id: 7,
                  tr: "BDT 25,000",
                },
                {
                  id: 8,
                  tr: "BDT 35,000",
                },
                {
                  id: 9,
                  tr: "BDT 50,000",
                },
              ],
            },
            {
              id: 6,
              planTableRow: [
                {
                  id: 1,
                  tr: "OPD Coverage",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "N/A",
                },
                {
                  id: 4,
                  tr: "N/A",
                },
                {
                  id: 5,
                  tr: "N/A",
                },
                {
                  id: 6,
                  tr: "BDT 2,000",
                },
                {
                  id: 7,
                  tr: "BDT 3,000",
                },
                {
                  id: 8,
                  tr: "BDT 5,000",
                },
                {
                  id: 9,
                  tr: "BDT 10,000",
                },
              ],
            },
            {
              id: 7,
              planTableRow: [
                {
                  id: 1,
                  tr: "Term",
                },
                {
                  id: 2,
                  tr: "1 Year",
                },
              ],
            },
            {
              id: 8,
              planTableRow: [
                {
                  id: 1,
                  tr: "Payment Mood",
                },
                {
                  id: 2,
                  tr: "Yearly",
                },
              ],
            },
            {
              id: 9,
              planTableRow: [
                {
                  id: 1,
                  tr: "Age",
                },
                {
                  id: 2,
                  tr: "18 to 60",
                },
              ],
            },
          ],
        },

        // {
        //   id: "life",
        //   PlanName: `${t("easylifeADCPlanLifeInsurance")}`,
        //   plans: [
        //     {
        //       id: 1,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "desctription",
        //         },
        //         {
        //           id: 2,
        //           tr: "Guardian Chaya",
        //         },
        //       ],
        //     },
        //     {
        //       id: 2,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: " Premium",
        //         },
        //         {
        //           id: 2,
        //           tr: "BDT 249",
        //         },
        //       ],
        //     },
        //     {
        //       id: 3,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "ADB Coverage",
        //         },
        //         {
        //           id: 2,
        //           tr: "BDT 100,000",
        //         },
        //       ],
        //     },
        //     {
        //       id: 4,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Life Coverage",
        //         },
        //         {
        //           id: 2,
        //           tr: "BDT 100,000",
        //         },
        //       ],
        //     },
        //     {
        //       id: 5,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Term",
        //         },
        //         {
        //           id: 2,
        //           tr: "1 Year",
        //         },
        //       ],
        //     },
        //     {
        //       id: 6,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Payment Mood",
        //         },
        //         {
        //           id: 2,
        //           tr: " Yearly",
        //         },
        //       ],
        //     },
        //     {
        //       id: 7,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Age ",
        //         },
        //         {
        //           id: 2,
        //           tr: "18 to 60 ",
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
    },

    {
      id: 5,
      companyName: `${t("easylifeADCUpayName")}`,
      img: upayImg,
      link: `/partnerchannels/upay`,
      des: `${t("easylifeADCUpayDetails")}`,
      purchasePolicy: [
        {
          id: 1,
          text: `${t("easylifeADCUpayWhyPolicyDetails")}`,
        },
        {
          id: 2,
          text: `${t("easylifeADCUpayWhyPolicyL1")}`,
        },
        {
          id: 3,
          text: `${t("easylifeADCUpayWhyPolicyL2")}`,
        },
        {
          id: 4,
          text: `${t("easylifeADCUpayWhyPolicyL3")}`,
        },
        {
          id: 5,
          text: `${t("easylifeADCUpayWhyPolicyL4")}`,
        },
        {
          id: 6,
          text: `${t("easylifeADCUpayWhyPolicyL5")}`,
        },
      ],
      howPurchase: [
        {
          id: 1,
          text: `${t("easylifeADCUpayHowPolicyDetails")}`,
        },
        {
          id: 2,
          text: `${t("easylifeADCUpayHowPolicyL1")}`,
        },
        {
          id: 3,
          text: `${t("easylifeADCUpayHowPolicyL2")}`,
        },
        {
          id: 4,
          text: `${t("easylifeADCUpayHowPolicyL3")}`,
        },
        {
          id: 5,
          text: `${t("easylifeADCUpayHowPolicyL4")}`,
        },
        {
          id: 6,
          text: `${t("easylifeADCUpayHowPolicyL5")}`,
        },
      ],
      keyUSP: [],
      videoLink: [
        {
          id: 1,
          title:
            "এখন uPay অ্যাপ থেকে সহজেই বেছে নেয়া সম্ভব পছন্দের যেকোনো পলিসি",
          link: "https://www.youtube.com/embed/VIYlzgfGS-0",
        },
      ],
      insurance: [
        {
          id: "health",
          PlanName: `${t("easylifeADCPlanHealthInsurance")}`,
          plans: [
            {
              id: 1,
              planTableRow: [
                {
                  id: 1,
                  tr: "desctription",
                },
                {
                  id: 2,
                  tr: "Guardian Bisshash",
                },
                {
                  id: 3,
                  tr: "Guardian Nirbhar",
                },
                {
                  id: 4,
                  tr: "Upay Health",
                },
                {
                  id: 5,
                  tr: "Upay Health Ultra",
                },
              ],
            },
            {
              id: 2,
              planTableRow: [
                {
                  id: 1,
                  tr: "Premium",
                },
                {
                  id: 2,
                  tr: "BDT 125",
                },
                {
                  id: 3,
                  tr: "BDT 225",
                },
                {
                  id: 4,
                  tr: "BDT 350",
                },
                {
                  id: 5,
                  tr: "BDT 375",
                },
              ],
            },
            {
              id: 3,
              planTableRow: [
                {
                  id: 1,
                  tr: "Critical Illness Coverage",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "N/A",
                },
                {
                  id: 4,
                  tr: "BDT 100,000",
                },
                {
                  id: 5,
                  tr: "N/A",
                },
              ],
            },
            {
              id: 4,
              planTableRow: [
                {
                  id: 1,
                  tr: "Life Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 25,000",
                },
                {
                  id: 3,
                  tr: "BDT 50,000",
                },
                {
                  id: 4,
                  tr: "BDT 100,000",
                },
                {
                  id: 5,
                  tr: "BDT 100,000",
                },
              ],
            },
            {
              id: 5,
              planTableRow: [
                {
                  id: 1,
                  tr: "Hospicash Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 5,000",
                },
                {
                  id: 3,
                  tr: "BDT 10,000",
                },
                {
                  id: 4,
                  tr: "N/A",
                },
                {
                  id: 5,
                  tr: "BDT 15,000",
                },
              ],
            },
            {
              id: 6,
              planTableRow: [
                {
                  id: 1,
                  tr: "Term",
                },
                {
                  id: 2,
                  tr: "1 Year",
                },
              ],
            },
            {
              id: 7,
              planTableRow: [
                {
                  id: 1,
                  tr: "Payment Mood",
                },
                {
                  id: 2,
                  tr: "Yearly",
                },
              ],
            },
            {
              id: 8,
              planTableRow: [
                {
                  id: 1,
                  tr: "Age",
                },
                {
                  id: 2,
                  tr: "18 to 60",
                },
              ],
            },
          ],
        },

        // {
        //   id: "life",
        //   PlanName: `${t("easylifeADCPlanLifeInsurance")}`,
        //   plans: [
        //     {
        //       id: 1,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "desctription",
        //         },
        //         {
        //           id: 2,
        //           tr: "Upay Life",
        //         },
        //       ],
        //     },
        //     {
        //       id: 2,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: " Premium",
        //         },
        //         {
        //           id: 2,
        //           tr: "BDT 225",
        //         },
        //       ],
        //     },
        //     {
        //       id: 3,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Life Coverage",
        //         },
        //         {
        //           id: 2,
        //           tr: "BDT 125,000",
        //         },
        //       ],
        //     },
        //     {
        //       id: 4,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Term",
        //         },
        //         {
        //           id: 2,
        //           tr: "1 Year",
        //         },
        //       ],
        //     },
        //     {
        //       id: 5,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Age ",
        //         },
        //         {
        //           id: 2,
        //           tr: "18 to 60 ",
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
    },

    {
      id: 6,
      companyName: `${t("easylifeADCOkWalletName")}`,
      img: walletImg,
      link: `/partnerchannels/ok-wallet`,
      des: `${t("easylifeADCOkWalletDetails")}`,
      purchasePolicy: [
        {
          id: 1,
          text: `${t("easylifeADCOkWalletWhyPolicyDetails")}`,
        },
        {
          id: 2,
          text: `${t("easylifeADCOkWalletWhyPolicyL1")}`,
        },
        {
          id: 3,
          text: `${t("easylifeADCOkWalletWhyPolicyL2")}`,
        },
        {
          id: 4,
          text: `${t("easylifeADCOkWalletWhyPolicyL3")}`,
        },
        {
          id: 5,
          text: `${t("easylifeADCOkWalletWhyPolicyL4")}`,
        },
        {
          id: 6,
          text: `${t("easylifeADCOkWalletWhyPolicyL5")}`,
        },
      ],
      howPurchase: [
        {
          id: 1,
          text: `${t("easylifeADCOkWalletHowPolicyDetails")}`,
        },
        {
          id: 2,
          text: `${t("easylifeADCOkWalletHowPolicyL1")}`,
        },
        {
          id: 3,
          text: `${t("easylifeADCOkWalletHowPolicyL2")}`,
        },
        {
          id: 4,
          text: `${t("easylifeADCOkWalletHowPolicyL3")}`,
        },
        {
          id: 5,
          text: `${t("easylifeADCOkWalletHowPolicyL4")}`,
        },
        {
          id: 6,
          text: `${t("easylifeADCOkWalletHowPolicyL5")}`,
        },
      ],
      keyUSP: [],
      videoLink: [
        {
          id: 1,
          title: "Easylife insurance plans with Ok wallet app",
          link: "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FGLIL.bd%2Fvideos%2F290373532763352%2F&show_text=false&width=476&t=0",
        },
      ],
      insurance: [
        {
          id: "health",
          PlanName: `${t("easylifeADCPlanHealthInsurance")}`,
          plans: [
            {
              id: 1,
              planTableRow: [
                {
                  id: 1,
                  tr: "desctription",
                },
                {
                  id: 2,
                  tr: "Guardian Bisshash",
                },
                {
                  id: 3,
                  tr: "Guardian Nirbhar",
                },
                {
                  id: 4,
                  tr: "Ok Health",
                },
                {
                  id: 5,
                  tr: "Ok Health Ultra",
                },
                {
                  id: 6,
                  tr: "Guardian Life Health Plus",
                },
              ],
            },
            {
              id: 2,
              planTableRow: [
                {
                  id: 1,
                  tr: "Premium",
                },
                {
                  id: 2,
                  tr: "BDT 125",
                },
                {
                  id: 3,
                  tr: "BDT 225",
                },
                {
                  id: 4,
                  tr: "BDT 350",
                },
                {
                  id: 5,
                  tr: "BDT 400",
                },
                {
                  id: 6,
                  tr: "BDT 500",
                },
              ],
            },
            {
              id: 3,
              planTableRow: [
                {
                  id: 1,
                  tr: "Critical Illness Coverage",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "N/A",
                },
                {
                  id: 4,
                  tr: "BDT 100,000",
                },
                {
                  id: 5,
                  tr: "N/A",
                },
                {
                  id: 6,
                  tr: "N/A",
                },
              ],
            },
            {
              id: 4,
              planTableRow: [
                {
                  id: 1,
                  tr: "Life Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 25,000",
                },
                {
                  id: 3,
                  tr: "BDT 50,000",
                },
                {
                  id: 4,
                  tr: "BDT 100,000",
                },
                {
                  id: 5,
                  tr: "BDT 150,000",
                },
                {
                  id: 6,
                  tr: "BDT 100,000",
                },
              ],
            },
            {
              id: 5,
              planTableRow: [
                {
                  id: 1,
                  tr: "Hospital Cashback Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 5,000",
                },
                {
                  id: 3,
                  tr: "BDT 10,000",
                },
                {
                  id: 4,
                  tr: "N/A",
                },
                {
                  id: 5,
                  tr: "BDT 10,000",
                },
                {
                  id: 6,
                  tr: "BDT 10,000",
                },
              ],
            },
            {
              id: 6,
              planTableRow: [
                {
                  id: 1,
                  tr: "OPD Coverage",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "N/A",
                },
                {
                  id: 4,
                  tr: "N/A",
                },
                {
                  id: 5,
                  tr: "N/A",
                },
                {
                  id: 6,
                  tr: "BDT 1,000",
                },
              ],
            },
            {
              id: 7,
              planTableRow: [
                {
                  id: 1,
                  tr: "Policy Type",
                },
                {
                  id: 2,
                  tr: "Term Life",
                },
              ],
            },
            {
              id: 8,
              planTableRow: [
                {
                  id: 1,
                  tr: "Payment Mood",
                },
                {
                  id: 2,
                  tr: "Yearly",
                },
              ],
            },
            {
              id: 9,
              planTableRow: [
                {
                  id: 1,
                  tr: "Age",
                },
                {
                  id: 2,
                  tr: "18 to 60",
                },
              ],
            },
          ],
        },

        // {
        //   id: "life",
        //   PlanName: `${t("easylifeADCPlanLifeInsurance")}`,
        //   plans: [
        //     {
        //       id: 1,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "desctription",
        //         },
        //         {
        //           id: 2,
        //           tr: "Guardian Life Insured",
        //         },
        //         {
        //           id: 3,
        //           tr: "Guardian Life Insured Ultra",
        //         },
        //       ],
        //     },
        //     {
        //       id: 2,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: " Premium",
        //         },
        //         {
        //           id: 2,
        //           tr: "BDT 225",
        //         },
        //         {
        //           id: 3,
        //           tr: "BDT 250",
        //         },
        //       ],
        //     },
        //     {
        //       id: 3,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Life Coverage",
        //         },
        //         {
        //           id: 2,
        //           tr: "BDT 125,000",
        //         },
        //         {
        //           id: 3,
        //           tr: "BDT 100,000",
        //         },
        //       ],
        //     },
        //     {
        //       id: 4,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "ADB Coverage",
        //         },
        //         {
        //           id: 2,
        //           tr: "N/A",
        //         },
        //         {
        //           id: 3,
        //           tr: "BDT 100,000",
        //         },
        //       ],
        //     },
        //     {
        //       id: 5,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Policy Type",
        //         },
        //         {
        //           id: 2,
        //           tr: "Term Life",
        //         },
        //       ],
        //     },
        //     {
        //       id: 6,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Payment Mode",
        //         },
        //         {
        //           id: 2,
        //           tr: "Yearly",
        //         },
        //       ],
        //     },
        //     {
        //       id: 7,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Age Range",
        //         },
        //         {
        //           id: 2,
        //           tr: "18 to 60 ",
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
    },

    {
      id: 7,
      img: carnivalImg,
      companyName: `${t("easylifeADCCarnivalName")}`,
      link: `/partnerchannels/carnival`,
      des: `${t("easylifeADCCarnivalDetails")}`,
      purchasePolicy: [
        {
          id: 1,
          text: `${t("easylifeADCCarnivalWhyPolicyDetails")}`,
        },
        {
          id: 2,
          text: `${t("easylifeADCCarnivalWhyPolicyL1")}`,
        },
        {
          id: 3,
          text: `${t("easylifeADCCarnivalWhyPolicyL2")}`,
        },
        {
          id: 4,
          text: `${t("easylifeADCCarnivalWhyPolicyL3")}`,
        },
        {
          id: 5,
          text: `${t("easylifeADCCarnivalWhyPolicyL4")}`,
        },
        {
          id: 6,
          text: `${t("easylifeADCCarnivalWhyPolicyL5")}`,
        },
      ],
      howPurchase: [
        {
          id: 1,
          text: `${t("easylifeADCCarnivalHowPolicyDetails")}`,
        },
        {
          id: 2,
          text: `${t("easylifeADCCarnivalHowPolicyL1")}`,
        },
        {
          id: 3,
          text: `${t("easylifeADCCarnivalHowPolicyL2")}`,
        },
        {
          id: 4,
          text: `${t("easylifeADCCarnivalHowPolicyL3")}`,
        },
      ],
      keyUSP: [],
      videoLink: [
        {
          id: 1,
          title:
            "EasyLife-এর সাথে নিজের ও পরিবারের স্বাস্থ্য সুরক্ষা এখন আরও সহজ Carnival Assure এ",
          link: "https://www.youtube.com/embed/28gxRA9XCh8",
        },
      ],
      insurance: [
        {
          id: "health",
          PlanName: `${t("easylifeADCPlanHealthInsurance")}`,
          plans: [
            {
              id: 1,
              planTableRow: [
                {
                  id: 1,
                  tr: "desctription",
                },
                {
                  id: 2,
                  tr: "Guardian Life Starter",
                },
                {
                  id: 3,
                  tr: "Guardian Life Booster",
                },
                {
                  id: 4,
                  tr: "Guardian Life Supreme",
                },
              ],
            },
            {
              id: 2,
              planTableRow: [
                {
                  id: 1,
                  tr: "Premium",
                },
                {
                  id: 2,
                  tr: "BDT 350",
                },
                {
                  id: 3,
                  tr: "BDT 1,850",
                },
                {
                  id: 4,
                  tr: "BDT 3,000",
                },
              ],
            },
            {
              id: 3,
              planTableRow: [
                {
                  id: 1,
                  tr: "Life Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 100,000",
                },
                {
                  id: 3,
                  tr: "BDT 350,000",
                },
                {
                  id: 4,
                  tr: "BDT 500,000",
                },
              ],
            },
            {
              id: 4,
              planTableRow: [
                {
                  id: 1,
                  tr: "Critical Illness Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 100,000",
                },
                {
                  id: 3,
                  tr: "N/A",
                },
                {
                  id: 4,
                  tr: "N/A",
                },
              ],
            },
            {
              id: 5,
              planTableRow: [
                {
                  id: 1,
                  tr: "Hospital Cashback Benefit",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "BDT 35,000",
                },
                {
                  id: 4,
                  tr: "BDT 50,000",
                },
              ],
            },
            {
              id: 6,
              planTableRow: [
                {
                  id: 1,
                  tr: "OPD Coverage",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "BDT 5,000",
                },
                {
                  id: 4,
                  tr: "BDT 10,000",
                },
              ],
            },
            {
              id: 7,
              planTableRow: [
                {
                  id: 1,
                  tr: "Term",
                },
                {
                  id: 2,
                  tr: " 1 Year",
                },
              ],
            },
            {
              id: 8,
              planTableRow: [
                {
                  id: 1,
                  tr: "Payment Mood",
                },
                {
                  id: 2,
                  tr: "Yearly",
                },
              ],
            },
            {
              id: 9,
              planTableRow: [
                {
                  id: 1,
                  tr: " Age Range",
                },
                {
                  id: 2,
                  tr: "18 to 60",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      id: 8,
      companyName: `${t("easylifeADCJotnoName")}`,
      img: jotnoImg,
      link: `/partnerchannels/jotno`,
      des: `${t("easylifeADCJotnoDetails")}`,
      purchasePolicy: [],
      howPurchase: [],
      keyUSP: [],
      videoLink: [
        {
          id: 1,
          title: "এখন ইন্স্যুরেন্স পলিসি কেনা যাবে মুহূর্তেই!",
          link: "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FEasyLifeGLIL%2Fvideos%2F1196359717828228%2F&show_text=false&width=476&t=0",
        },
      ],
      insurance: [
        {
          id: "health",
          PlanName: `${t("easylifeADCPlanHealthInsurance")}`,
          plans: [
            {
              id: 1,
              planTableRow: [
                {
                  id: 1,
                  tr: "desctription",
                },
                {
                  id: 2,
                  tr: "Guardian Affirmation",
                },
                {
                  id: 3,
                  tr: "Guardian Assure",
                },
                {
                  id: 4,
                  tr: "Guardian Assure Plus",
                },
                {
                  id: 5,
                  tr: "Guardian Secure",
                },
                {
                  id: 6,
                  tr: "Guardian Ensure",
                },
                {
                  id: 7,
                  tr: "Guardian Ensure Plus",
                },
                {
                  id: 8,
                  tr: "Guardian Promise",
                },
              ],
            },
            {
              id: 2,
              planTableRow: [
                {
                  id: 1,
                  tr: "Premium",
                },
                {
                  id: 2,
                  tr: "BDT 499",
                },
                {
                  id: 3,
                  tr: "BDT 149",
                },
                {
                  id: 4,
                  tr: "BDT 349",
                },
                {
                  id: 5,
                  tr: "BDT 599",
                },
                {
                  id: 6,
                  tr: "BDT 499",
                },
                {
                  id: 7,
                  tr: "BDT 799",
                },
                {
                  id: 8,
                  tr: "BDT 1549",
                },
              ],
            },
            {
              id: 3,
              planTableRow: [
                {
                  id: 1,
                  tr: "Critical Illness Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 150,000",
                },
                {
                  id: 3,
                  tr: "N/A",
                },
                {
                  id: 4,
                  tr: "N/A",
                },
                {
                  id: 5,
                  tr: "N/A",
                },
                {
                  id: 6,
                  tr: "N/A",
                },
                {
                  id: 7,
                  tr: "N/A",
                },
                {
                  id: 8,
                  tr: "N/A",
                },
              ],
            },
            {
              id: 4,
              planTableRow: [
                {
                  id: 1,
                  tr: "Life Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 150,000",
                },
                {
                  id: 3,
                  tr: "BDT 10,000",
                },
                {
                  id: 4,
                  tr: "BDT 25,000",
                },
                {
                  id: 5,
                  tr: "BDT 50,000",
                },
                {
                  id: 6,
                  tr: "BDT 10,000",
                },
                {
                  id: 7,
                  tr: "BDT 25,000",
                },
                {
                  id: 8,
                  tr: "BDT 50,000",
                },
              ],
            },
            {
              id: 5,
              planTableRow: [
                {
                  id: 1,
                  tr: "Hospital Cashback Coverage",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "BDT 10,000",
                },
                {
                  id: 4,
                  tr: "BDT 30,000",
                },
                {
                  id: 5,
                  tr: "BDT 50,000",
                },
                {
                  id: 6,
                  tr: "N/A",
                },
                {
                  id: 7,
                  tr: "N/A",
                },
                {
                  id: 8,
                  tr: "N/A",
                },
              ],
            },
            {
              id: 6,
              planTableRow: [
                {
                  id: 1,
                  tr: "OPD Coverage",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "N/A",
                },
                {
                  id: 4,
                  tr: "N/A",
                },
                {
                  id: 5,
                  tr: "N/A",
                },
                {
                  id: 6,
                  tr: "BDT 3,000",
                },
                {
                  id: 7,
                  tr: "BDT 5,000",
                },
                {
                  id: 8,
                  tr: "BDT 10,000",
                },
              ],
            },
            {
              id: 7,
              planTableRow: [
                {
                  id: 1,
                  tr: "Term",
                },
                {
                  id: 2,
                  tr: "1 Year",
                },
              ],
            },
            {
              id: 8,
              planTableRow: [
                {
                  id: 1,
                  tr: "Payment Mood",
                },
                {
                  id: 2,
                  tr: "Yearly",
                },
              ],
            },
            {
              id: 9,
              planTableRow: [
                {
                  id: 1,
                  tr: "Age",
                },
                {
                  id: 2,
                  tr: "18 to 60",
                },
              ],
            },
          ],
        },

        // {
        //   id: "life",
        //   PlanName: `${t("easylifeADCPlanLifeInsurance")}`,
        //   plans: [
        //     {
        //       id: 1,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "desctription",
        //         },
        //         {
        //           id: 2,
        //           tr: "Guardian Safety",
        //         },
        //       ],
        //     },
        //     {
        //       id: 2,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: " Premium",
        //         },
        //         {
        //           id: 2,
        //           tr: "BDT 249",
        //         },
        //       ],
        //     },
        //     {
        //       id: 3,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Life Coverage",
        //         },
        //         {
        //           id: 2,
        //           tr: "BDT 100,000",
        //         },
        //       ],
        //     },
        //     {
        //       id: 4,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "ADB Coverage",
        //         },

        //         {
        //           id: 2,
        //           tr: "BDT 100,000",
        //         },
        //       ],
        //     },
        //     {
        //       id: 5,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Term",
        //         },
        //         {
        //           id: 2,
        //           tr: "1 Year",
        //         },
        //       ],
        //     },
        //     {
        //       id: 6,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Payment Mode",
        //         },
        //         {
        //           id: 2,
        //           tr: "Yearly",
        //         },
        //       ],
        //     },
        //     {
        //       id: 7,
        //       planTableRow: [
        //         {
        //           id: 1,
        //           tr: "Age Range",
        //         },
        //         {
        //           id: 2,
        //           tr: "18 to 60 ",
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
    },

    {
      id: 9,
      img: robishopImg,
      companyName: `${t("easylifeADCRobiShopName")}`,
      link: `/partnerchannels/robishop`,
      des: `${t("easylifeADCRobiShopDetails")}`,
      purchasePolicy: [],
      howPurchase: [],
      keyUSP: [],
      videoLink: [
        {
          id: 1,
          title: "Life Insurance @robishop",
          link: "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FEasyLifeGLIL%2Fvideos%2F1105449426715056%2F&show_text=false&width=476&t=0",
        },
      ],
      insurance: [
        {
          id: "health",
          PlanName: `${t("easylifeADCPlanHealthInsurance")}`,
          plans: [
            {
              id: 1,
              planTableRow: [
                {
                  id: 1,
                  tr: "desctription",
                },
                {
                  id: 2,
                  tr: "Guardian Bisshash",
                },
                {
                  id: 3,
                  tr: "Guardian Nirbhar",
                },
                {
                  id: 4,
                  tr: "Guardian Shuchona",
                },
                {
                  id: 5,
                  tr: "Guardian Jotno",
                },
                {
                  id: 6,
                  tr: "Guardian Nishchinto",
                },
                {
                  id: 7,
                  tr: "Guardian Purnota",
                },
              ],
            },
            {
              id: 2,
              planTableRow: [
                {
                  id: 1,
                  tr: "Premium",
                },
                {
                  id: 2,
                  tr: "BDT 125",
                },
                {
                  id: 3,
                  tr: "BDT 225",
                },
                {
                  id: 4,
                  tr: "BDT 225",
                },
                {
                  id: 5,
                  tr: "BDT 350",
                },
                {
                  id: 6,
                  tr: "BDT 500",
                },
                {
                  id: 7,
                  tr: "BDT 750",
                },
              ],
            },
            {
              id: 3,
              planTableRow: [
                {
                  id: 1,
                  tr: "Life Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 25,000",
                },
                {
                  id: 3,
                  tr: "BDT 50,000",
                },
                {
                  id: 4,
                  tr: "BDT 125,000",
                },
                {
                  id: 5,
                  tr: "BDT 100,000",
                },
                {
                  id: 6,
                  tr: "BDT 150,000",
                },
                {
                  id: 7,
                  tr: "BDT 50,000",
                },
              ],
            },
            {
              id: 4,
              planTableRow: [
                {
                  id: 1,
                  tr: "Critical Illness Coverage",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "N/A",
                },
                {
                  id: 4,
                  tr: "N/A",
                },
                {
                  id: 5,
                  tr: "BDT 100,000",
                },
                {
                  id: 6,
                  tr: "N/A",
                },
                {
                  id: 7,
                  tr: "N/A",
                },
              ],
            },
            {
              id: 5,
              planTableRow: [
                {
                  id: 1,
                  tr: "HI Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 5,000",
                },
                {
                  id: 3,
                  tr: "BDT 10,000",
                },
                {
                  id: 4,
                  tr: "N/A",
                },
                {
                  id: 5,
                  tr: "N/A",
                },
                {
                  id: 6,
                  tr: "BDT 10,000",
                },
                {
                  id: 7,
                  tr: "BDT 10,000",
                },
              ],
            },
            {
              id: 6,
              planTableRow: [
                {
                  id: 1,
                  tr: "OPD Coverage",
                },
                {
                  id: 2,
                  tr: "N/A",
                },
                {
                  id: 3,
                  tr: "N/A",
                },
                {
                  id: 4,
                  tr: "N/A",
                },
                {
                  id: 5,
                  tr: "N/A",
                },
                {
                  id: 6,
                  tr: "N/A",
                },
                {
                  id: 7,
                  tr: "BDT 2,000",
                },
              ],
            },
            {
              id: 7,
              planTableRow: [
                {
                  id: 1,
                  tr: "Payment Mood",
                },
                {
                  id: 2,
                  tr: "Yearly",
                },
              ],
            },
            {
              id: 8,
              planTableRow: [
                {
                  id: 1,
                  tr: " Age Range",
                },
                {
                  id: 2,
                  tr: "18 to 60",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      id: 10,
      companyName: `${t("easylifeADCTRuckLagbeName")}`,
      img: truckImg,
      link: `/partnerchannels/truck-lagbe`,
      des: `${t("easylifeADCTRuckLagbeDetails")}`,
      purchasePolicy: [
        {
          id: 1,
          text: `${t("easylifeADCTRuckLagbeWhyPolicyDetails")}`,
        },
        {
          id: 2,
          text: `${t("easylifeADCTRuckLagbeWhyPolicyL1")}`,
        },
        {
          id: 3,
          text: `${t("easylifeADCTRuckLagbeWhyPolicyL2")}`,
        },
        {
          id: 4,
          text: `${t("easylifeADCTRuckLagbeWhyPolicyL3")}`,
        },
        {
          id: 5,
          text: `${t("easylifeADCTRuckLagbeWhyPolicyL4")}`,
        },
        {
          id: 6,
          text: `${t("easylifeADCTRuckLagbeWhyPolicyL5")}`,
        },
      ],
      howPurchase: [
        {
          id: 1,
          text: `${t("easylifeADCTRuckLagbeHowPolicyDetails")}`,
        },
        {
          id: 2,
          text: `${t("easylifeADCTRuckLagbeHowPolicyL1")}`,
        },
        {
          id: 3,
          text: `${t("easylifeADCTRuckLagbeHowPolicyL2")}`,
        },
        {
          id: 4,
          text: `${t("easylifeADCTRuckLagbeHowPolicyL3")}`,
        },
        {
          id: 5,
          text: `${t("easylifeADCTRuckLagbeHowPolicyL4")}`,
        },
        {
          id: 6,
          text: `${t("easylifeADCTRuckLagbeHowPolicyL5")}`,
        },
      ],
      keyUSP: [],
      videoLink: [
        {
          id: 1,
          title:
            "ট্রাক ড্রাইভার ভাইদের জন্য প্রথমবারের মত সুলভ মূল্যে লাইফ ইনস্যুরেন্স সুবিধা নিয়ে এসেছে ‘ট্রাক লাগবে’!",
          link: "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FTruckLagbe%2Fphotos%2Fa.795252963967954%2F1950788261747746%2F%3Ftype%3D3&show_text=true&width=500",
        },
      ],
      insurance: [
        {
          id: "life",
          PlanName: `${t("easylifeADCPlanLifeInsurance")}`,
          plans: [
            {
              id: 1,
              planTableRow: [
                {
                  id: 1,
                  tr: "desctription",
                },
                {
                  id: 2,
                  tr: "Nirapod",
                },
              ],
            },
            {
              id: 2,
              planTableRow: [
                {
                  id: 1,
                  tr: " Premium",
                },
                {
                  id: 2,
                  tr: "BDT 400",
                },
              ],
            },
            {
              id: 3,
              planTableRow: [
                {
                  id: 1,
                  tr: "Life Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 100,000",
                },
              ],
            },
            {
              id: 4,
              planTableRow: [
                {
                  id: 1,
                  tr: "ADB Coverage",
                },

                {
                  id: 2,
                  tr: "BDT 100,000",
                },
              ],
            },
            {
              id: 5,
              planTableRow: [
                {
                  id: 1,
                  tr: "Total & Permanent Disability Coverage",
                },
                {
                  id: 2,
                  tr: "BDT 100,000",
                },
              ],
            },
            {
              id: 6,
              planTableRow: [
                {
                  id: 1,
                  tr: "Policy Type",
                },
                {
                  id: 2,
                  tr: "Term Life",
                },
              ],
            },
            {
              id: 7,
              planTableRow: [
                {
                  id: 1,
                  tr: "Payment Mode",
                },
                {
                  id: 2,
                  tr: "Yearly",
                },
              ],
            },
            {
              id: 8,
              planTableRow: [
                {
                  id: 1,
                  tr: "Age Range",
                },
                {
                  id: 2,
                  tr: "18 to 60 ",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    getAllADCData();
  }, []);

  function getAllADCData() {
    const url = `${process.env.REACT_APP_host}/api/v1/adc`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setAllADC(data?.data);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className={`service-container ${allADC.length === 0 && 'pt-0'}`}>
      <div className="body-container secondary-container">
        <div className="adc-partner-grid">
          {allADC?.map((adc) => (
            <Link
              to={`/partnerchannels/${adc?.slug}`}
              key={adc?._id}
              className="border-0 bg-transparent adc-card"
              style={{ cursor: "pointer" }}
            >
              <div className="adc-card-img">
                <img src={adc?.partnerLogo} alt="partner" />
              </div>
              <div className="adc-card-details mt-3">
                <h6>{banglatranslate === 'bn' ? adc?.partnerNameBn : adc?.partnerName}</h6>
                <ul>
                  {
                    adc?.healthTitle &&
                    <li>
                      <div className="corporate-coverage-list">
                        <div className="corporate-coverage-check">
                          <FaCheckCircle />
                        </div>
                        <p style={{ fontSize: "14px" }}>{banglatranslate === 'bn' ? adc?.healthTitleBn : adc?.healthTitle}</p>
                      </div>
                    </li>
                  }
                  {
                    adc?.lifeTitle &&
                    <li>
                      <div className="corporate-coverage-list">
                        <div className="corporate-coverage-check">
                          <FaCheckCircle />
                        </div>
                        <p style={{ fontSize: "14px" }}>{banglatranslate === 'bn' ? adc?.lifeTitleBn : adc?.lifeTitle}</p>
                      </div>
                    </li>
                  }
                </ul>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ADCpartner;
