import React, { useEffect, useRef, useState } from 'react';
import Loading from '../../../components/Loading/Loading';
import { FaStepBackward } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DashboardNoticeBoardList from './DashboardNoticeBoardList';
import DashboardNoticeBoardEdit from './DashboardNoticeBoardEdit';

function DashboardNoticeBoardView() {
    const [notice, setNotice] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editModal, setEditModal] = useState(false);
    const [updatenotice, setUpdatenotice] = useState(null);

    useEffect(() => {
        getPaginationList();
    }, []);

    const handleEditClose = () => setEditModal(false);
    const handleEditShow = () => setEditModal(true);

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/notice`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setNotice(data?.data?.noticeBoardList);
                setIsLoading(false);
            });
    }

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }
    return (
        <div className="home-content">
            <div class="d-flex justify-content-between gap-2 align-items-center">
                <h1 className="dashboard-title">All Notice</h1>
                <div>
                    <Link
                        to="/dashboard/notice"
                        className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                    >
                        <FaStepBackward />
                        <span>Back</span>
                    </Link>
                </div>
            </div>

            <div className="list-contaner">
                {notice?.length === 0 ? (
                    <div className="min-vh-100 d-flex align-items-center justify-content-center">
                        No Notice Is Available
                    </div>
                ) : (
                    <>
                        <div className="table-responsive">
                            <table className="table table-light table-bordered">
                                <thead>
                                    <tr className="table-tr">
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            SL.NO.
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Notice Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Notice Name(bangla)
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Notice Link
                                        </th>

                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {notice?.map((noticeData, i) => (
                                        <DashboardNoticeBoardList
                                            key={noticeData._id}
                                            noticeData={noticeData}
                                            idx={i}
                                            handleEditShow={handleEditShow}
                                            setUpdatenotice={setUpdatenotice}
                                            getPaginationList={
                                                getPaginationList
                                            }
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}

                <DashboardNoticeBoardEdit
                    handleEditClose={handleEditClose}
                    editModal={editModal}
                    updatenotice={updatenotice}
                    getPaginationList={getPaginationList}
                    setUpdatenotice={setUpdatenotice}
                />
            </div>
        </div>
    );
}

export default DashboardNoticeBoardView;
