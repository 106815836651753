import React from "react";
import "./plans.css";

function Plans() {
  return (
    <div className="home-content min-vh-100 d-flex align-items-center justify-content-center ">
      <h1 className="plans-contents"> Setup A plans </h1>
    </div>
  );
}

export default Plans;
