import React from 'react';
import { FaStepBackward } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

const DashboardImageGallerySinglePhoto = () => {
    const { state: data } = useLocation();
    return (
        <div className="home-content">
            <div className="">
                <div class="d-flex justify-content-between gap-2 align-items-center">
                    <h1 className="dashboard-title">{data?.title}</h1>
                    <div>
                        <Link
                            to="/dashboard/media/view-photo"
                            className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                        >
                            <FaStepBackward />
                            <span>Back</span>
                        </Link>
                    </div>
                </div>
                <div className="section-margin-top gallery-image-grid">
                    {
                        data?.imageURL?.map((img, i) => <img src={img} alt='photoGallery' />)
                    }
                </div>
            </div>
        </div>
    );
};

export default DashboardImageGallerySinglePhoto;