import React from "react";
import MilestoneBanner from "../MilestoneBanner/MilestoneBanner";
import MilestoneTree from "../MilestoneTree/MilestoneTree";
import MilestonesRoad from "../MilestonesRoad/MilestonesRoad";
import useTitle from "../../../hooks/useTitle";

function Milestone() {
  useTitle("Milestone");

  return (
    <>
      <MilestoneBanner />
      <MilestonesRoad />
      {/* <MilestoneTree /> */}
    </>
  );
}

export default Milestone;
