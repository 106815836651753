import React from "react";
import "./ourPlansCardDetails.css";
import Buttons from "../Buttons/Buttons";
import { Link } from "react-router-dom";
import CheckItems from "../CheckItems/CheckItems";

function OurPlansCardDetails({ bannerItem, classes, btnName, imgBackground }) {
  const { textDesc } = bannerItem;
  return (
    <>
      <div className="right-img">
        <img src={bannerItem?.img} alt="" style={{ background: `${imgBackground}` }} />
      </div>
      <div className="left-content">
        <h1 className="title banner-title">{bannerItem?.title}</h1>
        <p className="text banner-text">{bannerItem?.desc}</p>
        {textDesc && <CheckItems textDesc={textDesc} />}

        {classes && btnName && (
          <div className="card-button">
            <Link to="/sub-catagory">
              <Buttons classes={classes}>{btnName}</Buttons>
            </Link>
          </div>
        )}
      </div>
    </>
  );
}

export default OurPlansCardDetails;
