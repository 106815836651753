import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { FaStepBackward } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loading from "../../../../components/Loading/Loading";
import { useState } from "react";
import DasboardImageAllGallery from "./DasboardImageAllGallery";
import ReactPaginate from "react-paginate";

function DashboardImageGalleryView() {
  const [newAllGalleryPhotos, AllGalleryPhoto] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const currentPage = useRef();

  useEffect(() => {
    currentPage.current = 1;
    getPaginationList();
  }, []);

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  // pagination
  function handlePageClick(e) {
    currentPage.current = e.selected + 1;
    getPaginationList();
  }

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/media-photo?page=${currentPage.current}&limit=${limit}`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data?.data?.page);
        AllGalleryPhoto(data?.data?.photoList);
        setIsLoading(false);
      });
  }
  return (
    <div className="home-content">
      <div class="d-flex justify-content-between gap-2 align-items-center">
        <h1 className="dashboard-title">All Album</h1>
        <div>
          <Link
            to="/dashboard/media/photo"
            className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
          >
            <FaStepBackward />
            <span>Back</span>
          </Link>
        </div>
      </div>
      <div className="blog-content mt-5">
        {newAllGalleryPhotos?.map((data) => (
          <DasboardImageAllGallery
            data={data}
            key={data.id}
            getPaginationList={getPaginationList}
          />
        ))}
      </div>

      <div className="mt-4">
        <ReactPaginate
          breakLabel="......."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          marginPagesDisplayed={2}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
        />
      </div>
    </div>
  );
}

export default DashboardImageGalleryView;
