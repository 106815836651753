import React from "react";
import { BsStarFill } from "react-icons/bs";
import LeftQuotation from "../../../assets/image/left-quotation.png";
import RightQuotation from "../../../assets/image/right-quotation.png";

function ClinentReviewCard({ clientReview }) {
  const { clientName, iconStar, img, destination, desc } = clientReview;
  return (
    <div className="review-card">
      <div className="client-text-review">
        <div className="quotation-img">
          <img src={LeftQuotation} alt="" />
        </div>
        <p className="review-text my-2">{desc}</p>

        <div className="quotation-img right-quotation-img">
          <img src={RightQuotation} alt="" />
        </div>
        <div className="client-img">
          <img src={img} alt="" />
        </div>
      </div>

      <div className="clinet-info">
        <div className="d-flex align-items-center gap-2 justify-content-center mb-2">
          {iconStar.map((starReviewIcon) => (
            <BsStarFill key={starReviewIcon.id} className="star-icon" />
          ))}
        </div>
        <h5>{clientName}</h5>
        <p>{destination}</p>
      </div>
    </div>
  );
}

export default ClinentReviewCard;
