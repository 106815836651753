import React from 'react';
import OurPlansTitle from '../../CatagoryPlans/OurPlans/OurPlansTitle';
import directorsImg from '../../../assets/image/boardDerictorsImg.png';
import './boardDirectors.css';
import ListCard from '../../../components/ListCard/ListCard';
import { useRef } from 'react';
import { useEffect } from 'react';
import Loading from '../../../components/Loading/Loading';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import ListCard2 from '../../../components/ListCard/ListCard2';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

function BoardDirectorsLinst() {
    const [newAllDirectors, setAllDirectors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState(5);
    const [pageCount, setPageCount] = useState(8);
    const [banglatranslate, setBanglaTraslate] = useState();

    const currentPage = useRef();

    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    // pagination
    function handlePageClick(e) {
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/board-of-director`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllDirectors(data?.data?.boardOfDirectorListLists);
                setIsLoading(false);
            });
    }

    return (
        <div className="body-container secondary-container message-contaner banner-container">
            <div>
                <h1 className=" title text-center list-titles">
                    {t('boardOfDirectorTitle')}
                </h1>
            </div>

            {newAllDirectors?.length === 0 ? (
                <div className="min-vh-100 d-flex align-items-center justify-content-center">
                    No Management Director Is Available
                </div>
            ) : (
                <>
                    <div className="grid-list">
                        {newAllDirectors.map((newTeam) => (
                            <ListCard2
                                newTeam={newTeam}
                                key={newTeam._id}
                                banglatranslate={banglatranslate}
                            />
                        ))}
                    </div>
                    {/* <div className="" style={{ marginTop: "6rem" }}>
            <ReactPaginate
              breakLabel="......."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            />
          </div> */}
                </>
            )}
        </div>
    );
}

export default BoardDirectorsLinst;
