import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import WhyGuardianLife from '../WhyGuardianLife/WhyGuardianLife';
import BancassuranceBenefitWeOffer from '../BancassuranceBenefitWeOffer/BancassuranceBenefitWeOffer';
import BancassuranceBenefits from '../BancassuranceBenefits/BancassuranceBenefits';
import BancassurancePartnerLogo from '../BancassurancePartnerLogo/BancassurancePartnerLogo';

function BancassuranceSubmenu() {
    const { t } = useTranslation();
    useEffect(() => {
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);
    const mediaCornerSubmenus = [
        { submenuId: 1, submenuName: `${t('Why Guardian Life')}` },
        { submenuId: 2, submenuName: `${t('Services')}` },
        { submenuId: 3, submenuName: `${t('Benefits')}` },
        { submenuId: 4, submenuName: `${t('Partners')}` },
    ];
    return (
        <div className="service-container pt-5">
            <div className="body-container secondary-container">
                <div>
                    <section
                        className="plans-submenu1"
                        style={{ marginBottom: '0rem' }}
                    >
                        {mediaCornerSubmenus.map((submenu) => (
                            <a
                                href={`#${submenu?.submenuId}`}
                                key={submenu?.submenuId}
                                className="default-submenu"
                            >
                                {submenu?.submenuName}
                            </a>
                        ))}
                    </section>

                    <section>
                        <div id={1}>
                            <WhyGuardianLife />
                        </div>
                        <div id={2}>
                            <BancassuranceBenefitWeOffer />
                        </div>
                        <div id={3}>
                            <BancassuranceBenefits />
                        </div>
                        <div id={4}>
                            <BancassurancePartnerLogo />
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default BancassuranceSubmenu;
