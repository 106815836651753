import React from "react";
import { Modal } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";

const officeCategorys = [
  {
    id: 1,
    officeCategory: "Area Office",
  },
  {
    id: 2,
    officeCategory: "Branch Office",
  },
  {
    id: 3,
    officeCategory: "Regional Office",
  },
];

function DashboardLoacateBranchEditModal({
  show,
  handleClose,
  getBranchList,
  updateBranch,
  setUpdateBranch,
}) {
  // const [officeCategory, setOfficeCategory] = useState(
  //   updateBranch?.officeCategory
  // );
  const [area, setArea] = useState(updateBranch?.area);
  const [officeName, setOfficeName] = useState(updateBranch?.officeName);
  const [address, setAddress] = useState(updateBranch?.address);
  const [contact, setContact] = useState(updateBranch?.contactPerson);
  const [mapLink, setMapLink] = useState(updateBranch?.mapLink);

  const editor = useRef(null);

  const handleChange = (e) => {
    setUpdateBranch({ ...updateBranch, [e.target.name]: e.target.value });
  };

  // console.log(area);

  const handleupdateOfficeBranch = (e) => {
    e.preventDefault();
    handleClose();

    const updateOfficeBranch = {
      officeCategory: updateBranch.officeCategory,
      area: updateBranch?.area,
      officeName: updateBranch?.officeName,
      address: updateBranch?.address,
      contactPerson: contact,
      mapLink: updateBranch?.mapLink,
    };

    console.log(updateOfficeBranch);

    fetch(
      `${process.env.REACT_APP_host}/api/v1/all-branch/${updateBranch?._id}`,
      {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(updateOfficeBranch),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          getBranchList();
          console.log(data);
          toast.success("Branch updated successfully!");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Branch is not updated!");
      });
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      scrollable={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          Update Locate Branch
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className=" form-body">
        <form onSubmit={handleupdateOfficeBranch}>
          <div className="mb-3">
            <label className="form-label  fw-bold">Office Category</label>
            <select
              name="officeCategory"
              className="form-select"
              onChange={handleChange}
            >
              <option value="">Choose office category</option>

              {officeCategorys?.map((officeCate) => (
                <option value={officeCate?.officeCategory} key={officeCate.id}>
                  {officeCate?.officeCategory}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label  fw-bold">Area</label>
            <input
              type="text"
              defaultValue={updateBranch?.area}
              onChange={handleChange}
              name="area"
              className="form-control"
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label  fw-bold">Office Name</label>
            <input
              type="text"
              defaultValue={updateBranch?.officeName}
              onChange={handleChange}
              name="officeName"
              className="form-control"
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label  fw-bold">Address</label>
            <textarea
              rows={2}
              defaultValue={updateBranch?.address}
              onChange={handleChange}
              name="address"
              className="form-control"
              required
            ></textarea>
          </div>

          <div className="mb-3">
            <label className="form-label fw-bold">Contact Person</label>
            <JoditEditor
              ref={editor}
              value={updateBranch?.contactPerson}
              // config={config}
              // tabIndex={1} // tabIndex of textarea
              onChange={(newContent) => {
                setContact(newContent);
              }}
            />
          </div>

          <div className="mb-3">
            <label className="form-label  fw-bold">Map Link</label>
            <input
              type="text"
              defaultValue={updateBranch?.mapLink}
              onChange={handleChange}
              name="mapLink"
              className="form-control"
              required
            />
          </div>

          <button type="submit" className="btn btn-primary px-4">
            Save
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default DashboardLoacateBranchEditModal;
