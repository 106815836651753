import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { toast } from 'react-hot-toast';
import { getImageUrl } from '../../../../hooks/imageUpload';
import { Modal } from 'react-bootstrap';
import JoditEditor from 'jodit-react';
import axios from 'axios';

function EditProductModal({
    editModal,
    handleEditClose,
    updateBlog,
    setUpdateBlog,
    getPaginationList,
}) {
    const editor = useRef(null);
    const [content, setContent] = useState(editModal?.description);
    const [contentBn, setContentBn] = useState(editModal?.descriptionBn);
    const handleEditChange = (e) => {
        setUpdateBlog({ ...updateBlog, [e.target.name]: e.target.value });
    };

    const handleEditImage = (e) => {
        setUpdateBlog({ ...updateBlog, image: e.target.files[0] });
    };

    const handleUpdateAction = async (e) => {
        e.preventDefault();
        handleEditClose();

        const formData = new FormData();
        formData.append('productCategory', updateBlog?.category);
        formData.append('productCategoryBn', updateBlog?.categoryBn);
        formData.append('productTitle', updateBlog?.productTitle);
        formData.append('productTitleBn', updateBlog?.productTitleBn);
        formData.append('description', content);
        formData.append('descriptionBn', contentBn);
        formData.append('coverage', updateBlog?.coverage);
        formData.append('coverageBn', updateBlog?.coverageBn);
        formData.append('term', updateBlog?.term);
        formData.append('termBn', updateBlog?.termBn);
        formData.append('premium', updateBlog?.premium);
        formData.append('premiumBn', updateBlog?.premiumBn);
        formData.append('maturityBenefit', updateBlog?.maturityBenefit);
        formData.append('maturityBenefitBn', updateBlog?.maturityBenefitBn);
        formData.append('deathBenefit', updateBlog?.deathBenefit);
        formData.append('deathBenefitBn', updateBlog?.deathBenefitBn);
        formData.append('sumAssured', updateBlog?.sumAssured);
        formData.append('sumAssuredBn', updateBlog?.sumAssuredBn);
        formData.append('premiumPaymentMode', updateBlog?.paymentMode);
        formData.append('premiumPaymentModeBn', updateBlog?.paymentModeBn);
        formData.append('productTerm', updateBlog?.productTerm);
        formData.append('productTermBn', updateBlog?.productTermBn);
        formData.append('age', updateBlog?.age);
        formData.append('ageBn', updateBlog?.ageBn);
        formData.append('surrenderOption', updateBlog?.surrenderOption);
        formData.append('surrenderOptionBn', updateBlog?.surrenderOptionBn);
        formData.append('medicalTest', updateBlog?.medicalTest);
        formData.append('medicalTestBn', updateBlog?.medicalTestBn);
        formData.append('rider', updateBlog?.rider);
        formData.append('riderBn', updateBlog?.riderBn);
        formData.append('conservationOption', updateBlog?.conservationOption);
        formData.append(
            'conservationOptionBn',
            updateBlog?.conservationOptionBn
        );
        formData.append('image', updateBlog?.image);

        for (const value of formData.values()) {
            console.log(value);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/easy-life-product/${updateBlog?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    console.log(res);
                    getPaginationList();
                    toast.success('Product updated successfully!');
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error('Product Not updated!');
            });

        // getImageUrl(updateBlog?.imageURL)
        //     .then((imageData) => {
        //         const updatedBlog = {
        //             productCategory: updateBlog?.category,
        //             productCategoryBn: updateBlog?.categoryBn,
        //             productTitle: updateBlog?.productTitle,
        //             productTitleBn: updateBlog?.productTitleBn,
        //             description: content,
        //             descriptionBn: contentBn,
        //             coverage: updateBlog?.coverage,
        //             coverageBn: updateBlog?.coverageBn,
        //             term: updateBlog?.term,
        //             termBn: updateBlog?.termBn,
        //             premium: updateBlog?.premium,
        //             premiumBn: updateBlog?.premiumBn,
        //             imageURL: imageData,
        //             maturityBenefit: updateBlog?.maturityBenefit,
        //             maturityBenefitBn: updateBlog?.maturityBenefitBn,
        //             deathBenefit: updateBlog?.deathBenefit,
        //             deathBenefitBn: updateBlog?.deathBenefitBn,
        //             sumAssured: updateBlog?.sumAssured,
        //             sumAssuredBn: updateBlog?.sumAssuredBn,
        //             premiumPaymentMode: updateBlog?.paymentMode,
        //             premiumPaymentModeBn: updateBlog?.paymentModeBn,
        //             productTerm: updateBlog?.productTerm,
        //             productTermBn: updateBlog?.productTermBn,
        //             age: updateBlog?.age,
        //             ageBn: updateBlog?.ageBn,
        //             surrenderOption: updateBlog?.surrenderOption,
        //             surrenderOptionBn: updateBlog?.surrenderOptionBn,
        //             medicalTest: updateBlog?.medicalTest,
        //             medicalTestBn: updateBlog?.medicalTestBn,
        //             rider: updateBlog?.rider,
        //             riderBn: updateBlog?.riderBn,
        //             conservationOption: updateBlog?.conservationOption,
        //             conservationOptionBn: updateBlog?.conservationOptionBn,
        //             slug: updateBlog?.productTitle
        //                 .toLowerCase()
        //                 .replace(/\s+/g, '-'),
        //         };

        //         fetch(
        //             `${process.env.REACT_APP_host}/api/v1/easy-life-product/${updateBlog?._id}`,
        //             {
        //                 method: 'PATCH',
        //                 headers: {
        //                     'Content-Type': 'application/json',
        //                 },
        //                 body: JSON.stringify(updatedBlog),
        //             }
        //         )
        //             .then((res) => {
        //                 if (res.status) {
        //                     console.log(res);
        //                     getPaginationList();
        //                     toast.success('Product updated successfully!');
        //                 }
        //             })
        //             .catch((error) => {
        //                 console.error(error);
        //                 toast.error('Not updated!');
        //             });
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         toast.error('Not updated!');
        //     });
    };
    return (
        <Modal
            show={editModal}
            onHide={handleEditClose}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Edit Product Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction}>
                    <div className="row gy-5 gx-5">
                        <section className="col-12 col-md-6">
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Select Category
                                </label>
                                <select
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.productCategory}
                                    name="category"
                                    class="form-select"
                                >
                                    <option value="">Choses a category</option>
                                    <option value="Term Life Insurance">
                                        Term Life Insurance
                                    </option>
                                    <option value="Savings Plan">
                                        Savings Plan
                                    </option>
                                    <option value="Women's Savings Plan">
                                        Women's Savings Plan
                                    </option>
                                    <option value="Cancer Care Plan">
                                        Cancer Care Plan
                                    </option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Product Title
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.productTitle}
                                    name="productTitle"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Product Description
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={updateBlog?.description}
                                    // config={config}
                                    // tabIndex={1} // tabIndex of textarea
                                    onChange={(newContent) => {
                                        setContent(newContent);
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Coverage
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.coverage}
                                    name="coverage"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Term
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.term}
                                    name="term"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Premium
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.premium}
                                    name="premium"
                                    className="form-control"
                                />
                            </div>
                            <h5 className="dashboard-title text-center fs-6 mt-4 mb-3">
                                Update Details of The Product
                            </h5>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Maturity Benefit
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.maturityBenefit}
                                    name="maturityBenefit"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Death/TPD Benefit
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.deathBenefit}
                                    name="deathBenefit"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Sum Assured
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.sumAssured}
                                    name="sumAssured"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Premium Payment Mode
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.paymentMode}
                                    name="paymentMode"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Product Term
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.productTerm}
                                    name="productTerm"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Age
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.age}
                                    name="age"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Surrender Option
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.surrenderOption}
                                    name="surrenderOption"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Medical Test
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.medicalTest}
                                    name="medicalTest"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Rider
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.rider}
                                    name="rider"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Conservation Option
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={
                                        updateBlog?.conservationOption
                                    }
                                    name="conservationOption"
                                    className="form-control"
                                />
                            </div>
                        </section>

                        <section className="col-12 col-md-6">
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Select Category
                                </label>
                                <select
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.productCategoryBn}
                                    name="categoryBn"
                                    class="form-select"
                                >
                                    <option value="" selected>
                                        একটি ক্যাটাগরি বেছে নিন
                                    </option>
                                    <option value="টার্ম লাইফ ইন্স্যুরেন্স">
                                        টার্ম লাইফ ইন্স্যুরেন্স
                                    </option>
                                    <option value="মাসিক সঞ্চয় প্ল্যান">
                                        মাসিক সঞ্চয় প্ল্যান
                                    </option>
                                    <option value="মহিলাদের মাসিক সঞ্চয় প্ল্যান">
                                        মহিলাদের মাসিক সঞ্চয় প্ল্যান
                                    </option>
                                    <option value="ক্যান্সার কেয়ার প্ল্যান">
                                        ক্যান্সার কেয়ার প্ল্যান
                                    </option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Product Title (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.productTitleBn}
                                    name="productTitleBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Product Description (Bangla)
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={updateBlog?.descriptionBn}
                                    // config={config}
                                    // tabIndex={1} // tabIndex of textarea
                                    onChange={(newContent) => {
                                        setContentBn(newContent);
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Coverage (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.coverageBn}
                                    name="coverageBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Term (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.termBn}
                                    name="termBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Premium (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.premiumBn}
                                    name="premiumBn"
                                    className="form-control"
                                />
                            </div>
                            <h5 className="dashboard-title text-center fs-6 mt-4 mb-3">
                                পণ্যের বিবরণ আপডেট করুন
                            </h5>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Maturity Benefit (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.maturityBenefitBn}
                                    name="maturityBenefitBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Death/TPD Benefit (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.deathBenefitBn}
                                    name="deathBenefitBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Sum Assured (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.sumAssuredBn}
                                    name="sumAssuredBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Premium Payment Mode (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.paymentModeBn}
                                    name="paymentModeBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Product Term (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.productTermBn}
                                    name="productTermBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Age (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.ageBn}
                                    name="ageBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Surrender Option (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.surrenderOptionBn}
                                    name="surrenderOptionBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Medical Test (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.medicalTestBn}
                                    name="medicalTestBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Rider (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={updateBlog?.riderBn}
                                    name="riderBn"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label fw-bold">
                                    Conservation Option (Bangla)
                                </label>
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    defaultValue={
                                        updateBlog?.conservationOptionBn
                                    }
                                    name="conservationOptionBn"
                                    className="form-control"
                                />
                            </div>
                        </section>
                    </div>

                    <div className="mb-5">
                        <label className="form-label fw-bold">
                            Product Image
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            onChange={handleEditImage}
                            className="form-control"
                        />
                    </div>

                    <div className="d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary px-4">
                            Update
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default EditProductModal;
