import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function MediaContentDetails({ data, menuTitle }) {
  // const navigate = useNavigate();
  const {
    _id,
    title,
    description,
    descriptionBn,
    titleBn,
    imageURL,
    createdAt,
  } = data;

  const [banglatranslate, setBanglaTraslate] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setBanglaTraslate(currentLang);
  }, []);

  const createdDate = format(new Date(createdAt), "PPP");
  const createdTime = format(new Date(createdAt), "p");
  // console.log(createdTime);

  // const handleDetails = (singleData, menuTitle, dataId) => {
  //   navigate(`/single-content/${dataId}`, { state: { singleData, menuTitle } });
  // };
  return (
    <div className="blog-card">
      <Link to={`/single-news/${_id}`}>
        <div
          className="card-img"
          // style={{ cursor: "pointer" }}
        // onClick={() => handleDetails(data, menuTitle, _id)}
        >
          <img src={imageURL} alt="img" />
        </div>
      </Link>
      <div className="card-body-content">
        <div className="d-flex align-items-center justify-content-between blog-date-time">
          <span>{createdDate}</span>
          <span>{createdTime}</span>
        </div>

        <hr />

        <div className="blog-name-title">
          <Link to={`/single-news/${_id}`}>
            <h4
              // style={{ cursor: "pointer" }}
            // onClick={() => handleDetails(data, menuTitle, _id)}
            >
              {banglatranslate === "bn"
                ? titleBn?.length > 32
                  ? `${titleBn?.slice(0, 32)}...`
                  : `${titleBn}`
                : title?.length > 32
                  ? `${title?.slice(0, 32)}...`
                  : `${title}`}
            </h4>
          </Link>
        </div>
        <div>
          <p className="blog-text-desc">
            {banglatranslate === "bn" ? (
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    descriptionBn?.length > 100
                      ? descriptionBn?.slice(0, 100) + "..."
                      : descriptionBn,
                }}
              ></span>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    description?.length > 100
                      ? description?.slice(0, 100) + "..."
                      : description,
                }}
              ></span>
            )}
          </p>
        </div>
        <div className="mt-2">
          {/* <button
            onClick={() => handleDetails(data, menuTitle, _id)}
            className="border-0 bg-transparent link-learnMore"
          >
            {t("viewmore")} <BsArrowRight className="learn-icon" />
          </button> */}

          <Link to={`/single-news/${_id}`} className="border-0 bg-transparent link-learnMore">
            {t("viewmore")} <BsArrowRight className="learn-icon" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MediaContentDetails;
