import React, { useEffect } from "react";
import "./mobileApp.css";
import MobileImage from "../../../assets/image/Opt-01.png";
import EasyLife from "../../../assets/image/easyLife.png";
import Guardian from "../../../assets/image/btn-myguirdian.png";
import BGDot from "../../../assets/image/bgDot.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function MobileApp() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  return (
    <div className="mobileApp-container">
      <div className="body-container secondary-container mobile-app-content">
        <div className="mobile-app-left">
          <img src={MobileImage} alt="" />
        </div>
        <div className="mobile-app-right">
          <h1 className="title mobile-app-title">{t("homeAppTitle")}</h1>
          <p className="text banner-text">{t("homeAppDesc")}</p>
          <div className="d-flex aling-items-center gap-4 mt-3">
            <div className="app-img2 ">
              <Link
                to="https://play.google.com/store/apps/details?id=com.sslwireless.easylife"
                target="_blank"
              >
                <img className="sponsor-logo" src={EasyLife} alt="" />
              </Link>
            </div>
            <div className="app-img2 ">
              <Link
                to="https://play.google.com/store/apps/details?id=com.guardianlifeinsurance.myguardian"
                target="_blank"
              >
                <img className="sponsor-logo" src={Guardian} alt="" />
              </Link>
            </div>
          </div>
          <div className="dot-img">
            <img src={BGDot} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileApp;
