import React from "react";
import "./checkItems.css";

import { BsCheckCircleFill } from "react-icons/bs";

function CheckItems({ textDesc }) {
  return (
    <>
      {textDesc.map((text, i) => (
        <div key={i} className="check-text">
          <div>
            <BsCheckCircleFill className="check-icon" />
          </div>
          <div>
            <span>{text}</span>
          </div>
        </div>
      ))}
    </>
  );
}

export default CheckItems;
