import React from 'react';
import BannerImg from '../../assets/easylife/Travel/BusinessTour.jpg';
import { FaCheckCircle } from 'react-icons/fa';
import {Link} from 'react-router-dom'
function BusinessTour() {

    const benifits = [
        'Emergency medical expenses and hospitalization: USD 100,000 or Euro equivalent for USA and Canada',
        'Emergency medical expenses and hospitalization: USD 50,000 or Euro 30,000 for rest of the world (for both schengen & non-schengen)',
        'Emergency dental care: USD 500 or Euro equivalent',
        'Cover for “covid-19” is included.',
        'Transport & repatriation in case of illness or accident included',
        'Repatriation of mortal remains included',
        'Policy Term: 180 days',
        'Age range: 6 months to 79 years',
    ];
    const target_link='https://adctest.myguardianbd.com/en/microsite/travel-insurance-registration/1/101';
    return (
        <div className="service-container" style={{ paddingTop: '5rem' }}>
            <div className="body-container secondary-container">
                <div>
                    <div className="banner-content">
                        <div className="left-content">
                            <h1 class="title banner-title text-start">
                                Business Tour
                            </h1>
                            <p className="pt-5 fw-bold text-justify">
                              Are you going to an important business trip? Ensure peace of mind and focus on your work with Guardian Travel Care (Overseas Mediclaim Policy) business tour plan. 
                            </p>

                            <h3 className="h3 pt-3">How does it benefit you?</h3>
                            <ul className="text banner-text pb-0 pt-3">
                                {benifits.map((benifit)=>(
                                    <li key={Math.round()}>
                                        <div className="corporate-coverage-list">
                                            <div className="corporate-coverage-check">
                                                <FaCheckCircle />
                                            </div>
                                            <p style={{ fontSize: '14px' }}>
                                                <span>
                                                    {benifit}
                                                </span>
                                            </p>
                                        </div>
                                     </li>
                                ))}
                                
                            </ul>
                            <br></br>
                            <Link
                                    to={target_link}
                                    target="_blank"
                                    className="btn-book btns"
                                    style={{ width: '40%' }}
                                >
                                    Proceed
                            </Link>
                        </div>
                        <div className="right-img">
                            <img src={BannerImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BusinessTour;
