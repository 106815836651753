import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Loading from "../../../../components/Loading/Loading";
import { useRef } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "@tanstack/react-query";
import { Button } from "react-bootstrap";

const InternationalHospital = () => {
  const [hospitalLists, setHospitalLists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(300);
  const [pageCount, setPageCount] = useState(1);
  const [country, setCountry] = useState("");
  const currentPage = useRef();
  const [search, setSearch] = useState("");

  // all country
  const { data: allCountry = [] } = useQuery({
    queryKey: [" allCountry"],
    queryFn: async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_host}/api/v1/country`);
        const data = await res.json();
        return data?.data;
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    currentPage.current = 1;
    getHospitalList();
  }, []);

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  function handlePageClick(e) {
    console.log(e);
    currentPage.current = e.selected + 1;
    getHospitalList();
  }

  function getHospitalList() {
    const url = `${process.env.REACT_APP_host}/api/v1/hospital?hospitalType=international`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        // setPageCount(data?.data?.page);
        if (data?.status) {
          setHospitalLists(data?.data?.hospitalList);
          setIsLoading(false);
        }
      });
  }

  function resetHospital() {
    setSearch("");
    setCountry("");
  }

  const uniqueItems = Array.from(
    new Set(hospitalLists?.map((item) => item?.country))
  ).map((name) => {
    return hospitalLists?.find((item) => item?.country === name);
  });

  console.log(uniqueItems);

  return (
    <div className="service-container bg-white pt-0">
      <div className="body-container secondary-container">
        <div className="mt-5 mb-3">
          <form className="row g-3 justify-content-center">
            <div className="col-12 col-md-4">
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                name="hospitalName"
                placeholder="Hospital Name"
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-3">
              <select
                name="country"
                value={country}
                className="form-select"
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="">Choose country</option>

                {uniqueItems?.map((country) => (
                  <option key={country?._id}>{country?.country}</option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-2">
              <Button onClick={resetHospital} className="btn-search btn-danger">
                Reset
              </Button>
            </div>
          </form>
        </div>

        <div className="table-responsive">
          <table className="table table-light table-bordered">
            <thead>
              <tr className="table-tr">
                <th scope="col" className="text-center align-middle table-th">
                  ID
                </th>
                <th scope="col" className="text-center align-middle table-th">
                  Hospital Name
                </th>
                <th scope="col" className="text-center align-middle table-th">
                  Country
                </th>
                <th scope="col" className="text-center align-middle table-th">
                  Address
                </th>
                <th scope="col" className="text-center align-middle table-th">
                  Facilities
                </th>
                <th scope="col" className="text-center align-middle table-th">
                  Contact Details
                </th>
                <th scope="col" className="text-center align-middle table-th">
                  Remark
                </th>
              </tr>
            </thead>
            <tbody>
              {hospitalLists
                ?.filter((item) => {
                  return search.toLowerCase() === "" && country === ""
                    ? item
                    : item?.hospitalName.toLowerCase().includes(search.toLowerCase()) &&
                        item?.country.includes(country);
                })
                ?.map(
                  (list) =>
                    list?.hospitalType === "international" && (
                      <tr key={list?._id}>
                        <td className="text-center align-middle table-text fw-bold">
                          {list?.hospitalId}
                        </td>
                        <td className="text-center align-middle table-text">
                          {list?.hospitalName}
                        </td>
                        <td className="text-center align-middle table-text">
                          {list?.country}
                        </td>
                        <td className="text-center align-middle table-text">
                          {list?.address}
                        </td>
                        <td className="align-middle table-text">
                          <div>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: list?.facilities,
                              }}
                            ></span>
                          </div>
                        </td>
                        <td className="align-middle table-text">
                          <div className="text-center">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: list?.contactDetails,
                              }}
                            ></span>
                          </div>
                        </td>
                        <td className="text-center align-middle table-text">
                          <p>{list?.remarks}</p>
                        </td>
                      </tr>
                    )
                )}
            </tbody>
          </table>
        </div>

        {/* <div className="mt-4">
          <ReactPaginate
            breakLabel="......."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
            containerClassName="pagination justify-content-end"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
          />
        </div> */}
      </div>
    </div>
  );
};

export default InternationalHospital;
