import React from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Buttons from '../../../components/Buttons/Buttons';

const CashlessFacilitiesModal = ({ show, handleClose }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => console.log(data);
    return (
        <div>
            <Modal scrollable={true} size="lg" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='book-title'>Make GOP Request</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='px-3 py-3'>
                        <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                            <div className="d-flex align-items-center gap-2 mb-4">
                                <div className='w-50'>
                                    <label className='mb-1'>Member ID</label>
                                    <input
                                        className="form-control form-contact w-100 py-2"
                                        placeholder="Enter member Id"
                                        type="text"
                                        {...register("memberId")}
                                    />
                                </div>
                                <div className='w-50'>
                                    <label className='mb-1'>Member name</label>
                                    <input
                                        type="text"
                                        className="form-control form-contact w-100 py-2"
                                        placeholder="Enter name"
                                        {...register("memberName")}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-2 mb-4">
                                <div className='w-50'>
                                    <label className='mb-1'>Organization</label>
                                    <input
                                        className="form-control form-contact w-100 py-2"
                                        placeholder="Enter organization"
                                        type="text"
                                        {...register("organization")}
                                    />
                                </div>
                                <div className='w-50'>
                                    <label className='mb-1'>Date Of Birth</label>
                                    <input
                                        type="date"
                                        className="form-control form-contact w-100 py-2"
                                        {...register("BirthDate")}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-2 mb-4">
                                <div className='w-50'>
                                    <label className='mb-1'>Commencement Date</label>
                                    <input
                                        className="form-control form-contact w-100 py-2"
                                        type="date"
                                        {...register("CommencementDate")}
                                    />
                                </div>
                                <div className='w-50'>
                                    <label className='mb-1 d-flex align-items-center gap-2'>
                                        <span>Claim Type <span className='text-danger'>*</span></span>
                                        {errors.ClaimType && <span className='d-flex justify-content-between text-danger' style={{ fontSize: "14px" }}>[Required Field]</span>}
                                    </label>
                                    <select
                                        {...register("ClaimType", { required: true })}
                                        className="form-select  form-contact py-2"
                                        aria-label="Default select example"
                                    >
                                        <option value="">
                                            Select claim type
                                        </option>
                                        <option value="Type-1">
                                            Type-1
                                        </option>
                                        <option value="Type-2">Type-2</option>
                                        <option value="Type-3">Type-3</option>
                                        <option value="Type-4">Type-4</option>
                                    </select>
                                </div>
                            </div>
                            <div className='mb-4'>
                                <div className="d-flex align-items-center gap-2">
                                    <div className='w-50'>
                                        <label className='mb-1 d-flex align-items-center gap-2'>
                                            <span>Admission/treatment date <span className='text-danger'>*</span></span>
                                            {errors.admissionDate && <span className='d-flex justify-content-between text-danger' style={{ fontSize: "14px" }}>[Required Field]</span>}
                                        </label>
                                        <input
                                            className="form-control form-contact w-100 py-2"
                                            type="date"
                                            {...register("admissionDate", { required: true })}
                                        />
                                        
                                    </div>
                                    <div className='w-50'>
                                        <label className='mb-1'>Contact Mobile Number (Optional)</label>
                                        <input
                                            type="text"
                                            placeholder='Enter mobile number'
                                            className="form-control form-contact w-100 py-2"
                                            {...register("mobileNumber")}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end mt-2' style={{ fontSize: "14px" }}>
                                    <span>Claim officer will contact here</span>
                                </div>
                            </div>

                            <hr className='my-4' style={{ border: "1px dotted" }} />

                            <div className="d-flex align-items-center gap-2 mb-4">
                                <div className='w-50'>
                                    <label className='mb-1 d-flex align-items-center gap-2'>
                                        <span>Area <span className='text-danger'>*</span></span>
                                        {errors.area && <span className='d-flex justify-content-between text-danger' style={{ fontSize: "14px" }}>[Required Field]</span>}
                                    </label>
                                    <select
                                        {...register("area", { required: true })}
                                        className="form-select  form-contact py-2"
                                        aria-label="Default select example"
                                    >
                                        <option value="">
                                            Select area
                                        </option>
                                        <option value="Badda">
                                            Badda
                                        </option>
                                        <option value="Savar">Savar</option>
                                        <option value="Motijhil">Motijhil</option>
                                        <option value="Dhanmondi">Dhanmondi</option>
                                    </select>
                                </div>
                                <div className='w-50'>
                                    <label className='mb-1 d-flex align-items-center gap-2'>
                                        <span>Hospital <span className='text-danger'>*</span></span>
                                        {errors.hospital && <span className='d-flex justify-content-between text-danger' style={{ fontSize: "14px" }}>[Required Field]</span>}
                                    </label>
                                    <select
                                        {...register("hospital", { required: true })}
                                        className="form-select  form-contact py-2"
                                        aria-label="Default select example"
                                    >
                                        <option value="">
                                            Select hospital
                                        </option>
                                        <option value="Hospital-1">
                                            Hospital-1
                                        </option>
                                        <option value="Hospital-2">Hospital-2</option>
                                        <option value="Hospital-3">Hospital-3</option>
                                        <option value="Hospital-4">Hospital-4</option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-2 mb-4">
                                <div className='w-50'>
                                    <label className='mb-1'>Enter Bed No. (Optional)</label>
                                    <input
                                        className="form-control form-contact w-100 py-2"
                                        type="text"
                                        {...register("bedNo")}
                                    />
                                </div>
                                <div className='w-50'>
                                    <label className='mb-1'>Attachment eg. Doctor prescription (Optional)</label>
                                    <input
                                        className="form-control form-contact w-100 py-2"
                                        type="file"
                                        {...register("attachment")}
                                    />
                                </div>
                            </div>
                            <div>
                                <label className='mb-1'>Cause of admission (Optional)</label>
                                <textarea
                                    className="form-control form-contact contact-area mb-4 "
                                    placeholder="Write cause"
                                    {...register("admissionCause")}
                                ></textarea>
                            </div>

                            {errors.exampleRequired && (
                                <span className="text-danger mb-3">This field is required</span>
                            )}

                            <div>
                                <Buttons type="submit" classes="btns send-btn w-100 rounded-2">Submit</Buttons>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CashlessFacilitiesModal;