import React from "react";

function PremiumCalculatorBanner() {
  return (
    <div className="career-banner">
      <div className="career-banner-content">
        <h1>Premium Calculator</h1>
        {/* <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry
        </p> */}
      </div>
    </div>
  );
}

export default PremiumCalculatorBanner;
