import React from 'react';

const MediaContentVideoPreview = ({ data }) => {
    return (
        <div className="media-container-video media-container-shadow">
            <iframe
                height="220"
                src={data?.videoLink}
                title={data?.vedioTitle || data?.title}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                className="w-100"
            ></iframe>
        </div>
    );
};

export default MediaContentVideoPreview;
