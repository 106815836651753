import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import { toast } from 'react-hot-toast';

function DashboardFAQ() {
    const editor = useRef(null);
    const navigate = useNavigate();
    const [content, setContent] = useState('');
    const [contentBn, setContentBn] = useState('');
    const [addFAQ, setAddFAQ] = useState({
        title: '',
        titleBn: '',
        selectPage: '',
    });

    const handleChange = (e) => {
        setAddFAQ({ ...addFAQ, [e.target.name]: e.target.value });
    };

    // post
    const handleAddFAQ = async (e) => {
        e.preventDefault();

        const newFAQ = {
            selectPage: addFAQ?.selectPage,
            title: addFAQ?.title,
            titleBn: addFAQ?.titleBn,
            description: content,
            descriptionBn: contentBn,
        };
        console.log(newFAQ);

        fetch(`${process.env.REACT_APP_host}/api/v1/faq`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newFAQ),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data?.status) {
                    toast.success('FAQ added successfully');
                    setAddFAQ('');
                    navigate('/dashboard/faq-view');
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('Not added successfully');
            });
    };
    return (
        <div className="home-content">
            <div class="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/faq-view"
                    class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View FAQ</span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center"> Add FAQ</h1>
                <form onSubmit={handleAddFAQ}>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Select Page
                        </label>
                        <select
                            onChange={handleChange}
                            name="selectPage"
                            class="form-select"
                        >
                            <option value="" selected>
                                Choses a Page
                            </option>
                            <option value="FAQ Page">FAQ Page</option>
                            <option value="Claims FAQ Page">
                                Claims FAQ Page
                            </option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">Question</label>
                        <input
                            type="text"
                            name="title"
                            onChange={handleChange}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Question (Bangla)
                        </label>
                        <input
                            type="text"
                            name="titleBn"
                            onChange={handleChange}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">Answer</label>

                        <JoditEditor
                            ref={editor}
                            value={content}
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Answer (Bangla)
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={contentBn}
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default DashboardFAQ;
