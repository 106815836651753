import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CorporateBookNow from '../CorporateSolutions/CorporateBookNow';
import CorporateHealthCoverage from '../CorporateSolutions/CorporateHealthCoverage/CorporateHealthCoverage';
import CorporateLifeCoverage from '../CorporateSolutions/CorporateLifeCoverage';
import CorporateServices from '../CorporateSolutions/CorporateServices/CorporateServices';
import DigitalLoyaltyCardDetails from './DigitalLoyaltyCardDetails';
import DigitalLoyaltyHowAvail from './DigitalLoyaltyHowAvail';
import './digitalLoayaltyCard.css';

function DigitalLaylatyMenu() {
    const [menuState, setMenuState] = useState(1);
    const action = (idx) => {
        setMenuState(idx);
    };

    const { t } = useTranslation();
    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);

    return (
        <div className="service-container">
            <div className="body-container secondary-container">
                <div>
                    <h1 className="title text-center banner-title ">
                        {t('digitalLoyaltyMenuTitle1')}
                    </h1>
                    <section>
                        <div className={menuState === 1 ? 'd-block' : 'd-none'}>
                            <DigitalLoyaltyCardDetails />
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default DigitalLaylatyMenu;
