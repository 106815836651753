import { format } from 'date-fns';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFacebookF, FaPinterestP, FaTwitter } from 'react-icons/fa';
import { useLoaderData, useLocation } from 'react-router-dom';
import './SingleContent.css';

function SingleContent() {
    const { t } = useTranslation();
    const [banglatranslate, setBanglaTraslate] = useState();
    const data = useLoaderData();

    // const { state: data } = useLocation();
    const {
        _id,
        title,
        titleBn,
        description,
        descriptionBn,
        imageURL,
        createdAt,
    } = data.data;
    const createdDate = format(new Date(createdAt), 'PPP');
    const createdTime = format(new Date(createdAt), 'p');

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    return (
        <div className="service-container">
            <div className="body-container secondary-container">
                <div>
                    <div className="d-flex justify-content-center">
                        <div className="gallery-title-area">
                            <h1 class="title title-service">{t('news')}</h1>
                        </div>
                    </div>
                </div>
                <div className="card-img blog-details-img section-margin-top px-0">
                    <img
                        style={{ objectFit: 'contain' }}
                        src={imageURL}
                        alt="img"
                    />
                </div>
                <div className="d-flex align-items-center justify-content-between blog-date-time">
                    <span>{createdDate}</span>
                    <span>
                        <span>{createdTime}</span>
                    </span>
                </div>
                <hr />
                <div className="blog-single-title mb-4 mt-4">
                    <h4> {banglatranslate === 'bn' ? titleBn : title}</h4>
                </div>
                <div className="d-flex flex-column gap-3">
                    <p className="blog-text-desc pt-0">
                        {banglatranslate === 'bn' ? (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: descriptionBn,
                                }}
                            ></span>
                        ) : (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            ></span>
                        )}
                    </p>
                </div>
                <div className="mt-4 d-flex gap-4">
                    <span className="fw-semibold">Share:</span>
                    <div className="details-share-icon">
                        <a href="/">
                            <FaFacebookF />
                        </a>
                        <a href="/">
                            <FaTwitter />
                        </a>
                        <a href="/">
                            <FaPinterestP />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleContent;
