import React from "react";

function BMIBanner({ t }) {
  return (
    <div className="career-banner">
      <div className="career-banner-content body-container secondary-container ">
        <h1>{t("Body Mass Index (BMI) Calculator")}</h1>
        <p>{t("Calculate how much you need to grow your health wealth to ensure a smooth and hassle free post retirements life with Easylife's Policies")}</p>
      </div>
    </div>
  );
}

export default BMIBanner;
