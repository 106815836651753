import React from "react";
import AboutEsayLifeBanner from "../AboutEsayLifeBanner/AboutEsayLifeBanner";
import DigitalLoyaltyOffers from "../DigitalLoyaltyOffers/DigitalLoyaltyOffers";
import ServicesProduct from "../ServicesPrododuct/ServicesProduct";
import OurMission from "../OurMission/OurMission";
import OurCustomer from "../OurCustomer/OurCustomer";
import OurVision from "../OurVision/OurVision";
import Award from "../Award/Award";
import AboutEasyLifeWhyJoinUS from "../AboutEasyLifeWhyJoinUS/AboutEasyLifeWhyJoinUS";
import AboutEasyLifeMedia from "../AboutEasyLifeMedia/AboutEasyLifeMedia";
import AboutEasyLifeDownloadApp from "../AboutEasyLifeDownloadApp/AboutEasyLifeDownloadApp";
import EasyLifeMobileApp from "../../EasyLifePages/EasyLifeContact/EasyLifeMobileApp";
import LearnAboutEasyLifeApp from "../LearnAboutEasyLifeApp/LearnAboutEasyLifeApp";
import AboutEasylifeGetProtected from "../AboutEasylifeGetProtected/AboutEasylifeGetProtected";
import useEasylifeTitle from "../../../hooks/useEasylifeTitle";
import Loading from "../../../components/Loading/Loading";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";

function AboutEsayLife() {
  // useEasylifeTitle("Easy Life");
  const [metaTags, setMetaTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getPaginationList();
  }, [metaTags]);

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/seo?pages=Easy Life Home`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setMetaTags(data?.data?.seo);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      {metaTags?.map((data) => (
        <Helmet>
          <title>{data?.title} - Easy Life </title>
          <meta name="description" content={data?.description} />
          <meta
            name="keywords"
            content={data?.keywords?.map((keys) => keys?.keywordsName)}
          />
        </Helmet>
      ))}
      {/* <AboutEsayLifeBanner /> */}
      <OurCustomer />
      {/* <DigitalLoyaltyOffers /> */}
      <OurMission />
      <OurVision />
      <ServicesProduct />
      <Award />
      <AboutEasyLifeWhyJoinUS />
      <AboutEasyLifeMedia />
      <LearnAboutEasyLifeApp />
      <AboutEasylifeGetProtected />
      <EasyLifeMobileApp />
    </div>
  );
}

export default AboutEsayLife;
