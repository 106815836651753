import { useQuery } from '@tanstack/react-query';
import { default as i18n, default as i18next } from 'i18next';
import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useTranslation } from 'react-i18next';
import { BiSupport } from 'react-icons/bi';
import { BsCalculator, BsCashStack } from 'react-icons/bs';
import { GiFruitTree, GiHamburgerMenu } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';
import { MdOutlineLocationOn } from 'react-icons/md';
import { TbFilePencil } from 'react-icons/tb';
import { Link, NavLink } from 'react-router-dom';
import LogoImage from '../../../../assets/logo/logo-1.png';
import AsideBar from '../../../../components/AsideBar/AsideBar';
import LanguageDropdown from '../../../../components/Language/LanguageDropdown';
import Loading from '../../../../components/Loading/Loading';
import Translate from '../../../Translate/Translate';
import '../header.css';
import './menuOffcanvas.css';

function MenuOffcanvas({ name, ...props }) {
    const [show, setShow] = useState(false);
    const [language, setLangusges] = useState();
    const { t } = useTranslation();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setLangusges(currentLang);
    }, []);

    // language
    const handleClick = (e) => {
        i18next.changeLanguage(e.target.value);
        window.location.reload();
        localStorage.setItem('lang', e.target.value);
    };

    const url = `${process.env.REACT_APP_host}/api/v1/plans`;

    const { data: plansData = [], isLoading } = useQuery({
        queryKey: ['plansData'],
        queryFn: async () => {
            try {
                const res = await fetch(url);
                const data = await res.json();
                return data?.data;
            } catch (err) {
                console.log(err);
            }
        },
    });

    if (isLoading) {
        return <Loading></Loading>;
    }
    return (
        <>
            <span
                onClick={handleShow}
                className="d-flex align-items-center gap-2"
            >
                <GiHamburgerMenu className="nav-icon" />{' '}
                <span>{t('Menu')}</span>
            </span>
            <Offcanvas
                className="offcanvas-dialogs"
                show={show}
                onHide={handleClose}
                {...props}
            >
                {/* closeButton */}
                <Offcanvas.Header className="px-md-0  px-lg-5 py-1">
                    <Offcanvas.Title className="w-100">
                        <nav className="first-nav py-0">
                            <div className="d-md-none">
                                <Link to="/home">
                                    <img
                                        className="logo-img"
                                        src={LogoImage}
                                        alt="logo"
                                    />
                                </Link>
                            </div>
                            <div className="left-menu">
                                <li className="menu-nav d-flex d-lg-none align-items-center gap-2">
                                    <LanguageDropdown
                                        onChange={(e) => handleClick(e)}
                                    />
                                </li>
                                <li
                                    onClick={handleClose}
                                    className="menu-nav d-flex align-items-center gap-2"
                                >
                                    <GrClose className="nav-icon" />
                                    <span>Close</span>
                                </li>
                                <li className="menu-nav d-none d-md-flex align-items-center gap-2">
                                    <BiSupport className="nav-icon" />
                                    <span>{t('24/7 HOTLINE: 16622')} </span>
                                </li>
                                <li className="menu-nav d-none d-md-flex align-items-center gap-2">
                                    {/* <BsCalculator className="nav-icon" />
                  <span> Premium Calculator </span> */}

                                    {['end'].map((placement, idx) => (
                                        <AsideBar
                                            key={idx}
                                            placement={placement}
                                            name={placement}
                                        />
                                    ))}

                                    {/* <Link
                    to="https://test.guardianlife.com.bd/en/premium-calculator"
                    target="_blank"
                  >
                    Premium Calculator
                  </Link> */}
                                </li>
                            </div>
                            <div className="d-none d-md-block">
                                <Link to="/home" onClick={handleClose}>
                                    <img
                                        className="logo-img"
                                        src={LogoImage}
                                        alt="logo"
                                    />
                                </Link>
                            </div>
                            <div className="right-menu">
                                <li className="menu-nav d-flex align-items-center gap-2">
                                    <Link
                                        to="/payment"
                                        className=" d-flex align-items-center gap-2"
                                        onClick={handleClose}
                                    >
                                        <BsCashStack className="nav-icon" />
                                        <span>{t('Payment channels')}</span>
                                    </Link>
                                </li>
                                {/* <li className="menu-nav d-none d-md-flex align-items-center gap-2 home-text">
                <Link
                  to="https://myguardianbd.com/"
                  className="d-flex align-items-center gap-2"
                  target="_blank"
                >
                  <GiFruitTree className="nav-icon" />
                  <span>{t("My Guardian")}</span>
                </Link>
              </li> */}

                                {/* <li className="menu-nav d-none d-md-flex align-items-center gap-2 home-text">
                  <Link
                    to="https://myguardianbd.com/"
                    className="d-flex align-items-center gap-2"
                    target="_blank"
                    onClick={handleClose}
                  >
                    <GiFruitTree className="nav-icon" />
                    <span>{t("My Guardian")}</span>
                  </Link>
                </li> */}
                                <li className="menu-nav d-flex align-items-center gap-2">
                                    <Link
                                        to="/locate-branch"
                                        className="menu-nav d-flex align-items-center gap-2"
                                        onClick={handleClose}
                                    >
                                        <MdOutlineLocationOn className="nav-icon" />
                                        <span>{t('Locate Branch')}</span>
                                    </Link>
                                </li>
                                <li className="menu-nav d-flex align-items-center gap-2">
                                    {/* <span>EN</span>
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label> */}

                                    <LanguageDropdown
                                        onChange={(e) => handleClick(e)}
                                    />

                                    {/* <Translate /> */}
                                </li>
                            </div>
                        </nav>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="body-color">
                    <div className="menu-items">
                        <div>
                            <h6 className="menu-items-title mb-4">
                                {t('footerLink')}
                            </h6>
                            <ul>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/home"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('home')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/about-us"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('aboutus')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 d-block d-lg-none ">
                                    <NavLink
                                        to="/chairman-message"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('chairmanmessage')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 d-block d-lg-none ">
                                    <NavLink
                                        to="/board-directors"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('boardofdirectors')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 d-block d-lg-none">
                                    <NavLink
                                        to="/shareholder-list"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('shareholderlist')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 d-block d-lg-none">
                                    <NavLink
                                        to="/management-team"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('managementteam')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 d-block d-lg-none">
                                    <NavLink
                                        to="/extended-management-committee"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>
                                            {t('extendedmanagementteam')}
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 d-block d-lg-none">
                                    <NavLink
                                        to="/staff-list"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('stafflist')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 d-block d-lg-none">
                                    <NavLink
                                        to="/agent-list"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('agentList')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 d-block d-lg-none">
                                    <NavLink
                                        to="/milestones"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('milestones')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 d-block d-lg-none">
                                    <NavLink
                                        to="/core-values"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('corevalues')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/corporate-solutions"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('corporate')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/microinsurance"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('microinsurance')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/bancassurance"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('bancassurance')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/easylife"
                                        target="__blank"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('digitalchannels')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/partnerchannels"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('easylifeADCTitle')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/claims"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('claims')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/media-corner"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('mediacorner')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/digital-loyalty-offer"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('digitalloyaltycard')}</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h6 className="menu-items-title mb-4">
                                {t('retail')}
                            </h6>
                            <ul>
                                {plansData.map((footerPlans) => (
                                    <>
                                        <li
                                            key={footerPlans?._id}
                                            className="nav-items-list mb-2 "
                                        >
                                            <NavLink
                                                to={`/retail/${footerPlans?.slug}`}
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? `nav-items-list-color navActive`
                                                        : `nav-items-list-color`
                                                }
                                                onClick={handleClose}
                                            >
                                                <span>
                                                    {language === 'bn'
                                                        ? footerPlans?.titleBn
                                                        : footerPlans?.title}
                                                </span>
                                            </NavLink>
                                        </li>
                                    </>
                                ))}
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        target="_blank"
                                        to="https://retail.myguardianbd.com/tax-certificate"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span> {t('taxCertificate')}</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h6 className="menu-items-title mb-4">
                                {t('Quick Service')}
                            </h6>
                            <ul>
                                <li className="nav-items-list mb-2 ">
                                    <div
                                        className="nav-items-list-color"
                                        style={{ cursor: 'pointer' }}
                                        // onClick={handleClose}
                                    >
                                        {['end'].map((placement, idx) => (
                                            <AsideBar
                                                key={idx}
                                                placement={placement}
                                                name={placement}
                                            />
                                        ))}
                                    </div>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        target="_blank"
                                        to="/easylife/bmi-calculator"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('BMICalculator')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        target="_blank"
                                        to="http://ocs.myguardianbd.com/"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('onlineClaim')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        target="_blank"
                                        to="https://acps.myguardianbd.com/claim/online/gop/request/"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('claimCashless')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        target="_blank"
                                        to="https://acps.myguardianbd.com/claim/online/track/"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('Track your Claim')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        target="_blank"
                                        to="https://acps.myguardianbd.com/client_login/"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('Group Claim')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        target="_blank"
                                        to="https://acps.myguardianbd.com/easy_life/client_login/"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('EasyLife Claim')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        target="_blank"
                                        to="https://rms.myguardianbd.com/claims/verification-request-form"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('Retail Claim')}</span>
                                    </NavLink>
                                </li>
                                {/* <li className="nav-items-list mb-2 ">
                  <NavLink
                    target="_blank"
                    to="https://myguardianbd.com/"
                    className={({ isActive }) =>
                      isActive
                        ? `nav-items-list-color navActive`
                        : `nav-items-list-color`
                    }
                    onClick={handleClose}
                  >
                    <span>{t("My Guardian")}</span>
                  </NavLink>
                </li> */}
                            </ul>
                        </div>
                        <div>
                            <h6 className="menu-items-title mb-4">
                                {t('SUPPORT')}
                            </h6>
                            <ul>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/form"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('support')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/notice-board"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('noticeboard')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/faq"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                    >
                                        <span>FAQ</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/preferred-hospital"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('hospitalList')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/locate-branch"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('Locate Branch')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/career"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('career')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <Link
                                        to="/payment"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <button className="btn btn-sm btn-payment">
                                            <span>{t('Payment channels')}</span>
                                        </button>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default MenuOffcanvas;
