import React from 'react';
import CorporateServices from './CorporateServices/CorporateServices';
import CorporateLifeCoverage from './CorporateLifeCoverage';
import CorporateHealthCoverage from './CorporateHealthCoverage/CorporateHealthCoverage';
import CorporateBookNow from './CorporateBookNow';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";




const CorporateSubmenu = () => {
    const { t } = useTranslation();
    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
    }, []);


    const mediaCornerSubmenus = [
        { submenuId: 1, submenuName: `${t("corporateMenuTitle1")}` },
        { submenuId: 2, submenuName: `${t("corporateMenuTitle2")}` },
        { submenuId: 3, submenuName: `${t("corporateMenuTitle3")}` }
    ]

    return (
        <div className="service-container pt-5">
            <div className="body-container secondary-container">
                <div>
                    <section className='corporate-submenu'>
                        {
                            mediaCornerSubmenus.map(submenu =>
                                <a href={`#${submenu?.submenuId}`} key={submenu?.submenuId} className="default-submenu">
                                    {submenu?.submenuName}
                                </a>
                            )
                        }
                    </section>

                    <section>
                        <div id={1}>
                            <CorporateServices />
                        </div>
                        <div id={2}>
                            <CorporateLifeCoverage />
                        </div>
                        <div id={3}>
                            <CorporateHealthCoverage />
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default CorporateSubmenu;