import React from "react";
import { Modal } from "react-bootstrap";

function ModalAddAgent({
  show,
  handleClose,
  handleAddAgentList,
  handleChange,
  handleImage,
  newAgent,
}) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      scrollable={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          Add Agent
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <form onSubmit={handleAddAgentList} className="row">
          <div className="mb-3 col-6">
            <label className="form-label">Name</label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={newAgent.name}
              className="form-control"
              required
            />
          </div>

          <div className="mb-3 col-6">
            <label className="form-label">Contact Number</label>
            <input
              type="text"
              name="contactNumber"
              className="form-control"
              value={newAgent.contactNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">NID</label>
            <input
              type="text"
              name="nid"
              onChange={handleChange}
              value={newAgent.nid}
              className="form-control"
              required
            />
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">FA Code</label>
            <input
              type="text"
              name="faCode"
              className="form-control"
              onChange={handleChange}
              value={newAgent.faCode}
              required
            />
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">UM Code</label>
            <input
              type="text"
              name="umCode"
              onChange={handleChange}
              value={newAgent.umCode}
              className="form-control"
              required
            />
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">Liscense No</label>
            <input
              type="text"
              name="liscenseNo"
              onChange={handleChange}
              value={newAgent.liscenseNo}
              className="form-control"
              required
            />
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">Issue Of License</label>
            <input
              type="date"
              name="issueOfLicense"
              onChange={handleChange}
              value={newAgent.issueOfLicense}
              className="form-control"
              required
            />
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">Expiry License</label>
            <input
              type="date"
              name="expiryLicense"
              onChange={handleChange}
              value={newAgent.expiryLicense}
              className="form-control"
              required
            />
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">Working Area</label>
            <input
              type="text"
              name="workingArea"
              onChange={handleChange}
              value={newAgent.workingArea}
              className="form-control"
              required
            />
          </div>
          <div className="mb-3 col-6">
            <label className="form-label">Image</label>
            <input
              type="file"
              onChange={handleImage}
              name="image"
              className="form-control"
            />
          </div>
          <div className="mb-3 col-12">
            <label className="form-label">Address</label>

            <textarea
              className="form-control"
              onChange={handleChange}
              value={newAgent.address}
              name="address"
            ></textarea>
          </div>

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ModalAddAgent;
