import React, { useEffect, useState } from 'react';
import './BancassuranceBenefitWeOffer.css';
import keyIcon from '../../../assets/image/plansImg1.png';
import keyIcon1 from '../../../assets/bancassurance/Natural.svg';
import keyIcon2 from '../../../assets/bancassurance/Accidental.svg';
import keyIcon3 from '../../../assets/bancassurance/Critical illness.svg';
import keyIcon4 from '../../../assets/bancassurance/Parmanent total disability.svg';
import keyIcon5 from '../../../assets/bancassurance/Hospitalization.svg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Loading from '../../../components/Loading/Loading';

const BancassuranceBenefitWeOffer = () => {
    const [updateIntro, setUpdateIntro] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();
    const [banglatranslate, setBanglaTraslate] = useState();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    const keyFeatures = [
        {
            id: 1,
            title: `${t('bancassuranceServiceOfferItem1')}`,
            img: keyIcon1,
        },
        {
            id: 2,
            title: `${t('bancassuranceServiceOfferItem2')}`,
            img: keyIcon2,
        },
        {
            id: 3,
            title: `${t('bancassuranceServiceOfferItem3')}`,
            img: keyIcon3,
        },
        {
            id: 4,
            title: `${t('bancassuranceServiceOfferItem4')}`,
            img: keyIcon4,
        },
        {
            id: 5,
            title: `${t('bancassuranceServiceOfferItem5')}`,
            img: keyIcon5,
        },
    ];

    useEffect(() => {
        fetch(`${process.env.REACT_APP_host}/api/v1/bancassurance-service`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setUpdateIntro(data?.data?.bancassuranceServiceList);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }
    return (
        <div className="service-container pb-0">
            <div className="body-container secondary-container">
                <h1 class="title title-service">
                    {t('bancassuranceServiceOfferTitle')}
                </h1>
                <div className="bancassurance-container">
                    {updateIntro?.map((keyFeature) => (
                        <div className="key-card">
                            <div className="key-img">
                                <img src={keyFeature?.imageURL} alt="" />
                            </div>
                            <h3 className="corporate-service-title mt-3">
                                {banglatranslate === 'bn'
                                    ? keyFeature?.titleBn
                                    : keyFeature?.title}
                            </h3>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BancassuranceBenefitWeOffer;
