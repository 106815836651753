import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

function AccidentalCarePDTTable() {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);

    const overviewLists = [
        {
            id: 1,
            silverPlan: `Loss of both hands or both legs or amputation from higher parts
            `,
            goldPlan: `100%`,
        },
        {
            id: 2,
            silverPlan: `Loss of 1 (one) hand and one leg`,
            goldPlan: `100%`,
        },
        {
            id: 3,
            silverPlan: `Loss of sight of both eyes to such an extent as to render the claimant unable to perform any work for which eye-sight is essential`,
            goldPlan: `100%`,
        },
        {
            id: 4,
            silverPlan: `Severe facial disfigurement`,
            goldPlan: `100%`,
        },
        {
            id: 5,
            silverPlan: `Compound fractures of skull with damage to the brain tissue`,
            goldPlan: `80%`,
        },
        {
            id: 6,
            silverPlan: `Loss of vision of 1 (one) eye
            `,
            goldPlan: `40%`,
        },
        {
            id: 7,
            silverPlan: `Spinal Cord fracture 
            `,
            goldPlan: `20%`,
        },
        {
            id: 8,
            silverPlan: `Any other fractures
            `,
            goldPlan: `10%`,
        },
    ];
    return (
        <div className="service-container pt-0">
            <div className="body-container secondary-container">
                <h1 class="title banner-title text-center">
                    BENEFITS ACCIDENTAL INDEMNITY (PTD & PPD) OF BENEFIT
                </h1>
                <div className="pt-4">
                    <div className="table-responsive">
                        <table className="table table-striped table-light table-bordered">
                            <thead>
                                <tr className="table-tr">
                                    <th
                                        scope="col"
                                        className="text-center align-middle table-th"
                                    >
                                        S/L NO.
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-center align-middle table-th"
                                    >
                                        Description Of Injury
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-center align-middle table-th"
                                    >
                                        Percentage Of Sum Assured (Relating To
                                        Accidental Indemnity Benefit)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {overviewLists.map((list) => (
                                    <tr>
                                        <td className="text-center align-middle table-text fs-6">
                                            {list?.id}
                                        </td>
                                        <td className="text-center align-middle table-text fs-6">
                                            {list?.silverPlan}
                                        </td>
                                        <td className="text-center align-middle table-text fs-6">
                                            {list?.goldPlan}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccidentalCarePDTTable;
