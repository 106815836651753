import React from 'react';
import './easyServiceProductCard.css';
import Blog1 from '../../assets/blog/blog-1.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';

function EasyServiceProductCard({ allProduct, banglatranslate, t }) {
    const {
        productTitle,
        productTitleBn,
        description,
        descriptionBn,
        imageURL,
        premium,
        premiumBn,
        term,
        termBn,
        coverage,
        coverageBn,
        slug,
    } = allProduct;

    const navigate = useNavigate();

    return (
        <div className="blog-card">
            <div className="card-img easylife-product-img">
                <img src={imageURL} alt="" />
            </div>
            <div className="card-body-content">
                <div className="d-flex align-items-center justify-content-between blog-date-time">
                    <div className="d-flex flex-column">
                        <span className="fw-bold mb-1">
                            {t('easylifeProductCoverage')}
                        </span>
                        <span>
                            {t('bdt')}{' '}
                            {banglatranslate === 'bn' ? coverageBn : coverage}
                        </span>
                    </div>
                    <div className="d-flex flex-column">
                        <span className="fw-bold mb-1">
                            {t('easylifeProductTerm')}
                        </span>
                        <span>
                            <span>
                                <span>
                                    {banglatranslate === 'bn' ? termBn : term}
                                </span>{' '}
                                {t('years')}
                            </span>
                        </span>
                    </div>
                    <div className="d-flex flex-column">
                        <span className="fw-bold mb-1">
                            {t('easylifeProductPremium')}
                        </span>
                        <span>
                            {t('bdt')}{' '}
                            {banglatranslate === 'bn' ? premiumBn : premium}
                        </span>
                    </div>
                </div>
                <hr />

                <div className="blog-name-title">
                    <h4>
                        {banglatranslate === 'bn'
                            ? productTitleBn
                            : productTitle}
                    </h4>
                    <p className="blog-text-desc">
                        <span
                            dangerouslySetInnerHTML={{
                                __html:
                                    banglatranslate === 'bn'
                                        ? descriptionBn
                                        : description,
                            }}
                        ></span>
                    </p>
                    {
                        // allProduct?.link ?
                        //   <div className="mt-4 d-flex justify-content-between">
                        //     <Link to={allProduct?.link} className="btn-book btns card-btn px-3 py-1 btn btn-primary w-auto">
                        //       View Details
                        //     </Link>
                        //     <Link to="http://easylife.myguardianbd.com/en/sign-in" target="_break" className="btn-book btns card-btn px-3 py-1 btn btn-primary w-auto">
                        //       Get A Quote
                        //     </Link>
                        //   </div>
                        //   :
                        <div className="mt-4 d-flex justify-content-between">
                            <Link
                                to={`/easylife/easy-life-product/${slug}`}
                                className="btn-book btns card-btn px-3 py-1 btn btn-primary w-auto"
                            >
                                {t('viewmore')}
                            </Link>
                            <Link
                                to="http://easylife.myguardianbd.com/en/sign-in"
                                target="_break"
                                className="btn-book btns card-btn px-3 py-1 btn btn-primary w-auto"
                            >
                                {t('easylifeProductGetQuoteBtn')}
                            </Link>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default EasyServiceProductCard;
