import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { getImageUrl } from '../../../hooks/imageUpload';
import axios from 'axios';

function PartnerLogoEdit({
    show,
    handleClose,
    updateLogo,
    setupdateLogo,
    getPaginationList,
}) {
    const handleChange = (e) => {
        setupdateLogo({ ...updateLogo, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setupdateLogo({ ...updateLogo, image: e.target.files[0] });
    };

    const handleUpdateAction = (e) => {
        e.preventDefault();
        handleClose();

        const formData = new FormData();

        formData.append('pageSelect', updateLogo?.pageSelect);

        formData.append('image', updateLogo?.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        for (const value of formData.values()) {
            console.log(value);
        }

        axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/partner-logo/${updateLogo?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    console.log(res);
                    getPaginationList();
                    toast.success('Partnet Logo updated successfully!');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Partnet Logo Not updated!!!');
            });

        // getImageUrl(updateLogo?.imageURL)
        //   .then((imageData) => {
        //     const editLogo = {
        //       imageURL: imageData,
        //       pageSelect: updateLogo?.pageSelect,
        //     };

        //     console.log(editLogo);

        //     fetch(
        //       `${process.env.REACT_APP_host}/api/v1/partner-logo/${updateLogo?._id}`,
        //       {
        //         method: "PATCH",
        //         headers: {
        //           "Content-Type": "application/json",
        //         },
        //         body: JSON.stringify(editLogo),
        //       }
        //     )
        //       .then((res) => {
        //         if (res.status) {
        //           console.log(res);
        //           getPaginationList();
        //           toast.success("Partnet Logo updated successfully!");
        //         }
        //       })
        //       .catch((error) => {
        //         console.error(error);
        //         toast.error("Not updated!");
        //       });
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //     toast.error("Not updated!");
        //   });
    };
    return (
        <Modal
            show={show}
            onHide={handleClose}
            scrollable={true}
            //   size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Edit Partner Logo
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction}>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Select Page
                        </label>
                        <select
                            onChange={handleChange}
                            defaultValue={updateLogo?.pageSelect}
                            name="pageSelect"
                            class="form-select"
                        >
                            <option value="" selected>
                                Choses a Page
                            </option>
                            <option value="Home Page">Home Page</option>
                            <option value="Corporate Page">
                                Corporate Page
                            </option>
                            <option value="Microinsurance Page">
                                Microinsurance Page
                            </option>
                            <option value="Bancassurance Page">
                                Bancassurance Page
                            </option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            onChange={handleImage}
                            className="form-control"
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Update
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default PartnerLogoEdit;
