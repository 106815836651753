import React from "react";
import { toast } from "react-hot-toast";
import { BiEdit } from "react-icons/bi";
import { BsFillEyeFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";

const DashboardPartnerChannelsViewTable = ({ handleEditShow, handleViewShow, partnerChannel, setPartnerChannelView, setPartnerChannelEdit, getAllGetAdc }) => {
  const { partnerName, lifeTitle, healthTitle, _id } = partnerChannel;
  const handleDeleteAction = (id) => {
    const agree = window.confirm(`Are you sure, you want to delete: "?"`);
    if (agree) {
      fetch(`${process.env.REACT_APP_host}/api/v1/adc/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            getAllGetAdc();
            toast.success(`Data is Deleted Partner Channels !!!!!!`);
          }
        });
    }
  };

  return (
    <>
      <tr>
        <td className="text-center align-middle table-text">{partnerName}</td>
        <td className="text-center align-middle table-text">
          {lifeTitle && <span>{lifeTitle}</span>} {lifeTitle && healthTitle && ","} {healthTitle && <span>{healthTitle}</span>}
        </td>
        <td className="text-center align-middle table-text">
          <div className="d-flex align-items-center justify-content-center gap-2">
            <button
              onClick={() => {
                handleViewShow();
                setPartnerChannelView(partnerChannel);
              }}
              className="btn btn-sm btn-primary table-btn fw-semibold d-flex align-items-center gap-1"
            >
              <BsFillEyeFill /> <span>View</span>
            </button>
            <button
              onClick={() => {
                handleEditShow();
                setPartnerChannelEdit(partnerChannel);
              }}
              className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center gap-1"
            >
              <BiEdit /> <span>Edit</span>
            </button>
            <button onClick={() => handleDeleteAction(_id)} className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center gap-1">
              <MdDelete /> <span>Delete</span>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default DashboardPartnerChannelsViewTable;
