import React from "react";

function More() {
  return (
    <div className="vh-100 body-container secondary-container d-flex justify-content-center align-items-center">
      <h1>More</h1>
    </div>
  );
}

export default More;
