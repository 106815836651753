import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

function RetailProductCards() {
    const navigate = useNavigate();
    const [banglatranslate, setBanglaTraslate] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        let currentLang = localStorage.getItem('lang');
        setBanglaTraslate(currentLang);
        i18n.changeLanguage(currentLang);
    }, []);

    const url = `${process.env.REACT_APP_host}/api/v1/plans`;
    const { data: plansData = [], isLoading } = useQuery({
        queryKey: ['plansData'],
        queryFn: async () => {
            try {
                const res = await fetch(url);
                const data = await res.json();
                return data?.data;
            } catch (err) {
                console.log(err);
            }
        },
    });

    if (isLoading) {
        return <Loading></Loading>;
    }

    console.log(plansData);

    const handleDetails = (dataId) => {
        navigate(`/retail/${dataId}`);
    };

    return (
        <div className="service-container">
            <div className="body-container secondary-container">
                <h1 className="title review-tilte">
                    {t('retailProductsTitle')}
                </h1>
                <div className="blog-content">
                    {plansData?.map((plan) => (
                        <div
                            className="blog-card retail-card"
                            onClick={() => handleDetails(plan?.slug)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div className="card-img retail-img">
                                <img src={plan?.imageURL} alt="img" />
                            </div>
                            <div className="card-body-content mt-4">
                                <div className="blog-name-title">
                                    <h4 className="fs-4 text-center">
                                        {banglatranslate === 'bn'
                                            ? plan?.titleBn
                                            : plan?.title}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default RetailProductCards;
