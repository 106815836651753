import React, { useEffect, useRef, useState } from "react";
import Loading from "../../../components/Loading/Loading";
import ReactPaginate from "react-paginate";
import { BsSearch } from "react-icons/bs";
import BookingTable from "./BookingTable";
import BookingShowModal from "./BookingShowModal";

function AllBookingList() {
  const [allBookingLists, setAllBookingLists] = useState([]);
  const [showBooking, setShowBooking] = useState(null);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const currentPage = useRef();

  useEffect(() => {
    currentPage.current = 1;
    getPaginationList();
  }, []);

  function handlePageClick(e) {
    console.log(e);
    currentPage.current = e.selected + 1;
    getPaginationList();
  }

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/booking?page=${currentPage.current}&limit=${limit}`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data?.data?.bookings);
        setPageCount(data?.data?.page);
        setAllBookingLists(data?.data?.bookings);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }
  return (
    <div className="home-content">
      <div>
        <h1 className="dashboard-title">Booking List</h1>
      </div>
      <div className="list-contaner">
        {/* <div className="input-group mb-2">
          <span className="input-group-text search-icon" id="search">
            <BsSearch />
          </span>
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            className="form-control search-field"
            placeholder="Search"
            aria-label="sarech"
            aria-describedby="search"
          />
        </div> */}

        {allBookingLists?.length === 0 ? (
          <div className="min-vh-100 d-flex align-items-center justify-content-center">
            No Booking Avaliable
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-light table-bordered">
                <thead>
                  <tr className="table-tr">
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Contact Number
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Address
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Booking For
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Appointment Date
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Message
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allBookingLists
                    //   .filter((item) => {
                    //     return search.toLowerCase() === ""
                    //       ? item
                    //       : item.name.toLowerCase().includes(search) ||
                    //           item.workingArea.toLowerCase().includes(search) ||
                    //           item.liscenseNo.toLowerCase().includes(search);
                    //   })
                    .map((bookingList) => (
                      <BookingTable
                        key={bookingList._id}
                        bookingList={bookingList}
                        handleShow={handleShow}
                        setShowBooking={setShowBooking}
                        getPaginationList={getPaginationList}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        <div className="mt-4">
          <ReactPaginate
            breakLabel="......."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
          />

          {/* <input
              placeholder="limit"
              onChange={(e) => setLimit(e.target.value)}
            />
            <button onClick={changeLimit}>Set Limit</button> */}
        </div>
        <BookingShowModal
          showBooking={showBooking}
          setShowBooking={setShowBooking}
          show={show}
          handleClose={handleClose}
          getPaginationList={getPaginationList}
        />
      </div>
    </div>
  );
}

export default AllBookingList;
