import React from 'react';
import EasylifeContactForm from '../EasyLifePages/EasyLifeContact/EasylifeContactForm';
import EasyLifeMobileApp from '../EasyLifePages/EasyLifeContact/EasyLifeMobileApp';
import useEasylifeTitle from '../../hooks/useEasylifeTitle';
import ContactForm from './ContactForm';
import useTitle from '../../hooks/useTitle';

function Contactus() {
    useTitle('Contact Us');
    return (
        <>
            {/* <EasyLifeContactBanner /> */}
            <ContactForm />
            <EasyLifeMobileApp background={'bg-white'} />
        </>
    );
}

export default Contactus;
