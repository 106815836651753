import React, { useEffect, useRef, useState } from "react";
import Loading from "../../../../components/Loading/Loading";
import ReactPaginate from "react-paginate";
import DashboardViewBlogTable from "./DashboardViewBlogTable";
import DashboardBlogEditModal from "./DashboardBlogEditModal";
import DashboardBlogViewModal from "./DashboardBlogViewModal";
import { Link } from "react-router-dom";
import { FaStepBackward } from "react-icons/fa";

function DashboardViewBlog() {
  const [newAllBlogs, setAllBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [editModal, setEditModal] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const [updateBlog, setUpdateBlog] = useState(null);
  const [viewBlog, setViewBlog] = useState(null);

  const currentPage = useRef();

  useEffect(() => {
    currentPage.current = 1;
    getPaginationList();
  }, []);

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  const handleViewClose = () => setViewShow(false);
  const handleViewShow = () => setViewShow(true);
  const handleEditClose = () => setEditModal(false);
  const handleEditShow = () => setEditModal(true);

  // pagination
  function handlePageClick(e) {
    currentPage.current = e.selected + 1;
    getPaginationList();
  }

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/blog?page=${currentPage.current}&limit=${limit}`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data?.data?.page);
        setAllBlogs(data?.data?.blogsList);
        setIsLoading(false);
      });
  }
  return (
    <div className="home-content">
      <div class="d-flex justify-content-between gap-2 align-items-center">
        <h1 className="dashboard-title">All Blog</h1>
        <div>
          <Link
            to="/dashboard/media/blog"
            className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
          >
            <FaStepBackward />
            <span>Back</span>
          </Link>
        </div>
      </div>

      <div className="list-contaner">
        {newAllBlogs?.length === 0 ? (
          <div className="min-vh-100 d-flex align-items-center justify-content-center">
            No Blog Is Available
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-light table-bordered">
                <thead>
                  <tr className="table-tr">
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Serial No.
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Photo
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Description
                    </th>
                    {/* <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Created At
                    </th> */}
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {newAllBlogs.map((newBlog, i) => (
                    <DashboardViewBlogTable
                      key={newBlog._id}
                      newBlog={newBlog}
                      idx={i}
                      handleEditShow={handleEditShow}
                      handleViewShow={handleViewShow}
                      setUpdateBlog={setUpdateBlog}
                      setViewBlog={setViewBlog}
                      getPaginationList={getPaginationList}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        <div className="mt-4">
          <ReactPaginate
            breakLabel="......."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
          />
        </div>

        <DashboardBlogEditModal
          editModal={editModal}
          handleEditClose={handleEditClose}
          updateBlog={updateBlog}
          setUpdateBlog={setUpdateBlog}
          getPaginationList={getPaginationList}
        />

        <DashboardBlogViewModal
          viewShow={viewShow}
          handleViewClose={handleViewClose}
          viewBlog={viewBlog}
        />
      </div>
    </div>
  );
}

export default DashboardViewBlog;
