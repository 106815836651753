import React from "react";

function DashboardLoyaltyOffer() {
  return (
    <div className="home-content min-vh-100 d-flex align-items-center justify-content-center ">
      <h1 className="plans-contents"> Loyalty Offer </h1>
    </div>
  );
}

export default DashboardLoyaltyOffer;
