import axios from 'axios';
import JoditEditor from 'jodit-react';
import React, { useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

function DashboardMilestones() {
    const editor = useRef(null);
    const editorBn = useRef(null);
    const [content, setContent] = useState('');
    const [contentBn, setContentBn] = useState('');
    const [year, setYear] = useState('');
    const [yearBn, setYearBn] = useState('');

    const navigate = useNavigate();

    // post
    const handleAddMilestone = (e) => {
        e.preventDefault();

        const newMilestones = {
            year: year,
            yearBn: yearBn,
            description: content,
            descriptionBn: contentBn,
        };

        console.log(newMilestones);

        fetch(`${process.env.REACT_APP_host}/api/v1/milestone`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(newMilestones),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status) {
                    toast.success('Milestone added successfully');
                    navigate('/dashboard/view-milestone');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not Milestone added!!!!');
            });
    };

    return (
        <div className="home-content">
            <div className="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/view-milestone"
                    className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Details </span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center">Add Milestone</h1>
                <form onSubmit={handleAddMilestone}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Type Year</label>
                        <input
                            type="text"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            name="year"
                            className="form-control"
                            placeholder="Type year"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Type Year(Bangla)
                        </label>
                        <input
                            type="text"
                            value={yearBn}
                            onChange={(e) => setYearBn(e.target.value)}
                            name="year"
                            className="form-control"
                            placeholder="Type year"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={content}
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description(Bangla)
                        </label>

                        <JoditEditor
                            ref={editorBn}
                            value={contentBn}
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default DashboardMilestones;
