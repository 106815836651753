import React, { useEffect } from "react";
import "./servicesProduct.css";
import TermLifeInsurance from "./TermLifeInsurance";
import { useState } from "react";
import SavingsPlan from "./SavingsPlan";
import WomenSavingsPlan from "./WomenSavingsPlan";
import { useTranslation } from "react-i18next";
import i18n from "i18next"
import NewServiceProduct from "../NewServiceProduct/NewServiceProduct";



function ServicesProduct() {
  const [banglatranslate, setBanglaTraslate] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setBanglaTraslate(currentLang);
  }, []);

  const serviceProductTitls = [
    {
      id: 1,
      title: `${t("easylifeAboutUsServiceOffermenu1")}`,
    },
    {
      id: 2,
      title: `${t("easylifeAboutUsServiceOffermenu2")}`,
    },
    {
      id: 3,
      title: `${t("easylifeAboutUsServiceOffermenu3")}`,
    },
  ];

  const [menuState, setMenuState] = useState(1);
  const action = (idx) => {
    setMenuState(idx);
  };

  return (
    <div className="body-container secondary-container aboutEsayLife-container plans-section">
      <h1 className="title text-center banner-title ">{t("easylifeAboutUsServiceOfferTitle")}</h1>
      <p className="esayLife-Text banner-text">
        {t("easylifeAboutUsServiceOfferDetails")}
      </p>

      <NewServiceProduct />


      {/* <div className="esayProduct-container">
        <div className="corporate-submenu">
          {serviceProductTitls.map((serviceTitle) => (
            <div
              key={serviceTitle?.id}
              onClick={() => action(serviceTitle.id)}
              className={
                menuState === serviceTitle.id
                  ? "active-submenu"
                  : "default-submenu easy-life-about-menu"
              }
            >
              {serviceTitle?.title}
            </div>
          ))}
        </div>

        <section>
          <div className={menuState === 1 ? "d-block" : "d-none"}>
            <TermLifeInsurance t={t} banglatranslate={banglatranslate} />
          </div>
          <div className={menuState === 2 ? "d-block" : "d-none"}>
            <SavingsPlan t={t} banglatranslate={banglatranslate} />
          </div>
          <div className={menuState === 3 ? "d-block" : "d-none"}>
            <WomenSavingsPlan t={t} banglatranslate={banglatranslate} />
          </div>
        </section>
      </div> */}
    </div>
  );
}

export default ServicesProduct;
