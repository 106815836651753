import React, { useEffect, useState } from 'react';
import BgBannerImg from '../../../assets/BG/bgLeft.png';
import BannerImg from '../../../assets/image/bancassurances.jpg';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Loading from '../../../components/Loading/Loading';

const BgStyle = {
    backgroundImage: `url(${BgBannerImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
};

const BancassuranceBanner = () => {
    const { t } = useTranslation();
    const [updateIntro, setUpdateIntro] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [banglatranslate, setBanglaTraslate] = useState();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    const bannerItems = [
        {
            id: 1,
            title: `${t('bancassuranceBannerTitle')}`,
            desc: `${t('bancassuranceBannerDetails')}`,
            img: BannerImg,
        },
    ];

    const [isShowMore, setShowMore] = useState(false);
    const handleShow = () => {
        setShowMore(!isShowMore);
    };

    useEffect(() => {
        fetch(
            `${process.env.REACT_APP_host}/api/v1/bancassurance-introduction`,
            {
                method: 'GET',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setUpdateIntro(data?.data?.bancassuranceIntroductionList);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }
    return (
        <div style={BgStyle}>
            <div className="body-container secondary-container banner-container">
                {updateIntro?.map((bannerItem) => (
                    <div key={bannerItem.id} className="banner-content">
                        <div className="left-content">
                            <h1 className="title banner-title">
                                {banglatranslate === 'bn'
                                    ? bannerItem?.titleBn
                                    : bannerItem?.title}
                            </h1>
                            <p className="text banner-text">
                                {!isShowMore
                                    ? banglatranslate === 'bn'
                                        ? bannerItem?.descriptionBn.slice(
                                              0,
                                              300
                                          ) + '...'
                                        : bannerItem?.description.slice(
                                              0,
                                              300
                                          ) + '...'
                                    : banglatranslate === 'bn'
                                    ? bannerItem?.descriptionBn
                                    : bannerItem?.description}
                            </p>
                            <div className="d-flex align-items-center gap-3 mt-4">
                                <Button
                                    className="btn-book btns"
                                    onClick={handleShow}
                                >
                                    {isShowMore
                                        ? `${t('readless')}`
                                        : `${t('readmore')}`}
                                </Button>
                            </div>
                        </div>
                        <div className="right-img">
                            <img src={bannerItem?.imageURL} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BancassuranceBanner;
