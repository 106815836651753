import React from "react";
import { Modal } from "react-bootstrap";

function SEOView({ show, handleClose, SEOViewList }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      scrollable={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          {SEOViewList?.pages}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <h6>
          <span className="fw-bold">Title:</span> {SEOViewList?.title}
        </h6>
        <p
          className="d-flex align-items-center gap-2 mt-2 fw-bold flex-wrap"
          style={{ fontSize: "12px" }}
        >
          KeyWords Item:
          {SEOViewList?.keywords?.map((data, i) => (
            <span
              className=""
              key={i}
              style={{
                backgroundColor: "#F3F2F1",
                padding: "2px 4px ",
                borderRadius: "5px",
                fontSize: "10px",
              }}
            >
              {data?.keywordsName}
            </span>
          ))}
        </p>
        <p>{SEOViewList?.description}</p>
      </Modal.Body>
    </Modal>
  );
}

export default SEOView;
