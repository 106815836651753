import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { getImageUrl } from '../../../../hooks/imageUpload';
import JoditEditor from 'jodit-react';
import { useRef } from 'react';
import { useState } from 'react';
import axios from 'axios';

function MediaEditModal({
    editModal,
    handleEditClose,
    updateMedia,
    setUpdateMedia,
    getPaginationList,
}) {
    const editor = useRef(null);
    const [content, setContent] = useState(editModal?.description);
    const [contentBn, setContentBn] = useState(editModal?.descriptionBn);
    const handleEditChange = (e) => {
        setUpdateMedia({ ...updateMedia, [e.target.name]: e.target.value });
    };

    const handleEditImage = (e) => {
        setUpdateMedia({ ...updateMedia, image: e.target.files[0] });
    };

    const handleUpdateAction = async (e) => {
        e.preventDefault();
        handleEditClose();

        const formData = new FormData();

        formData.append('title', updateMedia?.title);
        formData.append('titleBn', updateMedia?.titleBn);
        formData.append('description', content);
        formData.append('descriptionBn', contentBn);
        formData.append('image', updateMedia?.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for (const value of formData.values()) {
            console.log(value);
        }

        await axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/easy-life-media/${updateMedia?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    toast.success('Media updated successfully!');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not updated!');
            });

        // getImageUrl(updateMedia?.imageURL)
        //   .then((imageData) => {
        //     const updatedBlog = {
        //       title: updateMedia?.title,
        //       description: content,
        //       titleBn: updateMedia?.titleBn,
        //       descriptionBn: contentBn,
        //       imageURL: imageData,
        //     };

        //     fetch(
        //       `${process.env.REACT_APP_host}/api/v1/easy-life-media/${updateMedia?._id}`,
        //       {
        //         method: "PATCH",
        //         headers: {
        //           "Content-Type": "application/json",
        //         },
        //         body: JSON.stringify(updatedBlog),
        //       }
        //     )
        //       .then((res) => {
        //         if (res.status) {
        //           console.log(res);
        //           getPaginationList();
        //           toast.success("Media updated successfully!");
        //         }
        //       })
        //       .catch((error) => {
        //         console.error(error);
        //         toast.error("Not updated!");
        //       });
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //     toast.error("Not updated!");
        //   });
    };

    return (
        <Modal
            show={editModal}
            onHide={handleEditClose}
            scrollable={true}
            //   size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Edit Blog
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction} className="row">
                    <div className="mb-3 col-12">
                        <label className="form-label">Title</label>
                        <input
                            type="text"
                            name="title"
                            onChange={handleEditChange}
                            defaultValue={updateMedia?.title}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Title(Bangla)</label>
                        <input
                            type="text"
                            name="titleBn"
                            onChange={handleEditChange}
                            defaultValue={updateMedia?.titleBn}
                            className="form-control"
                        />
                    </div>

                    {/* <div className="mb-3 col-12">
            <label className="form-label">Description</label>
            <textarea
              rows={8}
              name="description"
              onChange={handleEditChange}
              className="form-control"
              required
            >
              {updateMedia?.description}
            </textarea>
          </div>
          <div className="mb-3 col-12">
            <label className="form-label">Description(Bangla)</label>
            <textarea
              rows={8}
              name="descriptionBn"
              onChange={handleEditChange}
              className="form-control"
            >
              {updateMedia?.descriptionBn}
            </textarea>
          </div> */}
                    <div className="mb-5 col-12">
                        <label className="form-label">Blog Image</label>
                        <input
                            type="file"
                            onChange={handleEditImage}
                            name="image"
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={updateMedia?.description}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description(Bangla)
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={updateMedia?.descriptionBn}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default MediaEditModal;
