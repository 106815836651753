import axios from 'axios';
import JoditEditor from 'jodit-react';
import React, { useRef, useState } from 'react';

import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

function DashboardBancassuranceBenfitsEdit({
    editModal,
    handleEditClose,
    updateBenfits,
    setUpdateBenfits,
    getPaginationList,
}) {
    const editor = useRef(null);
    const [content, setContent] = useState(editModal?.description);
    const [contentBn, setContentBn] = useState(editModal?.descriptionBn);
    const handleEditChange = (e) => {
        setUpdateBenfits({ ...updateBenfits, [e.target.name]: e.target.value });
    };

    const handleEditImage = (e) => {
        const selectedImage = e.target.files[0];
        setUpdateBenfits({ ...updateBenfits, imageURL: selectedImage });
    };

    const handleUpdateAction = async (e) => {
        e.preventDefault();
        handleEditClose();

        const formData = new FormData();

        formData.append('title', updateBenfits?.title);
        formData.append('titleBn', updateBenfits?.titleBn);
        formData.append('description', content);
        formData.append('descriptionBn', contentBn);
        formData.append('image', updateBenfits?.imageURL);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for (const value of formData.values()) {
            console.log(value);
        }

        await axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/bancassurance-benfits/${updateBenfits?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    toast.success('Benfits updated successfully!');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not updated!');
            });
    };

    return (
        <Modal
            show={editModal}
            onHide={handleEditClose}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Edit Benefit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction} className="row">
                    <div className="mb-3 col-12">
                        <label className="form-label">
                            Title <sup className="text-danger">*</sup>
                        </label>
                        <input
                            type="text"
                            name="title"
                            onChange={handleEditChange}
                            defaultValue={updateBenfits?.title}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Title(Bangla) </label>
                        <input
                            type="text"
                            name="titleBn"
                            onChange={handleEditChange}
                            defaultValue={updateBenfits?.titleBn}
                            className="form-control"
                        />
                    </div>

                    <div className="mb-5 col-12">
                        <label className="form-label">Image</label>
                        <input
                            type="file"
                            onChange={handleEditImage}
                            name="image"
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={updateBenfits?.description}
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description(Bangla)
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={updateBenfits?.descriptionBn}
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardBancassuranceBenfitsEdit;
