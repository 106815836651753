import React from 'react';
import { toast } from 'react-hot-toast';
import { BiEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';

const DasboardHospitalViewTable = ({
    HospitalList,
    getHospitalList,
    handleViewShow,
    handleEditShow,
    setViewHospital,
    setUpdateHospital,
}) => {
    const {
        _id,
        hospitalName,
        address,
        area,
        contactDetails,
        district,
        division,
        facilities,
        hospitalId,
        hospitalType,
        hospitalCategory,
        remarks,
        country,
    } = HospitalList;

    // console.log(HospitalList);

    const handleDeleteAction = (id) => {
        const agree = window.confirm(
            `Are you sure, you want to delete: "${hospitalName}?"`
        );
        if (agree) {
            fetch(`${process.env.REACT_APP_host}/api/v1/hospital/${id}`, {
                method: 'DELETE',
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.status) {
                        getHospitalList();
                        toast.success(`"${hospitalName}" is Deleted !`);
                    }
                });
        }
    };
    return (
        <tr>
            <td className="text-center align-middle table-text">
                {hospitalType}
            </td>
            <td className="text-center align-middle table-text text-capitalize">
                {hospitalCategory ? hospitalCategory : 'N/A'}
            </td>
            <td className="text-center align-middle table-text">
                {hospitalId ? hospitalId : 'N/A'}
            </td>
            <td className="text-center align-middle table-text">
                {hospitalName ? hospitalName : 'N/A'}
            </td>
            <td className="text-center align-middle table-text">
                {country ? country : 'N/A'}
            </td>
            <td className="text-center align-middle table-text">
                {division ? division : 'N/A'}
            </td>
            <td className="text-center align-middle table-text">
                {district ? district : 'N/A'}
            </td>
            <td className="text-center align-middle table-text">
                {area ? area : 'N/A'}
            </td>
            <td className="text-center align-middle table-text">
                {address ? address : 'N/A'}
            </td>
            <td className="align-middle table-text">
                {/* <span dangerouslySetInnerHTML={{ __html: description }}></span> */}
                <span
                    dangerouslySetInnerHTML={{
                        __html: facilities,
                    }}
                ></span>
            </td>
            <td className="text-center align-middle table-text">
                {/* <span dangerouslySetInnerHTML={{ __html: description }}></span> */}
                <span
                    dangerouslySetInnerHTML={{
                        __html: contactDetails,
                    }}
                ></span>
            </td>
            <td className="text-center align-middle table-text">
                {remarks ? remarks : 'N/A'}
            </td>

            <td className="text-center align-middle table-text">
                <div className="d-flex align-items-center justify-content-center gap-2">
                    <button
                        onClick={() => {
                            handleEditShow();
                            setUpdateHospital(HospitalList);
                        }}
                        className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center "
                    >
                        <BiEdit /> <span>Edit</span>
                    </button>
                    <button
                        onClick={() => handleDeleteAction(_id)}
                        className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center"
                    >
                        <MdDelete /> <span>Delete</span>
                    </button>
                </div>
            </td>
        </tr>
    );
};

export default DasboardHospitalViewTable;
