import JoditEditor from 'jodit-react';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { getImageUrl } from '../../../hooks/imageUpload';
import axios from 'axios';

function DashboardDigitalLoyaltyEditModal({
    editModal,
    handleEditClose,
    updateOffer,
    setUpdateOffer,
    getPaginationList,
}) {
    const editor = useRef(null);
    const [content, setContent] = useState(editModal?.description);
    const [category, setCategory] = useState(updateOffer?.category);
    const [categoryList, setCategoryList] = useState([]);

    const handleChange = (e) => {
        setUpdateOffer({ ...updateOffer, [e.target.name]: e.target.value });
    };

    const handleEditImage = (e) => {
        setUpdateOffer({ ...updateOffer, image: e.target.files[0] });
    };

    useEffect(() => {
        getCategoryList();
    }, [categoryList]);

    function getCategoryList() {
        const url = `${process.env.REACT_APP_host}/api/v1/loyalty-category`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setCategoryList(data?.data?.loyaltyCategoryList);
            });
    }

    const handleUpdateAction = async (e) => {
        e.preventDefault();
        handleEditClose();

        const formData = new FormData();

        formData.append('category', category);
        formData.append('name', updateOffer?.name);
        formData.append('discount', updateOffer?.discount);
        formData.append('offer', updateOffer?.offer);
        formData.append('validity', updateOffer?.validity);
        formData.append('offerDetails', content);
        formData.append('eligib', updateOffer?.eligib);
        formData.append('contactNuber', updateOffer?.contactNuber);
        formData.append('website', updateOffer?.website);
        formData.append('facebookLink', updateOffer?.facebookLink);
        formData.append('youtubeLink', updateOffer?.youtubeLink);
        formData.append('instagramLink', updateOffer?.instagramLink);
        formData.append('expiresDate', updateOffer?.expiresDate);
        formData.append('image', updateOffer?.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for (const value of formData.values()) {
            console.log(value);
        }

        axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/digital-loyalty-offer/${updateOffer?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    console.log(res);
                    getPaginationList();
                    toast.success('Digital Offer updated successfully!');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not updated!');
            });
    };

    return (
        <Modal
            show={editModal}
            onHide={handleEditClose}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Update Digital Loyalty Blog
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction} className="row">
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Choose Category
                        </label>
                        <select
                            name="category"
                            className="form-select"
                            defaultValue={updateOffer?.category}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            <option value="">Choose a Category</option>
                            {categoryList?.map((category) => (
                                <option
                                    key={category.category}
                                    value={category.category}
                                >
                                    {category.category}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-3 col-12">
                        <label className="form-label fw-bold">Name</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            defaultValue={updateOffer?.name}
                            name="name"
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3 col-12">
                        <label className="form-label fw-bold">Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            onChange={handleEditImage}
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3 col-12">
                        <label className="form-label fw-bold">Discount</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            defaultValue={updateOffer?.discount}
                            name="discount"
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3 col-12">
                        <label className="form-label fw-bold">Offer</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            defaultValue={updateOffer?.offer}
                            name="offer"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label fw-bold">Validity</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={updateOffer?.validity}
                            name="validity"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label fw-bold">
                            Eligibility
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={updateOffer?.eligib}
                            name="eligib"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label fw-bold">
                            Contact Number
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={updateOffer?.contactNuber}
                            name="contactNuber"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label fw-bold">
                            {' '}
                            Website Link
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={updateOffer?.website}
                            name="website"
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            {' '}
                            Facebook Link
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={updateOffer?.facebookLink}
                            name="facebookLink"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            {' '}
                            Instagram Link
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={updateOffer?.instagramLink}
                            name="instagramLink"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            {' '}
                            Youtube Link
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={updateOffer?.youtubeLink}
                            name="youtubeLink"
                            className="form-control"
                        />
                    </div>

                    <div className="col-12 mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={updateOffer?.offerDetails}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Expires Date
                        </label>
                        <input
                            type="date"
                            name="expiresDate"
                            onChange={handleChange}
                            value={updateOffer?.expiresDate}
                            className="form-control"
                        />
                    </div>
                    <button type="submit" className="mt-4 btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardDigitalLoyaltyEditModal;
