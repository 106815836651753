import React, { useEffect, useState } from 'react'
import { useLoaderData, useLocation } from 'react-router-dom';
import EasyServiceProductCard from '../../../components/EasyServiceProductCard/EasyServiceProductCard';
import { useTranslation } from "react-i18next";
import i18n from "i18next";


function DetailsServiceProduct() {
    const data = useLoaderData();
    const [banglatranslate, setBanglaTraslate] = useState();

    const { t } = useTranslation();
    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    return (
        <div className='service-container'>
            <div className='body-container secondary-container'>
                <h1 class="title banner-title text-center">{banglatranslate ==='bn' ? data?.data?.easyLifeProductList[0]?.productCategoryBn : data?.data?.easyLifeProductList[0]?.productCategory}</h1>

                <div className="serviceProduct-grid">
                    {data?.data?.easyLifeProductList?.map((allProduct) => (
                        <EasyServiceProductCard
                            allProduct={allProduct}
                            key={allProduct?._id}
                            banglatranslate={banglatranslate}
                            t={t}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default DetailsServiceProduct