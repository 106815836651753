import { useQuery } from '@tanstack/react-query';
import { default as i18n, default as i18next } from 'i18next';
import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useTranslation } from 'react-i18next';
import { BiSupport } from 'react-icons/bi';
import { BsCalculator, BsCashStack } from 'react-icons/bs';
import { GiFruitTree, GiHamburgerMenu } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';
import { MdOutlineLocationOn } from 'react-icons/md';
import { TbFilePencil } from 'react-icons/tb';
import { Link, NavLink } from 'react-router-dom';
import LogoImage from '../../../../assets/logo/logo-1.png';
import AsideBar from '../../../../components/AsideBar/AsideBar';
import LanguageDropdown from '../../../../components/Language/LanguageDropdown';
import Loading from '../../../../components/Loading/Loading';
import Translate from '../../../Translate/Translate';
import '../header.css';
import './menuOffcanvas.css';

const EasylifeMenuOffcanvas = ({ name, ...props }) => {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // language
    const handleClick = (e) => {
        i18next.changeLanguage(e.target.value);
        window.location.reload();
        localStorage.setItem('lang', e.target.value);
    };
    return (
        <>
            <span
                onClick={handleShow}
                className="d-flex align-items-center gap-2"
            >
                <GiHamburgerMenu className="nav-icon" />{' '}
                <span>{t('Menu')}</span>
            </span>
            <Offcanvas
                className="offcanvas-dialogs"
                show={show}
                onHide={handleClose}
                {...props}
            >
                {/* closeButton */}
                <Offcanvas.Header className="px-md-0  px-lg-5 py-1">
                    <Offcanvas.Title className="w-100">
                        <nav className="first-nav py-0">
                            <div className="d-md-none">
                                <Link to="/home">
                                    <img
                                        className="logo-img"
                                        src={LogoImage}
                                        alt="logo"
                                    />
                                </Link>
                            </div>
                            <div className="left-menu">
                                <li className="menu-nav d-flex d-lg-none align-items-center gap-2">
                                    <LanguageDropdown
                                        onChange={(e) => handleClick(e)}
                                    />
                                </li>
                                <li
                                    onClick={handleClose}
                                    className="menu-nav d-flex align-items-center gap-2"
                                >
                                    <GrClose className="nav-icon" />
                                    <span>Close</span>
                                </li>
                                <li className="menu-nav d-none d-md-flex align-items-center gap-2">
                                    <BiSupport className="nav-icon" />
                                    <span>{t('24/7 HOTLINE: 16622')}</span>
                                </li>
                                <li className="menu-nav d-none d-md-flex align-items-center gap-2">
                                    {/* <BsCalculator className="nav-icon" />
                  <span> Premium Calculator </span> */}

                                    {['end'].map((placement, idx) => (
                                        <AsideBar
                                            key={idx}
                                            placement={placement}
                                            name={placement}
                                        />
                                    ))}
                                </li>
                            </div>
                            <div className="d-none d-md-block">
                                <Link to="/home" onClick={handleClose}>
                                    <img
                                        className="logo-img"
                                        src={LogoImage}
                                        alt="logo"
                                    />
                                </Link>
                            </div>
                            <div className="right-menu">
                                {/* <li className="menu-nav d-flex align-items-center gap-2">
                  <Link
                    to="http://ocs.myguardianbd.com/"
                    className=" d-flex align-items-center gap-2"
                    target="_blank"
                    onClick={handleClose}
                  >
                    <TbFilePencil className="nav-icon" />
                    <span>{t("claims")}</span>
                  </Link>
                </li> */}
                                {/* <li className="menu-nav d-none d-md-flex align-items-center gap-2 home-text">
                  <Link
                    to="https://myguardianbd.com/"
                    className="d-flex align-items-center gap-2"
                    target="_blank"
                    onClick={handleClose}
                  >
                    <GiFruitTree className="nav-icon" />
                    <span>{t("My Guardian")}</span>
                  </Link>
                </li> */}
                                <li className="menu-nav d-flex align-items-center gap-2">
                                    <Link
                                        to="/payment"
                                        className=" d-flex align-items-center gap-2"
                                        onClick={handleClose}
                                    >
                                        <BsCashStack className="nav-icon" />
                                        <span>{t('Payment channels')}</span>
                                    </Link>
                                </li>
                                <li className="menu-nav d-flex align-items-center gap-2">
                                    <Link
                                        to="/locate-branch"
                                        className="menu-nav d-flex align-items-center gap-2"
                                        onClick={handleClose}
                                    >
                                        <MdOutlineLocationOn className="nav-icon" />
                                        <span>{t('Locate Branch')}</span>
                                    </Link>
                                </li>
                                <li className="menu-nav d-flex align-items-center gap-2">
                                    {/* <span>EN</span>
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label> */}

                                    {/* <Translate /> */}
                                </li>
                            </div>
                        </nav>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="body-color">
                    <div className="easylife-menu-items">
                        <div>
                            <h6 className="menu-items-title mb-4">
                                {t('footerLink')}
                            </h6>
                            <ul>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/easylife/home"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('home')}</span>
                                    </NavLink>
                                </li>
                                {/* <li className="nav-items-list mb-2 ">
                  <NavLink
                    to="/easylife/about-easy-life"
                    className={({ isActive }) =>
                      isActive
                        ? `nav-items-list-color navActive`
                        : `nav-items-list-color`
                    }
                    onClick={handleClose}
                  >
                    <span>About Us</span>
                  </NavLink>
                </li> */}

                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/digital-loyalty-offer"
                                        target="__blank"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('digitalOffers')}</span>
                                    </NavLink>
                                </li>
                                {/* <li className="nav-items-list mb-2 ">
                  <NavLink
                    to="/easylife/adc"
                    className={({ isActive }) =>
                      isActive
                        ? `nav-items-list-color navActive`
                        : `nav-items-list-color`
                    }
                    onClick={handleClose}
                  >
                    <span>{t("ADC")}</span>
                  </NavLink>
                </li> */}
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="https://acps.myguardianbd.com/easy_life/client_login/"
                                        target="_blank"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('claims')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/easylife/products/term-life-insurance"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>
                                            {' '}
                                            {t(
                                                'easylifeAboutUsServiceOffermenu1'
                                            )}
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/easylife/products/savings-plan"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>
                                            {' '}
                                            {t(
                                                'easylifeAboutUsServiceOffermenu2'
                                            )}
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/easylife/products/women's-savings-plan"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>
                                            {' '}
                                            {t(
                                                'easylifeAboutUsServiceOffermenu3'
                                            )}
                                        </span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/easylife/easy-life-cancer-care-plan"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('CancerCare')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/easylife/guardian-accident-care"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>
                                            {t(
                                                'easylifeAboutUsServiceOffermenu6'
                                            )}
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h6 className="menu-items-title mb-4">
                                {t('Quick Service')}
                            </h6>
                            <ul>
                                <li className="nav-items-list mb-2 ">
                                    <div
                                        className="nav-items-list-color"
                                        style={{
                                            cursor: 'pointer',
                                            width: '200px',
                                        }}
                                        // onClick={handleClose}
                                    >
                                        {['end'].map((placement, idx) => (
                                            <AsideBar
                                                key={idx}
                                                placement={placement}
                                                name={placement}
                                            />
                                        ))}
                                    </div>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        target="_blank"
                                        to="/easylife/bmi-calculator"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('BMICalculator')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        target="_blank"
                                        to="http://ocs.myguardianbd.com/"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('onlineClaim')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        target="_blank"
                                        to="https://acps.myguardianbd.com/claim/online/gop/request/"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('claimCashless')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        target="_blank"
                                        to="https://acps.myguardianbd.com/claim/online/track/"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('Track your Claim')}</span>
                                    </NavLink>
                                </li>
                                {/* <li className="nav-items-list mb-2 ">
                  <NavLink
                    target="_blank"
                    to="https://myguardianbd.com/"
                    className={({ isActive }) =>
                      isActive
                        ? `nav-items-list-color navActive`
                        : `nav-items-list-color`
                    }
                    onClick={handleClose}
                  >
                    <span>{t("My Guardian")}</span>
                  </NavLink>
                </li> */}
                            </ul>
                        </div>

                        <div>
                            <h6 className="menu-items-title mb-4">
                                {t('SUPPORT')}
                            </h6>
                            <ul>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/easylife/easy-life-contact"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('easyContact')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/easylife/faq"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                    >
                                        <span>{t('faq')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/easylife/privacy-policy"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('privacy')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="https://saleslead.myguardianbd.com/tax-certificate/EASYLIFE"
                                        target="_blank"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('taxCertificate')}</span>
                                    </NavLink>
                                </li>
                                <li className="nav-items-list mb-2 ">
                                    <NavLink
                                        to="/career"
                                        className={({ isActive }) =>
                                            isActive
                                                ? `nav-items-list-color navActive`
                                                : `nav-items-list-color`
                                        }
                                        onClick={handleClose}
                                    >
                                        <span>{t('career')}</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default EasylifeMenuOffcanvas;
