import React from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import SinglePositionBanner from './SinglePositionBanner';
import SinglePositionDetails from './SinglePositionDetails';
import SinglePositionApplyToday from './SinglePositionApplyToday';
import useTitle from '../../../hooks/useTitle';

function SingleCareerPosition() {
    const { data } = useLoaderData();

    useTitle(data?.positionName);
    return (
        <>
            <SinglePositionBanner data={data} />
            <div className="service-container bg-white">
                <div className="body-container secondary-container">
                    <div className="row gap-lg-5">
                        <div className="col-12 col-lg-7">
                            <SinglePositionDetails data={data} />
                        </div>
                        <div className="col-12 col-lg-4">
                            <SinglePositionApplyToday data={data} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SingleCareerPosition;
