import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { FaStepBackward } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import DashboardFAQList from "./DashboardFAQList";
import DashboardFAQModalView from "./DashboardFAQModalView";
import DashboardFAQModalEdit from "./DashboardFAQModalEdit";

function DashboardFAQView() {
  const [newAllFaq, setAllFaq] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [editModal, setEditModal] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const [updateFaq, setUpdateFaq] = useState(null);
  const [viewFaq, setViewFaq] = useState(null);

  const currentPage = useRef();

  useEffect(() => {
    currentPage.current = 1;
    getPaginationList();
  }, []);

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  const handleViewClose = () => setViewShow(false);
  const handleViewShow = () => setViewShow(true);
  const handleEditClose = () => setEditModal(false);
  const handleEditShow = () => setEditModal(true);

  // pagination
  function handlePageClick(e) {
    currentPage.current = e.selected + 1;
    getPaginationList();
  }

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/faq`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setAllFaq(data?.data?.faqList);
        setIsLoading(false);
      });
  }
  return (
    <div className="home-content">
      <div class="d-flex justify-content-between gap-2 align-items-center">
        <h1 className="dashboard-title">All FAQ</h1>
        <div>
          <Link
            to="/dashboard/faq"
            className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
          >
            <FaStepBackward />
            <span>Back</span>
          </Link>
        </div>
      </div>

      <div className="list-contaner">
        {newAllFaq?.length === 0 ? (
          <div className="min-vh-100 d-flex align-items-center justify-content-center">
            No Faq Is Available
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-light table-bordered">
                <thead>
                  <tr className="table-tr">
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Serial No.
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Page Name
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Question
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Answer
                    </th>

                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {newAllFaq?.map((newFaq, i) => (
                    <DashboardFAQList
                      key={newFaq._id}
                      newFaq={newFaq}
                      idx={i}
                      handleEditShow={handleEditShow}
                      handleViewShow={handleViewShow}
                      setUpdateFaq={setUpdateFaq}
                      setViewFaq={setViewFaq}
                      getPaginationList={getPaginationList}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        <DashboardFAQModalEdit
          editModal={editModal}
          handleEditClose={handleEditClose}
          updateFaq={updateFaq}
          setUpdateFaq={setUpdateFaq}
          getPaginationList={getPaginationList}
        />

        <DashboardFAQModalView
          viewShow={viewShow}
          handleViewClose={handleViewClose}
          viewFaq={viewFaq}
        />
      </div>
    </div>
  );
}

export default DashboardFAQView;
