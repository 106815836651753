import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { BsFillEyeFill } from "react-icons/bs";

const pageName = [
  {
    id: 1,
    page: "Home",
  },
  {
    id: 2,
    page: "About Us",
  },
  {
    id: 3,
    page: "Shareholder List",
  },
  {
    id: 4,
    page: "Retail",
  },
  {
    id: 5,
    page: "Savings",
  },
  {
    id: 5,
    page: "Early Cash",
  },
  {
    id: 7,
    page: "Children",
  },
  {
    id: 8,
    page: "Retirement",
  },
  {
    id: 9,
    page: "Investment",
  },
  {
    id: 10,
    page: "Term Life",
  },
  {
    id: 11,
    page: "Supplementary Benefits",
  },
  {
    id: 12,
    page: "Guardian Sanchay",
  },
  {
    id: 13,
    page: "Guardian Probridhi",
  },
  {
    id: 14,
    page: "Guardian Somridhi",
  },
  {
    id: 15,
    page: "Guardian Money Back Term",
  },
  {
    id: 16,
    page: "Guardian 3 Stage Plan",
  },
  {
    id: 17,
    page: "Guardian 4 Stage Plan",
  },
  {
    id: 18,
    page: "Guardian 5 Stage Plan",
  },
  {
    id: 19,
    page: "Child Protection Plan",
  },
  {
    id: 20,
    page: "Guardian Projonmo",
  },
  {
    id: 21,
    page: "Nishchit Pension Plan",
  },
  {
    id: 22,
    page: "Ajibon Pension Plan",
  },
  {
    id: 23,
    page: "Guardian Surokkha",
  },
  {
    id: 24,
    page: "Guardian Shield",
  },
  {
    id: 25,
    page: "Guardian Health Insurance",
  },
  {
    id: 26,
    page: "Critical Illness Plan",
  },
  {
    id: 27,
    page: "Permanent Disability Accidental Benefit (PDAB)",
  },
  {
    id: 28,
    page: "Double Indemnity Accidental Benefit (DIAB)",
  },
  {
    id: 30,
    page: "Corporate",
  },
  {
    id: 31,
    page: "Microinsurance",
  },
  {
    id: 32,
    page: "Bancassurance",
  },
  {
    id: 33,
    page: " Claims",
  },
  {
    id: 34,
    page: "Digital Loyalty Card",
  },
  {
    id: 35,
    page: "Partner Channels",
  },
  {
    id: 36,
    page: "bKash",
  },
  {
    id: 37,
    page: "bKash RMG",
  },
  {
    id: 38,
    page: "Nagad",
  },
  {
    id: 39,
    page: "Upay",
  },
  {
    id: 40,
    page: "OK Wallet",
  },
  {
    id: 41,
    page: "Carnival",
  },
  {
    id: 42,
    page: "Jotno",
  },
  {
    id: 43,
    page: "RobiShop",
  },
  {
    id: 44,
    page: "Truck Lagbe",
  },

  {
    id: 45,
    page: "Frequently Asked Questions",
  },
  {
    id: 46,
    page: "Form Library",
  },
  {
    id: 47,
    page: "Easy Life Home",
  },
  {
    id: 48,
    page: "Term Life Insurance",
  },
  {
    id: 49,
    page: "Savings Plan",
  },
  {
    id: 50,
    page: "Women's Savings Plan",
  },
  {
    id: 51,
    page: "Cancer Care",
  },
];

function AddSEO({ show, handleClose, getPaginationList }) {
  const intailsVlaue = {
    pages: "",
    title: "",
    description: "",
  };
  const [inputValue, setInputValue] = useState("");
  const [dataList, setDataList] = useState([]);
  const [addSeo, setAddSeo] = useState(intailsVlaue);

  const handleChange = (e) => {
    setAddSeo({ ...addSeo, [e.target.name]: e.target.value });
  };

  // Handle button click
  const handleButtonClick = () => {
    // Push the input value to the dataList and reset the input field
    if (inputValue) {
      const newData = { keywordsName: inputValue };
      setDataList([...dataList, newData]);
      setInputValue("");
    }
  };

  // post
  const handleAddSEO = (e) => {
    e.preventDefault();
    handleClose();

    const newSEO = {
      pages: addSeo.pages,
      title: addSeo?.title,
      description: addSeo.description,
      keywords: dataList,
    };
    console.log(newSEO);

    fetch(`${process.env.REACT_APP_host}/api/v1/seo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newSEO),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status) {
          getPaginationList();
          console.log(data);
          toast.success("SEO added successfully");
          setAddSeo("");
          setDataList("");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Not added successfully");
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      scrollable={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          Add SEO
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <form onSubmit={handleAddSEO}>
          <div className="mb-3">
            <label className="form-label fw-bold">Select Page</label>
            <select onChange={handleChange} name="pages" class="form-select">
              <option value="" selected>
                Choses a Page
              </option>
              {pageName?.map((data, i) => (
                <option key={i} value={data?.page}>
                  {data?.page}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Title</label>
            <input
              type="text"
              name="title"
              onChange={handleChange}
              value={addSeo.title}
              className="form-control"
              required
              placeholder="Enter title"
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Keywords</label>
            <div className="d-flex align-items-center gap-3">
              <input
                type="text"
                name="keywords"
                className="form-control"
                placeholder="Enter keywords"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <button
                onClick={handleButtonClick}
                className="btn btn-sm btn-primary table-btn fw-semibold d-flex align-items-center gap-1"
              >
                <span>Add</span>
              </button>
            </div>

            {dataList?.length > 0 && (
              <p
                className="d-flex align-items-center gap-2 mt-2 fw-bold flex-wrap"
                style={{ fontSize: "12px" }}
              >
                KeyWords Item:
                {dataList?.map((data, i) => (
                  <span
                    className=""
                    key={i}
                    style={{
                      backgroundColor: "#F3F2F1",
                      padding: "2px 4px ",
                      borderRadius: "5px",
                      fontSize: "10px",
                    }}
                  >
                    {data?.keywordsName}
                  </span>
                ))}
              </p>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Description</label>

            <textarea
              class="form-control"
              name="description"
              onChange={handleChange}
              value={addSeo.description}
              required
              rows="3"
              placeholder="Enter discription"
            ></textarea>
          </div>

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddSEO;
