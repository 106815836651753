import React from 'react';
import BannerImg from '../../assets/easylife/Travel/StudiesTour.jpg';
import { FaCheckCircle } from 'react-icons/fa';
import {Link} from 'react-router-dom'
function StudiesTour() {

    const benifits = [
        'Emergency medical expenses and hospitalization: USD 75,000 for world worldwide',
        'Emergency dental care: USD 500 or Euro equivalent',
        'Cover for “COVID-19” is included.',
        'Transport & repatriation in case of illness or accident included',
        'Repatriation of mortal remains included',
        'Policy Term: 180 days',
        'Age range: 18 to 59 years'
    ];
    const target_link='https://adctest.myguardianbd.com/en/microsite/travel-insurance-registration/1/105';
    return (
        <div className="service-container" style={{ paddingTop: '0rem' }}>
            <div className="body-container secondary-container">
                <div>
                    <div className="banner-content flex-column flex-lg-row">
                        <div className="right-img">
                            <img src={BannerImg} alt="" />
                        </div>
                        <div className="left-content">
                            <h1 class="title banner-title text-start">
                               Studies Tour
                            </h1>
                            <p className="pt-5 fw-bold">
                            Expand your horizons and focus on learning with peace of mind. Guardian Travel Care (Overseas Mediclaim Policy) offers comprehensive protection for Bangladeshi students embarking on enriching study tours abroad. Whether attending a short-term program, participating in an exchange visit, or starting a full-degree program, unexpected medical situations can disrupt your academic journey. Guardian Travel Care ensures you are covered for medical emergencies, allowing you to focus on gaining valuable knowledge and experiences. 
                            </p>

                            <h3 className="h3 pt-3">How does it benefit you?</h3>
                            <ul className="text banner-text pb-0 pt-3">
                                {benifits.map((benifit)=>(
                                    <li key={Math.round()}>
                                        <div className="corporate-coverage-list">
                                            <div className="corporate-coverage-check">
                                                <FaCheckCircle />
                                            </div>
                                            <p style={{ fontSize: '14px' }}>
                                                <span>
                                                    {benifit}
                                                </span>
                                            </p>
                                        </div>
                                     </li>
                                ))}
                                
                            </ul>
                            <br></br>
                            <Link
                                    to={target_link}
                                    target="_blank"
                                    className="btn-book btns"
                                    style={{ width: '40%' }}
                                >
                                    Proceed
                            </Link>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudiesTour;
