import React, { useEffect, useState } from "react";
import "./premiumCalculator.css";
import Loading from "../../components/Loading/Loading";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function PremiumCalculatorDetails() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  // const [familyPlans, setFamilyPlans] = useState([]);
  // const [selectedDate, setSelectedDate] = useState(null);
  // //   const [isLoaging, setIsLoading] = useState(true);
  // const [showError, setShowError] = useState(false);

  // useEffect(() => {
  //   getFamillyPlans();
  // }, []);

  // useEffect(() => {
  //   makeAPICall();
  // }, []);

  const handleDateChange = (event) => {
    // const selected = new Date(event.target.value);
    // setSelectedDate(selected);
    // // Check if user is underage
    // const today = new Date();
    // const ageDiff = today.getFullYear() - selected.getFullYear();
    // const monthDiff = today.getMonth() - selected.getMonth();
    // const dayDiff = today.getDate() - selected.getDate();
    // if (
    //   ageDiff < 18 ||
    //   (ageDiff === 18 && (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)))
    // ) {
    //   setShowError(true);
    // } else {
    //   setShowError(false);
    // }
  };

  // async function getFamillyPlans() {
  //   try {
  //     const response = await axios.get(
  //       "http://api.myguardianbd.com/api/myGuardian/plans"
  //     );
  //     console.log(response);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const makeAPICall = async () => {
    // try {
    //   const response = await fetch(
    //     "https://api.myguardianbd.com/api/myGuardian/plans",
    //     {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Origin": "*",
    //         Accept: "application/json",
    //         Authorization: `Bearer ${process.env.REACT_APP_Token_Secret}`,
    //       },
    //     }
    //   );
    //   const data = await response.json();
    //   console.log(data);
    // } catch (e) {
    //   console.log(e);
    // }
  };

  // function getFamillyPlans() {
  //   fetch(" https://retail.myguardianbd.com/api/corporate/offices-list", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: `Bearer ${process.env.REACT_APP_Token_Secret}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);
  //       // setFamilyPlans(data?.data?.locateBranchList);
  //       // setIsLoading(false);
  //     });
  // }

  // if (isLoaging) {
  //   return (
  //     <div className="vh-100 d-flex">
  //       <Loading />
  //     </div>
  //   );
  // }

  return (
    <div className="mt-5 px-5">
      <h1>{t("welcome")}</h1>
      <p>{t("title")}</p>
      {/* <iframe
        width="100%"
        height="100%"
        src="https://test.guardianlife.com.bd/en/premium-calculator"
        frameBorder="0"
        allowFullScreen
      ></iframe> */}

      {/* <div className="premiumCalculator-contnet">
        <div className="mb-3">
          <label className="form-label fw-bold" htmlFor="birthdate">
            Date of Birth
          </label>
          <input
            onChange={handleDateChange}
            type="date"
            id="birthdate"
            className="form-control py-3"
          />

          {showError && (
            <p className="form-text text-danger">
              You must be 18 years of age or older.
            </p>
          )}
        </div>

        {selectedDate && !showError && (
          //   <p>Selected date: {selectedDate.toDateString()}</p>
          <div className="mb-3">
            <label className="form-label fw-bold" htmlFor="birthdate">
              Family protection plans eligibility depends on age
            </label>
            <select className="form-select py-3">
              <option value="" selected>
                Select a Family Protection Plan
              </option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        )}
      </div> */}
    </div>
  );
}

export default PremiumCalculatorDetails;
