import React, { useEffect } from 'react';
import { BiSupport } from 'react-icons/bi';
import { BsCashStack, BsSearch } from 'react-icons/bs';
import { MdOutlineLocationOn } from 'react-icons/md';
import { TbFilePencil } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import LogoImage from '../../../assets/logo/logo-1.png';
import MenuItems from './Dropdown/MenuItems';
import MenuOffcanvas from './Offcanvas/MenuOffcanvas';
import './header.css';

// import { menuData } from "./menuItemsData";
import { useQuery } from '@tanstack/react-query';
import { default as i18n, default as i18next } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GiFruitTree } from 'react-icons/gi';
import { GrLanguage } from 'react-icons/gr';
import AsideBar from '../../../components/AsideBar/AsideBar';
import LanguageDropdown from '../../../components/Language/LanguageDropdown';
import Loading from '../../../components/Loading/Loading';
import Translate from '../../Translate/Translate';

function Header() {
    const [headersData, setHeadersData] = useState([]);
    const [language, setLangusges] = useState();

    const [sticky, setSticky] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 200);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setLangusges(currentLang);
    }, []);

    // useEffect(() => {
    //   fetch(`${"/locales/en/header.json"}`)
    //     .then((res) => res.json())
    //     .then((data) => {
    //       setHeadersData(data.menuData);
    //     });
    // }, []);

    // language
    const handleClick = (e) => {
        i18next.changeLanguage(e.target.value);
        window.location.reload();
        localStorage.setItem('lang', e.target.value);
    };

    const url = `${process.env.REACT_APP_host}/api/v1/plans`;

    const { data: plansData = [], isLoading } = useQuery({
        queryKey: ['plansData'],
        queryFn: async () => {
            try {
                const res = await fetch(url);
                const data = await res.json();
                return data?.data;
            } catch (err) {
                console.log(err);
            }
        },
    });

    if (isLoading) {
        return <Loading></Loading>;
    }

    const menuData = [
        { id: 1, title: `${t('home')}`, link: '/' },
        {
            id: 2,
            title: `${t('aboutus')}`,
            link: '/about-us',
            classesName: '',
            submenu: [
                {
                    title: `${t('aboutus')}`,
                    link: '/about-us',
                    classesName: '',
                },

                {
                    title: `${t('chairmanmessage')}`,
                    link: '/chairman-message',
                    classesName: '',
                },
                {
                    title: `${t('boardofdirectors')}`,
                    link: '/board-directors',
                    classesName: '',
                },
                {
                    title: `${t('shareholderlist')}`,
                    link: '/shareholder-list',
                    classesName: '',
                },
                {
                    title: `${t('managementteam')}`,
                    link: '/management-team',
                    classesName: '',
                },
                {
                    title: `${t('extendedmanagementteam')}`,
                    link: '/extended-management-committee',
                    classesName: '',
                },
                {
                    title: `${t('stafflist')}`,
                    link: '/staff-list',
                    classesName: '',
                },
                {
                    title: `${t('agentList')}`,
                    link: '/agent-list',
                    classesName: '',
                },
                {
                    title: `${t('milestones')}`,
                    link: '/milestones',
                    classesName: '',
                },
                {
                    title: `${t('corevalues')}`,
                    link: '/core-values',
                    classesName: '',
                },
            ],
        },
        {
            id: 3,
            title: `${t('retail')}`,
            link: '/retail-product',
            classesName: '',

            submenu: [
                {
                    submenu: [],
                },
                // {
                //   id: 1,
                //   title: "Tax Certificate",
                //   link: "https://retail.myguardianbd.com/tax-certificate",
                //   classesName: "",
                //   target: "__blank",
                // },
            ],
        },
        {
            id: 4,
            title: `${t('corporate')}`,
            link: '/corporate-solutions',
            classesName: '',
        },
        {
            id: 5,
            title: `${t('microinsurance')}`,
            link: '/microinsurance',
            classesName: '',
        },
        {
            id: 6,
            title: `${t('bancassurance')}`,
            link: '/bancassurance',
            classesName: '',
        },
        {
            id: 7,
            title: `${t('digitalchannels')}`,
            // link: "",
            isLink: false,
            classesName: '',
            target: '__blank',
            submenu: [
                {
                    id: 1,
                    title: `${t('easyLife')}`,
                    link: '/easylife',
                    classesName: '',
                    // target: "__blank",

                    // submenu: [
                    //   {
                    //     title: "About Easy Life",
                    //     link: "/easylife/about-esay-life",
                    //     classesName: "",
                    //   },
                    //   {
                    //     title: "Products",
                    //     link: "/easylife/easy-life-product",
                    //     classesName: "",
                    //   },
                    //   {
                    //     title: "ADC",
                    //     link: "/easylife/adc",
                    //     classesName: "",
                    //   },
                    //   {
                    //     title: "Digital Offers",
                    //     link: "/easylife/easy-life-digital-offers",
                    //     classesName: "",
                    //   },
                    //   {
                    //     title: "Claim",
                    //     link: "https://acps.myguardianbd.com/easy_life/client_login/",
                    //     classesName: "",
                    //   },
                    //   {
                    //     title: " Cancer Care Plan ",
                    //     link: "/easylife/easy-life-cancer-care-plan",
                    //     classesName: "cancer-plan-care",
                    //   },
                    // ],
                },
                {
                    id: 2,
                    title: `${t('easylifeADCTitle')}`,
                    link: '/partnerchannels',
                    classesName: '',
                    target: '',
                },
            ],
        },

        {
            id: 8,
            title: `${t('claims')}`,
            link: '/claims',
            classesName: '',
            submenu: [
                {
                    id: 1,
                    title: `${t('onlineClaim')}`,
                    isLink: false,
                    classesName: '',
                    // target: "__blank",
                    submenu: [
                        {
                            id: 1,
                            title: `${t('Group Claim')}`,
                            link: 'https://acps.myguardianbd.com/client_login/',
                            classesName: '',
                            target: '__blank',
                        },
                        {
                            id: 2,
                            title: `${t('EasyLife Claim')}`,
                            link: 'https://acps.myguardianbd.com/easy_life/client_login/',
                            classesName: '',
                            target: '__blank',
                        },
                        {
                            id: 3,
                            title: `${t('Retail Claim')}`,
                            link: 'https://rms.myguardianbd.com/claims/verification-request-form',
                            classesName: '',
                            target: '__blank',
                        },
                    ],
                },
                {
                    id: 2,
                    title: `${t('claimCashless')}`,
                    link: 'https://acps.myguardianbd.com/claim/online/gop/request/',
                    classesName: '',
                    target: '__blank',
                },
                {
                    id: 3,
                    title: `${t('Track your Claim')}`,
                    link: 'https://acps.myguardianbd.com/claim/online/track/',
                    classesName: '',
                    target: '__blank',
                },
            ],
        },
        {
            id: 9,
            title: `${t('more')}`,
            isLink: false,
            classesName: '',
            submenu: [
                {
                    title: `${t('mediacorner')}`,
                    link: '/media-corner',
                    classesName: '',
                },
                {
                    title: `${t('digitalloyaltycard')}`,
                    link: '/digital-loyalty-offer',
                    classesName: '',
                },
                {
                    title: `${t('taxrebate')}`,
                    link: '/tax-rebate',
                    classesName: '',
                },
                {
                    title: `${t('support')}`,
                    link: '/form',
                    classesName: '',
                },
                {
                    title: `${t('noticeboard')}`,
                    link: '/notice-board',
                    classesName: '',
                },
                {
                    title: `${t('career')}`,
                    link: '/career',
                    classesName: '',
                },
                {
                    title: `${t('faq')}`,
                    link: '/faq',
                    classesName: '',
                },
            ],
        },
    ];

    return (
        <>
            <div
                className={`${
                    sticky ? ' first-nav-bg stickys' : 'first-nav-bg'
                }`}
            >
                {/* frist navbar */}
                <div className="nav-second-containers">
                    <nav className="first-nav ">
                        <div className="d-md-none">
                            <Link to="/home">
                                <img
                                    className="logo-img"
                                    src={LogoImage}
                                    alt="logo"
                                />
                            </Link>
                        </div>
                        <div className="left-menu ">
                            <li className="menu-nav d-flex align-items-center gap-2">
                                {['top'].map((placement, idx) => (
                                    <MenuOffcanvas
                                        key={idx}
                                        placement={placement}
                                        name={placement}
                                    />
                                ))}
                            </li>
                            <li className="menu-nav d-none d-md-flex align-items-center gap-2 home-text">
                                <BiSupport className="nav-icon" />
                                <span>{t('24/7 HOTLINE: 16622')}</span>
                            </li>

                            <li className="menu-nav d-none d-lg-flex align-items-center gap-2">
                                {/* <Link
                  to="https://test.guardianlife.com.bd/en/premium-calculator"
                  target="_blank"
                >
                  Premium Calculator
                </Link> */}
                                {['end'].map((placement, idx) => (
                                    <AsideBar
                                        key={idx}
                                        placement={placement}
                                        name={placement}
                                    />
                                ))}
                                {/* <BsCalculator className="nav-icon" />
              <span> Premium Calculator </span> */}
                            </li>
                        </div>
                        <div className="d-none d-md-block">
                            <Link to="/home">
                                <img
                                    className="logo-img"
                                    src={LogoImage}
                                    alt="logo"
                                />
                            </Link>
                        </div>
                        <div className="right-menu">
                            <li className="menu-nav d-flex align-items-center gap-2">
                                <Link
                                    to="/payment"
                                    className=" d-flex align-items-center gap-2"
                                >
                                    <BsCashStack className="nav-icon" />
                                    <span>{t('Payment channels')}</span>
                                </Link>
                            </li>
                            {/* <li className="menu-nav d-none d-md-flex align-items-center gap-2 home-text">
                <Link
                  to="https://myguardianbd.com/"
                  className="d-flex align-items-center gap-2"
                  target="_blank"
                >
                  <GiFruitTree className="nav-icon" />
                  <span>{t("My Guardian")}</span>
                </Link>
              </li> */}
                            <li className="menu-nav d-flex align-items-center gap-2">
                                <Link
                                    to="/locate-branch"
                                    className="menu-nav d-flex align-items-center gap-2"
                                >
                                    <MdOutlineLocationOn className="nav-icon" />
                                    <span> {t('Locate Branch')}</span>
                                </Link>
                            </li>
                            <li className="menu-nav d-flex align-items-center gap-2">
                                {/* <span>EN</span>
                <label className="switch">
                  <input onChange={(e) => handleClick(e)} type="checkbox" />
                  <span className="slider round"></span>
                </label> */}

                                {/* <Translate /> */}

                                <GrLanguage />
                                <LanguageDropdown
                                    onChange={(e) => handleClick(e)}
                                />
                            </li>
                        </div>
                    </nav>
                </div>
            </div>

            {/* second navbar */}
            <div className="second-nav d-none d-md-block ">
                <nav className="body-container secondary-container">
                    <ul className="nav-items">
                        {menuData.map((menu, index) => {
                            const depthLevel = 0;
                            return (
                                <MenuItems
                                    items={menu}
                                    // key={t(menu?.id)}
                                    key={index}
                                    plansData={plansData}
                                    depthLevel={depthLevel}
                                />
                            );
                        })}
                    </ul>
                </nav>
            </div>
        </>
    );
}

export default Header;
