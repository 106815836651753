import React, { useEffect, useRef, useState } from 'react';
import { IoMdAddCircle } from 'react-icons/io';
import Loading from '../../../components/Loading/Loading';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import BoardOfDirectorTable from './BoardOfDirectorTable';
import BoardOfDirectorAddModal from './BoardOfDirectorAddModal';
import BoardOfDirectorEditModal from './BoardOfDirectorEditModal';

function BoardOfDirector() {
    const [newAllDirectors, setAllDirectors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState(5);
    const [pageCount, setPageCount] = useState(1);
    const [editModal, setEditModal] = useState(false);
    const [addShow, setAddShow] = useState(false);
    const [updateDirector, setUpdateDirector] = useState(null);
    const [newDirector, setNewDirector] = useState({
        name: '',
        designation: '',
        nameBn: '',
        designationBn: '',
        image: '',
    });

    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    const handleAddClose = () => setAddShow(false);
    const handleAddShow = () => setAddShow(true);
    const handleEditClose = () => setEditModal(false);
    const handleEditShow = () => setEditModal(true);

    const handleChange = (e) => {
        setNewDirector({ ...newDirector, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setNewDirector({ ...newDirector, image: e.target.files[0] });
    };

    // post
    const handleAddAgentList = (e) => {
        e.preventDefault();
        handleAddClose();

        const formData = new FormData();

        formData.append('name', newDirector?.name);
        formData.append('nameBn', newDirector?.nameBn);
        formData.append('designation', newDirector?.designation);
        formData.append('designationBn', newDirector?.designationBn);
        formData.append('image', newDirector.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for (const value of formData.values()) {
            console.log(value);
        }

        axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/board-of-director`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    toast.success('Director added successfully');
                    setNewDirector('');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not added!!!!');
            });
    };

    // pagination
    function handlePageClick(e) {
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/board-of-director`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllDirectors(data?.data?.boardOfDirectorListLists);
                setIsLoading(false);
            });
    }

    return (
        <div className="home-content">
            <div className="d-flex flex-column align-items-center">
                <div className="w-100 d-flex justify-content-between">
                    <h1 className="dashboard-title">Board Of Director List</h1>
                    <button
                        onClick={handleAddShow}
                        className="btn btn-sm btn-primary d-flex align-items-center fw-semibold"
                    >
                        <IoMdAddCircle className="add-icon" />{' '}
                        <span>Add Director</span>
                    </button>
                </div>

                <div className="list-contaner w-100">
                    {newAllDirectors?.length === 0 ? (
                        <div className="min-vh-100 d-flex align-items-center justify-content-center">
                            No Management Director Is Available
                        </div>
                    ) : (
                        <>
                            <div className="table-responsive">
                                <table className="table table-light table-bordered">
                                    <thead>
                                        <tr className="table-tr">
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Photo
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Designation
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {newAllDirectors.map((newDirector) => (
                                            <BoardOfDirectorTable
                                                key={newDirector._id}
                                                newDirector={newDirector}
                                                handleEditShow={handleEditShow}
                                                setUpdateDirector={
                                                    setUpdateDirector
                                                }
                                                getPaginationList={
                                                    getPaginationList
                                                }
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}

                    {/* <div className="mt-4">
            <ReactPaginate
              breakLabel="......."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            />
          </div> */}

                    <BoardOfDirectorAddModal
                        show={addShow}
                        handleClose={handleAddClose}
                        handleChange={handleChange}
                        handleImage={handleImage}
                        handleAddAgentList={handleAddAgentList}
                        newDirector={newDirector}
                    />

                    <BoardOfDirectorEditModal
                        editModal={editModal}
                        handleEditClose={handleEditClose}
                        updateDirector={updateDirector}
                        setUpdateDirector={setUpdateDirector}
                        getPaginationList={getPaginationList}
                    />
                </div>
            </div>
        </div>
    );
}

export default BoardOfDirector;
