import React, { useEffect } from "react";
import "./clinetReview.css";
import BgBannerImg from "../../../assets/BG/bgLeft.png";
import ClinetImg from "../../../assets/image/defualtImage.png";
import ClinetImg2 from "../../../assets/image/clinet-img1.jpg";
import ClinetImg3 from "../../../assets/image/client-img3.jpg";
import { BsStarFill } from "react-icons/bs";
import ClinentReviewCard from "./ClinentReviewCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import LeftQuotation from "../../../assets/image/left-quotation.png";
import RightQuotation from "../../../assets/image/right-quotation.png";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const BgStyle = {
  backgroundImage: `url(${BgBannerImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};

function ClinetReview() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const clientReviews = [
    {
      id: 1,
      clientName: `${t("clinetName1")}`,
      iconStar: [
        { id: 11, icon: <BsStarFill /> },
        { id: 22, icon: <BsStarFill /> },
        { id: 33, icon: <BsStarFill /> },
        { id: 44, icon: <BsStarFill /> },
        { id: 55, icon: <BsStarFill /> },
      ],
      img: ClinetImg,
      desc: `${t("clientDes1")}`,
      destination: `${t("destination1")}`,
    },
    {
      id: 2,
      clientName: `${t("clinetName2")}`,
      iconStar: [
        { id: 11, icon: <BsStarFill /> },
        { id: 22, icon: <BsStarFill /> },
        { id: 33, icon: <BsStarFill /> },
        { id: 44, icon: <BsStarFill /> },
        { id: 55, icon: <BsStarFill /> },
      ],
      img: ClinetImg,
      desc: `${t("clientDes2")}`,
      destination: `${t("destination2")}`,
    },
    {
      id: 3,
      clientName: `${t("clinetName3")}`,
      iconStar: [
        { id: 11, icon: <BsStarFill /> },
        { id: 22, icon: <BsStarFill /> },
        { id: 33, icon: <BsStarFill /> },
        { id: 44, icon: <BsStarFill /> },
        { id: 55, icon: <BsStarFill /> },
      ],
      img: ClinetImg,
      desc: `${t("clientDes3")}`,
      destination: `${t("destination3")}`,
    },
    {
      id: 4,
      clientName: `${t("clinetName4")}`,
      iconStar: [
        { id: 11, icon: <BsStarFill /> },
        { id: 22, icon: <BsStarFill /> },
        { id: 33, icon: <BsStarFill /> },
        { id: 44, icon: <BsStarFill /> },
        { id: 55, icon: <BsStarFill /> },
      ],
      img: ClinetImg,
      desc: `${t("clientDes4")}`,
      destination: `${t("destination4")}`,
    },
    {
      id: 5,
      clientName: `${t("clinetName5")}`,
      iconStar: [
        { id: 11, icon: <BsStarFill /> },
        { id: 22, icon: <BsStarFill /> },
        { id: 33, icon: <BsStarFill /> },
        { id: 44, icon: <BsStarFill /> },
        { id: 55, icon: <BsStarFill /> },
      ],
      img: ClinetImg,
      desc: `${t("clientDes5")}`,
      destination: `${t("destination5")}`,
    },
  ];
  return (
    <div style={BgStyle}>
      <div className="secondary-container body-container clinet-review-content">
        <h1 className="title review-tilte"> {t("homeClientTitle")}</h1>

        <div className="client-review-slider">
          <Swiper
            slidesPerView={1}
            spaceBetween={22}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            autoplay={{ delay: 2000 }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@1.00": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@1.50": {
                slidesPerView: 3,
                spaceBetween: 22,
              },
            }}
            modules={[Navigation, Pagination, Autoplay]}
            className="mySwiper"
          >
            {clientReviews.map((clientReview) => (
              <SwiperSlide key={clientReview.id}>
                <ClinentReviewCard clientReview={clientReview} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default ClinetReview;
