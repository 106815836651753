import React from "react";
import TaxRebateBanner from "./TaxRebateBanner";
import TaxRebateDetailsBenefits from "./TaxRebateDetailsBenefits";
import TaxRebateThing from "./TaxRebateThing";
import TaxRebateWhyGuardian from "./TaxRebateWhyGuardian";
import useTitle from "../../hooks/useTitle";

function TaxRebate() {
  useTitle("Tax Rebate");
  return (
    <>
      <TaxRebateBanner />
      <TaxRebateDetailsBenefits />
      <TaxRebateThing />
      <TaxRebateWhyGuardian />
    </>
  );
}

export default TaxRebate;
