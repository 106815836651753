import React from 'react';
import nagad from '../../assets/payment/nagad - new logo.png';
import ab from '../../assets/payment/ab.png';
import bkash from '../../assets/payment/bKash.png';
import cell from '../../assets/payment/cell.png';
import m from '../../assets/payment/m.png';
import sureCash from '../../assets/payment/sureCash.png';
import tap from '../../assets/payment/tap.jpg';
import uPay from '../../assets/payment/uPay.png';
import wallet from '../../assets/payment/wallet.png';
import rocket from '../../assets/payment/Rocket.png';
import city_touch from '../../assets/payment/city.png';
import mtb from '../../assets/payment/mtb.png';

function MobileBanking() {
    return (
        <div className="body-container secondary-container my-5">
            <div className="payment-submenu">
                <img src={bkash} className=" payment-logo" alt="" />
                <img src={nagad} className=" payment-logo" alt="" />
                <img src={sureCash} className=" payment-logo" alt="" />
                <img src={uPay} className=" payment-logo" alt="" />
                <img src={ab} className=" payment-logo" alt="" />
                <img src={tap} className=" payment-logo" alt="" />
                <img src={wallet} className=" payment-logo" alt="" />
                <img src={cell} className=" payment-logo" alt="" />
                <img src={m} className=" payment-logo" alt="" />
                <img src={rocket} className=" payment-logo" alt="" />
                <img src={city_touch} className=" payment-logo" alt="" />
                <img src={mtb} className=" payment-logo" alt="" />
            </div>
        </div>
    );
}

export default MobileBanking;
