import React, { useRef, useState } from 'react';
import './dashboardStafflist.css';
import { BsSearch } from 'react-icons/bs';
import StaffListTable from './StaffListTable/StaffListTable';
import { useQuery } from '@tanstack/react-query';
import { IoMdAddCircle } from 'react-icons/io';
import ModalAddStaff from './ModalAddStaff/ModalAddStaff';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import Loading from '../../../components/Loading/Loading';
import { Navigate } from 'react-router-dom';
import EditStaffListModal from './EditStaffListModal/EditStaffListModal';
import ReactPaginate from 'react-paginate';
import { useEffect } from 'react';
import { getImageUrl } from '../../../hooks/imageUpload';

function DashboardStaffList() {
    const [newAllStaffList, setAllStaffLists] = useState([]);
    const [updateStaff, setUpdateStaff] = useState(null);
    const [show, setShow] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [search, setSearch] = useState('');
    const [pageCount, setPageCount] = useState(1);
    const [query, setQuery] = useState('');
    const [limit, setLimit] = useState(300);
    const [isLoading, setIsLoading] = useState(true);
    const [newStaff, setNewStaff] = useState({
        name: '',
        email: '',
        contactNumber: '',
        designation: '',
        department: '',
        image: '',
    });

    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleEditClose = () => setEditModal(false);
    const handleEditShow = () => setEditModal(true);
    const handleChange = (e) => {
        setNewStaff({ ...newStaff, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setNewStaff({ ...newStaff, image: e.target.files[0] });
    };

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    // pagination
    function handlePageClick(e) {
        console.log(e);
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function changeLimit() {
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/staff`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllStaffLists(data?.data?.staffLists);
                setIsLoading(false);
            });
    }

    // post staff
    const handleAddStaffList = (e) => {
        e.preventDefault();
        handleClose();

        const formData = new FormData();

        formData.append('name', newStaff.name);
        formData.append('email', newStaff.email);
        formData.append('contactNumber', newStaff.contactNumber);
        formData.append('designation', newStaff.designation);
        formData.append('department', newStaff.department);
        formData.append('image', newStaff.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        for (const value of formData.values()) {
            console.log(value);
        }

        axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/staff`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    console.log(res);
                    toast.success('Staff added successfully');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <div className="home-content">
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className="dashboard-title">Staff List</h1>
                    <button
                        onClick={handleShow}
                        className="btn btn-sm btn-primary d-flex align-items-center fw-semibold"
                    >
                        <IoMdAddCircle className="add-icon" />{' '}
                        <span>Add Staff</span>
                    </button>
                </div>
                <div className="list-contaner">
                    <div className="input-group mb-2">
                        <span
                            className="input-group-text search-icon"
                            id="search"
                        >
                            <BsSearch />
                        </span>
                        <input
                            // onChange={(e) => setQuery(e.target.value.toLowerCase())}
                            onChange={(e) => setSearch(e.target.value)}
                            type="text"
                            className="form-control search-field"
                            placeholder="Search by name, designation, department"
                            aria-label="sarech"
                            aria-describedby="search"
                        />
                    </div>

                    {newAllStaffList?.length === 0 ? (
                        <div className="min-vh-100 d-flex align-items-center justify-content-center">
                            No Staffs Avaliable
                        </div>
                    ) : (
                        <>
                            <div className="table-responsive">
                                <table className="table table-light table-bordered">
                                    <thead>
                                        <tr className="table-tr">
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                name
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Designation
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Department
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Contact
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Email
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {newAllStaffList
                                            ?.filter((item) => {
                                                return search.toLowerCase() ===
                                                    ''
                                                    ? item
                                                    : item.name
                                                          .toLowerCase()
                                                          .includes(search) ||
                                                          item.designation
                                                              .toLowerCase()
                                                              .includes(
                                                                  search
                                                              ) ||
                                                          item.department
                                                              .toLowerCase()
                                                              .includes(search);
                                            })
                                            ?.map((staffList) => (
                                                <StaffListTable
                                                    handleShow={handleEditShow}
                                                    setUpdateStaff={
                                                        setUpdateStaff
                                                    }
                                                    key={staffList._id}
                                                    staffList={staffList}
                                                    getPaginationList={
                                                        getPaginationList
                                                    }
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                marginPagesDisplayed={2}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
              /> */}
                        </>
                    )}
                </div>
            </div>
            <ModalAddStaff
                show={show}
                handleClose={handleClose}
                handleChange={handleChange}
                handleImage={handleImage}
                handleAddStaffList={handleAddStaffList}
                newStaff={newStaff}
                getPaginationList={getPaginationList}
            />
            <EditStaffListModal
                show={editModal}
                handleClose={handleEditClose}
                handleChange={handleChange}
                handleImage={handleImage}
                updateStaff={updateStaff}
                setUpdateStaff={setUpdateStaff}
                getPaginationList={getPaginationList}
            />
        </>
    );
}

export default DashboardStaffList;
