import JoditEditor from 'jodit-react';
import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

const jobTypes = [
    {
        id: 1,
        typeName: 'Intership',
    },
    {
        id: 2,
        typeName: 'Part Time',
    },
    {
        id: 3,
        typeName: 'Contractual',
    },
];

function DashboardEditJobPostModal({
    show,
    handleClose,
    getJobPostList,
    updateJobPost,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const editor = useRef(null);
    const [content, setContent] = useState(updateJobPost?.description);
    const [positionName, setPositionName] = useState(
        updateJobPost?.positionName
    );
    const [jobType, setJobType] = useState(updateJobPost?.jobType);
    const [address, setAddress] = useState(updateJobPost?.address);

    const handleUpdateAction = (e) => {
        e.preventDefault();
        handleClose();

        const newJob = {
            description: content,
            address: address,
            jobType: jobType,
            positionName: positionName,
        };

        console.log(newJob);

        fetch(
            `${process.env.REACT_APP_host}/api/v1/job/${updateJobPost?._id}`,
            {
                method: 'PATCH',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(newJob),
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.status) {
                    getJobPostList();
                    console.log(data);
                    toast.success('Job updated successfully');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not updated Job!!!!');
            });
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Job Update
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Position Name
                        </label>
                        <input
                            type="text"
                            onChange={(e) => setPositionName(e.target.value)}
                            defaultValue={updateJobPost?.positionName}
                            name="positionName"
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label  fw-bold">Job Type</label>
                        <select
                            onChange={(e) => setJobType(e.target.value)}
                            defaultValue={updateJobPost?.jobType}
                            name="jobType"
                            class="form-select"
                        >
                            <option value="">Choses a job type</option>

                            {jobTypes?.map((jobType) => (
                                <option key={jobType.id}>
                                    {jobType?.typeName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Address</label>
                        <input
                            type="text"
                            onChange={(e) => setAddress(e.target.value)}
                            defaultValue={updateJobPost?.address}
                            name="address"
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={updateJobPost?.description}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardEditJobPostModal;
