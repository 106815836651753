import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import UserRoleList from './UserRoleList';
const checkboxOptions = [
    { id: 1, label: 'Dashboard' },
    { id: 2, label: 'Plans' },
    { id: 3, label: 'Staff List' },
    { id: 4, label: 'Agent List' },
    { id: 5, label: 'Management Team' },
    { id: 6, label: 'Extended Management' },
    { id: 7, label: 'Board Of Director' },
    { id: 8, label: 'Milestones' },
    { id: 9, label: 'All Booking' },
    { id: 10, label: 'All Message' },
    { id: 11, label: 'Preferred Hospital' },
    { id: 12, label: 'Locate Branch' },
    { id: 13, label: 'Claims Form' },
    { id: 14, label: 'Form Library' },
    { id: 15, label: 'Partner Logo' },
    { id: 16, label: 'FAQ' },
    { id: 17, label: 'Loyalty Offer' },
    { id: 18, label: 'Media Corner' },
    { id: 19, label: 'Jobs' },
    { id: 20, label: 'EasyLife' },
    { id: 21, label: 'Partner Channels' },
    { id: 22, label: 'SEO' },
    { id: 23, label: 'Pop Up' },
    { id: 24, label: 'Leads' },
    { id: 25, label: 'Notice Board' },
    { id: 26, label: 'Bancassurance' },
];

function UserRole() {
    const [allUserRole, setAllUserRole] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [checkboxes, setCheckboxes] = useState([]);
    const [roles, setRoles] = useState({
        role: '',
    });

    useEffect(() => {
        getAllUserRole();
    }, []);

    const handleChange = (e) => {
        setRoles({ ...roles, [e.target.name]: e.target.value });
    };

    // / post
    const handleAddRole = (e) => {
        e.preventDefault();

        const newUser = {
            role: roles?.role,
            menuItems: checkboxes,
        };
        console.log(newUser);
        const token = localStorage.getItem('accessToken');

        fetch(`${process.env.REACT_APP_host}/api/v1/user-role`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `bearer ${token}`,
            },
            body: JSON.stringify(newUser),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data?.status) {
                    console.log(data);
                    getAllUserRole();
                    toast.success('User Role added successfully');
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('Not added successfully');
            });
    };

    function getAllUserRole() {
        const url = `${process.env.REACT_APP_host}/api/v1/user-role`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setAllUserRole(data?.data?.userRoles);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }
    return (
        <div className="home-content">
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center"> Add Role</h1>
                <form onSubmit={handleAddRole}>
                    <div className="mb-3">
                        <label className="form-label fw-bold">Role Name</label>
                        <input
                            type="text"
                            name="role"
                            onChange={handleChange}
                            className="form-control"
                            required
                            placeholder="Enter a role name"
                        />
                    </div>

                    <div className="my-4">
                        <div className="mb-3">
                            <label className="form-label fw-bold">
                                Module Name
                            </label>
                        </div>

                        <div className="row ms-2 g-3">
                            {checkboxOptions.map((option) => (
                                <div class="form-check col-md-4">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id={option.label}
                                        checked={checkboxes.includes(
                                            option.label
                                        )}
                                        onChange={() => {
                                            if (
                                                checkboxes.includes(
                                                    option.label
                                                )
                                            ) {
                                                setCheckboxes(
                                                    checkboxes.filter(
                                                        (label) =>
                                                            label !==
                                                            option.label
                                                    )
                                                );
                                            } else {
                                                setCheckboxes([
                                                    ...checkboxes,
                                                    option.label,
                                                ]);
                                            }
                                        }}
                                    />
                                    <label htmlFor={option.label}>
                                        {option.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
            <div className="mt-5">
                <div className="table-responsive">
                    <table className="table table-light table-bordered">
                        <thead>
                            <tr className="table-tr">
                                <th
                                    scope="col"
                                    className="text-center align-middle table-th"
                                >
                                    Serial No.
                                </th>
                                <th
                                    scope="col"
                                    className="text-center align-middle table-th"
                                >
                                    Role
                                </th>
                                <th
                                    scope="col"
                                    className="text-center align-middle table-th"
                                >
                                    Permission Items
                                </th>

                                <th
                                    scope="col"
                                    className="text-center align-middle table-th"
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {allUserRole?.map((user, i) => (
                                <UserRoleList
                                    user={user}
                                    i={i}
                                    getAllUserRole={getAllUserRole}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default UserRole;
