import React from "react";
import ExtendedManagementList from "./ExtendedManagementList";
import useTitle from "../../hooks/useTitle";

const ExtendedManagementCommittee = () => {
  useTitle("Extended Management Committee");
  return (
    <>
      <ExtendedManagementList />
    </>
  );
};

export default ExtendedManagementCommittee;
