import i18n from "i18next";
import React, { useEffect, useState } from "react";
import "./languageDropdown.css";

function LanguageDropdown(props) {
    const [language, setLangusges] = useState();
    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setLangusges(currentLang);
    }, []);
    return (
        <div>
            <select
                onChange={props.onChange}
                className="languages border-0 bg-transparent"
            >
                <option
                    value={'en'}
                    selected={language === 'en' ? true : false}
                >
                    English
                </option>
                <option
                    value={'bn'}
                    selected={language === 'bn' ? true : false}
                >
                    বাংলা
                </option>
            </select>
        </div>
    );
}

export default LanguageDropdown;
