import React from 'react';

const BancassuranceMenuTitleBn = ({ selected, data, onClick }) => {
    return (
        <div
            key={data._id}
            className={`ourPlans-contnet-text align-items-baseline ${
                selected ? 'selected' : ''
            }`}
            onClick={() => onClick(data._id, data)}
        >
            <div
                className="cicrle"
                style={{
                    backgroundColor: selected ? '#232176' : '',
                }}
            ></div>
            <div
                className="plans-text"
                style={{
                    color: selected ? '#232176' : '',
                }}
            >
                {data?.titleBn.split(' ').length === 2 ? (
                    data?.titleBn
                ) : (
                    <div>
                        {data?.titleBn.split(' ').slice(0, 2).join(' ')}{' '}
                        <br></br>{' '}
                        {data?.titleBn
                            .split(' ')
                            .slice(2, data?.titleBn.split(' ').length)
                            .join(' ')}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BancassuranceMenuTitleBn;
