import React from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import './managementTeam.css';

function ManagementTeamDetails({
    show,
    handleClose,
    showTeam,
    banglatranslate,
}) {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    {banglatranslate === 'bn'
                        ? showTeam?.nameBn
                        : showTeam?.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 ">
                <div>
                    <div className="managment-team-img">
                        <img className="w-100" src={showTeam?.imageURL} />
                    </div>

                    <Card.Body>
                        <div class="card-content mt-2">
                            <h4>
                                {' '}
                                {banglatranslate === 'bn'
                                    ? showTeam?.nameBn
                                    : showTeam?.name}
                            </h4>
                            <h6>
                                {banglatranslate === 'bn'
                                    ? showTeam?.designationBn
                                    : showTeam?.designation}
                            </h6>
                        </div>
                        <p className="banner-text" style={{ fontSize: '16px' }}>
                            {banglatranslate === 'bn' ? (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: showTeam?.descriptionBn,
                                    }}
                                ></span>
                            ) : (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: showTeam?.description,
                                    }}
                                ></span>
                            )}
                        </p>
                    </Card.Body>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ManagementTeamDetails;
