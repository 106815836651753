import { useState, useEffect, useRef } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

import Dropdown from './Dropdown';
import { Link, NavLink } from 'react-router-dom';
import PlansDropdown from './PlansDropdown';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const MenuItems = ({ items, depthLevel, plansData }) => {
    const [subMenuDropdown, setSubMenuDropdown] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [categorySubMenu, setCategorySubMenu] = useState(false);
    const [hoveredValue, setHoveredValue] = useState('');
    const [banglatranslate, setBanglaTraslate] = useState();

    let ref = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    useEffect(() => {
        const handler = (event) => {
            if (
                dropdown &&
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                setDropdown(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [dropdown]);

    useEffect(() => {
        const handler = (event) => {
            if (
                subMenuDropdown &&
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                setSubMenuDropdown(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [subMenuDropdown]);

    useEffect(() => {
        const handler = (event) => {
            if (
                categorySubMenu &&
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                setCategorySubMenu(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [categorySubMenu]);

    //  event1

    const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
    };

    const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
    };

    //  event2

    const onMouseEnter1 = () => {
        window.innerWidth > 960 && setSubMenuDropdown(true);
    };

    const onMouseLeave1 = () => {
        window.innerWidth > 960 && setSubMenuDropdown(false);
    };

    //  event3

    const onMouseEnter2 = () => {
        window.innerWidth > 960 && setCategorySubMenu(true);
    };

    const onMouseLeave2 = () => {
        window.innerWidth > 960 && setCategorySubMenu(false);
    };

    const handleMouseEnter = (event) => {
        const value = event.target.innerText;
        console.log(value);
        setHoveredValue(value);
    };

    return (
        <>
            <li
                className="nav-items-list"
                ref={ref}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {items.submenu ? (
                    <>
                        {items?.isLink === false ? (
                            <div
                                className="nav-items-link"
                                style={{ cursor: 'pointer' }}
                            >
                                {(banglatranslate === 'bn' && items.titleBn) ||
                                    items.title}
                                {depthLevel > 0 ? (
                                    <span>
                                        <MdKeyboardArrowDown className="nav-upIcon" />
                                    </span>
                                ) : (
                                    <span>
                                        <MdKeyboardArrowDown className="nav-upIcon" />
                                    </span>
                                )}
                            </div>
                        ) : (
                            <NavLink
                                to={
                                    items?._id
                                        ? `/retail/${items?.slug}`
                                        : items.link
                                }
                                aria-haspopup="menu"
                                aria-expanded={dropdown ? 'true' : 'false'}
                                onClick={() => setDropdown((prev) => !prev)}
                                className={({ isActive }) =>
                                    isActive
                                        ? `nav-items-link navActive`
                                        : `nav-items-link`
                                }
                            >
                                {(banglatranslate === 'bn' && items.titleBn) ||
                                    items.title}

                                {depthLevel > 0 ? (
                                    <span>
                                        <MdKeyboardArrowDown className="nav-upIcon" />
                                    </span>
                                ) : (
                                    <span>
                                        <MdKeyboardArrowDown className="nav-upIcon" />
                                    </span>
                                )}
                            </NavLink>
                        )}

                        {items?.title === 'Retail' ||
                        items.title === 'রিটেইল' ? (
                            <>
                                {/* <ul className={`dropdowns ${dropdown ? "show" : ""}`}>
                  {plansData.map((plansElm) => {
                    return (
                      <span>
                        <li
                          key={plansElm?._id}
                          className="nav-items-list"
                          ref={ref}
                          onMouseEnter={(e)=>{
                            onMouseEnter1();
                            handleMouseEnter(e)
                          }}
                          onMouseLeave={onMouseLeave1}
                        >
                          <>
                            {plansElm?.plansCategory?.length > 0 ? (
                              <>
                                <NavLink
                                  to={`/plan/${plansElm?._id}`}
                                  className={({ isActive }) =>
                                    isActive
                                      ? `nav-items-link navActive`
                                      : `nav-items-link`
                                  }
                                >
                                  {plansElm?.title}
                                  <span>
                                    <MdKeyboardArrowDown className="nav-upIcon" />
                                  </span>

                                  {plansElm.title === hoveredValue && (
                                    <>
                                      <ul
                                        className={`dropdowns dropdown-submenu1  ${subMenuDropdown ? "subShow" : ""
                                          }`}
                                      >
                                        {plansElm?.plansCategory?.map((elm) => {
                                          return (
                                            <li
                                              key={elm?._id}
                                              className="nav-items-list"
                                              ref={ref}
                                              onMouseEnter={onMouseEnter2}
                                              onMouseLeave={onMouseLeave2}
                                            >
                                              <NavLink
                                                to={`/catagory/${elm?._id}`}
                                                className={({ isActive }) =>
                                                  isActive
                                                    ? `nav-items-link navActive`
                                                    : `nav-items-link`
                                                }
                                              >
                                                {elm?.categoryTitle}
                                              </NavLink>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </>
                                  )}
                                </NavLink>
                              </>
                            ) : (
                              <>
                                <NavLink
                                  to={`/plan/${plansElm?._id}`}
                                  className={({ isActive }) =>
                                    isActive
                                      ? `nav-items-link navActive`
                                      : `nav-items-link`
                                  }
                                >
                                  {plansElm?.title}
                                </NavLink>
                              </>
                            )}
                          </>
                        </li>
                      </span>
                    );
                  })}
                </ul> */}
                                <Dropdown
                                    depthLevel={depthLevel}
                                    submenus={plansData}
                                    dropdown={dropdown}
                                ></Dropdown>
                            </>
                        ) : (
                            <>
                                <Dropdown
                                    depthLevel={depthLevel}
                                    submenus={items.submenu}
                                    dropdown={dropdown}
                                />
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {items?.isLink === false ? (
                            <div
                                className="nav-items-link"
                                style={{ cursor: 'pointer' }}
                            >
                                {(banglatranslate === 'bn' && items.titleBn) ||
                                    items.title}
                            </div>
                        ) : (
                            <NavLink
                                to={
                                    items?._id
                                        ? `/retail/${items?.slug}`
                                        : items.link
                                }
                                target={items?.target}
                                className={({ isActive }) =>
                                    isActive
                                        ? `nav-items-link navActive`
                                        : `nav-items-link`
                                }
                            >
                                {(banglatranslate === 'bn' && items.titleBn) ||
                                    items.title}
                            </NavLink>
                        )}
                    </>
                )}
            </li>
        </>
    );
};

export default MenuItems;
