import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BsSearch } from 'react-icons/bs';
import Loading from '../../components/Loading/Loading';

function Support() {
    const { t } = useTranslation();

    const [search, setSearch] = useState('');

    const [banglatranslate, setBanglatranslate] = useState('');

    const [metaTags, setMetaTags] = useState([]);

    const [formDatas, setFormDatas] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        //get the current language stored in the local storage

        let currentLang = localStorage.getItem('lang');

        i18n.changeLanguage(currentLang);

        setBanglatranslate(currentLang);
    }, []);

    useEffect(() => {
        getPaginationList();
    }, [metaTags]);

    useEffect(() => {
        getAllFormLibrary();
    }, []);

    function getAllFormLibrary() {
        const url = `${process.env.REACT_APP_host}/api/v1/form-library`;

        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())

            .then((data) => {
                setFormDatas(data?.data?.formLibraryList);

                setIsLoading(false);
            });
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/seo?pages=Form Library`;

        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())

            .then((data) => {
                setMetaTags(data?.data?.seo);

                setIsLoading(false);
            })

            .catch((err) => {
                console.log(err);

                setIsLoading(false);
            });
    }

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    return (
        <div>
            {metaTags?.map((data) => (
                <Helmet>
                    <title>{data?.title} - Guardian Life Insurance Ltd. </title>

                    <meta name="description" content={data?.description} />

                    <meta
                        name="keywords"
                        content={data?.keywords?.map(
                            (keys) => keys?.keywordsName
                        )}
                    />
                </Helmet>
            ))}

            <div className="career-banner pt-4 ">
                <div className="body-container secondary-container">
                    <Breadcrumb>
                        <Breadcrumb.Item className="faq-link">
                            Home
                        </Breadcrumb.Item>

                        <Breadcrumb.Item active className="faq-link">
                            Forms
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className="mt-5 body-container secondary-container text-center">
                    <div className="career-banner-content">
                        <h1>{t('support')}</h1>

                        <p>{t('formLibraryBannerDetails')}</p>
                    </div>
                </div>
            </div>

            <div className="service-container pt-5 bg-white">
                <div className="body-container secondary-container">
                    <div className="input-group mb-5">
                        <span
                            className="input-group-text search-icon"
                            id="search"
                        >
                            <BsSearch />
                        </span>

                        <input
                            onChange={(e) => setSearch(e.target.value)}
                            type="text"
                            className="form-control search-field"
                            placeholder={t('search')}
                            aria-label="sarech"
                            aria-describedby="search"
                        />
                    </div>

                    <div className="mt-3">
                        <table className="custom-table w-100">
                            <thead>
                                <tr>
                                    {/* <th>Category</th> */}

                                    <th>{t('fileName')}</th>

                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {formDatas

                                    ?.filter((item) => {
                                        return search.toLowerCase() === ''
                                            ? item
                                            : item?.formName
                                                  ?.toLowerCase()
                                                  .includes(search);
                                    })

                                    ?.map((data) => (
                                        <tr key={data?.id}>
                                            <td>
                                                {}

                                                {banglatranslate === 'bn'
                                                    ? data?.formNameBn
                                                    : data?.formName}
                                            </td>

                                            <td>
                                                <div className="w-100">
                                                    <a
                                                        href={`${data?.formLink}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="btn-book btns btn btn-primary form-table-btn"
                                                    >
                                                        {t('download')}
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}

                                {/* <tr>

                  <td>Declaration of Good Health Form</td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/1805304177.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>Application for Name Correction</td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/1791940688.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>Application for Rider Addition & Deletion </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/649125824.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>Application for policy revival </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/1382800458.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>

                    Application for changing Policy Term, Payment mode, Plan,

                    Sum Assured{" "}

                  </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/1318967000.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>Health Insurance Application Form </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://drive.google.com/file/d/12laX89qj6sCeo9bKPDK5Cr9e4wzSJ5YO/view?usp=sharing"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>Critical Illness-MDB Claim Form </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/1640109036.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>Death Claim Form</td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/1506386839.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>Health Insurance Claim Form </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/1171130058.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>Loan Application </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/1152105427.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>PPD-PTD Claim Form </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/1651519208.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>Application for contact detail changing </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/2032414289.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>

                    Application for Changing Nominee & Nominee's Guardian{" "}

                  </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/1069657422.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>Surrender Application Form </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/1273456139.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>UW amendment Form </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/916309337.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>Refund Application Form </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/support/formdocuments/1425485660.pdf"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>

                    Authorization form for Guardian Life Premium Payment through

                    EFT Debit

                  </td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://drive.google.com/file/d/1c-WVMZ27ic4iZoPMEid-hZtxULuHCF8H/view?usp=sharing"

                        target="_blank"

                        rel="noreferrer"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        Download

                      </a>

                    </div>

                  </td>

                </tr> */}
                            </tbody>
                        </table>

                        {/* <table className="custom-table w-100">

              <thead>

                <tr>

                  <th>{t("claimFormTableTH1")}</th>

                  <th>{t("claimFormTableTH2")}</th>

                  <th className="text-center">{t("claimFormTableTH3")}</th>

                </tr>

              </thead>

              <tbody>

                <tr>

                  <td rowSpan={5}>{t("claimFormTableTBodyT1")}</td>

                </tr>

                <tr>

                  <td>{t("claimFormTableTBodyT1L1")}</td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/form-file/168310635.pdf"

                        target="_blank"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        {t("claimFormTableDownloadBtn")}

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>{t("claimFormTableTBodyT1L2")}</td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/form-file/141254166.pdf"

                        target="_blank"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        {t("claimFormTableDownloadBtn")}

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>{t("claimFormTableTBodyT1L3")}</td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/form-file/949128657.pdf"

                        target="_blank"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        {t("claimFormTableDownloadBtn")}

                      </a>

                    </div>

                  </td>

                </tr>

                <tr>

                  <td>{t("claimFormTableTBodyT1L4")}</td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://dashboard.guardianlife.com.bd/uploads/form-file/207825169.pdf"

                        target="_blank"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        {t("claimFormTableDownloadBtn")}

                      </a>

                    </div>

                  </td>

                </tr>



                <tr>

                  <td rowSpan={3}>{t("claimFormTableTBodyT2")}</td>

                </tr>

                <tr>

                  <td>{t("claimFormTableTBodyT2L1")}</td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://drive.google.com/file/d/1o9oAWFnBIusuuZNNaaom-vMwCVnvoy_Y/view?usp=sharing"

                        target="_blank"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        {t("claimFormTableDownloadBtn")}

                      </a>

                    </div>

                  </td>

                </tr>



                <tr>

                  <td>{t("claimFormTableTBodyT3L1")}</td>

                  <td>

                    <div className="w-100">

                      <a

                        href="https://drive.google.com/file/d/1ZPgFSlNIZprt9vA1ZftWc_mBuQbpcfpr/view?usp=sharing"

                        target="_blank"

                        className="btn-book btns btn btn-primary form-table-btn"

                      >

                        {t("claimFormTableDownloadBtn")}

                      </a>

                    </div>

                  </td>

                </tr>

              </tbody>

            </table> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Support;
