import React, { useEffect } from "react";
import "./EasyLifeMobileAPP.css";
import MobileImage from "../../../assets/image/Opt-01.png";
import EasyLifeAndroid from "../../../assets/icon/google-play-icon.png";
import EasyLifeApple from "../../../assets/icon/apple-icon.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function EasyLifeMobileApp({ background }) {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  return (
    <div className={`mobileApp-container ${background}`}>
      <div className="body-container secondary-container mobile-app-content gap-2">
        <div className="mobile-app-left">
          <img src={MobileImage} alt="" />
        </div>
        <div className="mobile-app-right">
          <h1 className="title mobile-app-title">{t("DOWNLOADOURAPP")}</h1>
          <p className="text banner-text">{t("appDescription")}</p>
          <div className="d-flex aling-items-center gap-4 mt-3">
            <div className="app-area">
              <Link
                to="https://play.google.com/store/apps/details?id=com.sslwireless.easylife"
                target="_blank"
              >
                <div className="d-flex align-items-center gap-3">
                  <div className="easyLife-mobile-app-icon">
                    <img src={EasyLifeAndroid} alt="" />
                  </div>
                  <div className="text-white d-flex flex-column">
                    <p className="app-text-1">GET IT ON</p>
                    <p className="app-text-2">Google Play</p>
                  </div>
                </div>
              </Link>
            </div>
            {/* <div className="app-area">
              <Link
                to="https://apps.apple.com/us/app/easy-life-guardian/id1493112484"
                target="_blank"
              >
                <div className="d-flex align-items-center gap-3">
                  <div className="easyLife-mobile-app-icon">
                    <img src={EasyLifeApple} alt="" />
                  </div>
                  <div className="text-white d-flex flex-column">
                    <p className="app-text-1">Available on the</p>
                    <p className="app-text-2">App Store</p>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EasyLifeMobileApp;
