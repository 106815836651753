import React from 'react'
import { useLocation } from 'react-router-dom';
import EasyLifeOurOfferCard from './EasyLifeOurOfferCard';

function DigitalOfferViewAllCards() {
    const { state: data } = useLocation();
    return (
        <div className='service-container'>
            <div className='body-container secondary-container'>
                <h1 class="title banner-title text-center">{data?.menuTitle}</h1>

                <div className="serviceProduct-grid">
                    {data?.data?.map((allOffer) => (
                        <EasyLifeOurOfferCard
                            allOffer={allOffer}
                            key={allOffer?.id}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default DigitalOfferViewAllCards