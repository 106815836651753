import React from 'react';
import { Modal } from 'react-bootstrap';
import JoditEditor from 'jodit-react';

function DashboardExtendManagementAdd({
    show,
    handleClose,
    handleChange,
    handleImage,
    handleAddManagementList,
    newManagment,
    setContent,
    editor,
    content,
    setContentBn,
    editorBn,
    contentBn,
}) {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Add Director
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleAddManagementList} className="row">
                    <div className="mb-3 col-12">
                        <label className="form-label">Name</label>
                        <input
                            type="text"
                            name="name"
                            onChange={handleChange}
                            value={newManagment?.name}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Name(Bangla)</label>
                        <input
                            type="text"
                            name="nameBn"
                            onChange={handleChange}
                            value={newManagment?.nameBn}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3 col-12">
                        <label className="form-label">Designation</label>
                        <input
                            type="text"
                            name="designation"
                            className="form-control"
                            value={newManagment?.designation}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">
                            Designation(Bangla)
                        </label>
                        <input
                            type="text"
                            name="designationBn"
                            className="form-control"
                            value={newManagment?.designationBn}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Image</label>
                        <input
                            type="file"
                            onChange={handleImage}
                            name="image"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label fw-bold">
                            Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={content}
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label fw-bold">
                            Description(Bangla)
                        </label>

                        <JoditEditor
                            ref={editorBn}
                            value={contentBn}
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardExtendManagementAdd;
