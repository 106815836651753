import React, { useEffect } from 'react';
import './asideBar.css';
import { BsCalculator } from 'react-icons/bs';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState } from 'react';
import Buttons from '../Buttons/Buttons';
import { useForm } from 'react-hook-form';
import BgBannerImg from '../../assets/BG/bgLeft.png';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const BgStyle = {
    backgroundImage: `url(${BgBannerImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
};

function AsideBar({ name, ...props }) {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const handleCalculator = (data) => {};
    return (
        <>
            <span
                onClick={handleShow}
                className="d-flex align-items-center gap-2"
            >
                <BsCalculator className="nav-icon" />
                <span>{t('Premium Calculator')} </span>
            </span>

            <Offcanvas
                show={show}
                onHide={handleClose}
                {...props}
                className="calculator-offcanvas px-4"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        <h5 className="calculator-offcanvas-title ">
                            PREMIUM CALCULATOR
                        </h5>
                        <div className="vh-100">
                            <iframe
                                width="100%"
                                height="100%"
                                src="https://test.guardianlife.com.bd/en/premium-calculator"
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                        </div>
                        
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default AsideBar;
