import React, { useEffect, useState } from "react";
import "./dashboard.css";
import Loading from "../../../components/Loading/Loading";

function Dashboard() {
  const [newAllStaffList, setAllStaffLists] = useState([]);
  const [newAllAgentList, setAllAgentLists] = useState([]);
  const [newAllBooking, setAllBooking] = useState([]);
  const [newAllJobApply, setAllJobApply] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // get all staffList
  const url = `${process.env.REACT_APP_host}/api/v1/staff`;

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setAllStaffLists(data?.data);
        setIsLoading(false);
      });
  }, [newAllStaffList]);

  // get all agentList
  useEffect(() => {
    fetch(`${process.env.REACT_APP_host}/api/v1/agent`)
      .then((res) => res.json())
      .then((data) => {
        setAllAgentLists(data?.data);
        setIsLoading(false);
      });
  }, [newAllAgentList]);

  // get all booking
  useEffect(() => {
    fetch(`${process.env.REACT_APP_host}/api/v1/booking`)
      .then((res) => res.json())
      .then((data) => {
        setAllBooking(data?.data);
        setIsLoading(false);
      });
  }, [newAllBooking]);

  // get all job apply
  useEffect(() => {
    fetch(`${process.env.REACT_APP_host}/api/v1/all-jobApplyList`)
      .then((res) => res.json())
      .then((data) => {
        setAllJobApply(data?.data);
        setIsLoading(false);
      });
  }, [newAllJobApply]);

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="home-content">
      <div className="overview-boxes">
        <div className="box">
          <div className="right-side">
            <div className="box-topic">Total Agent</div>
            <div className="number">{newAllAgentList?.totalAgnetLists}</div>
            <div className="indicator">
              <i className="bx bx-up-arrow-alt"></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className="bx bx-cart-alt cart"></i>
        </div>
        <div className="box">
          <div className="right-side">
            <div className="box-topic">Total Staff</div>
            <div className="number">{newAllStaffList?.totalStaffLists}</div>
            <div className="indicator">
              <i className="bx bx-up-arrow-alt"></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className="bx bxs-cart-add cart two"></i>
        </div>
        <div className="box">
          <div className="right-side">
            <div className="box-topic">Total Booking</div>
            <div className="number">{newAllBooking?.totalBookingLists}</div>
            <div className="indicator">
              <i className="bx bx-up-arrow-alt"></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className="bx bx-cart cart three"></i>
        </div>
        <div className="box">
          <div className="right-side">
            <div className="box-topic">Total Apply</div>
            <div className="number">{newAllJobApply?.totalJobApplyLists}</div>
            <div className="indicator">
              <i className="bx bx-down-arrow-alt down"></i>
              <span className="text">Up from yesterday</span>
            </div>
          </div>
          <i className="bx bxs-cart-download cart four"></i>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
