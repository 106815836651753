import React from 'react';
import BgBannerImg from "../../../../assets/BG/bgLeft.png";
import BannerImg from "../../../../assets/easylife/banner/banner-9.jpg";


const BgStyle = {
    backgroundImage: `url(${BgBannerImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
};

const bannerItems = [
    {
        id: 1,
        title: `Privacy Policy`,
        desc: `Easylife is a digital platform of Guardian Life Insurance shall apply to use and disclosure of sensitive personal data or information and personal information ("Personal Information") provided by You (which means and include as a Customer or a User) on the Website or otherwise at the time of client acceptance / registration/application in connection with availing products or services or facilities of Easylife. This privacy policy shall be read in conjunction with the Terms of Use (Terms and Conditions) agreed by you while registering on its Website for availing its services.`,
        img: BannerImg,
    },
];

function EasyLifePrivacyPolicyBanner() {
    return (
        <div style={BgStyle}>
            <div className="body-container secondary-container banner-container">
                {bannerItems.map((bannerItem) => (
                    <div key={bannerItem.id} className="banner-content">
                        <div className="left-content">
                            <h1 className="title banner-title">{bannerItem?.title}</h1>
                            <p className="text banner-text">{bannerItem?.desc}</p>
                        </div>
                        <div className="right-img">
                            <img src={bannerItem?.img} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default EasyLifePrivacyPolicyBanner