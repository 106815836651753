import React from 'react';
import EasyLifeFAQBanner from './EasyLifeFAQBanner';
import EasyLifeFAQSubmenu from './EasyLifeFAQSubmenu';
import EasyLifeMobileApp from '../../EasyLifeContact/EasyLifeMobileApp';
import useEasylifeTitle from '../../../../hooks/useEasylifeTitle';

const EasyLifeFAQ = () => {
    useEasylifeTitle("FAQ");

    return (
        <>
            <EasyLifeFAQBanner />
            <EasyLifeFAQSubmenu />
            <EasyLifeMobileApp />
        </>
    );
};

export default EasyLifeFAQ;