import React, { useEffect, useState } from "react";
import UserList from "./UserList";
import Loading from "../../../../components/Loading/Loading";
import { toast } from "react-hot-toast";

function UserCreate() {
  const [allUser, setAllUser] = useState([]);
  const [allUserRole, setAllUserRole] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  const [addUser, setAddUser] = useState({
    role: "",
    email: "",
  });
  useEffect(() => {
    getPaginationList();
    getAllUserRole();
  }, []);

  const handleChange = (e) => {
    setAddUser({ ...addUser, [e.target.name]: e.target.value });
  };

  const handleChangeNumber = (e) => {
    const newPassword = e.target.value;
    setInputValue(newPassword);

    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    const symbolRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
    const minLength = 6;

    // Check if the password meets all criteria
    const isUppercaseValid = uppercaseRegex.test(newPassword);
    const isLowercaseValid = lowercaseRegex.test(newPassword);
    const isNumberValid = numberRegex.test(newPassword);
    const isSymbolValid = symbolRegex.test(newPassword);
    const isLengthValid = newPassword.length >= minLength;

    // Set isValid to true if all criteria are met
    setErrorMessage(
      isUppercaseValid &&
        isLowercaseValid &&
        isNumberValid &&
        isSymbolValid &&
        isLengthValid
    );
  };

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/user`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setAllUser(data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  function getAllUserRole() {
    const url = `${process.env.REACT_APP_host}/api/v1/user-role`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setAllUserRole(data?.data?.userRoles);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  // / post
  const handleAddUser = (e) => {
    e.preventDefault();

    const newUser = {
      role: addUser?.role,
      email: addUser?.email,
      password: inputValue,
      status: "active",
    };
    console.log(newUser);
    const token = localStorage.getItem("accessToken");

    fetch(`${process.env.REACT_APP_host}/api/v1/user/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `bearer ${token}`,
      },
      body: JSON.stringify(newUser),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status) {
          console.log(data);
          getPaginationList();
          toast.success("User added successfully");
          //   setAddUser("");
          //   setInputValue("");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Not added successfully");
      });
  };

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }
  return (
    <div className="home-content">
      <div className="shadow rounded main-contents">
        <h1 className="dashboard-title text-center"> Add User</h1>
        <form onSubmit={handleAddUser}>
          <div className="mb-3">
            <label className="form-label fw-bold">Select Role</label>
            <select
              onChange={handleChange}
              name="role"
              class="form-select text-capitalize"
            >
              <option value="" selected>
                Choses a Role
              </option>

              {allUserRole?.map((roles) => (
                <option className="" value={roles?.role} key={roles?._id}>
                  {roles?.role}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Email</label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              className="form-control"
              required
              placeholder="example@gamil.com"
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Password</label>
            <input
              type="text"
              name="password"
              value={inputValue}
              onChange={handleChangeNumber}
              className="form-control"
              required
              placeholder="Password"
            />
            {errorMessage ? (
              <p className="valid" style={{ color: "green", fontSize: "12px" }}>
                Password is valid
              </p>
            ) : (
              <p className="invalid" style={{ color: "red", fontSize: "12px" }}>
                Password must contain at least one uppercase letter, one
                lowercase letter, one number, one symbol, and be at least 6
                characters long.
              </p>
            )}
          </div>

          <button type="submit" className="btn btn-primary px-4">
            Save
          </button>
        </form>
      </div>
      <div className="mt-5">
        <div className="table-responsive">
          <table className="table table-light table-bordered">
            <thead>
              <tr className="table-tr">
                <th scope="col" className="text-center align-middle table-th">
                  Serial No.
                </th>
                <th scope="col" className="text-center align-middle table-th">
                  Email
                </th>
                <th scope="col" className="text-center align-middle table-th">
                  Role
                </th>

                <th scope="col" className="text-center align-middle table-th">
                  Status
                </th>
                <th scope="col" className="text-center align-middle table-th">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {allUser?.map((user, i) => (
                <UserList
                  user={user}
                  i={i}
                  getPaginationList={getPaginationList}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default UserCreate;
