import React from 'react';
import { useLocation } from 'react-router-dom';
import EasyLifeMediaDetails from './EasyLifeMediaDetails';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import Loading from '../../../components/Loading/Loading';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

function AboutEasylifeViewAll() {
    // const { state: data } = useLocation();

    const [banglatranslate, setBanglaTraslate] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    const menuTitle = `${t('easylifeAboutUsMediaMenuTitle')}`;

    const [limit, setLimit] = useState(50);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [allMedia, setAllMedia] = useState([]);
    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/easy-life-media?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllMedia(data?.data?.easyLifeMediaList);
                setIsLoading(false);
            });
    }
    return (
        <div className="service-container">
            <div className="body-container secondary-container">
                <h1 class="title banner-title text-center">{menuTitle}</h1>

                <div className="blog-content mt-5">
                    {allMedia.map((allMedia) => (
                        <EasyLifeMediaDetails
                            data={allMedia}
                            key={allMedia._id}
                            menuTitle={menuTitle}
                            banglatranslate={banglatranslate}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AboutEasylifeViewAll;
