import React from 'react';
import directorsImg from '../../assets/image/boardDerictorsImg.png';
import ListCard from '../../components/ListCard/ListCard';
import OurPlansTitle from '../CatagoryPlans/OurPlans/OurPlansTitle';
import { useState } from 'react';
import { useRef } from 'react';
import Loading from '../../components/Loading/Loading';
import { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import ManagementTeamDetails from './ManagementTeamDetails';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

function ManagementTeamList() {
    const [newAllTeams, setAllTeams] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState(8);
    const [pageCount, setPageCount] = useState(1);
    const [addShow, setAddShow] = useState(false);
    const [showTeam, setShowTeam] = useState(null);
    const [banglatranslate, setBanglaTraslate] = useState();

    const currentPage = useRef();

    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    const handleAddClose = () => setAddShow(false);
    const handleAddShow = () => setAddShow(true);

    // pagination
    function handlePageClick(e) {
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/management-team`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllTeams(data?.data?.managementTeamLists);
                setIsLoading(false);
            });
    }

    return (
        <>
            <div className="body-container secondary-container message-contaner banner-container">
                <div>
                    <h1 className=" title text-center list-titles">
                        {t('managementCommitteeTitle')}
                    </h1>
                </div>

                {newAllTeams?.length === 0 ? (
                    <div className="min-vh-100 d-flex align-items-center justify-content-center">
                        No Management Team Is Available
                    </div>
                ) : (
                    <>
                        <div className="grid-list">
                            {newAllTeams.map((newTeam) => (
                                <ListCard
                                    newTeam={newTeam}
                                    key={newTeam._id}
                                    handleShow={handleAddShow}
                                    setShowTeam={setShowTeam}
                                    banglatranslate={banglatranslate}
                                />
                            ))}
                        </div>

                        {/* <div className="" style={{ marginTop: "6rem" }}>
              <ReactPaginate
                breakLabel="......."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                marginPagesDisplayed={2}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
              />
            </div> */}
                    </>
                )}
            </div>

            <ManagementTeamDetails
                show={addShow}
                handleClose={handleAddClose}
                showTeam={showTeam}
                banglatranslate={banglatranslate}
            />
        </>
    );
}

export default ManagementTeamList;
