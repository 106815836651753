import JoditEditor from 'jodit-react';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { CiCircleRemove } from 'react-icons/ci';
import { IoIosAddCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const DashboardPartnerChannelsEditForm = ({
    partnerChannelEdit,
    getAllGetAdc,
    handleEditClose,
}) => {
    // handel checkbox

    const [lifeTitleSwitchOn, setLifeTitleSwitchOn] = useState(
        partnerChannelEdit?.lifeTitle
    );
    const [lifeTitleBnSwitchOn, setLifeTitleBnSwitchOn] = useState(
        partnerChannelEdit?.lifeTitleBn
    );
    const [healthTitleSwitchOn, setHealthTitleSwitchOn] = useState(
        partnerChannelEdit?.healthTitle
    );
    const [healthTitleBnSwitchOn, setHealthTitleBnSwitchOn] = useState(
        partnerChannelEdit?.healthTitleBn
    );

    const [channelName, setChannelName] = useState(
        partnerChannelEdit?.partnerName
    );
    const [channelNameBn, setChannelNameBn] = useState(
        partnerChannelEdit?.partnerNameBn
    );
    const [partnerDescription, setPartnerDescription] = useState(
        partnerChannelEdit?.partnerDesc
    );
    const [partnerDescriptionBn, setPartnerDescriptionBn] = useState(
        partnerChannelEdit?.partnerDescBn
    );
    const [policyLifeDesc, setPolicyLifeDesc] = useState(
        partnerChannelEdit?.lifeDesc
    );
    const [policyLifeDescBn, setPolicyLifeDescBn] = useState(
        partnerChannelEdit?.lifeDescBn
    );
    const [policyHealthDesc, setPolicyHealthDesc] = useState(
        partnerChannelEdit?.healthDesc
    );
    const [policyHealthDescBn, setPolicyHealthDescBn] = useState(
        partnerChannelEdit?.healthDescBn
    );
    const [policyOurPolicyDesc, setPolicyOurPolicyDesc] = useState(
        partnerChannelEdit?.ourPolicyDesc
    );
    const [policyOurPolicyDescBn, setPolicyOurPolicyDescBn] = useState(
        partnerChannelEdit?.ourPolicyDescBn
    );
    const [purchaseDescription, setPurchaseDescription] = useState(
        partnerChannelEdit?.purchaseDesc
    );
    const [purchaseDescriptionBn, setPurchaseDescriptionBn] = useState(
        partnerChannelEdit?.purchaseDescBn
    );
    const [productVdLink, setProductVdLink] = useState(
        partnerChannelEdit?.vedioLink
    );
    const [logo, setLogo] = useState(partnerChannelEdit?.partnerLogo);
    const [policyImg, setPolicyImg] = useState(
        partnerChannelEdit?.ourPolicyImg
    );
    const [purchaseImage, setPurchaseImage] = useState(
        partnerChannelEdit?.purchaseImg
    );

    const [ourPolicyTitle, setOurPolicyTitle] = useState(
        partnerChannelEdit?.ourPolicyTitle
    );
    const [ourPolicyTitleBn, setOurPolicyTitleBn] = useState(
        partnerChannelEdit?.ourPolicyTitleBn
    );
    const [purchaseTitle, setPurchaseTitle] = useState(
        partnerChannelEdit?.purchaseTitle
    );
    const [purchaseTitleBn, setPurchaseTitleBn] = useState(
        partnerChannelEdit?.purchaseTitleBn
    );

    const editor = useRef(null);

    const handleLogo = (e) => {
        const file = e.target.files[0];
        setLogo(file ? file : partnerChannelEdit?.partnerLogo);
    };

    const handleOurPolicyImg = (e) => {
        const file = e.target.files[0];
        setPolicyImg(file ? file : partnerChannelEdit?.ourPolicyImg);
    };

    const handlePurchaseImg = (e) => {
        const file = e.target.files[0];
        setPurchaseImage(file ? file : partnerChannelEdit?.purchaseImg);
    };

    // Product Video
    const handleServiceChange = (e, index) => {
        const { value } = e.target;
        const list = [...productVdLink];
        list[index] = value;
        setProductVdLink(list);
    };

    const handleServiceRemove = (index) => {
        const list = [...productVdLink];
        list.splice(index, 1);
        setProductVdLink(list);
    };

    const handleServiceAdd = () => {
        setProductVdLink([...productVdLink, { productVdLink: '' }]);
    };

    const handelUpdatePartnerChannels = (e) => {
        e.preventDefault();
        handleEditClose();

        const form = e.target;
        const formData = new FormData();

        formData.append('partnerName', channelName);
        formData.append('partnerNameBn', channelNameBn);

        formData.append('partnerDesc', partnerDescription);
        formData.append('partnerDescBn', partnerDescriptionBn);
        formData.append(
            'lifeTitle',
            form.lifeTitle.checked ? 'Life Insurance' : ''
        );
        formData.append(
            'lifeTitleBn',
            form.lifeTitleBn.checked ? 'জীবন বীমা প্ল্যান' : ''
        );
        formData.append(
            'healthTitle',
            form.healthTitle.checked ? 'Health Insurance' : ''
        );
        formData.append(
            'healthTitleBn',
            form.healthTitleBn.checked ? 'স্বাস্থ্য বীমা প্ল্যান' : ''
        );
        formData.append('lifeDesc', policyLifeDesc);
        formData.append('lifeDescBn', policyLifeDescBn);
        formData.append('healthDesc', policyHealthDesc);
        formData.append('healthDescBn', policyHealthDescBn);
        formData.append('ourPolicyTitle', ourPolicyTitle);
        formData.append('ourPolicyTitleBn', ourPolicyTitleBn);
        formData.append('ourPolicyDesc', policyOurPolicyDesc);
        formData.append('ourPolicyDescBn', policyOurPolicyDescBn);
        formData.append('purchaseTitle', purchaseTitle);
        formData.append('purchaseTitleBn', purchaseTitleBn);
        formData.append('purchaseDesc', purchaseDescription);
        formData.append('purchaseDescBn', purchaseDescriptionBn);

        [...productVdLink].forEach((feature) => {
            formData.append('vedioLink', feature);
        });

        formData.append('partnerLogo', logo);
        formData.append('ourPolicyImg', policyImg);
        formData.append('purchaseImg', purchaseImage);

        for (const value of formData.values()) {
            console.log(value);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/adc/${partnerChannelEdit?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getAllGetAdc();

                    toast.success('Partner Channels Data Update successfully');

                    console.log(res);
                    form.reset();
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error(
                    'Partner Channels Data Do Not Update successfully!!!!!'
                );
            });
    };

    return (
        <>
            <form onSubmit={handelUpdatePartnerChannels}>
                {/* Partner Channel Name English  */}
                <div className="mb-3">
                    <label className="form-label  fw-bold">
                        Partner Channel Name
                        <span className="text-danger">*</span>{' '}
                    </label>
                    <input
                        placeholder="Type Partner channel name"
                        type="text"
                        defaultValue={channelName}
                        onChange={(e) => setChannelName(e.target.value)}
                        name="area"
                        className="form-control"
                        required
                    />
                </div>
                {/*Partner Channel Name Bangla  */}
                <div className="mb-3">
                    <label className="form-label  fw-bold">
                        Partner Channel Name(Bangla)
                        <span className="text-danger">*</span>{' '}
                    </label>
                    <input
                        placeholder="Type Partner channel name"
                        type="text"
                        defaultValue={channelNameBn}
                        onChange={(e) => setChannelNameBn(e.target.value)}
                        name="area"
                        className="form-control"
                        required
                    />
                </div>

                {/* Partner Channel Logo */}
                <div className="mb-3">
                    <label className="form-label fw-bold">
                        Partner Channel Logo
                        <span className="text-danger">*</span>{' '}
                    </label>
                    <input
                        type="file"
                        accept="image/*"
                        name="partnerLogo"
                        onChange={handleLogo}
                        className="form-control"
                    />
                </div>

                {/* Description English  */}
                <div className="mb-3">
                    <label className="form-label fw-bold">Description</label>
                    <JoditEditor
                        ref={editor}
                        value={partnerDescription}
                        // config={config}
                        // tabIndex={1} // tabIndex of textarea
                        onChange={(newContent) => {
                            setPartnerDescription(newContent);
                        }}
                    />
                </div>
                {/*Description: Bangla  */}
                <div className="mb-3">
                    <label className="form-label fw-bold">
                        Description (Bangla)
                    </label>
                    <JoditEditor
                        ref={editor}
                        value={partnerDescriptionBn}
                        // config={config}
                        // tabIndex={1} // tabIndex of textarea
                        onChange={(newContent) => {
                            setPartnerDescriptionBn(newContent);
                        }}
                    />
                </div>
                {/* Our Insurance Policies */}
                <div>
                    <label className="form-label fw-bold">
                        Our Insurance Policies
                    </label>
                    <div className="">
                        <div className="p-3 mb-4 shadow rounded">
                            <div className="mb-3">
                                <label className="form-label  fw-bold">
                                    Policy Name
                                </label>
                                <br />
                                <input
                                    checked={lifeTitleSwitchOn}
                                    onChange={(e) =>
                                        setLifeTitleSwitchOn(e.target.checked)
                                    }
                                    className="me-2"
                                    type="checkbox"
                                    name="lifeTitle"
                                    id=""
                                />
                                <label>Life Insurance</label>
                            </div>
                            <div className="my-3">
                                <label className="form-label fw-bold">
                                    Policy Description:
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={policyLifeDesc}
                                    // config={config}
                                    // tabIndex={1} // tabIndex of textarea
                                    onChange={(newContent) => {
                                        setPolicyLifeDesc(newContent);
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label  fw-bold">
                                    Policy Name(Bangla)
                                </label>
                                <br />
                                <input
                                    checked={lifeTitleBnSwitchOn}
                                    onChange={(e) =>
                                        setLifeTitleBnSwitchOn(e.target.checked)
                                    }
                                    className="me-2"
                                    type="checkbox"
                                    name="lifeTitleBn"
                                    id=""
                                />
                                <label>Life Insurance</label>
                            </div>
                            <div className="my-3">
                                <label className="form-label fw-bold">
                                    Policy Description (Bangla)
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={policyLifeDescBn}
                                    // config={config}
                                    // tabIndex={1} // tabIndex of textarea
                                    onChange={(newContent) => {
                                        setPolicyLifeDescBn(newContent);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="p-3 mb-4 shadow rounded">
                            <div className="mb-3">
                                <label className="form-label  fw-bold">
                                    Policy Name
                                </label>
                                <br />
                                <input
                                    checked={healthTitleSwitchOn}
                                    onChange={(e) =>
                                        setHealthTitleSwitchOn(e.target.checked)
                                    }
                                    className="me-2"
                                    type="checkbox"
                                    name="healthTitle"
                                    id=""
                                />
                                <label>Health Insurance</label>
                            </div>
                            <div className="my-3">
                                <label className="form-label fw-bold">
                                    Policy Description
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={policyHealthDesc}
                                    // config={config}
                                    // tabIndex={1} // tabIndex of textarea
                                    onChange={(newContent) => {
                                        setPolicyHealthDesc(newContent);
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label  fw-bold">
                                    Policy Name(bangla)
                                </label>
                                <br />
                                <input
                                    checked={healthTitleBnSwitchOn}
                                    onChange={(e) =>
                                        setHealthTitleBnSwitchOn(
                                            e.target.checked
                                        )
                                    }
                                    className="me-2"
                                    type="checkbox"
                                    name="healthTitleBn"
                                    id=""
                                />
                                <label>Health Insurance</label>
                            </div>
                            <div className="my-3">
                                <label className="form-label fw-bold">
                                    Policy Description (Bangla):
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={policyHealthDescBn}
                                    // config={config}
                                    // tabIndex={1} // tabIndex of textarea
                                    onChange={(newContent) => {
                                        setPolicyHealthDescBn(newContent);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-3 mb-4 shadow rounded">
                    {/* section-1:  */}

                    <div className="mb-3">
                        <label className="form-label  fw-bold">Section-1</label>
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Title</label>
                        <input
                            placeholder="Type title name"
                            type="text"
                            value={ourPolicyTitle}
                            onChange={(e) => setOurPolicyTitle(e.target.value)}
                            name="area"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Title (Bangla)
                        </label>
                        <input
                            placeholder="Type title name bangla"
                            type="text"
                            value={ourPolicyTitleBn}
                            onChange={(e) =>
                                setOurPolicyTitleBn(e.target.value)
                            }
                            name="area"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>
                        <JoditEditor
                            ref={editor}
                            value={policyOurPolicyDesc}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setPolicyOurPolicyDesc(newContent);
                            }}
                        />
                    </div>
                    {/* Why Choose Our Policy: bangla */}
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description(Bangla)
                        </label>
                        <JoditEditor
                            ref={editor}
                            value={policyOurPolicyDescBn}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setPolicyOurPolicyDescBn(newContent);
                            }}
                        />
                    </div>
                    {/* Upload Image:  */}
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Upload Image
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            name="ourPolicyImg"
                            onChange={handleOurPolicyImg}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="p-3 mb-4 shadow rounded">
                    {/*section-2:  */}
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Section-2</label>
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Title</label>
                        <input
                            placeholder="Type title name"
                            type="text"
                            value={purchaseTitle}
                            onChange={(e) => setPurchaseTitle(e.target.value)}
                            name="area"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Title (Bangla)
                        </label>
                        <input
                            placeholder="Type title name bangla"
                            type="text"
                            value={purchaseTitleBn}
                            onChange={(e) => setPurchaseTitleBn(e.target.value)}
                            name="area"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>
                        <JoditEditor
                            ref={editor}
                            value={purchaseDescription}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setPurchaseDescription(newContent);
                            }}
                        />
                    </div>
                    {/* How To Purchase: Bangal  */}
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description (Bangla)
                        </label>
                        <JoditEditor
                            ref={editor}
                            value={purchaseDescriptionBn}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setPurchaseDescriptionBn(newContent);
                            }}
                        />
                    </div>
                    {/* Upload Image:  */}
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Upload Image
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            name="imagePurchase"
                            onChange={handlePurchaseImg}
                            className="form-control"
                        />
                    </div>
                </div>
                {/* Product Video  */}
                <div className="mb-3">
                    <label className="form-label  fw-bold">
                        Product Video (Embed Link)
                    </label>
                    {productVdLink?.map((singleService, index) => (
                        <div
                            key={index}
                            className="d-flex align-items-center gap-2 mb-3"
                        >
                            <div className="d-flex align-items-center gap-2 w-100 ">
                                <input
                                    placeholder="Link"
                                    name="productVdLink"
                                    type="url"
                                    id="service"
                                    value={singleService}
                                    className="form-control"
                                    onChange={(e) =>
                                        handleServiceChange(e, index)
                                    }
                                />
                                {productVdLink?.length - 1 === index &&
                                    productVdLink?.length < 2 && (
                                        <button
                                            type="button"
                                            onClick={handleServiceAdd}
                                            className="btn bg-primary text-white"
                                        >
                                            <span>
                                                <IoIosAddCircle className="feature-btn" />
                                            </span>
                                        </button>
                                    )}
                            </div>
                            <div className="second-division">
                                {productVdLink.length !== 1 && (
                                    <button
                                        type="button"
                                        onClick={() =>
                                            handleServiceRemove(index)
                                        }
                                        className="btn bg-danger text-white"
                                    >
                                        <span>
                                            <CiCircleRemove className="feature-btn" />
                                        </span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <button type="submit" className="btn btn-primary px-4">
                    Update
                </button>
            </form>
        </>
    );
};

export default DashboardPartnerChannelsEditForm;
