import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

function AgentPdfListEdit({
    show,
    handleClose,
    updateAgentPdf,
    setUpdateAgentPdf,
}) {
    const handleChange = (e) => {
        setUpdateAgentPdf({
            ...updateAgentPdf,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdateAction = (e) => {
        e.preventDefault();
        handleClose();

        const updateAgentAdd = {
            pdfURL: updateAgentPdf?.pdfURL,
        };

        console.log(updateAgentAdd);
        fetch(
            `${process.env.REACT_APP_host}/api/v1/agnet-list-pdf/${updateAgentPdf?._id}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateAgentAdd),
            }
        )
            .then((res) => {
                if (res.status) {
                    console.log(res);
                    toast.success('Agent updated successfully');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not a update agent!!!!');
            });
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            scrollable={true}
            //   size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Update Agent Pdf Link
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction}>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Agent List (Pdf Link)
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="pdfURL"
                            placeholder="Pdf link"
                            defaultValue={updateAgentPdf?.pdfURL}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Update
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AgentPdfListEdit;
