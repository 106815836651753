import React, { useEffect } from "react";
import "../AgentList/agentList.css";
import agentListPDF from "../../../assets/files/agent_list.pdf";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Loading from "../../../components/Loading/Loading";
import { useState } from "react";

const AgentListPDF = () => {
  const { t } = useTranslation();
  const [agentPdfList, setAgentPdfList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_host}/api/v1/agnet-list-pdf`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setAgentPdfList(data?.data);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="body-container secondary-container message-contaner banner-container">
      <div>
        <h1 className="title text-center list-titles">{t("agentListTitle")}</h1>
      </div>

      <div className="list-contaner">
        {agentPdfList.map((agnets) => (
          <>
            <iframe
              src={agnets?.pdfURL}
              style={{ width: "100%", height: "600px" }}
              frameborder="0"
            ></iframe>
          </>
        ))}
        {/* <iframe
          title="Agent List"
          src={agentListPDF}
          style={{ width: "100%", height: "600px" }}
          frameborder="1"
        ></iframe> */}
      </div>
    </div>
  );
};

export default AgentListPDF;
