import React from "react";
import { toast } from "react-hot-toast";
import { MdDelete } from "react-icons/md";

function UserRoleList({ user, i, getAllUserRole }) {
  const { _id, role, menuItems } = user;

  const handleDeleteAction = (id) => {
    const agree = window.confirm(
      `Are you sure, you want to delete: "${role}?"`
    );

    const token = localStorage.getItem("accessToken");

    if (agree) {
      if (token) {
        fetch(`${process.env.REACT_APP_host}/api/v1/user-role/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            authorization: `bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status) {
              getAllUserRole();
              toast.success(`${role} is a Deleted`);
            }
          });
      }
    }
  };
  return (
    <tr>
      <td className="text-center align-middle table-text">{i + 1}</td>
      <td className="text-center align-middle table-text">{role}</td>
      <td className="text-center align-middle table-text">
        {menuItems?.map((data, i) => (
          <span className="fw-bold" key={i}>
            {data},
          </span>
        ))}
      </td>
      <td className="text-center align-middle table-text">
        <div className="d-flex align-items-center justify-content-center gap-2">
          <button
            onClick={() => handleDeleteAction(_id)}
            className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center"
          >
            <MdDelete /> <span>Delete</span>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default UserRoleList;
