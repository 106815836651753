import React from "react";
import { useState } from "react";
import Loading from "../../../../components/Loading/Loading";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { BiEdit } from "react-icons/bi";
import AgentPdfListEdit from "./AgentPdfListEdit";

function AgentPdfList() {
  const [agentPdfList, setAgentPdfList] = useState([]);
  const [updateAgentPdf, setUpdateAgentPdf] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_host}/api/v1/agnet-list-pdf`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setAgentPdfList(data?.data);
        setIsLoading(false);
      });
  }, [agentPdfList]);

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="table-responsive">
      <table className="table table-light table-bordered">
        <thead>
          <tr className="table-tr">
            <th scope="col" className="text-center align-middle table-th">
              Number
            </th>
            <th scope="col" className="text-center align-middle table-th">
              Pdf Link
            </th>

            <th scope="col" className="text-center align-middle table-th">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {agentPdfList?.map((agentList, i) => (
            <tr key={agentList?._id}>
              <td className="text-center align-middle table-text">{i + 1}</td>
              <td className="text-center align-middle table-text">
                {agentList?.pdfURL}
              </td>

              <td className="text-center align-middle table-text">
                <div className="d-flex align-items-center justify-content-center gap-2">
                  <button
                    onClick={() => {
                      handleShow();
                      setUpdateAgentPdf(agentList);
                    }}
                    className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center "
                  >
                    <BiEdit /> <span>Edit</span>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <AgentPdfListEdit
        show={show}
        handleClose={handleClose}
        updateAgentPdf={updateAgentPdf}
        setUpdateAgentPdf={setUpdateAgentPdf}
      />
    </div>
  );
}

export default AgentPdfList;
