import React, { useEffect, useRef, useState } from 'react';
import './MediaVideo.css';
import MediaContentVideoPreview from '../MediaContentVideoPreview';
import Loading from '../../../components/Loading/Loading';
import ReactPaginate from 'react-paginate';

const videoContents = [
    {
        id: 1,
        title: 'LEED Certification',
        link: 'https://www.youtube.com/embed/7zH84GHK5ec',
    },
    {
        id: 2,
        title: 'Guardian Life | 8years of Making Breakthroughs | 8th Anniversary',
        link: 'https://www.youtube.com/embed/czxnINSUAJ4',
    },
    {
        id: 3,
        title: 'Jingle | Guardian Life',
        link: 'https://www.youtube.com/embed/JupmpkkKkSU',
    },
    {
        id: 4,
        title: 'Millions under the Shade',
        link: 'https://www.youtube.com/embed/9p3tR-yuMEs',
    },
    {
        id: 5,
        title: 'Guardian Life Micro Insurance',
        link: 'https://www.youtube.com/embed/PqqHQ6ihnDs',
    },
    {
        id: 6,
        title: "গার্ডিয়ান লাইফ ডিজিটাল বীমা সেবা এ্যাপ 'MyGuardian' এখন ইংরেজির পাশাপাশি সম্পুর্ন বাংলা ভাষায়!",
        link: 'https://www.youtube.com/embed/1vtRWEXTB3Y',
    },
];

const MediaVideo = ({ t }) => {
    const [limit, setLimit] = useState(6);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [allVideo, setAllVideo] = useState([]);

    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    function handlePageClick(e) {
        console.log(e);
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/media-video?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllVideo(data?.data?.videoList);
                setIsLoading(false);
            });
    }

    return (
        <div className="mt-5">
            <div>
                <h1 className="title title-service">{t('video')}</h1>
                <div className="blog-content mt-4">
                    {allVideo?.map((data) => (
                        <MediaContentVideoPreview data={data} key={data._id} />
                    ))}
                </div>

                <div className="mt-4 d-flex justify-content-end">
                    <ReactPaginate
                        breakLabel="......."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>
            </div>
        </div>
    );
};

export default MediaVideo;
