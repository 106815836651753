import React from 'react';
import EasyLifePrivacyPolicyBanner from './EasyLifePrivacyPolicyBanner';
import EasyLifePrivacyPolicyContents from './EasyLifePrivacyPolicyContents';
import EasyLifeMobileApp from '../../EasyLifeContact/EasyLifeMobileApp';
import useEasylifeTitle from '../../../../hooks/useEasylifeTitle';

const EasyLifePrivacyPolicy = () => {
    useEasylifeTitle("Privacy Policy");
    return (
        <>
            <EasyLifePrivacyPolicyBanner />
            <EasyLifePrivacyPolicyContents />
            <EasyLifeMobileApp background={"bg-white"} />
        </>
    );
};

export default EasyLifePrivacyPolicy;