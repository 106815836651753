import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { format } from 'date-fns';

const EasyLifeMediaDetails = ({ data, menuTitle, banglatranslate }) => {
  const { _id, title, titleBn, description, descriptionBn, imageURL, createdAt, slug } = data;

  const postedDate = format(new Date(createdAt), 'P');
  const postedTime = format(new Date(createdAt), 'p');

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  return (
    <div className="blog-card easylife-media-card">
      <div
        className="card-img"
        style={{ cursor: "pointer" }}
      >
        <Link to={`/easylife/easy-life-media-single-content/${slug}`}>
            <img src={imageURL} alt="img" />
        </Link>
      </div>
      <div className="card-body-content">
        <div className="d-flex align-items-center justify-content-between blog-date-time">
          <span>{postedDate}</span>
          <span>{postedTime}</span>
        </div>

        <hr />

        <div className="blog-name-title">
          <Link to={`/easylife/easy-life-media-single-content/${slug}`}>
            <h4
              style={{ cursor: "pointer" }}
              title={banglatranslate === 'bn' ? titleBn : title}
            >
              {title.length > 32 ? banglatranslate === 'bn' ? `${titleBn?.slice(0, 32)}...` : `${title?.slice(0, 32)}...` : banglatranslate === 'bn' ? `${titleBn}` : `${title}`}
            </h4>
          </Link>
        </div>
        <div>
          <p className="blog-text-desc">
            <span
              dangerouslySetInnerHTML={{
                __html:
                  description?.length > 125 ? banglatranslate === 'bn' ? `${descriptionBn?.slice(0, 125)}...` : `${description?.slice(0, 125)}...` : banglatranslate === 'bn' ? `${descriptionBn}` : `${description}`
              }}
            ></span>
          </p>
        </div>
        <div className="mt-4">
          <Link to={`/easylife/easy-life-media-single-content/${slug}`} className="btn-book btns card-btn py-1 w-100 btn btn-primary">
            {t("viewmore")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EasyLifeMediaDetails;
