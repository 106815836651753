import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { getImageUrl } from '../../../hooks/imageUpload';
import { useRef } from 'react';
import { useState } from 'react';
import JoditEditor from 'jodit-react';
import axios from 'axios';

function DashboardExtendMangementEdit({
    editModal,
    handleEditClose,
    updateManagment,
    setUpdateMangment,
    getPaginationList,
}) {
    const editor = useRef(null);
    const [content, setContent] = useState(editModal?.description);
    const editorBn = useRef(null);
    const [contentBn, setContentBn] = useState(editModal?.description);
    const handleEditChange = (e) => {
        setUpdateMangment({
            ...updateManagment,
            [e.target.name]: e.target.value,
        });
    };

    const handleEditImage = (e) => {
        setUpdateMangment({ ...updateManagment, image: e.target.files[0] });
    };

    const handleUpdateAction = async (e) => {
        e.preventDefault();
        handleEditClose();

        const formData = new FormData();

        formData.append('name', updateManagment?.name);
        formData.append('nameBn', updateManagment?.nameBn);
        formData.append('designation', updateManagment?.designation);
        formData.append('designationBn', updateManagment?.designationBn);
        formData.append('description', content);
        formData.append('descriptionBn', contentBn);
        formData.append('image', updateManagment?.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for (const value of formData.values()) {
            console.log(value);
        }

        axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/extend-management/${updateManagment?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    toast.success(
                        'Extend Management Team updated successfully'
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not updated!!!!');
            });

        // getImageUrl(updateManagment?.imageURL)
        //     .then((imageData) => {
        //         const updateManagementTeam = {
        //             name: updateManagment?.name,
        //             nameBn: updateManagment?.nameBn,
        //             designation: updateManagment.designation,
        //             designationBn: updateManagment.designationBn,
        //             description: content,
        //             descriptionBn: contentBn,
        //             imageURL: imageData,
        //         };

        //         fetch(
        //             `${process.env.REACT_APP_host}/api/v1/extend-management/${updateManagment?._id}`,
        //             {
        //                 method: 'PATCH',
        //                 headers: {
        //                     'Content-Type': 'application/json',
        //                 },
        //                 body: JSON.stringify(updateManagementTeam),
        //             }
        //         )
        //             .then((res) => {
        //                 if (res.status) {
        //                     getPaginationList();
        //                     console.log(res);
        //                     toast.success('Updated successfully');
        //                 }
        //             })
        //             .catch((error) => {
        //                 console.error(error);
        //                 toast.error('Not a update!!!!');
        //             });
        //     })
        //     .catch((error) => console.log(error));
    };
    return (
        <Modal
            show={editModal}
            onHide={handleEditClose}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Edit Exteneded Management
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction} className="row">
                    <div className="mb-3 col-12">
                        <label className="form-label">Name</label>
                        <input
                            type="text"
                            name="name"
                            onChange={handleEditChange}
                            defaultValue={updateManagment?.name}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Name(Bangla)</label>
                        <input
                            type="text"
                            name="nameBn"
                            onChange={handleEditChange}
                            defaultValue={updateManagment?.nameBn}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Designation</label>
                        <input
                            type="text"
                            name="designation"
                            onChange={handleEditChange}
                            defaultValue={updateManagment?.designation}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">
                            Designation(Bangla)
                        </label>
                        <input
                            type="text"
                            name="designationBn"
                            onChange={handleEditChange}
                            defaultValue={updateManagment?.designationBn}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-5 col-12">
                        <label className="form-label">Image</label>
                        <input
                            type="file"
                            onChange={handleEditImage}
                            name="image"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={updateManagment?.description}
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description(Bangla)
                        </label>

                        <JoditEditor
                            ref={editorBn}
                            value={updateManagment?.descriptionBn}
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardExtendMangementEdit;
