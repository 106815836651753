import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { FiEye } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

function DashBoardVideo() {
  const navigate = useNavigate();

  const [addVideo, setAddVideo] = useState({
    vedioTitle: "",
    videoLink: ""
  });

  const handleChange = (e) => {
    setAddVideo({ ...addVideo, [e.target.name]: e.target.value });
  };


  // post
  const handleAddNews = async (e) => {
    e.preventDefault();

    const form = e.target;
    const vedioTitle = form.vedioTitle.value;
    const videoLink= form.videoLink.value;

    const newVideo = {
      vedioTitle,
      videoLink
    }
    console.log(newVideo);

    fetch(`${process.env.REACT_APP_host}/api/v1/media-video`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newVideo),
    })
      .then((res) => res.json())
      .then((data) => {
        if(data?.status) {
          toast.success("Video is added successfully!");
          form.reset();
          navigate("/dashboard/media/view-video");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Video is not added!");
      });

    // const formData = new FormData();

    // formData.append("title", addVideo.title);
    // formData.append("videoLink", addVideo.videoLink);

    // console.log(addVideo);

    // for (const value of formData.values()) {
    //   console.log(value);
    // }

    // const config = {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // };

    // await axios
    //   .post(`${process.env.REACT_APP_host}/api/v1/media-video`, formData, config)
    //   .then((res) => {
    //     if (res.status) {
    //       toast.success("Video is added successfully!");
    //       setAddVideo("");
    //       // navigate("/dashboard/media/view-video");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     toast.error("Video is not added!");
    //   });
  };

  return (
    <div className="home-content">
      <div class="d-flex justify-content-end align-items-center">
        <Link
          to="/dashboard/media/view-video"
          class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
        >
          <FiEye />
          <span>View Video</span>
        </Link>
      </div>
      <div className="shadow rounded main-contents">
        <h1 className="dashboard-title text-center">Add Video</h1>

        <form onSubmit={handleAddNews}>
          <div className="mb-3">
            <label className="form-label  fw-bold">Video Title</label>
            <input
              type="text"
              onChange={handleChange}
              value={addVideo.vedioTitle}
              name="vedioTitle"
              className="form-control"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label  fw-bold">Video (Embedded) Link</label>
            <input
              type="text"
              onChange={handleChange}
              value={addVideo.videoLink}
              name="videoLink"
              className="form-control"
              required
            />
          </div>

          <button type="submit" className="btn btn-primary px-4">
            Save
          </button>
        </form>
      </div>
    </div>
  );
}

export default DashBoardVideo;
