import React from "react";
import "./tableList.css";

function TableList({ staffList, i }) {
  const { name, imageURL, designation, department, contactNumber, email } =
    staffList;
  return (
    <tr>
      <td className="text-center align-middle table-text fw-bold">{i}</td>
      <td className="text-center align-middle table-text">
        <div className="d-flex  align-items-center gap-2">
          <div className="list-img">
            <img src={imageURL} />
          </div>
          <div>
            <p className="fw-bold table-text">{name}</p>
          </div>
        </div>
      </td>
      <td className="text-center align-middle table-text">{designation}</td>
      <td className="text-center align-middle table-text">{department}</td>
      <td className="text-center align-middle table-text">{contactNumber}</td>
      <td className="text-center align-middle table-text">{email}</td>
    </tr>
  );
}

export default TableList;
