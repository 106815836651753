import React from 'react';
import BancassuranceBanner from './BancassuranceBanner/BancassuranceBanner';
import WhyGuardianLife from './WhyGuardianLife/WhyGuardianLife';
import BancassuranceBenefitWeOffer from './BancassuranceBenefitWeOffer/BancassuranceBenefitWeOffer';
import BancassuranceBenefits from './BancassuranceBenefits/BancassuranceBenefits';
import useTitle from '../../hooks/useTitle';
import BancassurancePartnerLogo from './BancassurancePartnerLogo/BancassurancePartnerLogo';
import Loading from '../../components/Loading/Loading';
import { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import BancassuranceSubmenu from './BancassuranceSubmenu/BancassuranceSubmenu';
// import BancassuranceTeam from "./BancassuranceTeam/BancassuranceTeam";

function Bancassurance() {
    const [metaTags, setMetaTags] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getPaginationList();
    }, [metaTags]);

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/seo?pages=Bancassurance`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setMetaTags(data?.data?.seo);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }
    // useTitle("Bancassurance");
    return (
        <div>
            {metaTags?.map((data) => (
                <Helmet>
                    <title>{data?.title} - Guardian Life Insurance Ltd. </title>
                    <meta name="description" content={data?.description} />
                    <meta
                        name="keywords"
                        content={data?.keywords?.map(
                            (keys) => keys?.keywordsName
                        )}
                    />
                </Helmet>
            ))}
            <BancassuranceBanner />
            <BancassuranceSubmenu />
            {/* <WhyGuardianLife />
      <BancassuranceBenefitWeOffer />
      <BancassuranceBenefits />
      <BancassurancePartnerLogo /> */}
            {/* <BancassuranceTeam /> */}
        </div>
    );
}

export default Bancassurance;
