import { useQuery } from "@tanstack/react-query";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import MicroPlanList from "./MicroPlanList";
import "./MicroinsurancePlan.css";

const MicroinsurancePlan = () => {
  const [show, setShow] = useState(false);
  const [planData, setPlanData] = useState("");
  const [plan, setPlan] = useState([]);

  const handleClose = () => setShow(false);

  const handleShow = (data) => {
    setPlanData(data);
    setPlan(data.plan);
    setShow(true);
  };

  // const url = "microData.json";

  // const { data: allData = [], isLoading } = useQuery({
  //     queryKey: ["allPlans"],
  //     queryFn: async () => {
  //         try {
  //             const res = await fetch(url);
  //             const data = await res.json();
  //             return data;
  //         } catch (err) {
  //             console.log(err);
  //         }
  //     },
  // });

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const allData = [
    {
      id: 1,
      title: `${t("microinsuranceServiceCardTitle1")}`,
      listItem: [
        {
          id: 1,
          listTitle: `${t("microinsuranceServiceCardT1L1")}`,
        },
        {
          id: 2,
          listTitle: `${t("microinsuranceServiceCardT1L2")}`,
        },
        {
          id: 3,
          listTitle: `${t("microinsuranceServiceCardT1L3")}`,
        },
      ],
      plan: [
        {
          id: 1,
          title: `${t("microinsuranceServiceTitleApplicableTo")}`,
          description: `${t("microinsuranceServiceDesApplicableTo1")}`,
        },
        {
          id: 2,
          title: `${t("microinsuranceServiceTitlePolicyType")}`,
          description: `${t("microinsuranceServiceDesPolicyType1")}`,
        },
        {
          id: 3,
          title: `${t("microinsuranceServiceTitleRiskCovered")}`,
          description: `${t("microinsuranceServiceDesRiskCovered1")}`,
        },
        {
          id: 4,
          title: `${t("microinsuranceServiceTitlePremiumPaymentMode")}`,
          description: `${t("microinsuranceServiceDesPremiumPaymentMode1")}`,
        },
        {
          id: 5,
          title: `${t("microinsuranceServiceTitleCoverageLimit")}`,
          description: `${t("microinsuranceServiceDesCoverageLimit1")}`,
        },
        {
          id: 6,
          title: `${t("microinsuranceServiceTitleCoverageLimit")}`,
          description: `${t("microinsuranceServiceDesCoverageLimit1")}`,
        },
        {
          id: 7,
          title: `${t("microinsuranceServiceTitleSumInsured")}`,
          description: `${t("microinsuranceServiceDesSumInsured1")}`,
        },
        {
          id: 8,
          title: `${t("microinsuranceServiceTitleEligibility")}`,
          description: `${t("microinsuranceServiceDesEligibility1")}`,
        },
      ],
    },

    {
      id: 2,
      title: `${t("microinsuranceServiceCardTitle2")}`,
      listItem: [
        {
          id: 1,
          listTitle: `${t("microinsuranceServiceCardT2L1")}`,
        },
        {
          id: 2,
          listTitle: `${t("microinsuranceServiceCardT2L2")}`,
        },
        {
          id: 3,
          listTitle: `${t("microinsuranceServiceCardT2L3")}`,
        },
      ],
      plan: [
        {
          id: 1,
          title: `${t("microinsuranceServiceTitleApplicableTo")}`,
          description: `${t("microinsuranceServiceDesApplicableTo2")}`,
        },
        {
          id: 2,
          title: `${t("microinsuranceServiceTitlePolicyType")}`,
          description: `${t("microinsuranceServiceDesPolicyType2")}`,
        },
        {
          id: 3,
          title: `${t("microinsuranceServiceTitleRiskCovered")}`,
          description: `${t("microinsuranceServiceDesRiskCovered2")}`,
        },
        {
          id: 4,
          title: `${t("microinsuranceServiceTitlePremiumPaymentMode")}`,
          description: `${t("microinsuranceServiceDesPremiumPaymentMode2")}`,
        },
        {
          id: 5,
          title: `${t("microinsuranceServiceTitleCoverageLimit")}`,
          description: `${t("microinsuranceServiceDesCoverageLimit2")}`,
        },
        {
          id: 6,
          title: `${t("microinsuranceServiceTitleSumInsured")}`,
          description: `${t("microinsuranceServiceDesSumInsured2")}`,
        },
        {
          id: 7,
          title: `${t("microinsuranceServiceTitleEligibility")}`,
          description: `${t("microinsuranceServiceDesEligibility2")}`,
        },
      ],
    },
    {
      id: 3,
      title: `${t("microinsuranceServiceCardTitle3")}`,
      listItem: [
        {
          id: 1,
          listTitle: `${t("microinsuranceServiceCardT3L1")}`,
        },
        {
          id: 2,
          listTitle: `${t("microinsuranceServiceCardT3L2")}`,
        },
        {
          id: 3,
          listTitle: `${t("microinsuranceServiceCardT3L3")}`,
        },
      ],
      plan: [
        {
          id: 1,
          title: `${t("microinsuranceServiceTitleApplicableTo")}`,
          description: `${t("microinsuranceServiceDesApplicableTo3")}`,
        },
        {
          id: 2,
          title: `${t("microinsuranceServiceTitlePolicyType")}`,
          description: `${t("microinsuranceServiceDesPolicyType3")}`,
        },
        {
          id: 3,
          title: `${t("microinsuranceServiceTitleRiskCovered")}`,
          description: `${t("microinsuranceServiceDesRiskCovered3")}`,
        },
        {
          id: 4,
          title: `${t("microinsuranceServiceTitlePremiumPaymentMode")}`,
          description: `${t("microinsuranceServiceDesPremiumPaymentMode3")}`,
        },
        {
          id: 5,
          title: `${t("microinsuranceServiceTitleCoverageLimit")}`,
          description: `${t("microinsuranceServiceDesCoverageLimit3")}`,
        },
        {
          id: 6,
          title: `${t("microinsuranceServiceTitleSumInsured")}`,
          description: `${t("microinsuranceServiceDesSumInsured3")}`,
        },
        {
          id: 7,
          title: `${t("microinsuranceServiceTitleEligibility")}`,
          description: `${t("microinsuranceServiceDesEligibility3")}`,
        },
      ],
    },
    {
      id: 4,
      title: `${t("microinsuranceServiceCardTitle4")}`,
      listItem: [
        {
          id: 1,
          listTitle: `${t("microinsuranceServiceCardT4L1")}`,
        },
      ],
      plan: [
        {
          id: 1,
          title: `${t("microinsuranceServiceTitleApplicableTo")}`,
          description: `${t("microinsuranceServiceDesApplicableTo4")}`,
        },
        {
          id: 2,
          title: `${t("microinsuranceServiceTitlePolicyType")}`,
          description: `${t("microinsuranceServiceDesPolicyType4")}`,
        },
        {
          id: 3,
          title: `${t("microinsuranceServiceTitleRiskCovered")}`,
          description: `${t("microinsuranceServiceDesRiskCovered4")}`,
        },
        {
          id: 4,
          title: `${t("microinsuranceServiceTitlePremiumPaymentMode")}`,
          description: `${t("microinsuranceServiceDesPremiumPaymentMode4")}`,
        },
        {
          id: 5,
          title: `${t("microinsuranceServiceTitleCoverageLimit")}`,
          description: `${t("microinsuranceServiceDesCoverageLimit4")}`,
        },
        {
          id: 6,
          title: `${t("microinsuranceServiceTitleSumInsured")}`,
          description: `${t("microinsuranceServiceDesSumInsured4")}`,
        },
        {
          id: 7,
          title: `${t("microinsuranceServiceTitleEligibility")}`,
          description: `${t("microinsuranceServiceDesEligibility4")}`,
        },
      ],
    },
    {
      id: 5,
      title: `${t("microinsuranceServiceCardTitle5")}`,
      listItem: [
        {
          id: 1,
          listTitle: `${t("microinsuranceServiceCardT5L1")}`,
        },
      ],
      plan: [
        {
          id: 1,
          title: `${t("microinsuranceServiceTitleApplicableTo")}`,
          description: `${t("microinsuranceServiceDesApplicableTo5")}`,
        },
        {
          id: 2,
          title: `${t("microinsuranceServiceTitlePolicyType")}`,
          description: `${t("microinsuranceServiceDesPolicyType5")}`,
        },
        {
          id: 3,
          title: `${t("microinsuranceServiceTitleRiskCovered")}`,
          description: `${t("microinsuranceServiceDesRiskCovered5")}`,
        },
        {
          id: 4,
          title: `${t("microinsuranceServiceTitlePremiumPaymentMode")}`,
          description: `${t("microinsuranceServiceDesPremiumPaymentMode5")}`,
        },
        {
          id: 5,
          title: `${t("microinsuranceServiceTitleCoverageLimit")}`,
          description: `${t("microinsuranceServiceDesCoverageLimit5")}`,
        },
        {
          id: 6,
          title: `${t("microinsuranceServiceTitleSumInsured")}`,
          description: `${t("microinsuranceServiceDesSumInsured5")}`,
        },
        {
          id: 7,
          title: `${t("microinsuranceServiceTitleEligibility")}`,
          description: `${t("microinsuranceServiceDesEligibility2")}`,
        },
      ],
    },
  ];

  return (
    <div className="service-container pt-0">
      <div className="body-container secondary-container">
        <h1 className="title title-service">{t("microinsuranceServiceWeOfferTitle")}</h1>
        <div className="micro-plan-grid">
          {allData.map((data) => (
            <div key={data.id}>
              <div className="px-4 service-card position-relative" style={{ height: "420px" }}>
                <div className="card-info">
                  <h4 className="corporate-service-title text-center mb-3">{data?.title}</h4>
                  <div className="d-flex justify-content-center">
                    <ul className="d-flex flex-column gap-2">
                      <MicroPlanList listItem={data?.listItem} />
                    </ul>
                  </div>
                </div>
                <div className="px-4 w-100 subscription-card-btn">
                  <Button className="btn-book btns card-btn px-2 py-1 w-100" onClick={() => handleShow(data)}>
                    {t("moreDetails")}
                  </Button>
                </div>
              </div>
            </div>
          ))}
          <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <div className="book-title">{planData?.title}</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="px-3 py-3">
                {plan?.map((p) => (
                  <div key={p?.id} className="row gx-2" style={{ fontSize: "14px" }}>
                    <p className="col-5 fw-semibold mb-2">{p?.title}</p>
                    <p className="col-1 fw-semibold">:</p>
                    <p className="col-6">{p?.description}</p>
                  </div>
                ))}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default MicroinsurancePlan;
