import React, { useEffect, useState } from 'react';
import Loading from '../../../components/Loading/Loading';
import { Link } from 'react-router-dom';
import { FaStepBackward } from 'react-icons/fa';
import DashboardMilestoneList from './DashboardMilestoneList';
import DashboardMilestonesEdit from './DashboardMilestonesEdit';

function DashboardMilestoneView() {
    const [getAllMilestone, setGetAllMilestone] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editMilestoneModal, setEditMilestoneModal] = useState(false);
    const [editMilestone, setEditMilestone] = useState(null);

    const handleEditClose = () => setEditMilestoneModal(false);
    const handleEditShow = () => setEditMilestoneModal(true);

    // get all milestones
    useEffect(() => {
        getAllDataFetch();
    }, [getAllMilestone]);

    const getAllDataFetch = () => {
        fetch(`${process.env.REACT_APP_host}/api/v1/milestone`)
            .then((res) => res.json())
            .then((data) => {
                setGetAllMilestone(data?.data);
                setIsLoading(false);
            });
    };

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    return (
        <>
            <div className="home-content">
                <div class="d-flex justify-content-between gap-2 align-items-center">
                    <h1 className="dashboard-title">All Milestone</h1>
                    <div>
                        <Link
                            to="/dashboard/milestones"
                            className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                        >
                            <FaStepBackward />
                            <span>Back</span>
                        </Link>
                    </div>
                </div>

                {getAllMilestone?.length === 0 ? (
                    <>
                        <div className="min-vh-100 d-flex align-items-center justify-content-center">
                            No Milestone Avaliable
                        </div>
                    </>
                ) : (
                    <>
                        <div className="table-responsive mt-4">
                            <table className="table table-light table-bordered">
                                <thead>
                                    <tr className="table-tr">
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Year
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Year(Bangla)
                                        </th>

                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Description
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Description(Bangla)
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getAllMilestone.map((milestone) => (
                                        <DashboardMilestoneList
                                            milestone={milestone}
                                            key={milestone._id}
                                            setEditMilestone={setEditMilestone}
                                            handleEditShow={handleEditShow}
                                            getAllDataFetch={getAllDataFetch}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>

            <DashboardMilestonesEdit
                editMilestone={editMilestone}
                setEditMilestone={setEditMilestone}
                editMilestoneModal={editMilestoneModal}
                handleEditClose={handleEditClose}
                getAllDataFetch={getAllDataFetch}
            />
        </>
    );
}

export default DashboardMilestoneView;
