import React from "react";
import { useRef } from "react";
import { Card, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";

function DashboardSubCatagoryPlansViewModal({
  showPlans,
  viewPlansModal,
  handleViewClose,
  getAllDataFetch,
}) {
  const playerRef = useRef(null);
  return (
    <Modal
      show={viewPlansModal}
      onHide={handleViewClose}
      size="lg"
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          Plans Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card style={{ width: "100%" }}>
          <Card.Img
            variant="top"
            style={{ height: "300px" }}
            src={showPlans?.subCategoryImageURL}
          />
          <Card.Body>
            <Card.Title>{showPlans?.subCategorytitle}</Card.Title>
            <Card.Text>
              <span
                dangerouslySetInnerHTML={{
                  __html: showPlans?.subCategoryDescription,
                }}
              ></span>
            </Card.Text>
            <Card.Text>
              {showPlans?.subCategoryBenefit?.map((element) => (
                <div key={element?._id} className="mt-3">
                  <h5 className="mb-3">
                    Benefit Title: {element?.subBenefitTitle}
                  </h5>
                  <p>
                    <span className="fw-bold">Benefit Description:</span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: element?.subBenefitDescription,
                      }}
                    ></span>
                  </p>
                </div>
              ))}
            </Card.Text>
            <p className="mt-4">
              <strong>Video Link:</strong>
              <span> {showPlans?.subCategoryVedioLink}</span>
            </p>
            <div className="mt-2">
              <ReactPlayer
                className="w-100"
                ref={playerRef}
                url={showPlans?.subCategoryVedioLink}
                controls={true}
              />
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
      {/* <Modal.Footer>
      <Button variant="primary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer> */}
    </Modal>
  );
}

export default DashboardSubCatagoryPlansViewModal;
