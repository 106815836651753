import { default as i18n, default as i18next } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSupport } from 'react-icons/bi';
import { BsCashStack } from 'react-icons/bs';
import { GiFruitTree } from 'react-icons/gi';
import { GrLanguage } from 'react-icons/gr';
import { MdOutlineLocationOn, MdOutlineMenu } from 'react-icons/md';
import { TbFilePencil } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import easyLifeLogo from '../../../../assets/logo/easylife-logo-svg.png';
import LogoImage from '../../../../assets/logo/logo-1.png';
import AsideBar from '../../../../components/AsideBar/AsideBar';
import EasyLanguageDropdown from '../../../../components/Language/EasyLanguageDropdown';
import LanguageDropdown from '../../../../components/Language/LanguageDropdown';
import Translate from '../../../Translate/Translate';
import EasylifeMenuOffcanvas from '../Offcanvas/EasylifeMenuOffcanvas';
import EasyLifeMenuItems from './EasyLifeMenuItems';
import './EasylifeHeader.css';

const EasyLifeHeader = () => {
    const [sticky, setSticky] = useState(false);
    const [language, setLangusges] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setLangusges(currentLang);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 200);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // language
    const handleClick = (e) => {
        i18next.changeLanguage(e.target.value);
        window.location.reload();
        localStorage.setItem('lang', e.target.value);
    };

    const menuData = [
        {
            id: 1,
            title: `${t('home')}`,
            link: '/easylife/home',
            classesName: '',
            target: '',
        },
        {
            id: 2,
            title: `${t('products')}`,
            isLink: false,
            // link: "/easylife/products",
            classesName: '',
            target: '',
            submenu: [
                // {
                //   id: 1,
                //   title: `${t("easyLife")}`,
                //   link: "/easylife/products",
                //   classesName: "",
                //   target: "",
                //   // submenu: [
                //   //   {
                //   //     id: 1,
                //   //     title: "Product",
                //   //     link: "/easylife/easy-life-product",
                //   //     classesName: "",
                //   //     target: "",
                //   //   },
                //   // ],
                // },
                {
                    id: 1,
                    title: `${t('easylifeAboutUsServiceOffermenu1')}`,
                    link: '/easylife/products/term-life-insurance',
                    classesName: '',
                    target: '',
                },
                {
                    id: 2,
                    title: `${t('easylifeAboutUsServiceOffermenu2')}`,
                    link: '/easylife/products/savings-plan',
                    classesName: '',
                    target: '',
                },
                {
                    id: 3,
                    title: `${t('easylifeAboutUsServiceOffermenu3')}`,
                    link: "/easylife/products/women's-savings-plan",
                    classesName: '',
                    target: '',
                },

                {
                    id: 4,
                    title: `${t('easylifeAboutUsServiceOffermenu5')}`,
                    isLink: false,
                    classesName: '',
                    // target: "__blank",
                    submenu: [
                        {
                            id: 1,
                            title: `${t('easylifeAboutUsServiceOffermenu4')}`,
                            link: '/easylife/easy-life-cancer-care-plan',
                            classesName: '',
                            target: '',
                        },
                        {
                            id: 2,
                            title: `${t('easylifeAboutUsServiceOffermenu6')}`,
                            link: '/easylife/guardian-accident-care',
                            classesName: '',
                            target: '',
                        },
                        // {
                        //     id: .3,
                        //     title: `${t('easylifeAboutUsServiceOffermenu7')}`,
                        //     link: '/easylife/guardian-travel-care',
                        //     classesName: '',
                        //     target: '',
                        // },
                    ],
                },
            ],
        },
        {
            id: 3,
            title: `${t('digitalOffers')}`,
            link: '/digital-loyalty-offer',
            classesName: '',
            target: '',
        },
        {
            id: 4,
            title: `${t('claims')}`,
            link: 'https://acps.myguardianbd.com/easy_life/client_login/',
            classesName: '',
            target: '_blank',
        },
        {
            id: 5,
            title: `${t('more')}`,
            isLink: false,
            classesName: '',
            target: '_blank',
            submenu: [
                {
                    id: 1,
                    title: `${t('MyProfile')}`,
                    link: 'http://easylife.myguardianbd.com/en/sign-in',
                    classesName: '',
                    target: '_blank',
                },
                {
                    id: 2,
                    title: `${t('taxCertificate')}`,
                    link: 'https://saleslead.myguardianbd.com/tax-certificate/EASYLIFE',
                    classesName: '',
                    target: '_blank',
                },
                {
                    id: 3,
                    title: `${t('faq')}`,
                    link: '/easylife/faq',
                    classesName: '',
                    target: '',
                },
                {
                    id: 4,
                    title: `${t('privacy')}`,
                    link: '/easylife/privacy-policy',
                    classesName: '',
                    target: '',
                },
                {
                    id: 5,
                    title: `${t('BMICalculator')}`,
                    link: '/easylife/bmi-calculator',
                    classesName: '',
                    target: '',
                },
                {
                    id: 5,
                    title: `${t('login')}`,
                    link: 'http://easylife.myguardianbd.com/en/sign-in',
                    classesName: '',
                    target: '',
                },
            ],
        },
    ];
    return (
        <>
            {/* frist navbar */}
            <div className="first-nav-bg">
                <div className="nav-second-containers">
                    <nav className="first-nav ">
                        <div className="d-md-none">
                            <Link to="/home">
                                <img
                                    className="logo-img"
                                    src={LogoImage}
                                    alt="logo"
                                />
                            </Link>
                        </div>
                        <div className="left-menu ">
                            <li className="menu-nav d-flex align-items-center gap-2">
                                {['top'].map((placement, idx) => (
                                    <EasylifeMenuOffcanvas
                                        key={idx}
                                        placement={placement}
                                        name={placement}
                                    />
                                ))}
                            </li>
                            <li className="menu-nav d-none d-md-flex align-items-center gap-2 home-text">
                                <BiSupport className="nav-icon" />
                                <span>{t('24/7 HOTLINE: 16622')}</span>
                            </li>

                            <li className="menu-nav d-none d-lg-flex align-items-center gap-2">
                                {['end'].map((placement, idx) => (
                                    <AsideBar
                                        key={idx}
                                        placement={placement}
                                        name={placement}
                                    />
                                ))}
                                {/* <BsCalculator className="nav-icon" />
                                <span> Premium Calculator </span> */}
                            </li>
                        </div>
                        <div className="d-none d-md-block">
                            <Link to="/home">
                                <img
                                    className="logo-img"
                                    src={LogoImage}
                                    alt="logo"
                                />
                            </Link>
                        </div>
                        <div className="right-menu">
                            <li className="menu-nav d-flex align-items-center gap-2">
                                <Link
                                    to="/payment"
                                    className=" d-flex align-items-center gap-2"
                                >
                                    <BsCashStack className="nav-icon" />
                                    <span>{t('Payment channels')}</span>
                                </Link>
                            </li>
                            {/* <li className="menu-nav d-none d-md-flex align-items-center gap-2 home-text">
                <Link
                  to="https://myguardianbd.com/"
                  className="d-flex align-items-center gap-2"
                  target="_blank"
                >
                  <GiFruitTree className="nav-icon" />
                  <span>{t("My Guardian")}</span>
                </Link>
              </li> */}
                            <li className="menu-nav d-flex align-items-center gap-2">
                                <Link
                                    target="_blank"
                                    to="/locate-branch"
                                    className="menu-nav d-flex align-items-center gap-2"
                                >
                                    <MdOutlineLocationOn className="nav-icon" />
                                    <span> {t('Locate Branch')}</span>
                                </Link>
                            </li>
                            <li className="menu-nav d-flex align-items-center gap-2">
                                {/* <span>EN</span>
                                <label className="switch">
                                    <input type="checkbox" />
                                    <span className="slider round"></span>
                                </label> */}

                                {/* <Translate /> */}

                                <GrLanguage />
                                <LanguageDropdown
                                    onChange={(e) => handleClick(e)}
                                />
                            </li>
                        </div>
                    </nav>
                </div>
            </div>

            {/* second navbar */}
            <div
                className={`${
                    sticky
                        ? ' second-nav d-none d-md-block stickys'
                        : 'second-nav d-none d-md-block'
                }`}
            >
                <nav className="body-container secondary-container">
                    <ul className="nav-items py-0">
                        <li>
                            <div className="easy-life-logo">
                                <Link to="/easylife">
                                    <img src={easyLifeLogo} alt="logo" />
                                </Link>
                            </div>
                        </li>
                        {menuData?.map((menu, index) => {
                            const depthLevel = 0;
                            return (
                                <EasyLifeMenuItems
                                    items={menu}
                                    key={index}
                                    depthLevel={depthLevel}
                                />
                            );
                        })}
                    </ul>
                </nav>
            </div>
        </>
    );
};

export default EasyLifeHeader;
