import React from "react";
import { Button, Modal } from "react-bootstrap";

function DashboardClientMessageShowModal({
  show,
  handleClose,
  showBooking,
  showMessage,
}) {
  return (
    <Modal show={show} onHide={handleClose} size="lg" scrollable={true}>
      {/* <Modal.Header closeButton>
      <Modal.Title>{bookingFor}</Modal.Title>
    </Modal.Header> */}
      <Modal.Body>
        <div className="card border-0 shadow rounded">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <label className="form-label text-title">
                  <span className="fw-bold me-1"> Name:</span>
                  {showMessage?.name}
                </label>
              </div>
              <div className=" col-6">
                <label className="form-label text-title">
                  <span className="fw-bold me-1">Email:</span>
                  {showMessage?.email}
                </label>
              </div>
              <div className=" col-6">
                <label className="form-label text-title">
                  <span className="fw-bold me-1">Number:</span>
                  {showMessage?.contactNumber}
                </label>
              </div>
              <div className=" col-12 text-title">
                <p>
                  <span className="fw-bold me-1">Message:</span>
                </p>
                <p className="card-text">{showMessage?.message}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DashboardClientMessageShowModal;
