import React, { useEffect, useRef, useState } from 'react';
import Loading from '../../../../components/Loading/Loading';
import { Link } from 'react-router-dom';
import { FaStepBackward } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import DashboardBancassuranceServiceTable from './DashboardBancassuranceServiceTable';
import { IoMdAddCircle } from 'react-icons/io';
import axios from 'axios';
import DashboardBancassuranceAddService from './DashboardBancassuranceAddService';
import toast from 'react-hot-toast';
import DashboardBancassuranceEditService from './DashboardBancassuranceEditService';

function DashboardBancassuranceService() {
    const [newAllService, setAllService] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [editModal, setEditModal] = useState(false);
    const [updateService, setUpdateService] = useState(null);
    const [addShowModal, setAddShowModal] = useState(false);
    const [newService, setNewService] = useState({
        title: '',
        titleBn: '',
        image: '',
    });

    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    const handleAddClose = () => setAddShowModal(false);
    const handleAddShow = () => setAddShowModal(true);
    const handleEditClose = () => setEditModal(false);
    const handleEditShow = () => setEditModal(true);

    const handleChange = (e) => {
        setNewService({ ...newService, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setNewService({ ...newService, image: e.target.files[0] });
    };

    // pagination
    function handlePageClick(e) {
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/bancassurance-service?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllService(data?.data?.bancassuranceServiceList);
                setIsLoading(false);
            });
    }

    const handleAddService = (e) => {
        e.preventDefault();
        handleAddClose();

        const formData = new FormData();

        formData.append('title', newService.title);
        formData.append('titleBn', newService.titleBn);
        formData.append('image', newService.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        for (const value of formData.values()) {
            console.log(value);
        }

        axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/bancassurance-service`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    console.log(res);
                    toast.success('Service added successfully');
                    setNewService('');
                }
            })
            .catch((error) => {
                toast.error('Service not added!!!');
                console.log(error);
            });
    };

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }
    return (
        <div className="home-content">
            <div class="d-flex justify-content-between gap-2 align-items-center">
                <h1 className="dashboard-title">All Bancassurance Service</h1>
                <div>
                    <button
                        type="button"
                        onClick={handleAddShow}
                        className="btn btn-sm btn-primary d-flex align-items-center fw-semibold"
                    >
                        <IoMdAddCircle className="add-icon" />{' '}
                        <span>Add Service</span>
                    </button>
                </div>
            </div>

            <div className="list-contaner">
                {newAllService?.length === 0 ? (
                    <div className="min-vh-100 d-flex align-items-center justify-content-center">
                        No Service Is Available
                    </div>
                ) : (
                    <>
                        <div className="table-responsive">
                            <table className="table table-light table-bordered">
                                <thead>
                                    <tr className="table-tr">
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Serial No.
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Photo
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Title
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Title (Bangla)
                                        </th>

                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newAllService.map((newService, i) => (
                                        <DashboardBancassuranceServiceTable
                                            key={newService?._id}
                                            newService={newService}
                                            idx={i}
                                            handleEditShow={handleEditShow}
                                            setUpdateService={setUpdateService}
                                            getPaginationList={
                                                getPaginationList
                                            }
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}

                <div className="mt-4">
                    <ReactPaginate
                        breakLabel="......."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>

                {/*
  
          <DashboardBlogViewModal
            viewShow={viewShow}
            handleViewClose={handleViewClose}
            viewService={viewService}
          /> */}
            </div>

            <DashboardBancassuranceAddService
                addShowModal={addShowModal}
                handleAddClose={handleAddClose}
                handleChange={handleChange}
                handleImage={handleImage}
                handleAddService={handleAddService}
                newService={newService}
                getPaginationList={getPaginationList}
            />
            <DashboardBancassuranceEditService
                editModal={editModal}
                handleEditClose={handleEditClose}
                updateService={updateService}
                setUpdateService={setUpdateService}
                getPaginationList={getPaginationList}
            />
        </div>
    );
}

export default DashboardBancassuranceService;
