import React from "react";
import ab from "../../assets/payment/ab.png";
import bankAsia from "../../assets/payment/bankaisa.png";
import city from "../../assets/payment/city.png";
import eblSky from "../../assets/payment/eblsky.png";
import fsib from "../../assets/payment/fsib.png";
import islami from "../../assets/payment/islami.png";
import modhumoti from "../../assets/payment/modhumoti.png";
import mtb from "../../assets/payment/mtb.png";
import pMoney from "../../assets/payment/pmoney.png";
import sb from "../../assets/payment/sb.png";
import tapNPay from "../../assets/payment/tapnpay.jpg";
import woori from "../../assets/payment/woori.png";
import nexus from "../../assets/payment/nexus pay.png";

function InternetBanking() {
  return (
    <div className="body-container secondary-container my-5">
      <div className="payment-submenu">
        <img src={ab} className=" payment-logo" alt="" />
        <img src={nexus} className=" payment-logo" alt="" />
        <img src={city} className=" payment-logo" alt="" />
        <img src={mtb} className=" payment-logo" alt="" />
        <img src={islami} className=" payment-logo" alt="" />
        <img src={tapNPay} className=" payment-logo" alt="" />
        <img src={fsib} className=" payment-logo" alt="" />
        <img src={bankAsia} className=" payment-logo" alt="" />
        <img src={modhumoti} className=" payment-logo" alt="" />
        <img src={eblSky} className=" payment-logo" alt="" />
        <img src={sb} className=" payment-logo" alt="" />
        <img src={pMoney} className=" payment-logo" alt="" />
        <img src={woori} className=" payment-logo" alt="" />
      </div>
    </div>
  );
}

export default InternetBanking;
