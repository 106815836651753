import React from 'react';
import Modal from 'react-bootstrap/Modal';

function ModalAddStaff({
    show,
    handleClose,
    handleAddStaffList,
    handleChange,
    handleImage,
    newStaff,
}) {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Add Staff
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleAddStaffList} className="row">
                    <div className="mb-3 col-6">
                        <label className="form-label">Name</label>
                        <input
                            type="text"
                            name="name"
                            onChange={handleChange}
                            value={newStaff.name}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Email address</label>
                        <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            value={newStaff.email}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Contact Number</label>
                        <input
                            type="text"
                            name="contactNumber"
                            className="form-control"
                            value={newStaff.contactNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Designation</label>
                        <input
                            type="text"
                            name="designation"
                            className="form-control"
                            onChange={handleChange}
                            value={newStaff.designation}
                            required
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Department</label>
                        <input
                            type="text"
                            name="department"
                            onChange={handleChange}
                            value={newStaff.department}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Image</label>
                        <input
                            type="file"
                            onChange={handleImage}
                            name="image"
                            className="form-control"
                        />
                    </div>

                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default ModalAddStaff;
