import axios from 'axios';
import { setYear } from 'date-fns';
import JoditEditor from 'jodit-react';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

function DashboardMilestonesEdit({
    editMilestoneModal,
    handleEditClose,
    editMilestone,
    getAllDataFetch,
    setEditMilestone,
}) {
    const [content, setContent] = useState(editMilestone?.description);
    const [contentBn, setContentBn] = useState(editMilestone?.descriptionBn);
    const [year, setYear] = useState(editMilestone?.year);
    const [yearBn, setYearBn] = useState(editMilestone?.yearBn);
    const editor = useRef(null);
    const editorBn = useRef(null);

    useEffect(() => {
        if (editMilestone && editMilestone?.year) {
            setYear(editMilestone?.year);
        }
        if (editMilestone && editMilestone?.yearBn) {
            setYearBn(editMilestone?.yearBn);
        }
    }, [editMilestone]);

    const handleUpdateAction = async (e) => {
        e.preventDefault();
        handleEditClose();

        const updateMilestone = {
            year: year,
            yearBn: yearBn,
            description: content,
            descriptionBn: contentBn,
        };

        console.log(updateMilestone);

        await fetch(
            `${process.env.REACT_APP_host}/api/v1/milestone/${editMilestone?._id}`,
            {
                method: 'PATCH',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(updateMilestone),
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.status) {
                    getAllDataFetch();
                    toast.success('Milsetone updated successfully');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not updated Milestones!!!!');
            });
    };

    return (
        <Modal
            show={editMilestoneModal}
            onHide={handleEditClose}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Edit Milestone
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction} className="row">
                    <div className="mb-3 col-12">
                        <label className="form-label">Year</label>
                        <input
                            type="text"
                            name="year"
                            defaultValue={editMilestone?.year}
                            onChange={(e) => setYear(e.target.value)}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Year(Bangla)</label>
                        <input
                            type="text"
                            name="yearBn"
                            defaultValue={editMilestone?.yearBn}
                            onChange={(e) => setYearBn(e.target.value)}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>
                        <JoditEditor
                            ref={editor}
                            value={editMilestone?.description}
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description(Bangla)
                        </label>
                        <JoditEditor
                            ref={editorBn}
                            value={editMilestone?.descriptionBn}
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardMilestonesEdit;
