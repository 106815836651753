import React from "react";

import StaffListBanner from "./StaffListBanner";
import AllStaffList from "./AllStaffList/AllStaffList";
import useTitle from "../../hooks/useTitle";

function StaffList() {
  useTitle("Staff List");

  return (
    <>
      {/* <StaffListBanner /> */}
      <AllStaffList />
    </>
  );
}

export default StaffList;
