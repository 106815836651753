import React from "react";
import { useState } from "react";
import Loading from "../../../../components/Loading/Loading";
import { useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import DashboardPlansList from "./DashboardPlansList";
import DashboardPlansViewModal from "./DashboardPlansViewModal";
import DashboardPlansEditModal from "./DashboardPlansEditModal";
import { Link } from "react-router-dom";
import { FaStepBackward } from "react-icons/fa";

function DashboardViewPlans() {
  const [getAllPlans, setGetAllPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewPlansModal, setViewPlansModal] = useState(false);
  const [editPlansModal, setEditPlansModal] = useState(false);
  const [showPlans, setShowPlans] = useState(null);
  const [editPlans, setEditPlans] = useState(null);

  const handleViewClose = () => setViewPlansModal(false);
  const handleViewShow = () => setViewPlansModal(true);
  const handleEditClose = () => setEditPlansModal(false);
  const handleEditShow = () => setEditPlansModal(true);

  // get all Plans
  useEffect(() => {
    getAllDataFetch();
  }, [getAllPlans]);

  const getAllDataFetch = () => {
    fetch(`${process.env.REACT_APP_host}/api/v1/plans`)
      .then((res) => res.json())
      .then((data) => {
        setGetAllPlans(data?.data);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="home-content">
        <div class="d-flex justify-content-between gap-2 align-items-center">
          <h1 className="dashboard-title">All Plans</h1>
          <div>
            <Link
              to="/dashboard/plans/add-plans"
              className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
            >
              <FaStepBackward />
              <span>Back</span>
            </Link>
          </div>
        </div>

        {getAllPlans?.length === 0 ? (
          <>
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
              No Plans Avaliable
            </div>
          </>
        ) : (
          <>
            <div className="table-responsive mt-4">
              <table className="table table-light table-bordered">
                <thead>
                  <tr className="table-tr">
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Plans Name
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Image
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Video Link
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getAllPlans.map((getPlans) => (
                    <DashboardPlansList
                      getPlans={getPlans}
                      key={getPlans._id}
                      setShowPlans={setShowPlans}
                      setEditPlans={setEditPlans}
                      handleViewShow={handleViewShow}
                      handleEditShow={handleEditShow}
                      getAllDataFetch={getAllDataFetch}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>

      <DashboardPlansViewModal
        showPlans={showPlans}
        setShowPlans={setShowPlans}
        viewPlansModal={viewPlansModal}
        handleViewClose={handleViewClose}
        getAllDataFetch={getAllDataFetch}
      />
      <DashboardPlansEditModal
        editPlans={editPlans}
        setEditPlans={setEditPlans}
        editPlansModal={editPlansModal}
        handleEditClose={handleEditClose}
        getAllDataFetch={getAllDataFetch}
      />
    </>
  );
}

export default DashboardViewPlans;
