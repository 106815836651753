import i18n from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { Link, useLoaderData, useParams } from 'react-router-dom';
import BannerImg from '../../assets/image/banner.png';
import BannersCard from '../../components/BannersCard/BannersCard';
import BookModal from '../../components/BookModal/BookModal';
import Buttons from '../../components/Buttons/Buttons';
import KeyFeatures from '../../components/KeyFeatures/KeyFeatures';
import Loading from '../../components/Loading/Loading';
import OurPlansCardDetails from '../../components/OurPlansCardDetails/OurPlansCardDetails';
import ProductVedio from '../../components/ProductVedio/ProductVedio';
import { BookContext } from '../../context/BookingContext/BookingContext';
import useTitle from '../../hooks/useTitle';
import OurPlansTitle from '../CatagoryPlans/OurPlans/OurPlansTitle';
import './subCatagoryPlans.css';

function SubCatagoryPlans() {
    const plans = useLoaderData();
    const { id } = useParams();

    const [metaTags, setMetaTags] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const catagoryData = useLoaderData();
    const { t } = useTranslation();
    const [banglatranslate, setBanglaTraslate] = useState();

    const playerRef = useRef(null);
    const { handleShow } = useContext(BookContext);
    const catagoryPlansData = catagoryData?.data;
    const plansData = plans?.data;

    // useTitle(catagoryPlansData?.categoryTitle);

    const featuresEn = catagoryPlansData?.keyFeature;
    const featureBn = catagoryPlansData?.keyFeatureBn;

    const features = banglatranslate === 'bn' ? featureBn : featuresEn;

    const benefitsEn = catagoryPlansData?.categoryBenefit;
    const benefitsBn = catagoryPlansData?.categoryBenefitBn;

    const benefits = banglatranslate === 'bn' ? benefitsBn : benefitsEn;
    const [ourData, setOurData] = useState(benefitsEn[0]);
    const [ourDataBn, setOurDataBn] = useState(benefitsBn[0]);

    useEffect(() => {});

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    useEffect(() => {
        getPaginationList();
    }, [metaTags]);

    const [menuState, setMenuState] = useState(benefitsEn[0]?._id);
    const [menuStateBn, setMenuStateBn] = useState(benefitsBn[0]?._id);
    const action = (idx, data) => {
        setMenuState(idx);
        setMenuStateBn(idx);
        setOurData(data);
        setOurDataBn(data);
    };

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/seo?pages=${catagoryPlansData?.categoryTitle}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setMetaTags(data?.data?.seo);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }
    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    return (
        <div>
            {metaTags?.map((data) => (
                <Helmet>
                    <title>{data?.title} - Guardian Life Insurance Ltd. </title>
                    <meta name="description" content={data?.description} />
                    <meta
                        name="keywords"
                        content={data?.keywords?.map(
                            (keys) => keys?.keywordsName
                        )}
                    />
                </Helmet>
            ))}
            <div className="body-container secondary-container mt-3">
                <Breadcrumb>
                    <Breadcrumb.Item className="faq-link">
                        {t('retail')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active className="faq-link">
                        {banglatranslate === 'bn'
                            ? catagoryPlansData?.categoryTitleBn
                            : catagoryPlansData?.categoryTitle}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="secondary-container body-container">
                <div className="plans-banner-container">
                    <div className="plans-banner-contents">
                        <div className="left-content">
                            <h1 className="title banner-title">
                                {banglatranslate === 'bn'
                                    ? catagoryPlansData?.categoryTitleBn
                                    : catagoryPlansData?.categoryTitle}
                            </h1>
                            <p className="text banner-text">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            banglatranslate === 'bn'
                                                ? catagoryPlansData?.categoryDescriptionBn
                                                : catagoryPlansData?.categoryDescription,
                                    }}
                                ></span>
                            </p>

                            <div className="card-button">
                                <Button
                                    className="btn-book btns"
                                    onClick={handleShow}
                                >
                                    {t('letsTalk')}
                                </Button>
                            </div>
                        </div>
                        <div className="right-img">
                            <img
                                src={catagoryPlansData?.categoryImageURL}
                                alt=""
                            />
                        </div>

                        {/* start */}
                    </div>
                    {features?.length > 0 && (
                        <div className="mt-5">
                            <h1 class="title banner-title text-center">
                                {t('keyFeature')}
                            </h1>
                            <div className="keyFeatures-content">
                                {features?.map((keyFeature, i) => (
                                    <KeyFeatures
                                        key={i}
                                        keyFeature={keyFeature}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    <div>
                        <OurPlansTitle title={t('featureBenifit')} />
                    </div>
                    <div className="corporate-content">
                        <div className="retail-feature-container">
                            <div className="retail-plans-left">
                                <div className="retail-vl"></div>
                                {benefits?.map((data, i) => (
                                    <div
                                        className="retail-plan-content-text"
                                        key={i}
                                        onClick={() => {
                                            action(data?._id, data);
                                        }}
                                    >
                                        {banglatranslate === 'bn' ? (
                                            <div
                                                className="cicrle"
                                                style={{
                                                    backgroundColor:
                                                        menuStateBn ===
                                                        data?._id
                                                            ? '#232176'
                                                            : '',
                                                }}
                                            ></div>
                                        ) : (
                                            <div
                                                className="cicrle"
                                                style={{
                                                    backgroundColor:
                                                        menuState === data?._id
                                                            ? '#232176'
                                                            : '',
                                                }}
                                            ></div>
                                        )}
                                        {banglatranslate === 'bn' ? (
                                            <div
                                                className="plans-text"
                                                style={{
                                                    color:
                                                        menuStateBn ===
                                                        data?._id
                                                            ? '#232176'
                                                            : '',
                                                }}
                                            >
                                                {data?.benefitTitle?.split(' ')
                                                    ?.length === 2 ? (
                                                    data?.benefitTitle
                                                ) : (
                                                    <div>
                                                        {data?.benefitTitle
                                                            ?.split(' ')
                                                            .slice(0, 2)
                                                            .join(' ')}{' '}
                                                        <br></br>{' '}
                                                        {data?.benefitTitle
                                                            ?.split(' ')
                                                            ?.slice(
                                                                2,
                                                                data?.benefitTitle?.split(
                                                                    ' '
                                                                ).length
                                                            )
                                                            ?.join(' ')}
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div
                                                className="plans-text"
                                                style={{
                                                    color:
                                                        menuState === data?._id
                                                            ? '#232176'
                                                            : '',
                                                }}
                                            >
                                                {data?.benefitTitle?.split(' ')
                                                    ?.length === 2 ? (
                                                    data?.benefitTitle
                                                ) : (
                                                    <div>
                                                        {data?.benefitTitle
                                                            ?.split(' ')
                                                            .slice(0, 2)
                                                            .join(' ')}{' '}
                                                        {/* <br></br> */}{' '}
                                                        {data?.benefitTitle
                                                            ?.split(' ')
                                                            ?.slice(
                                                                2,
                                                                data?.benefitTitle?.split(
                                                                    ' '
                                                                ).length
                                                            )
                                                            ?.join(' ')}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="ourPlans-right">
                                <div className="ourPlans-content-left w-100">
                                    {banglatranslate === 'bn' ? (
                                        <>
                                            <h4> {ourDataBn?.benefitTitle} </h4>
                                            <p className="text banner-text">
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: ourDataBn?.benefitDescription,
                                                    }}
                                                ></span>
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <h4> {ourData?.benefitTitle} </h4>
                                            <p className="text banner-text">
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: ourData?.benefitDescription,
                                                    }}
                                                ></span>
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="plans-container">
                    {catagoryPlansData?.plansSubCategory?.map(
                        (bannerItem, i) => (
                            <div key={i} className="plans-section">
                                {i % 2 === 0 && (
                                    <div className="plans-banner-contents ">
                                        <div className="right-img">
                                            <img
                                                src={
                                                    bannerItem?.subCategoryImageURL
                                                }
                                                alt=""
                                            />
                                        </div>
                                        <div className="left-content">
                                            <h1 className="title banner-title">
                                                {bannerItem?.subCategorytitle}
                                            </h1>
                                            <p className="text banner-text">
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: bannerItem?.subCategoryDescription,
                                                    }}
                                                ></span>
                                            </p>

                                            <div className="card-button">
                                                <Link
                                                    to={`/retails/${bannerItem?.subCategorytitle}`}
                                                >
                                                    <Buttons classes="btn-learn">
                                                        {t('learnmore')}
                                                    </Buttons>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {i % 2 !== 0 && (
                                    <div className="plans-banner-contents right-plans-content">
                                        <div className="left-content">
                                            <h1 className="title banner-title">
                                                {bannerItem?.subCategorytitle}
                                            </h1>
                                            <p className="text banner-text">
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: bannerItem?.subCategoryDescription,
                                                    }}
                                                ></span>
                                            </p>

                                            <div className="card-button">
                                                <Link
                                                    to={`/retails/${bannerItem?.subCategorytitle}`}
                                                >
                                                    <Buttons classes="btn-learn">
                                                        {t('learnmore')}
                                                    </Buttons>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="right-img">
                                            <img
                                                src={
                                                    bannerItem?.subCategoryImageURL
                                                }
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    )}
                </div>
            </div>
            {catagoryPlansData?.categoryVedioLink && (
                <div className="product-vedio-container">
                    <div className="secondary-container body-container">
                        <h1 className="title product-vedio-title">
                            {t('productVedio')}
                        </h1>
                        <p className="text-center text  blog-text">
                            {t('productVrdioDesc')}
                        </p>

                        <div className="vedio-player-container shadow">
                            <ReactPlayer
                                className="vedio-size"
                                ref={playerRef}
                                url={catagoryPlansData?.categoryVedioLink}
                                controls={true}
                            />
                        </div>
                    </div>
                </div>
            )}

            <BookModal />
        </div>
    );
}

export default SubCatagoryPlans;
