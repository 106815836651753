import React from "react";
import { useContext } from "react";
import { useRef } from "react";
import { useLoaderData } from "react-router-dom";
import { BookContext } from "../../context/BookingContext/BookingContext";
import BookModal from "../../components/BookModal/BookModal";
import { Button } from "react-bootstrap";
import KeyFeatures from "../../components/KeyFeatures/KeyFeatures";
import OurPlansTitle from "../CatagoryPlans/OurPlans/OurPlansTitle";
import ReactPlayer from "react-player";

function PlansSubCatagoryPlans() {
  const subCatagoryData = useLoaderData();
  const playerRef = useRef(null);
  const { handleShow } = useContext(BookContext);
  const subCatagoryPlansData = subCatagoryData?.data;

  const features = subCatagoryPlansData?.keyFeature;
  return (
    <>
      <div className="secondary-container body-container">
        <div className="plans-banner-container">
          <div className="plans-banner-contents">
            <div className="left-content">
              <h1 className="title banner-title">
                {subCatagoryPlansData?.subCategorytitle}
              </h1>
              <p className="text banner-text">
                <span
                  dangerouslySetInnerHTML={{
                    __html: subCatagoryPlansData?.subCategoryDescription,
                  }}
                ></span>
              </p>

              <div className="card-button">
                <Button className="btn-book btns " onClick={handleShow}>
                  Let's Talk
                </Button>
              </div>
            </div>
            <div className="right-img">
              <img src={subCatagoryPlansData?.subCategoryImageURL} alt="" />
            </div>

            {/* start */}
          </div>
          {features?.length > 0 && (
            <div className="mt-5">
              <h1 class="title banner-title text-center">Key Features</h1>
              <div className="keyFeatures-content">
                {features.map((keyFeature, i) => (
                  <KeyFeatures key={i} keyFeature={keyFeature} />
                ))}
              </div>
            </div>
          )}
        </div>
        <div>
          <div>
            <OurPlansTitle title=" Feature & Benefit" />
          </div>
          <div className="mt-4">
            <span
              dangerouslySetInnerHTML={{
                __html: subCatagoryPlansData?.subCategoryBenefit,
              }}
            ></span>
          </div>
        </div>
      </div>
      <div className="product-vedio-container">
        <div className="secondary-container body-container">
          <h1 className="title product-vedio-title">Product video</h1>
          <p className="text-center text  blog-text">
            We currently support Facebook, Instagram, LinkedIn and Twitter. More
            to come. Felix is purpose built for ease of use and complete
            flexability.
          </p>

          <div className="vedio-player-container shadow">
            <ReactPlayer
              className="vedio-size"
              ref={playerRef}
              url={subCatagoryPlansData?.subCategoryVedioLink}
              controls={true}
            />
          </div>
        </div>
      </div>
      <BookModal />
    </>
  );
}

export default PlansSubCatagoryPlans;
