import React from "react";
import mapImg from "../../assets/icon/map-logo.png";
import { Link } from "react-router-dom";

function LocateBranchList({ branchList, idx }) {
  const {
    _id,
    officeCategory,
    officeName,
    division,
    district,
    area,
    address,
    contactPerson,
    mapLink,
  } = branchList;

  return (
    <tr>
      <td className="text-center align-middle table-text fw-bold">{idx + 1}</td>
      <td className="text-center align-middle table-text">{officeCategory}</td>
      <td className="text-center align-middle table-text">{officeName}</td>
      <td className="text-center align-middle table-text">{division}</td>
      <td className="text-center align-middle table-text">{district}</td>
      <td className="text-center align-middle table-text">{area}</td>
      <td className="text-center align-middle table-text w-25">{address}</td>
      <td className="text-center align-middle table-text">
        <span dangerouslySetInnerHTML={{ __html: contactPerson }}></span>
      </td>
      <td className="text-center align-middle table-text">
        <div>
          <Link
            to={mapLink}
            target="_blank"
            className="d-inline-block"
            style={{ width: "30px" }}
          >
            <img src={mapImg} alt="map" className="w-100" />
          </Link>
        </div>
      </td>
    </tr>
  );
}

export default LocateBranchList;
