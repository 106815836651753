import React, { useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { getImageUrl } from '../../../hooks/imageUpload';
import { toast } from 'react-hot-toast';
import axios from 'axios';

function PrartnerLogo() {
    const [addLogo, setAddLogo] = useState({
        image: '',
        pageSelect: '',
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        setAddLogo({ ...addLogo, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setAddLogo({ ...addLogo, image: e.target.files[0] });
    };

    // post
    const handleAddPartnerLogo = (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('pageSelect', addLogo?.pageSelect);

        formData.append('image', addLogo?.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        for (const value of formData.values()) {
            console.log(value);
        }

        axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/partner-logo`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    console.log(res);
                    toast.success('Partnet Logo added successfully');
                    setAddLogo('');
                    navigate('/dashboard/partner-logo-view');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Partnet Logo Not added!!!');
            });
    };
    return (
        <div className="home-content">
            <div class="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/partner-logo-view"
                    class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Logo</span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center">
                    {' '}
                    Add Partner Logo
                </h1>
                <form onSubmit={handleAddPartnerLogo}>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Select Page
                        </label>
                        <select
                            onChange={handleChange}
                            name="pageSelect"
                            class="form-select"
                        >
                            <option value="" selected>
                                Choses a Page
                            </option>
                            <option value="Home Page">Home Page</option>
                            <option value="Corporate Page">
                                Corporate Page
                            </option>
                            <option value="Microinsurance Page">
                                Microinsurance Page
                            </option>
                            <option value="Bancassurance Page">
                                Bancassurance Page
                            </option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            onChange={handleImage}
                            className="form-control"
                            required
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default PrartnerLogo;
