import React from 'react'

function EasylifeDashboard() {
    return (
        <div className="home-content min-vh-100 d-flex align-items-center justify-content-center ">
          <h1 className="plans-contents">EasyLife Dashboard</h1>
        </div>
      );
}

export default EasylifeDashboard