import React from "react";
import "./keyFeatures.css";
// import keyIcon from "../../assets/image/plansImg1.png";

function KeyFeatures({ keyFeature }) {
  return (
    <div className="key-card">
      {/* <div className="key-img">
        <img src={keyIcon} alt="" />
      </div> */}
      <h3 className="keyFeatures-title"> {keyFeature}</h3>
    </div>
  );
}

export default KeyFeatures;
