import React, { useEffect, useRef } from "react";
// import googleTranslate from "google-translate-api";

function Translate() {
  const googleTranslateRef = useRef(null);

  useEffect(() => {
    // Load Google Translate script
    const script = document.createElement("script");
    script.src =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    googleTranslateRef.current.appendChild(script);

    // Initialize Google Translate widget
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en", // Set the default language of your website
          includedLanguages: "en,bn", // Specify the languages you want to offer for translation
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        },
        "google-translate-element"
      );
    };
  }, []);
  return (
    <>
      <div ref={googleTranslateRef} id="google-translate-element"></div>
    </>
  );
}

export default Translate;
