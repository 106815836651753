import React, { createContext, useEffect, useState } from "react";

export const NotiContext = createContext();

const NotificationContext = ({ children }) => {
  const [isReadData, setIsReadData] = useState([]);

  useEffect(() => {
    getIsReadNotification();
  }, []);
  function getIsReadNotification() {
    fetch(`${process.env.REACT_APP_host}/api/v1/notification?isRead=${false}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setIsReadData(data?.data?.notificationsList);
      });
  }

  const NotiInfo = {
    getIsReadNotification,
    isReadData,
  };
  return <NotiContext.Provider value={NotiInfo}>{children}</NotiContext.Provider>;
};

export default NotificationContext;
