import React from "react";
import { FaStepBackward } from "react-icons/fa";
import { Link } from "react-router-dom";
import PartnerLogoList from "./PartnerLogoList";
import { useState } from "react";
import PartnerLogoEdit from "./PartnerLogoEdit";
import Loading from "../../../components/Loading/Loading";
import { useEffect } from "react";
import { useRef } from "react";
import ReactPaginate from "react-paginate";

function PartnerLogoView() {
  const [show, setShow] = useState(false);
  const [updateLogo, setupdateLogo] = useState(null);
  const [partnerLogos, setPartnerLogos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const currentPage = useRef();

  useEffect(() => {
    currentPage.current = 1;
    getPaginationList();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // pagination
  function handlePageClick(e) {
    currentPage.current = e.selected + 1;
    getPaginationList();
  }

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/partner-logo?page=${currentPage.current}&limit=${limit}`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data?.data?.page);
        setPartnerLogos(data?.data?.partnerLogoList);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }
  return (
    <div className="home-content">
      <div class="d-flex justify-content-between gap-2 align-items-center">
        <h1 className="dashboard-title">All Logo</h1>
        <div>
          <Link
            to="/dashboard/partner-logo"
            className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
          >
            <FaStepBackward />
            <span>Back</span>
          </Link>
        </div>
      </div>

      <div className="list-contaner">
        {partnerLogos?.length === 0 ? (
          <div className="min-vh-100 d-flex align-items-center justify-content-center">
            No Logo Is Available
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-light table-bordered">
                <thead>
                  <tr className="table-tr">
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Serial No.
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Page Name
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Photo
                    </th>

                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {partnerLogos?.map((partnerLogo, i) => (
                    <PartnerLogoList
                      key={partnerLogo.id}
                      partnerLogo={partnerLogo}
                      i={(currentPage.current - 1) * limit + i + 1}
                      setupdateLogo={setupdateLogo}
                      handleShow={handleShow}
                      getPaginationList={getPaginationList}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        <div className="mt-4">
          <ReactPaginate
            breakLabel="......."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
          />
        </div>

        <PartnerLogoEdit
          show={show}
          handleClose={handleClose}
          updateLogo={updateLogo}
          setupdateLogo={setupdateLogo}
          getPaginationList={getPaginationList}
        />
      </div>
    </div>
  );
}

export default PartnerLogoView;
