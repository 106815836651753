import React from "react";
import "./staffListTable.css";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { toast } from "react-hot-toast";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import { useState } from "react";
import EditStaffListModal from "../EditStaffListModal/EditStaffListModal";

function StaffListTable({
  staffList,
  handleShow,
  setUpdateStaff,
  getPaginationList,
}) {
  const { _id, name, imageURL, designation, department, contactNumber, email } =
    staffList;

  const handleDeleteAction = (id) => {
    const agree = window.confirm(
      `Are you sure, you want to delete: "${name}?"`
    );
    if (agree) {
      fetch(`${process.env.REACT_APP_host}/api/v1/staff/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            getPaginationList();
            toast.success(`${name} is a Deleted`);
          }
        });
    }
  };

  return (
    <>
      <tr>
        <td className="text-center align-middle table-text">
          <div className="d-flex  align-items-center gap-2">
            <div className="list-img">
              <img src={imageURL} />
            </div>
            <div>
              <p className="fw-bold table-text">{name}</p>
            </div>
          </div>
        </td>
        <td className="text-center align-middle table-text">{designation}</td>
        <td className="text-center align-middle table-text">{department}</td>
        <td className="text-center align-middle table-text">{contactNumber}</td>
        <td className="text-center align-middle table-text">{email}</td>
        <td className="text-center align-middle table-text">
          <div className="d-flex align-items-center justify-content-center gap-2">
            <button
              onClick={() => {
                handleShow();
                setUpdateStaff(staffList);
              }}
              className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center "
            >
              <BiEdit /> <span>Edit</span>
            </button>
            <button
              onClick={() => handleDeleteAction(_id)}
              className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center"
            >
              <MdDelete /> <span>Delete</span>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
}

export default StaffListTable;
