import React, { useEffect, useRef, useState } from "react";
import { FaStepBackward } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import DashboardFormLibraryEdit from "./DashboardFormLibraryEdit";
import DashboardFormLibraryList from "./DashboardFormLibraryList";

function DashboardFormLibraryView() {
  const [formLibrary, setFormLibrary] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [updateFormLibrary, setUpdateFormLibrary] = useState(null);

  const currentPage = useRef();

  useEffect(() => {
    currentPage.current = 1;
    getPaginationList();
  }, []);

  const handleEditClose = () => setEditModal(false);
  const handleEditShow = () => setEditModal(true);

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/form-library`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setFormLibrary(data?.data?.formLibraryList);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }
  return (
    <div className="home-content">
      <div class="d-flex justify-content-between gap-2 align-items-center">
        <h1 className="dashboard-title">All Form</h1>
        <div>
          <Link to="/dashboard/form-library" className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white">
            <FaStepBackward />
            <span>Back</span>
          </Link>
        </div>
      </div>

      <div className="list-contaner">
        {formLibrary?.length === 0 ? (
          <div className="min-vh-100 d-flex align-items-center justify-content-center">No Form Library Is Available</div>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-light table-bordered">
                <thead>
                  <tr className="table-tr">
                    <th scope="col" className="text-center align-middle table-th">
                      Form Name
                    </th>
                    <th scope="col" className="text-center align-middle table-th">
                      Form Name(bangla)
                    </th>
                    <th scope="col" className="text-center align-middle table-th">
                      Form Link
                    </th>

                    <th scope="col" className="text-center align-middle table-th">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {formLibrary?.map((formLibraryData, i) => (
                    <DashboardFormLibraryList
                      key={formLibraryData._id}
                      formLibraryData={formLibraryData}
                      idx={i}
                      handleEditShow={handleEditShow}
                      setUpdateFormLibrary={setUpdateFormLibrary}
                      getPaginationList={getPaginationList}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        <DashboardFormLibraryEdit
          handleEditClose={handleEditClose}
          editModal={editModal}
          updateFormLibrary={updateFormLibrary}
          getPaginationList={getPaginationList}
          setUpdateFormLibrary={setUpdateFormLibrary}
        />
      </div>
    </div>
  );
}

export default DashboardFormLibraryView;
