import React, { useEffect, useState } from 'react';
import './newServiceProduct.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import product1 from '../../../assets/easylife/products/Term Life.png';
import product2 from '../../../assets/easylife/products/Savings Plan.png';
import product3 from '../../../assets/easylife/products/Women Saving Plan.png';
import product4 from '../../../assets/easylife/products/Special Insurance plan.png';

function NewServiceProduct() {
    const [banglatranslate, setBanglaTraslate] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    const [menuState, setMenuState] = useState(1);
    const action = (idx) => {
        setMenuState(idx);
    };

    const serviceProductCards = [
        {
            id: 1,
            title: `${t('easylifeAboutUsServiceOffermenu1')}`,
            details: `${t(
                'One-time premium payment policy that provides death benefit to beneficiaries if the insured passes away during the term.'
            )}`,
            link: '/easylife/products/term-life-insurance',
            img: product1,
        },
        {
            id: 2,
            title: `${t('easylifeAboutUsServiceOffermenu2')}`,
            details: `${t(
                'Offers a dual benefit of life insurance protection and a savings component. Typically, a policyholder pays monthly premiums.'
            )}`,
            link: '/easylife/products/savings-plan',
            img: product2,
        },
        {
            id: 3,
            title: `${t('easylifeAboutUsServiceOffermenu3')}`,
            details: `${t(
                'Specially designed women insurance products with life insurance coverage, critical illness protection, and a savings component.'
            )}`,
            link: "/easylife/products/women's-savings-plan",
            img: product3,
        },
        {
            id: 4,
            title: `${t('easylifeAboutUsServiceOffermenu5')}`,
            details: `${t(
                'Special insurance plans are designed to cover the financial risks in accidental indemnity, personal accident coverage, critical illnesses like Cancer Care, Cardiac Insurance etc.'
            )}`,
            link: '/easylife/products/Special-insurance-plan',
            img: product4,
        },
    ];
    return (
        <div className="body-container aboutEsayLife-container plans-section my-5">
            <div className="new-service-product-container">
                {serviceProductCards.map((card) => (
                    <div
                        key={card.id}
                        className={`new-service-product-card ${
                            menuState === card.id
                                ? 'new-service-product-active-card'
                                : ''
                        }`}
                        onMouseOver={() => action(card.id)}
                    >
                        <div
                            className={`new-service-content ${
                                menuState === card.id
                                    ? 'new-service-active-content'
                                    : ''
                            }`}
                        >
                            <h2>{card?.title}</h2>
                            <p>{card?.details}</p>
                            <div>
                                <Link
                                    to={card?.link}
                                    className="btn-book btns btn btn-primary product-btn"
                                >
                                    {t('View Plan')}
                                </Link>
                            </div>
                        </div>
                        <div
                            className={`new-service-image ${
                                menuState === card.id
                                    ? 'new-service-active-image'
                                    : ''
                            }`}
                        >
                            <img src={card?.img} alt={card?.title} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NewServiceProduct;
