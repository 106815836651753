import React, { useEffect } from "react";
import Icon1 from "../../../assets/image/claim-help.png";
import Icon2 from "../../../assets/image/claim-innovation.png";
import Icon3 from "../../../assets/image/claim-overcoming.png";
import Icon4 from "../../../assets/image/claim-efficient.png";
import Icon5 from "../../../assets/image/claim-transparency.png";
import Icon6 from "../../../assets/image/claim-data-capture.png";
import Icon7 from "../../../assets/image/claim-milestone.png";
import Icon8 from "../../../assets/image/claim-process.png";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

// const title = "Our Services";

const ClaimOverviewCard = () => {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);


  const ourServiceItems = [
    {
      id: 1,
      title: `${t("claimFeatureTitle1")}`,
      dec: `${t("claimFeatureTitleDetails1")}`,
      icon: Icon1,
    },
    {
      id: 2,
      title: `${t("claimFeatureTitle2")}`,
      dec: `${t("claimFeatureTitleDetails2")}`,
      icon: Icon2,
    },
    {
      id: 3,
      title: `${t("claimFeatureTitle3")}`,
      dec: `${t("claimFeatureTitleDetails3")}`,
      icon: Icon3,
    },
    {
      id: 4,
      title: `${t("claimFeatureTitle4")}`,
      dec: `${t("claimFeatureTitleDetails4")}`,
      icon: Icon4,
    },
    {
      id: 5,
      title: `${t("claimFeatureTitle5")}`,
      dec: `${t("claimFeatureTitleDetails5")}`,
      icon: Icon5,
    },
    {
      id: 6,
      title: `${t("claimFeatureTitle6")}`,
      dec: `${t("claimFeatureTitleDetails6")}`,
      icon: Icon6,
    },
    {
      id: 7,
      title: `${t("claimFeatureTitle7")}`,
      dec: `${t("claimFeatureTitleDetails7")}`,
      icon: Icon7,
    },
    {
      id: 8,
      title: `${t("claimFeatureTitle8")}`,
      dec: `${t("claimFeatureTitleDetails8")}`,
      icon: Icon8,
    },
  ];
  return (
    <div className="service-container pt-1">
      <div className="body-container secondary-container">
        <h1 class="title title-service mt-5">{t("claimCardSectionTitle")}</h1>
        <div className="claim-overview-card-grid">
          {ourServiceItems.map((ourServiceItem) => (
            <div key={ourServiceItem.id} className="service-card">
              <div className="row g-2 align-items-center">
                <div className="col-2 me-3">
                  <div className="micro-img">
                    <img src={ourServiceItem.icon} alt="" />
                  </div>
                </div>
                <div className="col-9">
                  <h4 className="service-title" style={{ fontSize: "14px" }}>
                    {ourServiceItem.title}
                  </h4>
                </div>
              </div>
              <div className="card-info">
                <p
                  className="py-3 card-text"
                  style={{ fontSize: "14px", textAlign: "start" }}
                >
                  {ourServiceItem.dec}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClaimOverviewCard;
