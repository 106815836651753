import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import './editStaffList.css';
import axios from 'axios';
import { getImageUrl } from '../../../../hooks/imageUpload';

function EditStaffListModal({
    show,
    handleClose,
    updateStaff,
    setUpdateStaff,
    getPaginationList,
}) {
    const handleChange = (e) => {
        setUpdateStaff({ ...updateStaff, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setUpdateStaff({ ...updateStaff, image: e.target.files[0] });
    };

    const handleUpdateAction = async (e) => {
        e.preventDefault();
        handleClose();

        const formData = new FormData();
        formData.append('name', updateStaff.name);
        formData.append('email', updateStaff.email);
        formData.append('contactNumber', updateStaff.contactNumber);
        formData.append('designation', updateStaff.designation);
        formData.append('department', updateStaff.department);
        formData.append('image', updateStaff.image);

        console.log(updateStaff);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        await axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/staff/${updateStaff?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    console.log(res);
                    getPaginationList();
                    toast.success('Staff updated successfully');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not a update Staff!!!!');
            });
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Update Staff
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction} className="row">
                    <div className="mb-3 col-6">
                        <label className="form-label">Name</label>
                        <input
                            type="text"
                            name="name"
                            defaultValue={updateStaff?.name}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Email address</label>
                        <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            defaultValue={updateStaff?.email}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Contact Number</label>
                        <input
                            type="text"
                            name="contactNumber"
                            className="form-control"
                            onChange={handleChange}
                            defaultValue={updateStaff?.contactNumber}
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Designation</label>
                        <input
                            type="text"
                            name="designation"
                            className="form-control"
                            onChange={handleChange}
                            defaultValue={updateStaff?.designation}
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Department</label>
                        <input
                            type="text"
                            name="department"
                            onChange={handleChange}
                            defaultValue={updateStaff?.department}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3 col-6">
                        <label className="form-label">Image</label>
                        <input
                            type="file"
                            onChange={handleImage}
                            name="image"
                            className="form-control"
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default EditStaffListModal;
