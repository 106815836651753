import React from "react";
import { toast } from "react-hot-toast";
import { BiShow } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

function DashboardClientMessageTable({
  messageList,
  handleShow,
  setShowMessage,
  getPaginationList,
}) {
  const { _id, name, email, contactNumber, message } = messageList;

  const handleDeleteAction = (id) => {
    const agree = window.confirm(
      `Are you sure, you want to delete: "${name}?"`
    );
    if (agree) {
      fetch(`${process.env.REACT_APP_host}/api/v1/client-message/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            getPaginationList();
            toast.success(`${name} is a Deleted`);
          }
        });
    }
  };

  return (
    <tr>
      <td className="text-center align-middle table-text">{name}</td>
      <td className="text-center align-middle table-text">{email}</td>
      <td className="text-center align-middle table-text">{contactNumber}</td>
      <td className="text-center align-middle table-text">
        {message.length > 100 ? `${message.slice(0, 100)} .....` : message}
      </td>

      <td className="text-center align-middle table-text">
        <div className="d-flex align-items-center justify-content-center gap-2">
          <button
            onClick={() => {
              handleShow();
              setShowMessage(messageList);
            }}
            className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center "
          >
            <BiShow /> <span>Show</span>
          </button>
          <button
            onClick={() => handleDeleteAction(_id)}
            className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center"
          >
            <MdDelete /> <span>Delete</span>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default DashboardClientMessageTable;
