import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import Loading from '../../../../components/Loading/Loading';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { CiCircleRemove } from 'react-icons/ci';
import { IoIosAddCircle } from 'react-icons/io';
import { getImageUrl } from '../../../../hooks/imageUpload';
import JoditEditor from 'jodit-react';

function DashboardSubCategoryPlans() {
    const [plansGetById, setPlansGetById] = useState(null);
    const [fetchdata, setFetchdata] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const navigate = useNavigate();
    const currentPage = useRef();
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [benefit, setBenefit] = useState([{ benefitDis: '' }]);
    const [benefiteData, setBenefitData] = useState([{ benefitTitle: '' }]);
    const [keyFeature, setFeatureKey] = useState([{ keyFeature: '' }]);
    const [addSubCategoryPlans, setAddSubCategoryPlans] = useState({
        // plansId: "",
        plansCategorysId: '',
        subCategorytitle: '',
        image: '',
        subCategoryVedioLink: '',
    });

    useEffect(() => {
        currentPage.current = 1;

        getCategoryPlansList();
    }, [plansGetById]);

    const handleChange = (e) => {
        getCategoryPlansList();
        setAddSubCategoryPlans({
            ...addSubCategoryPlans,
            [e.target.name]: e.target.value,
        });
    };

    const handleImage = (e) => {
        getCategoryPlansList();
        setAddSubCategoryPlans({
            ...addSubCategoryPlans,
            image: e.target.files[0],
        });
    };

    const handleServiceChange = (e, index) => {
        const { value } = e.target;
        const list = [...keyFeature];
        list[index] = value;
        setFeatureKey(list);
    };

    const handleServiceRemove = (index) => {
        const list = [...keyFeature];
        list.splice(index, 1);
        setFeatureKey(list);
    };

    const handleServiceAdd = () => {
        setFeatureKey([...keyFeature, { keyFeature: '' }]);
    };

    // benefits

    const handleBenefitChange = (e, i) => {
        const { name, value } = e.target;
        const onchangeVal = [...benefiteData];
        onchangeVal[i][name] = value;
        setBenefitData(onchangeVal);
    };

    const handleAddDelete = (i) => {
        const deleteVal = [...benefiteData];
        deleteVal.splice(i, 1);
        setBenefitData(deleteVal);
    };
    const handleAddBenefits = () => {
        setBenefitData([...benefiteData, { benefitTitle: '' }]);
        setBenefit([...benefit, { benefitDis: '' }]);
    };

    const handleBenefitDescChange = (e, i) => {
        const value = e;
        const benefitDesc = [...benefit];
        benefitDesc[i] = { value };
        setBenefit(benefitDesc);
    };

    // Using map() method
    const combinedObjects = benefit?.map((description, index) => {
        return {
            subBenefitDescription: description?.value,
            subBenefitTitle: benefiteData[index]?.benefitTitle,
        };
    });
    // console.log(combinedObjects);

    // post
    const handleAddSubCategoryPlnas = (e) => {
        e.preventDefault();

        getImageUrl(addSubCategoryPlans?.image)
            .then((imageData) => {
                const newSubCategoryPlans = {
                    // plansId: addSubCategoryPlans.plansId,
                    subCategoryImageURL: imageData,
                    plansCategorysId: addSubCategoryPlans.plansCategorysId,
                    subCategorytitle: addSubCategoryPlans.subCategorytitle,
                    subCategoryVedioLink:
                        addSubCategoryPlans.subCategoryVedioLink,
                    subCategoryDescription: content,
                    subCategoryBenefit: combinedObjects,
                    keyFeature: keyFeature,
                };

                console.log(newSubCategoryPlans);

                fetch(
                    `${process.env.REACT_APP_host}/api/v1/sub-category-plans`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(newSubCategoryPlans),
                    }
                )
                    .then((res) => res.json())
                    .then((data) => {
                        if (data?.status) {
                            console.log(data);
                            getCategoryPlansList();
                            toast.success(
                                'Sub Category Plans added successfully'
                            );
                            setAddSubCategoryPlans(' ');
                            navigate(
                                '/dashboard/plans/view-sub-category-plans'
                            );
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        toast.error(' Not Sub Category Plans added!!!');
                    });
            })
            .catch((error) => {
                console.log(error);
                toast.error(' Not Sub Category Plans added!!!');
            });
    };

    // get plans
    const url = `${process.env.REACT_APP_host}/api/v1/plans`;
    const { data: allCategoryPlans = [], isLoading } = useQuery({
        queryKey: ['allCategoryPlans'],
        queryFn: async () => {
            try {
                const res = await fetch(url);
                const data = await res.json();
                getCategoryPlansList();
                return data?.data;
            } catch (err) {
                console.log(err);
            }
        },
    });

    function getCategoryPlansList() {
        const url = `${process.env.REACT_APP_host}/api/v1/plans/${plansGetById}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status) {
                    setFetchdata(data?.data);
                }
            });
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="home-content">
            <div class="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/plans/view-sub-category-plans"
                    class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Details </span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center">
                    Add Category Plans
                </h1>
                <form onSubmit={handleAddSubCategoryPlnas}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Plans Name
                        </label>
                        <select
                            onChange={(e) => setPlansGetById(e.target.value)}
                            name="plansId"
                            class="form-select"
                        >
                            <option value="" selected>
                                Choses a Plans
                            </option>
                            {allCategoryPlans?.map((allPlansCate) => (
                                <option
                                    value={allPlansCate?.slug}
                                    key={allPlansCate._id}
                                >
                                    {allPlansCate?.title}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Category Plans Name
                        </label>
                        <select
                            onChange={handleChange}
                            name="plansCategorysId"
                            class="form-select"
                        >
                            <option value="" selected>
                                Choses a Category Plans
                            </option>
                            {fetchdata?.plansCategory?.map((plansCate) => (
                                <option
                                    value={plansCate?._id}
                                    key={plansCate._id}
                                >
                                    {plansCate?.categoryTitle}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Sub Category Title
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addSubCategoryPlans.subCategorytitle}
                            name="subCategorytitle"
                            className="form-control"
                            required
                        />
                    </div>

                    {/* feature */}

                    <div className="mb-3">
                        <label className="form-label  fw-bold">Feature</label>
                        {keyFeature.map((singleService, index) => (
                            <div
                                key={index}
                                className="d-flex align-items-center gap-2 mb-3"
                            >
                                <div className="d-flex align-items-center gap-2 w-100 ">
                                    <input
                                        name="keyFeature"
                                        type="text"
                                        id="service"
                                        value={singleService?.keyFeature}
                                        className="form-control"
                                        onChange={(e) =>
                                            handleServiceChange(e, index)
                                        }
                                    />
                                    {keyFeature.length - 1 === index &&
                                        keyFeature.length < 4 && (
                                            <button
                                                type="button"
                                                onClick={handleServiceAdd}
                                                className="btn bg-primary text-white"
                                            >
                                                <span>
                                                    <IoIosAddCircle className="feature-btn" />
                                                </span>
                                            </button>
                                        )}
                                </div>
                                <div className="second-division">
                                    {keyFeature.length !== 1 && (
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleServiceRemove(index)
                                            }
                                            className="btn bg-danger text-white"
                                        >
                                            <span>
                                                <CiCircleRemove className="feature-btn" />
                                            </span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImage}
                            name="image"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Product Video Link
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addSubCategoryPlans.subCategoryVedioLink}
                            name="subCategoryVedioLink"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Introduction
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={content}
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>

                    {/* benefits */}

                    <div className="mb-3">
                        <label className="form-label fw-bold">Benefits</label>

                        {benefiteData.map((val, i) => (
                            <div key={i} className="d-flex gap-2">
                                <div className="w-100">
                                    <div className="mb-3 w-100">
                                        <label className="form-label fw-bold">
                                            Title
                                        </label>
                                        <input
                                            name="benefitTitle"
                                            value={val?.benefitTitle}
                                            className="form-control"
                                            onChange={(e) =>
                                                handleBenefitChange(e, i)
                                            }
                                        />
                                    </div>
                                    <div className="mb-3 w-100">
                                        <label className="form-label fw-bold">
                                            Description
                                        </label>
                                        <JoditEditor
                                            ref={editor}
                                            value={val?.benefit}
                                            onChange={(e) =>
                                                handleBenefitDescChange(e, i)
                                            }
                                            // onChange={(newContent) => {
                                            //   setBenefit(newContent);
                                            // }}
                                        />

                                        {/* <textarea
                      name="benefitDescription"
                      value={val.benefitDescription}
                      className="form-control"
                      onChange={(e) => handleBenefitChange(e, i)}
                    ></textarea> */}
                                    </div>
                                </div>

                                <div className="benefits-clone">
                                    {benefiteData.length - 1 === i && (
                                        <button
                                            type="button"
                                            onClick={handleAddBenefits}
                                            className="btn bg-primary text-white"
                                        >
                                            <span>
                                                <IoIosAddCircle className="feature-btn" />
                                            </span>
                                        </button>
                                    )}
                                </div>
                                <div className="second-division benefits-clone">
                                    {benefiteData.length !== 1 && (
                                        <button
                                            type="button"
                                            onClick={() => handleAddDelete(i)}
                                            className="btn bg-danger text-white"
                                        >
                                            <span>
                                                <CiCircleRemove className="feature-btn" />
                                            </span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <div className="mb-3">
            <label className="form-label fw-bold">Benefits</label>

            <JoditEditor
              ref={editor}
              value={benefit}
              onChange={(newContent) => {
                setBenefit(newContent);
              }}
            />
          </div> */}

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default DashboardSubCategoryPlans;
