import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import BgBannerImg from "../../../assets/BG/bgLeft.png";
import Image1 from "../../../assets/image/corporate-health-coverage.png";
import "./CorporateHealthCoverage.css";

const BgStyle = {
  backgroundImage: `url(${BgBannerImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};

const CorporateHealthCoverage = () => {
  const [banglatranslate, setBanglaTraslate] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setBanglaTraslate(currentLang);
  }, []);

  const ourPlansDataEn = [
    // {
    //   id: 0,
    //   title: `${t("corporateHealthSubmenuTitle1")}`,
    //   img: Image1,
    //   link: "",
    //   des: `${t("corporateHealthSubmenuDetails1")}`,
    //   list: [],
    // },
    {
      id: 0,
      title: `In-Patient Treatment (IPD) Plan`,
      img: Image1,
      link: "",
      des: `The Hospitalization Insurance Plan aims to offer extensive financial protection for medical expenses incurred during a hospital stay at least 24 hours, for an insured individual's illness or injury sustained within the policy coverage period.`,
      list: [],
    },
    {
      id: 1,
      title: `Maternity Benefits`,
      img: Image1,
      link: "",
      des: `Maternity is one of the common benefits offered under group health insurance. Maternity benefits in insurance provide financial support and peace of mind during what can be a costly and stressful time. This benefit is intended for female married employees/spouses who are up to 45 years old. The coverage for maternity benefits that is included in this plan is given below.`,
      list: [`Normal delivery`, `Caesarean/Ectopic/Extra-uterine pregnancy`, `Legal abortion or miscarriage`],
    },
    {
      id: 2,
      title: `General Out-Patient Treatment (OPD) Plan`,
      img: Image1,
      link: "",
      des: `Employees can make claims for expenses incurred under an Out Patient Treatment plan, which covers the cost of medicines, as well as diagnostics and procedures, without requiring hospitalization. The benefits provided by this plan for OPD expenses are listed below.`,
      list: [`Doctors Consultation fees`, `Medicine Cost`, `Investigation cost`],
    },
    {
      id: 3,
      title: `Dental Out-Patient Treatment Benefit`,
      img: Image1,
      link: "",
      des: `Dental OPD benefits in insurance refer to the coverage provided for dental treatments that do not require hospitalization. The benefits provided by this plan for dental expenses are listed below.`,
      list: [
        `Doctors Consultation fees`,
        `Amalgam, Resin Plastic & Temporary/Permanent Fillings`,
        `Routine Extraction`,
        `Medication`,
        `X-rays/Investigation`,
        `Root Canal Treatment, including bridging and capping`,
        `Extraction`,
        `Scaling & polishing once in a year for each member`,
      ],
    },
    {
      id: 4,
      title: `Optical Out-Patient Treatment Benefit`,
      img: Image1,
      link: "",
      des: `Optical OPD benefits in insurance refer to the coverage provided for optical treatments that do not require hospitalization. The benefits provided by this plan for optical expenses are listed below.`,
      list: [`Consultation`, `Vision tests for errors of refraction`, `Medication`, `Lenses & Spectacles`],
    },
  ];

  const ourPlansDataBn = [
    {
      id: 0,
      title: `ইন-পেশেন্ট ট্রিটমেন্ট প্ল্যান`,
      img: Image1,
      link: "",
      des: `পলিসি কভারেজ সময়ের মধ্যে অন্তত ২৪ ঘণ্টা হাসপাতালে ভর্তি হয়ে থাকতে হলে সেক্ষেত্রে আর্থিক সহায়তা প্রদানের জন্য হসপিটালাইজেশন ইন্স্যুরেন্স প্ল্যান প্রস্তুত করা হয়েছে।`,
      list: [],
    },
    {
      id: 1,
      title: `মাতৃত্বকালীন সুবিধা`,
      img: Image1,
      link: "",
      des: `গ্রুপ হেলথ ইন্স্যুরেন্সের মধ্যে খুব সাধারণ একটি সুবিধা হল মাতৃত্বকালীন সুবিধা। অতিরিক্ত খরচ এবং দুশ্চিন্তার সময়ে এই প্ল্যান মানসিক শান্তি নিশ্চিত করতে পারে। এই প্ল্যান সর্বোচ্চ ৪৫ বছর বয়স্ক বিবাহিত নারীদের জন্য অফার করা হয়। এই প্ল্যানে নিম্নোক্ত কভারেজগুলো দেয়`,
      list: [`নরমাল ডেলিভারি`, `সিজারিয়ান/এক্টোপিক/এক্সট্রা-ইউটেরিন প্রেগনেন্সি`, `লিগাল অ্যাবরশন/মিসক্যারেজ`],
    },
    {
      id: 2,
      title: `সাধারণ ওপিডি প্ল্যান`,
      img: Image1,
      link: "",
      des: `আউট পেশেন্ট ট্রিটমেন্ট প্ল্যানে কর্মচারীরা হাসপাতালে ভর্তি ছাড়াও ওষুধের খরচ, টেস্টের খরচ, বা অন্যান্য চিকিৎসা ব্যায় দাবী করতে পারে। সুবিধাগুলো নিম্নরূপঃ`,
      list: [`ডাক্তারের কনসালটেশন ফী`, `ওষুধের খরচ`, `প্যাথোলজি টেস্টের খরচ`],
    },
    {
      id: 3,
      title: `ডেন্টাল ওপিডি প্ল্যান`,
      img: Image1,
      link: "",
      des: `হাসপাতালে ভর্তি ব্যাতিত দাঁতের অন্যান্য চিকিৎসার খরচ এই প্ল্যানের মাধ্যমে দাবী করা যায়। সুবিধাসমূহ নিম্নরূপঃ`,
      list: [
        `ডাক্তারের কনসালটেশন ফী`,
        `অ্যামালগ্যাম, রেজিন প্লাস্টিক এবং অস্থায়ী/স্থায়ী ফিলিং`,
        `দাঁত তোলা`,
        `ঔষধ`,
        `এক্স-রে/তদন্ত`,
        `রুট ক্যানেল চিকিৎসা`,
        `নিষ্কাশন`,
        `স্কেলিং এবং পলিশিং (প্রতিটি সদস্যের জন্য বছরে একবার)`,
      ],
    },
    {
      id: 4,
      title: `অপটিকাল ওপিডি প্ল্যান`,
      img: Image1,
      link: "",
      des: `হাসপাতালে ভর্তি ব্যাতিত চোখের অন্যান্য চিকিৎসার খরচ এই প্ল্যানের মাধ্যমে দাবী করা যায়। সুবিধাসমূহ নিম্নরূপঃ`,
      list: [`ডাক্তারের কনসালটেশন ফী`, `রিফ্র্যাকশনের ভুলের কারণে চোখের টেস্ট`, `ওষুধপত্র`, `লেন্স এবং চশমা`],
    },
  ];

  const ourPlansData = banglatranslate === "bn" ? ourPlansDataBn : ourPlansDataEn;
  const [ourData, setOurData] = useState(ourPlansDataEn[0]);
  const [ourDataBn, setOurDataBn] = useState(ourPlansDataBn[0]);
  const [selected, setSelected] = useState(ourPlansDataEn[0]?.id);
  const [selectedBn, setSelectedBn] = useState(ourPlansDataBn[0]?.id);

  const handleClick = (idx, data) => {
    setOurData(data);
    setOurDataBn(data);
    setSelected(idx);
    setSelectedBn(idx);
  };

  return (
    <div>
      <div className="ourPlans-container pb-0">
        <h1 className="title title-service">{`${t("corporateHealthTitle")}`}</h1>
        <h6 className="text-center banner-text mt-2 w-75 mx-auto">{`${t("corporateHealthDetails")}`}</h6>
        <div className="corporate-content">
          <div className="slider-container">
            <div className="ourPlans-left">
              <div className="vl"></div>
              {ourPlansData.map((data, i) => (
                <div className="ourPlans-contnet-text align-items-baseline" key={i} onClick={() => handleClick(data?.id, data)}>
                  {banglatranslate === "bn" ? (
                    <div
                      className="cicrle"
                      style={{
                        backgroundColor: selectedBn === data?.id ? "#232176" : "",
                      }}
                    ></div>
                  ) : (
                    <div
                      className="cicrle"
                      style={{
                        backgroundColor: selected === data?.id ? "#232176" : "",
                      }}
                    ></div>
                  )}
                  {banglatranslate === "bn" ? (
                    <div
                      className="plans-text"
                      style={{
                        color: selectedBn === data?.id ? "#232176" : "",
                      }}
                    >
                      {data.title.split(" ").length === 2 ? (
                        data.title
                      ) : (
                        <div>
                          {data.title.split(" ").slice(0, 2).join(" ")} <br></br> {data.title.split(" ").slice(2, data.title.split(" ").length).join(" ")}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className="plans-text"
                      style={{
                        color: selected === data?.id ? "#232176" : "",
                      }}
                    >
                      {data.title.split(" ").length === 2 ? (
                        data.title
                      ) : (
                        <div>
                          {data.title.split(" ").slice(0, 2).join(" ")} <br></br> {data.title.split(" ").slice(2, data.title.split(" ").length).join(" ")}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="ourPlans-right">
              {banglatranslate === "bn" ? (
                <>
                  <div className="ourPlans-content-left">
                    <h4> {ourDataBn.title}</h4>
                    <p className="text banner-text">
                      {ourDataBn.des}
                      {ourDataBn.list.length ? ":" : ""}
                    </p>
                    <ul className="d-flex flex-column gap-2">
                      {ourDataBn?.list.map((lst, i) => (
                        <li key={i} className="corporate-coverage-list">
                          <div className="corporate-coverage-check">
                            <FaCheckCircle />
                          </div>
                          <p>{lst}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="ourPlans-img-right">
                    <img src={ourDataBn.img} />
                  </div>
                </>
              ) : (
                <>
                  <div className="ourPlans-content-left">
                    <h4> {ourData.title}</h4>
                    <p className="text banner-text">
                      {ourData.des}
                      {ourData.list.length ? ":" : ""}
                    </p>
                    <ul className="d-flex flex-column gap-2">
                      {ourData?.list.map((lst, i) => (
                        <li key={i} className="corporate-coverage-list">
                          <div className="corporate-coverage-check">
                            <FaCheckCircle />
                          </div>
                          <p>{lst}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="ourPlans-img-right">
                    <img src={ourData.img} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateHealthCoverage;
