import React from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";

function DashboardClaimsFormEdit({
  handleEditClose,
  editModal,
  updateFormClaims,
  getPaginationList,
  setUpdateFormClaims,
}) {
  const handleChange = (e) => {
    setUpdateFormClaims({
      ...updateFormClaims,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditClaims = (e) => {
    e.preventDefault();
    handleEditClose();

    const updatedClaims = {
      formCategory: updateFormClaims?.formCategory,
      formCategoryBn: updateFormClaims?.formCategoryBn,
      title: updateFormClaims?.title,
      titleBn: updateFormClaims?.titleBn,
      formLink: updateFormClaims?.formLink,
    };
    console.log(updatedClaims);

    fetch(
      `${process.env.REACT_APP_host}/api/v1/claims-form/${updateFormClaims?._id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedClaims),
      }
    )
      .then((res) => {
        if (res.status) {
          console.log(res);
          getPaginationList();
          toast.success("Form Claims updated successfully!");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Form Claims not updated!");
      });
  };
  return (
    <Modal
      show={editModal}
      onHide={handleEditClose}
      scrollable={true}
      //   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          Edit Claims Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <form onSubmit={handleEditClaims}>
          <div className="mb-3">
            <label className="form-label  fw-bold">Form Category</label>
            <select
              name="formCategory"
              class="form-select"
              defaultValue={updateFormClaims?.formCategory}
              onChange={handleChange}
              required
            >
              <option value="">Choose Form Category</option>
              <option value="General">General</option>
              <option value="Statement(Updated)">Statement(Updated)</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label  fw-bold">Form Category(Bangla)</label>
            <select
              name="formCategoryBn"
              class="form-select"
              defaultValue={updateFormClaims?.formCategoryBn}
              onChange={handleChange}
              required
            >
              <option value="">Choose Form Category</option>
              <option value="সাধারণ বিষয়াদি">সাধারণ বিষয়াদি</option>
              <option value="স্টেটমেন্ট আপডেট">স্টেটমেন্ট আপডেট</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label  fw-bold">Title</label>
            <input
              type="text"
              onChange={handleChange}
              defaultValue={updateFormClaims?.title}
              name="title"
              className="form-control"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label  fw-bold">Title(Bangla)</label>
            <input
              type="text"
              name="titleBn"
              onChange={handleChange}
              defaultValue={updateFormClaims?.titleBn}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label className="form-label  fw-bold">Form Link</label>
            <input
              type="text"
              name="formLink"
              onChange={handleChange}
              defaultValue={updateFormClaims?.formLink}
              className="form-control"
            />
          </div>

          <button type="submit" className="btn btn-primary px-4">
            Save
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default DashboardClaimsFormEdit;
