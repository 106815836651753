import React from 'react';
import { Link } from 'react-router-dom';

function SpecialInsurancePlanCard({ t, allProduct, banglatranslate }) {
    const {
        imageURL,
        productTitle,
        productTitleBn,
        description,
        descriptionBn,
        link,
    } = allProduct;
    return (
        <div className="blog-card">
            <div className="card-img easylife-product-img">
                <img src={imageURL} alt="" />
            </div>
            <div className="card-body-content">
                <div className="blog-name-title">
                    <h4>
                        {banglatranslate === 'bn'
                            ? productTitleBn
                            : productTitle}
                    </h4>
                    <p className="blog-text-desc">
                        {banglatranslate === 'bn'
                            ? descriptionBn?.length > 115
                                ? `${descriptionBn?.slice(0, 115)} .....`
                                : descriptionBn
                            : description?.length > 115
                            ? `${description?.slice(0, 115)} .....`
                            : description}
                    </p>
                    {
                        <div className="mt-4">
                            <Link
                                to={link}
                                className="btn-book btns card-btn px-3 py-1 btn btn-primary w-100"
                            >
                                {t('viewmore')}
                            </Link>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SpecialInsurancePlanCard;
