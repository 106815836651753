import axios from 'axios';
import React, { useRef } from 'react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';

function DashboardAddBlog() {
    const navigate = useNavigate();
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [contentBn, setContentBn] = useState('');
    const [addBlog, setAddBlog] = useState({
        title: '',
        titleBn: '',
        image: '',
    });

    const handleChange = (e) => {
        setAddBlog({ ...addBlog, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setAddBlog({ ...addBlog, image: e.target.files[0] });
    };

    // post
    const handleAddBlog = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('title', addBlog?.title);
        formData.append('titleBn', addBlog?.titleBn);
        formData.append('description', content);
        formData.append('descriptionBn', contentBn);
        formData.append('image', addBlog?.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for (const value of formData.values()) {
            console.log(value);
        }

        await axios
            .post(`${process.env.REACT_APP_host}/api/v1/blog`, formData, config)
            .then((res) => {
                if (res.status) {
                    toast.success('Blog added successfully');
                    setAddBlog('');
                    navigate('/dashboard/media/all-blog');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not added successfully');
            });
    };
    return (
        <div className="home-content">
            <div class="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/media/all-blog"
                    class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Blog</span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center"> Add Blog</h1>
                <form onSubmit={handleAddBlog}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Title</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addBlog.title}
                            name="title"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Title(Bangla)
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addBlog.titleBn}
                            name="titleBn"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            onChange={handleImage}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={content}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description(Bangla)
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={contentBn}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default DashboardAddBlog;
