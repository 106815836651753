import React from "react";
import BoardOfDirectorsBanner from "./BoardOfDirectorsBanner";
import BoardDirectorsLinst from "./BoardDirectorsLinst";
import useTitle from "../../../hooks/useTitle";

function BoardOfDirectors() {
  useTitle("Board of Directors");
  return (
    <>
      {/* <BoardOfDirectorsBanner /> */}
      <BoardDirectorsLinst />
    </>
  );
}

export default BoardOfDirectors;
