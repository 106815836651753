import React, { useEffect } from "react";
import "./partnerClient.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Loading from "../../../components/Loading/Loading";

function PartnerClient() {
  const [microinsurancePartnerLogo, setMicroinsurancePartnerLogo] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_host}/api/v1/partner-logo?pageSelect=Microinsurance Page`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setMicroinsurancePartnerLogo(data?.data?.partnerLogoList);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="secondary-container body-container partnet-content">
      <h1 className="title review-tilte">{t("partner")}</h1>
      <div className="partnet-slider">
        <Swiper
          slidesPerView={1}
          spaceBetween={15}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          autoplay={{ delay: 2000 }}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            "@1.50": {
              slidesPerView: 6,
              spaceBetween: 15,
            },
          }}
          modules={[Navigation, Pagination, Autoplay]}
          className="mySwiper partner-contianer"
        >
          {microinsurancePartnerLogo?.map((partnerSlider) => (
            <SwiperSlide key={partnerSlider?._id} className="slider-partner">
              <div>
                <img src={partnerSlider?.imageURL} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default PartnerClient;
