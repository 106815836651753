import { useQuery } from "@tanstack/react-query";
import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { FiEye } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import DashboardDistrictName from "./DashboardDistrictName";

const officeCategorys = [
  {
    id: 1,
    name: "Area Office",
  },
  {
    id: 2,
    name: "Branch Office",
  },
  {
    id: 3,
    name: "Regional Office",
  },
];

function DashboardLocateBranch() {
  const [getDivision, setGetDivision] = useState(null);
  const editor = useRef(null);
  const navigate = useNavigate();
  const [officeCategory, setOfficeCategory] = useState("");
  const [division, setDivision] = useState("");
  const [district, setDistrict] = useState("");
  const [area, setArea] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [mapLink, setMapLink] = useState("");
  const currentPage = useRef();

  useEffect(() => {
    currentPage.current = 1;
    getNameByDivision();
  }, [division]);

  // get division district

  const url = `${process.env.REACT_APP_host}/api/v1/division-district`;
  const { data: allDivisionDistrict = [], isLoading } = useQuery({
    queryKey: [" allDivisionDistrict"],
    queryFn: async () => {
      try {
        const res = await fetch(url);
        const data = await res.json();
        return data?.data;
      } catch (err) {
        console.log(err);
      }
    },
  });

  // get by name division
  function getNameByDivision() {
    const url = `${process.env.REACT_APP_host}/api/v1/division-district?division=${division}`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          setGetDivision(data?.data);
          // setDivision("");
        }
      });
  }

  //   post hospital
  const handleAddOfficeBranch = (e) => {
    e.preventDefault();

    const newOfficeBranch = {
      officeCategory,
      division,
      district,
      area,
      officeName,
      address,
      contactPerson: contact,
      mapLink,
    };

    console.log(newOfficeBranch);

    fetch(`${process.env.REACT_APP_host}/api/v1/all-branch`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(newOfficeBranch),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          console.log(data);
          toast.success("Branch added successfully!");
          navigate("/dashboard/locate-branch/all-office-branch");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Branch is not added!");
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="home-content">
        <div className="d-flex justify-content-end gap-2 align-items-center">
          <Link
            to="/dashboard/locate-branch/all-office-branch"
            className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
          >
            <FiEye />
            <span>View Details </span>
          </Link>
        </div>
        <div className="shadow rounded main-contents">
          <h1 className="dashboard-title text-center mb-4">
            Add A Office Branch
          </h1>
          <form onSubmit={handleAddOfficeBranch}>
            <div className="mb-3">
              <label className="form-label  fw-bold">Office Category</label>
              <select
                name="officeCategory"
                className="form-select"
                onChange={(e) => setOfficeCategory(e.target.value)}
              >
                <option value="">Choose office category</option>
                {officeCategorys?.map((officeCate) => (
                  <option value={officeCate?.name} key={officeCate.id}>
                    {officeCate?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label  fw-bold">Division</label>
              <select
                name="division"
                class="form-select"
                onChange={(e) => setDivision(e.target.value)}
              >
                <option value="">Choose division</option>
                {allDivisionDistrict?.map((divisions) => (
                  <option key={divisions._id}>{divisions?.division}</option>
                ))}
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label fw-bold">District</label>
              <select
                name="district"
                className="form-select"
                onChange={(e) => setDistrict(e.target.value)}
              >
                <option value="">Choses district</option>
                {getDivision?.map((getUpdateDivision) => (
                  <DashboardDistrictName
                    getUpdateDivision={getUpdateDivision}
                    key={getUpdateDivision?._id}
                  />
                ))}
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label  fw-bold">Area</label>
              <input
                type="text"
                value={area}
                onChange={(e) => setArea(e.target.value)}
                name="area"
                className="form-control"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label  fw-bold">Office Name</label>
              <input
                type="text"
                value={officeName}
                onChange={(e) => setOfficeName(e.target.value)}
                name="officeName"
                className="form-control"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label  fw-bold">Address</label>
              <textarea
                rows={2}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                name="address"
                className="form-control"
                required
              ></textarea>
            </div>

            <div className="mb-3">
              <label className="form-label fw-bold">Contact Person</label>
              <JoditEditor
                ref={editor}
                value={contact}
                // config={config}
                // tabIndex={1} // tabIndex of textarea
                onChange={(newContent) => {
                  setContact(newContent);
                }}
              />
            </div>

            <div className="mb-3">
              <label className="form-label  fw-bold">Map Link</label>
              <input
                type="text"
                value={mapLink}
                onChange={(e) => setMapLink(e.target.value)}
                name="mapLink"
                className="form-control"
                required
              />
            </div>

            <button type="submit" className="btn btn-primary px-4">
              Save
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default DashboardLocateBranch;
