import React, { useEffect, useRef, useState } from 'react';
import { IoMdAddCircle } from 'react-icons/io';
import Loading from '../../../components/Loading/Loading';
import { getImageUrl } from '../../../hooks/imageUpload';
import { toast } from 'react-hot-toast';
import DashboardExtendManagmentList from './DashboardExtendManagmentList';
import DashboardExtendManagementAdd from './DashboardExtendManagementAdd';
import DashboardExtendMangementEdit from './DashboardExtendMangementEdit';
import axios from 'axios';

function DashboardExtendManagementTeam() {
    const [allExtenedManagement, setAllExtenedManagement] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState(5);
    const [pageCount, setPageCount] = useState(1);
    const [editModal, setEditModal] = useState(false);
    const [addShow, setAddShow] = useState(false);
    const [updateManagment, setUpdateMangment] = useState(null);
    const [newManagment, setNewManagment] = useState({
        name: '',
        nameBn: '',
        designation: '',
        designationBn: '',
        image: '',
    });
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const editorBn = useRef(null);
    const [contentBn, setContentBn] = useState('');

    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    const handleAddClose = () => setAddShow(false);
    const handleAddShow = () => setAddShow(true);
    const handleEditClose = () => setEditModal(false);
    const handleEditShow = () => setEditModal(true);

    const handleChange = (e) => {
        setNewManagment({ ...newManagment, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setNewManagment({ ...newManagment, image: e.target.files[0] });
    };

    // post
    const handleAddManagementList = (e) => {
        e.preventDefault();
        handleAddClose();

        const formData = new FormData();

        formData.append('name', newManagment?.name);
        formData.append('nameBn', newManagment?.nameBn);
        formData.append('designation', newManagment?.designation);
        formData.append('designationBn', newManagment?.designationBn);
        formData.append('description', content);
        formData.append('descriptionBn', contentBn);
        formData.append('image', newManagment?.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for (const value of formData.values()) {
            console.log(value);
        }

        axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/extend-management`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    toast.success('Exteneded Management added successfully');
                    setNewManagment('');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not added!!!!');
            });
    };

    // pagination
    function handlePageClick(e) {
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/extend-management`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setAllExtenedManagement(data?.data?.extenedManagementTeamLists);
                setIsLoading(false);
            });
    }
    return (
        <div className="home-content">
            <div className="d-flex flex-column align-items-center">
                <div className="w-100 d-flex justify-content-between">
                    <h1 className="dashboard-title">Extend Management List</h1>
                    <button
                        onClick={handleAddShow}
                        className="btn btn-sm btn-primary d-flex align-items-center fw-semibold"
                    >
                        <IoMdAddCircle className="add-icon" />{' '}
                        <span>Add Extend Management</span>
                    </button>
                </div>

                <div className="list-contaner w-100">
                    {allExtenedManagement?.length === 0 ? (
                        <div className="min-vh-100 d-flex align-items-center justify-content-center">
                            No Extend Management Team Is Available
                        </div>
                    ) : (
                        <>
                            <div className="table-responsive">
                                <table className="table table-light table-bordered">
                                    <thead>
                                        <tr className="table-tr">
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Photo
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Designation
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allExtenedManagement?.map(
                                            (newManagment) => (
                                                <DashboardExtendManagmentList
                                                    key={newManagment?._id}
                                                    newManagment={newManagment}
                                                    handleEditShow={
                                                        handleEditShow
                                                    }
                                                    setUpdateMangment={
                                                        setUpdateMangment
                                                    }
                                                    getPaginationList={
                                                        getPaginationList
                                                    }
                                                />
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}

                    <DashboardExtendManagementAdd
                        show={addShow}
                        handleClose={handleAddClose}
                        handleChange={handleChange}
                        handleImage={handleImage}
                        handleAddManagementList={handleAddManagementList}
                        newManagment={newManagment}
                        setContent={setContent}
                        editor={editor}
                        content={content}
                        setContentBn={setContentBn}
                        editorBn={editorBn}
                        contentBn={contentBn}
                    />

                    <DashboardExtendMangementEdit
                        editModal={editModal}
                        handleEditClose={handleEditClose}
                        updateManagment={updateManagment}
                        setUpdateMangment={setUpdateMangment}
                        getPaginationList={getPaginationList}
                    />
                </div>
            </div>
        </div>
    );
}

export default DashboardExtendManagementTeam;
