import React from "react";

function DashboardJobs() {
  return (
    <div className="home-content min-vh-100 d-flex align-items-center justify-content-center ">
      <h1 className="plans-contents"> Dashboard Jobs </h1>
    </div>
  );
}

export default DashboardJobs;
