import React from 'react';
import Buttons from '../../../components/Buttons/Buttons';
import { Modal } from 'react-bootstrap';

const ApplyTodayModal = ({ show, handleClose, data, handleAddApplication, handleSubmit, errors, register }) => {
    return (
        <div>
            <Modal scrollable={true} size="lg" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='book-title'>Apply for "{data?.positionName}" position</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='px-3 py-3'>
                        <form className="mt-3" onSubmit={handleSubmit(handleAddApplication)}>
                            <div className="row g-3">
                                <div className="col-12 col-lg-6">
                                    <label className='mb-1 d-flex align-items-center gap-2 calculator-text-size'>
                                        <span>First Name <span className="text-danger">*</span></span>
                                        {errors.firstName && <span className='text-danger'>[Required Field]</span>}
                                    </label>
                                    <input type="text" {...register("firstName", { required: true })} placeholder='Enter First Name' className="form-control form-contact py-1 calculator-text-size" />
                                </div>
                                <div className="col-12 col-lg-6">
                                    <label className='mb-1 d-flex align-items-center gap-2 calculator-text-size'>
                                        <span>Last Name <span className="text-danger">*</span></span>
                                        {errors.lastName && <span className='text-danger'>[Required Field]</span>}
                                    </label>
                                    <input type="text" {...register("lastName", { required: true })} placeholder='Enter Last Name' className="form-control form-contact py-1 calculator-text-size" />
                                </div>
                                <div className="col-12 col-lg-6">
                                    <label className='mb-1 d-flex align-items-center gap-2 calculator-text-size'>
                                        <span>Email <span className="text-danger">*</span></span>
                                        {errors.email && <span className='text-danger'>[Required Field]</span>}
                                    </label>
                                    <input type="email" {...register("email", { required: true })} placeholder='Enter email' className="form-control form-contact py-1 calculator-text-size" />
                                </div>
                                <div className="col-12 col-lg-6">
                                    <label className='mb-1 d-flex align-items-center gap-2 calculator-text-size'>
                                        <span>Phone <span className="text-danger">*</span></span>
                                        {errors.phone && <span className='text-danger'>[Required Field]</span>}
                                    </label>
                                    <input type="text" {...register("phone", { required: true })} placeholder='Enter phone number' className="form-control form-contact py-1 calculator-text-size" />
                                </div>
                                <div className="col-12">
                                    <label className='mb-1 d-flex align-items-center gap-2 calculator-text-size'>
                                        <span>Upload Your CV (.pdf) <span className="text-danger">*</span></span>
                                        {errors.image && <span className='text-danger'>[Required Field]</span>}
                                    </label>
                                    <input type="file" accept=".pdf" {...register("image", { required: true })} className="form-control form-contact py-1 calculator-text-size" />
                                </div>
                            </div>

                            <div className="mt-5 col-12 col-lg-4 mx-auto">
                                <Buttons type="submit" classes="btns send-btn w-100 rounded-2 py-1">Submit</Buttons>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ApplyTodayModal;