import React, { useState } from 'react';
import './allStaffList.css';
import OurPlansTitle from '../../CatagoryPlans/OurPlans/OurPlansTitle';
import image1 from '../../../assets/staffList/s-1.jpeg';
import image2 from '../../../assets/staffList/s-2.jpeg';
import image3 from '../../../assets/staffList/s-3.jpeg';
import image4 from '../../../assets/staffList/s-4.jpeg';
import image5 from '../../../assets/staffList/s-5.jpeg';
import TableList from '../../../components/TableList/TableList';
import { BsSearch } from 'react-icons/bs';
import Loading from '../../../components/Loading/Loading';
import { useEffect } from 'react';
import { useRef } from 'react';
import ReactPaginate from 'react-paginate';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

function AllStaffList() {
    const [newAllStaffList, setAllStaffLists] = useState([]);
    const [search, setSearch] = useState('');
    const [isUrl, setIsUrl] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [query, setQuery] = useState('');
    const [limit, setLimit] = useState(300);
    const [isLoading, setIsLoading] = useState(true);

    const currentPage = useRef();

    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, [search]);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    // pagination
    function handlePageClick(e) {
        console.log(e);
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function changeLimit() {
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/staff`;
        fetch(
            url,
            // isUrl === true
            //   ? `${process.env.REACT_APP_host}/api/v1/staff?name=${search}`
            //   : `${process.env.REACT_APP_host}/api/v1/staff?page=${currentPage.current}&limit=${limit}`,

            {
                method: 'GET',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllStaffLists(data?.data?.staffLists);
                setIsLoading(false);
            });
    }

    const handleFilterChange = (e) => {
        setSearch(e.target.value);
        setIsUrl(true);
    };

    return (
        <div className="body-container secondary-container message-contaner banner-container">
            <div>
                <h1 className=" title text-center list-titles">
                    {t('stuffListTitle')}
                </h1>
            </div>

            <div className="list-contaner">
                <div className="input-group mb-2">
                    <span className="input-group-text search-icon" id="search">
                        <BsSearch />
                    </span>
                    <input
                        value={search}
                        onChange={handleFilterChange}
                        // onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        className="form-control search-field"
                        placeholder="Search by name, designation, department"
                        aria-label="sarech"
                        aria-describedby="search"
                    />
                </div>
                {/* <div>
          {console.log(limit)}
          <Form.Select
            onChange={(e) => setLimit(e.target.value)}
            onClick={changeLimit}
            aria-label="Default select example"
          >
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div> */}

                {newAllStaffList?.length === 0 ? (
                    <div className="min-vh-100 d-flex align-items-center justify-content-center">
                        No Staffs Avaliable
                    </div>
                ) : (
                    <>
                        <div className="table-responsive">
                            <table className="table table-light table-bordered">
                                <thead>
                                    <tr className="table-tr">
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            id
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            name
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Designation
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Department
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Contact
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Email
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newAllStaffList
                                        .filter((item) => {
                                            return search.toLowerCase() === ''
                                                ? item
                                                : item.name
                                                      .toLowerCase()
                                                      .includes(search) ||
                                                      item.designation
                                                          .toLowerCase()
                                                          .includes(search) ||
                                                      item.department
                                                          .toLowerCase()
                                                          .includes(search);
                                        })
                                        .map((staffList, i) => (
                                            <TableList
                                                staffList={staffList}
                                                i={
                                                    (currentPage.current - 1) *
                                                        limit +
                                                    i +
                                                    1
                                                }
                                                key={staffList._id}
                                            />
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            /> */}
                    </>
                )}
            </div>
        </div>
    );
}

export default AllStaffList;
