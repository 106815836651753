import React from "react";
import "./ImageGallery.css"
import { useLoaderData, useLocation } from "react-router-dom";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

function ImageGallery() {
  // const { state: data } = useLocation();
  const data = useLoaderData();

  return <div className="service-container">
    <div className="body-container secondary-container">
      <div className="d-flex justify-content-center">
        <div className="gallery-title-area">
          <h1 class="title title-service">Album: {data?.data?.title}</h1>
        </div>
      </div>
      <div className="section-margin-top">
        <PhotoProvider>
          <div className="gallery-image-grid">
            {
              data?.data?.imageURL?.map((img, i) =>
                <PhotoView key={i} src={img}>
                  <img src={img} alt='photoGallery' style={{ cursor: "pointer" }} />
                </PhotoView>
              )
            }
          </div>
        </PhotoProvider>
      </div>
    </div>
  </div>;
}

export default ImageGallery;
