import axios from 'axios';
import './DashboardImageGallery.css';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosAddCircle } from 'react-icons/io';
import { CiCircleRemove } from 'react-icons/ci';
import { getImageUrl } from '../../../../hooks/imageUpload';

function DashBoardImageGallery() {
    const navigate = useNavigate();

    const [imageUpload, setImageUpload] = useState([{ imageUpload: '' }]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [addTitle, setAddTitle] = useState(null);

    // const handleImageChange = (event) => {
    //   const files = event.target.files;
    //   const imagesArray = [...selectedImages];

    //   for (let i = 0; i < files.length; i++) {
    //     imagesArray.push(files[i]);
    //   }
    //   setSelectedImages(imagesArray);
    // };

    // const handleChange = (e) => {
    //   setAddTitle({ ...addTitle, [e.target.name]: e.target.value });
    // };

    // // multiple image upload
    // const handleImageUpload = (e, index) => {
    //   const value = e.target.files[0];
    //   const list = [...imageUpload];
    //   list[index] = value;
    //   setImageUpload(list);
    //   console.log(list);
    // };

    // const handleImageUploadRemove = (index) => {
    //   const list = [...imageUpload];
    //   list.splice(index, 1);
    //   setImageUpload(list);
    // };

    // const handleImageUploadAdd = () => {
    //   setImageUpload([...imageUpload, { imageUpload: "" }]);
    // };

    const handleImageChange = (event) => {
        const files = event.target.files;
        const imagesArray = [...selectedImages];

        for (let i = 0; i < files.length; i++) {
            imagesArray.push(files[i]);
        }
        setSelectedImages(imagesArray);
    };

    const handleChange = (e) => {
        setAddTitle({ ...addTitle, [e.target.name]: e.target.value });
    };

    // post
    const handleAddPhoto = async (e) => {
        e.preventDefault();

        e.preventDefault();

        const addPhoto = {
            selectedImages,
            title: addTitle.title,
        };

        const formData = new FormData();

        formData.append('title', addPhoto?.title);

        [...selectedImages].forEach((images) => {
            formData.append('image', images);
        });

        for (const value of formData.values()) {
            console.log(value);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        await axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/media-photo`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    console.log(res);
                    toast.success('Photo added successfully');
                    navigate('/dashboard/media/view-photo');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not added successfully');
            });
    };

    return (
        <div className="home-content">
            <div class="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/media/view-photo"
                    class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Album</span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center">Add Album</h1>

                <form onSubmit={handleAddPhoto}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Album Name
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={setAddTitle.title}
                            name="title"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Multiple Image Upload (Maximum size: 5 MB )
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            multiple
                            onChange={handleImageChange}
                            className="form-control"
                            required
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>

        // <div className="home-content">
        //     <div class="d-flex justify-content-end align-items-center">
        //         <Link
        //             to="/dashboard/media/view-photo"
        //             class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
        //         >
        //             <FiEye />
        //             <span>View Album</span>
        //         </Link>
        //     </div>
        //     <div className="shadow rounded main-contents">
        //         <h1 className="dashboard-title text-center">Add Album</h1>

        //         <form onSubmit={handleAddPhoto}>
        //             <div className="mb-3">
        //                 <label className="form-label  fw-bold">
        //                     Album Name
        //                 </label>
        //                 <input
        //                     type="text"
        //                     onChange={handleChange}
        //                     value={setAddTitle.title}
        //                     name="title"
        //                     className="form-control"
        //                     required
        //                 />
        //             </div>
        //             <div className="mb-3">
        //                 <label className="form-label  fw-bold">
        //                     Multiple Image Upload
        //                 </label>
        //                 {/* <input
        //       type="file"
        //       accept="image/*"
        //       name="image"
        //       multiple
        //       onChange={handleImageChange}
        //       className="form-control"
        //       required
        //     /> */}

        //                 {imageUpload.map((singleService, index) => (
        //                     <div
        //                         key={index}
        //                         className="d-flex align-items-center gap-2 mb-3"
        //                     >
        //                         <div className="d-flex align-items-center gap-2 w-100 ">
        //                             <input
        //                                 name="imageUpload"
        //                                 type="file"
        //                                 accept="image/*"
        //                                 id="service"
        //                                 // value={singleService?.imageUpload}
        //                                 className="form-control"
        //                                 onChange={(e) =>
        //                                     handleImageUpload(e, index)
        //                                 }
        //                             />
        //                             {imageUpload.length - 1 === index && (
        //                                 <button
        //                                     type="button"
        //                                     onClick={handleImageUploadAdd}
        //                                     className="btn bg-primary text-white"
        //                                 >
        //                                     <span>
        //                                         <IoIosAddCircle className="feature-btn" />
        //                                     </span>
        //                                 </button>
        //                             )}
        //                         </div>
        //                         <div className="second-division">
        //                             {imageUpload.length !== 1 && (
        //                                 <button
        //                                     type="button"
        //                                     onClick={() =>
        //                                         handleImageUploadRemove(index)
        //                                     }
        //                                     className="btn bg-danger text-white"
        //                                 >
        //                                     <span>
        //                                         <CiCircleRemove className="feature-btn" />
        //                                     </span>
        //                                 </button>
        //                             )}
        //                         </div>
        //                     </div>
        //                 ))}
        //             </div>

        //             <button type="submit" className="btn btn-primary px-4">
        //                 Save
        //             </button>
        //         </form>
        //     </div>
        // </div>
    );
}

export default DashBoardImageGallery;
