import React, { useEffect } from "react";
import "./bookModal.css";
import Modal from "react-bootstrap/Modal";
import Buttons from "../Buttons/Buttons";
import { useContext } from "react";
import { BookContext } from "../../context/BookingContext/BookingContext";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useState } from "react";

function BookModal({ bookingFor }) {
  const {
    handleAddBooking,
    show,
    handleClose,
    handleChangeNumberBooking,
    inputValueBooking,
    errorMessageBooking,
  } = useContext(BookContext);

  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const minDate = () => {
    const today = new Date().toISOString().split("T")[0];
    return today;
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      //   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          {t("letsTalk")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <form className="mt-3" onSubmit={handleAddBooking}>
          <div className="d-flex align-items-center gap-2">
            <input
              className="form-control form-contact mb-4 w-100 py-2"
              name="name"
              required
              placeholder={t("name")}
              type="text"
            />
            <input
              type="email"
              className="form-control form-contact mb-4 w-100 py-2"
              placeholder={t("email")}
              name="email"
              required
            />
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className="w-100 mb-4">
              <input
                type="number"
                className="form-control form-contact py-2  number-arrow"
                placeholder={t("contactNumber")}
                name="contactNumber"
                value={inputValueBooking}
                onChange={handleChangeNumberBooking}
                maxLength={11}
                required
              />
              {errorMessageBooking && (
                <p style={{ color: "red", fontSize: "12px" }} className="mb-0">
                  {errorMessageBooking}
                </p>
              )}
            </div>

            <div className="w-100">
              <input
                className="form-control form-contact mb-4 w-100 py-2"
                placeholder={t("address")}
                type="text"
                name="address"
                required
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className="w-100">
              <select
                className="form-select   form-contact mb-4 py-2"
                aria-label="Default select example"
                name="bookingFor"
              >
                <option value="General Querys">{t("query")}</option>
                <option value="Retail">{t("retail")}</option>
                <option value="Corporate">{t("corporate")}</option>
                <option value="Microinsurance">{t("microinsurance")}</option>
                <option value="Bancassurance">{t("bancassurance")}</option>
                <option value="Claims">{t("claims")}</option>
                <option value="Career">{t("career")}</option>
                <option value="Customer Support">{t("customerSupport")}</option>
              </select>
            </div>

            <div className="w-100">
              <input
                type="date"
                name="date"
                value={selectedDate}
                min={minDate()}
                onChange={(e) => setSelectedDate(e.target.value)}
                required
                className="form-control form-contact mb-4 w-100 py-2"
                placeholder="Appointment date"
              />
            </div>
          </div>

          <textarea
            name="message"
            className="form-control form-contact contact-area mb-4 "
            placeholder={t("message")}
            required
          ></textarea>

          <div>
            <Buttons classes="btns send-btn w-100">{t("submit")}</Buttons>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default BookModal;
