import i18n from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ShareholderTableBody from "./ShareholderTableBody";

function ShareholderListTable() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const shareholderLists = [
    {
      id: 1,
      nameOfShareholder: `${t("nameOfShareholder1")}`,
      noOfShare: `${t("noOfShare1")}`,
      shareholdings: `${t("shareholdings1")}`,
    },
    {
      id: 2,
      nameOfShareholder: `${t("nameOfShareholder2")}`,
      noOfShare: `${t("noOfShare2")}`,
      shareholdings: `${t("shareholdings2")}`,
    },
    {
      id: 3,
      nameOfShareholder: `${t("nameOfShareholder3")}`,
      noOfShare: `${t("noOfShare3")}`,
      shareholdings: `${t("shareholdings3")}`,
    },
    {
      id: 4,
      nameOfShareholder: `${t("nameOfShareholder4")}`,
      noOfShare: `${t("noOfShare4")}`,
      shareholdings: `${t("shareholdings4")}`,
    },
    {
      id: 5,
      nameOfShareholder: `${t("nameOfShareholder5")}`,
      noOfShare: `${t("noOfShare5")}`,
      shareholdings: `${t("shareholdings5")}`,
    },
    {
      id: 6,
      nameOfShareholder: `${t("nameOfShareholder6")}`,
      noOfShare: `${t("noOfShare6")}`,
      shareholdings: `${t("shareholdings6")}`,
    },
    {
      id: 7,
      nameOfShareholder: `${t("nameOfShareholder7")}`,
      noOfShare: `${t("noOfShare7")}`,
      shareholdings: `${t("shareholdings7")}`,
    },
    {
      id: 8,
      nameOfShareholder: `${t("nameOfShareholder8")}`,
      noOfShare: `${t("noOfShare8")}`,
      shareholdings: `${t("shareholdings8")}`,
    },
    {
      id: 9,
      nameOfShareholder: `${t("nameOfShareholder9")}`,
      noOfShare: `${t("noOfShare9")}`,
      shareholdings: `${t("shareholdings9")}`,
    },
    {
      id: 10,
      nameOfShareholder: `${t("nameOfShareholder10")}`,
      noOfShare: `${t("noOfShare10")}`,
      shareholdings: `${t("shareholdings10")}`,
    },
    {
      id: 11,
      nameOfShareholder: `${t("nameOfShareholder11")}`,
      noOfShare: `${t("noOfShare11")}`,
      shareholdings: `${t("shareholdings11")}`,
    },
    {
      id: 12,
      nameOfShareholder: `${t("nameOfShareholder12")}`,
      noOfShare: `${t("noOfShare12")}`,
      shareholdings: `${t("shareholdings12")}`,
    },
    {
      id: 13,
      nameOfShareholder: `${t("nameOfShareholder13")}`,
      noOfShare: `${t("noOfShare13")}`,
      shareholdings: `${t("shareholdings13")}`,
    },
    {
      id: 14,
      nameOfShareholder: `${t("nameOfShareholder14")}`,
      noOfShare: `${t("noOfShare14")}`,
      shareholdings: `${t("shareholdings14")}`,
    },
  ];

  // const totalShare = shareholderLists.map()

  return (
    <div className="service-container pt-5">
      <div className="body-container secondary-container">
        <div className="table-responsive">
          <table className="table table-light table-bordered">
            <thead>
              <tr className="table-tr">
                <th scope="col" className="text-center align-middle table-th">
                  {t("shareholderSL")}
                </th>
                <th scope="col" className="text-center align-middle table-th">
                  {t("nameOfShareholder")}
                </th>
                <th scope="col" className="text-center align-middle table-th">
                  {t("noOfShare")}
                </th>
                <th scope="col" className="text-center align-middle table-th">
                  {t("shareholdings")}
                </th>
              </tr>
            </thead>
            <tbody>
              {shareholderLists
                // .filter((item) => {
                //   return search.toLowerCase() === ""
                //     ? item
                //     : item.name.toLowerCase().includes(search) ||
                //         item.designation.toLowerCase().includes(search) ||
                //         item.department.toLowerCase().includes(search);
                // })
                .map((staffList, i) => (
                  <ShareholderTableBody staffList={staffList} key={staffList.id} idx={i} />
                ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={2} className="text-center fw-bold">
                  {t("shareholderTotal")}
                </th>
                <th className="text-center fw-bold">{t("noOfShareTotal")}</th>
                <th className="text-center fw-bold">{t("shareholdingsTotal")}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ShareholderListTable;
