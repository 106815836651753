import React from "react";
import "./listCard.css";

function ListCard2({ newTeam, banglatranslate }) {
  const { name, nameBn, designation, designationBn, imageURL } = newTeam;

  return (
    <div className="grid-card">
      <div className="director-img-div">
        <div className="director-img">
          <img src={imageURL} alt="img" />
        </div>
      </div>
      <div className="card-content">
        <h4>{banglatranslate === "bn" ? nameBn : name}</h4>
        <h6>{banglatranslate === "bn" ? designationBn : designation}</h6>
      </div>
    </div>
  );
}

export default ListCard2;
