import React, { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import EasyLifeDropdown from "./EasyLifeDropdown";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const EasyLifeMenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  const [banglatranslate, setBanglaTraslate] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setBanglaTraslate(currentLang);
  }, []);

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };
  return (
    <>
      <li
        className="nav-items-list"
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {items?.submenu?.length > 0 ? (
          <>
            {items?.isLink === false ? (
              <div className="nav-items-link" style={{ cursor: "pointer" }}>
                {(banglatranslate === "bn" && items.titleBn) || items.title}
                {depthLevel > 0 ? (
                  <span>
                    <MdKeyboardArrowDown className="nav-upIcon" />
                  </span>
                ) : (
                  <span>
                    <MdKeyboardArrowDown className="nav-upIcon" />
                  </span>
                )}
              </div>
            ) : (
              <NavLink
                to={items.link}
                aria-haspopup="menu"
                aria-expanded={dropdown ? "true" : "false"}
                onClick={() => setDropdown((prev) => !prev)}
                className={({ isActive }) =>
                  isActive ? `nav-items-link navActive` : `nav-items-link`
                }
              >
                {items.title}

                {depthLevel > 0 ? (
                  <span>
                    <MdKeyboardArrowDown className="nav-upIcon" />
                  </span>
                ) : (
                  <span>
                    <MdKeyboardArrowDown className="nav-upIcon" />
                  </span>
                )}
              </NavLink>
            )}

            <EasyLifeDropdown
              depthLevel={depthLevel}
              submenus={items?.submenu}
              dropdown={dropdown}
            />
          </>
        ) : (
          <>
            {items.title === "Claim" && items.title === "More" ? (
              <Link
                to={items.link}
                target={`${items?.target}`}
                className="nav-items-link"
              >
                {items.title}
              </Link>
            ) : (
              <NavLink
                to={items.link}
                target={`${items?.target}`}
                className={({ isActive }) =>
                  isActive ? `nav-items-link navActive` : `nav-items-link`
                }
              >
                {items.title}
              </NavLink>
            )}
          </>
        )}
      </li>
    </>
  );
};

export default EasyLifeMenuItems;
