import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const MediaContentGallery = ({ data }) => {
    // const navigate = useNavigate();

    // const handleDetails = (singleData, dataId) => {
    //     navigate(`/single-image`, { state: singleData });
    // };
    return (
        <div className='media-container-video media-container-shadow'>
            <Link to={`/single-image/${data?._id}`} className='border-0 bg-transparent media-photo-gallery'>
                <div className='media-gallery-photo'>
                <img src={data?.imageURL[0]} alt='photoGallery' />
                </div>
                <div className='media-gallery-overly'></div>
                <p className='media-gallery-title'>{data?.title?.length > 32 ? `${data?.title.slice(0, 32)}...` : `${data?.title}`}</p>
            </Link>
        </div>
    );
};

export default MediaContentGallery;