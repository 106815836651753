import React from 'react';
import newsImg1 from '../../../assets/media/news/news-1.jpg'
import newsImg2 from '../../../assets/media/news/news-2.jpg'
import newsImg3 from '../../../assets/media/news/news-3.jpg'
import newsImg4 from '../../../assets/media/news/news-4.jpg'
import newsImg5 from '../../../assets/media/news/news-5.jpg'
import newsImg6 from '../../../assets/media/news/news-6.jpg'
import MediaContentDetails from '../MediaContentDetails';
import ReactPaginate from 'react-paginate';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import Loading from '../../../components/Loading/Loading';


const MediaNews = ({t}) => {
    const menuTitle = `${t("news")}`;

    const [limit, setLimit] = useState(6);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [allNews, setAllNews] = useState([]);

    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }
    function handlePageClick(e) {
        console.log(e);
        currentPage.current = e.selected + 1;
        getPaginationList();
    }


    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/news?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllNews(data?.data?.newsList);
                setIsLoading(false);
            });
    }
    return (
        <div className="mt-5">
            <div>
                <h1 className="title title-service">{menuTitle}</h1>
                <div className="blog-content mt-4">
                    {allNews?.map((data) => (
                        <MediaContentDetails data={data} key={data._id} menuTitle={menuTitle} />
                    ))}
                </div>

                <div className='mt-4 d-flex justify-content-end'>
                    <ReactPaginate
                        breakLabel="......."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>
            </div>
        </div>
    );
};

export default MediaNews;