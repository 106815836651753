import React from "react";

function Users() {
  return (
    <div className="home-content min-vh-100 d-flex align-items-center justify-content-center ">
      <h1 className="plans-contents">Create Role and User</h1>
    </div>
  );
}

export default Users;
