import React from "react";
import { toast } from "react-hot-toast";
import { AiOutlineEye } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

function DashboardSubCatagoryPlansList({
  getPlans,
  setShowPlans,
  setEditPlans,
  handleViewShow,
  handleEditShow,
  getAllDataFetch,
}) {
  const {
    _id,
    subCategorytitle,
    subCategoryDescription,
    subCategoryImageURL,
    subCategoryVedioLink,
    plansCategory,
    subCategoryBenefit,
  } = getPlans;

  //   delete by id
  const handleDeleteAction = (id) => {
    const agree = window.confirm(
      `Are you sure, you want to delete: "${subCategorytitle}?"`
    );
    if (agree) {
      fetch(`${process.env.REACT_APP_host}/api/v1/sub-category-plans/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            getAllDataFetch();
            toast.success(`${subCategorytitle} is a Deleted`);
          }
        });
    }
  };

  return (
    <tr>
      <td className="text-center align-middle table-text fw-bold">
        {subCategorytitle}
      </td>
      <td className="text-center align-middle table-text">
        <div className="plans-img">
          <img src={subCategoryImageURL} />
        </div>
      </td>
      <td className="text-center align-middle table-text">
        {subCategoryVedioLink.length > 30
          ? `${subCategoryVedioLink.slice(0, 30)} .....`
          : subCategoryVedioLink}
      </td>
      <td className="text-center align-middle table-text">
        <span
          dangerouslySetInnerHTML={{
            __html:
              subCategoryDescription?.length > 120
                ? `${subCategoryDescription.slice(0, 120)} .....`
                : subCategoryDescription,
          }}
        ></span>
      </td>
      {/* <td className="text-center align-middle table-text">
        <span
          dangerouslySetInnerHTML={{
            __html:
              subCategoryBenefit?.length > 120
                ? `${subCategoryBenefit.slice(0, 120)} .....`
                : subCategoryBenefit,
          }}
        ></span>
      </td> */}
      <td className="text-center align-middle table-text">
        <div className="d-flex align-items-center justify-content-center gap-2">
          <button
            onClick={() => {
              handleViewShow();
              setShowPlans(getPlans);
            }}
            className="btn btn-sm btn-info text-white table-btn fw-semibold d-flex align-items-center "
          >
            <AiOutlineEye /> <span>Show</span>
          </button>
          <button
            onClick={() => {
              handleEditShow();
              setEditPlans(getPlans);
            }}
            className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center "
          >
            <BiEdit /> <span>Edit</span>
          </button>
          <button
            onClick={() => handleDeleteAction(_id)}
            className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center"
          >
            <MdDelete /> <span>Delete</span>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default DashboardSubCatagoryPlansList;
