import React from "react";
import ClaimTitleOverview from "./ClaimTitleOverview/ClaimTitleOverview";
import ClaimCashlessFacility from "./ClaimCashlessFacility/ClaimCashlessFacility";
import ClaimYourTrack from "./ClaimYourTrack/ClaimYourTrack";
import ClaimFAQ from "./ClaimFAQ/ClaimFAQ";
import ClaimForms from "./ClaimForms/ClaimForms";
import useTitle from "../../hooks/useTitle";
import Loading from "../../components/Loading/Loading";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";

function Claims() {
  const [metaTags, setMetaTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getPaginationList();
  }, [metaTags]);

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/seo?pages=Claims`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setMetaTags(data?.data?.seo);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }
  // useTitle("Claims");
  return (
    <div>
      {metaTags?.map((data) => (
        <Helmet>
          <title>{data?.title} - Guardian Life Insurance Ltd. </title>
          <meta name="description" content={data?.description} />
          <meta
            name="keywords"
            content={data?.keywords?.map((keys) => keys?.keywordsName)}
          />
        </Helmet>
      ))}
      <ClaimTitleOverview />
      <ClaimCashlessFacility />
      <ClaimYourTrack />
      <ClaimFAQ />
      <ClaimForms />
    </div>
  );
}

export default Claims;
