import React, { useEffect, useRef, useState } from 'react';
import './blog.css';
import Buttons from '../../../components/Buttons/Buttons';
import Blog1 from '../../../assets/blog/blog-1.jpg';
import Blog2 from '../../../assets/blog/blog-2.jpg';
import Blog3 from '../../../assets/blog/blog-3.jpg';
import { Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import BlogCard from '../../../components/BlogCard/BlogCard';
import Loading from '../../../components/Loading/Loading';
import MediaContentDetails from '../../MediaCorner/MediaContentDetails';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import MediaContentDetailsBlog from '../../MediaCorner/MediaContentDetailsBlog';

const blogData = [
    {
        id: 1,
        title: 'Business Strategy Make His Goal Acheive.',
        desc: `We currently support Facebook, Instagram,
     LinkedIn and Twitter. More to come. Felix is 
     purpose built for ease of use and complete 
     flexability.`,
        img: Blog1,
        link: '',
        date: 'December 05, 2021',
        time: '3',
    },
    {
        id: 2,
        title: 'Business Strategy Make His Goal Acheive.',
        desc: `We currently support Facebook, Instagram,
     LinkedIn and Twitter. More to come. Felix is 
     purpose built for ease of use and complete 
     flexability.`,
        img: Blog2,
        link: '',
        date: 'December 05, 2021',
        time: '3',
    },
    {
        id: 3,
        title: 'Business Strategy Make His Goal Acheive.',
        desc: `We currently support Facebook, Instagram,
     LinkedIn and Twitter. More to come. Felix is 
     purpose built for ease of use and complete 
     flexability.`,
        img: Blog3,
        link: '',
        date: 'December 05, 2021',
        time: '3',
    },
];

const menuTitle = 'Blog';

function Blog() {
    const [limit, setLimit] = useState(3);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [allBlog, setAllBlog] = useState([]);

    const currentPage = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }
    function handlePageClick(e) {
        console.log(e);
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/blog?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllBlog(data?.data?.blogsList);
                setIsLoading(false);
            });
    }

    return (
        <div className="blog-contianer">
            <div className="body-container secondary-container">
                <h1 className="title blog-title">{t('bolgTitle')}</h1>
                <p className="text-center text  blog-text">
                    {t('bolgTitleDesc')}
                </p>
                <div className="d-flex justify-content-center mt-2">
                    <Link to="/media-corner">
                        <Buttons classes="btn-learn">{t('seemore')}</Buttons>
                    </Link>
                </div>

                <div className="blog-content">
                    {allBlog?.map((data) => (
                        <MediaContentDetailsBlog
                            data={data}
                            key={data._id}
                            menuTitle={menuTitle}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Blog;
