import React from "react";
import { Modal } from "react-bootstrap";

function DashboardViewJobPostModal({ viewShow, handleViewClose, viewJobPost }) {
  //   const { description } = viewJobPost;
  return (
    <Modal
      show={viewShow}
      onHide={handleViewClose}
      scrollable={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          Job Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <div className="d-lg-flex alin justify-content-between align-items-center gap-2">
          <p>
            <strong>Position Name: </strong>
            <span>{viewJobPost?.positionName}</span>
          </p>
          <p>
            <strong>Address: </strong>
            <span>{viewJobPost?.address}</span>
          </p>
          <p>
            <strong>Job Type: </strong>
            <span>{viewJobPost?.jobType}</span>
          </p>
        </div>
        <div className="mt-4">
          <span
            dangerouslySetInnerHTML={{
              __html: `${viewJobPost?.description}`,
            }}
          ></span>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DashboardViewJobPostModal;
