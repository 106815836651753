import React from "react";
import MediaCornerBanner from "../MediaCornerBanner/MediaCornerBanner";
import MediaCornerSubmenu from "../MediaCornerSubmenu/MediaCornerSubmenu";
import useTitle from "../../../hooks/useTitle";

function MediaCorner() {
  useTitle("Media Corner");
  return (
    <div>
      <MediaCornerBanner />
      <MediaCornerSubmenu />
    </div>
  );
}

export default MediaCorner;
