import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import JoditEditor from 'jodit-react';

function DashboardBancassuranceBenfitsAdd({
    addShowModal,
    handleAddClose,
    getPaginationList,
}) {
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [contentBn, setContentBn] = useState('');
    const [addBenfits, setAddBenfits] = useState({
        title: '',
        titleBn: '',
        image: '',
    });

    const handleChange = (e) => {
        setAddBenfits({ ...addBenfits, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setAddBenfits({ ...addBenfits, image: e.target.files[0] });
    };

    // post
    const handleAddBenfits = async (e) => {
        e.preventDefault();
        handleAddClose();

        const formData = new FormData();

        formData.append('title', addBenfits?.title);
        formData.append('titleBn', addBenfits?.titleBn);
        formData.append('description', content);
        formData.append('descriptionBn', contentBn);
        formData.append('image', addBenfits?.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for (const value of formData.values()) {
            console.log(value);
        }

        await axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/bancassurance-benfits`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    toast.success('Benfits added successfully');
                    setAddBenfits('');
                    setContent('');
                    setContentBn('');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not added successfully');
            });
    };
    return (
        <Modal
            show={addShowModal}
            onHide={handleAddClose}
            aria-labelledby="contained-modal-title-vcenter"
            size="lg"
            centered
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Add Benfits
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleAddBenfits}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Title <sup className="text-danger">*</sup>
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addBenfits.title}
                            name="title"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Title(Bangla) <sup className="text-danger">*</sup>
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addBenfits.titleBn}
                            name="titleBn"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Image <sup className="text-danger">*</sup>
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            onChange={handleImage}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={content}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description(Bangla)
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={contentBn}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardBancassuranceBenfitsAdd;
