import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BgOurPlanImg from "../../assets/BG/bgRight.png";
import Image1 from "../../assets/image/corporate-life-coverage.png";

const BgStyle = {
  backgroundImage: `url(${BgOurPlanImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",
};

const CorporateLifeCoverage = () => {
  const [banglatranslate, setBanglaTraslate] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setBanglaTraslate(currentLang);
  }, []);

  const ourPlansDataEn = [
    // {
    //     id: 0,
    //     title: `${t("corporateLifeSubmenuTitle1")}`,
    //     img: Image1,
    //     link: "",
    //     des: `${t("corporateLifeSubmenuDetails1")}`,
    // },
    {
      id: 0,
      title: `Death Benefits (Natural Death)`,
      img: Image1,
      link: "",
      des: `If an insured member dies, Guardian Life Insurance upon receipt of written proof of such death, will pay the organization/Nominee of Employee the sum insured in respect of the said insured member.`,
    },
    {
      id: 1,
      title: `Accidental Death (ADB)`,
      img: Image1,
      link: "",
      des: `In the event of an insured member's direct death resulting from an accident caused by external and violent, the accidental death benefit will provide the employer (organization) with the sum insured, in addition to the death benefit.`,
    },
    {
      id: 2,
      title: `Permanent and Total Disability (PTD)`,
      img: Image1,
      link: "",
      des: `The coverage for Permanent and Total Disability will provide the organization with the insured employee's sum insured if the employee becomes permanently and totally disabled due to an accident resulting in bodily injury.`,
    },
    {
      id: 3,
      title: `Permanent and partial disability (PPD)`,
      img: Image1,
      link: "",
      des: `In the event of an accident that results in permanent partial disability for an insured employee, the specified benefit will be paid to the employer/employee according to the Labor Law of Bangladesh, the employer/employee shall be provided with fixed financial benefits in case of permanent partial disability due to accident.`,
    },
    {
      id: 4,
      title: `Critical Illness Benefit`,
      img: Image1,
      link: "",
      des: `The Critical Illness Scheme is a specialized supplementary plan created for employees and their families, aiming to offer financial assistance and protection if a member is diagnosed with one of the specified diseases. However, it applies to the following 18 critical illnesses: 1.Cancer, 2.Heart Attack (Myocardial Infarction), 3.Stroke, 4.Coronary Artery (Bypass) Surgery, 5.Kidney Failure (End Stage Renal Disease), 6.Major Organ Transplantation, 7.Paralysis, 8.Multiple Sclerosis, 9.Loss of Limbs, 10.Blindness/Loss of Sight, 11.Heart Valve Replacement, 12.Surgery of Aorta, 13.Aplastic Anemia, 14.Benign Brain Tumor, 15.Chronic Lung Disease/End Stage Lung Disease, 16.Deafness/Loss of Hearing, 17.Major Head Trauma, 18. Loss of Speech.`,
    },
  ];

  const ourPlansDataBn = [
    {
      id: 0,
      title: `সাধারণ মৃত্যুকালীন সুবিধা`,
      img: Image1,
      link: "",
      des: `বীমাকৃত সদস্যের মৃত্যু হলে লিখিত প্রমাণের ভিত্তিতে উক্ত প্রতিষ্ঠান বা মনোনীত ব্যক্তিকে বীমাকৃত অংক প্রদান করা হয়।`,
    },
    {
      id: 1,
      title: `দুর্ঘটনাজনিত মৃত্যুতে সুবিধা`,
      img: Image1,
      link: "",
      des: `কোন দুর্ঘটনায় বীমাকৃত সদস্যের মৃত্যু হলে নিয়োগকর্তাকে নির্ধারিত বীমা অংক এবং অতিরিক্ত মৃত্যু সুবিধা প্রদান করা হয়।`,
    },
    {
      id: 2,
      title: `চিরস্থায়ী পূর্ণাঙ্গ অক্ষমতা`,
      img: Image1,
      link: "",
      des: `দুর্ঘটনার কারণে অঙ্গহানি হলে নিয়োগকর্তাকে নির্ধারিত বীমা অংক প্রদান করা হবে।`,
    },
    {
      id: 3,
      title: `চিরস্থায়ী আংশিক অক্ষমতা`,
      img: Image1,
      link: "",
      des: `দুর্ঘটনার কারণে আংশিক অক্ষমতা হলে নিয়োগকর্তা বা কর্মচারীকে বাংলাদেশ শ্রম আইন অনুযায়ী নির্ধারিত সুবিধা প্রদান করা হবে এবং স্থায়ী আর্থিক সুবিধা দেয়া হবে।`,
    },
    {
      id: 4,
      title: `গুরুতর অসুস্থতা সুবিধা`,
      img: Image1,
      link: "",
      des: `কোন বীমাকৃত সদস্য নির্ধারিত রোগে আক্রান্ত হলে তার বা তার পরিবারের সদস্যদের আর্থিক সহায়তা প্রদানের জন্য এটি একটি সহযোগী বীমা প্ল্যান। এটি নিম্নলিখিত ১৮ টি রোগের ক্ষেত্রে প্রযোজ্যঃ ১. ক্যান্সার ২. হার্ট অ্যাটাক ৩. বাইপাস সার্জারি ৪. স্ট্রোক ৫. কিডনি বিকল হয়ে যাওয়া ৬. শরীরের গুরুত্বপূর্ণ অঙ্গ প্রতিস্থাপন ৭. বিকালঙ্গতা ৮. শরীরের কোষ অস্বাভাবিক শক্ত হয়ে যাওয়া (স্ক্লেরোসিস) ৯. অঙ্গহানি ১০. অন্ধত্ব ১১. হার্টের ভাল্ভ প্রতিস্থাপন ১২. মহাধমনির অপারেশন ১৩. অবর্ধক রক্তশূন্যতা ১৪. অপ্রসারক ব্রেইন টিউমার ১৫. চলমান/শেষ ধাপের ফুসফুসের রোগ ১৬. শ্রবণশক্তি হারিয়ে যাওয়া ১৭. গুরুতর মাথার আঘাত ১৮. কথা বলার শক্তি হারিয়ে যাওয়া ।`,
    },
  ];

  const ourPlansData = banglatranslate === "bn" ? ourPlansDataBn : ourPlansDataEn;
  const [ourData, setOurData] = useState(ourPlansDataEn[0]);
  const [ourDataBn, setOurDataBn] = useState(ourPlansDataBn[0]);
  const [selected, setSelected] = useState(ourPlansDataEn[0]?.id);
  const [selectedBn, setSelectedBn] = useState(ourPlansDataBn[0]?.id);

  const handleClick = (idx, data) => {
    setOurData(data);
    setOurDataBn(data);
    setSelected(idx);
    setSelectedBn(idx);
  };

  return (
    <div>
      <div className="pb-0 ourPlans-container">
        <h1 className="title title-service">{`${t("corporateLifeTitle")}`}</h1>
        <h6 className="text-center banner-text mt-3 w-75 mx-auto">{`${t("corporateLifeDetails")}`}</h6>
        <div className="corporate-content">
          <div className="slider-container">
            <div className="ourPlans-left">
              <div className="vl"></div>
              {ourPlansData.map((data, i) => (
                <div className="ourPlans-contnet-text" key={i} onClick={() => handleClick(data?.id, data)}>
                  {banglatranslate === "bn" ? (
                    <div
                      className="cicrle"
                      style={{
                        backgroundColor: selectedBn === data?.id ? "#232176" : "",
                      }}
                    ></div>
                  ) : (
                    <div
                      className="cicrle"
                      style={{
                        backgroundColor: selected === data?.id ? "#232176" : "",
                      }}
                    ></div>
                  )}
                  {banglatranslate === "bn" ? (
                    <div
                      className="plans-text"
                      style={{
                        color: selectedBn === data?.id ? "#232176" : "",
                      }}
                    >
                      {data.title.split(" ").length === 2 ? (
                        data.title
                      ) : (
                        <div>
                          {data.title.split(" ").slice(0, 2).join(" ")} <br></br> {data.title.split(" ").slice(2, data.title.split(" ").length).join(" ")}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className="plans-text"
                      style={{
                        color: selected === data?.id ? "#232176" : "",
                      }}
                    >
                      {data.title.split(" ").length === 2 ? (
                        data.title
                      ) : (
                        <div>
                          {data.title.split(" ").slice(0, 2).join(" ")} <br></br> {data.title.split(" ").slice(2, data.title.split(" ").length).join(" ")}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="ourPlans-right">
              {banglatranslate === "bn" ? (
                <>
                  <div className="ourPlans-content-left">
                    <h4> {ourDataBn.title} </h4>
                    <p className="text banner-text">{ourDataBn.des}</p>
                  </div>
                  <div className="ourPlans-img-right">
                    <img src={ourDataBn.img} />
                  </div>
                </>
              ) : (
                <>
                  <div className="ourPlans-content-left">
                    <h4> {ourData.title} </h4>
                    <p className="text banner-text">{ourData.des}</p>
                  </div>
                  <div className="ourPlans-img-right">
                    <img src={ourData.img} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateLifeCoverage;
