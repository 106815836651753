import React, { useEffect, useRef, useState } from 'react';
import Loading from '../../../../components/Loading/Loading';
import { IoMdAddCircle } from 'react-icons/io';
import ReactPaginate from 'react-paginate';
import DashboardBancassuranceBenfitsTable from './DashboardBancassuranceBenfitsTable';
import DashboardBancassuranceBenfitsAdd from './DashboardBancassuranceBenfitsAdd';
import DashboardBancassuranceBenfitsView from './DashboardBancassuranceBenfitsView';
import DashboardBancassuranceBenfitsEdit from './DashboardBancassuranceBenfitsEdit';

function DashboardBancassuranceBenfits() {
    const [newAllService, setAllService] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [editModal, setEditModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [updateBenfits, setUpdateBenfits] = useState(null);
    const [viewBenfits, setViewBenfits] = useState(null);

    const [addShowModal, setAddShowModal] = useState(false);

    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    const handleAddClose = () => setAddShowModal(false);
    const handleAddShow = () => setAddShowModal(true);
    const handleEditClose = () => setEditModal(false);
    const handleEditShow = () => setEditModal(true);

    const handleViewClose = () => setViewModal(false);
    const handleViewShow = () => setViewModal(true);

    // pagination
    function handlePageClick(e) {
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/bancassurance-benfits?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllService(data?.data?.bancassuranceBenefitsList);
                setIsLoading(false);
            });
    }

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }
    return (
        <div className="home-content">
            <div class="d-flex justify-content-between gap-2 align-items-center">
                <h1 className="dashboard-title">All Bancassurance Benefit</h1>
                <div>
                    <button
                        type="button"
                        onClick={handleAddShow}
                        className="btn btn-sm btn-primary d-flex align-items-center fw-semibold"
                    >
                        <IoMdAddCircle className="add-icon" />{' '}
                        <span>Add Benefit</span>
                    </button>
                </div>
            </div>

            <div className="list-contaner">
                {newAllService?.length === 0 ? (
                    <div className="min-vh-100 d-flex align-items-center justify-content-center">
                        No Service Is Available
                    </div>
                ) : (
                    <>
                        <div className="table-responsive">
                            <table className="table table-light table-bordered">
                                <thead>
                                    <tr className="table-tr">
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Serial No.
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Photo
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Title
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Description
                                        </th>

                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newAllService.map((newBenfits, i) => (
                                        <DashboardBancassuranceBenfitsTable
                                            key={newBenfits?._id}
                                            newBenfits={newBenfits}
                                            idx={i}
                                            handleEditShow={handleEditShow}
                                            handleViewShow={handleViewShow}
                                            setUpdateBenfits={setUpdateBenfits}
                                            setViewBenfits={setViewBenfits}
                                            getPaginationList={
                                                getPaginationList
                                            }
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}

                <div className="mt-4">
                    <ReactPaginate
                        breakLabel="......."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>

                {/*

  <DashboardBlogViewModal
    viewShow={viewShow}
    handleViewClose={handleViewClose}
    viewService={viewService}
  /> */}
            </div>

            <DashboardBancassuranceBenfitsAdd
                addShowModal={addShowModal}
                handleAddClose={handleAddClose}
                getPaginationList={getPaginationList}
            />
            <DashboardBancassuranceBenfitsView
                handleViewClose={handleViewClose}
                viewBenfits={viewBenfits}
                viewModal={viewModal}
            />
            <DashboardBancassuranceBenfitsEdit
                editModal={editModal}
                handleEditClose={handleEditClose}
                updateBenfits={updateBenfits}
                setUpdateBenfits={setUpdateBenfits}
                getPaginationList={getPaginationList}
            />
        </div>
    );
}

export default DashboardBancassuranceBenfits;
