import React, { useState } from "react";
import BgBannerImg from "../../assets/BG/bgLeft.png";
import BannerImg from "../../assets/easylife/banner/cancer-plans.jpg";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const BgStyle = {
  backgroundImage: `url(${BgBannerImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};

function CancerCareCenterBanner() {
  const [isShowMore, setShowMore] = useState(false);
  const [banglatranslate, setBanglaTraslate] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setBanglaTraslate(currentLang);
  }, []);

  const handleShow = () => {
    setShowMore(!isShowMore);
  };

  const bannerItems = [
    {
      id: 1,
      title: `${t("cancerplansTitle")}`,
      desc: `${t("cancerPlansDesc")}`,
      img: BannerImg,
    },
  ];
  return (
    <div style={BgStyle}>
      <div className="body-container secondary-container banner-container">
        {bannerItems.map((bannerItem) => (
          <div key={bannerItem.id} className="banner-content">
            <div className="left-content">
              <h1 className="title banner-title">{bannerItem?.title}</h1>
              <p className="text banner-text">
                <span
                  dangerouslySetInnerHTML={{
                    __html: !isShowMore
                      ? bannerItem?.desc.slice(0, 300) + "..."
                      : bannerItem?.desc,
                  }}
                ></span>
              </p>
              <div className="d-md-flex align-items-center gap-3 mt-4">
                <Button className="btn-book btns" onClick={handleShow}>
                  {isShowMore ? `${t("readless")}` : `${t("readmore")}`}
                </Button>
                <Link to={banglatranslate === "bn" ? `https://drive.google.com/file/d/16kccI_6qggERCVyQG0E4wDwiKTXLBP4L/view` : `https://drive.google.com/file/d/11dV2HOFQfRTRu_KKDDMf9mCzT0DEqDyq/view`} target="_blank" className="btn-learn btns bg-transparent btn btn-primary" style={{ width: '40%' }}>{t("cancerPlanDownload")}</Link>
              </div>
            </div>
            <div className="right-img">
              <img src={bannerItem?.img} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CancerCareCenterBanner;
