import React from 'react';
import { Modal } from 'react-bootstrap';

function DashboardBancassuranceAddService({
    addShowModal,
    handleAddClose,
    handleAddService,
    handleChange,
    handleImage,
    newService,
}) {
    return (
        <Modal
            show={addShowModal}
            onHide={handleAddClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Add Service
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleAddService} className="row">
                    <div className="mb-3 col-12">
                        <label className="form-label">Title</label>
                        <input
                            type="text"
                            name="title"
                            onChange={handleChange}
                            value={newService?.title}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Title (Bangla)</label>
                        <input
                            type="text"
                            name="titleBn"
                            onChange={handleChange}
                            value={newService?.titleBn}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3 col-12">
                        <label className="form-label">Image</label>
                        <input
                            type="file"
                            onChange={handleImage}
                            name="image"
                            className="form-control"
                        />
                    </div>

                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardBancassuranceAddService;
