/* eslint-disable react/style-prop-object */
import React from "react";
import { Modal } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { Link } from "react-router-dom";

function DigitalLoyaltyViewModal({ viewDigitalLoyalty, handleViewClose, viewShow }) {
  return (
    <Modal show={viewShow} onHide={handleViewClose} scrollable={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title className="book-title ps-2" id="contained-modal-title-vcenter">
          {viewDigitalLoyalty?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <div>
          <p className="mb-3">
            <span className="fw-bold">Offer: </span>
            <span>{viewDigitalLoyalty?.offer}</span>
          </p>
          <p className="mb-3">
            <span className="fw-bold">Validity: </span>
            <span>{viewDigitalLoyalty?.validity}</span>
          </p>
          <p className="mb-3">
            <span className="fw-bold"> Eligibility: </span>
            <span>{viewDigitalLoyalty?.eligib}</span>
          </p>
          <p className="mb-3">
            <span className="fw-bold"> Offer Details: </span>
          </p>
          <div className="mb-3">
            {/* {viewDigitalLoyalty?.offerDetails?.map((offers, i) => (
              <p className="mb-2">
                <span className="fw-bold">{i + 1}: </span>
                <span>{offers}</span>
              </p>
            ))} */}

            <span
              dangerouslySetInnerHTML={{
                __html: viewDigitalLoyalty?.offerDetails,
              }}
            ></span>
          </div>
          <p className="mb-3">
            <span className="fw-bold"> Contact Number: </span>
            <span>{viewDigitalLoyalty?.contactNuber}</span>
          </p>
          {viewDigitalLoyalty?.expiresDate && (
            <p className="mb-3">
              <span className="fw-bold">Expires Date :</span>
              <span> {viewDigitalLoyalty?.expiresDate}</span>
            </p>
          )}
          <div className="d-flex gap-2">
            {viewDigitalLoyalty?.website && (
              <p className="mb-3">
                {/* <span className="fw-bold"> Website: </span> */}
                <Link className="link-warning" to={viewDigitalLoyalty?.website} target="_blank">
                  <TbWorld size={24} />
                </Link>
              </p>
            )}
            {viewDigitalLoyalty?.youtubeLink && (
              <p className="mb-3">
                {/* <span className="fw-bold"> Youtube Link: </span> */}
                <Link className="link-warning" to={viewDigitalLoyalty?.youtubeLink} target="_blank">
                  <FaYoutube size={24} />
                </Link>
              </p>
            )}
            {viewDigitalLoyalty?.facebookLink && (
              <p className="mb-3">
                {/* <span className="fw-bold"> Facebook Link: </span> */}
                <Link className="link-warning" to={viewDigitalLoyalty?.facebookLink} target="_blank">
                  <FaFacebook size={24} />
                </Link>
              </p>
            )}
            {viewDigitalLoyalty?.instagramLink && (
              <p className="mb-3">
                {/* <span className="fw-bold"> Instagram Link: </span> */}
                <Link className="link-warning" to={viewDigitalLoyalty?.instagramLink} target="_blank">
                  <FaInstagram size={24} />
                </Link>
              </p>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DigitalLoyaltyViewModal;
