import React from 'react';
import BgBannerImg from "../../../assets/BG/bgLeft.png";
import BannerImg from "../../../assets/image/banner.png";
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";


const BgStyle = {
    backgroundImage: `url(${BgBannerImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
};



const PartnerDetailsBanner = ({ data }) => {
    const [banglatranslate, setBanglaTraslate] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    return (
        <div style={BgStyle}>
            <div className="body-container secondary-container banner-container pb-5">
                <div className="banner-content">
                    <div className="left-content">
                        <h1 className="title banner-title">{banglatranslate === 'bn' ? data?.partnerNameBn : data?.partnerName}</h1>
                        <div className="text banner-text">
                            {banglatranslate === "bn" ? (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            data?.partnerDescBn
                                    }}
                                ></span>
                            ) : (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            data?.partnerDesc
                                    }}
                                ></span>
                            )}
                        </div>
                    </div>
                    <div className="right-adc-img">
                        <img src={data?.partnerLogo} alt="" className='rounded-0' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnerDetailsBanner;