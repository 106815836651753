import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import JoditEditor from 'jodit-react';
import { useRef } from 'react';
import { useState } from 'react';

const DashboardBlogEditModal = ({
    editModal,
    handleEditClose,
    updateBlog,
    setUpdateBlog,
    getPaginationList,
}) => {
    const editor = useRef(null);
    const [content, setContent] = useState(editModal?.description);
    const [contentBn, setContentBn] = useState(editModal?.descriptionBn);
    const handleEditChange = (e) => {
        setUpdateBlog({ ...updateBlog, [e.target.name]: e.target.value });
    };

    const handleEditImage = (e) => {
        setUpdateBlog({ ...updateBlog, image: e.target.files[0] });
    };

    const handleUpdateAction = async (e) => {
        e.preventDefault();
        handleEditClose();

        const formData = new FormData();

        formData.append('title', updateBlog?.title);
        formData.append('titleBn', updateBlog?.titleBn);
        formData.append('description', content);
        formData.append('descriptionBn', contentBn);
        formData.append('image', updateBlog?.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for (const value of formData.values()) {
            console.log(value);
        }

        await axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/blog/${updateBlog?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    toast.success('Blog updated successfully!');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not updated!');
            });
    };

    return (
        <Modal
            show={editModal}
            onHide={handleEditClose}
            scrollable={true}
            //   size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Edit Blog
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction} className="row">
                    <div className="mb-3 col-12">
                        <label className="form-label">Title</label>
                        <input
                            type="text"
                            name="title"
                            onChange={handleEditChange}
                            defaultValue={updateBlog?.title}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Title(Bangla)</label>
                        <input
                            type="text"
                            name="titleBn"
                            onChange={handleEditChange}
                            defaultValue={updateBlog?.titleBn}
                            className="form-control"
                        />
                    </div>

                    {/* <div className="mb-3 col-12">
            <label className="form-label">Description</label>
            <textarea
              rows={8}
              name="description"
              onChange={handleEditChange}
              className="form-control"
              required
            >
              {updateBlog?.description}
            </textarea>
          </div>
          <div className="mb-3 col-12">
            <label className="form-label">Description(Bangla)</label>
            <textarea
              rows={8}
              name="descriptionBn"
              onChange={handleEditChange}
              className="form-control"
            >
              {updateBlog?.descriptionBn}
            </textarea>
          </div> */}
                    <div className="mb-5 col-12">
                        <label className="form-label">Blog Image</label>
                        <input
                            type="file"
                            onChange={handleEditImage}
                            name="image"
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={updateBlog?.description}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description(Bangla)
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={updateBlog?.descriptionBn}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default DashboardBlogEditModal;
