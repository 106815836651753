import React, { useEffect } from "react";
import "./login.css";
import Img from "../../assets/logo/logo-1.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-hot-toast";
import useToken from "../../hooks/useToken";
import useTitle from "../../hooks/useTitle";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  // const [loginUserEmail, setLoginUserEmail] = useState("");
  const [token, setToken] = useState("");
  // const [token] = useToken(loginUserEmail);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  useTitle("Login");

  useEffect(() => {
    if (token) {
      navigate(from, { replace: true });
    }
  }, [token, from, navigate]);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const form = e.target;

    const email = form.email.value;
    const password = form.password.value;

    const user = {
      email,
      password,
    };
    // console.log(user);

    fetch(`${process.env.REACT_APP_host}/api/v1/user/login`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          const jwtToken = data?.data?.token;
          if (jwtToken) {
            // console.log(data?.data);
            localStorage.setItem("accessToken", jwtToken);
            setToken(jwtToken);
            // setLoginUserEmail(user);
            form.reset();
            toast.success("Login successfully!");
          } else {
            toast.error(data?.error);
            console.log(data?.error);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("User not Login!!");
      });
  };

  return (
    <div className="login-container">
      <div className="background">
        <div className="shape"></div>
        <div className="shape"></div>
      </div>
      <form onSubmit={handleLogin} className="login-form">
        <div className="login-img">
          <Link to="/home">
            <img src={Img} />
          </Link>
        </div>
        <h3>Login</h3>

        <label className="login-label" htmlFor="username">
          Email
        </label>
        <input
          className="login-input"
          type="text"
          placeholder="Email"
          name="email"
          id="username"
          required
        />

        <label className="login-label" htmlFor="password">
          Password
        </label>
        <div className="position-relative">
          <input
            className="login-input pe-5"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            name="password"
            id="password"
            required
          />
          {showPassword ? (
            <>
              <AiFillEye
                onClick={togglePassword}
                className="password-show"
              ></AiFillEye>
            </>
          ) : (
            <>
              <AiFillEyeInvisible
                onClick={(e) => setShowPassword(e)}
                className="password-show "
              ></AiFillEyeInvisible>
            </>
          )}
        </div>

        <button type="submit" className="login-btn">
          Log In
        </button>
        {/* <div class="social">
          <div class="go">
            <i class="fab fa-google"></i> Google
          </div>
          <div class="fb">
            <i class="fab fa-facebook"></i> Facebook
          </div>
        </div> */}
      </form>
    </div>
  );
}

export default Login;
