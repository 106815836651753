import {
    AiOutlineGift,
    AiOutlineOrderedList,
    AiOutlineUnorderedList,
} from 'react-icons/ai';
import {
    FaArrowAltCircleRight,
    FaDonate,
    FaDotCircle,
    FaStickyNote,
} from 'react-icons/fa';

import {
    BiCategoryAlt,
    BiGridAlt,
    BiLabel,
    BiMessageAltDetail,
} from 'react-icons/bi';
import {
    BsBoxSeam,
    BsCameraVideo,
    BsDatabaseGear,
    BsPeople,
    BsSearch,
} from 'react-icons/bs';
import { DiHtml5Multimedia } from 'react-icons/di';
import {
    FaBlogger,
    FaCriticalRole,
    FaPeopleCarry,
    FaPhotoVideo,
    FaQuestionCircle,
    FaRegHandshake,
    FaRegHospital,
    FaRegNewspaper,
    FaWpforms,
} from 'react-icons/fa';
import { GiCircleForest, GiLifeInTheBalance } from 'react-icons/gi';
import { GoFileMedia } from 'react-icons/go';
import { GrUserSettings } from 'react-icons/gr';
import { HiOutlineNewspaper, HiOutlineUsers } from 'react-icons/hi';
import { IoLogoBuffer, IoMdLocate } from 'react-icons/io';
import {
    MdCategory,
    MdEmojiPeople,
    MdKeyboardArrowDown,
    MdKeyboardArrowRight,
    MdLocalOffer,
    MdOutlineCategory,
    MdOutlineNaturePeople,
    MdOutlinePeopleAlt,
    MdPreview,
} from 'react-icons/md';
import { RiProductHuntFill, RiUserSharedLine } from 'react-icons/ri';
import { SiFormstack, SiGoogleadsense } from 'react-icons/si';
import { TbStackPop, TbTimelineEvent } from 'react-icons/tb';

export const dashboardNavName = [
    {
        id: 1,
        link: '/dashboard/admin-dashboard',
        icon: <BiGridAlt />,
        title: 'Dashboard',
    },
    {
        id: 2,
        link: '/dashboard/user',
        icon: <RiUserSharedLine />,
        title: 'User and Role',
        iconClosed: <MdKeyboardArrowRight className="text-white fs-5" />,
        iconOpened: <MdKeyboardArrowDown className="text-white fs-5" />,
        subNav: [
            {
                title: 'Create Role',
                link: '/dashboard/user/role',
                icon: <FaCriticalRole />,
            },
            {
                title: 'Create User',
                link: '/dashboard/user/create',
                icon: <HiOutlineUsers />,
            },
        ],
    },
    {
        id: 3,
        link: '/dashboard/plans',
        icon: <BsBoxSeam />,
        title: 'Plans',
        iconClosed: <MdKeyboardArrowRight className="text-white fs-5" />,
        iconOpened: <MdKeyboardArrowDown className="text-white fs-5" />,
        subNav: [
            {
                title: 'Add Plans',
                link: '/dashboard/plans/add-plans',
                icon: <BiLabel />,
            },
            {
                title: 'Category Plans',
                link: '/dashboard/plans/category-plans',
                icon: <BiCategoryAlt />,
            },
            // {
            //   title: " Sub Category Plans",
            //   link: "/dashboard/plans/sub-category-plans",
            //   icon: <MdOutlineCategory />,
            // },
        ],
    },
    {
        id: 4,
        link: '/dashboard/staff-list',
        icon: <AiOutlineUnorderedList />,
        title: 'Staff List',
    },
    {
        id: 5,
        link: '/dashboard/agent-list',
        icon: <AiOutlineOrderedList />,
        title: 'Agent List',
    },
    {
        id: 6,
        link: '/dashboard/management-team',
        icon: <BsPeople />,
        title: 'Management Team',
    },
    {
        id: 7,
        link: '/dashboard/extended-management-committee',
        icon: <BsPeople />,
        title: 'Extended Management',
    },
    {
        id: 8,
        link: '/dashboard/board-director',
        icon: <MdOutlinePeopleAlt />,
        title: 'Board Of Director',
    },
    {
        id: 9,
        link: '/dashboard/milestones',
        icon: <TbTimelineEvent />,
        title: 'Milestones',
    },
    {
        id: 10,
        link: '/dashboard/booking-list',
        icon: <GiCircleForest />,
        title: 'All Booking',
    },
    {
        id: 11,
        link: '/dashboard/customer-message',
        icon: <BiMessageAltDetail />,
        title: 'All Message',
    },
    {
        id: 12,
        link: '/dashboard/preferred-hospital',
        icon: <FaRegHospital />,
        title: 'Preferred Hospital',
    },
    {
        id: 13,
        link: '/dashboard/locate-branch',
        icon: <IoMdLocate />,
        title: 'Locate Branch',
    },
    {
        id: 14,
        link: '/dashboard/claims',
        icon: <FaWpforms />,
        title: 'Claims Form',
    },
    {
        id: 15,
        link: '/dashboard/form-library',
        icon: <SiFormstack />,
        title: 'Form Library',
    },
    {
        id: 16,
        link: '/dashboard/notice',
        icon: <FaStickyNote />,
        title: 'Notice Board',
    },
    {
        id: 17,
        link: '/dashboard/pop-up',
        icon: <TbStackPop />,
        title: 'Pop Up',
    },
    {
        id: 18,
        link: '/dashboard/partner-logo',
        icon: <IoLogoBuffer />,
        title: 'Partner Logo',
    },
    {
        id: 19,
        link: '/dashboard/faq',
        icon: <FaQuestionCircle />,
        title: 'FAQ',
    },
    // {
    //   id: 19,
    //   link: "/dashboard/digital-offer",
    //   icon: <AiOutlineGift />,
    //   title: "Digital Offer",
    // },
    {
        id: 20,
        link: '/dashboard/digital-offer',
        icon: <AiOutlineGift />,
        title: 'Loyalty Offer',
        iconClosed: <MdKeyboardArrowRight className="text-white fs-5" />,
        iconOpened: <MdKeyboardArrowDown className="text-white fs-5" />,
        subNav: [
            {
                title: 'Add Category',
                link: '/dashboard/digital-offer/add-category',
                icon: <MdCategory />,
            },
            {
                title: 'Add Loyalty Offer',
                link: '/dashboard/digital-offer/add-loyalty',
                icon: <MdLocalOffer />,
            },
        ],
    },

    {
        id: 21,
        link: '/dashboard/media',
        icon: <GoFileMedia />,
        title: 'Media Corner',
        iconClosed: <MdKeyboardArrowRight className="text-white fs-5" />,
        iconOpened: <MdKeyboardArrowDown className="text-white fs-5" />,
        subNav: [
            {
                title: 'Blog',
                link: '/dashboard/media/blog',
                icon: <FaBlogger />,
            },
            {
                title: 'News',
                link: '/dashboard/media/news',
                icon: <FaRegNewspaper />,
            },
            {
                title: 'Press',
                link: '/dashboard/media/press',
                icon: <HiOutlineNewspaper />,
            },
            {
                title: 'Video',
                link: '/dashboard/media/video',
                icon: <BsCameraVideo />,
            },
            {
                title: 'Photo',
                link: '/dashboard/media/photo',
                icon: <FaPhotoVideo />,
            },
            // {
            //     title: 'Review',
            //     link: '/dashboard/media/review',
            //     icon: <MdPreview />,
            // },
        ],
    },
    {
        id: 22,
        link: '/dashboard/jobs',
        icon: <MdOutlineNaturePeople />,
        title: 'Jobs',
        iconClosed: <MdKeyboardArrowRight className="text-white fs-5" />,
        iconOpened: <MdKeyboardArrowDown className="text-white fs-5" />,
        subNav: [
            {
                title: 'Post A Job',
                link: '/dashboard/jobs/post-jobs',
                icon: <FaPeopleCarry />,
            },
            {
                title: 'Submitted Applied',
                link: '/dashboard/jobs/applied',
                icon: <MdEmojiPeople />,
            },
        ],
    },
    {
        id: 23,
        link: '/dashboard/easylife',
        icon: <GiLifeInTheBalance />,
        title: 'EasyLife',
        iconClosed: <MdKeyboardArrowRight className="text-white fs-5" />,
        iconOpened: <MdKeyboardArrowDown className="text-white fs-5" />,
        subNav: [
            {
                title: 'Products',
                link: '/dashboard/easylife/products',
                icon: <RiProductHuntFill />,
            },
            {
                title: 'Media',
                link: '/dashboard/easylife/media',
                icon: <DiHtml5Multimedia />,
            },
        ],
    },
    {
        id: 24,
        link: '/dashboard/partner-channel',
        icon: <FaRegHandshake />,
        title: 'Partner Channels',
    },
    {
        id: 25,
        link: '/dashboard/seo',
        icon: <BsSearch />,
        title: 'SEO',
    },
    {
        id: 26,
        link: '/dashboard/leads',
        icon: <SiGoogleadsense />,
        title: 'Leads',
    },
    {
        id: 27,
        link: '/dashboard/bancassurance',
        icon: <FaDonate />,
        title: 'Bancassurance',
        iconClosed: <MdKeyboardArrowRight className="text-white fs-5" />,
        iconOpened: <MdKeyboardArrowDown className="text-white fs-5" />,
        subNav: [
            {
                id: 1,
                title: 'Introduction',
                link: '/dashboard/bancassurance/introduction',
                icon: <FaArrowAltCircleRight />,
            },
            {
                id: 2,
                title: 'Why Guardian Life',
                link: '/dashboard/bancassurance/why-guardian-life',
                icon: <FaArrowAltCircleRight />,
            },
            {
                id: 3,
                title: 'Services',
                link: '/dashboard/bancassurance/services',
                icon: <FaArrowAltCircleRight />,
            },
            {
                id: 4,
                title: 'Benefits',
                link: '/dashboard/bancassurance/benefits',
                icon: <FaArrowAltCircleRight />,
            },
        ],
    },
];
