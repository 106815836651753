import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import careerPosition from "../../../assets/image/career-position.png";

function SinglePositionDetails({ data }) {
    return (
        <div>
            {
                <span
                    dangerouslySetInnerHTML={{ __html: data?.description }}
                ></span>
            }

            {/* <div>
                <div class="career-details-title w-100">
                    <h2 className='text-start'>Job Responsibilities</h2>
                </div>
                <div className='career-single-text mt-4'>
                    <p>{data?.jobResponsibilitiesDescription?.split("/")[0]}</p>
                </div>
                <ul className='mt-2'>
                    {
                        data?.jobResponsibilities?.map(responsibility =>
                            <li key={responsibility?.id}>
                                <div className='corporate-coverage-list'>
                                    <div className='corporate-coverage-check'>
                                        <FaCheckCircle />
                                    </div>
                                    <p className='career-single-text'>{responsibility?.title}</p>
                                </div>
                            </li>
                        )
                    }
                </ul>
                <div className='mt-4 career-single-text'>
                    <p>{data?.jobResponsibilitiesDescription.split("/")[1]}</p>
                </div>
                <div className='mt-4 career-single-img'>
                    <img src={careerPosition} alt='career' />
                </div>

                <div className='service-container bg-white pb-0'>
                    <div class="career-details-title w-100">
                        <h2 className='text-start'>Job Requirements</h2>
                    </div>
                    <div className='mt-4 career-single-text d-flex flex-column gap-3'>
                        {
                            data?.jobRequirements?.split("/")?.map((req, i) =>
                                <p key={i}>{req}</p>
                            )
                        }
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default SinglePositionDetails