import React from "react";
import BannerImg from "../../../assets/image/mission.png";
import BannerImg2 from "../../../assets/image/visions.png";
import OurPlansTitle from "../../CatagoryPlans/OurPlans/OurPlansTitle";
import BannersCard from "../../../components/BannersCard/BannersCard";
import OurPlansCardDetails from "../../../components/OurPlansCardDetails/OurPlansCardDetails";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import i18n from "i18next";

function OurMissionVission() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const missionVissionDetails = [
    {
      id: 1,
      title: `${t("mission")}`,
      desc: `${t("missionDesc1")}`,
      textDesc: [
        `${t("missionDesc2")}`,
        `${t("missionDesc3")}`,
        `${t("missionDesc4")}`,
        `${t("missionDesc5")}`,
        `${t("missionDesc6")}`,
        `${t("missionDesc7")}`,
      ],
      img: BannerImg,
    },
    {
      id: 2,
      title: `${t("vision")}`,
      desc: `${t("vissionDesc1")}`,
      textDesc: [
        `${t("vissionDesc2")}`,
        `${t("vissionDesc3")}`,
        `${t("vissionDesc4")}`,
      ],
      img: BannerImg2,
    },
  ];

  return (
    <div className="body-container secondary-container banner-container">
      <div>
        <OurPlansTitle title={t("missionVission")} />
      </div>
      <div className="plans-container">
        {missionVissionDetails.map((bannerItem, i) => (
          <div key={i} className="plans-section">
            {i % 2 === 0 && (
              <div className="plans-banner-contents ">
                <>
                  <OurPlansCardDetails
                    bannerItem={bannerItem}
                    key={bannerItem.id}
                  />
                </>
              </div>
            )}
            {i % 2 !== 0 && (
              <div className="plans-banner-contents right-plans-content">
                <BannersCard bannerItem={bannerItem} key={bannerItem.id} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default OurMissionVission;
