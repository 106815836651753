import React, { useState } from "react";
import EasyLifeHeader from "../pages/Shared/Header/EasyLifeHeader/EasyLifeHeader";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import { Link, Outlet } from "react-router-dom";
import EasyLifeFooter from "../pages/Shared/Footer/EasyLifeFooter/EasyLifeFooter";
import {
  BsDashLg,
  BsEmojiSmile,
  BsFacebook,
  BsInstagram,
  BsSendFill,
  BsYoutube,
} from "react-icons/bs";
import { ImLinkedin } from "react-icons/im";
import { AiOutlineUser } from "react-icons/ai";
import MessangerIcon from "../assets/icon/message.png";
import Lottie from "lottie-react";
import lottieDigitalBox from "../assets/icon/lottie-gift-box.json";
import { FaGift } from "react-icons/fa";

const EasyLifeLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <EasyLifeHeader />
      <ScrollToTop />
      <div className="position-relative">
        <div className="social-icon-container d-none d-lg-block">
          <div className="social-icon-card">
            <Link to="https://www.facebook.com/EasyLifeGLIL" target="_blank">
              <BsFacebook className="social-icon" />
            </Link>
            <Link to="https://www.instagram.com/easylifebd" target="_blank">
              <BsInstagram className="social-icon" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/easylife-bd/"
              target="_blank"
            >
              <ImLinkedin className="social-icon" />
            </Link>
            <Link to="https://www.youtube.com/@EasyLifeBDGLIL" target="_blank">
              <BsYoutube className="social-icon" />
            </Link>
          </div>
        </div>
        <div className="messanger-container">
          {/* {isOpen && (
                        <div className="message-pupop-containr">
                            <div>
                                <div className="messanger-header">
                                    <div className="header-content">
                                        <AiOutlineUser className="user-icon" />
                                        <span className="header-text">Welcome to Live Chat!</span>
                                    </div>
                                    <div>
                                        <BsDashLg onClick={togglePopup} className="close-icon" />
                                    </div>
                                </div>
                                <div className="messanger-body">
                                    <div className="text-messages">
                                        <div>
                                            <AiOutlineUser className="user-icon user-send-icon" />
                                        </div>
                                        <p className="user-send-text">Hello</p>
                                    </div>
                                </div>
                                <div className="messanger-footer">
                                    <div className="messanger-footer-content">
                                        <div className="write-message">
                                            <textarea
                                                className="form-control text-message"
                                                placeholder="Write a message"
                                            ></textarea>
                                        </div>
                                        <div className="send-text">
                                            <BsEmojiSmile className="emoji" />
                                            <BsSendFill className="send-btns" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )} */}

          {/* <div className="messanger-img">
            <img onClick={togglePopup} src={MessangerIcon} />
          </div> */}
        </div>

        <div className="digital-container">
          <div className="digital-icon">
            {/* <img onClick={togglePopup} src={MessangerIcon} /> */}
            <Link to="/digital-loyalty-offer" target="_blank">
              {/* <Lottie animationData={lottieDigitalBox} loop={true} /> */}
              <FaGift className="digital-icon"/>
            </Link>
          </div>
        </div>
      </div>
      <div>
        <Outlet />
      </div>
      <EasyLifeFooter />
    </div>
  );
};

export default EasyLifeLayout;
