import React, { useState } from 'react';
import ProductFAQ from './ProductFAQ';
import OffersFAQ from './OffersFAQ';
import GeneralFAQ from './GeneralFAQ';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";



const EasyLifeFAQSubmenu = () => {
    const [menuState, setMenuState] = useState(0);

    const action = (idx) => {
        setMenuState(idx);
    }

    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
    }, []);
    return (
        <div className='service-container bg-white'>
            <div className='body-container secondary-container'>
                <div>
                    <section className='media-corner-submenu d-flex justify-content-center'>
                        <div onClick={() => action(0)} className={menuState === 0 ? "active-submenu" : "default-submenu"}>{t("easylifeFAQSubmenu1")}</div>

                        <div onClick={() => action(1)} className={menuState === 1 ? "active-submenu" : "default-submenu"}>{t("easylifeFAQSubmenu2")}</div>

                        <div onClick={() => action(2)} className={menuState === 2 ? "active-submenu" : "default-submenu"}>{t("easylifeFAQSubmenu3")}</div>
                    </section>

                    <section className='mt-5'>
                        <div className={menuState === 0 ? "d-block" : "d-none"}>
                            <ProductFAQ t={t} />
                        </div>
                        <div className={menuState === 1 ? "d-block" : "d-none"}>
                            <OffersFAQ t={t} />
                        </div>
                        <div className={menuState === 2 ? "d-block" : "d-none"}>
                            <GeneralFAQ t={t} />
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default EasyLifeFAQSubmenu;