import React from 'react';
import { Link } from 'react-router-dom';
import Buttons from '../../../components/Buttons/Buttons';

const OurPlansAllCategories = ({ bannerItem, i, banglatranslate, t }) => {
    return (
        <div className="">
            <div id={bannerItem?.slug}>
                {i % 2 === 0 && (
                    <div className="plans-banner-contents flex-column flex-lg-row right-plans-padding">
                        <div className="right-img">
                            <img src={bannerItem?.categoryImageURL} alt="" />
                        </div>
                        <div className="left-content">
                            <h1 className="title banner-title">
                                {banglatranslate === 'bn'
                                    ? bannerItem?.categoryTitleBn
                                    : bannerItem?.categoryTitle}
                            </h1>
                            <p className="text banner-text">
                                {banglatranslate === 'bn' ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                bannerItem
                                                    ?.categoryDescriptionBn
                                                    ?.length > 250
                                                    ? bannerItem?.categoryDescriptionBn.slice(
                                                          0,
                                                          250
                                                      ) + '...'
                                                    : bannerItem?.categoryDescriptionBn,
                                        }}
                                    ></span>
                                ) : (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                bannerItem?.categoryDescription
                                                    ?.length > 250
                                                    ? bannerItem?.categoryDescription.slice(
                                                          0,
                                                          250
                                                      ) + '...'
                                                    : bannerItem?.categoryDescription,
                                        }}
                                    ></span>
                                )}
                            </p>

                            <div className="card-button">
                                <Link to={`/retails/${bannerItem?.slug}`}>
                                    <Buttons classes="btn-learn">
                                        {t('learnmore')}
                                    </Buttons>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div id={bannerItem?.slug}>
                {i % 2 !== 0 && (
                    <div className="plans-banner-contents right-plans-padding">
                        <div className="left-content">
                            <h1 className="title banner-title">
                                {banglatranslate === 'bn'
                                    ? bannerItem?.categoryTitleBn
                                    : bannerItem?.categoryTitle}
                            </h1>
                            <p className="text banner-text">
                                {banglatranslate === 'bn' ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                bannerItem
                                                    ?.categoryDescriptionBn
                                                    ?.length > 250
                                                    ? bannerItem?.categoryDescriptionBn.slice(
                                                          0,
                                                          250
                                                      ) + '...'
                                                    : bannerItem?.categoryDescriptionBn,
                                        }}
                                    ></span>
                                ) : (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                bannerItem?.categoryDescription
                                                    ?.length > 250
                                                    ? bannerItem?.categoryDescription.slice(
                                                          0,
                                                          250
                                                      ) + '...'
                                                    : bannerItem?.categoryDescription,
                                        }}
                                    ></span>
                                )}
                            </p>

                            <div className="card-button">
                                <Link to={`/retails/${bannerItem?.slug}`}>
                                    <Buttons classes="btn-learn">
                                        {t('learnmore')}
                                    </Buttons>
                                </Link>
                            </div>
                        </div>
                        <div className="right-img">
                            <img src={bannerItem?.categoryImageURL} alt="" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OurPlansAllCategories;
