import React from "react";
import { Modal } from "react-bootstrap";

const DashboardBlogViewModal = ({ viewBlog, handleViewClose, viewShow }) => {
  return (
    <Modal
      show={viewShow}
      onHide={handleViewClose}
      scrollable={true}
      //   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          Single Blog
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <div>
          <div>
            <img
              src={viewBlog?.imageURL}
              alt="blog"
              className="w-100"
              style={{ height: "270px" }}
            />
          </div>
          <h5 className="fw-bold mt-4 pb-2 border-bottom">{viewBlog?.title}</h5>
          <p className="mt-3">
            <span
              dangerouslySetInnerHTML={{
                __html: viewBlog?.description,
              }}
            ></span>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DashboardBlogViewModal;
