import React, { useEffect } from 'react';
import "./MicroinsuranceAchievement.css"
import Icon from "../../../assets/image/service.png";
import achiveLivesIcon from "../../../assets/image/achive-lives.png";
import percentageIcon from "../../../assets/image/percentage.png";
import paidIcon from "../../../assets/image/paid.png";
import claimSettledIcon from "../../../assets/image/settled.png";
import awardIcon from "../../../assets/image/award.png";
import bankIcon from "../../../assets/image/bank.png";
import cooperativeIcon from "../../../assets/image/Cooperative.png";
import assetIcon from "../../../assets/image/Asset.png";
import NBFIsIcon from "../../../assets/image/NBFIs.png";
import microFinanceIcon from "../../../assets/image/micro-finance.png";
import { useTranslation } from "react-i18next";
import i18n from "i18next";


const MicroinsuranceAchievement = () => {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
    }, []);

    const microAchievements = [
        {
            id: 1,
            title: `${t("microinsuranceAchievementListT1")}`,
            subTitle: `${t("microinsuranceAchievementListT1Sub")}`,
            icon: achiveLivesIcon,
        },
        {
            id: 2,
            title: `${t("microinsuranceAchievementListT2")}`,
            subTitle: `${t("microinsuranceAchievementListT2Sub")}`,
            icon: percentageIcon,
        },
        {
            id: 3,
            title: `${t("microinsuranceAchievementListT3")}`,
            subTitle: `${t("microinsuranceAchievementListT3Sub")}`,
            icon: paidIcon,
        },
        {
            id: 4,
            title: `${t("microinsuranceAchievementListT4")}`,
            subTitle: `${t("microinsuranceAchievementListT4Sub")}`,
            icon: claimSettledIcon,
        },
        {
            id: 5,
            title: `${t("microinsuranceAchievementListT5")}`,
            subTitle: `${t("microinsuranceAchievementListT5Sub")}`,
            icon: awardIcon,
        }
    ];
    
    
    const microBeneficiaries = [
        {
            id: 1,
            title: `${t("microinsuranceBeneficiariesListT1")}`,
            icon: microFinanceIcon,
        },
        {
            id: 2,
            title: `${t("microinsuranceBeneficiariesListT2")}`,
            icon: NBFIsIcon,
        },
        {
            id: 3,
            title: `${t("microinsuranceBeneficiariesListT3")}`,
            icon: assetIcon,
        },
        {
            id: 4,
            title: `${t("microinsuranceBeneficiariesListT4")}`,
            icon: cooperativeIcon,
        },
        {
            id: 5,
            title: `${t("microinsuranceBeneficiariesListT5")}`,
            icon: bankIcon,
        }
    ];
    return (
        <div className="service-container pt-0">
            <div className="body-container secondary-container">
                <div className='rounded-4 p-5 position-relative micro-achievement-container'>
                    <section>
                        {/* achievement */}
                        <div className='row flex-column flex-lg-row align-items-center gx-3'>
                            <h1 className="col-12 col-lg-2 title title-service micro-achieve-title mb-4 mb-lg-0 text-center text-lg-start">{t("microinsuranceAchievementTitle")}</h1>
                            <div className="col-12 col-lg-10 micro-achievement-grid">
                                {microAchievements.map((achievement) => (
                                    <div className="cards d-flex flex-md-column">
                                        <div class="micro-achive-icon p-1 d-flex justify-content-center">
                                            <img src={achievement.icon} alt='icon' />
                                        </div>
                                        <div className="card-info text-md-center">
                                            <h4 className="micro-title text-capitalize">{achievement.title}</h4>
                                            <p style={{ fontSize: "14px" }}>{achievement.subTitle}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    <hr className='my-5' style={{ color: "#999999" }} />

                    {/* beneficiaries */}
                    <section>
                        <div className='row flex-column flex-lg-row align-items-center gx-3'>
                            <h1 className="col-12 col-lg-2 title title-service micro-achieve-title mb-4 mb-lg-0 text-center text-lg-start">{t("microinsuranceBeneficiariesTitle")}</h1>
                            <div className="col-12 col-lg-10 micro-achievement-grid">
                                {microBeneficiaries.map((beneficiary) => (
                                    <div className="cards d-flex flex-md-column">
                                        <div class="micro-achive-icon p-1 d-flex justify-content-center">
                                            <img src={beneficiary.icon} alt='icon' />
                                        </div>
                                        <div className="card-info text-md-center">
                                            <h4 className="micro-title text-capitalize">{beneficiary.title}</h4>
                                            <p style={{ fontSize: "14px" }}>{beneficiary.subTitle}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    <div className='micro-vertical-line'></div>
                </div>
            </div>
        </div>
    );
};

export default MicroinsuranceAchievement;