import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useRef } from 'react';
import JoditEditor from 'jodit-react';

function DashboardCategoryPlansEditModal({
    editPlans,
    setEditPlans,
    editPlansModal,
    handleEditClose,
    getAllDataFetch,
}) {
    const [keyFeature, setFeatureKey] = useState([]);
    const [keyFeatureBn, setFeatureKeyBn] = useState([]);
    const [datas, setDatas] = useState([]);
    const [datasBn, setDatasBn] = useState([]);
    const editor = useRef(null);
    const [content, setContent] = useState(editPlans?.categoryDescription);
    const [contentBn, setContentBn] = useState(
        editPlans?.categoryDescriptionBn
    );

    useEffect(() => {
        if (editPlans && editPlans?.keyFeature) {
            setFeatureKey(editPlans?.keyFeature);
        }
    }, [editPlans]);

    useEffect(() => {
        if (editPlans && editPlans?.keyFeatureBn) {
            setFeatureKeyBn(editPlans?.keyFeatureBn);
        }
    }, [editPlans]);

    // Check if keyFeature is undefined and render accordingly
    if (keyFeature === undefined && keyFeatureBn === undefined) {
        return <div>Loading...</div>;
    }

    const handleChange = (e) => {
        setEditPlans({ ...editPlans, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setEditPlans({ ...editPlans, image: e.target.files[0] });
    };

    const handleUpdateAction = (e) => {
        e.preventDefault();
        handleEditClose();

        const formData = new FormData();

        formData.append('plansId', editPlans?.plansId);
        formData.append('categoryTitle', editPlans?.categoryTitle);
        formData.append('categoryTitleBn', editPlans?.categoryTitleBn);
        formData.append('image', editPlans?.image);
        formData.append('categoryVedioLink', editPlans?.categoryVedioLink);

        [...keyFeature].forEach((feature) => {
            formData.append('keyFeature', feature);
        });

        [...keyFeatureBn].forEach((feature) => {
            formData.append('keyFeatureBn', feature);
        });

        formData.append('categoryDescription', content);
        formData.append('categoryDescriptionBn', contentBn);

        for (const value of formData.values()) {
            console.log(value);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/categoryPlans/${editPlans?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getAllDataFetch();
                    console.log(res);
                    toast.success('Plans Catagory updated successfully');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not catagory plans updated!!!');
            });
    };

    return (
        <Modal
            show={editPlansModal}
            onHide={handleEditClose}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Update Category Plans
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleUpdateAction}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Category Title
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            defaultValue={editPlans?.categoryTitle}
                            name="categoryTitle"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Category Title(Bangla)
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            defaultValue={editPlans?.categoryTitleBn}
                            name="categoryTitleBn"
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label  fw-bold">Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImage}
                            name="categoryImageURL"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Product Vedio Link
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            defaultValue={editPlans?.categoryVedioLink}
                            name="categoryVedioLink"
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Introduction
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={editPlans?.categoryDescription}
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Introduction(Bangla)
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={editPlans?.categoryDescriptionBn}
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Key Feature
                        </label>

                        {keyFeature?.map((data, index) => (
                            <input
                                key={index}
                                type="text"
                                name="keyFeature"
                                defaultValue={data}
                                onChange={(e) => {
                                    const updatedDatas = [...keyFeature]; // Create a copy of the array
                                    updatedDatas[index] = e.target.value; // Update the value at the current index
                                    setFeatureKey(updatedDatas); // Update the state with the new array
                                }}
                                className="form-control mb-3"
                            />
                        ))}
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Key Feature(Bangla)
                        </label>
                        {keyFeatureBn?.map((data, index) => (
                            <input
                                key={index}
                                type="text"
                                name="keyFeatureBn"
                                defaultValue={data}
                                onChange={(e) => {
                                    const updatedDatas = [...keyFeatureBn]; // Create a copy of the array
                                    updatedDatas[index] = e.target.value; // Update the value at the current index
                                    setFeatureKeyBn(updatedDatas); // Update the state with the new array
                                }}
                                className="form-control mb-3"
                                required
                            />
                        ))}
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Update
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardCategoryPlansEditModal;
