import React, { useEffect } from "react";
import "./ourService.css";
import OurServiceCard from "./OurServiceCard";
import { GiShakingHands } from "react-icons/gi";
import {
  MdCleanHands,
  MdMiscellaneousServices,
  MdOutlineHandshake,
} from "react-icons/md";
import { SiSecurityscorecard } from "react-icons/si";
import { RiComputerFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function OurService() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const title = `${t("serviceTitle")}`;

  const ourServiceItems = [
    {
      id: 1,
      title: `${t("retail")}`,
      dec: `Ut enim ad minim veniam, quis nostrud quis`,
      icon: <MdMiscellaneousServices className="card-icon" />,
      link: "/retail-product",
      status: "active",
    },
    {
      id: 2,
      title: `${t("corporate")}`,
      dec: `Ut enim ad minim veniam, quis nostrud quis`,
      icon: <SiSecurityscorecard className="card-icon" />,
      link: "/corporate-solutions",
      status: "active",
    },
    {
      id: 3,
      title: `${t("bancassurance")}`,
      dec: `Ut enim ad minim veniam, quis nostrud quis`,
      icon: <MdCleanHands className="card-icon" />,
      link: "/bancassurance",
      status: "active",
    },
    {
      id: 4,
      title: `${t("microinsurance")}`,
      dec: `Ut enim ad minim veniam, quis nostrud quis`,
      icon: <MdOutlineHandshake className="card-icon" />,
      link: "/microinsurance",
      status: "active",
    },
    {
      id: 5,
      title: `${t("digitalService")}`,
      dec: `Ut enim ad minim veniam, quis nostrud quis`,
      icon: <RiComputerFill className="card-icon" />,
      link: "/easylife",
      status: "active",
      target: "_blank",
    },
  ];

  return (
    <div className="service-container">
      <div className="body-container secondary-container">
        <h1 className="title title-service">{title}</h1>
        <div className="service-grid1">
          {ourServiceItems.map((ourServiceItem) => (
            <OurServiceCard
              ourServiceItem={ourServiceItem}
              key={ourServiceItem.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurService;
