import React, { useEffect, useState } from 'react';
import PartnerDetailsBanner from './PartnerDetailsBanner';
import { useLoaderData, useLocation } from 'react-router-dom';
import PartnerOurInsurance from './PartnerOurInsurance/PartnerOurInsurance';
import robiImg from '../../../assets/adcIcon/card_design-01.png';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Loading from '../../../components/Loading/Loading';
import { Helmet } from 'react-helmet';

const ADCPartnerDetails = () => {
    const singleData = useLoaderData();
    const [metaTags, setMetaTags] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);

    useEffect(() => {
        getPaginationList();
    }, [metaTags]);

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/seo?pages=${singleData?.data?.partnerName}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setMetaTags(data?.data?.seo);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    return (
        <div>
            {metaTags?.map((data) => (
                <Helmet>
                    <title>{data?.title} - Guardian Life Insurance Ltd. </title>
                    <meta name="description" content={data?.description} />
                    <meta
                        name="keywords"
                        content={data?.keywords?.map(
                            (keys) => keys?.keywordsName
                        )}
                    />
                </Helmet>
            ))}
            <div>
                <PartnerDetailsBanner data={singleData?.data} />
                <PartnerOurInsurance data={singleData?.data} />
            </div>
        </div>
    );
};

export default ADCPartnerDetails;
