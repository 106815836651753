import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import partnerImage1 from "../../assets/partner/Applied Bioplastics.png";
import partnerImage2 from "../../assets/partner/Apps Code.png";
import partnerImage3 from "../../assets/partner/Archroma.png";
import partnerImage4 from "../../assets/partner/Arla.png";
import partnerImage5 from "../../assets/partner/ATEC.png";
import partnerImage6 from "../../assets/partner/Bay.png";
import partnerImage7 from "../../assets/partner/Berger.png";
import partnerImage8 from "../../assets/partner/Carrefour.png";
import partnerImage9 from "../../assets/partner/DCA.png";
import partnerImage10 from "../../assets/partner/Enroute.png";
import partnerImage11 from "../../assets/partner/Islamic.png";
import partnerImage12 from "../../assets/partner/Marico.png";
import partnerImage13 from "../../assets/partner/Prime Bank.png";
import partnerImage14 from "../../assets/partner/Telenor.png";
import partnerImage15 from "../../assets/partner/The Palace.png";
import partnerImage16 from "../../assets/partner/United Hospital.png";
import partnerImage17 from "../../assets/partner/Wildlife Conservation Society.png";
import partnerImage18 from "../../assets/Partners Logo/00.png";
import partnerImage19 from "../../assets/Partners Logo/Bank Asia.png";
import partnerImage20 from "../../assets/Partners Logo/Bastob.png";
import partnerImage21 from "../../assets/Partners Logo/GJUS.png";
import partnerImage22 from "../../assets/Partners Logo/Leva Health and Eudcation.png";
import partnerImage23 from "../../assets/Partners Logo/NGF.png";
import partnerImage24 from "../../assets/Partners Logo/RDRS.png";
import partnerImage25 from "../../assets/Partners Logo/SDC.png";
import partnerImage26 from "../../assets/Partners Logo/SDS.png";
import partnerImage27 from "../../assets/Partners Logo/Shanta.png";
import partnerImage28 from "../../assets/Partners Logo/brac.png";
import partnerImage29 from "../../assets/Partners Logo/iFarer.png";
import partnerImage30 from "../../assets/Partners Logo/netizen.png";
import partnerImage31 from "../../assets/Partners Logo/proyash.png";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading/Loading";

const partnerSliders = [
  { id: 1, img: partnerImage1 },
  { id: 2, img: partnerImage2 },
  { id: 3, img: partnerImage3 },
  { id: 4, img: partnerImage4 },
  { id: 5, img: partnerImage5 },
  { id: 6, img: partnerImage6 },
  { id: 7, img: partnerImage7 },
  { id: 8, img: partnerImage8 },
  { id: 9, img: partnerImage9 },
  { id: 10, img: partnerImage10 },
  { id: 11, img: partnerImage11 },
  { id: 12, img: partnerImage12 },
  { id: 13, img: partnerImage13 },
  { id: 14, img: partnerImage14 },
  { id: 15, img: partnerImage15 },
  { id: 16, img: partnerImage16 },
  { id: 17, img: partnerImage17 },
  { id: 18, img: partnerImage18 },
  { id: 19, img: partnerImage19 },
  { id: 20, img: partnerImage20 },
  { id: 21, img: partnerImage21 },
  { id: 22, img: partnerImage22 },
  { id: 23, img: partnerImage23 },
  { id: 24, img: partnerImage24 },
  { id: 25, img: partnerImage25 },
  { id: 26, img: partnerImage26 },
  { id: 27, img: partnerImage27 },
  { id: 28, img: partnerImage28 },
  { id: 29, img: partnerImage29 },
  { id: 30, img: partnerImage30 },
  { id: 31, img: partnerImage31 },
];
const CorporatePartnerSection = () => {
  const [homePartnerLogo, setHomePartnerLogo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_host}/api/v1/partner-logo?pageSelect=Home Page`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setHomePartnerLogo(data?.data?.partnerLogoList);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="secondary-container body-container partnet-content">
      <h1 className="title review-tilte">{t("partner")}</h1>
      <div className="partnet-slider">
        <Swiper
          slidesPerView={1}
          spaceBetween={15}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          autoplay={{ delay: 2000 }}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            "@1.50": {
              slidesPerView: 6,
              spaceBetween: 15,
            },
          }}
          modules={[Navigation, Pagination, Autoplay]}
          className="mySwiper partner-contianer"
        >
          {homePartnerLogo?.map((partnerSlider) => (
            <SwiperSlide key={partnerSlider?._id} className="slider-partner">
              <div>
                <img src={partnerSlider?.imageURL} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CorporatePartnerSection;
