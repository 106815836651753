import React from "react";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import mapImg from "../../../assets/icon/map-logo.png";
import { toast } from "react-hot-toast";

function DashboardLocateBranchList({
  locateBranchList,
  getBranchList,
  setUpdateBranch,
  handleEditShow,
  filtersBranch,
  idx,
}) {
  const {
    _id,
    officeCategory,
    officeName,
    division,
    district,
    area,
    address,
    contactPerson,
    mapLink,
  } = locateBranchList;

  const handleDeleteAction = (id) => {
    const agree = window.confirm(
      `Are you sure, you want to delete: "${officeName}?"`
    );
    if (agree) {
      fetch(`${process.env.REACT_APP_host}/api/v1/all-branch/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            getBranchList();
            filtersBranch();
            toast.success(`${officeName} is a Deleted`);
          }
        });
    }
  };

  return (
    <tr>
      <td className="text-center align-middle table-text"> {idx + 1}</td>
      <td className="text-center align-middle table-text"> {officeCategory}</td>
      <td className="text-center align-middle table-text">{officeName}</td>
      <td className="text-center align-middle table-text">{division}</td>
      <td className="text-center align-middle table-text">{district}</td>
      <td className="text-center align-middle table-text">{area}</td>
      <td className="text-center align-middle table-text">{address}</td>
      <td className="text-center align-middle table-text">
        <span dangerouslySetInnerHTML={{ __html: contactPerson }}></span>
      </td>
      <td className="text-center align-middle table-text">
        <div>
          <Link
            to={mapLink}
            target="_blank"
            className="d-inline-block"
            style={{ width: "30px" }}
          >
            <img src={mapImg} alt="map" className="w-100" />
          </Link>
        </div>
      </td>

      <td className="text-center align-middle table-text">
        <div className="d-flex align-items-center justify-content-center gap-2">
          <button
            onClick={() => {
              handleEditShow();
              setUpdateBranch(locateBranchList);
            }}
            className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center "
          >
            <BiEdit /> <span>Edit</span>
          </button>
          <button
            onClick={() => handleDeleteAction(_id)}
            className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center"
          >
            <MdDelete /> <span>Delete</span>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default DashboardLocateBranchList;
