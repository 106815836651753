import React, { useEffect } from 'react';
import { useContext } from 'react';
import { AiOutlineHome, AiOutlineMail } from 'react-icons/ai';
import { BsTelephone } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { BookContext } from '../../context/BookingContext/BookingContext';
import Buttons from '../../components/Buttons/Buttons';

function ContactForm() {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);

    const contactDetailsData = [
        {
            id: 1,
            title: `${t('address')}`,
            desc: `${t('addressDetails')}`,
            icon: <AiOutlineHome />,
        },
        {
            id: 2,
            title: `${t('contactNumber')}`,
            desc: `${t('phoneNumber')} , ${t('09666716622')}`,
            icon: <BsTelephone />,
        },
        {
            id: 3,
            title: `${t('email')}`,
            desc: `info@guardianlife.com.bd`,
            icon: <AiOutlineMail />,
        },
    ];

    const { handleAddMessage, handleChangeNumber, errorMessage, inputValue } =
        useContext(BookContext);
    return (
        <div className="service-container">
            <div className="secondary-container body-container contact-container py-0">
                <h1 className="title title-service corporate-book-now-title">
                    {t('easylifeContactTitle')}
                </h1>
                <div className="contact-content-from">
                    <div className="contact-left">
                        <p className="text pt-0">{t('contactDes')}</p>
                        <div>
                            {contactDetailsData.map((data) => (
                                <div key={data.id} className="mb-3">
                                    <div className=" contact-details d-flex align-items-center gap-2">
                                        <span className="contact-icon">
                                            {data.icon}
                                        </span>
                                        <h6 className="contact-title-content">
                                            {data.title}
                                        </h6>
                                    </div>

                                    <p className="contact-desc">{data.desc}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="vertical-line"></div>

                    <div className="contact-right">
                        <h5>{t('form')}</h5>

                        <form className="mt-5" onSubmit={handleAddMessage}>
                            <div className="d-flex align-items-center gap-2">
                                <input
                                    className="form-control form-contact mb-4 w-100 py-2"
                                    placeholder={`${t('name')}`}
                                    name="name"
                                    type="text"
                                    required
                                />
                                <input
                                    type="email"
                                    className="form-control form-contact mb-4 w-100 py-2"
                                    placeholder={`${t('email')}`}
                                    name="email"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <input
                                    type="number"
                                    className="form-control form-contact number-arrow  py-2"
                                    placeholder={`${t('contactNumber')}`}
                                    name="contactNumber"
                                    value={inputValue}
                                    onChange={handleChangeNumber}
                                    maxLength={11}
                                    required
                                />
                                {errorMessage && (
                                    <span style={{ color: 'red' }}>
                                        {errorMessage}
                                    </span>
                                )}
                            </div>
                            <textarea
                                className="form-control form-contact contact-area mb-4 "
                                placeholder={`${t('message')}`}
                                name="message"
                                required
                            ></textarea>

                            <div>
                                <Buttons classes="btns send-btn w-100">
                                    {t('send')}
                                </Buttons>
                                {/* <input cl type="submit" /> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;
