import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast } from 'react-hot-toast';

const DasboardImageAllGallery = ({ data, getPaginationList }) => {
    const navigate = useNavigate();
    console.log(data.imageURL[0]);

    const handleDetails = (singleData, dataId) => {
        navigate(`/dashboard/media/view-gallery/${dataId}`, { state: singleData });
    };

    const handleDeleteAction = (id) => {
        const agree = window.confirm(`Are you sure, you want to delete: "${data?.title}" ?`);
        if (agree) {
            fetch(`${process.env.REACT_APP_host}/api/v1/media-photo/${id}`, {
                method: "DELETE",
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.status) {
                        getPaginationList();
                        toast.success(`Photo gallery is Deleted successfully !`);
                    }
                });
        }
    };

    return (
        <div className='media-container-video media-container-shadow position-relative'>
            <div onClick={() => handleDetails(data, data?._id)} className='border-0 bg-transparent media-photo-gallery'>
                <div className='media-gallery-photo'>
                    <img src={data.imageURL[0]} alt='photoGallery' />
                </div>
                <div className='media-gallery-overly'></div>
                <p className='media-gallery-title'>{data?.title?.length > 32 ? `${data?.title.slice(0, 32)}...` : `${data?.title}`}</p>
            </div>
            <div onClick={() => handleDeleteAction(data?._id)} className='gallery-banner-delete-icon' title="Delete">
                <RiDeleteBin5Line />
            </div>
        </div>
    );
};

export default DasboardImageAllGallery;