import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

const DashboardLoyaltyOfferCategory = () => {
    const navigate = useNavigate();
    const [addCategory, setAddCategory] = useState({
        category: '',
        categoryBn: '',
    });

    const handleChange = (e) => {
        setAddCategory({ ...addCategory, [e.target.name]: e.target.value });
    };

    // post
    const handleAddCategory = async (e) => {
        e.preventDefault();

        const newCategory = {
            category: addCategory.category,
            categoryBn: addCategory.categoryBn,
        };

        console.log(newCategory);

        fetch(`${process.env.REACT_APP_host}/api/v1/loyalty-category`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newCategory),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data?.status) {
                    toast.success('Digital Offer Category added successfully');
                    navigate('/dashboard/digital-offer/all-category');
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('Not added successfully');
            });
    };

    return (
        <div className="home-content">
            <div class="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/digital-offer/all-category"
                    class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Category</span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center">
                    Add Loyalty Offer Category
                </h1>
                <form onSubmit={handleAddCategory}>
                    <div className="mb-3">
                        <label className="form-label">Category</label>
                        <input
                            onChange={handleChange}
                            type="text"
                            name="category"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Category (Bangla)</label>
                        <input
                            onChange={handleChange}
                            type="text"
                            name="categoryBn"
                            className="form-control"
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default DashboardLoyaltyOfferCategory;
