import React from 'react';
import { useState } from 'react';
import NationalHospital from './NationalHospital/NationalHospital';
import InternationalHospital from './InternationalHospital/InternationalHospital';

const HospitalList = () => {
    const [menuState, setMenuState] = useState(0);

    const action = (idx) => {
        setMenuState(idx);
    }

    
    return (
        <div>
            <div>
            <h1 class="title title-service">Find Hospital and Diagnostics</h1>
            <div className='mt-5'>
                    <section className='media-corner-submenu d-flex justify-content-center'>
                        <div onClick={() => action(0)} className={menuState === 0 ? "active-submenu" : "default-submenu"}>National</div>

                        <div onClick={() => action(1)} className={menuState === 1 ? "active-submenu" : "default-submenu"}>International</div>
                    </section>

                    <section className='mt-4'>
                        <div className={menuState === 0 ? "d-block" : "d-none"}>
                            <NationalHospital />
                        </div>
                        <div className={menuState === 1 ? "d-block" : "d-none"}>
                            <InternationalHospital />
                        </div>
                    </section>

                </div>
            </div>
        </div>
    );
};

export default HospitalList;