import React, { useState } from 'react';
import BgBannerImg from "../../assets/BG/bgLeft.png";
import BannerImg from "../../assets/image/retail-banner.png";
import Button from "react-bootstrap/Button";
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";


const BgStyle = {
    backgroundImage: `url(${BgBannerImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
};


const RetailBanner = () => {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
    }, []);

    const bannerItems = [
        {
            id: 1,
            title: `${t("retailBannerTitle")}`,
            desc: `${t("retailBannerDetails")}`,
            img: BannerImg,
        },
    ];

    const [isShowMore, setShowMore] = useState(false);
    const handleShow = () => {
        setShowMore(!isShowMore);
    }
    return (
        <div style={BgStyle}>
            <div className="body-container secondary-container banner-container">
                {bannerItems.map((bannerItem) => (
                    <div key={bannerItem.id} className="banner-content">
                        <div className="left-content">
                            <h1 className="title banner-title">{bannerItem?.title}</h1>
                            <p className="text banner-text">
                                {
                                    !isShowMore ? bannerItem?.desc.slice(0, 300) + "..." : bannerItem?.desc
                                }
                            </p>
                            <div className="d-flex align-items-center gap-3 mt-4">
                                <Button className="btn-book btns" onClick={handleShow}>
                                    {isShowMore ? `${t("readless")}` : `${t("readmore")}`}
                                </Button>
                            </div>
                        </div>
                        <div className="right-img">
                            <img src={bannerItem?.img} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RetailBanner;