import React from 'react';
import { toast } from 'react-hot-toast';
import JoditEditor from 'jodit-react';
import { useRef } from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiEye } from 'react-icons/fi';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

function DashboardPreferredHospital() {
    const editor = useRef(null);
    const navigate = useNavigate();
    const [hospitalType, setHospitalType] = useState('');
    const [hospitalCategory, setHospitalCategory] = useState('');
    const [country, setCountry] = useState('');
    const [division, setDivision] = useState('');
    const [district, setDistrict] = useState('');
    const [area, setArea] = useState('');
    const [hospitalId, setHospitalId] = useState('');
    const [hospitalName, setHospitalName] = useState('');
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState('');
    const [facilities, setFacilities] = useState('');
    const [remark, setRemark] = useState('');
    const [getDivision, setGetDivision] = useState(null);
    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getNameByDivision();
    }, [division]);

    // all division
    const url = `${process.env.REACT_APP_host}/api/v1/division-district`;
    const { data: allDivisionDistrict = [], isLoading } = useQuery({
        queryKey: [' allDivisionDistrict'],
        queryFn: async () => {
            try {
                const res = await fetch(url);
                const data = await res.json();
                return data?.data;
            } catch (err) {
                console.log(err);
            }
        },
    });

    // all country
    const { data: allCountry = [] } = useQuery({
        queryKey: [' allCountry'],
        queryFn: async () => {
            try {
                const res = await fetch(
                    `${process.env.REACT_APP_host}/api/v1/country`
                );
                const data = await res.json();
                return data?.data;
            } catch (err) {
                console.log(err);
            }
        },
    });

    // get by name division
    function getNameByDivision() {
        const url = `${process.env.REACT_APP_host}/api/v1/division-district?division=${division}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status) {
                    setGetDivision(data?.data);
                    // setDivision("");
                }
            });
    }

    //   post hospital
    const handleAddHospital = (e) => {
        e.preventDefault();

        const newHospital = {
            hospitalType,
            hospitalCategory,
            country,
            division,
            district,
            area,
            hospitalId,
            hospitalName,
            address,
            contactDetails: contact,
            facilities,
            remarks: remark,
        };

        // console.log(newHospital);

        fetch(`${process.env.REACT_APP_host}/api/v1/hospital`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(newHospital),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status) {
                    console.log(data);
                    toast.success('Hospital added successfully!');
                    navigate('/dashboard/preferred-hospital/all-hospital');
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error('Hospital is not added!');
            });
    };

    return (
        <>
            <div className="home-content">
                <div class="d-flex justify-content-end gap-2 align-items-center">
                    <Link
                        to="/dashboard/preferred-hospital/all-hospital"
                        className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                    >
                        <FiEye />
                        <span>View Details </span>
                    </Link>
                </div>
                <div className="shadow rounded main-contents">
                    <h1 className="dashboard-title text-center mb-4">
                        Add Hospital
                    </h1>
                    <form onSubmit={handleAddHospital}>
                        <div className="mb-3">
                            <label className="form-label  fw-bold">
                                Hospital Type{' '}
                                <span className="text-danger">*</span>
                            </label>
                            <select
                                name="hospitalType"
                                class="form-select"
                                onChange={(e) =>
                                    setHospitalType(e.target.value)
                                }
                                required
                            >
                                <option value="">Choose hospital type</option>
                                <option value="national">National</option>
                                <option value="international">
                                    International
                                </option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label className="form-label  fw-bold">
                                National Hospital Category
                            </label>
                            <select
                                name="hospitalCategory"
                                class="form-select"
                                onChange={(e) =>
                                    setHospitalCategory(e.target.value)
                                }
                            >
                                <option value="">
                                    Choose hospital category
                                </option>
                                <option value="hospital">Hospital</option>
                                <option value="diagnostic">
                                    Diagnostic Center
                                </option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label className="form-label  fw-bold">
                                Country
                            </label>
                            <select
                                name="country"
                                class="form-select"
                                onChange={(e) => setCountry(e.target.value)}
                                // disabled={
                                //     hospitalType === 'international'
                                //         ? false
                                //         : true
                                // }
                                // required
                            >
                                <option value="">Choose country</option>

                                {allCountry?.map((country) => (
                                    <option key={country._id}>
                                        {country?.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label  fw-bold">
                                Division
                            </label>
                            <select
                                name="division"
                                class="form-select"
                                onChange={(e) => setDivision(e.target.value)}
                                // disabled={
                                //     hospitalType === 'national' ? false : true
                                // }
                                // required
                            >
                                <option value="">Choose division</option>
                                {allDivisionDistrict?.map((divisions) => (
                                    <option key={divisions._id}>
                                        {divisions?.division}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label className="form-label  fw-bold">
                                District
                            </label>
                            <select
                                name="district"
                                class="form-select"
                                onChange={(e) => setDistrict(e.target.value)}
                                // disabled={
                                //     hospitalType === 'national' ? false : true
                                // }
                                // required
                            >
                                <option value="">Choses district</option>
                                {getDivision?.map((getUpdateDivision) =>
                                    getUpdateDivision?.districts?.map(
                                        (district, i) => (
                                            <option key={i}>{district}</option>
                                        )
                                    )
                                )}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label className="form-label  fw-bold">Area</label>
                            <input
                                type="text"
                                value={area === 'area' ? '' : area}
                                onChange={(e) => setArea(e.target.value)}
                                name="area"
                                className="form-control"
                                // disabled={
                                //     hospitalType === 'national' ? false : true
                                // }
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label  fw-bold">
                                Hospital ID
                            </label>
                            <input
                                type="text"
                                value={hospitalId}
                                onChange={(e) => setHospitalId(e.target.value)}
                                name="hospitalId"
                                className="form-control"
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label  fw-bold">
                                Hospital Name
                            </label>
                            <input
                                type="text"
                                value={hospitalName}
                                onChange={(e) =>
                                    setHospitalName(e.target.value)
                                }
                                name="hospitalName"
                                className="form-control"
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label  fw-bold">
                                Address
                            </label>
                            <textarea
                                rows={2}
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                name="address"
                                className="form-control"
                            ></textarea>
                        </div>

                        <div className="mb-3">
                            <label className="form-label fw-bold">
                                Contact Details
                            </label>
                            <JoditEditor
                                ref={editor}
                                value={contact}
                                // config={config}
                                // tabIndex={1} // tabIndex of textarea
                                onChange={(newContent) => {
                                    setContact(newContent);
                                }}
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label fw-bold">
                                Facilities
                            </label>
                            <JoditEditor
                                ref={editor}
                                value={facilities}
                                // config={config}
                                // tabIndex={1} // tabIndex of textarea
                                onChange={(newContent) => {
                                    setFacilities(newContent);
                                }}
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label  fw-bold">
                                Remarks
                            </label>
                            <textarea
                                rows={2}
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                                name="remark"
                                className="form-control"
                            ></textarea>
                        </div>

                        <button type="submit" className="btn btn-primary px-4">
                            Save
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default DashboardPreferredHospital;
