import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "i18next";

function CancerCarePlansTable() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const overviewLists = [
    {
      id: 1,
      feature: `${t("Description")}`,
      silverPlan: `${t("Comprehensive Insurance Plan for Cancer Treatment")}`,
      goldPlan: `${t(
        "Comprehensive Insurance Plan for Cancer Treatment with Indexation Benefit"
      )}`,
    },
    {
      id: 2,
      feature: `${t("Sum Assured / Coverage")}`,
      silverPlan: `${t("Maximum BDT 20 Lakhs")}`,
      goldPlan: `${t("Maximum BDT 20 Lakhs")}`,
    },
    {
      id: 3,
      feature: `${t("Policy Term")}`,
      silverPlan: `${t("5 to 20 Years")}`,
      goldPlan: `${t("5 to 20 Years")}`,
    },
    {
      id: 4,
      feature: `${t("Payment Mode")}`,
      silverPlan: `${t("Yearly")}`,
      goldPlan: `${t("Yearly")}`,
    },
    {
      id: 5,
      feature: `${t("Entry Age Limit")}`,
      silverPlan: `${t("65 Years")}`,
      goldPlan: `${t("65 Years")}`,
    },
    {
      id: 6,
      feature: `${t(
        "10% increase in Sum Assured for every claim free year (Indexation Benefit)"
      )}`,
      silverPlan: `${t("No")}`,
      goldPlan: `${t("Yes")}`,
    },

    {
      id: 7,
      feature: `${t("Premium")}`,
      silverPlan: `${t(
        "Starts from BDT 150 (Depends on age, policy term and coverage value)"
      )}`,
      goldPlan: `${t(
        "Starts from BDT 200 (Depends on age, policy term and coverage value)"
      )}`,
    },
  ];
  return (
    <div className="service-container pt-0">
      <div className="body-container secondary-container">
        <h1 class="title banner-title text-center">{t("cancelCarePlans")}</h1>
        <div className="pt-4">
          <div className="table-responsive">
            <table className="table table-striped table-light table-bordered">
              <thead>
                <tr className="table-tr">
                  <th
                    scope="col"
                    className="text-center align-middle table-th"
                    style={{ width: "20%" }}
                  ></th>
                  <th
                    scope="col"
                    className="text-center align-middle table-th"
                    style={{ width: "40%" }}
                  >
                    {t("Cancer Care Plan Silver")}
                  </th>
                  <th
                    scope="col"
                    className="text-center align-middle table-th"
                    style={{ width: "40%" }}
                  >
                    {t("Cancer Care Plan Gold")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {overviewLists.map((list) => (
                  <tr>
                    <td className="text-center align-middle table-text fw-bold fs-6">
                      {list?.feature}
                    </td>
                    <td className="text-center align-middle table-text fs-6">
                      {list?.silverPlan}
                    </td>
                    <td className="text-center align-middle table-text fs-6">
                      {list?.goldPlan}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="text-center align-middle table-text fw-bold fs-6">
                    {t("Action")}
                  </td>
                  <td className="text-center align-middle table-text">
                    <div className="mx-auto" style={{ width: "60%" }}>
                      <Link
                        to="https://adc.myguardianbd.com/en/microsite/glil/insurance-policy-details/184?channel=EASYLIFE"
                        target="_blank"
                        class="btn-book btns btn btn-primary form-table-btn fs-6"
                      >
                        {t("Buy Now")}
                      </Link>
                    </div>
                  </td>
                  <td className="text-center align-middle table-text">
                    <div className="mx-auto" style={{ width: "60%" }}>
                      <Link
                        to="https://adc.myguardianbd.com/en/microsite/glil/insurance-policy-details/185?channel=EASYLIFE"
                        target="_blank"
                        class="btn-book btns btn btn-primary form-table-btn fs-6"
                      >
                        {t("Buy Now")}
                      </Link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancerCarePlansTable;
