import React, { useRef } from 'react';
import '../plans.css';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import axios from 'axios';

function DashboardAddPlans() {
    const navigate = useNavigate();
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [contentBn, setContentBn] = useState('');
    const [addPlans, setAddPlans] = useState({
        title: '',
        image: '',
        vedioLink: '',
        titleBn: '',
    });

    const handleChange = (e) => {
        setAddPlans({ ...addPlans, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setAddPlans({ ...addPlans, image: e.target.files[0] });
    };

    // post
    const handleAddPlnas = (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('title', addPlans.title);
        formData.append('titleBn', addPlans.titleBn);
        formData.append('description', content);
        formData.append('descriptionBn', contentBn);
        formData.append('vedioLink', addPlans.vedioLink);
        formData.append('image', addPlans.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/plans`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    toast.success('Plans added successfully');
                    setAddPlans('');
                    navigate('/dashboard/plans/view-plans');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not plans added!!!!');
            });
    };

    return (
        <div className="home-content">
            <div className="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/plans/view-plans"
                    className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Details </span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center">Add Plans</h1>
                <form onSubmit={handleAddPlnas}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Plans Name
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addPlans.title}
                            name="title"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Plans Name (Bangla)
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addPlans.titleBn}
                            name="titleBn"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            onChange={handleImage}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Product Video Link
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addPlans.vedioLink}
                            name="vedioLink"
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={content}
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description(Bangla)
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={contentBn}
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default DashboardAddPlans;
