import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BankPayment from './BankPayment';
import Cards from './Cards';
import InternetBanking from './InternetBanking';
import MobileBanking from './MobileBanking';
import SureCashPayment from './SureCashPayment';
import UpayPayment from './UpayPayment';
import './payment.css';

function PaymentSubMenu() {
    const [banglatranslate, setBanglaTraslate] = useState();
    const [menuState, setMenuState] = useState(1);
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    const action = (idx) => {
        setMenuState(idx);
    };

    const mediaCornerSubmenus = [
        { submenuId: 1, submenuName: `${t('MOBILE BANKING')}` },
        { submenuId: 2, submenuName: `${t('CARDS')}` },
        { submenuId: 3, submenuName: `${t('INTERNET BANKING')}` },
        { submenuId: 4, submenuName: `${t('DIRECT BANK')}` },
    ];
    return (
        <div className="pt-5">
            <div className="body-container secondary-container">
                <div>
                    <h5
                        className="text-center fw-bold mb-4"
                        style={{
                            fontSize: '24px',
                            textTransform: 'uppercase',
                            color: '#000000',
                        }}
                    >
                        {t('PAYMENT WE SUPPORT')}
                    </h5>

                    <section className="payment-submenu mt-4">
                        {mediaCornerSubmenus.map((submenu) => (
                            <div
                                key={submenu.submenuId}
                                onClick={() => action(submenu.submenuId)}
                                className={
                                    menuState === submenu.submenuId
                                        ? 'active-submenu d-flex justify-content-center align-items-center'
                                        : 'default-submenu d-flex justify-content-center align-items-center'
                                }
                            >
                                {submenu.submenuName}
                            </div>
                        ))}
                    </section>

                    <section className="pb-5">
                        <div className={menuState === 1 ? 'd-block' : 'd-none'}>
                            <MobileBanking />
                        </div>
                        <div className={menuState === 2 ? 'd-block' : 'd-none'}>
                            <Cards />
                        </div>
                        <div className={menuState === 3 ? 'd-block' : 'd-none'}>
                            <InternetBanking />
                        </div>
                        <div className={menuState === 4 ? 'd-block' : 'd-none'}>
                            <BankPayment />
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default PaymentSubMenu;
