import React, { useEffect, useRef, useState } from 'react';
import Loading from '../../components/Loading/Loading';
import ListCard from '../../components/ListCard/ListCard';
import EMCImg1 from '../../assets/extendedManagement/Majedur Rashid Chowdhury.jpg';
import EMCImg2 from '../../assets/extendedManagement/Mahmud Afsar Ibne Hossain.jpg';
import EMCImg3 from '../../assets/extendedManagement/Syed Abdullah Zabir.jpg';
import EMCImg4 from '../../assets/extendedManagement/Amir Faisal Mohammad Zakaria.jpg';
import EMCImg5 from '../../assets/extendedManagement/Benzir Tazin Hossain.jpg';
import EMCImg6 from '../../assets/extendedManagement/Md. Aminul Islam.jpg';
import EMCImg7 from '../../assets/extendedManagement/Mr. Abdul Halim.jpg';
import EMCImg9 from '../../assets/extendedManagement/Fasihul Mostofa.jpg';
import EMCImg10 from '../../assets/extendedManagement/Mashfiqur Rahman.jpg';
import EMCImg11 from '../../assets/extendedManagement/Major Mohammad Siraj Sikder (Rtd).jpg';
import EMCImg13 from '../../assets/extendedManagement/Mohammad Nurul Absar.jpg';
import EMCImg14 from '../../assets/extendedManagement/Palash Lodh.jpg';
import EMCImg15 from '../../assets/extendedManagement/Sanjif Rahman.jpg';
import EMCImg16 from '../../assets/extendedManagement/Iftakher Ahmed.jpg';
import EMCImg17 from '../../assets/extendedManagement/Nowrin Islam.jpg';
import defaultMan from '../../assets/extendedManagement/Untitled-13.jpg';
import defaultWoman from '../../assets/extendedManagement/Untitled-14.jpg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import ExtendedManagementCommitteeDetails from './ExtendedManagementCommitteeDetails';

const newAllTeam = [
    {
        _id: '01',
        name: 'Majedur Rashid Chowdhury',
        designation: 'Executive Vice President, Finance & Accounts Department',
        imageURL: EMCImg1,
        description: '',
    },
    {
        _id: '02',
        name: 'Mahmud Afsar Ibne Hossain',
        designation: 'Executive Vice President, Group Insurance Department',
        imageURL: EMCImg2,
        description: '',
    },
    {
        _id: '03',
        name: 'Syed Abdullah Zabir',
        designation: 'Consultant, Underwriting & Policy Servicing Department',
        imageURL: EMCImg3,
        description: '',
    },
    {
        _id: '04',
        name: 'Amir Faisal Mohammad Zakaria',
        designation:
            'Executive Vice President, Information Technology Department',
        imageURL: EMCImg4,
        description: '',
    },
    {
        _id: '05',
        name: 'Benzir Tazin Hossain',
        designation: 'Senior Vice President, Audit & Compliance',
        imageURL: EMCImg5,
        description: '',
    },
    {
        _id: '06',
        name: 'Md. Aminul Islam',
        designation: 'Senior Vice President, Retail Insurance Business',
        imageURL: EMCImg6,
        description: '',
    },
    {
        _id: '07',
        name: 'Mr. Abdul Halim',
        designation: 'Senior Vice President, Micro Insurance Department',
        imageURL: EMCImg7,
        description: '',
    },
    {
        _id: '08',
        name: 'Dr. Zubair Ahmed',
        designation: 'Senior Vice President, Claims Department',
        imageURL: defaultMan,
        description: '',
    },
    {
        _id: '09',
        name: 'Fasihul Mostofa',
        designation: 'Senior Vice President, Digital & ADC Department',
        imageURL: EMCImg9,
        description: '',
    },
    {
        _id: '10',
        name: 'Mashfiqur Rahman',
        designation: 'Senior Vice President, Bancassurance Department',
        imageURL: EMCImg10,
        description: '',
    },
    {
        _id: '11',
        name: 'Major Mohammad Siraj Sikder (Rtd)',
        designation:
            'Senior Vice President, Administration & Procurement Department',
        imageURL: EMCImg11,
        description: '',
    },
    {
        _id: '12',
        name: 'Zafar Ahmed',
        designation: 'Senior Vice President, Retail Insurance Business',
        imageURL: defaultMan,
        description: '',
    },
    {
        _id: '13',
        name: 'Mohammad Nurul Absar',
        designation:
            'Vice President, Actuarial Functions & Reinsurance Department',
        imageURL: EMCImg13,
        description: '',
    },
    {
        _id: '14',
        name: 'Palash Lodh',
        designation: 'Vice President, Customer Service Department',
        imageURL: EMCImg14,
        description: '',
    },
    {
        _id: '15',
        name: 'Sanjif Rahman',
        designation: 'Vice President, Human Resources & Training',
        imageURL: EMCImg15,
        description: '',
    },
    {
        _id: '16',
        name: 'Iftakher Ahmed',
        designation: 'Vice President, Group Insurance Department',
        imageURL: EMCImg16,
        description: '',
    },
    {
        _id: '17',
        name: 'Nowrin Islam',
        designation: 'Vice President, Marketing & Communications Department',
        imageURL: EMCImg17,
        description: '',
    },
];

function ExtendedManagementList() {
    const [newAllTeams, setAllTeams] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState(8);
    const [pageCount, setPageCount] = useState(1);
    const [addShow, setAddShow] = useState(false);
    const [showTeam, setShowTeam] = useState(null);
    const [banglatranslate, setBanglaTraslate] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    const handleAddClose = () => setAddShow(false);
    const handleAddShow = () => setAddShow(true);

    // pagination
    function handlePageClick(e) {
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/extend-management`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setAllTeams(data?.data?.extenedManagementTeamLists);
                setIsLoading(false);
            });
    }
    return (
        <>
            <div className="body-container secondary-container message-contaner banner-container">
                <div>
                    <h1 className=" title text-center list-titles">
                        {t('extendManagementCommitteeTitle')}
                    </h1>
                </div>

                {newAllTeams?.length === 0 ? (
                    <div className="min-vh-100 d-flex align-items-center justify-content-center">
                        No Management Team Is Available
                    </div>
                ) : (
                    <>
                        <div className="grid-list">
                            {newAllTeams?.map((newTeam) => (
                                <ListCard
                                    newTeam={newTeam}
                                    key={newTeam._id}
                                    handleShow={handleAddShow}
                                    setShowTeam={setShowTeam}
                                    banglatranslate={banglatranslate}
                                />
                            ))}
                        </div>

                        {/* <div className="" style={{ marginTop: "6rem" }}>
              <ReactPaginate
                breakLabel="......."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                marginPagesDisplayed={2}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
              />
            </div> */}
                    </>
                )}
            </div>

            {/* <ExtendedManagementCommitteeDetails
                show={addShow}
                handleClose={handleAddClose}
                showTeam={showTeam}
                banglatranslate={banglatranslate}
            /> */}
        </>
    );
}

export default ExtendedManagementList;
