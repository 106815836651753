import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading/Loading';
import { Breadcrumb } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';

function NoticeBoard() {
    const { t } = useTranslation();

    const [search, setSearch] = useState('');

    const [banglatranslate, setBanglatranslate] = useState('');

    const [noticeData, setNoticeData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        //get the current language stored in the local storage

        let currentLang = localStorage.getItem('lang');

        i18n.changeLanguage(currentLang);

        setBanglatranslate(currentLang);
    }, []);

    useEffect(() => {
        getAllFormLibrary();
    }, []);

    function getAllFormLibrary() {
        const url = `${process.env.REACT_APP_host}/api/v1/notice`;

        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())

            .then((data) => {
                setNoticeData(data?.data?.noticeBoardList);

                setIsLoading(false);
            });
    }

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    return (
        <div>
            <div className="career-banner pt-4 ">
                <div className="body-container secondary-container">
                    <Breadcrumb>
                        <Breadcrumb.Item className="faq-link">
                            Home
                        </Breadcrumb.Item>

                        <Breadcrumb.Item active className="faq-link">
                            Notice Board
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className="mt-5 body-container secondary-container text-center">
                    <div className="career-banner-content">
                        <h1>{t('noticeboard')}</h1>

                        <p>
                            {t(
                                'Explore our latest updates on policy changes, regulatory announcements, and company news.'
                            )}
                        </p>
                    </div>
                </div>
            </div>

            <div className="service-container pt-5 bg-white">
                <div className="body-container secondary-container">
                    <div className="input-group mb-5">
                        <span
                            className="input-group-text search-icon"
                            id="search"
                        >
                            <BsSearch />
                        </span>

                        <input
                            onChange={(e) => setSearch(e.target.value)}
                            type="text"
                            className="form-control search-field"
                            placeholder={t('search')}
                            aria-label="sarech"
                            aria-describedby="search"
                        />
                    </div>

                    <div className="mt-3">
                        <table className="custom-table w-100">
                            <thead>
                                <tr>
                                    <th>{t('fileName')}</th>

                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>

                            {noticeData?.length === 0 ? (
                                <p
                                    style={{
                                        color: '#000',
                                    }}
                                >
                                    No Found
                                </p>
                            ) : (
                                <tbody>
                                    {noticeData

                                        ?.filter((item) => {
                                            return search.toLowerCase() === ''
                                                ? item
                                                : item?.noticeName
                                                      ?.toLowerCase()
                                                      .includes(search);
                                        })

                                        ?.map((data) => (
                                            <tr key={data?.id}>
                                                <td style={{ width: '80%' }}>
                                                    {banglatranslate === 'bn'
                                                        ? data?.noticeNameBn
                                                        : data?.noticeName}
                                                </td>

                                                <td style={{ width: '20%' }}>
                                                    <div className="">
                                                        <a
                                                            href={`${data?.noticeLink}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="btn-book btns btn btn-primary form-table-btn"
                                                        >
                                                            {t('View Details')}
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NoticeBoard;
