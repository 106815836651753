import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
function DashboardNoticeBoardEdit({
    handleEditClose,
    editModal,
    updatenotice,
    getPaginationList,
    setUpdatenotice,
}) {
    const handleChange = (e) => {
        setUpdatenotice({
            ...updatenotice,
            [e.target.name]: e.target.value,
        });
    };

    const handleEditFormLibrary = (e) => {
        e.preventDefault();
        handleEditClose();

        const updatedNotice = {
            noticeName: updatenotice?.noticeName,
            noticeNameBn: updatenotice?.noticeNameBn,
            noticeLink: updatenotice?.noticeLink,
        };

        fetch(
            `${process.env.REACT_APP_host}/api/v1/notice/${updatenotice?._id}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedNotice),
            }
        )
            .then((res) => {
                if (res.status) {
                    console.log(res);
                    getPaginationList();
                    toast.success('Form Library updated successfully!');
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error('FormLibrary not updated!');
            });
    };
    return (
        <Modal
            show={editModal}
            onHide={handleEditClose}
            scrollable={true}
            //   size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Edit Form Library
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleEditFormLibrary}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Form Name</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            defaultValue={updatenotice?.noticeName}
                            name="noticeName"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Form Name(Bangla)
                        </label>
                        <input
                            type="text"
                            name="noticeNameBn"
                            onChange={handleChange}
                            defaultValue={updatenotice?.noticeNameBn}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Form Link</label>
                        <input
                            type="text"
                            name="noticeLink"
                            onChange={handleChange}
                            defaultValue={updatenotice?.noticeLink}
                            className="form-control"
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardNoticeBoardEdit;
