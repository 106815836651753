import React from "react";
import LocateBranchList from "./LocateBranchList";
import Loading from "../../components/Loading/Loading";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { Button } from "react-bootstrap";
import DashboardDistrictName from "../Dashboard/DashboardLocateBranch/DashboardDistrictName";
import HospitalList from "../PreferredHospital/HospitalList/HospitalList";

const officeCategorys = [
  {
    id: 1,
    name: "Area Office",
  },
  {
    id: 2,
    name: "Branch Office",
  },
  {
    id: 3,
    name: "Regional Office",
  },
];

function LocateBranchTable() {
  const [locateBranchLists, setLocateBranchLists] = useState([]);
  const [getDivision, setGetDivision] = useState(null);
  const [officeCategory, setOfficeCategory] = useState("");
  const [division, setDivision] = useState("");
  const [district, setDistrict] = useState("");
  const [area, setArea] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const currentPage = useRef();

  useEffect(() => {
    currentPage.current = 1;
    getBranchList();
    resetBranch();
  }, []);

  useEffect(() => {
    currentPage.current = 1;
    getNameByDivision();
  }, [division]);

  // get division district
  const url = `${process.env.REACT_APP_host}/api/v1/division-district`;
  const { data: allDivisionDistrict = [] } = useQuery({
    queryKey: [" allDivisionDistrict"],
    queryFn: async () => {
      try {
        const res = await fetch(url);
        const data = await res.json();
        return data?.data;
      } catch (err) {
        console.log(err);
      }
    },
  });

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  function getBranchList() {
    fetch(`${process.env.REACT_APP_host}/api/v1/all-branch`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setLocateBranchLists(data?.data?.locateBranchList);
        setIsLoading(false);
      });
  }

  // get by name division
  function getNameByDivision() {
    const url = `${process.env.REACT_APP_host}/api/v1/division-district?division=${division}`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          setGetDivision(data?.data);
        }
      });
  }

  function resetBranch() {
    setOfficeCategory("");
    setDivision("");
    setDistrict("");
    setArea("");
  }

  const lists = locateBranchLists?.filter(
    (items) =>
      items.officeCategory === officeCategory && items?.district === district
  );

  const uniqueItems = Array.from(new Set(lists?.map((item) => item?.area))).map(
    (name) => {
      return lists?.find((item) => item?.area === name);
    }
  );

  return (
    <div className="service-container bg-white pt-0">
      <div className="body-container secondary-container">
        <div className="search-location mb-4">
          <div>
            <select
              value={officeCategory}
              onChange={(e) => setOfficeCategory(e.target.value)}
              className="form-select location-select"
            >
              <option value="" selected>
                Office Category
              </option>
              {officeCategorys?.map((officeCate) => (
                <option value={officeCate?.name} key={officeCate.id}>
                  {officeCate?.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              name="division"
              class="form-select location-select"
              value={division}
              onChange={(e) => setDivision(e.target.value)}
            >
              <option value="" selected>
                Choose division
              </option>
              {allDivisionDistrict?.map((divisions) => (
                <option key={divisions._id}>{divisions?.division}</option>
              ))}
            </select>
          </div>
          <div>
            <select
              name="district"
              class="form-select location-select"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            >
              <option value="" selected>
                Choses district
              </option>
              {getDivision?.map((getUpdateDivision) => (
                <DashboardDistrictName
                  getUpdateDivision={getUpdateDivision}
                  key={getUpdateDivision?._id}
                />
              ))}
            </select>
          </div>
          <div>
            <select
              value={area}
              onChange={(e) => setArea(e.target.value)}
              className="form-select location-select"
            >
              <option value="" selected>
                Area
              </option>
              {uniqueItems?.map((area) => (
                <option value={area?.area} key={area._id}>
                  {area?.area}
                </option>
              ))}
            </select>
          </div>
          <div>
            <Button onClick={resetBranch} className="btn-search btn-danger">
              Reset
            </Button>
          </div>
        </div>

        {locateBranchLists?.length === 0 ? (
          <div className="min-vh-100 d-flex align-items-center justify-content-center">
            No Branch Avaliable
          </div>
        ) : (
          <>
            <div className="mt-4">
              <div className="table-responsive">
                <table className="table table-light table-bordered">
                  <thead>
                    <tr className="table-tr">
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        SL
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        Office Category
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        Office Name
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        Division
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        District
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        Area
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th w-25"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        Contact Person
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        View on Map
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {locateBranchLists
                      ?.filter((item) => {
                        return officeCategory === "" &&
                          division === "" &&
                          district === "" &&
                          area === ""
                          ? item
                          : item?.officeCategory.includes(officeCategory) &&
                              item?.division.includes(division) &&
                              item?.district.includes(district) &&
                              item?.area.includes(area);
                      })
                      ?.map((branchList, i) => (
                        <LocateBranchList
                          branchList={branchList}
                          key={branchList?._id}
                          idx={i}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default LocateBranchTable;
