import React, { useState } from "react";
import "./bmiCalculator.css";

import { AiFillCheckCircle } from "react-icons/ai";

function BMICaculatorDetails({ t }) {
  const [weightKg, setWeightKg] = useState("");
  const [feet, setFeet] = useState("");
  const [inches, setInches] = useState("");
  const [heightCm, setHeightCm] = useState(null);
  const [bmi, setBmi] = useState(null);

  const handleFeetChange = (e) => {
    const newFeet = parseInt(e.target.value);
    setFeet(newFeet);
    const totalInches = newFeet * 12 + inches;
    const totalCm = totalInches * 2.54;
    setHeightCm(totalCm);
  };

  const handleInchesChange = (e) => {
    const newInches = parseInt(e.target.value);
    setInches(newInches);
    const totalInches = feet * 12 + newInches;
    const totalCm = totalInches * 2.54;
    setHeightCm(totalCm);
  };

  console.log(heightCm);

  const calculateBMI = () => {
    const bmiValue = (weightKg / Math.pow(heightCm / 100, 2)).toFixed(2);
    setBmi(bmiValue);
  };

  const calculateClear = () => {
    setWeightKg("");
    setFeet("");
    setHeightCm("");
    setBmi("");
    setInches("");
  };
  return (
    <div className="body-container secondary-container banner-container">
      <div>
        <div className="row g-5">
          <div className="col-12 col-md-5">
            <div className="mb-3 ">
              <label className="mb-2 fw-bold">{t("Height (feet)")}:</label>
              <input type="number" className="form-control" value={feet} onChange={handleFeetChange} required />
            </div>
            <div className="mb-3">
              <label className="mb-2 fw-bold">{t("Height (inches)")}:</label>
              <input type="number" className="form-control" value={inches} onChange={handleInchesChange} />
            </div>
            <div className="mb-3">
              <label className="mb-2 fw-bold">{t("Height (cm)")}:</label>
              <input type="number" className="form-control" value={heightCm} onChange={(e) => setHeightCm(e.target.value)} disabled />
            </div>
            <div className="mb-3 ">
              <label className="mb-2 fw-bold">{t("Weight (kg)")}:</label>
              <input type="number" value={weightKg} className="form-control" onChange={(e) => setWeightKg(e.target.value)} required />
            </div>
            <div className="d-flex align-items-center gap-4 mt-5">
              <button onClick={calculateBMI} className="btns btn btn-primary calculate-btn">
                {t("Calculate BMI")}
              </button>
              <button onClick={calculateClear} className=" btns btn btn-danger ">
                {t("Clear")}
              </button>
            </div>
            <div className="mt-5">
              <div className=" d-flex align-items-center gap-2">
                <p className="mb-2 fw-bold">{t("Your BMI is")}: </p>
                <p className="">
                  <input type="number" className="form-control " disabled value={bmi} />
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7 h-100">
            <div className="calculate-card">
              <h5 className="bmi-card-title mb-4 fw-bold ">{t("Ideal BMI for Men & Women")}</h5>

              <div className="banner-text mb-2 d-flex gap-2">
                <div>
                  <AiFillCheckCircle />
                </div>
                <div>{t("Under 18.5 - you are very underweight and possibly malnourished")}</div>
              </div>
              <div className="banner-text mb-2 d-flex gap-2">
                <div>
                  <AiFillCheckCircle />
                </div>
                <div>{t("18.5 to 24.9 - you have a healthy weight range for young and middle-aged adults")}</div>
              </div>
              <div className="banner-text mb-2 d-flex gap-2">
                <div>
                  <AiFillCheckCircle />
                </div>
                <div> {t("25.0 to 29.9 - you are over weight")}.</div>
              </div>
              <div className="banner-text d-flex gap-2">
                <div>
                  <AiFillCheckCircle />
                </div>
                <div> {t("Over 30 - you are obese")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BMICaculatorDetails;
