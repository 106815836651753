import React from "react";

function OurCustomerCard({ ourClient }) {
  const { numberClient, desc, img } = ourClient;
  return (
    <div className="client-card">
      <div className="client-card-img">
        <img src={img} />
      </div>
      <div className="client-card-content">
        <h6>{numberClient}</h6>
        <p>{desc}</p>
      </div>
    </div>
  );
}

export default OurCustomerCard;
