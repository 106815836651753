import React from 'react';
import { Accordion } from 'react-bootstrap';


const ProductFAQ = ({t}) => {

    const faqContents = [
        {
            id: 1,
            title: `${t("easylifeFAQProductT1")}`,
            des: `${t("easylifeFAQProductD1")}`,
        },
        {
            id: 2,
            title: `${t("easylifeFAQProductT2")}`,
            des: `${t("easylifeFAQProductD2")}`,
        },
        {
            id: 3,
            title: `${t("easylifeFAQProductT3")}`,
            des: `${t("easylifeFAQProductD3")}`,
        },
        {
            id: 4,
            title: `${t("easylifeFAQProductT4")}`,
            des: `${t("easylifeFAQProductD4")}`,
        },
        {
            id: 5,
            title: `${t("easylifeFAQProductT5")}`,
            des: `${t("easylifeFAQProductD5")}`,
        },
        {
            id: 6,
            title: `${t("easylifeFAQProductT6")}`,
            des: `${t("easylifeFAQProductD6")}`,
        },
    ];
    return (
        <div>
            <Accordion defaultActiveKey="0">
                {faqContents.map((faq, i) => (
                    <Accordion.Item eventKey={i} key={faq.id}>
                        <Accordion.Header>
                            {faq.id}. {faq.title}
                        </Accordion.Header>
                        <Accordion.Body>{faq.des}</Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
};

export default ProductFAQ;