import React, { useEffect, useState } from 'react';
import './WhyGuardianLife.css';
import BannerImg from '../../../assets/image/bancassurance-banner-2.jpg';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Loading from '../../../components/Loading/Loading';

const WhyGuardianLife = () => {
    const [updateIntro, setUpdateIntro] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isShowMore, setShowMore] = useState(false);
    const [banglatranslate, setBanglaTraslate] = useState();

    const handleShow = () => {
        setShowMore(!isShowMore);
    };

    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_host}/api/v1/bancassurance-why`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setUpdateIntro(data?.data?.bancassuranceWhyList[0]);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }
    return (
        <div className="service-container pb-0">
            <div className="body-container secondary-container">
                <h1 className="title title-service">
                    {banglatranslate === 'bn'
                        ? updateIntro?.titleBn
                        : updateIntro?.title}
                </h1>
                <div className="plans-banner-contents section-margin-top">
                    <div className="right-img">
                        <img src={updateIntro?.imageURL} alt="" />
                    </div>
                    <div className="left-content">
                        <p className="text banner-text pt-0">
                            {!isShowMore
                                ? banglatranslate === 'bn'
                                    ? updateIntro?.descriptionBn.slice(0, 300) +
                                      '...'
                                    : updateIntro?.description.slice(0, 300) +
                                      '...'
                                : banglatranslate === 'bn'
                                ? updateIntro?.descriptionBn
                                : updateIntro?.description}
                        </p>
                        <div className="d-flex align-items-center gap-3 mt-4">
                            <Button
                                className="btn-book btns"
                                onClick={handleShow}
                            >
                                {isShowMore
                                    ? `${t('readless')}`
                                    : `${t('readmore')}`}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyGuardianLife;
