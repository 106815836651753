import React, { useEffect } from 'react';
import "./CoreValueCard.css"
// import Icon from "../../assets/image/service.png";
import { GiShakingHands } from "react-icons/gi";
import { FaPeopleCarry } from "react-icons/fa";
import { BsPeopleFill } from "react-icons/bs";
import { MdOutlineHandshake, MdCleanHands } from "react-icons/md";
import { RiComputerFill } from "react-icons/ri";
import OurServiceCard from '../Home/OurService/OurServiceCard';
import { useTranslation } from "react-i18next";
import i18n from "i18next";


function CoreValueCard() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const coreValueItems = [
    {
      id: 1,
      title: `${t("coreValueCardT1")}`,
      dec: `All members of the Guardian Life Team are accustomed to performing their duties with the highest levels of integrity and professionalism.`,
      icon: <GiShakingHands className="card-icon" />,
    },
    {
      id: 2,
      title: `${t("coreValueCardT2")}`,
      dec: `Guardian Life aspires to build and maintain a lifelong relationship with their clients by providing top class insurance services to their clients are rest assured.`,
      icon: <FaPeopleCarry className="card-icon" />,
    },
    {
      id: 3,
      title: `${t("coreValueCardT3")}`,
      dec: `Guardian Life is a customer focused company where the customers are the topmost priority. We produce the most innovative products and offer solutions customized to our client’s needs and life goals.`,
      icon: <BsPeopleFill className="card-icon" />,
    },
    {
      id: 4,
      title: `${t("coreValueCardT4")}`,
      dec: `Being professionals we strongly believe in respecting one another.`,
      icon: <MdOutlineHandshake className="card-icon" />,
    },
    {
      id: 5,
      title: `${t("coreValueCardT5")}`,
      dec: ` We aspire to be regarded as the best practitioners by all our Stakeholders; customers, staff, regulators and the community and will keep working diligently until we are successful in doing so.`,
      icon: <MdCleanHands className="card-icon" />,
    },
    {
      id: 6,
      title: `${t("coreValueCardT6")}`,
      dec: `Ut enim ad minim veniam, quis nostrud quis exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat`,
      icon: <RiComputerFill className="card-icon" />,
    },
  ];
  return (
    <div className="service-container pt-1">
      <div className="body-container secondary-container">
        <div className="service-grid">
          {coreValueItems?.map((ourServiceItem) => (
            <OurServiceCard
              ourServiceItem={ourServiceItem}
              key={ourServiceItem.id}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CoreValueCard