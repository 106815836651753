import React from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { getImageUrl } from "../../../../hooks/imageUpload";
import JoditEditor from "jodit-react";
import { useRef } from "react";
import { useState } from "react";

function DashboardSubCatagoryPlansEditModal({
  editPlans,
  setEditPlans,
  editPlansModal,
  handleEditClose,
  getAllDataFetch,
}) {
  const editor = useRef(null);
  const [content, setContent] = useState(editPlans?.subCategoryDescription);
  const [benefit, setBenefit] = useState(editPlans?.subCategoryBenefit);

  const handleChange = (e) => {
    setEditPlans({ ...editPlans, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    setEditPlans({ ...editPlans, subCategoryImageURL: e.target.files[0] });
  };

  const handleUpdateAction = async (e) => {
    e.preventDefault();
    handleEditClose();

    getImageUrl(editPlans?.subCategoryImageURL)
      .then((imageData) => {
        const updateSubCategoryPlans = {
          // plansId: editPlans.plansId,

          plansCategorysId: editPlans.plansCategorysId,
          subCategorytitle: editPlans.subCategorytitle,
          subCategoryVedioLink: editPlans.subCategoryVedioLink,
          keyFeature: editPlans?.keyFeature,
          subCategoryImageURL: imageData,
          subCategoryDescription: content,
          // subCategoryBenefit: benefit,
        };

        console.log(updateSubCategoryPlans);

        fetch(
          `${process.env.REACT_APP_host}/api/v1/sub-category-plans/${editPlans?._id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updateSubCategoryPlans),
          }
        )
          .then((res) => {
            if (res.status) {
              getAllDataFetch();
              console.log(res);
              toast.success("Plans Sub Catagory updated successfully");
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("Not a update Plans Sub Catagory!!!!");
          });
      })
      .catch((error) => {
        toast.error("Not a update Plans Sub Catagory!!!!");
        console.log(error);
      });
  };
  return (
    <Modal
      show={editPlansModal}
      onHide={handleEditClose}
      scrollable={true}
      //   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          Update Category Plans
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleUpdateAction}>
          <div className="mb-3">
            <label className="form-label  fw-bold">Category Title</label>
            <input
              type="text"
              onChange={handleChange}
              defaultValue={editPlans?.subCategorytitle}
              name="subCategorytitle"
              className="form-control"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label  fw-bold">Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImage}
              name="image"
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label className="form-label  fw-bold">Product Vedio Link</label>
            <input
              type="text"
              onChange={handleChange}
              defaultValue={editPlans?.subCategoryVedioLink}
              name="subCategoryVedioLink"
              className="form-control"
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label fw-bold">Introduction</label>

            <JoditEditor
              ref={editor}
              value={editPlans?.subCategoryDescription}
              onChange={(newContent) => {
                setContent(newContent);
              }}
            />
          </div>
          {/* <div className="mb-3">
            <label className="form-label fw-bold">Benefits</label>

            <JoditEditor
              ref={editor}
              value={editPlans?.subCategoryBenefit}
              onChange={(newContent) => {
                setBenefit(newContent);
              }}
            />
          </div> */}

          <button type="submit" className="btn btn-primary px-4">
            Update
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default DashboardSubCatagoryPlansEditModal;
