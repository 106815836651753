import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

function DashboardFormLibrary() {
    const [addFormLibrary, setAddFormLibrary] = useState({
        formName: '',
        formNameBn: '',
        formLink: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        setAddFormLibrary({
            ...addFormLibrary,
            [e.target.name]: e.target.value,
        });
    };

    const handleAddFormLibrary = async (e) => {
        e.preventDefault();

        const newFormLibrary = {
            formName: addFormLibrary?.formName,
            formNameBn: addFormLibrary?.formNameBn,
            formLink: addFormLibrary?.formLink,
        };

        fetch(`${process.env.REACT_APP_host}/api/v1/form-library`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newFormLibrary),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data?.status) {
                    toast.success('Form Library added successfully');
                    setAddFormLibrary('');
                    navigate('/dashboard/all-form-library');
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('Not added successfully');
            });
    };
    return (
        <div className="home-content">
            <div class="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/all-form-library"
                    class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Form</span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center">
                    {' '}
                    Add Form Library
                </h1>
                <form onSubmit={handleAddFormLibrary}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Form Name</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addFormLibrary.formName}
                            name="formName"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Form Name(Bangla)
                        </label>
                        <input
                            type="text"
                            name="formNameBn"
                            onChange={handleChange}
                            value={addFormLibrary.formNameBn}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Form Link</label>
                        <input
                            type="text"
                            name="formLink"
                            placeholder="https://example.com"
                            onChange={handleChange}
                            value={addFormLibrary.formLink}
                            className="form-control"
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default DashboardFormLibrary;
