import React from 'react';
import { useRef } from 'react';
import ReactPlayer from 'react-player';

const ADCVideoSection = ({ data, t }) => {
    const playerRef = useRef(null);

    // Check if data?.vedioLink contains only empty strings
    if (!data?.vedioLink || data?.vedioLink.every((link) => link === '')) {
        return <></>;
    }

    return (
        <div className="body-container secondary-container banner-container">
            <h1 class="title title-service mb-5">
                {t('easylifeADCProductVideoTitle')}
            </h1>
            <div className="d-flex gap-3 justify-content-center">
                {data?.vedioLink?.map((v, i) => (
                    <div
                        key={i}
                        className="media-container-video media-container-shadow"
                        style={{ width: data?.length === 1 ? '50%' : '100%' }}
                    >
                        <iframe
                            height="350"
                            src={v}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen="true"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            class="w-100"
                        ></iframe>

                        {/* <ReactPlayer className="w-100" ref={playerRef} url={v} controls={true} /> */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ADCVideoSection;
