import React from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";

function DashboardLeadsEdit({ handleEditClose, editModal, updateLeads, getPaginationList, setUpdateLeads }) {
  const handleChange = (e) => {
    setUpdateLeads({
      ...updateLeads,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditLeads = (e) => {
    e.preventDefault();
    handleEditClose();

    const updatedLeads = {
      name: updateLeads?.name,
      submitDate: updateLeads?.submitDate,
      birthDate: updateLeads?.birthDate,
      gender: updateLeads?.gender,
      interestedSer: updateLeads?.interestedSer,
      phoneNumber: updateLeads?.phoneNumber,
      email: updateLeads?.email,
    };
    console.log(updatedLeads);

    fetch(`${process.env.REACT_APP_host}/api/v1/leads/${updateLeads?._id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedLeads),
    })
      .then((res) => {
        if (res.status) {
          console.log(res);
          getPaginationList();
          toast.success("Leads updated successfully!");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Leads not updated!");
      });
  };
  return (
    <Modal
      show={editModal}
      onHide={handleEditClose}
      scrollable={true}
      //   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="book-title ps-2" id="contained-modal-title-vcenter">
          Edit Leads
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <form onSubmit={handleEditLeads}>
          <div className="mb-3">
            <label className="form-label  fw-bold">
              Name <sup className="text-warning">*</sup>
            </label>
            <input type="text" onChange={handleChange} defaultValue={updateLeads?.name} name="name" className="form-control" required />
          </div>

          <div className="row">
            <div className="mb-3 col-6">
              <label className="form-label fw-bold">
                Submit Date <sup className="text-warning">*</sup>
              </label>
              <input type="date" name="submitDate" onChange={handleChange} defaultValue={updateLeads?.submitDate} className="form-control" required />
            </div>
            <div className="mb-3 col-6">
              <label className="form-label fw-bold">
                Birth Date <sup className="text-warning">*</sup>
              </label>
              <input type="date" name="birthDate" onChange={handleChange} defaultValue={updateLeads?.birthDate} className="form-control" required />
            </div>
            <div className="mb-3 col-6">
              <label className="form-label fw-bold">
                Gender <sup className="text-warning">*</sup>
              </label>
              <select onChange={handleChange} defaultValue={updateLeads?.gender} name="gender" class="form-select" required>
                <option value="" selected>
                  Please Select
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="mb-3 col-6">
              <label className="form-label fw-bold">
                Interested Service <sup className="text-warning">*</sup>
              </label>
              <select onChange={handleChange} name="interestedSer" defaultValue={updateLeads?.interestedSer} class="form-select" required>
                <option value="" selected>
                  Please Select
                </option>
                <option value="Saving">Saving</option>
                <option value="Early Cash">Early Cash</option>
                <option value="Children">Children</option>
                <option value="Retirement">Retirement</option>
                <option value="Investment">Investment</option>
                <option value="Team Life">Team Life</option>
                <option value="Supplement Benefits">Supplement Benefits</option>
              </select>
            </div>

            <div className="mb-3 col-6">
              <label className="form-label  fw-bold">
                Phone Number <sup className="text-warning">*</sup>
              </label>
              <input type="tel" onChange={handleChange} defaultValue={updateLeads?.phoneNumber} name="phoneNumber" className="form-control" required />
            </div>
            <div className="mb-3 col-6">
              <label className="form-label  fw-bold">Email</label>
              <input type="email" onChange={handleChange} defaultValue={updateLeads?.email} name="email" className="form-control" />
            </div>
          </div>

          <button type="submit" className="btn btn-primary px-4">
            Save
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default DashboardLeadsEdit;
