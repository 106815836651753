import React from "react";
import TaxRebateCalculatorAsideBar from "../../components/AsideBar/TaxRebateCalculatorAsideBar";
import { Button } from "react-bootstrap";

function TaxRebateBannerText({ bannerItem }) {
  return (
    <>
      <div className="left-content">
        <h1 className="title banner-title">{bannerItem?.title}</h1>
        <p className="text banner-text">{bannerItem?.desc}</p>
        <Button className=" btns taxtRebateBtn">
          {["end"].map((placement, idx) => (
            <TaxRebateCalculatorAsideBar
              key={idx}
              placement={placement}
              name={placement}
            />
          ))}
        </Button>
      </div>
      <div className="right-img">
        <img src={bannerItem?.img} alt="" />
      </div>
    </>
  );
}

export default TaxRebateBannerText;
