import React from 'react';
import { useRef } from 'react';
import { Modal } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ReactPlayer from 'react-player';

function DashboardPlansViewModal({
    showPlans,
    viewPlansModal,
    handleViewClose,
    getAllDataFetch,
}) {
    const playerRef = useRef(null);

    return (
        <Modal show={viewPlansModal} onHide={handleViewClose} scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Plans Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card style={{ width: '100%' }}>
                    <Card.Img
                        variant="top"
                        style={{ height: '300px' }}
                        src={showPlans?.imageURL}
                    />
                    <Card.Body>
                        <Card.Title>{showPlans?.title}</Card.Title>
                        <Card.Text>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: showPlans?.description,
                                }}
                            ></span>
                        </Card.Text>
                        <p className="mt-4">
                            <strong>Vedio Link:</strong>
                            <span> {showPlans?.vedioLink}</span>
                        </p>
                        <div className="mt-2">
                            <ReactPlayer
                                className="w-100"
                                ref={playerRef}
                                url={showPlans?.vedioLink}
                                controls={true}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardPlansViewModal;
