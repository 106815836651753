import React from 'react';
import toast from 'react-hot-toast';
import { BiEdit } from 'react-icons/bi';
import { BsFillEyeFill } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';

function DashboardBancassuranceBenfitsTable({
    newBenfits,
    handleEditShow,
    setUpdateBenfits,
    setViewBenfits,
    getPaginationList,
    handleViewShow,
    idx,
}) {
    const { _id, title, description, imageURL } = newBenfits;

    // console.log(newService);

    const handleDeleteAction = (id) => {
        const agree = window.confirm(
            `Are you sure, you want to delete: "${title}?"`
        );
        if (agree) {
            fetch(
                `${process.env.REACT_APP_host}/api/v1/bancassurance-benfits/${id}`,
                {
                    method: 'DELETE',
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    if (data.status) {
                        getPaginationList();
                        toast.success(`"${title}" is Deleted !`);
                    }
                });
        }
    };

    return (
        <>
            <tr>
                <td className="text-center align-middle table-text">
                    {idx + 1}
                </td>
                <td className="text-center align-middle table-text">
                    <div className="d-flex  align-items-center gap-2">
                        <div className="list-img">
                            <img src={imageURL} alt="blog" />
                        </div>
                    </div>
                </td>
                <td className="text-center align-middle table-text">{title}</td>
                <td className="text-center align-middle table-text">
                    <span
                        dangerouslySetInnerHTML={{
                            __html:
                                description?.length > 150
                                    ? description.slice(0, 150) + '...'
                                    : description,
                        }}
                    ></span>
                </td>

                <td className="text-center align-middle table-text">
                    <div className="d-flex align-items-center justify-content-center gap-2">
                        <button
                            onClick={() => {
                                handleViewShow();
                                setViewBenfits(newBenfits);
                            }}
                            className="btn btn-sm btn-primary table-btn fw-semibold d-flex align-items-center gap-1"
                        >
                            <BsFillEyeFill /> <span>View</span>
                        </button>
                        <button
                            onClick={() => {
                                handleEditShow();
                                setUpdateBenfits(newBenfits);
                            }}
                            className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center gap-1"
                        >
                            <BiEdit /> <span>Edit</span>
                        </button>
                        <button
                            onClick={() => handleDeleteAction(_id)}
                            className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center gap-1"
                        >
                            <MdDelete /> <span>Delete</span>
                        </button>
                    </div>
                </td>
            </tr>
        </>
    );
}

export default DashboardBancassuranceBenfitsTable;
