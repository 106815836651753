import React from "react";
import enTax from "../../assets/taxrebate/en_frame_with_text.jpg";
import bnTax from "../../assets/taxrebate/bn_frame_with_text.jpg";
import "./taxRebate.css";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import i18n from "i18next";
import { useState } from "react";

function TaxRebateDetailsBenefits() {
  const { t } = useTranslation();
  const [imageBn, setImageBn] = useState();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setImageBn(currentLang);
  }, []);
  return (
    <div className="service-container">
      <div className="body-container secondary-container">
        <h1 className="title title-service">
          {t("Investment sectors for tax rebate")}
        </h1>
        <div className="tax-benfit-img">
          <img src={imageBn === "bn" ? bnTax : enTax} />
        </div>
      </div>
    </div>
  );
}

export default TaxRebateDetailsBenefits;
