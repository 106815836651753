import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { getImageUrl } from '../../../hooks/imageUpload';
import { useRef } from 'react';
import { useState } from 'react';
import JoditEditor from 'jodit-react';

const ManagementEditModal = ({
    editModal,
    handleEditClose,
    updateTeam,
    setUpdateTeam,
    getPaginationList,
}) => {
    const editor = useRef(null);
    const [content, setContent] = useState(editModal?.description);
    const editorBn = useRef(null);
    const [contentBn, setContentBn] = useState(editModal?.descriptionBn);
    const handleEditChange = (e) => {
        setUpdateTeam({ ...updateTeam, [e.target.name]: e.target.value });
    };

    const handleEditImage = (e) => {
        setUpdateTeam({ ...updateTeam, image: e.target.files[0] });
    };

    const handleUpdateAction = async (e) => {
        e.preventDefault();
        handleEditClose();

        const formData = new FormData();

        formData.append('name', updateTeam?.name);
        formData.append('nameBn', updateTeam?.nameBn);
        formData.append('designation', updateTeam?.designation);
        formData.append('designationBn', updateTeam?.designationBn);
        formData.append('description', content);
        formData.append('descriptionBn', contentBn);
        formData.append('image', updateTeam?.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for (const value of formData.values()) {
            console.log(value);
        }

        axios
            .put(
                `${process.env.REACT_APP_host}/api/v1/management-team/${updateTeam?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    toast.success('Management Team updated successfully');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not updated!!!!');
            });
    };
    return (
        <Modal
            show={editModal}
            onHide={handleEditClose}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Edit Team
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction} className="row">
                    <div className="mb-3 col-12">
                        <label className="form-label">Name</label>
                        <input
                            type="text"
                            name="name"
                            onChange={handleEditChange}
                            defaultValue={updateTeam?.name}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Name(Bangla)</label>
                        <input
                            type="text"
                            name="nameBn"
                            onChange={handleEditChange}
                            defaultValue={updateTeam?.nameBn}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Designation</label>
                        <input
                            type="text"
                            name="designation"
                            onChange={handleEditChange}
                            defaultValue={updateTeam?.designation}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">
                            Designation(Bangla)
                        </label>
                        <input
                            type="text"
                            name="designationBn"
                            onChange={handleEditChange}
                            defaultValue={updateTeam?.designationBn}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-5 col-12">
                        <label className="form-label">Image</label>
                        <input
                            type="file"
                            onChange={handleEditImage}
                            name="image"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={updateTeam?.description}
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description(Bangla)
                        </label>

                        <JoditEditor
                            ref={editorBn}
                            value={updateTeam?.descriptionBn}
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ManagementEditModal;
