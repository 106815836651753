import React from "react";
import { toast } from "react-hot-toast";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

function PartnerLogoList({
  partnerLogo,
  i,
  setupdateLogo,
  handleShow,
  getPaginationList,
}) {
  const { _id, pageSelect, imageURL } = partnerLogo;
  const handleDeleteAction = (id) => {
    const agree = window.confirm(`Are you sure, you want to delete logo"`);
    if (agree) {
      fetch(`${process.env.REACT_APP_host}/api/v1/partner-logo/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            getPaginationList();
            toast.success(`Partner Logo is Deleted !`);
          }
        });
    }
  };
  return (
    <tr>
      <td className="text-center align-middle table-text">{i}</td>
      <td className="text-center align-middle table-text">{pageSelect} </td>
      <td className="text-center align-middle table-text">
        <div className="plans-img">
          <img src={imageURL} alt="partnet logo" />
        </div>
      </td>

      <td className="text-center align-middle table-text">
        <div className="d-flex align-items-center justify-content-center gap-2">
          <button
            onClick={() => {
              handleShow();
              setupdateLogo(partnerLogo);
            }}
            className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center gap-1"
          >
            <BiEdit /> <span>Edit</span>
          </button>
          <button
            onClick={() => handleDeleteAction(_id)}
            className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center gap-1"
          >
            <MdDelete /> <span>Delete</span>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default PartnerLogoList;
