import React from "react";
import { Link } from "react-router-dom";
import Buttons from "../../components/Buttons/Buttons";
import { ImSad } from "react-icons/im";
import "./ErrorPage.css";

function ErrorPage() {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <div className="">
          <ImSad className="sad-icon" />
        </div>
        <h1 className="display-1 fw-bold text-secondary">404</h1>
        <p className="fs-3">
          <span className="text-danger">Opps!</span> Page not found.
        </p>
        <p className="lead pb-4">The page you’re looking for doesn’t exist.</p>
        <Link to="/home">
          <Buttons classes="btn-error">Go Home</Buttons>
        </Link>
      </div>
    </div>
  );
}

export default ErrorPage;
