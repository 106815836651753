import React from "react";
import PreferredHospitalBanner from "../PreferredHospitalBanner/PreferredHospitalBanner";
import HospitalList from "../HospitalList/HospitalList";
import useTitle from "../../../hooks/useTitle";

function PreferredHospital() {
  useTitle("Preferred Hospitals");
  return (
    <>
      <PreferredHospitalBanner />
      <HospitalList />
    </>
  );
}

export default PreferredHospital;
