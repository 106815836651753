import React from 'react';
import BgBannerImg from "../../../assets/BG/bgLeft.png";
import BannerImg from "../../../assets/image/find-hospital-diagnostics.png";
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const BgStyle = {
    backgroundImage: `url(${BgBannerImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
};


const PreferredHospitalBanner = () => {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
    }, []);

    const bannerItems = [
        {
            id: 1,
            title: `${t("hospitalBannerTitle")}`,
            desc: `${t("hospitalBannerDetails")}`,
            img: BannerImg,
        },
    ];

    return (
        <div style={BgStyle}>
            <div className="body-container secondary-container banner-container">
                {bannerItems.map((bannerItem) => (
                    <div key={bannerItem.id} className="banner-content">
                        <div className="left-content">
                            <h1 className="title banner-title">{bannerItem?.title}</h1>
                            <p className="text banner-text">{bannerItem?.desc}</p>
                        </div>
                        <div className="right-img">
                            <img src={bannerItem?.img} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PreferredHospitalBanner;