import React from 'react';
import { FaCheckCircle } from "react-icons/fa";

const MicroPlanList = ({listItem}) => {
    return (
        <>
            {
                listItem?.map((lst) =>
                    <li key={lst?.id}>
                        <div className='corporate-coverage-list'>
                            <div className='corporate-coverage-check'>
                                <FaCheckCircle />
                            </div>
                            <p style={{ fontSize: "14px" }}>{lst?.listTitle}</p>
                        </div>
                    </li>
                )
            }
        </>
    );
};

export default MicroPlanList;