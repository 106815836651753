import i18n from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import nagad from '../../assets/payment/Nagad.png';
import rocket from '../../assets/payment/Rocket.png';
import bkash from '../../assets/payment/bKash.png';
import SureCash from '../../assets/payment/sureCash.png';
import uPay from '../../assets/payment/uPay.png';
import useTitle from '../../hooks/useTitle';
import PaymentSubMenu from './PaymentSubMenu';
import './payment.css';
import { Link } from 'react-router-dom';

function Payment() {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);
    useTitle('Payment channels');
    return (
        <>
            <div className="career-banner py-4 ">
                <div className="body-container secondary-container"></div>
                <div className="mt-5 body-container secondary-container text-center">
                    <div className="career-banner-content">
                        <h1>{t('Payment channels')}</h1>
                        <p>
                            {t(
                                'Say Goodbye to Transaction Delays - Experience Lightning-Fast Payments with Payment Channels'
                            )}
                        </p>

                        <div className="mt-4">
                            <Link
                                to="https://retail.myguardianbd.com/premium/pay"
                                target="_blank"
                                className="btn-learn btns bg-transparent"
                            >
                                {t('Pay Online')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <PaymentSubMenu />
        </>
    );
}

export default Payment;
