import React, { useEffect, useState } from 'react';
import './partnerOurInsurance.css';
import MonthlyPlanTable from './MonthlyPlanTable';
import LifePlanTable from './LifePlanTable';
import HealthPlanTable from './HealthPlanTable';
import ADCPurchasePolicy from '../ADCPurchasePolicy';
import ADCHowPurchase from '../ADCHowPurchase';
import ADCVideoSection from '../ADCVideoSection';
import ADCKeyUSP from '../ADCKeyUSP';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const PartnerOurInsurance = ({ data }) => {
    const [banglatranslate, setBanglaTraslate] = useState();
    const [menuState, setMenuState] = useState(data?.healthTitle);

    const action = (idx) => {
        setMenuState(idx);
    };

    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    return (
        <div className="service-container ">
            {(data?.healthTitle || data?.lifeTitle) && (
                <div className="body-container secondary-container">
                    <h1 class="title title-service">
                        {t('easylifeADCOurInsurancePolicyTitle')}
                    </h1>
                    <div className="mt-5">
                        <section className="media-corner-submenu d-flex justify-content-center">
                            {data?.healthTitle && (
                                <div
                                    onClick={() => action(data?.healthTitle)}
                                    className={
                                        menuState === data?.healthTitle
                                            ? 'active-submenu'
                                            : 'default-submenu'
                                    }
                                >
                                    {banglatranslate === 'bn'
                                        ? data?.healthTitleBn
                                        : data?.healthTitle}
                                </div>
                            )}
                            {data?.lifeTitle && (
                                <div
                                    onClick={() => action(data?.lifeTitle)}
                                    className={
                                        menuState === data?.lifeTitle
                                            ? 'active-submenu'
                                            : 'default-submenu'
                                    }
                                >
                                    {banglatranslate === 'bn'
                                        ? data?.lifeTitleBn
                                        : data?.lifeTitle}
                                </div>
                            )}
                        </section>

                        <section className="mt-4">
                            {data?.lifeDesc && (
                                <div
                                    className={
                                        menuState === 'Life Insurance'
                                            ? 'd-block'
                                            : 'd-none'
                                    }
                                >
                                    <LifePlanTable
                                        data={data}
                                        banglatranslate={banglatranslate}
                                    />
                                </div>
                            )}
                            {/* <div className={menuState === "month" ? "d-block" : "d-none"}>
                           <MonthlyPlanTable insurance={data?.insurance} />
                       </div> */}
                            {data?.healthDesc && (
                                <div
                                    className={
                                        menuState === 'Health Insurance'
                                            ? 'd-block'
                                            : 'd-none'
                                    }
                                >
                                    <HealthPlanTable
                                        data={data}
                                        banglatranslate={banglatranslate}
                                    />
                                </div>
                            )}
                        </section>
                    </div>
                </div>
            )}

            <section>
                {data?.ourPolicyDesc && (
                    <ADCPurchasePolicy
                        data={data}
                        banglatranslate={banglatranslate}
                        t={t}
                    />
                )}
            </section>

            <section>
                {data?.purchaseDesc && (
                    <ADCHowPurchase
                        data={data}
                        banglatranslate={banglatranslate}
                        t={t}
                    />
                )}
            </section>

            {/* <section>
                {
                    data?.keyUSP.length > 0 &&
                    <ADCKeyUSP keyUSP={data?.keyUSP} t={t} />
                }
            </section> */}

            <section>
                {data?.vedioLink.length > 0 && (
                    <ADCVideoSection data={data} t={t} />
                )}
            </section>
        </div>
    );
};

export default PartnerOurInsurance;
