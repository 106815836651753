import React from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import './dashboardAddPlans.css';
import { toast } from 'react-hot-toast';

function DashboardPlansList({
    getPlans,
    setShowPlans,
    handleViewShow,
    getAllDataFetch,
    setEditPlans,
    handleEditShow,
}) {
    const { _id, title, description, imageURL, vedioLink } = getPlans;

    //   delete by id
    const handleDeleteAction = (id) => {
        const agree = window.confirm(
            `Are you sure, you want to delete: "${title}?"`
        );
        if (agree) {
            fetch(`${process.env.REACT_APP_host}/api/v1/plans/${id}`, {
                method: 'DELETE',
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.status) {
                        getAllDataFetch();
                        toast.success(`${title} is a Deleted`);
                    }
                });
        }
    };
    return (
        <tr>
            <td className="text-center align-middle table-text fw-bold">
                {title}
            </td>
            <td className="text-center align-middle table-text">
                <div className="plans-img">
                    <img src={imageURL} />
                </div>
            </td>
            <td className="text-center align-middle table-text">
                {vedioLink.length > 30
                    ? `${vedioLink.slice(0, 30)} .....`
                    : vedioLink}
            </td>
            <td className="text-center align-middle table-text">
                <span
                    dangerouslySetInnerHTML={{
                        __html:
                            description.length > 120
                                ? `${description.slice(0, 120)} .....`
                                : description,
                    }}
                ></span>
            </td>
            <td className="text-center align-middle table-text">
                <div className="d-flex align-items-center justify-content-center gap-2">
                    <button
                        onClick={() => {
                            handleViewShow();
                            setShowPlans(getPlans);
                        }}
                        className="btn btn-sm btn-info text-white table-btn fw-semibold d-flex align-items-center "
                    >
                        <AiOutlineEye /> <span>Show</span>
                    </button>
                    <button
                        onClick={() => {
                            handleEditShow();
                            setEditPlans(getPlans);
                        }}
                        className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center "
                    >
                        <BiEdit /> <span>Edit</span>
                    </button>
                    <button
                        onClick={() => handleDeleteAction(_id)}
                        className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center"
                    >
                        <MdDelete /> <span>Delete</span>
                    </button>
                </div>
            </td>
        </tr>
    );
}

export default DashboardPlansList;
