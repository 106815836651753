import React from "react";
import ManagementTeamBanner from "./ManagementTeamBanner";
import ManagementTeamList from "./ManagementTeamList";
import useTitle from "../../hooks/useTitle";

function ManagementTeam() {
  useTitle("Management Committee");
  return (
    <>
      {/* <ManagementTeamBanner /> */}
      <ManagementTeamList />
    </>
  );
}

export default ManagementTeam;
