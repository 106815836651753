import React from 'react';
import { Accordion } from 'react-bootstrap';



const GeneralFAQ = ({ t }) => {
    const faqContents = [
        {
            id: 1,
            title: `${t("easylifeFAQGeneralT1")}`,
            des: `${t("easylifeFAQGeneralD1")}`,
        },
        {
            id: 2,
            title: `${t("easylifeFAQGeneralT2")}`,
            des: `${t("easylifeFAQGeneralD2")}`,
        },
        {
            id: 3,
            title: `${t("easylifeFAQGeneralT3")}`,
            des: `${t("easylifeFAQGeneralD3")}`,
        },
        {
            id: 4,
            title: `${t("easylifeFAQGeneralT4")}`,
            des: `${t("easylifeFAQGeneralD4")}`,
        },
        {
            id: 5,
            title: `${t("easylifeFAQGeneralT5")}`,
            des: `${t("easylifeFAQGeneralD5")}`,
        },
        {
            id: 6,
            title: `${t("easylifeFAQGeneralT6")}`,
            des: `${t("easylifeFAQGeneralD6")}`,
        },
        {
            id: 7,
            title: `${t("easylifeFAQGeneralT7")}`,
            des: `${t("easylifeFAQGeneralD7")}`,
        },
        {
            id: 8,
            title: `${t("easylifeFAQGeneralT8")}`,
            des: `${t("easylifeFAQGeneralD8")}`,
        },
        {
            id: 9,
            title: `${t("easylifeFAQGeneralT9")}`,
            des: `${t("easylifeFAQGeneralD9")}`,
        },
        {
            id: 10,
            title: `${t("easylifeFAQGeneralT10")}`,
            des: `${t("easylifeFAQGeneralD10")}`,
        },
        {
            id: 11,
            title: `${t("easylifeFAQGeneralT11")}`,
            des: `${t("easylifeFAQGeneralD11")}`,
        },
        {
            id: 12,
            title: `${t("easylifeFAQGeneralT12")}`,
            des: `${t("easylifeFAQGeneralD12")}`,
        },
        {
            id: 13,
            title: `${t("easylifeFAQGeneralT13")}`,
            des: `${t("easylifeFAQGeneralD13")}`,
        },
        {
            id: 14,
            title: `${t("easylifeFAQGeneralT14")}`,
            des: `${t("easylifeFAQGeneralD14")}`,
        },
        {
            id: 15,
            title: `${t("easylifeFAQGeneralT15")}`,
            des: `${t("easylifeFAQGeneralD15")}`,
        },
    ];
    return (
        <div>
            <Accordion defaultActiveKey="0">
                {faqContents.map((faq, i) => (
                    <Accordion.Item eventKey={i} key={faq.id}>
                        <Accordion.Header>
                            {faq.id}. {faq.title}
                        </Accordion.Header>
                        <Accordion.Body>{faq.des}</Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
};

export default GeneralFAQ;