import React, { useEffect, useRef, useState } from 'react';
import Loading from '../../../../components/Loading/Loading';
import ReactPaginate from 'react-paginate';
import DashboardViewNewsTable from './DashboardViewNewsTable';
import DashboardNewsEditModal from './DashboardNewsEditModal';
import DashboardNewsViewModal from './DashboardNewsViewModal';
import { Link } from 'react-router-dom';
import { FaStepBackward } from 'react-icons/fa';

function DashboardViewNews() {
    const [newAllNewss, setAllNewss] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState(50);
    const [pageCount, setPageCount] = useState(1);
    const [editModal, setEditModal] = useState(false);
    const [viewShow, setViewShow] = useState(false);
    const [updateNews, setUpdateNews] = useState(null);
    const [viewNews, setViewNews] = useState(null);

    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    const handleViewClose = () => setViewShow(false);
    const handleViewShow = () => setViewShow(true);
    const handleEditClose = () => setEditModal(false);
    const handleEditShow = () => setEditModal(true);

    // pagination
    function handlePageClick(e) {
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/news?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllNewss(data?.data?.newsList);
                setIsLoading(false);
            });
    }

    return (
        <div className="home-content">
            <div class="d-flex justify-content-between gap-2 align-items-center">
                <h1 className="dashboard-title">All News</h1>
                <div>
                    <Link
                        to="/dashboard/media/news"
                        className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                    >
                        <FaStepBackward />
                        <span>Back</span>
                    </Link>
                </div>
            </div>

            <div className="list-contaner">
                {newAllNewss?.length === 0 ? (
                    <div className="min-vh-100 d-flex align-items-center justify-content-center">
                        No News Is Available
                    </div>
                ) : (
                    <>
                        <div className="table-responsive">
                            <table className="table table-light table-bordered">
                                <thead>
                                    <tr className="table-tr">
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Serial No.
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Photo
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Title
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Description
                                        </th>
                                        {/* <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Created At
                    </th> */}
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newAllNewss.map((newNews, i) => (
                                        <DashboardViewNewsTable
                                            key={newNews._id}
                                            newNews={newNews}
                                            idx={i}
                                            handleEditShow={handleEditShow}
                                            handleViewShow={handleViewShow}
                                            setUpdateNews={setUpdateNews}
                                            setViewNews={setViewNews}
                                            getPaginationList={
                                                getPaginationList
                                            }
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}

                <div className="mt-4">
                    <ReactPaginate
                        breakLabel="......."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>

                <DashboardNewsEditModal
                    editModal={editModal}
                    handleEditClose={handleEditClose}
                    updateNews={updateNews}
                    setUpdateNews={setUpdateNews}
                    getPaginationList={getPaginationList}
                />

                <DashboardNewsViewModal
                    viewShow={viewShow}
                    handleViewClose={handleViewClose}
                    viewNews={viewNews}
                />
            </div>
        </div>
    );
}

export default DashboardViewNews;
