import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import BannerImg from "../../assets/taxrebate/things_to_remember.jpeg";
import "./taxRebate.css";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import i18n from "i18next";

function TaxRebateThing() {
  const [isShowMore, setShowMore] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);
  const handleShow = () => {
    setShowMore(!isShowMore);
  };
  return (
    <div className="service-container pt-0">
      <div className="body-container secondary-container">
        <h1 className="title title-service">{t("Things to remember")}</h1>
        <div className="plans-banner-contents section-margin-top">
          <div className="right-img">
            <img src={BannerImg} alt="" />
          </div>
          <div className="left-content">
            <div className="text banner-text pt-0">
              {!isShowMore ? (
                <>
                  <p className="fw-bold mb-2">
                    {t(
                      "Payment of a minimum income tax of BDT 5000 is compulsory for the eligible taxpayer irrespective of the rebate amount"
                    )}
                  </p>

                  <p className="mb-2 text-primary">
                    {t(
                      "Invest the amount equal to or greater than the allowable amount in Guardian Life policy before 30th June to get tax rebate."
                    )}
                  </p>

                  <p className="mb-2">
                    {t(
                      "Investment on insurance is the maximum amount that you can annually invest in life insurance premium."
                    )}
                  </p>

                  <p className="mb-2">
                    {t(
                      "Tax rebate is applicable on the premium of any revived lapsed policy."
                    )}
                  </p>

                  <p>
                    {t(
                      "Any type of insurance, whether life or health, provides the highest tax benefits...."
                    )}
                  </p>
                </>
              ) : (
                <>
                  <p className="fw-bold mb-2">
                    {t(
                      "Payment of a minimum income tax of BDT 5000 is compulsory for the eligible taxpayer irrespective of the rebate amount"
                    )}
                  </p>

                  <p className="mb-2 text-primary">
                    {t(
                      "Invest the amount equal to or greater than the allowable amount in Guardian Life policy before 30th June to get tax rebate."
                    )}
                  </p>

                  <p className="mb-2">
                    {t(
                      "Investment on insurance is the maximum amount that you can annually invest in life insurance premium."
                    )}
                  </p>

                  <p className="mb-2">
                    {t(
                      "Tax rebate is applicable on the premium of any revived lapsed policy."
                    )}
                  </p>

                  <p className="">
                    {t(
                      "Any type of insurance, whether life or health, provides the highest tax benefits."
                    )}
                  </p>

                  <h5 className="tax-rebet-title my-3">
                    {t("Who are eligible?")}
                  </h5>
                  <p className="mb-2">
                    {t(
                      "- Businessman, professional, public and private job holders who are tax payers."
                    )}
                  </p>
                  <p className="mb-2">
                    {t(
                      "Investment Instruments: All the Guardian Life Insurance Plans (e.g. Pension, CPP etc)"
                    )}
                  </p>
                  <div>
                    <p>
                      {t(
                        "As per section [44(4) (b)] of income tax ordinance 1984, specific types of investment, charity or contribution qualifies for income tax rebate and resultant rebate amount may be derived from:"
                      )}
                    </p>
                    <p>{t("(a) Total Income")}</p>
                    <p>{t("(b) Eligible Amount for rebate")}</p>
                  </div>
                </>
              )}
            </div>
            <div className="d-flex align-items-center gap-3 mt-4">
              <Button className="btn-book btns" onClick={handleShow}>
                {isShowMore ? `${t("readless")}` : `${t("readmore")}`}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaxRebateThing;
