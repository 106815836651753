import React from "react";
import { Link } from "react-router-dom";
import Buttons from "../../../components/Buttons/Buttons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import mobileImg1 from "../../../assets/image/mobile.jpg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import "./aboutEasyLifeGetProtected.css";

const MobileSliders = [
  { id: 1, img: mobileImg1 },
  { id: 2, img: mobileImg1 },
];

function AboutEasylifeGetProtected() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);
  return (
    <div className="service-container bg-white pt-0">
      <div className="body-container secondary-container">
        <div className="row flex-column-reverse flex-lg-row g-3">
          <section className="col-12 col-lg-6">
            <h1 class="title banner-title mb-4">
              {t("easylifeAboutUsGetProtectedTitle")}
            </h1>
            <p>{t("easylifeAboutUsGetProtectedDetails")}</p>
            <div className="d-flex align-items-center gap-3 mt-4">
              <Link
                to="https://play.google.com/store/apps/details?id=com.sslwireless.easylife"
                target="_blank"
              >
                <Buttons classes="btn-book btns">
                  {t("easylifeGetAppBtn")}
                </Buttons>
              </Link>
              {/* <Link to="/easylife/easy-life-product">
                <Buttons classes="btn-viewProducts">
                  {t("easylifeGetQuoteBtn")}
                </Buttons>
              </Link> */}
            </div>
          </section>

          <section className="col-12 col-lg-6">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              // pagination={true}
              autoplay={{ delay: 2000 }}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@0.75": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@1.00": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@1.50": {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
              }}
              modules={[Navigation, Pagination, Autoplay]}
              className="mySwiper"
            >
              {MobileSliders?.map((mobile) => (
                <SwiperSlide
                  key={mobile.id}
                  className="slider-partner single-award shadow-none"
                  style={{ padding: "200px 0" }}
                >
                  <div style={{ width: "350px" }}>
                    <img src={mobile.img} alt="" style={{ width: "100%" }} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
        </div>
      </div>
    </div>
  );
}

export default AboutEasylifeGetProtected;
