import React from 'react';
import "./AboutEasyLifeWhyJoinUS.css";
import developmentImg from "../../../assets/image/easy-development.png";
import cultureImg from "../../../assets/image/easy-culture.png";
import growTogetherImg from "../../../assets/image/easy-grow-together.png";
import joinUsImg from "../../../assets/image/easy-join-us.png";
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";



function AboutEasyLifeWhyJoinUS() {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
    }, []);

    const ourServiceItems = [
        {
            id: 1,
            title: `${t("easylifeAboutUsWhyJoinUsCard1Title")}`,
            details: `${t("easylifeAboutUsWhyJoinUsCard1Details")}`,
            icon: developmentImg
        },
        {
            id: 2,
            title: `${t("easylifeAboutUsWhyJoinUsCard2Title")}`,
            details: `${t("easylifeAboutUsWhyJoinUsCard2Details")}`,
            icon: cultureImg
        },
        {
            id: 3,
            title: `${t("easylifeAboutUsWhyJoinUsCard3Title")}`,
            details: `${t("easylifeAboutUsWhyJoinUsCard3Details")}`,
            icon: growTogetherImg
        },
        {
            id: 4,
            title: `${t("easylifeAboutUsWhyJoinUsCard4Title")}`,
            details: `${t("easylifeAboutUsWhyJoinUsCard4Details")}`,
            icon: joinUsImg
        }
    ];
    return (
        <div className='service-container bg-white'>
            <div className='body-container secondary-container'>
                <h1 className="title banner-title text-center">{t("easylifeAboutUsWhyJoinUsTitle")}</h1>
                <div className="corporate-service-grid">
                    {ourServiceItems.map((ourServiceItem) => (
                        <div key={ourServiceItem.id} className='easy-life-why-join-card'>
                            <div className="d-flex flex-column">
                                <div className="row gap-md-2 gap-lg-4 align-items-center mb-3">
                                    <div className='col-2'>
                                        <div className='micro-img'>
                                            <img src={ourServiceItem.icon} alt="" />
                                        </div>
                                    </div>
                                    <h4 className="col-8 corporate-service-title text-uppercase">{ourServiceItem.title}</h4>
                                </div>
                                <div className="card-info">
                                    <p style={{ fontSize: "14px", lineHeight: "24px" }}>{ourServiceItem.details}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AboutEasyLifeWhyJoinUS