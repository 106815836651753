import React from 'react';
import ApplyTodayModal from './ApplyTodayModal';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';

const SinglePositionApplyToday = ({ data }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleShow = () => {
        setShow(true);
    };

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();

    // post
    const handleAddApplication = (applicationValue) => {
        // e.preventDefault();
        handleClose();

        const formData = new FormData();
        formData.append(
            'name',
            applicationValue?.firstName + ' ' + applicationValue?.lastName
        );
        formData.append('email', applicationValue?.email);
        formData.append('contactNumber', applicationValue?.phone);
        formData.append('positionName', data?.positionName);
        formData.append('image', applicationValue?.image[0]);

        for (const value of formData.values()) {
            console.log(value);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/all-jobApplyList`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    console.log(res);
                    toast.success('Application added successfully');
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error('Applying form not submitted!');
            });
    };

    return (
        <div className="career-apply-today">
            <div>
                <h5 className="position-title">Apply today</h5>
                <p className="career-single-text mt-4"></p>Please read the
                requirements carefully, if you feel you are fit for the
                position, please apply through the link
                <div className="w-50 mt-5">
                    <div className="career-contact-btn">
                        <button onClick={() => handleShow()}>Apply Now</button>
                    </div>
                </div>
            </div>
            <ApplyTodayModal
                handleAddApplication={handleAddApplication}
                show={show}
                handleClose={handleClose}
                data={data}
                handleSubmit={handleSubmit}
                errors={errors}
                register={register}
            />
        </div>
    );
};

export default SinglePositionApplyToday;
