import { Navigate, useLocation } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { useContext, useEffect, useState } from "react";
import useLoggedInUser from "../../hooks/useLoggedInUser";

function PrivateRoutes({ children }) {
  const [users, isLoading] = useLoggedInUser();

  // const [users, setUsers] = useState(null);
  // const [isLoadings, setIsLoadings] = useState(true);

  const location = useLocation();

  // get user
  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_host}/api/v1/user/me`, {
  //     method: "GET",
  //     headers: {
  //       authorization: `bearer ${localStorage.getItem("accessToken")}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setUsers(data?.data);
  //       setIsLoadings(false);
  //     });
  // }, []);

  if (isLoading) {
    return <Loading></Loading>;
  }

  if (users) {
    return children;
  }

  return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
}

export default PrivateRoutes;
