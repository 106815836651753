import React from 'react'
import { toast } from 'react-hot-toast';
import { BiEdit } from 'react-icons/bi';
import { BsFillEyeFill } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';


function ViewProductTable({ newBlog, handleEditShow, handleViewShow, setUpdateBlog,
    getPaginationList, idx, setViewBlog }) {
    const { _id, productCategory, productTitle, description, imageURL } = newBlog;

    const handleDeleteAction = (id) => {
        const agree = window.confirm(
            `Are you sure, you want to delete: "${productTitle}?"`
        );
        if (agree) {
            fetch(`${process.env.REACT_APP_host}/api/v1/easy-life-product/${id}`, {
                method: "DELETE",
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.status) {
                        getPaginationList();
                        toast.success(`"${productTitle}" is Deleted !`);
                    }
                });
        }
    };
    return (
        <>
            <tr>
                <td className="text-center align-middle table-text">{idx + 1}</td>
                <td className="text-center align-middle table-text">
                    {productCategory}
                </td>
                <td className="text-center align-middle table-text">
                    <div className="d-flex  align-items-center gap-2">
                        <div className="list-img">
                            <img src={imageURL} alt="blog" />
                        </div>
                    </div>
                </td>
                <td className="text-center align-middle table-text">
                    {productTitle}
                </td>
                <td className="text-center align-middle table-text">
                    <span
                        dangerouslySetInnerHTML={{
                            __html:
                                description.length > 150
                                    ? description.slice(0, 150) + "..."
                                    : description,
                        }}
                    ></span>
                </td>
                {/* <td className="text-center align-middle table-text">{createdTime} on {createdDate}</td> */}
                <td className="text-center align-middle table-text">
                    <div className="d-flex align-items-center justify-content-center gap-2">
                        <button
                            onClick={() => {
                                handleEditShow();
                                setUpdateBlog(newBlog);
                            }}
                            className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center gap-1"
                        >
                            <BiEdit /> <span>Edit</span>
                        </button>
                        <button
                            onClick={() => handleDeleteAction(_id)}
                            className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center gap-1"
                        >
                            <MdDelete /> <span>Delete</span>
                        </button>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default ViewProductTable