import React from "react";
import { toast } from "react-hot-toast";
import { AiOutlineLink } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";

function DashboardFormLibraryList({ formLibraryData, getPaginationList, handleEditShow, setUpdateFormLibrary }) {
  const { _id, formName, formNameBn, formLink } = formLibraryData;

  const handleDeleteAction = (id) => {
    const agree = window.confirm(`Are you sure, you want to delete: "${formName}?"`);
    if (agree) {
      fetch(`${process.env.REACT_APP_host}/api/v1/form-library/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            getPaginationList();
            toast.success(`"${formName}" is Deleted !`);
          }
        });
    }
  };
  return (
    <tr>
      <td className="text-center align-middle table-text">{formName}</td>
      <td className="text-center align-middle table-text">{formNameBn}</td>
      <td className="text-center align-middle table-text">
        <Link to={formLink} target="_blank">
          <AiOutlineLink />
        </Link>
      </td>

      <td className="text-center align-middle table-text">
        <div className="d-flex align-items-center justify-content-center gap-2">
          <button
            onClick={() => {
              handleEditShow();
              setUpdateFormLibrary(formLibraryData);
            }}
            className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center gap-1"
          >
            <BiEdit /> <span>Edit</span>
          </button>
          <button onClick={() => handleDeleteAction(_id)} className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center gap-1">
            <MdDelete /> <span>Delete</span>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default DashboardFormLibraryList;
