import React from "react";

function DigitalChannels() {
  return (
    <div className="vh-100 body-container secondary-container d-flex justify-content-center align-items-center">
      <h1>DigitalChannels</h1>
    </div>
  );
}

export default DigitalChannels;
