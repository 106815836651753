import React, { useState } from 'react';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import Loading from '../../../../components/Loading/Loading';
import { useRef } from 'react';
import ReactPaginate from 'react-paginate';
import { Button } from 'react-bootstrap';

function HospitalCategory() {
    const [hospitalLists, setHospitalLists] = useState([]);
    const [isUrl, setIsUrl] = useState(false);

    const [getDivision, setGetDivision] = useState(null);
    const [division, setDivision] = useState('');
    const [district, setDistrict] = useState('');
    const [area, setArea] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState(1000);
    const [pageCount, setPageCount] = useState(1);
    const currentPage = useRef();
    const [search, setSearch] = useState('');
    const [menuState, setMenuState] = useState(0);

    useEffect(() => {
        currentPage.current = 1;
        getHospitalList();
    }, []);

    useEffect(() => {
        currentPage.current = 1;
        getNameByDivision();
    }, [division]);

    const action = (idx) => {
        setMenuState(idx);
    };

    const url = `${process.env.REACT_APP_host}/api/v1/division-district`;
    const { data: allDivisionDistrict = [] } = useQuery({
        queryKey: [' allDivisionDistrict'],
        queryFn: async () => {
            try {
                const res = await fetch(url);
                const data = await res.json();
                return data?.data;
            } catch (err) {
                console.log(err);
            }
        },
    });

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }
    console.log(hospitalLists);

    function handlePageClick(e) {
        console.log(e);
        currentPage.current = e.selected + 1;
        getHospitalList();
    }

    function filtersHospital() {
        getHospitalList();
        setIsUrl(true);
    }

    function getHospitalList() {
        const url = `${process.env.REACT_APP_host}/api/v1/hospital?hospitalType=national&hospitalCategory=hospital`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                if (data?.status) {
                    setHospitalLists(data?.data?.hospitalList);
                    setIsLoading(false);
                }
            });
    }

    // get by name division
    function getNameByDivision() {
        const url = `${process.env.REACT_APP_host}/api/v1/division-district?division=${division}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status) {
                    setGetDivision(data?.data);
                }
            });
    }

    function resetHospital() {
        setSearch('');
        setDivision('');
        setDistrict('');
        setArea('');
    }

    const lists = hospitalLists?.filter(
        (items) => items?.district === district
    );

    const uniqueItems = Array.from(
        new Set(lists?.map((item) => item?.area))
    ).map((name) => {
        return lists?.find((item) => item?.area === name);
    });

    return (
        <div className="service-container bg-white pt-0">
            <div className="body-container secondary-container">
                <div className="mt-5 mb-3">
                    <form className="row g-3">
                        <div className="col-12 col-md-4">
                            <input
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                type="text"
                                name="hospitalName"
                                placeholder="Hospital Name"
                                className="form-control"
                            />
                        </div>
                        <div className="col-12 col-md-2">
                            <select
                                name="division"
                                className="form-select"
                                value={division}
                                onChange={(e) => setDivision(e.target.value)}
                            >
                                <option value="" selected>
                                    Choose division
                                </option>
                                {allDivisionDistrict?.map((divisions) => (
                                    <option key={divisions._id}>
                                        {divisions?.division}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <select
                                name="District"
                                className="form-select"
                                onChange={(e) => setDistrict(e.target.value)}
                            >
                                <option value="" selected>
                                    Select District
                                </option>
                                {getDivision?.map((getUpdateDivision) =>
                                    getUpdateDivision?.districts?.map(
                                        (district, i) => (
                                            <option key={i}>{district}</option>
                                        )
                                    )
                                )}
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <select
                                name="Area"
                                className="form-select"
                                onChange={(e) => setArea(e.target.value)}
                            >
                                <option value="">Select Area</option>
                                {uniqueItems?.map(
                                    (area) =>
                                        area?.area !== 'area' && (
                                            <option
                                                value={area?.area}
                                                key={area._id}
                                            >
                                                {area?.area}
                                            </option>
                                        )
                                )}
                            </select>
                        </div>

                        <div className="col-12 col-md-2">
                            <Button
                                onClick={resetHospital}
                                className="btn-search btn-danger"
                            >
                                Reset
                            </Button>
                            {/* <button
                // onClick={filtersHospital}
                type="button"
                class="btn-book btns btn btn-primary hospital-search-btn"
              >
                Search
              </button> */}
                        </div>
                    </form>
                </div>

                <div className="table-responsive">
                    <table className="table table-light table-bordered">
                        <thead>
                            <tr className="table-tr">
                                <th
                                    scope="col"
                                    className="text-center align-middle table-th"
                                >
                                    ID
                                </th>
                                <th
                                    scope="col"
                                    className="text-center align-middle table-th"
                                >
                                    Hospital Name
                                </th>
                                <th
                                    scope="col"
                                    className="text-center align-middle table-th"
                                >
                                    Division
                                </th>
                                <th
                                    scope="col"
                                    className="text-center align-middle table-th"
                                >
                                    District
                                </th>
                                <th
                                    scope="col"
                                    className="text-center align-middle table-th"
                                >
                                    Area
                                </th>
                                <th
                                    scope="col"
                                    className="text-center align-middle table-th"
                                >
                                    Address
                                </th>
                                <th
                                    scope="col"
                                    className="text-center align-middle table-th"
                                >
                                    Facilities
                                </th>
                                <th
                                    scope="col"
                                    className="text-center align-middle table-th"
                                >
                                    Contact Details
                                </th>
                                <th
                                    scope="col"
                                    className="text-center align-middle table-th"
                                >
                                    Remark
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {hospitalLists
                                ?.filter((item) => {
                                    return search.toLowerCase() === '' &&
                                        division === '' &&
                                        district === '' &&
                                        area === ''
                                        ? item
                                        : item?.hospitalName.toLowerCase().includes(search.toLowerCase()) &&
                                        
                                            item?.division.includes(
                                                division
                                            ) &&
                                            item?.district.includes(
                                                district
                                            ) &&
                                            item?.area.includes(area);
                                })
                                ?.map(
                                    (list) =>
                                        list?.hospitalType === 'national' && (
                                            <tr key={list?._id}>
                                                <td className="text-center align-middle table-text fw-bold">
                                                    {list?.hospitalId}
                                                </td>
                                                <td className="text-center align-middle table-text">
                                                    {list?.hospitalName}
                                                </td>
                                                <td className="text-center align-middle table-text">
                                                    {list?.division}
                                                </td>
                                                <td className="text-center align-middle table-text">
                                                    {list?.district}
                                                </td>
                                                <td className="text-center align-middle table-text">
                                                    {list?.area}
                                                </td>
                                                <td className="text-center align-middle table-text">
                                                    {list?.address}
                                                </td>
                                                <td className="align-middle table-text">
                                                    <div>
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: list?.facilities,
                                                            }}
                                                        ></span>
                                                    </div>
                                                </td>
                                                <td className="align-middle table-text">
                                                    <div className="text-center">
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: list?.contactDetails,
                                                            }}
                                                        ></span>
                                                    </div>
                                                </td>
                                                <td className="text-center align-middle table-text">
                                                    <p>{list?.remarks}</p>
                                                </td>
                                            </tr>
                                        )
                                )}
                        </tbody>
                    </table>
                </div>

                {/* <div className="mt-4">
          <ReactPaginate
            breakLabel="......."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
            containerClassName="pagination justify-content-end"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
          />
        </div> */}
            </div>
        </div>
    );
}

export default HospitalCategory;
