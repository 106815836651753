import useAdmin from "../../hooks/useAdmin";
import { Navigate, useLocation } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import useLoggedInUser from "../../hooks/useLoggedInUser";

function AdminRoutes({ children }) {
  const [users, isLoading] = useLoggedInUser();
  const [isAdmin, isAdminLoading] = useAdmin();
  const location = useLocation();

  if (isLoading || isAdminLoading) {
    return <Loading></Loading>;
  }

  if (users && isAdmin) {
    return children;
  }
  return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
}

export default AdminRoutes;
