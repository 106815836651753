import React, { useState } from 'react';
import './NationalHospital.css';
import HospitalCategory from './HospitalCategory';
import DiagnosticCenterCategory from './DiagnosticCenterCategory';

const NationalHospital = () => {
    const [menuState, setMenuState] = useState(0);

    const action = (idx) => {
        setMenuState(idx);
    };

    return (
        <div className="mt-5">
            <section className="media-corner-submenu d-flex justify-content-center">
                <div
                    onClick={() => action(0)}
                    className={
                        menuState === 0
                            ? 'active-submenu py-1 rounded'
                            : 'default-submenu py-1 rounded'
                    }
                >
                    Hospital
                </div>

                <div
                    onClick={() => action(1)}
                    className={
                        menuState === 1
                            ? 'active-submenu py-1 rounded'
                            : 'default-submenu py-1 rounded'
                    }
                >
                    Diagnostic Center
                </div>
            </section>

            <section className="mt-4">
                <div className={menuState === 0 ? 'd-block' : 'd-none'}>
                    <HospitalCategory />
                </div>
                <div className={menuState === 1 ? 'd-block' : 'd-none'}>
                    <DiagnosticCenterCategory />
                </div>
            </section>
        </div>
    );
};

export default NationalHospital;
