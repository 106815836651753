import React from "react";
import BannerImg from "../../assets/easylife/feature-cancer-care.jpg";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useEffect } from "react";

function CancerCareCenterFeature() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);
  const FeatureItems = [
    {
      id: 1,
      title: `${t("easyFeature")}`,
      featureList: [
        {
          id: 1,
          list: `${t("easyFeature1")}`,
        },
        {
          id: 2,
          list: `${t("easyFeature2")}`,
        },
        {
          id: 3,
          list: `${t("easyFeature3")}`,
        },
        {
          id: 4,
          list: `${t("easyFeature4")}`,
        },
        {
          id: 5,
          list: `${t("easyFeature5")}`,
        },
        {
          id: 6,
          list: `${t("easyFeature6")}`,
        },
        {
          id: 7,
          list: `${t("easyFeature7")}`,
        },
        {
          id: 8,
          list: `${t("easyFeature8")}`,
        },
      ],
      img: BannerImg,
    },
  ];
  return (
    <div className="service-container">
      <div className="body-container secondary-container">
        <div>
          {FeatureItems?.map((items) => (
            <div key={items.id} className="banner-content">
              <div className="left-content">
                <h1 class="title banner-title text-start">{items?.title}</h1>
                <ul className="text banner-text pb-0 pt-4">
                  {items?.featureList?.map((lst) => (
                    <li key={lst?.id}>
                      <div className="corporate-coverage-list">
                        <div className="corporate-coverage-check">
                          <FaCheckCircle />
                        </div>
                        <p style={{ fontSize: "14px" }}>{lst?.list}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="right-img">
                <img src={items?.img} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CancerCareCenterFeature;
