import React from 'react';
import { FaStepBackward } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading/Loading';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import DasboardHospitalViewTable from './DasboardHospitalViewTable';
import DashboardEditHospitalModal from './DashboardEditHospitalModal';

function DashboardViewAllHospital() {
    const [newHospitalList, setHospitalLists] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(300);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [viewShow, setViewShow] = useState(false);
    const [updateHospital, setUpdateHospital] = useState(null);
    const [viewHospital, setViewHospital] = useState(null);
    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getHospitalList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    const handleViewClose = () => setViewShow(false);
    const handleViewShow = () => setViewShow(true);
    const handleEditClose = () => setEditModal(false);
    const handleEditShow = () => setEditModal(true);

    function handlePageClick(e) {
        console.log(e);
        currentPage.current = e.selected + 1;
        getHospitalList();
    }

    function changeLimit() {
        getHospitalList();
    }

    function getHospitalList() {
        const url = `${process.env.REACT_APP_host}/api/v1/hospital?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setHospitalLists(data?.data?.hospitalList);
                setIsLoading(false);
            });
    }
    return (
        <>
            <div className="home-content">
                <div class="d-flex justify-content-between gap-2 align-items-center">
                    <h1 className="dashboard-title">All Hospital</h1>
                    <div>
                        <Link
                            to="/dashboard/preferred-hospital"
                            className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                        >
                            <FaStepBackward />
                            <span>Back</span>
                        </Link>
                    </div>
                </div>

                <div className="list-contaner">
                    {/* <div className="input-group mb-2">
          <span className="input-group-text search-icon" id="search">
            <BsSearch />
          </span>
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            className="form-control search-field"
            placeholder="Search by name, designation, department"
            aria-label="sarech"
            aria-describedby="search"
          />
        </div> */}

                    {newHospitalList?.length === 0 ? (
                        <div className="min-vh-100 d-flex align-items-center justify-content-center">
                            No Hospital Available
                        </div>
                    ) : (
                        <>
                            <div className="table-responsive">
                                <table className="table table-light table-bordered">
                                    <thead>
                                        <tr className="table-tr">
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Hospital Type
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                National Hospital Category
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                                style={{ width: '5%' }}
                                            >
                                                Hospital ID
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Hospital Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Country
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Division
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                District
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Area
                                            </th>
                                            <th
                                                scope="col"
                                                className="align-middle table-th"
                                            >
                                                Address
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                                style={{ width: '15%' }}
                                            >
                                                Facilities
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                                style={{ width: '15%' }}
                                            >
                                                Contact Details
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                                style={{ width: '10%' }}
                                            >
                                                Remark
                                            </th>

                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {newHospitalList
                                            // .filter((item) => {
                                            //   return search.toLowerCase() === ""
                                            //     ? item
                                            //     : item.name.toLowerCase().includes(search) ||
                                            //         item.workingArea.toLowerCase().includes(search) ||
                                            //         item.liscenseNo.toLowerCase().includes(search);
                                            // })
                                            .map((HospitalList) => (
                                                <DasboardHospitalViewTable
                                                    key={HospitalList._id}
                                                    HospitalList={HospitalList}
                                                    handleViewShow={
                                                        handleViewShow
                                                    }
                                                    handleEditShow={
                                                        handleEditShow
                                                    }
                                                    setViewHospital={
                                                        setViewHospital
                                                    }
                                                    getHospitalList={
                                                        getHospitalList
                                                    }
                                                    setUpdateHospital={
                                                        setUpdateHospital
                                                    }
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}

                    <div className="mt-4">
                        <ReactPaginate
                            breakLabel="......."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            renderOnZeroPageCount={null}
                            marginPagesDisplayed={2}
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            activeClassName="active"
                        />
                    </div>
                </div>
            </div>

            {/* <DashboardViewHospitalModal
        viewShow={viewShow}
        handleViewClose={handleViewClose}
        viewHospital={viewHospital}
      /> */}

            <DashboardEditHospitalModal
                show={editModal}
                setEditModal={setEditModal}
                handleClose={handleEditClose}
                getHospitalList={getHospitalList}
                updateHospital={updateHospital}
            />
        </>
    );
}

export default DashboardViewAllHospital;
