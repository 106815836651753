import React from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

function OurServiceCard({ ourServiceItem }) {
  return (
    <>
      {
        ourServiceItem?.link ?
          <Link to={ourServiceItem?.link} target={ourServiceItem?.target} className="service-card1 card-color-change text-center d-flex flex-column align-items-center justify-content-center">
            <div className="">
              <div className="service-img service-img1 d-flex align-items-center justify-content-center mb-2">
                {/* <img src={ourServiceItem.icon} alt="" /> */}
                <span>{ourServiceItem.icon}</span>
              </div>
              <h4 className="service-title service-title1 text-capitalize">{ourServiceItem.title}</h4>
            </div>
            {/* <div className="card-info cards-info">
        <p className="py-3 card-text text-center">{ourServiceItem.dec}</p>
        {ourServiceItem?.status === "active" && (
          <Link to={ourServiceItem.link} className="link-learnMore">
            Learn More <BsArrowRight className="learn-icon" />
          </Link>
        )}
        </div> */}
          </Link>
          :
          <div className="service-card1 card-color-change text-center d-flex flex-column align-items-center justify-content-center">
            <div className="">
              <div className="service-img service-img1 d-flex align-items-center justify-content-center mb-2">
                {/* <img src={ourServiceItem.icon} alt="" /> */}
                <span>{ourServiceItem.icon}</span>
              </div>
              <h4 className="service-title service-title1 text-capitalize">{ourServiceItem.title}</h4>
            </div>
            {/* <div className="card-info cards-info">
        <p className="py-3 card-text text-center">{ourServiceItem.dec}</p>
        {ourServiceItem?.status === "active" && (
          <Link to={ourServiceItem.link} className="link-learnMore">
            Learn More <BsArrowRight className="learn-icon" />
          </Link>
        )}
        </div> */}
          </div>
      }

    </>
  );
}

export default OurServiceCard;
