import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

function AccidentalBenfitsTable() {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);

    const overviewLists = [
        // {
        //     id: 1,

        //     silverPlan: `Basic Life Insurance 
        //     (Amount payable at death of policyholder)
        //     `,
        //     goldPlan: `BDT 10,000`,
        //     fix: 'Fixed',
        // },
        {
            id: 2,

            silverPlan: `Accidental Death Benefit (ADB)`,
            goldPlan: `BDT 50,000`,
            fix: 'Fixed',
        },
        {
            id: 3,

            silverPlan: `Accidental Hospitalization`,
            goldPlan: `BDT 50,000`,
            fix: `Maximum or Actual Cost
            (Whichever is lower)
            `,
        },
        {
            id: 4,

            silverPlan: `Accidental Indemnity (PTD & PPD)`,
            goldPlan: `BDT 50,000`,
            fix: `Fixed`,
        },
    ];
    return (
        <div className="service-container pt-0">
            <div className="body-container secondary-container">
                <h1 class="title banner-title text-center">
                    BENEFITS OF GUARDIAN ACCIDENT CARE
                </h1>
                <div className="pt-4">
                    <div className="table-responsive">
                        <table className="table table-striped table-light table-bordered">
                            <thead>
                                <tr className="table-tr">
                                    <th
                                        scope="col"
                                        className="text-center align-middle table-th"
                                    >
                                        Particulars
                                    </th>
                                    <th
                                        scope="col"
                                        colSpan={3}
                                        className="text-center align-middle table-th"
                                    >
                                        Benefits Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {overviewLists.map((list) => (
                                    <tr>
                                        <td className="text-center align-middle table-text fs-6">
                                            {list?.silverPlan}
                                        </td>
                                        <td className="text-center align-middle table-text fs-6">
                                            {list?.goldPlan}
                                        </td>
                                        <td className="text-center align-middle table-text fs-6">
                                            {list?.fix}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccidentalBenfitsTable;
