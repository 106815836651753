import React from "react";
import { useEffect } from "react";

function ChatBoot() {
  //   useEffect(() => {
  //     const script = document.createElement("script");
  //     script.type = "text/javascript";
  //     script.async = true;
  //     script.setAttribute("charset", "utf-8");
  //     script.src = `${
  //       window.location.protocol === "https:" ? "https://" : "http://"
  //     }static.revechat.com/widget/scripts/new-livechat.js?${new Date().getTime()}`;

  //     const existingScript = document.getElementsByTagName("script")[0];
  //     existingScript.parentNode.insertBefore(script, existingScript);
  //   }, []);

  return <div id="revechat-widget-container"></div>;
}

export default ChatBoot;
