import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FaStepBackward } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import DashboardClaimsFormList from "./DashboardClaimsFormList";
import DashboardClaimsFormEdit from "./DashboardClaimsFormEdit";

function DashboardClaimsFormView() {
  const [claimsForm, setClaimsForm] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [updateFormClaims, setUpdateFormClaims] = useState(null);

  const currentPage = useRef();

  useEffect(() => {
    currentPage.current = 1;
    getPaginationList();
  }, []);

  const handleEditClose = () => setEditModal(false);
  const handleEditShow = () => setEditModal(true);

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/claims-form`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setClaimsForm(data?.data?.claimsFormList);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }
  return (
    <div className="home-content">
      <div class="d-flex justify-content-between gap-2 align-items-center">
        <h1 className="dashboard-title">All Form</h1>
        <div>
          <Link
            to="/dashboard/claims"
            className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
          >
            <FaStepBackward />
            <span>Back</span>
          </Link>
        </div>
      </div>

      <div className="list-contaner">
        {claimsForm?.length === 0 ? (
          <div className="min-vh-100 d-flex align-items-center justify-content-center">
            No Claims Form Is Available
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-light table-bordered">
                <thead>
                  <tr className="table-tr">
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Form Category
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Form Link
                    </th>

                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {claimsForm?.map((claimsFormData, i) => (
                    <DashboardClaimsFormList
                      key={claimsFormData._id}
                      claimsFormData={claimsFormData}
                      idx={i}
                      handleEditShow={handleEditShow}
                      setUpdateFormClaims={setUpdateFormClaims}
                      getPaginationList={getPaginationList}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        <DashboardClaimsFormEdit
          handleEditClose={handleEditClose}
          editModal={editModal}
          updateFormClaims={updateFormClaims}
          getPaginationList={getPaginationList}
          setUpdateFormClaims={setUpdateFormClaims}
        />
      </div>
    </div>
  );
}

export default DashboardClaimsFormView;
