import React from 'react';
import { TbCircleKeyFilled } from "react-icons/tb";
import { RiLoginCircleFill } from "react-icons/ri";
import { AiTwotoneGift } from "react-icons/ai";
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";


const LearnAboutEasyLifeApp = () => {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
    }, []);

    const easylifeAppItems = [
        {
            id: 1,
            title: `${t("easylifeAboutEasylifeAppFeatureTitle1")}`,
            dec: `${t("easylifeAboutEasylifeAppFeatureDetails1")}`,
            icon: <TbCircleKeyFilled />,
        },
        {
            id: 2,
            title: `${t("easylifeAboutEasylifeAppFeatureTitle2")}`,
            dec: `${t("easylifeAboutEasylifeAppFeatureDetails2")}`,
            icon: <RiLoginCircleFill />,
        },
        {
            id: 3,
            title: `${t("easylifeAboutEasylifeAppFeatureTitle3")}`,
            dec: `${t("easylifeAboutEasylifeAppFeatureDetails3")}`,
            icon: <AiTwotoneGift />,
        }
    ];
    return (
        <div className='service-container bg-white pt-0'>
            <div className='body-container secondary-container'>
                <h1 className="title banner-title text-center">{t("easylifeAboutEasylifeAppTitle")}</h1>
                <div className="service-grid">
                    {easylifeAppItems.map((item) => (
                        <div key={item.id}>
                            <div className="service-card h-100">
                                <div className="d-flex justify-content-center">
                                    <div style={{ fontSize: "44px", color: "#232176" }}>
                                        <span>
                                            {item?.icon}
                                        </span>
                                    </div>
                                </div>
                                <div className="card-info text-center mt-2">
                                    <h4 className="corporate-service-title text-uppercase fw-semibold mb-1">{item.title}</h4>
                                    <p style={{ fontSize: "14px" }}>{item?.dec}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LearnAboutEasyLifeApp;