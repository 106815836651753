import React, { useEffect, useRef, useState } from "react";
import Loading from "../../../../components/Loading/Loading";
import ReactPaginate from "react-paginate";
import DashboardVideoViewTable from "./DashboardVideoViewTable";
import DashboardVideoEditModal from "./DashboardVideoEditModal";
import { Link } from "react-router-dom";
import { FaStepBackward } from "react-icons/fa";

function DashboardVideoView() {
  const [newAllVideo, setAllVideo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [editModal, setEditModal] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const [updateVideo, setUpdateVideo] = useState(null);
  const [viewVideo, setViewVideo] = useState(null);

  const currentPage = useRef();

  useEffect(() => {
    currentPage.current = 1;
    getPaginationList();
  }, []);

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  const handleEditClose = () => setEditModal(false);
  const handleEditShow = () => setEditModal(true);

  // pagination
  function handlePageClick(e) {
    currentPage.current = e.selected + 1;
    getPaginationList();
  }

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/media-video?page=${currentPage.current}&limit=${limit}`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data?.data?.page);
        setAllVideo(data?.data?.videoList);
        setIsLoading(false);
      });
  }
  return (
    <div className="home-content">
      <div class="d-flex justify-content-between gap-2 align-items-center">
        <h1 className="dashboard-title">All Videos & TVC</h1>
        <div>
          <Link
            to="/dashboard/media/video"
            className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
          >
            <FaStepBackward />
            <span>Back</span>
          </Link>
        </div>
      </div>

      <div className="list-contaner">
        {newAllVideo?.length === 0 ? (
          <div className="min-vh-100 d-flex align-items-center justify-content-center">
            No Video Is Available
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-light table-bordered">
                <thead>
                  <tr className="table-tr">
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                      style={{ width: "10%" }}
                    >
                      Serial No.
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                      style={{ width: "20%" }}
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                      style={{ width: "50%" }}
                    >
                      Embedded URL
                    </th>
                    <th
                      scope="col"
                      className="text-center align-middle table-th"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {newAllVideo.map((newVideo, i) => (
                    <DashboardVideoViewTable
                      key={newVideo._id}
                      newVideo={newVideo}
                      idx={i}
                      handleEditShow={handleEditShow}
                      setUpdateVideo={setUpdateVideo}
                      setViewVideo={setViewVideo}
                      getPaginationList={getPaginationList}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        <div className="mt-4">
          <ReactPaginate
            breakLabel="......."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
          />
        </div>

        <DashboardVideoEditModal
          editModal={editModal}
          handleEditClose={handleEditClose}
          updateVideo={updateVideo}
          setUpdateVideo={setUpdateVideo}
          getPaginationList={getPaginationList}
        />

        {/* <DashboardVideoViewModal
          viewShow={viewShow}
          handleViewClose={handleViewClose}
          viewVideo={viewVideo}
        /> */}
      </div>
    </div>
  );
}

export default DashboardVideoView;
