import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { FiEye } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

function DashBoardReview() {
  const navigate = useNavigate();

  const [addVideo, setAddVideo] = useState({
    title: "",
    videoLink: ""
  });

  const handleChange = (e) => {
    setAddVideo({ ...addVideo, [e.target.name]: e.target.value });
  };


  // post
  const handleAddNews = async (e) => {
    e.preventDefault();

    const form = e.target;
    const title = form.title.value;
    const videoLink = form.videoLink.value;

    const newVideo = {
      title,
      videoLink
    }
    console.log(newVideo);

    fetch(`${process.env.REACT_APP_host}/api/v1/media-review`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newVideo),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status) {
          toast.success("Video is added successfully!");
          form.reset();
          navigate("/dashboard/media/view-review");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Video is not added!");
      });
  }
  return (
    <div className="home-content">
      <div class="d-flex justify-content-end align-items-center">
        <Link
          to="/dashboard/media/view-review"
          class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
        >
          <FiEye />
          <span>View Review</span>
        </Link>
      </div>
      <div className="shadow rounded main-contents">
        <h1 className="dashboard-title text-center">Add Review</h1>

        <form onSubmit={handleAddNews}>
          <div className="mb-3">
            <label className="form-label  fw-bold">Review Title</label>
            <input
              type="text"
              onChange={handleChange}
              value={addVideo.title}
              name="title"
              className="form-control"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label  fw-bold">Video (Embedded) Link</label>
            <input
              type="text"
              onChange={handleChange}
              value={addVideo.videoLink}
              name="videoLink"
              className="form-control"
              required
            />
          </div>

          <button type="submit" className="btn btn-primary px-4">
            Save
          </button>
        </form>
      </div>
    </div>
  );
}

export default DashBoardReview;
