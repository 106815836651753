import React from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";

const pageName = [
  {
    id: 1,
    page: "Home",
  },
  {
    id: 2,
    page: "About Us",
  },
  {
    id: 3,
    page: "Shareholder List",
  },
  {
    id: 4,
    page: "Retail",
  },
  {
    id: 5,
    page: "Savings",
  },
  {
    id: 5,
    page: "Early Cash",
  },
  {
    id: 7,
    page: "Children",
  },
  {
    id: 8,
    page: "Retirement",
  },
  {
    id: 9,
    page: "Investment",
  },
  {
    id: 10,
    page: "Term Life",
  },
  {
    id: 11,
    page: "Supplementary Benefits",
  },
  {
    id: 12,
    page: "Guardian Sanchay",
  },
  {
    id: 13,
    page: "Guardian Probridhi",
  },
  {
    id: 14,
    page: "Guardian Somridhi",
  },
  {
    id: 15,
    page: "Guardian Money Back Term",
  },
  {
    id: 16,
    page: "Guardian 3 Stage Plan",
  },
  {
    id: 17,
    page: "Guardian 4 Stage Plan",
  },
  {
    id: 18,
    page: "Guardian 5 Stage Plan",
  },
  {
    id: 19,
    page: "Child Protection Plan",
  },
  {
    id: 20,
    page: "Guardian Projonmo",
  },
  {
    id: 21,
    page: "Nishchit Pension Plan",
  },
  {
    id: 22,
    page: "Ajibon Pension Plan",
  },
  {
    id: 23,
    page: "Guardian Surokkha",
  },
  {
    id: 24,
    page: "Guardian Shield",
  },
  {
    id: 25,
    page: "Guardian Health Insurance",
  },
  {
    id: 26,
    page: "Critical Illness Plan",
  },
  {
    id: 27,
    page: "Permanent Disability Accidental Benefit (PDAB)",
  },
  {
    id: 28,
    page: "Double Indemnity Accidental Benefit (DIAB)",
  },
  {
    id: 30,
    page: "Corporate",
  },
  {
    id: 31,
    page: "Microinsurance",
  },
  {
    id: 32,
    page: "Bancassurance",
  },
  {
    id: 33,
    page: " Claims",
  },
  {
    id: 34,
    page: "Digital Loyalty Card",
  },
  {
    id: 35,
    page: "Partner Channels",
  },
  {
    id: 36,
    page: "bKash",
  },
  {
    id: 37,
    page: "bKash RMG",
  },
  {
    id: 38,
    page: "Nagad",
  },
  {
    id: 39,
    page: "Upay",
  },
  {
    id: 40,
    page: "OK Wallet",
  },
  {
    id: 41,
    page: "Carnival",
  },
  {
    id: 42,
    page: "Jotno",
  },
  {
    id: 43,
    page: "RobiShop",
  },
  {
    id: 44,
    page: "Truck Lagbe",
  },

  {
    id: 45,
    page: "Frequently Asked Questions",
  },
  {
    id: 46,
    page: "Form Library",
  },
  {
    id: 47,
    page: "Easy Life Home",
  },
  {
    id: 48,
    page: "Term Life Insurance",
  },
  {
    id: 49,
    page: "Savings Plan",
  },
  {
    id: 50,
    page: "Women's Savings Plan",
  },
  {
    id: 51,
    page: "Cancer Care",
  },
];

function SEOEdit({
  show,
  handleClose,
  SEOEditList,
  setSEOEditList,
  getPaginationList,
}) {
  const handleChange = (e) => {
    setSEOEditList({ ...SEOEditList, [e.target.name]: e.target.value });
  };

  const handleUpdateAction = async (e) => {
    e.preventDefault();
    handleClose();

    const updatedSEO = {
      page: SEOEditList?.page,
      title: SEOEditList?.title,
      description: SEOEditList?.description,
    };
    console.log(updatedSEO);

    fetch(`${process.env.REACT_APP_host}/api/v1/seo/${SEOEditList?._id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedSEO),
    })
      .then((res) => {
        if (res.status) {
          console.log(res);
          getPaginationList();
          toast.success("SEO updated successfully!");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Not updated!");
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      scrollable={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          Update SEO
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <form onSubmit={handleUpdateAction}>
          <div className="mb-3">
            <label className="form-label fw-bold">Select Page</label>
            <select
              onChange={handleChange}
              name="pages"
              class="form-select"
              defaultValue={SEOEditList?.page}
            >
              <option value="" selected>
                Choses a Page
              </option>
              {pageName?.map((data, i) => (
                <option key={i} value={data?.page}>
                  {data?.page}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Title</label>
            <input
              type="text"
              name="title"
              onChange={handleChange}
              defaultValue={SEOEditList?.title}
              className="form-control"
              required
              placeholder="Enter title"
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Description</label>

            <textarea
              class="form-control"
              name="description"
              onChange={handleChange}
              defaultValue={SEOEditList?.description}
              required
              rows="3"
              placeholder="Enter discription"
            ></textarea>
          </div>

          <button type="submit" className="btn btn-primary">
            Update
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default SEOEdit;
