import React from "react";
import { toast } from "react-hot-toast";
import { MdDelete } from "react-icons/md";

function UserList({ user, i, getPaginationList }) {
  const { email, role, status } = user;

  const handleDeleteAction = (email) => {
    const agree = window.confirm(
      `Are you sure, you want to delete: "${role}?"`
    );

    const token = localStorage.getItem("accessToken");

    if (agree) {
      if (token) {
        fetch(`${process.env.REACT_APP_host}/api/v1/user/${email}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            authorization: `bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status) {
              getPaginationList();
              toast.success(`${role} is a Deleted`);
            }
          });
      }
    }
  };

  return (
    <tr>
      <td className="text-center align-middle table-text">{i + 1}</td>
      <td className="text-center align-middle table-text">{email}</td>
      <td className="text-center align-middle table-text">{role}</td>
      <td className="text-center align-middle table-text">
        <div>
          <label className="form-check-label">
            {status === "active" ? (
              <span className="text-success fw-bold">{status}</span>
            ) : (
              <span className="text-danger fw-bold">{status}</span>
            )}
          </label>
        </div>
      </td>
      <td className="text-center align-middle table-text">
        <div className="d-flex align-items-center justify-content-center gap-2">
          <button
            onClick={() => handleDeleteAction(email)}
            className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center"
          >
            <MdDelete /> <span>Delete</span>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default UserList;
