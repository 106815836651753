import React, { useEffect } from "react";
import ourMissionImg from "../../../assets/easylife/easylife-mission.png";
import OurPlansCardDetails from "../../../components/OurPlansCardDetails/OurPlansCardDetails";
import { useTranslation } from "react-i18next";
import i18n from "i18next";


function OurMission() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const missionDetails = [
    {
      id: 1,
      title: `${t("easylifeAboutUsMissionTitle")}`,
      desc:`${t("easylifeAboutUsMissionDetails")}`,
      img: ourMissionImg,
    },
  ];
  return (
    <div className="body-container secondary-container aboutEsayLife-container">
      <div className="plans-container">
        {missionDetails.map((bannerItem, i) => (
          <div key={i} className="plans-section">
            {i % 2 === 0 && (
              <div className="plans-banner-contents flex-column flex-lg-row">
                <>
                  <OurPlansCardDetails
                    bannerItem={bannerItem}
                    key={bannerItem.id}
                    imgBackground="#f3f3f3" 
                  />
                </>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default OurMission;
