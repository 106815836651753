import React from "react";
import { toast } from "react-hot-toast";
import { BiEdit } from "react-icons/bi";
import { BsFillEyeFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";

function DashboardFAQList({
  newFaq,
  handleEditShow,
  handleViewShow,
  setUpdateFaq,
  getPaginationList,
  idx,
  setViewFaq,
}) {
  const { _id, title, description, selectPage } = newFaq;
  const handleDeleteAction = (id) => {
    const agree = window.confirm(
      `Are you sure, you want to delete: "${title}?"`
    );
    if (agree) {
      fetch(`${process.env.REACT_APP_host}/api/v1/faq/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            getPaginationList();
            toast.success(`"${title}" is Deleted !`);
          }
        });
    }
  };
  return (
    <tr>
      <td className="text-center align-middle table-text">{idx + 1}</td>
      <td className="text-center align-middle table-text">{selectPage}</td>

      <td className="text-center align-middle table-text">
        {title.length > 26 ? title.slice(0, 26) + "..." : title}
      </td>
      <td className="text-center align-middle table-text">
        <span
          dangerouslySetInnerHTML={{
            __html:
              description.length > 150
                ? description.slice(0, 150) + "..."
                : description,
          }}
        ></span>
      </td>
      <td className="text-center align-middle table-text">
        <div className="d-flex align-items-center justify-content-center gap-2">
          <button
            onClick={() => {
              handleViewShow();
              setViewFaq(newFaq);
            }}
            className="btn btn-sm btn-primary table-btn fw-semibold d-flex align-items-center gap-1"
          >
            <BsFillEyeFill /> <span>View</span>
          </button>
          <button
            onClick={() => {
              handleEditShow();
              setUpdateFaq(newFaq);
            }}
            className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center gap-1"
          >
            <BiEdit /> <span>Edit</span>
          </button>
          <button
            onClick={() => handleDeleteAction(_id)}
            className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center gap-1"
          >
            <MdDelete /> <span>Delete</span>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default DashboardFAQList;
