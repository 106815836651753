import React, { useEffect, useState } from 'react';
import { Link, useLoaderData, useLocation } from 'react-router-dom';
import BannerImg from '../../../assets/easylife/banner/banner-6.jpg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const EasylifeSingleProductDetails = () => {
    // const { state: data } = useLocation();
    const data = useLoaderData();

    const [banglatranslate, setBanglaTraslate] = useState();

    const { t } = useTranslation();
    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    return (
        <div>
            {/* banner area */}
            <div className="body-container secondary-container banner-container">
                <div className="banner-content">
                    <div className="left-content">
                        <h1 className="title banner-title">
                            {banglatranslate === 'bn'
                                ? data?.data?.productTitleBn
                                : data?.data?.productTitle}
                        </h1>
                        <p className="text banner-text">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html:
                                        banglatranslate === 'bn'
                                            ? data?.data?.descriptionBn
                                            : data?.data?.description,
                                }}
                            ></span>
                        </p>
                        <div className="row g-3 single-product-banner-text">
                            <div className="col-12 col-md-4">
                                <h5 className="mb-1">
                                    {t('easylifeProductCoverage')}
                                </h5>
                                <p>
                                    {t('upTo')} {t('bdt')}{' '}
                                    {banglatranslate === 'bn'
                                        ? data?.data?.coverageBn
                                        : data?.data?.coverage}
                                </p>
                            </div>
                            <div className="col-12 col-md-4">
                                <h5 className="mb-1">
                                    {t('easylifeProductTerm')}
                                </h5>
                                <p>
                                    {t('upTo')}{' '}
                                    {banglatranslate === 'bn'
                                        ? data?.data?.termBn
                                        : data?.data?.term}{' '}
                                    {t('years')}
                                </p>
                            </div>
                            <div className="col-12 col-md-4">
                                <h5 className="mb-1">
                                    {t('easylifeProductPremium')}
                                </h5>
                                <p>
                                    {t('asLowAs')} {t('bdt')}{' '}
                                    {banglatranslate === 'bn'
                                        ? data?.data?.premiumBn
                                        : data?.data?.premium}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="right-img">
                        <img src={data?.data?.imageURL} alt="" />
                    </div>
                </div>
            </div>

            {/* product details */}

            {data?.data?.productCategory !== 'Cancer Care Plan' && (
                <div className="service-container">
                    <div className="body-container secondary-container">
                        <h1 className="title banner-title">
                            {t('easylifeProductDetailsTitle')}
                        </h1>
                        <div className="row g-3 mt-3">
                            {data?.data?.maturityBenefit && (
                                <div className="col-12 col-md-6">
                                    <p>
                                        <span className="fw-semibold">
                                            {t(
                                                'easylifeProductMaturityBenefit'
                                            )}
                                            :
                                        </span>{' '}
                                        {banglatranslate === 'bn'
                                            ? data?.data?.maturityBenefitBn
                                            : data?.data?.maturityBenefit}
                                    </p>
                                </div>
                            )}

                            {data?.data?.deathBenefit && (
                                <div className="col-12 col-md-6">
                                    <p>
                                        <span className="fw-semibold">
                                            {t('easylifeProductDeathBenefit')}:
                                        </span>{' '}
                                        {banglatranslate === 'bn'
                                            ? data?.data?.deathBenefitBn
                                            : data?.data?.deathBenefit}
                                    </p>
                                </div>
                            )}

                            {data?.data?.sumAssured && (
                                <div className="col-12 col-md-6">
                                    <p>
                                        <span className="fw-semibold">
                                            {t('easylifeProductSumAssured')}:
                                        </span>{' '}
                                        {banglatranslate === 'bn'
                                            ? data?.data?.sumAssuredBn
                                            : data?.data?.sumAssured}
                                    </p>
                                </div>
                            )}

                            {data?.data?.premiumPaymentMode && (
                                <div className="col-12 col-md-6">
                                    <p>
                                        <span className="fw-semibold">
                                            {t('easylifeProductPremiumPayment')}
                                            :
                                        </span>{' '}
                                        {banglatranslate === 'bn'
                                            ? data?.data?.premiumPaymentModeBn
                                            : data?.data?.premiumPaymentMode}
                                    </p>
                                </div>
                            )}

                            {data?.data?.productTerm && (
                                <div className="col-12 col-md-6">
                                    <p>
                                        <span className="fw-semibold">
                                            {t('easylifeProductTerm')}:
                                        </span>{' '}
                                        {banglatranslate === 'bn'
                                            ? data?.data?.productTermBn
                                            : data?.data?.productTerm}
                                    </p>
                                </div>
                            )}

                            {data?.data?.age && (
                                <div className="col-12 col-md-6">
                                    <p>
                                        <span className="fw-semibold">
                                            {t('easylifeProductAge')}:
                                        </span>{' '}
                                        {banglatranslate === 'bn'
                                            ? data?.data?.ageBn
                                            : data?.data?.age}
                                    </p>
                                </div>
                            )}

                            {data?.data?.rider && (
                                <div className="col-12 col-md-6">
                                    <p>
                                        <span className="fw-semibold">
                                            {t('easylifeProductRider')}:
                                        </span>{' '}
                                        {banglatranslate === 'bn'
                                            ? data?.data?.riderBn
                                            : data?.data?.rider}
                                    </p>
                                </div>
                            )}

                            {data?.data?.surrenderOption && (
                                <div className="col-12 col-md-6">
                                    <p>
                                        <span className="fw-semibold">
                                            {t(
                                                'easylifeProductSurrenderOption'
                                            )}
                                            :
                                        </span>{' '}
                                        {banglatranslate === 'bn'
                                            ? data?.data?.surrenderOptionBn
                                            : data?.data?.surrenderOption}
                                    </p>
                                </div>
                            )}

                            <div className="col-12 col-md-6">
                                {data?.data?.medicalTest ? (
                                    <p>
                                        <span className="fw-semibold">
                                            {t('easylifeProductMedicalTest')}:
                                        </span>{' '}
                                        {banglatranslate === 'bn'
                                            ? data?.data?.medicalTestBn
                                            : data?.data?.medicalTest}
                                    </p>
                                ) : data?.data?.conservationOption ? (
                                    <p>
                                        <span className="fw-semibold">
                                            {t(
                                                'easylifeProductConservationOption'
                                            )}
                                            :
                                        </span>{' '}
                                        {banglatranslate === 'bn'
                                            ? data?.data?.conservationOptionBn
                                            : data?.data?.conservationOption}
                                    </p>
                                ) : (
                                    <p>
                                        {banglatranslate === 'bn'
                                            ? 'মেয়াদপূর্তির তারিখের আগে যে কোনো সময়ে দুর্ঘটনা এবং/অথবা অসুস্থতার কারণে বীমাকৃত ব্যক্তির মৃত্যু ঘটলে, সম্পূর্ণ নিশ্চিতকৃত অর্থ প্রদান করা হবে।'
                                            : 'IN THE EVENT OF THE INSURED’S DEATH DUE TO ACCIDENT AND/OR SICKNESS AT ANY TIME BEFORE THE MATURITY DATE, THE FULL SUM ASSURED WILL BE PAID'}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="mt-5 d-flex justify-content-between">
                            <Link
                                to="http://easylife.myguardianbd.com/en/sign-in"
                                target="_break"
                                className="btn-book btns card-btn px-3 py-1 btn btn-primary w-auto"
                            >
                                {t('easylifeProductGetQuoteBtn')}
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EasylifeSingleProductDetails;
