import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BgOurPlanImg from '../../../assets/BG/bgRight.png';
import Image1 from '../../../assets/image/bancassurance-benefits.png';
import BancassuranceContentBn from './BancassuranceBenefitsContent/BancassuranceContentBn';
import BancassuranceContentEn from './BancassuranceBenefitsContent/BancassuranceContentEn';
import BancassuranceMenuTitleBn from './BancassuranceBenefitsMenuTitle/BancassuranceMenuTitleBn';
import BancassuranceMenuTitleEn from './BancassuranceBenefitsMenuTitle/BancassuranceMenuTitleEn';
import './bancassuranceBenefits.css';
import Loading from '../../../components/Loading/Loading';

const BgStyle = {
    backgroundImage: `url(${BgOurPlanImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
};

const BancassuranceBenefits = () => {
    const [updateBenfits, setUpdateBenfits] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [banglatranslate, setBanglaTraslate] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_host}/api/v1/bancassurance-benfits`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setUpdateBenfits(data?.data?.bancassuranceBenefitsList);
                setIsLoading(false);
            });
    }, []);

    const ourPlansDataEn = [
        {
            id: 0,
            title: `Benefits for Bank’s Customers`,
            img: Image1,
            link: '',
            list: [],
            subTitle1: {
                title: `${t('bancassuranceBenefitT1S1')}`,
                list: [
                    `Safeguards the family of the deceased from the difficulties of repaying the loan.`,
                    `Creating a respite from bad times even after immense health hazards that might lead to disabilities, installment payments will not be hampered.`,
                    `Health benefits as add-on benefit that comes with Life coverage.`,
                ],
            },
            subTitle2: {
                title: `Benefits for Bank`,
                list: [
                    `Increasing revenue through the sale of insurance products (Minimum investment risk with high return. More utilization of existing Channels/Branches/Sales Points).`,
                    `Minimizing credit risk exposures in Mortgage/Personal/SME/Loan & Credit cards.`,
                    `Motivating staff through additional income opportunities through incentives/commission income earned from selling Insurance Products.`,
                    `Increase attractiveness of their existing product line.`,
                ],
            },
            subTitle3: {
                title: '',
                list: [],
            },
            subTitle4: {
                title: '',
                list: [],
            },
            subTitle5: {
                title: '',
                list: [],
            },
            subTitle6: {
                title: '',
                list: [],
            },
        },

        {
            id: 1,
            title: `Type of Products`,
            img: Image1,
            link: '',
            list: [],
            subTitle1: {
                title: `Credit Card Shield`,
                list: [
                    `Offers up to 200% of the outstanding balance of credit card due to natural death or PTD (Permanent Total disability).`,
                    `Covers critical illness.`,
                    `Health Benefits (Inpatient– IPD and Outpatient-OPD as optional add-ons).`,
                ],
            },
            subTitle2: {
                title: `Mortgage/Home Loan`,
                list: [
                    `Protection for the Principal of the outstanding loan balance.`,
                    `Cover for death (both natural & ADB (Accidental Death Benefit)) and permanent total disability.`,
                ],
            },
            subTitle3: {
                title: `Personal Loan`,
                list: [
                    `Repayment of the loan to the bank/FI in case of death or disability of the borrower.`,
                ],
            },
            subTitle4: {
                title: `SME Loan`,
                list: [
                    `Payment of the outstanding liabilities to the bank in case of life casualties.`,
                ],
            },
            subTitle5: {
                title: `Employee`,
                list: [
                    `Customized insurance coverage with minimum cost bundled with employee/salary accounts.`,
                ],
            },
            subTitle6: {
                title: `Depository Insurance Products`,
                list: [
                    `Cash assurance facility in generic current and savings accounts.`,
                    `Customized deposit products such as DPS, Fixed Deposit.`,
                ],
            },
        },
        // {
        //   id: 3,
        //   title: "Depository Insurance Products",
        //   img: Image1,
        //   link: "",
        //   list: [
        //     "CASH Assurance facility in generic Current and Savings Accounts ",
        //     "Customized deposit products such as DPS, Fixed Deposit.",
        //   ],
        //   subTitle1: {
        //     title: "",
        //     list: [],
        //   },
        //   subTitle2: {
        //     title: "",
        //     list: [],
        //   },
        //   subTitle3: {
        //     title: "",
        //     list: [],
        //   },
        //   subTitle4: {
        //     title: "",
        //     list: [],
        //   },
        //   subTitle5: {
        //     title: "",
        //     list: [],
        //   },
        // },
    ];

    const ourPlansDataBn = [
        {
            id: 0,
            // title: `${t("bancassuranceBenefitT1")}`,
            title: `ব্যাংকের গ্রাহকদের সুবিধা`,
            img: Image1,
            link: '',
            list: [],
            subTitle1: {
                title: `${t('bancassuranceBenefitT1S1')}`,
                list: [
                    `ঋণ পরিশোধের চাপ থেকে মৃতের পরিবারের সদস্যদের মুক্তি দেয়।`,
                    `ভয়াবহ শারীরিক অসুস্থতার সময়ে লোন ইনস্টলমেন্ট পরিশোধের হাত থেকে আপনাকে মুক্তি দেয়।।`,
                    `লাইফ কভারেজের সাথে সহযোগী সুবিধা হিসাবে হেলথ বেনিফিট পাওয়া যায়।`,
                ],
            },
            subTitle2: {
                title: `ব্যাংকের সুবিধা`,
                list: [
                    `ইন্সুরেন্স বিক্রয়ের মাধ্যমে আয় বৃদ্ধি`,
                    `মর্টগেজ, পার্সোনাল লোন, এসএমই লোন, এবং ক্রেডিট কার্ড প্রদানের সময় ঝুঁকি কমানো`,
                    `অতিরিক্ত আয়ের মাধ্যমে কর্মকর্তা/কর্মচারীদের মনোবল বৃদ্ধি করা`,
                    `তাদের বিদ্যমান পণ্য তালিকাকে আরও আকর্ষণীয় করে তোলা।`,
                ],
            },
            subTitle3: {
                title: '',
                list: [],
            },
            subTitle4: {
                title: '',
                list: [],
            },
            subTitle5: {
                title: '',
                list: [],
            },
            subTitle6: {
                title: '',
                list: [],
            },
        },

        {
            id: 1,
            title: `পণ্যের ধরন`,
            img: Image1,
            link: '',
            list: [],
            subTitle1: {
                title: `ক্রেডিট কার্ড শিল্ড`,
                list: [
                    `স্বাভাবিক মৃত্যু বা পিটিডিএর কারণে ক্রেডিট কার্ডের বকেয়া পরিমাণের ২০০% পর্যন্ত অফার করে।`,
                    `গুরুতর অসুস্থতা কভার করে।`,
                    `হেলথ বেনিফিট (ঐচ্ছিক হিসাবে ইন-পেশেন্ট এবং আউট-পেশেন্ট সুবিধা)।`,
                ],
            },
            subTitle2: {
                title: `মর্টগেজ/হোম লোন`,
                list: [
                    `মূল বকেয়া লোন পরিমাণের সুরক্ষা`,
                    `মৃত্যু কভারেজ (স্বাবক এবং দুর্ঘটনাজনিত) এবং স্থায়ী পূর্ণাঙ্গ অক্ষমতা।`,
                ],
            },
            subTitle3: {
                title: `পার্সোনাল লোন`,
                list: [
                    `ঋণগ্রহীতার মৃত্যুতে বা অক্ষমতায় ব্যাংক/আর্থিক প্রতিষ্ঠানকে ঋণ পরিশোধ`,
                ],
            },
            subTitle4: {
                title: `এস.এম.ই ঋণ`,
                list: [`প্রাণহানির ক্ষেত্রে ব্যাংকের কাছে বকেয়া দায় পরিশোধ।`],
            },
            subTitle5: {
                title: `কর্মচারী`,
                list: [
                    `সর্বনিম্ন খরচে কর্মচারী/স্যালারি অ্যাকাউন্টের সাথে কাস্টোমাইজড ইনশিওরেন্স কভারেজ`,
                ],
            },
            subTitle6: {
                title: `ডিপজিটরি ইন্সুরেন্স প্রোডাক্ট`,
                list: [
                    `সাধারণ চলতি এবং সঞ্চয়ী হিসাবে নগদ অ্যাশিওরেন্স সুবিধা।`,
                    `কাস্টোমাইজড ডিপোজিট প্রোডাক্ট যেমন ডিপিএস, ফিক্সড ডিপোজিট।`,
                ],
            },
        },
    ];

    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (updateBenfits.length > 0 && selected === null) {
            setSelected(updateBenfits[0]._id);
        }
    }, [updateBenfits, selected]);

    const handleClick = (idx, data) => {
        setSelected(idx);
    };

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    return (
        <div style={BgStyle}>
            <div className="body-container secondary-container ourPlans-container pb-0">
                <h1 className="title title-service">
                    {t('bancassuranceBenefitTitle')}
                </h1>
                <div className="corporate-content">
                    <div className="slider-container">
                        <div className="ourPlans-left">
                            <div className="vl"></div>
                            {updateBenfits?.map((data, i) => (
                                <>
                                    {banglatranslate === 'bn' ? (
                                        <BancassuranceMenuTitleBn
                                            selected={selected === data._id}
                                            data={data}
                                            onClick={handleClick}
                                        />
                                    ) : (
                                        <BancassuranceMenuTitleEn
                                            selected={selected === data._id}
                                            data={data}
                                            onClick={handleClick}
                                        />
                                    )}
                                </>
                            ))}
                        </div>
                        <div className="ourPlans-right">
                            <div className="ourPlans-content-left">
                                {banglatranslate === 'bn' ? (
                                    <BancassuranceContentBn
                                        ourDataBn={updateBenfits.find(
                                            (item) => item._id === selected
                                        )}
                                    />
                                ) : (
                                    <BancassuranceContentEn
                                        ourData={updateBenfits.find(
                                            (item) => item._id === selected
                                        )}
                                    />
                                )}
                            </div>

                            <div className="ourPlans-img-right">
                                <img
                                    src={
                                        updateBenfits.find(
                                            (item) => item._id === selected
                                        )?.imageURL
                                    }
                                    alt="img"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BancassuranceBenefits;
