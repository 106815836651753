import React from 'react';
import { Modal } from 'react-bootstrap';

function DashboardBancassuranceBenfitsView({
    handleViewClose,
    viewBenfits,
    viewModal,
}) {
    return (
        <Modal
            show={viewModal}
            onHide={handleViewClose}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Show Benefit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <div>
                    <div>
                        <img
                            src={viewBenfits?.imageURL}
                            alt="blog"
                            className="w-100"
                            style={{ height: '270px' }}
                        />
                    </div>
                    <h5 className="fw-bold mt-4 pb-2 border-bottom">
                        {viewBenfits?.title}
                    </h5>
                    <h5 className="fw-bold mt-4 pb-2 border-bottom">
                        {viewBenfits?.titleBn}
                    </h5>
                    <p className="mt-3">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: viewBenfits?.description,
                            }}
                        ></span>
                    </p>
                    <p className="mt-3">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: viewBenfits?.descriptionBn,
                            }}
                        ></span>
                    </p>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardBancassuranceBenfitsView;
