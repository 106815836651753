import React, { useEffect } from "react";
import BannerImg from "../../../assets/image/track-claim.png";
import BGDot from "../../../assets/image/bgDot.png";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Buttons from "../../../components/Buttons/Buttons";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const ClaimYourTrack = () => {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const claimTrack = [
    {
      id: 1,
      title: `${t("claimTrackTitle")}`,
      details: `${t("claimTrackDetails")}`,
    },
  ];

  return (
    <div className="service-container">
      <div className="body-container secondary-container mobile-app-content">
        <div className="mobile-app-left right-img">
          <img src={BannerImg} alt="" />
        </div>
        <div className="mobile-app-right">
          <h1 className="title title-service text-start">
            {claimTrack[0]?.title}
          </h1>
          <p className="text banner-text">{claimTrack[0]?.details}</p>
          <div className="d-flex align-items-center gap-3 mt-4">
            <Link
              to="https://acps.myguardianbd.com/claim/online/track/"
              target="_blank"
            >
              <Button className="btn-book btns online-claim-btn">
                {t("claimTrackBtn")}
              </Button>
            </Link>

            {/* <Link to="https://myguardianbd.com/" target="_blank">
              <Buttons classes="btn-learn online-claim-btn">
              {t("claimMyGuardianBtn")}
              </Buttons>
            </Link> */}
          </div>
          <div className="dot-img">
            <img src={BGDot} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimYourTrack;
