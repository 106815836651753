import React from 'react';
import EasyLifeMobileApp from '../EasyLifePages/EasyLifeContact/EasyLifeMobileApp';
import TravelCareBanner from './TravelCareBanner';
import BusinessTour from './BusinessTour';
import HolidayTour from './HolidayTour';
import EmploymentTour from './EmploymentTour';
import StudiesTour from './StudiesTour';
import useEasylifeTitle from '../../hooks/useEasylifeTitle';
import './TravelCare.css'

function EasyLifeGuardianTravelCare() {
    useEasylifeTitle('Guardian Accident Care');
    return (
        <div>
            <TravelCareBanner />
            <BusinessTour/>
            <HolidayTour/>
            <EmploymentTour/>
            <StudiesTour/>
            <EasyLifeMobileApp background={'bg-white'} />
        </div>
    );
}

export default EasyLifeGuardianTravelCare;
