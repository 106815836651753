import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./bookingCard.css";

function BookingShowModal({
  show,
  handleClose,
  showBooking,
  setShowBooking,
  getPaginationList,
}) {
  //   const { name, email, contactNumber, address, bookingFor, date, message } =
  //     showBooking;

  return (
    <Modal show={show} onHide={handleClose} size="lg" scrollable={true}>
      {/* <Modal.Header closeButton>
        <Modal.Title>{bookingFor}</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <div className="card border-0 shadow rounded">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <label className="form-label text-title">
                  <span className="fw-bold me-1"> Name:</span>
                  {showBooking?.name}
                </label>
              </div>
              <div className=" col-6">
                <label className="form-label text-title">
                  <span className="fw-bold me-1">Email:</span>
                  {showBooking?.email}
                </label>
              </div>
              <div className=" col-6">
                <label className="form-label text-title">
                  <span className="fw-bold me-1">Number:</span>
                  {showBooking?.contactNumber}
                </label>
              </div>
              <div className=" col-6">
                <label className="form-label text-title">
                  <span className="fw-bold me-1">Address:</span>
                  {showBooking?.address}
                </label>
              </div>
              <div className=" col-6">
                <label className="form-label text-title">
                  <span className="fw-bold me-1">Booking For:</span>
                  {showBooking?.bookingFor}
                </label>
              </div>
              <div className=" col-6">
                <label className="form-label text-title">
                  <span className="fw-bold me-1 ">Date</span>
                  {showBooking?.date}
                </label>
              </div>
              <div className=" col-12 text-title">
                <p>
                  <span className="fw-bold me-1">Message:</span>
                </p>
                <p className="card-text">{showBooking?.message}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BookingShowModal;
