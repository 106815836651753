import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BgBannerImg from "../../assets/BG/bgLeft.png";
import BannerImg from "../../assets/image/digital-loyalty-banner.jpg";

const BgStyle = {
  backgroundImage: `url(${BgBannerImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};

function DIgitalLoyaltyCardBanner() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const bannerItems = [
    {
      id: 1,
      title: `${t("digitalLoyaltyBannerTitle")}`,
      desc: `${t("digitalLoyaltyBannerDetails")}`,
      img: BannerImg,
    },
  ];

  const [isShowMore, setIsShowMore] = useState(false);
  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <div style={BgStyle}>
      <div className="body-container secondary-container banner-container">
        {bannerItems.map((bannerItem) => (
          <div key={bannerItem.id} className="banner-content">
            <div className="left-content">
              <>
                <h1 className="title banner-title">{bannerItem?.title}</h1>

                <p className="text banner-text">{!isShowMore ? `${bannerItem?.desc.slice(0, 453)}...` : bannerItem?.desc}</p>
                <div className="mt-4">
                  <Button onClick={toggleReadMoreLess} className="btn-book btns">
                    {isShowMore ? `${t("readless")}` : `${t("readmore")}`}
                  </Button>
                </div>
              </>
            </div>
            <div className="right-img">
              <img src={bannerItem?.img} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DIgitalLoyaltyCardBanner;
