import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { getImageUrl } from '../../../../hooks/imageUpload';
import { useRef } from 'react';
import { useState } from 'react';
import JoditEditor from 'jodit-react';

function DashboardPlansEditModal({
    editPlans,
    setEditPlans,
    editPlansModal,
    handleEditClose,
    getAllDataFetch,
}) {
    const editor = useRef(null);
    const [content, setContent] = useState(editPlans?.description);
    const [contentBn, setContentBn] = useState(editPlans?.descriptionBn);
    const handleChange = (e) => {
        setEditPlans({ ...editPlans, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setEditPlans({ ...editPlans, image: e.target.files[0] });
    };

    const handleUpdateAction = async (e) => {
        e.preventDefault();
        handleEditClose();

        const formData = new FormData();

        formData.append('title', editPlans?.title);
        formData.append('titleBn', editPlans?.titleBn);
        formData.append('description', content);
        formData.append('descriptionBn', contentBn);
        formData.append('vedioLink', editPlans?.vedioLink);
        formData.append('image', editPlans?.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/plans/${editPlans?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getAllDataFetch();
                    toast.success('Plans updated successfully');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not a updated Plans!!!!');
            });
    };

    return (
        <Modal
            show={editPlansModal}
            onHide={handleEditClose}
            scrollable={true}
            //   size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Update Plans
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleUpdateAction}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Plans Name
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            defaultValue={editPlans?.title}
                            name="title"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Plans Name(Bangla)
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            defaultValue={editPlans?.titleBn}
                            name="titleBn"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            onChange={handleImage}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Product Vedio Link
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={editPlans?.vedioLink}
                            name="vedioLink"
                            className="form-control"
                            required
                        />
                    </div>
                    {/* <div className="mb-3">
            <label className="form-label  fw-bold">
              Brochure Link ( google drive link)
            </label>
            <input
              type="text"
              onChange={handleChange}
              value={editPlans?.pdfLink}
              name="pdfLink"
              className="form-control"
              required
            />
          </div> */}
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={editPlans?.description}
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Description(Bangla)
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={editPlans?.descriptionBn}
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Update
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardPlansEditModal;
