import React from 'react';
import "./EasyLifeProduct.css";
import EasyLifeProductBanner from '../EasyLifeProductBanner';
import EasyLifeOurProduct from '../EasyLifeOurProduct';
import useEasylifeTitle from '../../../hooks/useEasylifeTitle';

function EasyLifeProduct() {
  useEasylifeTitle("Products");
  return (
    <>
        <EasyLifeProductBanner />
        <EasyLifeOurProduct />
    </>
  )
}

export default EasyLifeProduct