import React, { useEffect, useRef, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { IoMdAddCircle } from 'react-icons/io';
import ReactPaginate from 'react-paginate';
import Loading from '../../../components/Loading/Loading';
import AgentListTable from './AgentListTable/AgentListTable';
import ModalAddAgent from './ModalAddAgent/ModalAddAgent';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import EditAgentModal from './EditAgentModal/EditAgentModal';
import AgentPdfList from './AgentPdfList/AgentPdfList';

function DashboardAllAgentList() {
    const [newAllAgentList, setAllAgentLists] = useState([]);
    const [updateAgent, setUpdateAgent] = useState(null);
    const [deleteAgent, setDeleteAgent] = useState(null);
    const [show, setShow] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(300);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [newAgent, setNewAgent] = useState({
        name: '',
        address: '',
        contactNumber: '',
        nid: '',
        faCode: '',
        umCode: '',
        liscenseNo: '',
        issueOfLicense: '',
        expiryLicense: '',
        workingArea: '',
        image: '',
    });
    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleEditClose = () => setEditModal(false);
    const handleEditShow = () => setEditModal(true);

    const handleChange = (e) => {
        setNewAgent({ ...newAgent, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setNewAgent({ ...newAgent, image: e.target.files[0] });
    };

    // post Agent
    const handleAddAgentList = (e) => {
        e.preventDefault();
        handleClose();

        const formData = new FormData();

        formData.append('name', newAgent.name);
        formData.append('address', newAgent.address);
        formData.append('contactNumber', newAgent.contactNumber);
        formData.append('nid', newAgent.nid);
        formData.append('faCode', newAgent.faCode);
        formData.append('umCode', newAgent.umCode);
        formData.append('liscenseNo', newAgent.liscenseNo);
        formData.append('issueOfLicense', newAgent.issueOfLicense);
        formData.append('expiryLicense', newAgent.expiryLicense);
        formData.append('workingArea', newAgent.workingArea);
        formData.append('image', newAgent.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/agent`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    toast.success('Agent added successfully');
                    setNewAgent('');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not Agent added!!!!');
            });
    };

    // pagination
    function handlePageClick(e) {
        console.log(e);
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function changeLimit() {
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/agent?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllAgentLists(data?.data?.agentLists);
                setIsLoading(false);
            });
    }

    return (
        <>
            <div className="home-content">
                <div className="d-flex justify-content-between align-items-center">
                    <h1 className="dashboard-title">Agent List</h1>
                    <button
                        onClick={handleShow}
                        className="btn btn-sm btn-primary d-flex align-items-center fw-semibold"
                    >
                        <IoMdAddCircle className="add-icon" />{' '}
                        <span>Add Agent</span>
                    </button>
                </div>

                <div className="my-5">
                    <AgentPdfList />
                </div>
                <hr />
                <div className="list-contaner">
                    <div className="input-group mb-2">
                        <span
                            className="input-group-text search-icon"
                            id="search"
                        >
                            <BsSearch />
                        </span>
                        <input
                            onChange={(e) => setSearch(e.target.value)}
                            type="text"
                            className="form-control search-field"
                            placeholder="Search by name, area, liscense No"
                            aria-label="sarech"
                            aria-describedby="search"
                        />
                    </div>

                    {newAllAgentList?.length === 0 ? (
                        <div className="min-vh-100 d-flex align-items-center justify-content-center">
                            No Agent Avaliable
                        </div>
                    ) : (
                        <>
                            <div className="table-responsive">
                                <table className="table table-light table-bordered">
                                    <thead>
                                        <tr className="table-tr">
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Address
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Contact Number
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                NID
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                FA Code
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                UM Code
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Liscense No.
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Issue Of License
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Expiry License
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Working Area
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {newAllAgentList
                                            ?.filter((item) => {
                                                return search.toLowerCase() ===
                                                    ''
                                                    ? item
                                                    : item.name
                                                          .toLowerCase()
                                                          .includes(search) ||
                                                          item.workingArea
                                                              .toLowerCase()
                                                              .includes(
                                                                  search
                                                              ) ||
                                                          item.liscenseNo
                                                              .toLowerCase()
                                                              .includes(search);
                                            })
                                            ?.map((agentList) => (
                                                <AgentListTable
                                                    key={agentList._id}
                                                    agentList={agentList}
                                                    handleShow={handleEditShow}
                                                    setUpdateAgent={
                                                        setUpdateAgent
                                                    }
                                                    getPaginationList={
                                                        getPaginationList
                                                    }
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}

                    <div className="mt-4">
                        <ReactPaginate
                            breakLabel="......."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            renderOnZeroPageCount={null}
                            marginPagesDisplayed={2}
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            activeClassName="active"
                        />

                        {/* <input
              placeholder="limit"
              onChange={(e) => setLimit(e.target.value)}
            />
            <button onClick={changeLimit}>Set Limit</button> */}
                    </div>
                </div>
            </div>
            <ModalAddAgent
                show={show}
                handleClose={handleClose}
                handleChange={handleChange}
                handleImage={handleImage}
                handleAddAgentList={handleAddAgentList}
                newAgent={newAgent}
            />
            <EditAgentModal
                show={editModal}
                handleClose={handleEditClose}
                handleChange={handleChange}
                handleImage={handleImage}
                updateAgent={updateAgent}
                setUpdateAgent={setUpdateAgent}
                getPaginationList={getPaginationList}
            />
        </>
    );
}

export default DashboardAllAgentList;
