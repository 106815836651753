import React from 'react';

const BancassuranceContentEn = ({ ourData }) => {
    return (
        <>
            <h4 className="mb-3">{ourData?.title}</h4>
            <div>
                <span
                    dangerouslySetInnerHTML={{
                        __html: ourData?.description,
                    }}
                ></span>
            </div>
        </>
    );
};

export default BancassuranceContentEn;
