import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "../../../components/Loading/Loading";
import "./ClaimForm.css";

const ClaimForms = () => {
  const { t } = useTranslation();
  const [formClaimsGeneral, setFormClaimsGeneral] = useState([]);
  const [formClaimsStatement, setFormClaimsStatement] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [banglatranslate, setBanglaTraslate] = useState();

  useEffect(() => {
    getClimsForm();
    getClimsForm2();
  }, []);

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setBanglaTraslate(currentLang);
  }, []);

  function getClimsForm() {
    const url = `${process.env.REACT_APP_host}/api/v1/claims-form?formCategory=General`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setFormClaimsGeneral(data?.data?.claimsFormList);
        setIsLoading(false);
      });
  }
  function getClimsForm2() {
    const url = `${process.env.REACT_APP_host}/api/v1/claims-form?formCategory=Statement(Updated)`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setFormClaimsStatement(data?.data?.claimsFormList);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="service-container">
      <div className="body-container secondary-container">
        <h1 class="title mobile-app-title">{t("claimFormTitle")}</h1>
        <div className="mt-3">
          <table className="custom-table w-100">
            <thead>
              <tr>
                <th>{t("claimFormTableTH1")}</th>
                <th>{t("claimFormTableTH2")}</th>
                <th className="text-center">{t("claimFormTableTH3")}</th>
              </tr>
            </thead>
            <tbody>
              {formClaimsGeneral?.length > 0 && (
                <tr>
                  <td rowSpan={formClaimsGeneral?.length + 1}>{t("claimFormTableTBodyT1")}</td>
                </tr>
              )}
              {formClaimsGeneral?.length > 0 &&
                formClaimsGeneral?.map((formGeneralData) => (
                  <>
                    <tr>
                      <td>{banglatranslate === "bn" ? formGeneralData?.titleBn : formGeneralData?.title}</td>
                      <td>
                        <div className="w-100">
                          <a href={formGeneralData?.formLink} target="_blank" className="btn-book btns btn btn-primary form-table-btn" rel="noreferrer">
                            {t("claimFormTableDownloadBtn")}
                          </a>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              {/* <tr>
                <td rowSpan={5}>{t("claimFormTableTBodyT1")}</td>
              </tr>
              <tr>
                <td>{t("claimFormTableTBodyT1L1")}</td>
                <td>
                  <div className="w-100">
                    <a
                      href="https://dashboard.guardianlife.com.bd/uploads/form-file/168310635.pdf"
                      target="_blank"
                      className="btn-book btns btn btn-primary form-table-btn"
                    >
                      {t("claimFormTableDownloadBtn")}
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{t("claimFormTableTBodyT1L2")}</td>
                <td>
                  <div className="w-100">
                    <a
                      href="https://dashboard.guardianlife.com.bd/uploads/form-file/141254166.pdf"
                      target="_blank"
                      className="btn-book btns btn btn-primary form-table-btn"
                    >
                      {t("claimFormTableDownloadBtn")}
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{t("claimFormTableTBodyT1L3")}</td>
                <td>
                  <div className="w-100">
                    <a
                      href="https://dashboard.guardianlife.com.bd/uploads/form-file/949128657.pdf"
                      target="_blank"
                      className="btn-book btns btn btn-primary form-table-btn"
                    >
                      {t("claimFormTableDownloadBtn")}
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{t("claimFormTableTBodyT1L4")}</td>
                <td>
                  <div className="w-100">
                    <a
                      href="https://dashboard.guardianlife.com.bd/uploads/form-file/207825169.pdf"
                      target="_blank"
                      className="btn-book btns btn btn-primary form-table-btn"
                    >
                      {t("claimFormTableDownloadBtn")}
                    </a>
                  </div>
                </td>
              </tr> */}

              {formClaimsStatement?.length > 0 && (
                <tr>
                  <td rowSpan={formClaimsStatement?.length + 1}>{t("claimFormTableTBodyT2")}</td>
                </tr>
              )}

              {formClaimsStatement?.length > 0 &&
                formClaimsStatement?.map((formClaimsStatementData) => (
                  <tr>
                    <td>{banglatranslate === "bn" ? formClaimsStatementData?.titleBn : formClaimsStatementData?.title}</td>
                    <td>
                      <div className="w-100">
                        <a href={formClaimsStatementData?.formLink} target="_blank" className="btn-book btns btn btn-primary form-table-btn" rel="noreferrer">
                          {t("claimFormTableDownloadBtn")}
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}

              {/* <tr>
                <td>{t("claimFormTableTBodyT2L1")}</td>
                <td>
                  <div className="w-100">
                    <a
                      href="
                      https://drive.google.com/file/d/1bR3FY27xqGgsfNhBZA88eWjYL2WABFlQ/view?usp=drive_link
                    "
                      target="_blank"
                      className="btn-book btns btn btn-primary form-table-btn"
                    >
                      {t("claimFormTableDownloadBtn")}
                    </a>
                  </div>
                </td>
              </tr> */}

              {/* <tr>
                <td>{t("claimFormTableTBodyT3L1")}</td>
                <td>
                  <div className="w-100">
                    <a
                      href="https://drive.google.com/file/d/1JryLILlgimAAVyM6_6yz6i2e5LachM9K/view?usp=drive_link"
                      target="_blank"
                      className="btn-book btns btn btn-primary form-table-btn"
                    >
                      {t("claimFormTableDownloadBtn")}
                    </a>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ClaimForms;
