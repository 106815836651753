import React from "react";
import PremiumCalculatorBanner from "./PremiumCalculatorBanner";
import PremiumCalculatorDetails from "./PremiumCalculatorDetails";

function PremiumCalculator() {
  return (
    <>
      <PremiumCalculatorBanner />
      <PremiumCalculatorDetails />
    </>
  );
}

export default PremiumCalculator;
