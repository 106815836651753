import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

function DashboardNoticeBoard() {
    const [addNotice, setAddNotice] = useState({
        noticeName: '',
        noticeNameBn: '',
        noticeLink: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        setAddNotice({
            ...addNotice,
            [e.target.name]: e.target.value,
        });
    };

    const handleAddNotice = async (e) => {
        e.preventDefault();

        const newNotice = {
            noticeName: addNotice?.noticeName,
            noticeNameBn: addNotice?.noticeNameBn,
            noticeLink: addNotice?.noticeLink,
        };

        fetch(`${process.env.REACT_APP_host}/api/v1/notice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newNotice),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data?.status) {
                    toast.success('Notice added successfully');
                    setAddNotice('');
                    navigate('/dashboard/notice-view');
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('Not added successfully');
            });
    };
    return (
        <div className="home-content">
            <div class="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/notice-view"
                    class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Notice</span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center">Add Notice</h1>
                <form onSubmit={handleAddNotice}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Notice Name
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addNotice.noticeName}
                            name="noticeName"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Notice Name(Bangla)
                        </label>
                        <input
                            type="text"
                            name="noticeNameBn"
                            onChange={handleChange}
                            value={addNotice.noticeNameBn}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Notice Link
                        </label>
                        <input
                            type="text"
                            name="noticeLink"
                            placeholder="https://example.com"
                            onChange={handleChange}
                            value={addNotice.noticeLink}
                            className="form-control"
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default DashboardNoticeBoard;
