import React from 'react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

function DashboardClaimsForm() {
    const [addClaimsForm, setAddClaimsForm] = useState({
        formCategory: '',
        formCategoryBn: '',
        title: '',
        titleBn: '',
        formLink: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        setAddClaimsForm({ ...addClaimsForm, [e.target.name]: e.target.value });
    };

    const handleAddClaims = async (e) => {
        e.preventDefault();

        const newClaimsForm = {
            formCategory: addClaimsForm?.formCategory,
            formCategoryBn: addClaimsForm?.formCategoryBn,
            title: addClaimsForm?.title,
            titleBn: addClaimsForm?.titleBn,
            formLink: addClaimsForm?.formLink,
        };
        console.log(newClaimsForm);

        fetch(`${process.env.REACT_APP_host}/api/v1/claims-form`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newClaimsForm),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data?.status) {
                    toast.success('Claims Form added successfully');
                    setAddClaimsForm('');
                    navigate('/dashboard/all-claims');
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('Not added successfully');
            });
    };
    return (
        <div className="home-content">
            <div class="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/all-claims"
                    class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Form</span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center">
                    {' '}
                    Add Claims Form
                </h1>
                <form onSubmit={handleAddClaims}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Form Category
                        </label>
                        <select
                            name="formCategory"
                            class="form-select"
                            onChange={handleChange}
                            // onChange={(e) => setHospitalType(e.target.value)}
                            required
                        >
                            <option value="">Choose Form Category</option>
                            <option value="General">General</option>
                            <option value="Statement(Updated)">
                                Statement(Updated)
                            </option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Form Category(Bangla)
                        </label>
                        <select
                            name="formCategoryBn"
                            class="form-select"
                            onChange={handleChange}
                            // onChange={(e) => setHospitalType(e.target.value)}
                            required
                        >
                            <option value="">Choose Form Category</option>
                            <option value="সাধারণ বিষয়াদি">
                                সাধারণ বিষয়াদি
                            </option>
                            <option value="স্টেটমেন্ট আপডেট">
                                স্টেটমেন্ট আপডেট
                            </option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Title</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addClaimsForm.title}
                            name="title"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Title(Bangla)
                        </label>
                        <input
                            type="text"
                            name="titleBn"
                            onChange={handleChange}
                            value={addClaimsForm.titleBn}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Form Link</label>
                        <input
                            type="text"
                            name="formLink"
                            onChange={handleChange}
                            value={addClaimsForm.formLink}
                            className="form-control"
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default DashboardClaimsForm;
