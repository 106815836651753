import React from "react";
import { BsArrowRight } from "react-icons/bs";
import "./digitalLoayaltyCard.css";

function DigitalCardLoayaltyList({ offerCard, handleViewShow, setViewDigitalLoyalty }) {
  const { img, offer, name, discount, offerDetails, loyaltyStatus } = offerCard;
  return (
    <div className={`blog-card bg-white ${loyaltyStatus ? "" : "d-none"}`}>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="top-text">Up To</p>
          <h1 className="fw-bold discount">{discount}</h1>
          <p className="top-text">Discount</p>
        </div>

        <div className="digital-img">
          <img src={img} alt="" />
        </div>
      </div>

      <div className="card-body-content">
        <p className="fw-bold py-4">@{name}</p>

        <div className="blog-name-title">
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: offerDetails?.length > 100 ? offerDetails?.slice(0, 100) + "..." : offerDetails,
              }}
            ></span>
          </div>

          <hr />
          {/* <p className="blog-text-desc">{desc}</p> */}
          <button
            onClick={() => {
              handleViewShow();
              setViewDigitalLoyalty(offerCard);
            }}
            className="link-learnMore mt-2 border-0"
          >
            View More <BsArrowRight className="learn-icon" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default DigitalCardLoayaltyList;
