import React, { useEffect } from "react";
import "./dashboardLayout.css";

import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { CgProfile } from "react-icons/cg";
import { FaBars } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io";
import { MdKeyboardArrowDown, MdOutlineNotifications, MdVideoLabel } from "react-icons/md";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import LogoImage from "../assets/logo/logo-1.png";
import Loading from "../components/Loading/Loading";
import NotificationView from "../components/Notification/NotificationView";
import useLoggedInUser from "../hooks/useLoggedInUser";
import { dashboardNavName } from "./MenuData";
import SubMenu from "./SubMenu";

function DashboardLayout() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const navigate = useNavigate();
  const [allUserRole, setAllUserRole] = useState([]);
  const [isLoadings, setIsLoadings] = useState(true);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const [users, isLoading] = useLoggedInUser();
  useEffect(() => {
    getAllUserRole();
  }, []);

  const logOut = () => {
    localStorage.removeItem("accessToken");
    navigate("/login");
  };

  function getAllUserRole() {
    const url = `${process.env.REACT_APP_host}/api/v1/user-role`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setAllUserRole(data?.data?.userRoles);
        setIsLoadings(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoadings(false);
      });
  }

  const roleList = allUserRole.find((roles) => roles?.role === users?.role);

  const matchedElement = dashboardNavName.filter((element) => roleList?.menuItems.includes(element?.title));

  if (isLoadings) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div className="sidebar">
        <div className="logo-details">
          <span className="logo_img">
            <img src={LogoImage} />
          </span>
        </div>
        <ul className="nav-links" sidebar={sidebar}>
          <li onClick={showSidebar}>
            {matchedElement?.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </li>
        </ul>
      </div>
      <section className="home-section">
        <nav className="d-flex align-items-center">
          <div className="sidebar-button">
            <i onClick={toggleMenu}>
              <FaBars className="bars-icon d-block d-lg-none" style={{ cursor: "pointer" }} />
            </i>
            <span className="dashboard">Dashboard</span>
          </div>
          <div className="search-box"></div>

          <div className="profile-details">
            <div className="notification-icon">
              <NotificationView />
            </div>
            <Dropdown>
              <Dropdown.Toggle>
                <CgProfile className="text-white" />
                <span className="admin_name text-white text-capitalize">{users?.role}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={logOut}>Log Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* <CgProfile className="profile-icon" />
            <img src={profileImg} alt="" />
            <span className="admin_name">Admin</span>
            <i>
              <MdKeyboardArrowDown />
            </i> */}
          </div>
        </nav>
        <Outlet />
      </section>
    </div>
  );
}

export default DashboardLayout;
