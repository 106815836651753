import React, { useEffect, useState } from "react";
import "./banner.css";
import BgBannerImg from "../../../assets/BG/bgLeft.png";
import BannerImg from "../../../assets/image/WHO-WE-ARE.jpg";
import BannerText from "./BannerText";
import Icon1 from "../../../assets/image/payIcon.PNG";
import Icon2 from "../../../assets/image/payIcon1.PNG";
import Icon3 from "../../../assets/image/payIcon3.PNG";
import BannerCard from "./BannerCard";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const BgStyle = {
  backgroundImage: `url(${BgBannerImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};

function Banner() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const bannerItems = [
    {
      id: 1,
      title: `${t("homeTitle")}`,
      desc: `${t("homeBannerDesc")} `,
      img: BannerImg,
    },
  ];

  const bannersCardItems = [
    {
      id: 1,
      title: `${t("1.1+Crore")}`,
      info: `${t("LivesCovered")}`,
      img: Icon1,
    },
    {
      id: 2,
      title: `${t("BDT1300+Crore")}`,
      info: `${t("PaidinClaims")}`,
      img: Icon2,
    },
    {
      id: 3,
      title: `${t("pcentage")}`,
      info: `${t("ClaimsPayoutRatio")}`,
      img: Icon3,
    },
  ];

  const bookingFor = [
    { id: 1, title: "Retirement" },
    { id: 2, title: "Investment" },
    { id: 3, title: "Term Life" },
    { id: 4, title: "Insurance" },
  ];
  return (
    <div style={BgStyle}>
      <div className="body-container secondary-container banner-container">
        <div className="banner-content">
          <>
            {bannerItems.map((bannerItem) => (
              <BannerText
                bannerItem={bannerItem}
                key={bannerItem.id}
                bookingFor={bookingFor}
              />
            ))}
          </>
        </div>
        <div className="banner-grid">
          {bannersCardItems.map((bannersCardItem) => (
            <BannerCard
              bannersCardItem={bannersCardItem}
              key={bannersCardItem.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Banner;
