import React, { useEffect, useState } from "react";
import "./MediaCornerSubmenu.css";
import MediaNews from "../MediaNews/MediaNews";
import MediaPress from "../MediaPress/MediaPress";
import MediaBlog from "../MediaBlog/MediaBlog";
import MediaVideo from "../MediaVideo/MediaVideo";
import MediaReview from "../MediaReview/MediaReview";
import MediaPhotoGallery from "../MediaPhotoGallery/MediaPhotoGallery";
import { useTranslation } from "react-i18next";
import i18n from "i18next";



function MediaCornerSubmenu() {
  const [menuState, setMenuState] = useState(1);
  const [banglatranslate, setBanglaTraslate] = useState();

  const action = (idx) => {
    setMenuState(idx);
  };

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setBanglaTraslate(currentLang);
  }, []);

  const mediaCornerSubmenus = [
    { submenuId: 1, submenuName: `${t("news")}` },
    { submenuId: 2, submenuName: `${t("press")}` },
    { submenuId: 3, submenuName: `${t("blog")}` },
    { submenuId: 4, submenuName: `${t("video")}` },
    { submenuId: 5, submenuName: `${t("photoGallery")}` },
    // { submenuId: 6, submenuName: `${t("review")}` },
  ];
  return (
    <div className="service-container">
      <div className="body-container secondary-container">
        <div>
          <section className="media-corner-submenu">
            {mediaCornerSubmenus.map((submenu) => (
              <div
                key={submenu.submenuId}
                onClick={() => action(submenu.submenuId)}
                className={
                  menuState === submenu.submenuId
                    ? "active-submenu d-flex justify-content-center align-items-center"
                    : "default-submenu d-flex justify-content-center align-items-center"
                }
              >
                {submenu.submenuName}
              </div>
            ))}
          </section>

          <section>
            <div className={menuState === 1 ? "d-block" : "d-none"}>
              <MediaNews t={t} />
            </div>
            <div className={menuState === 2 ? "d-block" : "d-none"}>
              <MediaPress t={t} />
            </div>
            <div className={menuState === 3 ? "d-block" : "d-none"}>
              <MediaBlog t={t} />
            </div>
            <div className={menuState === 4 ? "d-block" : "d-none"}>
              <MediaVideo t={t} />
            </div>
            <div className={menuState === 5 ? "d-block" : "d-none"}>
              <MediaPhotoGallery t={t} />
            </div>
            {/* <div className={menuState === 6 ? "d-block" : "d-none"}>
              <MediaReview />
            </div> */}
          </section>
        </div>
      </div>
    </div>
  );
}

export default MediaCornerSubmenu;
