import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import img7 from '../../assets/digitalCard/Grand-mostafa.png';
import img2 from '../../assets/digitalCard/NazimgarhResort-removebg.png';
import img5 from '../../assets/digitalCard/artisan.png';
import img6 from '../../assets/digitalCard/hotel_seagull.png';
import img3 from '../../assets/digitalCard/sarahResort-removebg.png';
import img4 from '../../assets/digitalCard/sotiResort-removebg.png';
import img1 from '../../assets/digitalCard/walton-removebg.png';
import Loading from '../../components/Loading/Loading';
import i18n from './../../i18n';
import DigitalCardLoayaltyList from './DigitalCardLoayaltyList';
import DigitalLoyaltyViewModal from './DigitalLoyaltyViewModal';
import './digitalLoayaltyCard.css';

function DigitalLoyaltyCardDetails() {
    const [viewDigitalLoyalty, setViewDigitalLoyalty] = useState(null);
    const [limit, setLimit] = useState(8);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [allOffers, setAllOffers] = useState([]);
    const [banglatranslate, setBanglaTraslate] = useState();
    const [loyaltyCategoryList, setLoyaltyCategoryList] = useState([]);
    const [menuState, setMenuState] = useState(null);

    const [viewShow, setViewShow] = useState(false);

    const handleViewClose = () => setViewShow(false);
    const handleViewShow = () => setViewShow(true);

    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    useEffect(() => {
        getCategoryList();
    }, []);

    useEffect(() => {
        // Once the category list is fetched, check if there are categories available
        if (loyaltyCategoryList.length > 0) {
            setMenuState(loyaltyCategoryList[0]._id);
        }
    }, [loyaltyCategoryList]);

    useEffect(() => {
        // currentPage.current = 1;
        getPaginationList();
    }, []);

    function getCategoryList() {
        const url = `${process.env.REACT_APP_host}/api/v1/loyalty-category`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setLoyaltyCategoryList(data?.data?.loyaltyCategoryList);
            });
    }

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/digital-loyalty-offer`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllOffers(data?.data?.digitalLoylatyOfferList);
                setIsLoading(false);
            });
    }

    const activeOffersByCategory = {};

    // Initialize activeOffersByCategory
    loyaltyCategoryList.forEach((category) => {
        activeOffersByCategory[category.category] = allOffers.filter(
            (offerCard) =>
                offerCard.category === category.category &&
                offerCard.loyaltyStatus === true
        );
    });

    return (
        <>
            <div>
                <section className="loyalty-submenu mt-4">
                    {loyaltyCategoryList.map((category) => (
                        <div
                            key={category._id}
                            onClick={() => setMenuState(category._id)}
                            className={
                                menuState === category._id
                                    ? 'active-submenu d-flex justify-content-center align-items-center'
                                    : 'default-submenu d-flex justify-content-center align-items-center'
                            }
                        >
                            {banglatranslate === 'bn'
                                ? category.categoryBn
                                : category.category}
                        </div>
                    ))}

                    <Link to="/preferred-hospital">
                        <div
                            className={
                                menuState === 'hospital'
                                    ? 'active-submenu d-flex justify-content-center align-items-center'
                                    : 'default-submenu d-flex justify-content-center align-items-center'
                            }
                        >
                            {t('Hospital')}
                        </div>
                    </Link>
                </section>

                {loyaltyCategoryList.map(
                    (category) =>
                        menuState === category._id && (
                            <div
                                key={category._id}
                                className="digitalCard-gird pb-0"
                            >
                                {activeOffersByCategory[category.category]
                                    .length > 0 ? (
                                    activeOffersByCategory[
                                        category.category
                                    ].map((offerCard) => (
                                        <DigitalCardLoayaltyList
                                            offerCard={offerCard}
                                            key={offerCard.id}
                                            setViewDigitalLoyalty={
                                                setViewDigitalLoyalty
                                            }
                                            handleViewShow={handleViewShow}
                                        />
                                    ))
                                ) : (
                                    <div className="h-50 mt-5 d-flex align-items-center justify-content-center">
                                        {banglatranslate === 'bn'
                                            ? category.categoryBn
                                            : category.category}{' '}
                                        {t(`No Available Discount`)}
                                    </div>
                                )}
                            </div>
                        )
                )}
            </div>
            <DigitalLoyaltyViewModal
                viewShow={viewShow}
                viewDigitalLoyalty={viewDigitalLoyalty}
                handleViewClose={handleViewClose}
            />
        </>
    );
}

export default DigitalLoyaltyCardDetails;
