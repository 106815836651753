import i18n from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useEasylifeTitle from "../../hooks/useEasylifeTitle";
import BMIBanner from "./BMIBanner";
import BMICaculatorDetails from "./BMICaculatorDetails";
import BMICalculatorContent from "./BMICalculatorContent";

function BMICalculator() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  useEasylifeTitle("BMI Calculator");

  return (
    <>
      <BMIBanner t={t} />
      <BMICaculatorDetails t={t} />
      <BMICalculatorContent t={t} />
    </>
  );
}

export default BMICalculator;
