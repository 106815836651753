import React, { useEffect, useState } from 'react';
import './milestonesRoad.css';
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Loading from '../../../components/Loading/Loading';
import MilestoneRoadContnet from './MilestoneRoadContnet';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

function MilestonesRoad() {
    const [getAllMilestone, setGetAllMilestone] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [banglatranslate, setBanglaTraslate] = useState();

    const { t } = useTranslation();

    // get all milestoness
    useEffect(() => {
        getAllDataFetch();
    }, [getAllMilestone]);

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    const getAllDataFetch = () => {
        fetch(`${process.env.REACT_APP_host}/api/v1/milestone`)
            .then((res) => res.json())
            .then((data) => {
                setGetAllMilestone(data?.data);
                setIsLoading(false);
            });
    };

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    return (
        <div className="service-container">
            <div className="body-container secondary-container">
                <VerticalTimeline>
                    {getAllMilestone?.map((getMilestone) => (
                        <MilestoneRoadContnet
                            key={getMilestone?._id}
                            getMilestone={getMilestone}
                            banglatranslate={banglatranslate}
                        />
                    ))}
                </VerticalTimeline>
            </div>
        </div>
    );
}

export default MilestonesRoad;
