import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { GiTreehouse } from 'react-icons/gi';

function MilestoneRoadContnet({ getMilestone, banglatranslate }) {
    const { year, yearBn, description, descriptionBn } = getMilestone;
    return (
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
                background: '#232176',
                color: '#fff',
            }}
            contentArrowStyle={{
                borderRight: '7px solid  #232176',
                fontSize: '30px',
            }}
            date={banglatranslate === 'bn' ? yearBn : year}
            iconStyle={{
                background: '#f7941d',
                color: '#ffffff',
                fontSize: '30px',
            }}
            icon={<GiTreehouse />}
        >
            {/* <h3 className="vertical-timeline-element-title">
                Creative Director
              </h3> */}
            <h4 className="vertical-timeline-element-subtitle">
                {banglatranslate === 'bn' ? (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: descriptionBn,
                        }}
                    ></span>
                ) : (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    ></span>
                )}
            </h4>
            {/* <p>
                Creative Direction, User Experience, Visual Design, Project
                Management, Team Leading
              </p> */}
        </VerticalTimelineElement>
    );
}

export default MilestoneRoadContnet;
