import React, { useEffect, useState } from "react";
import { FaStepBackward } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import DashboardPartnerChannelsEditModal from "./DashboardPartnerChannelsEditModal";
import DashboardPartnerChannelsViewModal from "./DashboardPartnerChannelsViewModal";
import DashboardPartnerChannelsViewTable from "./DashboardPartnerChannelsViewTable";

const DashboardPartnerChannelsView = () => {
  const [editModal, setEditModal] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [partnerChannels, setPartnerChannels] = useState([]);
  const [partnerChannelEdit, setPartnerChannelEdit] = useState(null);
  const [partnerChannelView, setPartnerChannelView] = useState({});

  const handleViewClose = () => setViewShow(false);
  const handleViewShow = () => setViewShow(true);
  const handleEditClose = () => setEditModal(false);
  const handleEditShow = () => setEditModal(true);

  useEffect(() => {
    getAllGetAdc();
  }, []);

  function getAllGetAdc() {
    fetch(`${process.env.REACT_APP_host}/api/v1/adc`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setPartnerChannels(data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="home-content">
      <div class="d-flex justify-content-between gap-2 align-items-center">
        <h1 className="dashboard-title">All Partner Channels View</h1>
        <div>
          <Link to="/dashboard/partner-channel" className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white">
            <FaStepBackward />
            <span>Back</span>
          </Link>
        </div>
      </div>
      <div className="table-responsive mt-4">
        <table className="table table-light table-bordered">
          <thead>
            <tr className="table-tr">
              <th scope="col" className="text-center align-middle table-th">
                Partner Channels Name
              </th>

              <th scope="col" className="text-center align-middle table-th">
                Insurance Policies
              </th>
              <th scope="col" className="text-center align-middle table-th">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {partnerChannels &&
              partnerChannels?.map((partnerChannel) => (
                <DashboardPartnerChannelsViewTable
                  partnerChannel={partnerChannel}
                  key={partnerChannel._id}
                  handleEditShow={handleEditShow}
                  handleViewShow={handleViewShow}
                  setPartnerChannelEdit={setPartnerChannelEdit}
                  setPartnerChannelView={setPartnerChannelView}
                  getAllGetAdc={getAllGetAdc}
                />
              ))}
          </tbody>
        </table>
      </div>
      <DashboardPartnerChannelsEditModal
        partnerChannelEdit={partnerChannelEdit}
        handleEditClose={handleEditClose}
        editModal={editModal}
        setPartnerChannelEdit={setPartnerChannelEdit}
        getAllGetAdc={getAllGetAdc}
      />
      <DashboardPartnerChannelsViewModal partnerChannelView={partnerChannelView} handleViewClose={handleViewClose} viewShow={viewShow} setPartnerChannelView={setPartnerChannelView} />
    </div>
  );
};

export default DashboardPartnerChannelsView;
