import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const JobAppliedListViewModal = ({ show, handleClose, showApplyingJob, setShowApplyingJob, getPaginationList, appliedDate }) => {

    return (
        <Modal show={show} onHide={handleClose} scrollable={true} centered>
            <Modal.Header closeButton className='align-items-baseline'>
                <Modal.Title className='book-title pe-4'>Job Applied for "{showApplyingJob?.positionName}"</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card border-0 shadow rounded">
                    <div className="card-body">
                        <div className="row g-2">
                            <div className="col-6">
                                <label className="form-label text-title">
                                    <span className="fw-bold me-1">Name:</span>
                                    {showApplyingJob?.name}
                                </label>
                            </div>
                            <div className=" col-6">
                                <label className="form-label text-title">
                                    <span className="fw-bold me-1">Email:</span>
                                    {showApplyingJob?.email}
                                </label>
                            </div>
                            <div className=" col-6">
                                <label className="form-label text-title">
                                    <span className="fw-bold me-1">Number:</span>
                                    {showApplyingJob?.contactNumber}
                                </label>
                            </div>
                            <div className=" col-6">
                                <label className="form-label text-title">
                                    <span className="fw-bold me-1 ">Applied Date:</span>
                                    {appliedDate}
                                </label>
                            </div>
                            <div className="col-6">
                                <label className="form-label text-title">
                                    <span className="fw-bold me-1">Applied For:</span>
                                    {showApplyingJob?.positionName}
                                </label>
                            </div>
                            <div className="col-6 text-title">
                                <span>
                                    <span className="fw-bold me-1">Resume:</span>
                                    <span>
                                        <Link
                                            target="_blank"
                                            to={showApplyingJob?.imageURL}
                                            className="job-applied-resume"
                                        >
                                            {showApplyingJob?.name}'s Resume
                                        </Link>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default JobAppliedListViewModal;