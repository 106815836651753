import React, { useEffect } from 'react';
import Icon from "../../../assets/image/service.png";
import icon1 from "../../../assets/microinsurance/SimplifiedProducts.svg";
import icon2 from "../../../assets/microinsurance/EconomicPricing.svg";
import icon3 from "../../../assets/microinsurance/CustomizedOffering.svg";
import icon4 from "../../../assets/microinsurance/DigitalEnrolmentAndClaim.svg";
import icon5 from "../../../assets/microinsurance/BestCustomerServices.svg";
import icon6 from "../../../assets/microinsurance/FastestClaimSettlementRatio.svg";
import icon7 from "../../../assets/microinsurance/Win-winSituationForBoth.svg";
import icon8 from "../../../assets/microinsurance/SecuringProtectingFunds.svg";
import icon9 from "../../../assets/microinsurance/RiskDiversification.svg";
import icon10 from "../../../assets/microinsurance/ExperienceSurplus.svg";
import icon11 from "../../../assets/microinsurance/ImprovedCreditRating.svg";
import icon12 from "../../../assets/microinsurance/BetterCustomerExperience.svg";
import { useTranslation } from "react-i18next";
import i18n from "i18next";



const MicroinsuranceFeature = () => {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
    }, []);

    const ourServiceItems = [
        {
            id: 1,
            title: `${t("microinsuranceGuardianMicroCardT1")}`,
            details: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
            icon: icon1
        },
        {
            id: 2,
            title: `${t("microinsuranceGuardianMicroCardT2")}`,
            details: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. final copy is available.",
            icon: icon2
        },
        {
            id: 3,
            title: `${t("microinsuranceGuardianMicroCardT3")}`,
            details: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
            icon: icon3
        },
        {
            id: 4,
            title: `${t("microinsuranceGuardianMicroCardT4")}`,
            details: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
            icon: icon4
        },
        {
            id: 5,
            title: `${t("microinsuranceGuardianMicroCardT5")}`,
            details: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
            icon: icon5
        },
        {
            id: 6,
            title: `${t("microinsuranceGuardianMicroCardT6")}`,
            details: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on before final copy is available.",
            icon: icon6
        },
        {
            id: 7,
            title: `${t("microinsuranceGuardianMicroCardT7")}`,
            details: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without.",
            icon: icon7
        },
        {
            id: 8,
            title: `${t("microinsuranceGuardianMicroCardT8")}`,
            details: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
            icon: icon8
        },
        {
            id: 9,
            title: `${t("microinsuranceGuardianMicroCardT9")}`,
            details: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
            icon: icon9
        },
        {
            id: 10,
            title: `${t("microinsuranceGuardianMicroCardT10")}`,
            details: "In publishing and graphic design, typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
            icon: icon10
        },
        {
            id: 11,
            title: `${t("microinsuranceGuardianMicroCardT11")}`,
            details: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
            icon: icon11
        },
        {
            id: 12,
            title: `${t("microinsuranceGuardianMicroCardT12")}`,
            details: "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.",
            icon: icon12
        }
    ];
    return (
        <div className="service-container pt-0">
            <div className="body-container secondary-container">
                <h1 className="title title-service">{t("microinsuranceGuardianMicroTitle")}</h1>
                {/* <div className="service-grid">
                    {ourServiceItems.map((ourServiceItem) => (
                        <div key={ourServiceItem.id} className='service-card h-100'>
                            <div className="d-flex flex-column">
                                <div className="row gap-3 align-items-center mb-3">
                                    <div className='col-2'>
                                        <div className='micro-img'>
                                            <img src={ourServiceItem.icon} alt="" />
                                        </div>
                                    </div>
                                    <h4 className="col-9 corporate-service-title text-uppercase">{ourServiceItem.title}</h4>
                                </div>
                                <div className="card-info">
                                    <p style={{ fontSize: "14px", lineHeight: "24px" }}>{ourServiceItem.details}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div> */}

                <div className="corporate-service-grid">
                    {ourServiceItems.map((ourServiceItem) => (
                        <div key={ourServiceItem.id}>
                            <div className="service-card h-100">
                                <div className="d-flex justify-content-center">
                                    <div className="micro-img">
                                        <img src={ourServiceItem.icon} alt="" />
                                    </div>
                                </div>
                                <div className="card-info text-center text-capitalize mt-3">
                                    <h4 className="corporate-service-title fw-semibold">{ourServiceItem.title}</h4>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MicroinsuranceFeature;