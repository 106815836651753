import React from "react";
import BannerImg from "../../assets/easylife/policy-options.jpg";
import { FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import i18n from "i18next";

function CancerCareCenterPolicyOption() {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);
  const FeatureItems = [
    {
      id: 1,
      title: `${t("easyPolicy")}`,
      dis: `${t("easyPolicyDesc")}`,
      featureList: [
        {
          id: 1,
          list: `${t("easyPolicydesc1")}`,
        },
        {
          id: 2,
          list: `${t("easyPolicydesc2")}`,
        },
      ],
      img: BannerImg,
    },
  ];
  return (
    <div className="service-container pt-0">
      <div className="body-container secondary-container">
        <div>
          {FeatureItems?.map((items) => (
            <div
              key={items.id}
              className="banner-content flex-column flex-lg-row"
            >
              <div className="right-img">
                <img
                  src={items?.img}
                  alt=""
                  style={{ background: "rgb(243, 243, 243)" }}
                />
              </div>

              <div className="left-content">
                <h1 class="title banner-title text-start">{items?.title}</h1>
                <p className="pt-4">{items?.dis}</p>
                <ul className="text banner-text pb-0 pt-2">
                  {items?.featureList?.map((lst) => (
                    <li key={lst?.id}>
                      <div className="corporate-coverage-list">
                        <div className="corporate-coverage-check">
                          <FaCheckCircle />
                        </div>
                        <p style={{ fontSize: "14px" }}>{lst?.list}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CancerCareCenterPolicyOption;
