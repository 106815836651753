import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

function DashboardLeads() {
    const [addLeads, setAddLeads] = useState({
        name: '',
        submitDate: '',
        birthDate: '',
        gender: '',
        interestedSer: '',
        phoneNumber: '',
        email: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        setAddLeads({ ...addLeads, [e.target.name]: e.target.value });
    };

    const handleAddLeads = async (e) => {
        e.preventDefault();

        const newLeads = {
            name: addLeads?.name,
            submitDate: addLeads?.submitDate,
            birthDate: addLeads?.birthDate,
            gender: addLeads?.gender,
            interestedSer: addLeads?.interestedSer,
            phoneNumber: addLeads?.phoneNumber,
            email: addLeads?.email,
        };

        console.log(newLeads);

        fetch(`${process.env.REACT_APP_host}/api/v1/leads`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newLeads),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data?.status) {
                    console.log(data);
                    toast.success('Leads added successfully');
                    setAddLeads('');
                    navigate('/dashboard/all-leads');
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error('Not added successfully');
            });
    };
    return (
        <div className="home-content">
            <div class="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/all-leads"
                    class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Form</span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <img
                    className="img-fluid h-25 rounded mb-3"
                    src="https://img.freepik.com/premium-photo/businessman-with-protection-gesture-family-life-health-home-insurance-icon-insurance_186616-835.jpg?w=996"
                    alt=""
                />
                <h1 className="dashboard-title text-center">
                    Guardian Life Insurance Limited
                </h1>
                <form onSubmit={handleAddLeads}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Name <sup className="text-danger">*</sup>
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addLeads.name}
                            name="name"
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="row">
                        <div className="mb-3 col-6">
                            <label className="form-label fw-bold">
                                Submit Date <sup className="text-danger">*</sup>
                            </label>
                            <input
                                type="date"
                                name="submitDate"
                                onChange={handleChange}
                                value={addLeads.submitDate}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label fw-bold">
                                Birth Date <sup className="text-danger">*</sup>
                            </label>
                            <input
                                type="date"
                                name="birthDate"
                                onChange={handleChange}
                                value={addLeads.birthDate}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label fw-bold">
                                Gender <sup className="text-danger">*</sup>
                            </label>
                            <select
                                onChange={handleChange}
                                name="gender"
                                class="form-select"
                                required
                            >
                                <option value="" selected>
                                    Please Select
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label fw-bold">
                                Interested Service{' '}
                                <sup className="text-danger">*</sup>
                            </label>
                            <select
                                onChange={handleChange}
                                name="interestedSer"
                                class="form-select"
                                required
                            >
                                <option value="" selected>
                                    Please Select
                                </option>
                                <option value="Saving">Saving</option>
                                <option value="Early Cash">Early Cash</option>
                                <option value="Children">Children</option>
                                <option value="Retirement">Retirement</option>
                                <option value="Investment">Investment</option>
                                <option value="Team Life">Team Life</option>
                                <option value="Supplement Benefits">
                                    Supplement Benefits
                                </option>
                            </select>
                        </div>

                        <div className="mb-3 col-6">
                            <label className="form-label  fw-bold">
                                Phone Number{' '}
                                <sup className="text-danger">*</sup>
                            </label>
                            <input
                                type="tel"
                                onChange={handleChange}
                                value={addLeads.phoneNumber}
                                name="phoneNumber"
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="mb-3 col-6">
                            <label className="form-label  fw-bold">Email</label>
                            <input
                                type="email"
                                onChange={handleChange}
                                value={addLeads.email}
                                name="email"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default DashboardLeads;
