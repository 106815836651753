import React from 'react';

function DashboardBancassurance() {
    return (
        <div className="home-content min-vh-100 d-flex align-items-center justify-content-center ">
            <h1 className="plans-contents"> Bancassurance </h1>
        </div>
    );
}

export default DashboardBancassurance;
