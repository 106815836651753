import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";

function SEOKeywordsEdit({
  show,
  handleClose,
  SEOEditKeywordsList,
  setSEOEditKeywordsList,
  getPaginationList,
}) {
  const [benefitData, setBenefitData] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [textAreaShow, setTextAreaShow] = useState(true);

  const handleItemClick = (item) => {
    setBenefitData(item);

    if (item?._id) {
      setTextAreaShow(false);
      setActiveButton(item?._id);
    }
  };

  const handleChange = (e) => {
    setBenefitData({
      ...benefitData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateAction = (e) => {
    e.preventDefault();

    // handleEditClose();

    const updateKeywords = {
      keywordsName: benefitData?.keywordsName,
    };

    console.log(updateKeywords);

    fetch(
      `${process.env.REACT_APP_host}/api/v1/seo/${SEOEditKeywordsList?._id}/keyword/${benefitData?._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateKeywords),
      }
    )
      .then((res) => {
        if (res.status) {
          getPaginationList();
          console.log(res);
          toast.success("keywords updated successfully");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Not a update keywords!!!!");
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      scrollable={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          SEO KeyWords
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <div className="d-flex flex-column gap-4">
          {SEOEditKeywordsList?.keywords?.map((benefit) => (
            <form
              onSubmit={handleUpdateAction}
              key={benefit._id}
              className="d-flex align-items-center justify-content-between gap-4"
            >
              <div className="w-100">
                <input
                  type="text"
                  onChange={handleChange}
                  defaultValue={benefit?.keywordsName}
                  name="keywordsName"
                  className="form-control"
                  required
                  disabled={benefit?._id === activeButton ? false : true}
                />
              </div>

              <div className="d-flex aling-align-items-center justify-content-center">
                <span
                  onClick={() => handleItemClick(benefit)}
                  className={`btn btn-secondary px-4 ${
                    benefit?._id === activeButton ? "d-none" : "d-block"
                  }`}
                >
                  Edit
                </span>

                <button
                  type="submit"
                  className={`btn btn-primary px-4 ${
                    benefit?._id === activeButton ? "d-block" : "d-none"
                  }`}
                >
                  Update
                </button>
              </div>
            </form>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SEOKeywordsEdit;
