import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

function DashboardBancassuranceEditService({
    editModal,
    handleEditClose,
    updateService,
    setUpdateService,
    getPaginationList,
}) {
    const handleEditChange = (e) => {
        setUpdateService({ ...updateService, [e.target.name]: e.target.value });
    };

    const handleEditImage = (e) => {
        const selectedImage = e.target.files[0];
        setUpdateService({ ...updateService, imageURL: selectedImage });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        handleEditClose();

        const formData = new FormData();

        formData.append('title', updateService?.title);
        formData.append('titleBn', updateService?.titleBn);
        formData.append('image', updateService?.imageURL);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for (const value of formData.values()) {
            console.log(value);
        }

        await axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/bancassurance-service/${updateService?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    toast.success('Service updated successfully!');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not updated!');
            });
    };

    return (
        <Modal
            show={editModal}
            onHide={handleEditClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Update Service
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdate} className="row">
                    <div className="mb-3 col-12">
                        <label className="form-label">Title</label>
                        <input
                            type="text"
                            name="title"
                            onChange={handleEditChange}
                            defaultValue={updateService?.title}
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Title (Bangla)</label>
                        <input
                            type="text"
                            name="titleBn"
                            onChange={handleEditChange}
                            defaultValue={updateService?.titleBn}
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3 col-12">
                        <label className="form-label">Image</label>
                        <input
                            type="file"
                            onChange={handleEditImage}
                            name="image"
                            className="form-control"
                        />
                    </div>

                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default DashboardBancassuranceEditService;
