import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { MdOutlineNotifications, MdOutlineWatchLater } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { NotiContext } from '../../context/NotificationContext/NotificationContext';
import useLoggedInUser from '../../hooks/useLoggedInUser';
import Loading from '../Loading/Loading';
import './notification.css';

const NotificationView = () => {
    const { getIsReadNotification, isReadData } = useContext(NotiContext);
    const [isLoading, setIsLoading] = useState(true);
    const [allNotification, setAllNotification] = useState(null);
    // const [allUserRole, setAllUserRole] = useState([]);

    // const [users] = useLoggedInUser();

    const options = {
        timeZone: 'Asia/Dhaka',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        fractionalSecondDigits: 3,
    };

    useEffect(() => {
        getPaginationList();
    }, [allNotification]);
    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/notification`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setAllNotification(data?.data);
                setIsLoading(false);
                getIsReadNotification();
            });
    }

    const handleNotification = async (idx) => {
        const updatedNotification = {
            isRead: true,
        };

        fetch(`${process.env.REACT_APP_host}/api/v1/notification/${idx}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedNotification),
        })
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    getIsReadNotification();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    // delete notification
    const deletionTimeThreshold = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
    const currentTime = new Date().getTime();
    const deletionTime = new Date(
        currentTime - deletionTimeThreshold
    ).getTime();
    const notificationsToDelete = allNotification?.notificationsList?.filter(
        (notification) => {
            const postedTime = new Date(notification.postedTime).getTime();
            return notification.isRead && postedTime < deletionTime;
        }
    );

    if (notificationsToDelete?.length > 0) {
        notificationsToDelete?.forEach(async (notification) => {
            try {
                const response = await axios.delete(
                    `${process.env.REACT_APP_host}/api/v1/notification/${notification._id}`
                );
            } catch (error) {
                console.error(
                    `Error deleting notification with ID ${notification._id}:`,
                    error
                );
            }
        });
    }
    // end delete

    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className="border-0 notification-container position-relative"
                >
                    <MdOutlineNotifications />
                    <span className="notification-badge">
                        <span>
                            {isReadData?.length > 99
                                ? '99+'
                                : isReadData?.length}
                        </span>
                    </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="notification">
                    <div className="notification-title">
                        <p>Notifications</p>
                    </div>
                    <div className="notification-body">
                        {allNotification.totalNotifications > 0 ? (
                            allNotification?.notificationsList
                                ?.slice(0, 5)
                                .map((noti) => (
                                    <Dropdown.Item
                                        key={noti?._id}
                                        href={`/dashboard/${noti?.slug}`}
                                        onClick={() =>
                                            handleNotification(noti?._id)
                                        }
                                        className={
                                            noti?.isRead === true
                                                ? 'notification-read'
                                                : 'notification-unread'
                                        }
                                    >
                                        <p className="noti-body-title">
                                            {noti?.title.length > 40
                                                ? noti?.title?.slice(0, 39) +
                                                  '...'
                                                : noti?.title}
                                        </p>
                                        <p className="noti-body-time">
                                            <span className="noti-body-time-icon">
                                                <MdOutlineWatchLater />
                                            </span>
                                            <span>
                                                {moment(
                                                    new Date(
                                                        noti?.postedTime
                                                    ).toLocaleString(
                                                        'en-US',
                                                        options
                                                    ),
                                                    'M/D/YYYY, h:mm:ss.SSS A'
                                                ).fromNow()}
                                            </span>
                                        </p>
                                        <p className="noti-body-description">
                                            {noti?.description.length > 40
                                                ? noti?.description?.slice(
                                                      0,
                                                      39
                                                  ) + '...'
                                                : noti?.description}
                                        </p>
                                    </Dropdown.Item>
                                ))
                        ) : (
                            <>
                                <p className="no-notification-msg">
                                    No Notification Found
                                </p>
                            </>
                        )}
                    </div>
                    {allNotification.totalNotifications > 0 && (
                        <div className="notification-footer">
                            <Link to="/dashboard/all-notification">
                                View all
                            </Link>
                        </div>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default NotificationView;
