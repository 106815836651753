import React from 'react';
import "./CorporateServices.css";
import { HiUserGroup } from "react-icons/hi";
import { MdSpatialTracking, MdLocalHospital } from "react-icons/md";
import { ImDownload } from "react-icons/im";
import { BsHeartPulseFill, BsCashStack } from "react-icons/bs";
import { FaAmbulance, FaBusinessTime } from "react-icons/fa";
import { RiHandHeartFill } from "react-icons/ri";
import Icon from "../../../assets/image/service.png";
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";



const CorporateServices = () => {
    const { t } = useTranslation();
    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
    }, []);


    const ourServiceItems = [
        {
            id: 1,
            title: `${t("ourServiceTitle1")}`,
            icon: <HiUserGroup />,
        },
        {
            id: 2,
            title: `${t("ourServiceTitle2")}`,
            icon: <MdLocalHospital />,
        },
        {
            id: 3,
            title: `${t("ourServiceTitle3")}`,
            icon: <ImDownload />,
        },
        {
            id: 4,
            title: `${t("ourServiceTitle4")}`,
            icon: <MdSpatialTracking />,
        },
        {
            id: 5,
            title: `${t("ourServiceTitle5")}`,
            icon: <BsHeartPulseFill />,
        },
        {
            id: 6,
            title: `${t("ourServiceTitle6")}`,
            icon: <FaBusinessTime />,
        },
        {
            id: 7,
            title: `${t("ourServiceTitle7")}`,
            icon: <FaAmbulance />,
        },
        {
            id: 8,
            title: `${t("ourServiceTitle8")}`,
            icon: <RiHandHeartFill />,
        },
    ];
    return (
        <div className="service-container pb-0">
            <div className="">
                <h1 className="title title-service">{`${t("corporateServiceTitle")}`}</h1>
                <h6 className='text-center banner-text mt-3 w-75 mx-auto'>{`${t("corporateServiceDetails")}`}</h6>
                <div className="corporate-service-grid">
                    {ourServiceItems.map((ourServiceItem) => (
                        <div key={ourServiceItem.id}>
                            <div className="service-card h-100">
                                <div className="d-flex flex-column">
                                    <div className='corporate-icon mx-auto'>
                                        <span>
                                            {ourServiceItem?.icon}
                                        </span>
                                    </div>
                                    <div className="card-info text-center text-capitalize mt-3">
                                        <h4 className="corporate-service-title fw-semibold">{ourServiceItem.title}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CorporateServices;