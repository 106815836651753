import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { createContext } from 'react';
import { toast } from 'react-hot-toast';
import { NotiContext } from '../NotificationContext/NotificationContext';

export const BookContext = createContext();

function BookingContext({ children }) {
    const [show, setShow] = useState(false);
    const [users, setUsers] = useState(null);
    const [isLoadings, setIsLoadings] = useState(true);

    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [inputValueBooking, setInputValueBooking] = useState('');
    const [errorMessageBooking, setErrorMessageBooking] = useState('');

    // handle massage lets number talk
    const handleChangeNumberBooking = (e) => {
        const value = e.target.value;

        if (inputValueBooking.length >= 10) {
            setErrorMessageBooking('');
        } else {
            setErrorMessageBooking('Please exactly 11 digits.');
        }

        // Remove any non-digit characters (e.g., spaces) from the input
        const sanitizedValue = value.replace(/\D/g, '');
        // Limit the input to 11 digits
        const limitedValue = sanitizedValue.slice(0, 11);
        setInputValueBooking(limitedValue);
    };

    // handle change client namber message
    const handleChangeNumber = (e) => {
        const value = e.target.value;

        if (inputValue.length >= 10) {
            setErrorMessage('');
        } else {
            setErrorMessage('Please enter exactly 11 digits.');
        }

        // Remove any non-digit characters (e.g., spaces) from the input
        const sanitizedValue = value.replace(/\D/g, '');
        // Limit the input to 11 digits
        const limitedValue = sanitizedValue.slice(0, 11);
        setInputValue(limitedValue);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // get plans
    const url = `${process.env.REACT_APP_host}/api/v1/plans`;
    const { data: allCategoryPlans = [], isLoading } = useQuery({
        queryKey: ['allCategoryPlans'],
        queryFn: async () => {
            try {
                const res = await fetch(url);
                const data = await res.json();
                return data?.data;
            } catch (err) {
                console.log(err);
            }
        },
    });

    //   post booking
    const handleAddBooking = (e) => {
        e.preventDefault();

        const form = e.target;

        const name = form.name.value;
        const email = form.email.value;
        // const contactNumber = form.contactNumber.value;
        const address = form.address.value;
        const bookingFor = form.bookingFor.value;
        const date = form.date.value;
        const message = form.message.value;

        const addBooking = {
            name,
            email,
            contactNumber: inputValueBooking,
            address,
            bookingFor,
            date,
            message,
        };

        if (inputValueBooking.length >= 10) {
            handleClose();
            fetch(`${process.env.REACT_APP_host}/api/v1/booking`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(addBooking),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.status) {
                        toast.success('Booking added successfully');
                        setErrorMessageBooking('');
                        setInputValueBooking('');

                        form.reset();

                        // notification
                        const newNotification = {
                            title: `Booking for ${data?.data?.bookingFor}`,
                            description: `${data?.data?.message}`,
                            postedTime: `${data?.data?.createdAt}`,
                            slug: `booking-list`,
                            menuItem: `All Booking`,
                        };
                        fetch(
                            `${process.env.REACT_APP_host}/api/v1/notification`,
                            {
                                method: 'POST',
                                headers: {
                                    'content-type': 'application/json',
                                },
                                body: JSON.stringify(newNotification),
                            }
                        )
                            .then((res) => res.json())
                            .then((notiData) => {
                                if (notiData.status) {
                                }
                            });
                    }
                })
                .catch((err) => {
                    console.error(err);
                    toast.error('Do not your booking!!!!!');
                });
        } else {
            setErrorMessage('Please exactly 11 digits.');
        }
    };

    // post customer message
    const handleAddMessage = (e) => {
        e.preventDefault();

        const form = e.target;
        const name = form.name.value;
        const email = form.email.value;
        const message = form.message.value;

        if (inputValue.length >= 10) {
            const addMessage = {
                name,
                email,
                contactNumber: inputValue,
                message,
            };
            fetch(`${process.env.REACT_APP_host}/api/v1/client-message`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(addMessage),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.status) {
                        toast.success('Your Message successfully');

                        setErrorMessage('');
                        setInputValue('');
                        form.reset();

                        // notification
                        const newNotification = {
                            title: `${data?.data?.name} sends you a message`,
                            description: `${data?.data?.message}`,
                            postedTime: `${data?.data?.createdAt}`,
                            slug: `customer-message`,
                            menuItem: `All Message`,
                        };
                        fetch(
                            `${process.env.REACT_APP_host}/api/v1/notification`,
                            {
                                method: 'POST',
                                headers: {
                                    'content-type': 'application/json',
                                },
                                body: JSON.stringify(newNotification),
                            }
                        )
                            .then((res) => res.json())
                            .then((notiData) => {
                                if (notiData.status) {
                                }
                            });
                    }
                })
                .catch((err) => {
                    console.error(err);
                    toast.error('Do not send your message!!!!!');
                });
        } else {
            setErrorMessage('Please enter exactly 11 digits.');
        }
    };

    const BookingInfo = {
        handleAddBooking,
        handleAddMessage,
        show,
        handleClose,
        handleShow,
        allCategoryPlans,
        isLoading,
        users,
        isLoadings,
        handleChangeNumber,
        inputValue,
        errorMessage,
        handleChangeNumberBooking,
        inputValueBooking,
        errorMessageBooking,
    };
    return (
        <BookContext.Provider value={BookingInfo}>
            {children}
        </BookContext.Provider>
    );
}

export default BookingContext;
