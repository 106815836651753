import React from 'react';
import "./ClaimTitleOverview.css";
import ClaimOverviewBanner from './ClaimOverviewBanner';
import ClaimOverviewCard from './ClaimOverviewCard';

const ClaimTitleOverview = () => {
    return (
        <div>
            <ClaimOverviewBanner/>
            <ClaimOverviewCard/>
        </div>
    );
};

export default ClaimTitleOverview;