import React from 'react';

const EasyLifeOurOfferCard = ({ allOffer }) => {
  const { title, offerCondition, img, availService, offer } = allOffer;
  return (
    <div className="blog-card">
      <div className="digital-offer-card">
        <img src={img} alt="" />
      </div>
      <div className="card-body-content mt-4">
        <div className="d-flex align-items-center justify-content-between blog-date-time">
          <div className="d-flex flex-column align-items-center">
            <span className="fw-bold mb-1">User Can Avail</span>
            <span className='easy-life-card-text'>{availService}</span>
          </div>
          <div className="d-flex flex-column align-items-center">
            <span className="fw-bold mb-1">Offer</span>
            <span>
              <span>
                <span className='easy-life-card-text'>{offer}</span> Off
              </span>
            </span>
          </div>
        </div>
        <hr />

        <div className="blog-name-title">
          <h4>{title}</h4>
          <p className="blog-text-desc">{offerCondition}</p>
          <div className='mt-4 d-flex justify-content-center'>
            <button type="button" class="btn-book btns card-btn py-1 w-100 btn btn-primary">Avail</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EasyLifeOurOfferCard;