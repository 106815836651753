import React, { useEffect, useState } from "react";
import "./ClaimCashlessFacility.css";
import BgBannerImg from "../../../assets/BG/bgRight.png";
import BannerImg from "../../../assets/image/claim-cashless.png";
import Button from "react-bootstrap/Button";
import CashlessFacilitiesModal from "./CashlessFacilitiesModal";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const BgStyle = {
  backgroundImage: `url(${BgBannerImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",
};

const ClaimCashlessFacility = () => {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const bannerItems = [
    {
      id: 1,
      title: `${t("claimCashlessTitle")}`,
      desc: `${t("claimCashlessDetails")}`,
      contact1: `${t("claimCashlessContactNum1")}`,
      contact2: `${t("claimCashlessContactNum2")}`,
      img: BannerImg,
    },
  ];

  const [isShowMore, setShowMore] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleTextShow = () => {
    setShowMore(!isShowMore);
  };
  return (
    <div style={BgStyle}>
      <div className="body-container secondary-container banner-container">
        {bannerItems.map((bannerItem) => (
          <div
            key={bannerItem.id}
            className="banner-content flex-column-reverse flex-lg-row"
          >
            <div className="left-content">
              <h1 className="title title-service text-start">
                {bannerItem?.title}
              </h1>
              <div className="text banner-text">
                {bannerItem?.desc}
                {/* {!isShowMore
                  ? `${bannerItem?.desc.slice(0, 289)}...`
                  : bannerItem?.desc}
                <Link onClick={handleTextShow} className="fw-bold">
                  {isShowMore ? `--${t("readless")}` : `${t("readmore")}`}
                </Link> */}
              </div>

              <div className="mt-4">
                <span className="contact-title">
                  {t("claimCashlessContactTitle")}
                </span>
                <div className="mt-1 claim-cashless-facility-contact">
                  <span>
                    <Link
                      to={`tel:${bannerItem?.contact1}`}
                      className="claim-cashless-facility-link"
                    >
                      {bannerItem?.contact1}
                    </Link>
                  </span>
                  <span>|</span>
                  <span>
                    <Link
                      to={`tel:${bannerItem?.contact2}`}
                      className="claim-cashless-facility-link"
                    >
                      {bannerItem?.contact2}
                    </Link>
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center gap-3 mt-5">
                <Link
                  to="https://acps.myguardianbd.com/claim/online/gop/request/"
                  target="_blank"
                >
                  <Button className="btn-book btns online-claim-btn">
                    {t("claimCashlessBtn")}
                  </Button>
                </Link>
              </div>
            </div>
            <div className="right-img m-auto">
              <img src={bannerItem?.img} alt="" />
            </div>
          </div>
        ))}

        <CashlessFacilitiesModal show={show} handleClose={handleClose} />
      </div>
    </div>
  );
};

export default ClaimCashlessFacility;
