import React from 'react';
import EasyLifeMobileApp from '../EasyLifePages/EasyLifeContact/EasyLifeMobileApp';
import AccidentalCareBanner from './AccidentalCareBanner';
import WhatAccidentalCare from './WhatAccidentalCare';
import useEasylifeTitle from '../../hooks/useEasylifeTitle';
import WhyAccidentalCare from './WhyAccidentalCare';
import AcceidentalCareFeature from './AcceidentalCareFeature';
import AccidentalPlanCare from './AccidentalPlanCare';
import AccidentalBenfitsTable from './AccidentalBenfitsTable';
import AccidentalHospitaliztionTable from './AccidentalHospitaliztionTable';
import AccidentalCarePDTTable from './AccidentalCarePDTTable';

function EasyLifeGuardianAccidentalCare() {
    useEasylifeTitle('Guardian Accident Care');
    return (
        <div>
            <AccidentalCareBanner />
            <WhatAccidentalCare />
            <WhyAccidentalCare />
            <AcceidentalCareFeature />
            <AccidentalPlanCare />
            <AccidentalBenfitsTable />
            <AccidentalHospitaliztionTable />
            <AccidentalCarePDTTable />
            <EasyLifeMobileApp background={'bg-white'} />
        </div>
    );
}

export default EasyLifeGuardianAccidentalCare;
