import { format } from "date-fns";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFacebookF, FaPinterestP, FaTwitter } from "react-icons/fa";
import { useLoaderData, useLocation } from "react-router-dom";

const MediaSingleContentDetails = () => {
  const data = useLoaderData();

  const [banglatranslate, setBanglaTraslate] = useState();

  const { t } = useTranslation();
  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setBanglaTraslate(currentLang);
  }, []);

  const menuTitle = `${t("easylifeAboutUsMediaMenuTitle")}`;

  const { _id, title, titleBn, description, descriptionBn, imageURL, createdAt } = data.data;
  const postedDate = format(new Date(createdAt), "P");
  const postedTime = format(new Date(createdAt), "p");

  return (
    <div className="service-container">
      <div className="body-container secondary-container">
        <div>
          <div className="d-flex justify-content-center">
            <div className="gallery-title-area">
              <h1 class="title title-service easylife-media-title">{menuTitle}</h1>
            </div>
          </div>
          <div className="card-img blog-details-img section-margin-top px-0">
            <img src={imageURL} alt="img" />
          </div>
          <div className="d-flex align-items-center justify-content-between blog-date-time">
            <span>{postedDate}</span>
            <span>
              <span>{postedTime}</span>
            </span>
          </div>
          <hr />
          <div className="blog-single-title mb-4 mt-4">
            <h4>{banglatranslate === "bn" ? titleBn : title}</h4>
          </div>
          <div className="d-flex flex-column gap-3">
            <p className="blog-text-desc pt-0">
              <span
                dangerouslySetInnerHTML={{
                  __html: banglatranslate === "bn" ? descriptionBn : description,
                }}
              ></span>
            </p>
          </div>
          <div className="mt-4 d-flex gap-4">
            <span className="fw-semibold">{t("easylifeShareText")}:</span>
            <div className="details-share-icon">
              <a href="/">
                <FaFacebookF />
              </a>
              <a href="/">
                <FaTwitter />
              </a>
              <a href="/">
                <FaPinterestP />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaSingleContentDetails;
