import React from "react";
import { Modal } from "react-bootstrap";

function DashboardFAQModalView({ viewFaq, handleViewClose, viewShow }) {
  return (
    <Modal
      show={viewShow}
      onHide={handleViewClose}
      scrollable={true}
      //   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          Single FAQ
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <div>
          <h5 className="fw-bold mt-4 pb-2 border-bottom">{viewFaq?.title}</h5>
          <h5 className="fw-bold mt-4 pb-2 border-bottom">
            {viewFaq?.titleBn}
          </h5>
          <p className="mt-3">
            <span
              dangerouslySetInnerHTML={{
                __html: viewFaq?.description,
              }}
            ></span>
          </p>
          <p className="mt-3">
            <span
              dangerouslySetInnerHTML={{
                __html: viewFaq?.descriptionBn,
              }}
            ></span>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DashboardFAQModalView;
