import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import careerPosition from "../../../assets/image/career-position.png";
import Loading from "../../../components/Loading/Loading";
import i18n from "../../../i18n";
import CareerContactModal from "./CareerContactModal";
import "./CareerDetails.css";

function CareerDetails() {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const handleShow = () => {
    setShow(true);
  };

  const url = `${process.env.REACT_APP_host}/api/v1/department`;
  const { data: allData = [], isLoading } = useQuery({
    queryKey: ["allPlans"],
    queryFn: async () => {
      try {
        const res = await fetch(url);
        const data = await res.json();
        return data?.data;
      } catch (err) {
        console.log(err);
      }
    },
  });

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  const handleDetails = (dataId) => {
    navigate(`/single-career-position/${dataId}`);
  };

  return (
    <div className="service-container bg-white pb-0">
      <div className="body-container secondary-container">
        <div class="career-details-title">
          <h2>{t("careerDetailsTitle")}</h2>
        </div>
        {allData
          ?.filter((career) => career?.jobOfDepartment.some((p) => p?.jobStatus))
          .map((career) => (
            <div key={career?._id}>
              {career?.jobOfDepartment?.length > 0 && (
                <div className="service-container pb-0 bg-white">
                  <h3 className="department-title">{career?.title}</h3>
                  {career?.jobOfDepartment?.map((p) => (
                    <div key={p?.id} className={`position-section ${p?.jobStatus ? "" : "d-none"}`}>
                      <div className="row g-5 align-items-center">
                        <div className="col-10">
                          <h5 className="position-title">{p?.positionName}</h5>
                          <p className="position-type">
                            <span>{p?.address}</span>
                            <span>|</span>
                            <span>{p?.jobType}</span>
                          </p>
                          <p
                            className="position-details"
                            dangerouslySetInnerHTML={{
                              __html: p?.description?.length > 200 ? p?.description.slice(0, 200) + "..." : p?.description,
                            }}
                          ></p>
                        </div>
                        <div className="col-2 career-apply-btn">
                          <button onClick={() => handleDetails(p?._id)}>View Details</button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        <div className="service-container bg-white">
          <div className="text-center">
            <h5 className="position-title">{t("positionTitleQ")}</h5>
            <p className="position-details mt-4">{t("positionDec")}</p>
          </div>
          <div className="w-25 mt-5 mx-auto">
            <div className="career-contact-btn">
              <button onClick={() => handleShow()}>{t("positionBtnDropCV")}</button>
            </div>
          </div>
        </div>

        <CareerContactModal show={show} handleClose={handleClose} />
      </div>
    </div>
  );
}

export default CareerDetails;
