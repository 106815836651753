import React from 'react';
import BannerImg from '../../../assets/easylife/adc-how-to-purchase.png';
import { FaCheckCircle } from 'react-icons/fa';

const ADCHowPurchase = ({ data, banglatranslate, t }) => {
    return (
        <div>
            <div>
                <div className="body-container secondary-container banner-container pb-0">
                    <h1 class="title title-service mb-5">
                        {banglatranslate === 'bn'
                            ? data?.purchaseTitleBn
                            : data?.purchaseTitle}
                    </h1>
                    <div className="banner-content">
                        <div className="right-img">
                            <img src={data?.purchaseImg} alt="" />
                        </div>
                        <div className="left-content">
                            <div className="text banner-text">
                                {banglatranslate === 'bn' ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: data?.purchaseDescBn,
                                        }}
                                    ></span>
                                ) : (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: data?.purchaseDesc,
                                        }}
                                    ></span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ADCHowPurchase;
