import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { IoMdAddCircle } from 'react-icons/io';
import Loading from '../../../components/Loading/Loading';
import ManagementAddModal from './ManagementAddModal';
import ManagementEditModal from './ManagementEditModal';
import ReactPaginate from 'react-paginate';
import ManagementTeamTable from './ManagementTeamTable';
import { getImageUrl } from '../../../hooks/imageUpload';

function DashbaordManagementTeam() {
    const [newAllTeams, setAllTeams] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState(20);
    const [pageCount, setPageCount] = useState(1);
    const [editModal, setEditModal] = useState(false);
    const [addShow, setAddShow] = useState(false);
    const [updateTeam, setUpdateTeam] = useState(null);
    const [newTeam, setNewTeam] = useState({
        name: '',
        nameBn: '',
        designation: '',
        designationBn: '',
        image: '',
    });

    const currentPage = useRef();
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const editorBn = useRef(null);
    const [contentBn, setContentBn] = useState('');

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    const handleAddClose = () => setAddShow(false);
    const handleAddShow = () => setAddShow(true);
    const handleEditClose = () => setEditModal(false);
    const handleEditShow = () => setEditModal(true);

    const handleChange = (e) => {
        setNewTeam({ ...newTeam, [e.target.name]: e.target.value });
    };

    const handleImage = (e) => {
        setNewTeam({ ...newTeam, image: e.target.files[0] });
    };

    // post Agent
    const handleAddAgentList = (e) => {
        e.preventDefault();
        handleAddClose();

        const formData = new FormData();

        formData.append('name', newTeam?.name);
        formData.append('nameBn', newTeam?.nameBn);
        formData.append('designation', newTeam?.designation);
        formData.append('designationBn', newTeam?.designationBn);
        formData.append('description', content);
        formData.append('descriptionBn', contentBn);
        formData.append('image', newTeam?.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for (const value of formData.values()) {
            console.log(value);
        }

        axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/management-team`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    toast.success('Management Team added successfully');
                    setNewTeam('');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not added!!!!');
            });
    };

    // pagination
    function handlePageClick(e) {
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/management-team`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllTeams(data?.data?.managementTeamLists);
                setIsLoading(false);
            });
    }

    return (
        <div className="home-content">
            <div className="d-flex flex-column align-items-center">
                <div className="w-100 d-flex justify-content-between">
                    <h1 className="dashboard-title">Management Team List</h1>
                    <button
                        onClick={handleAddShow}
                        className="btn btn-sm btn-primary d-flex align-items-center fw-semibold"
                    >
                        <IoMdAddCircle className="add-icon" />
                        <span>Add Management Team</span>
                    </button>
                </div>

                <div className="list-contaner w-100">
                    {newAllTeams?.length === 0 ? (
                        <div className="min-vh-100 d-flex align-items-center justify-content-center">
                            No Management Team Is Available
                        </div>
                    ) : (
                        <>
                            <div className="table-responsive">
                                <table className="table table-light table-bordered">
                                    <thead>
                                        <tr className="table-tr">
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Photo
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Designation
                                            </th>
                                            <th
                                                scope="col"
                                                className="text-center align-middle table-th"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {newAllTeams?.map((newTeam) => (
                                            <ManagementTeamTable
                                                key={newTeam._id}
                                                newTeam={newTeam}
                                                handleEditShow={handleEditShow}
                                                setUpdateTeam={setUpdateTeam}
                                                getPaginationList={
                                                    getPaginationList
                                                }
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}

                    {/* <div className="mt-4">
            <ReactPaginate
              breakLabel="......."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            />
          </div> */}

                    <ManagementAddModal
                        show={addShow}
                        handleClose={handleAddClose}
                        handleChange={handleChange}
                        handleImage={handleImage}
                        handleAddAgentList={handleAddAgentList}
                        newTeam={newTeam}
                        setContent={setContent}
                        editor={editor}
                        content={content}
                        setContentBn={setContentBn}
                        editorBn={editorBn}
                        contentBn={contentBn}
                    />

                    <ManagementEditModal
                        editModal={editModal}
                        handleEditClose={handleEditClose}
                        updateTeam={updateTeam}
                        setUpdateTeam={setUpdateTeam}
                        getPaginationList={getPaginationList}
                    />
                </div>
            </div>
        </div>
    );
}

export default DashbaordManagementTeam;
