import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";


const LocateBranchBanner = () => {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
    }, []);
    return (
        <div className='career-banner py-5'>
            <div className='career-banner-content text-center w-75 mx-auto'>
                <h1>{t("locateBranchBannerTitle")}</h1>
                <p>{t("locateBranchBannerDetails")}</p>
            </div> 
        </div>
    );
};

export default LocateBranchBanner;