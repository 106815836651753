import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/Loading/Loading';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { IoIosAddCircle } from 'react-icons/io';
import { CiCircleRemove } from 'react-icons/ci';
import JoditEditor from 'jodit-react';
import './dashboardCategoryPlans.css';

function DashboardCategoryPlans() {
    const [allCategoryPlans, setAllCategoryPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [contentBn, setContentBn] = useState('');
    const [keyFeature, setFeatureKey] = useState([{ keyFeature: '' }]);
    const [keyFeatureBn, setFeatureKeyBn] = useState([{ keyFeatureBn: '' }]);

    const [benefit, setBenefit] = useState([{ benefitDis: '' }]);
    const [benefiteData, setBenefitData] = useState([{ benefitTitle: '' }]);

    const [benefitBn, setBenefitBn] = useState([{ benefitDisBn: '' }]);
    const [benefiteDataBn, setBenefitDataBn] = useState([
        { benefitTitleBn: '' },
    ]);
    const [addCategoryPlans, setAddCategoryPlans] = useState({
        plansId: '',
        categoryTitle: '',
        categoryTitleBn: '',
        image: '',
        categoryVedioLink: '',
    });

    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPlansList();
    }, []);

    const handleChange = (e) => {
        setAddCategoryPlans({
            ...addCategoryPlans,
            [e.target.name]: e.target.value,
        });
    };

    const handleImage = (e) => {
        setAddCategoryPlans({ ...addCategoryPlans, image: e.target.files[0] });
    };

    // feature key
    const handleServiceChange = (e, index) => {
        const { value } = e.target;
        const list = [...keyFeature];
        list[index] = value;
        setFeatureKey(list);
    };

    const handleServiceRemove = (index) => {
        const list = [...keyFeature];
        list.splice(index, 1);
        setFeatureKey(list);
    };

    const handleServiceAdd = () => {
        setFeatureKey([...keyFeature, { keyFeature: '' }]);
    };

    // feature key bangla
    // feature key
    const handleServiceBnChange = (e, index) => {
        const { value } = e.target;
        const list = [...keyFeatureBn];
        list[index] = value;
        setFeatureKeyBn(list);
    };

    const handleServiceBnRemove = (index) => {
        const list = [...keyFeatureBn];
        list.splice(index, 1);
        setFeatureKeyBn(list);
    };

    const handleServiceBnAdd = () => {
        setFeatureKeyBn([...keyFeatureBn, { keyFeatureBn: '' }]);
    };

    // benefits

    const handleBenefitChange = (e, i) => {
        const { name, value } = e.target;
        const onchangeVal = [...benefiteData];
        onchangeVal[i][name] = value;
        setBenefitData(onchangeVal);
    };

    const handleAddDelete = (i) => {
        const deleteVal = [...benefiteData];
        deleteVal.splice(i, 1);
        setBenefitData(deleteVal);
    };
    const handleAddBenefits = () => {
        setBenefitData([...benefiteData, { benefitTitle: '' }]);
        setBenefit([...benefit, { benefitDis: '' }]);
    };

    const handleBenefitDescChange = (e, i) => {
        const value = e;
        const benefitDesc = [...benefit];
        benefitDesc[i] = { value };
        setBenefit(benefitDesc);
    };

    // Using map() method
    const combinedObjects = benefit?.map((description, index) => {
        return {
            benefitDescription: description?.value,
            benefitTitle: benefiteData[index]?.benefitTitle,
        };
    });

    // benefits bangla
    const handleBenefitChangeBn = (e, i) => {
        const { name, value } = e.target;
        const onchangeValBn = [...benefiteDataBn];
        onchangeValBn[i][name] = value;
        setBenefitDataBn(onchangeValBn);
    };

    const handleAddDeleteBn = (i) => {
        const deleteValBn = [...benefiteDataBn];
        deleteValBn.splice(i, 1);
        setBenefitData(deleteValBn);
    };

    const handleAddBenefitsBn = () => {
        setBenefitDataBn([...benefiteDataBn, { benefitTitleBn: '' }]);
        setBenefitBn([...benefitBn, { benefitDisBn: '' }]);
    };

    const handleBenefitDescChangeBn = (e, i) => {
        const value = e;
        const benefitDescBn = [...benefitBn];
        benefitDescBn[i] = { value };
        setBenefitBn(benefitDescBn);
    };

    // Using map() method
    const combinedObjectsBn = benefitBn?.map((descriptionBn, index) => {
        return {
            benefitDescription: descriptionBn?.value,
            benefitTitle: benefiteDataBn[index]?.benefitTitleBn,
        };
    });

    // post category plans

    const handleCategoryPlans = (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('plansId', addCategoryPlans?.plansId);
        formData.append('categoryTitle', addCategoryPlans?.categoryTitle);
        formData.append('categoryTitleBn', addCategoryPlans?.categoryTitleBn);
        formData.append('image', addCategoryPlans?.image);
        formData.append(
            'categoryVedioLink',
            addCategoryPlans?.categoryVedioLink
        );

        [...keyFeature].forEach((feature) => {
            formData.append('keyFeature', feature);
        });

        [...keyFeatureBn].forEach((feature) => {
            formData.append('keyFeatureBn', feature);
        });

        combinedObjects.forEach((item, index) => {
            formData.append(
                `categoryBenefit[${index}][benefitDescription]`,
                item.benefitDescription
            );
            formData.append(
                `categoryBenefit[${index}][benefitTitle]`,
                item.benefitTitle
            );
        });

        combinedObjectsBn.forEach((item, index) => {
            formData.append(
                `categoryBenefitBn[${index}][benefitDescription]`,
                item.benefitDescription
            );
            formData.append(
                `categoryBenefitBn[${index}][benefitTitle]`,
                item.benefitTitle
            );
        });

        formData.append('categoryDescription', content);
        formData.append('categoryDescriptionBn', contentBn);

        for (const value of formData.values()) {
            console.log(value);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/categoryPlans`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    toast.success('Plans added successfully');
                    setAddCategoryPlans(' ');
                    navigate('/dashboard/plans/view-category-plans');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not catagory plans added!!!');
            });
    };

    // get

    function getPlansList() {
        const url = `${process.env.REACT_APP_host}/api/v1/plans`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data?.data);
                setAllCategoryPlans(data?.data);
                setIsLoading(false);
            });
    }

    if (isLoading) {
        return (
            <div className=" vh-100 d-flex justify-content-center align-items-center ">
                <Loading />
            </div>
        );
    }

    return (
        <div className="home-content">
            <div className="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/plans/view-category-plans"
                    className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Details </span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center">
                    Add Category Plans
                </h1>
                <form onSubmit={handleCategoryPlans} className="mt-5">
                    <div className="mb-3 ">
                        <label className="form-label  fw-bold">
                            Plans Name
                        </label>
                        <select
                            onChange={handleChange}
                            name="plansId"
                            class="form-select"
                        >
                            <option value="" selected>
                                Choses a Plans
                            </option>
                            {allCategoryPlans?.map((allPlansCate) => (
                                <option
                                    value={allPlansCate?._id}
                                    key={allPlansCate._id}
                                >
                                    {allPlansCate?.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3  ">
                        <label className="form-label  fw-bold">
                            Category Title
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addCategoryPlans.categoryTitle}
                            name="categoryTitle"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3  ">
                        <label className="form-label  fw-bold">
                            Category Title(Bangla)
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addCategoryPlans.categoryTitleBn}
                            name="categoryTitleBn"
                            className="form-control"
                            required
                        />
                    </div>

                    {/* feature */}

                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Key Feature
                        </label>
                        {keyFeature.map((singleService, index) => (
                            <div
                                key={index}
                                className="d-flex align-items-center gap-2 mb-3"
                            >
                                <div className="d-flex align-items-center gap-2 w-100 ">
                                    <input
                                        name="keyFeature"
                                        type="text"
                                        id="service"
                                        value={singleService?.keyFeature}
                                        className="form-control"
                                        onChange={(e) =>
                                            handleServiceChange(e, index)
                                        }
                                    />
                                    {keyFeature.length - 1 === index &&
                                        keyFeature.length < 4 && (
                                            <button
                                                type="button"
                                                onClick={handleServiceAdd}
                                                className="btn bg-primary text-white"
                                            >
                                                <span>
                                                    <IoIosAddCircle className="feature-btn" />
                                                </span>
                                            </button>
                                        )}
                                </div>
                                <div className="second-division">
                                    {keyFeature.length !== 1 && (
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleServiceRemove(index)
                                            }
                                            className="btn bg-danger text-white"
                                        >
                                            <span>
                                                <CiCircleRemove className="feature-btn" />
                                            </span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* feature bangla */}
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Key Feature(Bangla){' '}
                        </label>
                        {keyFeatureBn.map((singleService, index) => (
                            <div
                                key={index}
                                className="d-flex align-items-center gap-2 mb-3"
                            >
                                <div className="d-flex align-items-center gap-2 w-100 ">
                                    <input
                                        name="keyFeatureBn"
                                        type="text"
                                        id="service"
                                        value={singleService?.keyFeatureBn}
                                        className="form-control"
                                        onChange={(e) =>
                                            handleServiceBnChange(e, index)
                                        }
                                    />
                                    {keyFeatureBn.length - 1 === index &&
                                        keyFeatureBn.length < 4 && (
                                            <button
                                                type="button"
                                                onClick={handleServiceBnAdd}
                                                className="btn bg-primary text-white"
                                            >
                                                <span>
                                                    <IoIosAddCircle className="feature-btn" />
                                                </span>
                                            </button>
                                        )}
                                </div>
                                <div className="second-division">
                                    {keyFeatureBn.length !== 1 && (
                                        <button
                                            type="button"
                                            onClick={() =>
                                                handleServiceBnRemove(index)
                                            }
                                            className="btn bg-danger text-white"
                                        >
                                            <span>
                                                <CiCircleRemove className="feature-btn" />
                                            </span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="mb-3  ">
                        <label className="form-label  fw-bold">Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImage}
                            name="image"
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3  ">
                        <label className="form-label  fw-bold">
                            Product Video Link
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addCategoryPlans.categoryVedioLink}
                            name="categoryVedioLink"
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Introduction
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={content}
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Introduction(Bangla)
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={contentBn}
                            onChange={(newContent) => {
                                setContentBn(newContent);
                            }}
                        />
                    </div>

                    {/* benefits */}

                    <div className="mb-3">
                        <label className="form-label fw-bold">Benefits</label>

                        {benefiteData.map((val, i) => (
                            <div key={i} className="d-flex gap-2">
                                <div className="w-100">
                                    <div className="mb-3 w-100">
                                        <label className="form-label fw-bold">
                                            Title
                                        </label>
                                        <input
                                            name="benefitTitle"
                                            value={val?.benefitTitle}
                                            className="form-control"
                                            onChange={(e) =>
                                                handleBenefitChange(e, i)
                                            }
                                        />
                                    </div>

                                    <div className="mb-3 w-100">
                                        <label className="form-label fw-bold">
                                            Description
                                        </label>
                                        <JoditEditor
                                            ref={editor}
                                            value={val?.benefit}
                                            onChange={(e) =>
                                                handleBenefitDescChange(e, i)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="benefits-clone">
                                    {benefiteData?.length - 1 === i && (
                                        <button
                                            type="button"
                                            onClick={handleAddBenefits}
                                            className="btn bg-primary text-white"
                                        >
                                            <span>
                                                <IoIosAddCircle className="feature-btn" />
                                            </span>
                                        </button>
                                    )}
                                </div>
                                <div className="second-division benefits-clone">
                                    {benefiteData?.length !== 1 && (
                                        <button
                                            type="button"
                                            onClick={() => handleAddDelete(i)}
                                            className="btn bg-danger text-white"
                                        >
                                            <span>
                                                <CiCircleRemove className="feature-btn" />
                                            </span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* benefits bangla */}

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Benefits(Bangla)
                        </label>

                        {benefiteDataBn.map((val, i) => (
                            <div key={i} className="d-flex gap-2">
                                <div className="w-100">
                                    <div className="mb-3 w-100">
                                        <label className="form-label fw-bold">
                                            Title(Bangla)
                                        </label>
                                        <input
                                            name="benefitTitleBn"
                                            value={val?.benefitTitleBn}
                                            className="form-control"
                                            onChange={(e) =>
                                                handleBenefitChangeBn(e, i)
                                            }
                                        />
                                    </div>

                                    <div className="mb-3 w-100">
                                        <label className="form-label fw-bold">
                                            Description(Bangla)
                                        </label>
                                        <JoditEditor
                                            ref={editor}
                                            value={val?.benefitBn}
                                            onChange={(e) =>
                                                handleBenefitDescChangeBn(e, i)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="benefits-clone">
                                    {benefiteDataBn?.length - 1 === i && (
                                        <button
                                            type="button"
                                            onClick={handleAddBenefitsBn}
                                            className="btn bg-primary text-white"
                                        >
                                            <span>
                                                <IoIosAddCircle className="feature-btn" />
                                            </span>
                                        </button>
                                    )}
                                </div>
                                <div className="second-division benefits-clone">
                                    {benefiteDataBn?.length !== 1 && (
                                        <button
                                            type="button"
                                            onClick={() => handleAddDeleteBn(i)}
                                            className="btn bg-danger text-white"
                                        >
                                            <span>
                                                <CiCircleRemove className="feature-btn" />
                                            </span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default DashboardCategoryPlans;
