import React from "react";

function DashboardDistrictName({ getUpdateDivision }) {
  return (
    <>
      {getUpdateDivision?.districts?.map((district, i) => (
        <option key={i}>{district}</option>
      ))}
      {/* <option value={departments?._id} key={departments._id}>
    {departments?.title}
  </option> */}
    </>
  );
}

export default DashboardDistrictName;
