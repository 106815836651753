import React from 'react';
import { MdOutlineWatchLater } from 'react-icons/md';
import { FiEye } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import ReactPaginate from 'react-paginate';
import { useContext } from 'react';
import { NotiContext } from '../../context/NotificationContext/NotificationContext';
import moment from 'moment';
import { toast } from 'react-hot-toast';

const AllNotificationView = () => {
    const { getIsReadNotification } = useContext(NotiContext);
    const [limit, setLimit] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const currentPage = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [allNotification, setAllNotification] = useState(null);

    const options = {
        timeZone: 'Asia/Dhaka',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        fractionalSecondDigits: 3,
    };

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, [allNotification]);

    // pagination
    function handlePageClick(e) {
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/notification?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllNotification(data?.data);
                setIsLoading(false);
                getIsReadNotification();
            });
    }

    const handleNotification = async (idx) => {
        const updatedNotification = {
            isRead: true,
        };

        fetch(`${process.env.REACT_APP_host}/api/v1/notification/${idx}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedNotification),
        })
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    getIsReadNotification();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    return (
        <div className="home-content">
            <h1 class="dashboard-title">All Notifications</h1>

            <div className="bg-white p-4 rounded mt-4">
                <div className="d-flex flex-column gap-4 ps-2">
                    {allNotification.totalNotifications > 0 ? (
                        allNotification?.notificationsList?.map((noti) => (
                            <div
                                key={noti?._id}
                                className={`row align-items-center gx-4 rounded p-2 ${
                                    noti?.isRead === true
                                        ? 'notification-read'
                                        : 'notification-unread'
                                }`}
                            >
                                <div className="col-10">
                                    <p className="noti-body-title">
                                        {noti?.title}
                                    </p>
                                    <p className="noti-body-time">
                                        <span className="noti-body-time-icon">
                                            <MdOutlineWatchLater />
                                        </span>
                                        <span>
                                            {moment(
                                                new Date(
                                                    noti?.postedTime
                                                ).toLocaleString(
                                                    'en-US',
                                                    options
                                                ),
                                                'M/D/YYYY, h:mm:ss.SSS A'
                                            ).fromNow()}
                                        </span>
                                    </p>
                                    <p className="noti-body-description">
                                        {noti?.description}
                                    </p>
                                </div>
                                <div className="col-2">
                                    <Link
                                        to={`/dashboard/${noti?.slug}`}
                                        class="btn btn-sm btn-primary d-flex justify-content-center align-items-center gap-1 fw-semibold text-white"
                                        onClick={() =>
                                            handleNotification(noti?._id)
                                        }
                                    >
                                        <FiEye />
                                        <span>View More</span>
                                    </Link>
                                </div>
                            </div>
                        ))
                    ) : (
                        <>
                            <p className="no-notification-msg">
                                No Notification Found
                            </p>
                        </>
                    )}
                </div>
                {allNotification.totalNotifications > 0 && (
                    <div className="mt-5">
                        <ReactPaginate
                            breakLabel="......."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            renderOnZeroPageCount={null}
                            marginPagesDisplayed={2}
                            containerClassName="pagination justify-content-end"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            activeClassName="active"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllNotificationView;
