import React from 'react';

const ShareholderTableBody = ({ staffList, idx }) => {
    const { id, nameOfShareholder, noOfShare, shareholdings } = staffList;

    return (
        <tr>
            <td className="text-center align-middle fw-bold">{idx + 1}</td>
            <td className="text-center align-middle fw-bold">
                {nameOfShareholder}
            </td>
            <td className="text-center align-middle fw-bold">{noOfShare}</td>
            <td className="text-center align-middle fw-bold">
                {shareholdings}
            </td>
        </tr>
    );
};

export default ShareholderTableBody;
