import React from 'react';

const BancassuranceContentBn = ({ ourDataBn }) => {
    return (
        <>
            <h4 className="mb-3">{ourDataBn?.titleBn}</h4>
            <div>
                <span
                    dangerouslySetInnerHTML={{
                        __html: ourDataBn?.descriptionBn,
                    }}
                ></span>
            </div>
        </>
    );
};

export default BancassuranceContentBn;
