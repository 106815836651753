import { RouterProvider } from "react-router-dom";
import "./App.css";
import { router } from "./routes/Routes/Routes";
import { Suspense } from "react";
import Loading from "./components/Loading/Loading";

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router}></RouterProvider>
    </Suspense>
  );
}

export default App;
