import React from "react";

function OurPlansTitle({ title }) {
  return (
    <div className="d-flex align-items-center gap-4">
      <div className="title banner-title-color">{title}</div>
      <hr className="horzontails-line"></hr>
    </div>
  );
}

export default OurPlansTitle;
