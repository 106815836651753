import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

function AccidentalHospitaliztionTable() {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);

    const overviewLists = [
        {
            id: 1,

            silverPlan: `Maximum coverage for Accidental Hospitalization 
            `,
            goldPlan: `BDT 50,000`,
        },
        {
            id: 2,

            silverPlan: `Sub Limit:`,
            goldPlan: ``,
        },
        {
            id: 3,

            silverPlan: `Hospital room rent per day (Actual cost incurred or maximum whichever is lower)`,
            goldPlan: `BDT 1,250 `,
        },
        {
            id: 4,

            silverPlan: `Total hospital room rent limit per confinement`,
            goldPlan: `BDT 20,000 `,
        },
        {
            id: 5,

            silverPlan: `ICU/CCU limit per confinement`,
            goldPlan: `14 days`,
        },
        {
            id: 6,

            silverPlan: `All other In-Patient treatment expenses inclusive of surgical charges, consultation fees, medicines and relevant medical investigations related to the ailment and other Ancillary Services (excluding room & ICU/CCU charges).
            (Actual cost incurred or maximum whichever is lower)
            `,
            goldPlan: `BDT 30,000 `,
        },
    ];
    return (
        <div className="service-container pt-0">
            <div className="body-container secondary-container">
                <h1 class="title banner-title text-center">
                    BENEFITS SCHEDULE OF ACCIDENTAL HOSPITALIZATION
                </h1>
                <div className="pt-4">
                    <div className="table-responsive">
                        <table className="table table-striped table-light table-bordered">
                            <thead>
                                <tr className="table-tr">
                                    <th
                                        scope="col"
                                        className="text-center align-middle table-th"
                                        style={{ width: '80%' }}
                                    >
                                        Benefits (per person)
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-center align-middle table-th"
                                        style={{ width: '20%' }}
                                    >
                                        Coverage
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {overviewLists.map((list) => (
                                    <tr>
                                        <td className="text-center align-middle table-text fs-6">
                                            {list?.silverPlan}
                                        </td>
                                        <td className="text-center align-middle table-text fs-6">
                                            {list?.goldPlan}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccidentalHospitaliztionTable;
