import React, { useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { FaStepBackward } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Loading from "../../../../components/Loading/Loading";
import DashBoardPostList from "./DashBoardPostList";
import DashboardEditJobPostModal from "./DashboardEditJobPostModal";
import DashboardViewJobPostModal from "./DashboardViewJobPostModal";

function DashboardViewJobPost() {
  const [newJobPostList, setJobPostLists] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [viewShow, setViewShow] = useState(false);
  const [updateJobPost, setUpdateJobPost] = useState(null);
  const [viewJobPost, setViewJobPost] = useState(null);
  const currentPage = useRef();

  useEffect(() => {
    currentPage.current = 1;
    getJobPostList();
  }, []);

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  const handleViewClose = () => setViewShow(false);
  const handleViewShow = () => setViewShow(true);
  const handleEditClose = () => setEditModal(false);
  const handleEditShow = () => setEditModal(true);

  // pagination
  function handlePageClick(e) {
    console.log(e);
    currentPage.current = e.selected + 1;
    getJobPostList();
  }

  function changeLimit() {
    getJobPostList();
  }

  function getJobPostList() {
    const url = `${process.env.REACT_APP_host}/api/v1/job?page=${currentPage.current}&limit=${limit}`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data?.data?.jobOfDepartments);
        setPageCount(data?.data?.page);
        setJobPostLists(data?.data?.jobOfDepartments);
        console.log(data?.data?.jobOfDepartments);
        setIsLoading(false);
      });
  }

  return (
    <>
      <div className="home-content">
        <div class="d-flex justify-content-between gap-2 align-items-center">
          <h1 className="dashboard-title">All Job</h1>
          <div>
            <Link to="/dashboard/jobs/post-jobs" className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white">
              <FaStepBackward />
              <span>Back </span>
            </Link>
          </div>
        </div>

        <div className="list-contaner">
          {/* <div className="input-group mb-2">
          <span className="input-group-text search-icon" id="search">
            <BsSearch />
          </span>
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            className="form-control search-field"
            placeholder="Search by name, designation, department"
            aria-label="sarech"
            aria-describedby="search"
          />
        </div> */}

          {newJobPostList?.length === 0 ? (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">No Job Avaliable</div>
          ) : (
            <>
              <div className="table-responsive">
                <table className="table table-light table-bordered">
                  <thead>
                    <tr className="table-tr">
                      <th scope="col" className="text-center align-middle table-th">
                        Position Name
                      </th>
                      <th scope="col" className="text-center align-middle table-th">
                        Address
                      </th>
                      <th scope="col" className="text-center align-middle table-th">
                        Job Type
                      </th>
                      <th scope="col" className="text-center align-middle table-th">
                        Status
                      </th>
                      <th scope="col" className="text-center align-middle table-th">
                        Description
                      </th>

                      <th scope="col" className="text-center align-middle table-th">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {newJobPostList
                      // .filter((item) => {
                      //   return search.toLowerCase() === ""
                      //     ? item
                      //     : item.name.toLowerCase().includes(search) ||
                      //         item.workingArea.toLowerCase().includes(search) ||
                      //         item.liscenseNo.toLowerCase().includes(search);
                      // })
                      .map((jobPostList) => (
                        <DashBoardPostList
                          key={jobPostList._id}
                          jobPostList={jobPostList}
                          handleViewShow={handleViewShow}
                          handleEditShow={handleEditShow}
                          setViewJobPost={setViewJobPost}
                          getJobPostList={getJobPostList}
                          setUpdateJobPost={setUpdateJobPost}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          <div className="mt-4">
            <ReactPaginate
              breakLabel="......."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            />

            {/* <input
              placeholder="limit"
              onChange={(e) => setLimit(e.target.value)}
            />
            <button onClick={changeLimit}>Set Limit</button> */}
          </div>
        </div>
      </div>

      <DashboardViewJobPostModal viewShow={viewShow} handleViewClose={handleViewClose} viewJobPost={viewJobPost} />
      <DashboardEditJobPostModal show={editModal} handleClose={handleEditClose} getJobPostList={getJobPostList} updateJobPost={updateJobPost} />
    </>
  );
}

export default DashboardViewJobPost;
