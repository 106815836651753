import React from "react";
import { useState } from "react";
import "./ourCustemer.css";
import img1 from "../../../assets/image/users.svg";
import img2 from "../../../assets/image/user-plus.svg";
import img3 from "../../../assets/image/target.svg";
import img4 from "../../../assets/image/bangladesh-map.svg";
import ourEasyAbout from "../../../assets/image/aboutesayLife.png";
import { Button } from "react-bootstrap";
import OurCustomerCard from "./OurCustomerCard";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";



function OurCustomer() {
  const [isShowMore, setShowMore] = useState(false);
  const handleShow = () => {
    setShowMore(!isShowMore);
  };

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const bannerItems = [
    {
      id: 1,
      title: `${t("easylifeAboutEasylifeTitle")}`,
      desc: `${t("easylifeAboutEasylifeDetails")}`,
      img: ourEasyAbout,
    },
  ];

  const ourClients = [
    {
      id: 1,
      numberClient: `${t("easylifeAboutUsCustomerCardTitle1")}`,
      desc: `${t("easylifeAboutUsCustomerCardDetails1")}`,
      img: img1,
    },
    {
      id: 2,
      numberClient: `${t("easylifeAboutUsCustomerCardTitle2")}`,
      desc: `${t("easylifeAboutUsCustomerCardDetails2")}`,
      img: img2,
    },
    {
      id: 3,
      numberClient: `${t("easylifeAboutUsCustomerCardTitle3")}`,
      desc: `${t("easylifeAboutUsCustomerCardDetails3")}`,
      img: img3,
    },
    {
      id: 4,
      numberClient: `${t("easylifeAboutUsCustomerCardTitle4")}`,
      desc: `${t("easylifeAboutUsCustomerCardDetails4")}`,
      img: img4,
    },
  ];
  return (
    <div className="body-container secondary-container banner-container pb-0">
      <div>
        {bannerItems.map((bannerItem) => (
          <div key={bannerItem.id} className="banner-content">
            <div className="left-content">
              <>
                <h1 className="title banner-title">{bannerItem?.title}</h1>
                <p className="text banner-text">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: !isShowMore
                        ? bannerItem?.desc.slice(0, 500) + "..."
                        : bannerItem?.desc,
                    }}
                  ></span>
                </p>
                <div className="mt-3 d-flex align-items-center gap-3">
                  <Button className="btn-book btns" onClick={handleShow}>
                    {isShowMore ? `${t("readless")}` : `${t("readmore")}`}
                  </Button>
                </div>
              </>
            </div>
            <div className="right-img d-flex justify-content-center align-items-center">
              <img src={bannerItem?.img} alt="" />
            </div>
          </div>
        ))}
      </div>
      <div className="ourClient-grid">
        {ourClients.map((ourClient) => (
          <OurCustomerCard ourClient={ourClient} key={ourClient.id} />
        ))}
      </div>
    </div>
  );
}

export default OurCustomer;
