import React, { useEffect, useRef, useState } from 'react';
import { FaStepBackward } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading/Loading';
import DashboardLeadsEdit from './DashboardLeadsEdit';
import DashboardLeadsList from './DashboardLeadsList';
import ReactPaginate from 'react-paginate';

function DashboardLeadsView() {
    const [leads, setLeads] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editModal, setEditModal] = useState(false);
    const [updateLeads, setUpdateLeads] = useState(null);
    const [pageCount, setPageCount] = useState(1);
    const [limit, setLimit] = useState(100);

    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    const handleEditClose = () => setEditModal(false);
    const handleEditShow = () => setEditModal(true);

    function handlePageClick(e) {
        console.log(e);
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/leads?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setLeads(data?.data?.leadsList);
                setIsLoading(false);
            });
    }

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }
    return (
        <div className="home-content">
            <div class="d-flex justify-content-between gap-2 align-items-center">
                <h1 className="dashboard-title">All Lead</h1>
                <div>
                    <Link
                        to="/dashboard/leads"
                        className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                    >
                        <FaStepBackward />
                        <span>Back</span>
                    </Link>
                </div>
            </div>

            <div className="list-contaner">
                {leads?.length === 0 ? (
                    <div className="min-vh-100 d-flex align-items-center justify-content-center">
                        No Leads Is Available
                    </div>
                ) : (
                    <>
                        <div className="table-responsive">
                            <table className="table table-light table-bordered">
                                <thead>
                                    <tr className="table-tr">
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Submitted Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Birth Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Phone NO.
                                        </th>

                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Interested Service
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Gender
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Email
                                        </th>

                                        <th
                                            scope="col"
                                            className="text-center align-middle table-th"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leads?.map((leadsData, i) => (
                                        <DashboardLeadsList
                                            key={leadsData._id}
                                            leadsData={leadsData}
                                            idx={i}
                                            handleEditShow={handleEditShow}
                                            setUpdateLeads={setUpdateLeads}
                                            getPaginationList={
                                                getPaginationList
                                            }
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}

                <DashboardLeadsEdit
                    handleEditClose={handleEditClose}
                    editModal={editModal}
                    updateLeads={updateLeads}
                    getPaginationList={getPaginationList}
                    setUpdateLeads={setUpdateLeads}
                />
            </div>
            <div className="mt-4">
                <ReactPaginate
                    breakLabel="......."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName="pagination justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                />

                {/* <input
              placeholder="limit"
              onChange={(e) => setLimit(e.target.value)}
            />
            <button onClick={changeLimit}>Set Limit</button> */}
            </div>
        </div>
    );
}

export default DashboardLeadsView;
