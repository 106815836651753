import React, { useEffect, useRef, useState } from 'react';
import JoditEditor from 'jodit-react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import Loading from '../../../components/Loading/Loading';

const DashboardEditHospitalModal = ({
    show,
    handleClose,
    getHospitalList,
    updateHospital,
    setEditModal,
}) => {
    const editor = useRef(null);
    const [country, setCountry] = useState(updateHospital?.country);
    const [hospitalType, setHospitalType] = useState(
        updateHospital?.hospitalType
    );
    const [hospitalCategory, setHospitalCategory] = useState(
        updateHospital?.hospitalCategory
    );

    const [division, setDivision] = useState(updateHospital?.division || '');
    const [district, setDistrict] = useState(updateHospital?.district || '');
    const [area, setArea] = useState(updateHospital?.area);
    const [hospitalId, setHospitalId] = useState(updateHospital?.hospitalId);
    const [hospitalName, setHospitalName] = useState(
        updateHospital?.hospitalName
    );
    const [address, setAddress] = useState(updateHospital?.address);
    const [contact, setContact] = useState(updateHospital?.contactDetails);
    const [facilities, setFacilities] = useState(updateHospital?.facilities);
    const [remark, setRemark] = useState(updateHospital?.remarks);
    const [getDivision, setGetDivision] = useState(null);
    const currentPage = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        currentPage.current = 1;
        getNameByDivision();
    }, [division, hospitalType]);

    useEffect(() => {
        if (updateHospital && updateHospital?.hospitalType) {
            setHospitalType(updateHospital?.hospitalType);
        }
        if (updateHospital && updateHospital?.hospitalCategory) {
            setHospitalCategory(updateHospital?.hospitalCategory);
        }
        if (updateHospital && updateHospital?.country) {
            setCountry(updateHospital?.country);
        }
        if (updateHospital && updateHospital?.division) {
            setDivision(updateHospital?.division);
        }
        if (updateHospital && updateHospital?.district) {
            setDistrict(updateHospital?.district);
        }
        if (updateHospital && updateHospital?.area) {
            setArea(updateHospital?.area);
        }
        if (updateHospital && updateHospital?.area) {
            setArea(updateHospital?.area);
        }
        if (updateHospital && updateHospital?.hospitalId) {
            setHospitalId(updateHospital?.hospitalId);
        }
        if (updateHospital && updateHospital?.hospitalName) {
            setHospitalName(updateHospital?.hospitalName);
        }

        if (updateHospital && updateHospital?.address) {
            setAddress(updateHospital?.address);
        }
        if (updateHospital && updateHospital?.remarks) {
            setRemark(updateHospital?.remarks);
        }
    }, [updateHospital, updateHospital?.hospitalType]);

    useEffect(() => {
        if (!show) {
            setHospitalType(updateHospital?.hospitalType);
            setCountry(updateHospital?.country);
            setDivision(updateHospital?.division);
            setDistrict(updateHospital?.district);
            setRemark(updateHospital?.remarks);
            setAddress(updateHospital?.address);
            setHospitalName(updateHospital?.hospitalName);
            setHospitalId(updateHospital?.hospitalId);
        }
    }, [show]);

    const url = `${process.env.REACT_APP_host}/api/v1/division-district`;
    const { data: allDivisionDistrict = [], isLoading } = useQuery({
        queryKey: [' allDivisionDistrict'],
        queryFn: async () => {
            try {
                const res = await fetch(url);
                const data = await res.json();
                return data?.data;
            } catch (err) {
                console.log(err);
            }
        },
    });

    // get by name division
    function getNameByDivision() {
        const url = `${process.env.REACT_APP_host}/api/v1/division-district?division=${division}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status) {
                    setGetDivision(data?.data);
                    // setDivision("");
                }
            });
    }
    // all country
    const { data: allCountry = [] } = useQuery({
        queryKey: [' allCountry'],
        queryFn: async () => {
            try {
                const res = await fetch(
                    `${process.env.REACT_APP_host}/api/v1/country`
                );
                const data = await res.json();
                return data?.data;
            } catch (err) {
                console.log(err);
            }
        },
    });

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    const handleUpdateAction = (e) => {
        e.preventDefault();
        handleModalClose();

        const newHospital = {
            hospitalType,
            hospitalCategory,
            country: country,
            division,
            district,
            area,
            hospitalId,
            hospitalName,
            address,
            contactDetails: contact,
            facilities,
            remarks: remark,
        };

        console.log(newHospital);

        fetch(
            `${process.env.REACT_APP_host}/api/v1/hospital/${updateHospital?._id}`,
            {
                method: 'PATCH',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(newHospital),
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.status) {
                    getHospitalList();
                    console.log(data);
                    toast.success('Hospital updated successfully');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not updated Hospital!!!!');
            });
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        handleClose();
    };

    return (
        <Modal
            show={show}
            onHide={handleModalClose}
            scrollable={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Hospital Update
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction}>
                    <div className="mb-3">
                        <label className="form-label">
                            <span className="fw-bold">
                                Hospital Type{' '}
                                <span className="text-danger">*</span>{' '}
                            </span>
                        </label>
                        <select
                            name="hospitalType"
                            class="form-select"
                            onChange={(e) => setHospitalType(e.target.value)}
                            defaultValue={updateHospital?.hospitalType}
                            required
                        >
                            <option value="">Choose hospital type</option>
                            <option value="national">National</option>
                            <option value="international">International</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            National Hospital Category
                        </label>
                        <select
                            name="hospitalCategory"
                            class="form-select"
                            onChange={(e) =>
                                setHospitalCategory(e.target.value)
                            }
                            defaultValue={updateHospital?.hospitalCategory}
                        >
                            <option value="">Choose hospital category</option>
                            <option value="hospital">Hospital</option>
                            <option value="diagnostic">
                                Diagnostic Center
                            </option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">
                            <span className="fw-bold">Country </span>
                        </label>
                        <select
                            name="country"
                            class="form-select"
                            onChange={(e) => setCountry(e.target.value)}
                            defaultValue={updateHospital?.country}
                        >
                            <option value="">Choose Country</option>
                            {allCountry?.map((country) => (
                                <option
                                    key={country?.name}
                                    value={country?.name}
                                >
                                    {country?.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">
                            <span className="fw-bold">Division </span>
                        </label>
                        <select
                            name="division"
                            class="form-select"
                            onChange={(e) => setDivision(e.target.value)}
                            defaultValue={updateHospital?.division}
                        >
                            <option value="">Choose division</option>
                            {allDivisionDistrict?.map((divisions) => (
                                <option key={divisions._id}>
                                    {divisions?.division}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">
                            <span className="fw-bold">District </span>
                        </label>
                        <select
                            name="district"
                            class="form-select"
                            onChange={(e) => setDistrict(e.target.value)}
                            value={district || ''}
                        >
                            <option value="">Choses district</option>
                            {getDivision?.map((getUpdateDivision) =>
                                getUpdateDivision?.districts?.map(
                                    (district, i) => (
                                        <option key={i}>{district}</option>
                                    )
                                )
                            )}
                        </select>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">
                            <span className="fw-bold">Area </span>
                        </label>
                        <input
                            type="text"
                            onChange={(e) => setArea(e.target.value)}
                            defaultValue={updateHospital?.area}
                            name="area"
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Hospital ID
                        </label>
                        <input
                            type="text"
                            onChange={(e) => setHospitalId(e.target.value)}
                            defaultValue={updateHospital?.hospitalId}
                            name="hospitalId"
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Hospital Name
                        </label>
                        <input
                            type="text"
                            onChange={(e) => setHospitalName(e.target.value)}
                            defaultValue={updateHospital?.hospitalName}
                            name="hospitalName"
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label  fw-bold">Address</label>
                        <textarea
                            rows={2}
                            onChange={(e) => setAddress(e.target.value)}
                            defaultValue={updateHospital?.address}
                            name="address"
                            className="form-control"
                        ></textarea>
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Contact Details
                        </label>
                        <JoditEditor
                            ref={editor}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setContact(newContent);
                            }}
                            value={updateHospital?.contactDetails}
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">Facilities</label>
                        <JoditEditor
                            ref={editor}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setFacilities(newContent);
                            }}
                            value={updateHospital?.facilities}
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label  fw-bold">Remarks</label>
                        <textarea
                            rows={2}
                            onChange={(e) => setRemark(e.target.value)}
                            defaultValue={updateHospital?.remarks}
                            name="remark"
                            className="form-control"
                        ></textarea>
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default DashboardEditHospitalModal;
