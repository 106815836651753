import JoditEditor from 'jodit-react';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import axios from 'axios';

function DashboardPopUp() {
    const editor = useRef(null);
    const [updatePopUp, setUpdatePopUp] = useState({});
    const [content, setContent] = useState(updatePopUp?.popUpDec);
    const [image, setImage] = useState(null);
    const [isSwitchOn, setIsSwitchOn] = useState(false);

    const handleChange = (e) => {
        setUpdatePopUp({
            ...updatePopUp,
            [e.target.name]: e.target.value,
        });
    };

    const handleChangeStatus = (e) => {
        setIsSwitchOn(e.target.checked);
    };

    const handleImage = (e) => {
        setImage(e.target.files[0]);
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_host}/api/v1/pop-up`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setUpdatePopUp(data?.data?.popUpList[0]);
                setIsSwitchOn(data?.data?.popUpList[0].popUpStatus);
            });
    }, []);

    const handleUpdatePopUp = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        // formData.append('popUpTitle', updatePopUp?.popUpTitle);
        // formData.append('popUpDec', content);
        // formData.append('videoLink', updatePopUp?.videoLink);
        // formData.append('type', updatePopUp?.type);
        formData.append('popUpStatus', isSwitchOn);
        formData.append('image', image);

        for (const value of formData.values()) {
            console.log(value);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        try {
            const res = await axios.put(
                `${process.env.REACT_APP_host}/api/v1/pop-up/${updatePopUp?._id}`,
                formData,
                config
            );

            if (res.status === 200) {
                console.log(res);
                toast.success('PopUp updated successfully!');
            }
        } catch (error) {
            console.error(error);
            toast.error('PopUp not updated!');
        }
    };

    return (
        <div className="home-content">
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center">Edit Pop Up</h1>
                <form onSubmit={handleUpdatePopUp}>
                    {/* <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Pop Up Title
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            defaultValue={updatePopUp?.popUpTitle}
                            name="popUpTitle"
                            className="form-control"
                            required
                        />
                    </div> */}
                    {/* <div className="mb-3">
            <label className="form-label  fw-bold">Pop Up Title(Bangla)</label>
            <input type="text" name="popUpTitleBn" onChange={handleChange} value={addPopUp.popUpTitleBn} className="form-control" required />
          </div> */}
                    {/* <div className="mb-3">
                        <label className="form-label fw-bold">
                            Pop Up Description
                        </label>
                        <JoditEditor
                            ref={editor}
                            value={updatePopUp?.popUpDec}
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div> */}
                    {/* <div className="mb-3">
            <label className="form-label fw-bold">Pop Up Description(Bangla)</label>
            <JoditEditor
              ref={editor}
              value={contentBn}
              onChange={(newContent) => {
                setContentBn(newContent);
              }}
            />
          </div> */}
                    {/* <div className="mb-3">
                        <label className="form-label fw-bold">
                            Select Display Type
                        </label>
                        <select
                            onChange={handleChange}
                            name="type"
                            value={updatePopUp?.type}
                            class="form-select"
                            required
                        >
                            <option value="" selected>
                                Choses a Display Type
                            </option>
                            <option value="Video">Video</option>
                            <option value="Image">Image</option>
                        </select>
                    </div> */}
                    {/* {updatePopUp?.type === 'Video' && (
                        <div className="mb-3">
                            <label className="form-label  fw-bold">
                                Video Link
                            </label>
                            <input
                                type="url"
                                name="videoLink"
                                placeholder="https://example.com"
                                onChange={handleChange}
                                defaultValue={updatePopUp?.videoLink}
                                className="form-control"
                            />
                        </div>
                    )} */}

                    {/* {updatePopUp?.type === 'Image' && ( */}
                    <div className="mb-5">
                        <label className="form-label fw-bold">
                            Upload Image
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            onChange={handleImage}
                            className="form-control"
                        />
                    </div>
                    {/* )} */}
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Pop Up Status
                        </label>

                        <div className="d-flex gap-2">
                            <input
                                className="form-check-input d-block"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckChecked"
                                onChange={(e) => {
                                    handleChangeStatus(e);
                                }}
                                checked={isSwitchOn}
                            />
                            <label className="form-check-label">
                                {isSwitchOn === true ? (
                                    <span className="text-success fw-bold">
                                        {' '}
                                        Active
                                    </span>
                                ) : (
                                    <span className="text-danger fw-bold">
                                        Inactive
                                    </span>
                                )}
                            </label>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default DashboardPopUp;
