import React from "react";
import BgBannerImg from "../../../assets/BG/bgLeft.png";
import BannerImg from "../../../assets/easylife/banner/banner-7.jpg";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const BgStyle = {
  backgroundImage: `url(${BgBannerImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};

const bannerItems = [
  {
    id: 1,
    title: `EasyLife Contact`,
    desc: `Our knowledgeable and friendly support staff is available to address your needs promptly. You can reach out to us through various channels, including email, phone, or our online contact form. We strive to provide exceptional customer service and ensure that your experience with us is positive and satisfying. Don't hesitate to get in touch with us; we look forward to hearing from you and helping you in any way we can.`,
    img: BannerImg,
  },
];
function EasyLifeContactBanner() {
  return (
    <div style={BgStyle}>
      <div className="body-container secondary-container banner-container">
        {bannerItems.map((bannerItem) => (
          <div key={bannerItem.id} className="banner-content">
            <div className="left-content">
              <>
                <h1 className="title banner-title">{bannerItem?.title}</h1>

                <p className="text banner-text">{bannerItem?.desc}</p>
                <div className="mt-4">
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.sslwireless.easylife"
                    target=" _blank"
                  >
                    <Button className="btn-book btns">Get the App</Button>
                  </Link>
                </div>
              </>
            </div>
            <div className="right-img">
              <img src={bannerItem?.img} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EasyLifeContactBanner;
