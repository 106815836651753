import React from "react";
import Accordion from "react-bootstrap/Accordion";
import OurPlansTitle from "../CatagoryPlans/OurPlans/OurPlansTitle";

function BMICalculatorContent({ t }) {
  return (
    <div className="body-container secondary-container message-contaner">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h1 className="title" style={{ color: "#f7941d" }}>
              {t("Disclaimer")}
            </h1>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <p className="banner-text mt-5 mb-3">{t("DisclaimerDetails1")}</p>
              <p className="mb-3 banner-text">{t("DisclaimerDetails2")}</p>
              <p className="mb-3 banner-text">{t("DisclaimerDetails3")}</p>
              <p className="mb-3 banner-text">{t("DisclaimerDetails4")}</p>
              <p className=" banner-text">{t("DisclaimerDetails5")}</p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* <div>
        <OurPlansTitle title="Disclaimer" />
        <div>
          <p className="banner-text mt-5 mb-3">
            The following BMI (Body Mass Index) calculation is provided for
            informational purposes only. It is important to note that BMI is a
            general measurement that may not accurately assess an individual's
            overall health or body composition. BMI does not account for factors
            such as muscle mass, bone density, and distribution of fat, which
            can significantly impact an individual's health status.
          </p>
          <p className="mb-3 banner-text">
            The BMI calculation is intended to provide a rough estimation of
            whether an individual's weight falls within a health range based on
            their height. However, it should not be used as the sole determinant
            of an individual's health or as a diagnostic tool.
          </p>
          <p className="mb-3 banner-text">
            It is advisable to consult with a qualified healthcare professional
            or a licensed medical practitioner for a comprehensive evaluation of
            your health, including a thorough examination of your medical
            history, physical condition, and other relevant factors. They will
            be able to provide personalized advice and guidance regarding your
            weight, health, and any necessary interventions.
          </p>
          <p className="mb-3 banner-text">
            By using this BMI calculation, you acknowledge that you understand
            the limitations and potential inaccuracies associated with this
            measurement. The responsibilty for any actions taken based on the
            results of the BMI calculation lies solely with the user, and
            neither the provider of this calculation nor any affiiated
            individuals or organizations can be held liable for any consequences
            that may arise from such actions.
          </p>
          <p className=" banner-text">
            Please proceed with caution and always prioritize your health and
            well-being by seeking professional advice and guidance when making
            decisions regarding your weight and overall health.
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default BMICalculatorContent;
