import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import Loading from "../../../components/Loading/Loading";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { FaStepBackward } from "react-icons/fa";
import DashboardLocateBranchList from "./DashboardLocateBranchList";
import DashboardLoacateBranchEditModal from "./DashboardLoacateBranchEditModal";
import { Button } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import DashboardDistrictName from "./DashboardDistrictName";
import axios from "axios";

const officeCategorys = [
  {
    id: 1,
    name: "Area Office",
  },
  {
    id: 2,
    name: "Branch Office",
  },
  {
    id: 3,
    name: "Regional Office",
  },
];

function DashboardViewAllOfficeBranch() {
  const [isUrl, setIsUrl] = useState(false);

  const [locateBranchLists, setLocateBranchLists] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [searchLocateBranchLists, setSearchLocateBranchLists] = useState([]);
  const [getDivision, setGetDivision] = useState(null);
  const [officeCategory, setOfficeCategory] = useState("");
  const [division, setDivision] = useState("");
  const [district, setDistrict] = useState("");
  const [area, setArea] = useState("");

  const [editModal, setEditModal] = useState(false);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(100);
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [viewShow, setViewShow] = useState(false);
  const [updateBranch, setUpdateBranch] = useState(null);
  const [viewJobPost, setViewJobPost] = useState(null);
  const currentPage = useRef();

  const handleViewClose = () => setViewShow(false);
  const handleViewShow = () => setViewShow(true);
  const handleEditClose = () => setEditModal(false);
  const handleEditShow = () => setEditModal(true);

  useEffect(() => {
    currentPage.current = 1;
    getBranchList();
    // filtersBranch();
  }, []);

  useEffect(() => {
    currentPage.current = 1;
    getNameByDivision();
  }, [division]);

  // get division district
  const url = `${process.env.REACT_APP_host}/api/v1/division-district`;
  const { data: allDivisionDistrict = [] } = useQuery({
    queryKey: [" allDivisionDistrict"],
    queryFn: async () => {
      try {
        const res = await fetch(url);
        const data = await res.json();
        return data?.data;
      } catch (err) {
        console.log(err);
      }
    },
  });

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  function handlePageClick(e) {
    console.log(e);
    currentPage.current = e.selected + 1;
    getBranchList();
  }

  function changeLimit() {
    getBranchList();
  }

  function getBranchList() {
    fetch(
      `${process.env.REACT_APP_host}/api/v1/all-branch`,
      // isUrl === true
      //   ? `${process.env.REACT_APP_host}/api/v1/all-branch?officeCategory=${officeCategory}&division=${division}&district=${district}&area=${area}`
      //   : `${process.env.REACT_APP_host}/api/v1/all-branch`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data?.data?.page);
        setLocateBranchLists(data?.data?.locateBranchList);
        setIsLoading(false);
      });
  }

  // get by name division
  function getNameByDivision() {
    const url = `${process.env.REACT_APP_host}/api/v1/division-district?division=${division}`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          setGetDivision(data?.data);
          // setDivision("");
        }
      });
  }

  function resetBranch() {
    setOfficeCategory("");
    setDivision("");
    setDistrict("");
    setArea("");
    // getBranchList();
    // setIsUrl(true);
  }

  const lists = locateBranchLists?.filter(
    (items) =>
      items.officeCategory === officeCategory && items?.district === district
  );

  const uniqueItems = Array.from(new Set(lists?.map((item) => item?.area))).map(
    (name) => {
      return lists?.find((item) => item?.area === name);
    }
  );

  return (
    <>
      <div className="home-content">
        <div class="d-flex justify-content-between gap-2 align-items-center">
          <h1 className="dashboard-title">All Office's Branch</h1>
          <div>
            <Link
              to="/dashboard/locate-branch"
              className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
            >
              <FaStepBackward />
              <span>Back</span>
            </Link>
          </div>
        </div>

        <div className="list-contaner">
          {locateBranchLists?.length === 0 ? (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
              No Branch Avaliable
            </div>
          ) : (
            <>
              <div className="search-location mb-4">
                <div>
                  <select
                    value={officeCategory}
                    onChange={(e) => setOfficeCategory(e.target.value)}
                    className="form-select location-select"
                  >
                    <option value="" selected>
                      Office Category
                    </option>
                    {officeCategorys?.map((officeCate) => (
                      <option value={officeCate?.name} key={officeCate.id}>
                        {officeCate?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <select
                    name="division"
                    class="form-select location-select"
                    value={division}
                    onChange={(e) => setDivision(e.target.value)}
                  >
                    <option value="" selected>
                      Choose division
                    </option>
                    {allDivisionDistrict?.map((divisions) => (
                      <option key={divisions._id}>{divisions?.division}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <select
                    name="district"
                    class="form-select location-select"
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                  >
                    <option value="" selected>
                      Choses district
                    </option>
                    {getDivision?.map((getUpdateDivision) => (
                      <DashboardDistrictName
                        getUpdateDivision={getUpdateDivision}
                        key={getUpdateDivision?._id}
                      />
                    ))}
                  </select>
                </div>
                <div>
                  <select
                    value={area}
                    onChange={(e) => setArea(e.target.value)}
                    className="form-select location-select"
                  >
                    <option value="" selected>
                      Area
                    </option>
                    {uniqueItems?.map((area) => (
                      <option value={area?.area} key={area._id}>
                        {area?.area}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <Button
                    onClick={resetBranch}
                    className="btn-search btn-danger"
                  >
                    Reset
                  </Button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-light table-bordered">
                  <thead>
                    <tr className="table-tr">
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        id
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        Office Category
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        Office Name
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        Division
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        District
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        Area
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        Contact Person
                      </th>
                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        Map Link
                      </th>

                      <th
                        scope="col"
                        className="text-center align-middle table-th"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {locateBranchLists
                      ?.filter((item) => {
                        return officeCategory === "" &&
                          division === "" &&
                          district === "" &&
                          area === ""
                          ? item
                          : item?.officeCategory.includes(officeCategory) &&
                              item?.division.includes(division) &&
                              item?.district.includes(district) &&
                              item?.area.includes(area);
                      })
                      ?.map((locateBranchList, idx) => (
                        <DashboardLocateBranchList
                          key={locateBranchList._id}
                          idx={idx}
                          locateBranchList={locateBranchList}
                          handleEditShow={handleEditShow}
                          setViewJobPost={setViewJobPost}
                          getBranchList={getBranchList}
                          setUpdateBranch={setUpdateBranch}
                          // filtersBranch={filtersBranch}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {/* <div className="mt-4">
            <ReactPaginate
              breakLabel="......."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            />
          </div> */}
        </div>
      </div>

      <DashboardLoacateBranchEditModal
        show={editModal}
        handleClose={handleEditClose}
        getBranchList={getBranchList}
        updateBranch={updateBranch}
        setUpdateBranch={setUpdateBranch}
      />
    </>
  );
}

export default DashboardViewAllOfficeBranch;
