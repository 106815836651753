import React, { useEffect, useState } from "react";
import img from "../../../assets/homeBanner/Banners for GLIL-02.png";
import img2 from "../../../assets/homeBanner/healthInsuranceEn.png";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function HomeBannerCardOne() {
  const [imageBn, setImageBn] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setImageBn(currentLang);
  }, []);

  return (
    <div className="">
      <div>
        <img
          className="w-100"
          src={imageBn === "bn" ? img2 : img}
          alt="banner-img"
        />
      </div>
    </div>
  );
}

export default HomeBannerCardOne;
