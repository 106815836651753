import Offcanvas from "react-bootstrap/Offcanvas";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useEffect } from "react";

function TaxRebateCalculatorAsideBar({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);
  return (
    <>
      <span onClick={handleShow} className="d-flex align-items-center gap-2">
        <span>{t("Tax Rebate Calculator")}</span>
      </span>

      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className="calculator-offcanvas px-4"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <h5 className="calculator-offcanvas-title ">
              Tax Rebate Calculator
            </h5>
            <div className="vh-100">
              <iframe
                width="100%"
                height="100%"
                src="https://test.guardianlife.com.bd/en/tax-info-and-calculation"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default TaxRebateCalculatorAsideBar;
