import React from "react";
import { toast } from "react-hot-toast";
import { BiEdit } from "react-icons/bi";
import { BsFillEyeFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";

function SEOList({
  idx,
  seo,
  handleViewShow,
  setSEOView,
  getPaginationList,
  setSEOEditList,
  handleEditShow,
  handleEditKeywordsShow,
  setSEOEditKeywordsList,
}) {
  const { _id, pages, title, keywords, description } = seo;

  const handleDeleteAction = (id) => {
    const agree = window.confirm(`Are you sure, you want to delete SEO"`);
    if (agree) {
      fetch(`${process.env.REACT_APP_host}/api/v1/seo/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            getPaginationList();
            toast.success(`SEO is Deleted !`);
          }
        });
    }
  };
  return (
    <tr>
      <td className="text-center align-middle table-text">{idx}</td>
      <td className="text-center align-middle table-text">{pages}</td>
      <td className="text-center align-middle table-text">{title}</td>
      <td className="text-center align-middle table-text">
        {keywords?.map((keyword, i) => (
          <span key={i}>{keyword?.keywordsName},</span>
        ))}
      </td>
      <td className="text-center align-middle table-text">
        {description?.length > 150
          ? description?.slice(0, 150) + "..."
          : description}
      </td>
      <td className="text-center align-middle table-text">
        <div className="d-flex align-items-center justify-content-center gap-2">
          <button
            onClick={() => {
              handleViewShow();
              setSEOView(seo);
            }}
            className="btn btn-sm btn-primary table-btn fw-semibold d-flex align-items-center gap-1"
          >
            <BsFillEyeFill /> <span>View</span>
          </button>
          <button
            onClick={() => {
              handleEditShow();
              setSEOEditList(seo);
            }}
            className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center gap-1"
          >
            <BiEdit /> <span>Edit</span>
          </button>
          <button
            onClick={() => {
              handleEditKeywordsShow();
              setSEOEditKeywordsList(seo);
            }}
            className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center gap-1"
          >
            <BiEdit /> <span>Keywords</span>
          </button>
          <button
            onClick={() => handleDeleteAction(_id)}
            className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center gap-1"
          >
            <MdDelete /> <span>Delete</span>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default SEOList;
