import React, { useEffect, useState } from 'react';
import Loading from '../../../../components/Loading/Loading';
import axios from 'axios';
import toast from 'react-hot-toast';

function DashboardBancassuranceWhyGuardinLife() {
    const [updateWhy, setUpdateWhy] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_host}/api/v1/bancassurance-why`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setUpdateWhy(data?.data?.bancassuranceWhyList[0]);
                setIsLoading(false);
            });
    }, []);

    const handleChange = (e) => {
        setUpdateWhy({
            ...updateWhy,
            [e.target.name]: e.target.value,
        });
    };

    const handleImage = (e) => {
        const selectedImage = e.target.files[0];
        setUpdateWhy({ ...updateWhy, imageURL: selectedImage });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', updateWhy?.title);
        formData.append('titleBn', updateWhy?.titleBn);
        formData.append('description', updateWhy?.description);
        formData.append('descriptionBn', updateWhy?.descriptionBn);

        formData.append('image', updateWhy?.imageURL);

        for (const value of formData.values()) {
            console.log(value);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        try {
            const res = await axios.put(
                `${process.env.REACT_APP_host}/api/v1/bancassurance-why/${updateWhy?._id}`,
                formData,
                config
            );

            if (res.status === 200) {
                console.log(res);
                toast.success(' Updated successfully!');
            }
        } catch (error) {
            console.error(error);
            toast.error('Not updated!');
        }
    };

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    return (
        <div className="home-content">
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center">
                    Edit Why Guardian Life
                </h1>
                <form onSubmit={handleUpdate}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Title</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            defaultValue={updateWhy?.title}
                            name="title"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Title(Bangla)
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            defaultValue={updateWhy?.titleBn}
                            name="titleBn"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Description
                        </label>
                        <textarea
                            type="text"
                            onChange={handleChange}
                            defaultValue={updateWhy?.description}
                            name="description"
                            rows={10}
                            className="form-control"
                            required
                        ></textarea>
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Description(Bangla)
                        </label>
                        <textarea
                            type="text"
                            onChange={handleChange}
                            defaultValue={updateWhy?.descriptionBn}
                            name="descriptionBn"
                            rows={10}
                            className="form-control"
                            required
                        ></textarea>
                    </div>

                    <div className="mb-5">
                        <label className="form-label fw-bold">
                            Upload Image
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            onChange={handleImage}
                            className="form-control"
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default DashboardBancassuranceWhyGuardinLife;
