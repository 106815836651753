import React from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";

function DashboardFormLibraryEdit({ handleEditClose, editModal, updateFormLibrary, getPaginationList, setUpdateFormLibrary }) {
  const handleChange = (e) => {
    setUpdateFormLibrary({
      ...updateFormLibrary,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditFormLibrary = (e) => {
    e.preventDefault();
    handleEditClose();

    const updatedFormLibrary = {
      formName: updateFormLibrary?.formName,
      formNameBn: updateFormLibrary?.formNameBn,
      formLink: updateFormLibrary?.formLink,
    };
    console.log(updatedFormLibrary);

    fetch(`${process.env.REACT_APP_host}/api/v1/form-library/${updateFormLibrary?._id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedFormLibrary),
    })
      .then((res) => {
        if (res.status) {
          console.log(res);
          getPaginationList();
          toast.success("Form Library updated successfully!");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("FormLibrary not updated!");
      });
  };
  return (
    <Modal
      show={editModal}
      onHide={handleEditClose}
      scrollable={true}
      //   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="book-title ps-2" id="contained-modal-title-vcenter">
          Edit Form Library
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <form onSubmit={handleEditFormLibrary}>
          <div className="mb-3">
            <label className="form-label  fw-bold">Form Name</label>
            <input type="text" onChange={handleChange} defaultValue={updateFormLibrary?.formName} name="formName" className="form-control" required />
          </div>
          <div className="mb-3">
            <label className="form-label  fw-bold">Form Name(Bangla)</label>
            <input type="text" name="formNameBn" onChange={handleChange} defaultValue={updateFormLibrary?.formNameBn} className="form-control" />
          </div>
          <div className="mb-3">
            <label className="form-label  fw-bold">Form Link</label>
            <input type="text" name="formLink" onChange={handleChange} defaultValue={updateFormLibrary?.formLink} className="form-control" />
          </div>

          <button type="submit" className="btn btn-primary px-4">
            Save
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default DashboardFormLibraryEdit;
