import React from "react";
import { Modal } from "react-bootstrap";
import DashboardPartnerChannelsEditForm from "./DashboardPartnerChannelsEditForm";

const DashboardPartnerChannelsEditModal = ({ editModal, handleEditClose, partnerChannelEdit, getAllGetAdc }) => {
  return (
    <>
      <Modal show={editModal} onHide={handleEditClose} scrollable={true} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="book-title ps-2" id="contained-modal-title-vcenter">
            Edit Partner Channels
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DashboardPartnerChannelsEditForm handleEditClose={handleEditClose} partnerChannelEdit={partnerChannelEdit} getAllGetAdc={getAllGetAdc}></DashboardPartnerChannelsEditForm>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DashboardPartnerChannelsEditModal;
