import React from "react";
import american from "../../assets/payment/american.png";
import masterCard from "../../assets/payment/mastercard.png";
import visa from "../../assets/payment/visa.png";

function Cards() {
  return (
    <div className="body-container secondary-container my-5">
      <div className="payment-submenu">
        <img src={visa} className=" payment-logo" alt="" />
        <img src={masterCard} className=" payment-logo" alt="" />
        <img src={american} className=" payment-logo" alt="" />
        <p className="fw-bold">Other Cards</p>
      </div>
    </div>
  );
}

export default Cards;
