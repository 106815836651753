import React from "react";
import { IoMdAddCircle } from "react-icons/io";
import SEOList from "./SEOList";
import { useState } from "react";
import AddSEO from "./AddSEO";
import { useEffect } from "react";
import Loading from "../../../components/Loading/Loading";
import { useRef } from "react";
import ReactPaginate from "react-paginate";
import SEOView from "./SEOView";
import SEOEdit from "./SEOEdit";
import SEOKeywordsEdit from "./SEOKeywordsEdit";

const seoList = [
  {
    id: 1,
    page: "Home",
    title: "sfdsf",
    description: "frefsaf",
    keywords: [{ keywordsName: "sfsfs" }, { keywordsName: "ssfsfs" }],
  },
];

function SEO() {
  const [allSEOList, setAllSEOList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [limit, setLimit] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const currentPage = useRef();
  const [addSEOShow, setAddSEOShow] = useState(false);
  const [viewSEOShow, setViewSEOShow] = useState(false);
  const [editSEOShow, setEditSEOShow] = useState(false);
  const [editSEOKeywordsShow, setEditSEOKeywordsShow] = useState(false);
  const [SEOViewList, setSEOViewList] = useState(null);
  const [SEOEditList, setSEOEditList] = useState(null);
  const [SEOEditKeywordsList, setSEOEditKeywordsList] = useState(null);

  useEffect(() => {
    currentPage.current = 1;
    getPaginationList();
  }, []);

  const handleAddClose = () => setAddSEOShow(false);
  const handleAddShow = () => setAddSEOShow(true);

  const handleViewClose = () => setViewSEOShow(false);
  const handleViewShow = () => setViewSEOShow(true);

  const handleEditClose = () => setEditSEOShow(false);
  const handleEditShow = () => setEditSEOShow(true);

  const handleEditKeywordsClose = () => setEditSEOKeywordsShow(false);
  const handleEditKeywordsShow = () => setEditSEOKeywordsShow(true);

  // pagination
  function handlePageClick(e) {
    currentPage.current = e.selected + 1;
    getPaginationList();
  }

  function getPaginationList() {
    const url = `${process.env.REACT_APP_host}/api/v1/seo?page=${currentPage.current}&limit=${limit}`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data?.data?.page);
        setAllSEOList(data?.data?.seo);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="home-content">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="dashboard-title">SEO List</h1>
        <button
          onClick={handleAddShow}
          className="btn btn-sm btn-primary d-flex align-items-center fw-semibold"
        >
          <IoMdAddCircle className="add-icon" /> <span>Add SEO</span>
        </button>
      </div>

      <div className="table-responsive mt-5">
        <table className="table table-light table-bordered">
          <thead>
            <tr className="table-tr">
              <th scope="col" className="text-center align-middle table-th">
                Serial No.
              </th>
              <th scope="col" className="text-center align-middle table-th">
                Page
              </th>
              <th scope="col" className="text-center align-middle table-th">
                Title
              </th>
              <th scope="col" className="text-center align-middle table-th">
                Keywords
              </th>
              <th scope="col" className="text-center align-middle table-th">
                Description
              </th>

              <th scope="col" className="text-center align-middle table-th">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {allSEOList?.map((seo, i) => (
              <SEOList
                key={seo.id}
                seo={seo}
                idx={i + 1}
                handleViewShow={handleViewShow}
                setSEOView={setSEOViewList}
                setSEOEditList={setSEOEditList}
                handleEditShow={handleEditShow}
                getPaginationList={getPaginationList}
                handleEditKeywordsShow={handleEditKeywordsShow}
                setSEOEditKeywordsList={setSEOEditKeywordsList}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4">
        <ReactPaginate
          breakLabel="......."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          marginPagesDisplayed={2}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
        />
      </div>

      <AddSEO
        show={addSEOShow}
        handleClose={handleAddClose}
        getPaginationList={getPaginationList}
      />
      <SEOView
        show={viewSEOShow}
        handleClose={handleViewClose}
        SEOViewList={SEOViewList}
      />
      <SEOEdit
        show={editSEOShow}
        handleClose={handleEditClose}
        SEOEditList={SEOEditList}
        setSEOEditList={setSEOEditList}
        getPaginationList={getPaginationList}
      />
      <SEOKeywordsEdit
        show={editSEOKeywordsShow}
        handleClose={handleEditKeywordsClose}
        SEOEditKeywordsList={SEOEditKeywordsList}
        setSEOEditKeywordsList={setSEOEditKeywordsList}
        getPaginationList={getPaginationList}
      />
    </div>
  );
}

export default SEO;
