import React from "react";
import CoreValueBanner from "../CoreValueBanner";
import CoreValueCard from "../CoreValueCard";
import useTitle from "../../../hooks/useTitle";

const CoreValue = () => {
  useTitle("Core Value");
  return (
    <>
      <CoreValueBanner />
      <CoreValueCard />
    </>
  );
};

export default CoreValue;
