import React from 'react';
import Icon from "../../assets/image/service.png";
import { BsGraphDownArrow } from "react-icons/bs";
import { MdWorkspacePremium, MdSecurity, MdOutlinePolicy } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import { GiHealthIncrease } from "react-icons/gi";
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";




const WhyMicroinsurance = () => {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
    }, []);

    const ourServiceItems = [
        {
            id: 1,
            title: `${t("microinsuranceWhyMicroCardTitle1")}`,
            icon: <BsGraphDownArrow />,
        },
        {
            id: 2,
            title: `${t("microinsuranceWhyMicroCardTitle2")}`,
            icon: <MdWorkspacePremium />,
        },
        {
            id: 3,
            title: `${t("microinsuranceWhyMicroCardTitle3")}`,
            icon: <MdOutlinePolicy />,
        },
        {
            id: 4,
            title: `${t("microinsuranceWhyMicroCardTitle4")}`,
            icon: <IoIosPeople />,
        },
        {
            id: 5,
            title: `${t("microinsuranceWhyMicroCardTitle5")}`,
            icon: <MdSecurity />,
        },
        {
            id: 6,
            title: `${t("microinsuranceWhyMicroCardTitle6")}`,
            icon: <GiHealthIncrease />,
        },
    ];
    return (
        <div className="service-container">
            <div className="body-container secondary-container">
                <h1 className="title title-service">{t("microinsuranceWhyMicroTitle")}</h1>
                <div className="service-grid">
                    {ourServiceItems.map((ourServiceItem) => (
                        <div key={ourServiceItem.id}>
                            <div className="service-card h-100">
                                <div className="d-flex justify-content-center">
                                    <div className="corporate-icon">
                                        <span>
                                            {ourServiceItem?.icon}
                                        </span>
                                    </div>
                                </div>
                                <div className="card-info text-center text-capitalize mt-3">
                                    <h4 className="corporate-service-title fw-semibold">{ourServiceItem.title}</h4>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WhyMicroinsurance;