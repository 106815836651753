import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import cancerImg from '../../../../assets/easylife/banner/cancer_care.jpg';
import accidentImg from '../../../../assets/easylife/banner/accident.jpg';
import travelImg from '../../../../assets/easylife/Travel/GUARDIAN-TRAVEL-CARE.jpg';
import SpecialInsurancePlanCard from './SpecialInsurancePlanCard';

function SpecialInsurancePlan() {
    const [banglatranslate, setBanglaTraslate] = useState();

    const { t } = useTranslation();
    useEffect(() => {
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
        setBanglaTraslate(currentLang);
    }, []);

    const specialProduct = [
        {
            id: 1,
            imageURL: cancerImg,
            productTitle: 'Guardian Cancer Care',
            productTitleBn: 'ক্যান্সার কেয়ার',
            description:
                'There is no low-cost solution for cancer care in Bangladesh. As per the National Cancer Research Institute, the treatment cost of an average cancer patient is BDT 639,000 per year.',
            descriptionBn:
                'বাংলাদেশে ক্যান্সার চিকিৎসার জন্য কোনো সাশ্রয়ী সমাধান নেই। ন্যাশনাল ক্যানসার রিসার্চ ইনস্টিটিউটের তথ্য অনুযায়ী, একজন ক্যান্সার রোগীর চিকিৎসার পিছনে বছরে প্রায় ৬৩৯,০০০ টাকা খরচ হয়ে থাকে।',
            link: '/easylife/easy-life-cancer-care-plan',
        },
        {
            id: 2,
            imageURL: accidentImg,
            productTitle: 'Guardian Accident Care',
            productTitleBn: 'গার্ডিয়ান আকসিডেন্টাল কেয়ার',
            description:
                'Unpredicted accidents can strike without warning, ranging from minor mishaps to serious injuries. Such unpredictability often brings financial challenges. To alleviate this risk, investing in an accident insurance policy is highly advisable.',
            descriptionBn:
                'অপ্রত্যাশিত দুর্ঘটনা সতর্কতা ছাড়াই আঘাত হানতে পারে, ছোটখাটো দুর্ঘটনা থেকে গুরুতর আঘাত পর্যন্ত।  এই ঝুঁকি কমানোর জন্য, একটি দুর্ঘটনা বীমা পলিসিতে বিনিয়োগ করা অত্যন্ত যুক্তিযুক্ত।',

            link: '/easylife/guardian-accident-care',
        },
        // {
        //     id: 3,
        //     imageURL: travelImg,
        //     productTitle: 'Guardian Travel Care',
        //     productTitleBn: 'গার্ডিয়ান ট্রাভেল কেয়ার',
        //     description:
        //         "Guardian Travel Care (Overseas Mediclaim Policy), your trusted Overseas Mediclaim Policy, offers Bangladeshi travelers comprehensive protection for unexpected events and medical emergencies during their journeys abroad. Whether you're on a business tour, a family vacation, pursuing studies, or embarking on a new employment opportunity, our flexible plans ensure you're covered.",
        //     descriptionBn:
        //         "Guardian Travel Care (Overseas Mediclaim Policy), your trusted Overseas Mediclaim Policy, offers Bangladeshi travelers comprehensive protection for unexpected events and medical emergencies during their journeys abroad. Whether you're on a business tour, a family vacation, pursuing studies, or embarking on a new employment opportunity, our flexible plans ensure you're covered.",
        //     link: '/easylife/guardian-travel-care',
        // },
    ];

    return (
        <div className="service-container">
            <div className="body-container secondary-container">
                <h1 class="title banner-title text-center">
                    {t('easylifeAboutUsServiceOffermenu5')}
                </h1>

                <div className="serviceProduct-grid">
                    {specialProduct?.map((allProduct) => (
                        <SpecialInsurancePlanCard
                            allProduct={allProduct}
                            key={allProduct?.id}
                            banglatranslate={banglatranslate}
                            t={t}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SpecialInsurancePlan;
