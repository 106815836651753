import React from 'react';
import { Accordion } from 'react-bootstrap';



const OffersFAQ = ({ t }) => {
    const faqContents = [
        {
            id: 1,
            title: `${t("easylifeFAQOffersT1")}`,
            des: `${t("easylifeFAQOffersD1")}`,
        },
        {
            id: 2,
            title: `${t("easylifeFAQOffersT2")}`,
            des: `${t("easylifeFAQOffersD2")}`,
        },
        {
            id: 3,
            title: `${t("easylifeFAQOffersT3")}`,
            des: `${t("easylifeFAQOffersD3")}`,
        }
    ];
    return (
        <div>
            <Accordion defaultActiveKey="0">
                {faqContents.map((faq, i) => (
                    <Accordion.Item eventKey={i} key={faq.id}>
                        <Accordion.Header>
                            {faq.id}. {faq.title}
                        </Accordion.Header>
                        <Accordion.Body>{faq.des}</Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
};

export default OffersFAQ;