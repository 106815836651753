import React, { useEffect, useRef, useState } from 'react';
import './MediaPhotoGallery.css';
import MediaContentGallery from './MediaContentGallery';
import Loading from '../../../components/Loading/Loading';
import ReactPaginate from 'react-paginate';

const MediaPhotoGallery = ({ t }) => {
    const [limit, setLimit] = useState(3);
    const [pageCount, setPageCount] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [allGalleryPhoto, setAllGalleryPhoto] = useState([]);

    const currentPage = useRef();

    useEffect(() => {
        currentPage.current = 1;
        getPaginationList();
    }, []);

    if (isLoading) {
        return (
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Loading />
            </div>
        );
    }

    function handlePageClick(e) {
        console.log(e);
        currentPage.current = e.selected + 1;
        getPaginationList();
    }

    function getPaginationList() {
        const url = `${process.env.REACT_APP_host}/api/v1/media-photo?page=${currentPage.current}&limit=${limit}`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setPageCount(data?.data?.page);
                setAllGalleryPhoto(data?.data?.photoList);
                setIsLoading(false);
            });
    }
    return (
        <div className="mt-5">
            <div>
                <h1 className="title title-service">{t('photoGallery')}</h1>
                <div className="blog-content mt-4">
                    {allGalleryPhoto?.map((data) => (
                        <MediaContentGallery data={data} key={data.id} />
                    ))}
                </div>

                <div className="mt-4 d-flex justify-content-end">
                    <ReactPaginate
                        breakLabel="......."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>
            </div>
        </div>
    );
};

export default MediaPhotoGallery;
