import React from "react";
import { toast } from "react-hot-toast";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

const BoardOfDirectorTable = ({
  newDirector,
  handleEditShow,
  setUpdateDirector,
  getPaginationList,
}) => {
  const { _id, name, designation, imageURL } = newDirector;
  const handleDeleteAction = (id) => {
    const agree = window.confirm(
      `Are you sure, you want to delete: "${name}?"`
    );
    if (agree) {
      fetch(`${process.env.REACT_APP_host}/api/v1/board-of-director/${id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            getPaginationList();
            toast.success(`"${name}" is Deleted !`);
          }
        });
    }
  };
  return (
    <>
      <tr>
        <td className="text-center align-middle table-text">
          <div className="d-flex  align-items-center gap-2">
            <div className="list-img">
              <img src={imageURL} alt="blog" />
            </div>
          </div>
        </td>
        <td className="text-center align-middle table-text">{name}</td>
        <td className="text-center align-middle table-text">{designation}</td>
        <td className="text-center align-middle table-text">
          <div className="d-flex align-items-center justify-content-center gap-2">
            <button
              onClick={() => {
                handleEditShow();
                setUpdateDirector(newDirector);
              }}
              className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center gap-1"
            >
              <BiEdit /> <span>Edit</span>
            </button>
            <button
              onClick={() => handleDeleteAction(_id)}
              className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center gap-1"
            >
              <MdDelete /> <span>Delete</span>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default BoardOfDirectorTable;
