import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { AiOutlineEye } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';

function DashBoardPostList({
    jobPostList,
    getJobPostList,
    handleViewShow,
    handleEditShow,
    setViewJobPost,
    setUpdateJobPost,
}) {
    const {
        _id,
        positionName,
        description,
        address,
        jobType,
        jobStatus: status,
    } = jobPostList;

    const [isSwitchOn, setIsSwitchOn] = useState(status);

    const handleChange = (e) => {
        if (e.target.checked === true) {
            setIsSwitchOn(true);
        } else {
            setIsSwitchOn(false);
        }
    };

    // update status
    const handleUpdateActive = (id) => {
        const agree = window.confirm(
            `Are you sure, you want to status${
                status ? ' inactive' : ' active'
            }`
        );

        if (agree) {
            const updateStatus = {
                jobStatus: !isSwitchOn,
            };

            fetch(`${process.env.REACT_APP_host}/api/v1/job/${id}`, {
                method: 'PATCH',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(updateStatus),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.status) {
                        getJobPostList();
                        console.log(data);
                        toast.success(`Status update success`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(`Not update status`);
                });
        } else {
            setIsSwitchOn(status);
        }
    };

    const handleDeleteAction = (id) => {
        const agree = window.confirm(
            `Are you sure, you want to delete: "${positionName}?"`
        );
        if (agree) {
            fetch(`${process.env.REACT_APP_host}/api/v1/job/${id}`, {
                method: 'DELETE',
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.status) {
                        getJobPostList();
                        toast.success(`"${positionName}" is Deleted !`);
                    }
                });
        }
    };

    return (
        <tr>
            <td className="text-center align-middle table-text">
                {positionName}
            </td>
            <td className="text-center align-middle table-text">{address}</td>
            <td className="text-center align-middle table-text">{jobType}</td>
            <td className="text-center align-middle table-text">
                <div className="form-check form-switch d-flex justify-content-center align-items-center gap-2 ">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        onChange={(e) => {
                            handleChange(e);
                            handleUpdateActive(_id);
                        }}
                        checked={isSwitchOn}
                    />
                    <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                    >
                        {isSwitchOn === true ? (
                            <span className="text-success fw-bold">
                                {' '}
                                Active
                            </span>
                        ) : (
                            <span className="text-danger fw-bold">
                                Inactive
                            </span>
                        )}
                    </label>
                </div>
            </td>
            <td className="text-center align-middle table-text">
                {/* <span dangerouslySetInnerHTML={{ __html: description }}></span> */}
                <span
                    dangerouslySetInnerHTML={{
                        __html:
                            description?.length > 100
                                ? description.slice(0, 100)
                                : description,
                    }}
                ></span>
            </td>

            <td className="text-center align-middle table-text">
                <div className="d-flex align-items-center justify-content-center gap-2">
                    <button
                        onClick={() => {
                            handleViewShow();
                            setViewJobPost(jobPostList);
                        }}
                        className="btn btn-sm btn-info table-btn fw-semibold d-flex align-items-center text-white "
                    >
                        <AiOutlineEye /> <span>Show</span>
                    </button>
                    <button
                        onClick={() => {
                            handleEditShow();
                            setUpdateJobPost(jobPostList);
                        }}
                        className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center "
                    >
                        <BiEdit /> <span>Edit</span>
                    </button>
                    <button
                        onClick={() => handleDeleteAction(_id)}
                        className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center"
                    >
                        <MdDelete /> <span>Delete</span>
                    </button>
                </div>
            </td>
        </tr>
    );
}

export default DashBoardPostList;
