import React, { useEffect } from "react";
import "./ClaimFAQ.css";
import Accordion from "react-bootstrap/Accordion";
import { BsSearch } from "react-icons/bs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Loading from "../../../components/Loading/Loading";

const ClaimFAQ = () => {
  const [search, setSearch] = useState("");
  const [claimPage, setClaimPage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [banglatranslate, setBanglaTraslate] = useState();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
    setBanglaTraslate(currentLang);
  }, []);

  const faqContents = [
    {
      id: 1,
      title: `${t("claimFaqQuestion1")}`,
      des: `${t("claimFaqAnswer1")}`,
    },
    {
      id: 2,
      title: `${t("claimFaqQuestion2")}`,
      des: `${t("claimFaqAnswer2")}`,
    },
    {
      id: 3,
      title: `${t("claimFaqQuestion3")}`,
      des: `${t("claimFaqAnswer3")}`,
    },
    {
      id: 4,
      title: `${t("claimFaqQuestion4")}`,
      des: `${t("claimFaqAnswer4")}`,
    },
    {
      id: 5,
      title: `${t("claimFaqQuestion5")}`,
      des: `${t("claimFaqAnswer5")}`,
    },
    {
      id: 6,
      title: `${t("claimFaqQuestion6")}`,
      des: `${t("claimFaqAnswer6")}`,
    },
    {
      id: 7,
      title: `${t("claimFaqQuestion7")}`,
      des: `${t("claimFaqAnswer7")}`,
    },
    {
      id: 8,
      title: `${t("claimFaqQuestion8")}`,
      des: `${t("claimFaqAnswer8")}`,
    },
    {
      id: 9,
      title: `${t("claimFaqQuestion9")}`,
      des: `${t("claimFaqAnswer9")}`,
    },
    {
      id: 10,
      title: `${t("claimFaqQuestion10")}`,
      des: `${t("claimFaqAnswer10")}`,
    },
    {
      id: 11,
      title: `${t("claimFaqQuestion11")}`,
      des: `${t("claimFaqAnswer11")}`,
    },
    {
      id: 12,
      title: `${t("claimFaqQuestion12")}`,
      des: `${t("claimFaqAnswer12")}`,
    },
    {
      id: 13,
      title: `${t("claimFaqQuestion13")}`,
      des: `${t("claimFaqAnswer13")}`,
    },
    {
      id: 14,
      title: `${t("claimFaqQuestion14")}`,
      des: `${t("claimFaqAnswer14")}`,
    },
    {
      id: 15,
      title: `${t("claimFaqQuestion15")}`,
      des: `${t("claimFaqAnswer15")}`,
    },
    {
      id: 16,
      title: `${t("claimFaqQuestion16")}`,
      des: `${t("claimFaqAnswer16")}`,
    },
    {
      id: 17,
      title: `${t("claimFaqQuestion17")}`,
      des: `${t("claimFaqAnswer17")}`,
    },
    {
      id: 18,
      title: `${t("claimFaqQuestion18")}`,
      des: `${t("claimFaqAnswer18")}`,
    },
    {
      id: 19,
      title: `${t("claimFaqQuestion19")}`,
      des: `${t("claimFaqAnswer19")}`,
    },
    {
      id: 20,
      title: `${t("claimFaqQuestion20")}`,
      des: `${t("claimFaqAnswer20")}`,
    },
    {
      id: 21,
      title: `${t("claimFaqQuestion21")}`,
      des: `${t("claimFaqAnswer21")}`,
    },
    {
      id: 22,
      title: `${t("claimFaqQuestion22")}`,
      des: `${t("claimFaqAnswer22")}`,
    },
  ];

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_host}/api/v1/faq?selectPage=Claims FAQ Page`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setClaimPage(data?.data?.faqList);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  // {banglatranslate === "bn" ? plansData?.titleBn : plansData?.title}
  // <span
  //   dangerouslySetInnerHTML={{
  //     __html:
  //       banglatranslate === "bn"
  //         ? catagoryPlansData?.categoryDescriptionBn
  //         : catagoryPlansData?.categoryDescription,
  //   }}
  // ></span>;
  return (
    <div className="service-container bg-white">
      <div className="body-container secondary-container">
        <h1 class="title title-service text-start">
          {t("easylifeFAQBannerTitle")}
        </h1>
        <div className="input-group my-5">
          <span className="input-group-text search-icon" id="search">
            <BsSearch />
          </span>
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            className="form-control search-field"
            placeholder={t("claimFAQSearch")}
            aria-label="sarech"
            aria-describedby="search"
          />
        </div>
        <div className="mt-3">
          <Accordion defaultActiveKey="0">
            {claimPage
              ?.filter((item) => {
                return search.toLowerCase() === ""
                  ? item
                  : item?.title.toLowerCase().includes(search);
              })

              ?.map((faq, i) => (
                <Accordion.Item eventKey={i} key={faq.id}>
                  <Accordion.Header>
                    {i + 1}.{" "}
                    {banglatranslate === "bn" ? faq?.titleBn : faq?.title}
                  </Accordion.Header>
                  <Accordion.Body>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          banglatranslate === "bn"
                            ? faq?.descriptionBn
                            : faq?.description,
                      }}
                    ></span>
                    {/* <span dangerouslySetInnerHTML={{ __html: faq.des }}></span> */}
                    {/* {faq.des} */}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default ClaimFAQ;
