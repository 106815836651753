import React from "react";
import MobileImage from "../../../assets/image/mobileApp.png";
import EasyLife from "../../../assets/image/easyLife.png";
import Guardian from "../../../assets/image/btn-myguirdian.png";
import { Link } from "react-router-dom";

function AboutEasyLifeDownloadApp() {
  return (
    <div className="mobileApp-container">
      <div className="body-container secondary-container mobile-app-content">
        <div className="mobile-app-left">
          <img src={MobileImage} alt="" />
        </div>
        <div className="mobile-app-right">
          <h1 className="title mobile-app-title">
            Download Our Mobile App & Access it from anywhere
          </h1>
          <p className="text banner-text">
            Downloading our app offers a multitude of benefits and enriches your
            overall experience. With our app, you gain convenient access to a
            range of features and functionalities that enhance productivity as
            well.
          </p>
          <div className="d-flex aling-items-center gap-4 mt-3">
            <div className="app-img">
              <Link
                to="https://play.google.com/store/apps/details?id=com.sslwireless.easylife"
                target="_blank"
              >
                <img className="sponsor-logo" src={EasyLife} alt="" />
              </Link>
            </div>
            <div className="app-img">
              <Link
                to="https://play.google.com/store/apps/details?id=com.guardianlifeinsurance.myguardian"
                target="_blank"
              >
                <img className="sponsor-logo" src={Guardian} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutEasyLifeDownloadApp;
