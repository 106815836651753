import React, { useState } from "react";
import BgBannerImg from "../../../assets/BG/bgLeft.png";
import BannerImg from "../../../assets/aboutGlil/WHO-WE-ARE.jpg";
import "./aboutGLIL.css";
import { Button } from "react-bootstrap";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const BgStyle = {
  backgroundImage: `url(${BgBannerImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};

function AboutGLILBanner() {
  const { t } = useTranslation();

  const [isShowMore, setIsShowMore] = useState(false);
  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  const bannerItems = [
    {
      id: 1,
      title: `${t("whoWeAre")}`,
      desc: `${t("whoDesc")}`,
      img: BannerImg,
    },
  ];

  return (
    <div style={BgStyle}>
      <div className="body-container secondary-container banner-container">
        {bannerItems.map((bannerItem) => (
          <div key={bannerItem.id} className="banner-content">
            <div className="left-content">
              <>
                <h1 className="title banner-title">{bannerItem?.title}</h1>

                <p className="text banner-text">
                  {!isShowMore
                    ? `${bannerItem?.desc.slice(0, 453)}...`
                    : bannerItem?.desc}
                </p>
                <div className="mt-4">
                  <Button
                    onClick={toggleReadMoreLess}
                    className="btn-book btns"
                  >
                    {isShowMore ? `${t("readless")}` : `${t("readmore")}`}
                  </Button>
                </div>
              </>
            </div>
            <div className="right-img">
              <img src={bannerItem?.img} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutGLILBanner;
