import React from "react";
import ChairmanMeassageBanner from "./ChairmanMeassageBanner";
import MessageText from "./MessageText";
import useTitle from "../../../hooks/useTitle";

function ChairmanMeassage() {
  useTitle("Chairman Message");
  return (
    <>
      {/* <ChairmanMeassageBanner /> */}
      <MessageText />
    </>
  );
}

export default ChairmanMeassage;
