import React from 'react';
import BgBannerImg from '../../../assets/BG/bgLeft.png';
import BannerImg from '../../../assets/easylife/adc-purchase-policy.png';
import { FaCheckCircle } from 'react-icons/fa';

// const BgStyle = {
//     backgroundImage: `url(${BgBannerImg})`,
//     backgroundRepeat: "no-repeat",
//     backgroundPosition: "left",
// };

const ADCPurchasePolicy = ({ data, banglatranslate, t }) => {
    return (
        <div>
            <div>
                <div className="body-container secondary-container banner-container pb-0">
                    <h1 class="title title-service mb-5">
                        {banglatranslate === 'bn'
                            ? data?.ourPolicyTitleBn
                            : data?.ourPolicyTitle}
                    </h1>
                    <div className="banner-content">
                        <div className="left-content">
                            <div className="text banner-text">
                                {banglatranslate === 'bn' ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: data?.ourPolicyDescBn,
                                        }}
                                    ></span>
                                ) : (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: data?.ourPolicyDesc,
                                        }}
                                    ></span>
                                )}
                            </div>
                        </div>
                        <div className="right-img">
                            <img src={data?.ourPolicyImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ADCPurchasePolicy;
