import React from 'react';
import { Modal } from 'react-bootstrap';

const BoardOfDirectorAddModal = ({
    show,
    handleClose,
    handleChange,
    handleImage,
    handleAddAgentList,
    newDirector,
}) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            scrollable={true}
            //   size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Add Director
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleAddAgentList} className="row">
                    <div className="mb-3 col-12">
                        <label className="form-label">Name</label>
                        <input
                            type="text"
                            name="name"
                            onChange={handleChange}
                            value={newDirector?.name}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Name(Bangla)</label>
                        <input
                            type="text"
                            name="nameBn"
                            onChange={handleChange}
                            value={newDirector?.nameBn}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-3 col-12">
                        <label className="form-label">Designation</label>
                        <input
                            type="text"
                            name="designation"
                            className="form-control"
                            value={newDirector?.designation}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">
                            Designation(Bangla)
                        </label>
                        <input
                            type="text"
                            name="designationBn"
                            className="form-control"
                            value={newDirector?.designationBn}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Image</label>
                        <input
                            type="file"
                            onChange={handleImage}
                            name="image"
                            className="form-control"
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default BoardOfDirectorAddModal;
