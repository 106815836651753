import React from "react";
import { useRef } from "react";
import { Modal } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useState } from "react";
import { toast } from "react-hot-toast";

function DashboardFAQModalEdit({
  editModal,
  handleEditClose,
  updateFaq,
  setUpdateFaq,
  getPaginationList,
}) {
  const editor = useRef(null);
  const [content, setContent] = useState(editModal?.description);
  const [contentBn, setContentBn] = useState(editModal?.descriptionBn);
  const handleEditChange = (e) => {
    setUpdateFaq({ ...updateFaq, [e.target.name]: e.target.value });
  };

  const handleUpdateAction = async (e) => {
    e.preventDefault();
    handleEditClose();

    const updatedFaq = {
      selectPage: updateFaq?.selectPage,
      title: updateFaq?.title,
      description: content,
      titleBn: updateFaq?.titleBn,
      descriptionBn: contentBn,
    };
    console.log(updatedFaq);

    fetch(`${process.env.REACT_APP_host}/api/v1/faq/${updateFaq?._id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedFaq),
    })
      .then((res) => {
        if (res.status) {
          console.log(res);
          getPaginationList();
          toast.success("Faq updated successfully!");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Not updated!");
      });
  };
  return (
    <Modal
      show={editModal}
      onHide={handleEditClose}
      scrollable={true}
      //   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          Edit Faq
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <form onSubmit={handleUpdateAction} className="row">
          <div className="mb-3">
            <label className="form-label fw-bold">Select Page</label>
            <select
              onChange={handleEditChange}
              defaultValue={updateFaq?.selectPage}
              name="selectPage"
              class="form-select"
            >
              <option value="" selected>
                Choses a Page
              </option>
              <option value="FAQ Page">FAQ Page</option>
              <option value="Claims FAQ Page">Claims FAQ Page</option>
            </select>
          </div>
          <div className="mb-3 col-12">
            <label className="form-label">Title</label>
            <input
              type="text"
              name="title"
              onChange={handleEditChange}
              defaultValue={updateFaq?.title}
              className="form-control"
              required
            />
          </div>
          <div className="mb-3 col-12">
            <label className="form-label">Title(Bangla)</label>
            <input
              type="text"
              name="titleBn"
              onChange={handleEditChange}
              defaultValue={updateFaq?.titleBn}
              className="form-control"
            />
          </div>

          <div className="mb-3">
            <label className="form-label fw-bold">Description</label>

            <JoditEditor
              ref={editor}
              value={updateFaq?.description}
              onChange={(newContent) => {
                setContent(newContent);
              }}
            />
          </div>
          <div className="mb-3">
            <label className="form-label fw-bold">Description(Bangla)</label>

            <JoditEditor
              ref={editor}
              value={updateFaq?.descriptionBn}
              onChange={(newContent) => {
                setContentBn(newContent);
              }}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default DashboardFAQModalEdit;
