import React from 'react';
import EasyLifeContactBanner from './EasyLifeContactBanner';
import EasylifeContactForm from './EasylifeContactForm';
import EasyLifeMobileApp from './EasyLifeMobileApp';
import useEasylifeTitle from '../../../hooks/useEasylifeTitle';

const EasyLifeContact = () => {
    useEasylifeTitle("Contact");
    return (
        <>
            {/* <EasyLifeContactBanner /> */}
            <EasylifeContactForm />
            <EasyLifeMobileApp background={"bg-white"} />
        </>
    );
};

export default EasyLifeContact;