import JoditEditor from "jodit-react";
import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import "./dashboardCategoryPlans.css";

function DashboardCategoryBenefitsEditModal({
  editPlansBenefit,
  editPlansBenefitModal,
  setEditPlansBenefit,
  handleEditClose,
  getAllDataFetch,
}) {
  const editor = useRef(null);
  const [benefitData, setBenefitData] = useState(null);
  const [benefitDataBn, setBenefitDataBn] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  // const [isEditorDisabled, setIsEditorDisabled] = useState(true);
  const [content, setContent] = useState("");
  const [contentBn, setContentBn] = useState("");

  const [textAreaShow, setTextAreaShow] = useState(true);
  let regex = /(<([^>]+)>)/gi;

  // const config = {
  //   // readonly: false,
  //   // disabled: true,
  //   disabled: true,
  // };

  const handleItemClick = (item) => {
    setBenefitData(item);
    setContent(item?.benefitDescription);

    if (item?._id) {
      setTextAreaShow(false);
      setActiveButton(item?._id);
    }
  };
  const handleItemBnClick = (item) => {
    setBenefitDataBn(item);
    setContentBn(item?.benefitDescription);

    if (item?._id) {
      setTextAreaShow(false);
      setActiveButton(item?._id);
    }
  };
  const handleBenefitText = (e) => {
    setContent(e);
  };

  const handleChange = (e) => {
    setBenefitData({
      ...benefitData,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeBn = (e) => {
    setBenefitDataBn({
      ...benefitDataBn,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateAction = (e) => {
    e.preventDefault();

    // handleEditClose();

    const updateBenefits = {
      benefitTitle: benefitData?.benefitTitle,
      benefitDescription: content,
    };

    console.log(updateBenefits);

    fetch(
      `${process.env.REACT_APP_host}/api/v1/categoryPlans/${editPlansBenefit?.slug}/categoryBenefit/${benefitData?._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateBenefits),
      }
    )
      .then((res) => {
        if (res.status) {
          getAllDataFetch();
          console.log(res);
          toast.success("Benefit updated successfully");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Not a update benefit!!!!");
      });
  };

  //   bangla contnet function
  const handleUpdateBnAction = (e) => {
    e.preventDefault();
    // handleEditClose();

    const updateBenefitsBn = {
      benefitTitle: benefitDataBn?.benefitTitle,
      benefitDescription: contentBn,
    };

    console.log(updateBenefitsBn);

    fetch(
      `${process.env.REACT_APP_host}/api/v1/categoryPlans/${editPlansBenefit?.slug}/categoryBenefitBn/${benefitDataBn?._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateBenefitsBn),
      }
    )
      .then((res) => {
        if (res.status) {
          getAllDataFetch();
          console.log(res);
          toast.success("Benefit updated successfully");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Not a update benefit!!!!");
      });
  };

  return (
    <Modal
      show={editPlansBenefitModal}
      onHide={handleEditClose}
      scrollable={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="book-title ps-2"
          id="contained-modal-title-vcenter"
        >
          Update Feature & Benefits
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-4">
          {editPlansBenefit?.categoryBenefit?.map((benefit) => (
            <form
              onSubmit={handleUpdateAction}
              key={benefit._id}
              className="d-flex align-items-center justify-content-between gap-4"
            >
              <div className="w-100">
                <div className="mb-3">
                  <input
                    type="text"
                    onChange={handleChange}
                    defaultValue={benefit?.benefitTitle}
                    name="benefitTitle"
                    className="form-control"
                    required
                    disabled={benefit?._id === activeButton ? false : true}
                  />
                </div>

                <div
                  className={`mb-3 ${
                    benefit?._id === activeButton ? "d-none" : "d-block"
                  }`}
                >
                  <textarea
                    type="text"
                    defaultValue={benefit?.benefitDescription?.replace(
                      regex,
                      ""
                    )}
                    name="benefitTitle"
                    className="form-control"
                    required
                    disabled={benefit?._id === activeButton ? false : true}
                  ></textarea>
                </div>

                <div
                  className={`mb-3 ${
                    benefit?._id === activeButton ? "d-block" : "d-none"
                  }`}
                >
                  <JoditEditor
                    ref={editor}
                    value={benefit?.benefitDescription}
                    // config={
                    //   benefit?._id === activeButton
                    //     ? { disabled: false }
                    //     : { disabled: true }
                    // }
                    onChange={handleBenefitText}
                  />
                </div>
              </div>
              <div className="">
                <div className="d-flex aling-align-items-center justify-content-center">
                  <span
                    onClick={() => handleItemClick(benefit)}
                    className={`btn btn-secondary px-4 ${
                      benefit?._id === activeButton ? "d-none" : "d-block"
                    }`}
                  >
                    Edit
                  </span>

                  <button
                    type="submit"
                    className={`btn btn-primary px-4 ${
                      benefit?._id === activeButton ? "d-block" : "d-none"
                    }`}
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          ))}

          {/* bangla content benefit */}
          {editPlansBenefit?.categoryBenefitBn?.map((benefit) => (
            <form
              onSubmit={handleUpdateBnAction}
              key={benefit._id}
              className="d-flex align-items-center justify-content-between gap-4"
            >
              <div className="w-100">
                <div className="mb-3">
                  <input
                    type="text"
                    onChange={handleChangeBn}
                    defaultValue={benefit?.benefitTitle}
                    name="benefitTitle"
                    className="form-control"
                    required
                    disabled={benefit?._id === activeButton ? false : true}
                  />
                </div>
                <div
                  className={`mb-3 ${
                    benefit?._id === activeButton ? "d-none" : "d-block"
                  }`}
                >
                  <textarea
                    type="text"
                    defaultValue={benefit?.benefitDescription?.replace(
                      regex,
                      ""
                    )}
                    name="benefitTitle"
                    className="form-control"
                    required
                    disabled={benefit?._id === activeButton ? false : true}
                  ></textarea>
                </div>

                <div
                  className={`mb-3 ${
                    benefit?._id === activeButton ? "d-block" : "d-none"
                  }`}
                >
                  <JoditEditor
                    ref={editor}
                    value={benefit?.benefitDescription}
                    onChange={(newContent) => {
                      setContentBn(newContent);
                    }}
                  />
                </div>
              </div>
              <div className="">
                <div className="d-flex aling-align-items-center justify-content-center">
                  <span
                    onClick={() => handleItemBnClick(benefit)}
                    className={`btn btn-secondary px-4 ${
                      benefit?._id === activeButton ? "d-none" : "d-block"
                    }`}
                  >
                    Edit
                  </span>

                  <button
                    type="submit"
                    className={`btn btn-primary px-4 ${
                      benefit?._id === activeButton ? "d-block" : "d-none"
                    }`}
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DashboardCategoryBenefitsEditModal;
