import JoditEditor from "jodit-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FiEye } from "react-icons/fi";
import { IoMdAddCircle } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../../../components/Loading/Loading";

const jobTypes = [
  {
    id: 1,
    typeName: "Intership",
  },
  {
    id: 2,
    typeName: "Part Time",
  },
  {
    id: 3,
    typeName: "Contractual",
  },
  {
    id: 4,
    typeName: "Full Time",
  },
];

function DashboardPostAJob() {
  const [departmentShow, setDepartmentShow] = useState(false);
  const [showDepartment, setShowDepartment] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [positionName, setPositionName] = useState("");
  const [jobType, setJobType] = useState("");
  const [address, setAddress] = useState("");
  const navigate = useNavigate();

  // const config = useMemo({
  //   readonly: false, // all options from https://xdsoft.net/jodit/docs/,
  // });

  const handleDepartmentClose = () => setDepartmentShow(false);
  const handleDepartmentShow = () => setDepartmentShow(true);

  useEffect(() => {
    getDepartmentList();
  }, []);

  //   post job
  const handleAddJob = (e) => {
    e.preventDefault();

    const newJob = {
      departmentId: departmentId,
      description: content,
      address: address,
      jobType: jobType,
      positionName: positionName,
    };

    console.log(newJob);

    fetch(`${process.env.REACT_APP_host}/api/v1/job`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(newJob),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          console.log(data);
          toast.success("Job added successfully");
          navigate("/dashboard/jobs/all-job");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Not added Job!!!!");
      });
  };

  //   post a department
  const handleAddDepartment = (e) => {
    e.preventDefault();
    handleDepartmentClose();
    const form = e.target;
    const title = form.title.value;

    const addDepartment = {
      title,
    };

    console.log(addDepartment);

    fetch(`${process.env.REACT_APP_host}/api/v1/department`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addDepartment),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          console.log(data);
          getDepartmentList();
          toast.success("Department added successfully");
          form.reset();
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Not added department!!!!");
      });
  };

  function getDepartmentList() {
    const url = `${process.env.REACT_APP_host}/api/v1/department`;
    fetch(url, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          setShowDepartment(data?.data);
          setIsLoading(false);
        }
      });
  }

  if (isLoading) {
    return (
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="home-content">
        <div class="d-flex justify-content-end gap-2 align-items-center">
          <button onClick={handleDepartmentShow} className="btn btn-sm btn-info d-flex align-items-center gap-1 fw-semibold text-white">
            <IoMdAddCircle className="add-icon" /> <span>Add Department</span>
          </button>

          <Link to="/dashboard/jobs/all-job" className="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white">
            <FiEye />
            <span>View Details </span>
          </Link>
        </div>
        <div className="shadow rounded main-contents">
          <h1 className="dashboard-title text-center">Add Job </h1>
          <form onSubmit={handleAddJob}>
            <div className="mb-3">
              <label className="form-label  fw-bold">Department Name</label>
              <select name="departmentId" class="form-select" onChange={(e) => setDepartmentId(e.target.value)}>
                <option value="">Choses a department</option>
                {showDepartment?.map((departments) => (
                  <option value={departments?._id} key={departments._id}>
                    {departments?.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label  fw-bold">Position Name</label>
              <input type="text" value={positionName} onChange={(e) => setPositionName(e.target.value)} name="positionName" className="form-control" required />
            </div>

            <div className="mb-3">
              <label className="form-label  fw-bold">Job Type</label>
              <select value={jobType} onChange={(e) => setJobType(e.target.value)} name="jobType" class="form-select">
                <option value="">Choses a job type</option>

                {jobTypes?.map((jobType) => (
                  <option key={jobType.id}>{jobType?.typeName}</option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label className="form-label  fw-bold">Address</label>
              <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} name="address" className="form-control" required />
            </div>

            <div className="mb-3">
              <label className="form-label fw-bold">Description</label>

              <JoditEditor
                ref={editor}
                value={content}
                // config={config}
                // tabIndex={1} // tabIndex of textarea
                onChange={(newContent) => {
                  setContent(newContent);
                }}
              />
            </div>

            <button type="submit" className="btn btn-primary px-4">
              Save
            </button>
          </form>
        </div>
      </div>

      {/* add department modal */}

      <Modal
        show={departmentShow}
        onHide={handleDepartmentClose}
        scrollable={true}
        //   size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="book-title ps-2" id="contained-modal-title-vcenter">
            Add Department
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 form-body">
          <form onSubmit={handleAddDepartment}>
            <div className="mb-3">
              <label className="form-label">Department</label>
              <input type="text" name="title" className="form-control" required />
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DashboardPostAJob;
