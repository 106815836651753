import React, { useState } from 'react';
import BannerImg from '../../assets/easylife/Accidental/WHO-CAN-TAKE-THIS-INSURANCE-PLAN.jpg';
import { FaCheckCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import i18n from 'i18next';
import { Button } from 'react-bootstrap';

function AccidentalPlanCare() {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);

    const FeatureItems = [
        {
            id: 1,
            title: `${t('Why Guardian Accident Care?')}`,
            desc: `${t('Why Guardian Accident Care Desc')}`,
            img: BannerImg,
        },
    ];

    return (
        <div className="service-container pt-0">
            <div className="body-container secondary-container">
                <div>
                    <div className="banner-content flex-column flex-lg-row">
                        <div className="right-img">
                            <img
                                src={BannerImg}
                                alt=""
                                // style={{ background: 'rgb(243, 243, 243)' }}
                            />
                        </div>

                        <div className="left-content">
                            <h1 class="title banner-title text-start">
                                WHO CAN TAKE THIS INSURANCE PLAN?
                            </h1>
                            <p className="text banner-text">
                                <span>
                                    Guardian Accident Care is eligible for any
                                    Bangladeshi Nationality between the ages of
                                    18 and 60 with a valid NID.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccidentalPlanCare;
