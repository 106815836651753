import JoditEditor from 'jodit-react';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FiEye } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { getImageUrl } from '../../../hooks/imageUpload';
import axios from 'axios';

function DashboardDigitalLoyaltyOffer() {
    const navigate = useNavigate();
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [category, setCategory] = useState('');
    const [addDigitalOffer, setAddDigitalOffer] = useState({
        name: '',
        discount: '',
        offer: '',
        validity: '',
        eligib: '',
        contactNuber: '',
        website: '',
        facebookLink: '',
        instagramLink: '',
        youtubeLink: '',
        image: '',
        expiresDate: '',
    });

    const handleChange = (e) => {
        setAddDigitalOffer({
            ...addDigitalOffer,
            [e.target.name]: e.target.value,
        });
    };

    const handleImage = (e) => {
        setAddDigitalOffer({ ...addDigitalOffer, image: e.target.files[0] });
    };

    useEffect(() => {
        getCategoryList();
    }, [categoryList]);

    function getCategoryList() {
        const url = `${process.env.REACT_APP_host}/api/v1/loyalty-category`;
        fetch(url, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => {
                setCategoryList(data?.data?.loyaltyCategoryList);
            });
    }

    // post
    const handleAddOffer = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('category', category);
        formData.append('name', addDigitalOffer?.name);
        formData.append('discount', addDigitalOffer?.discount);
        formData.append('offer', addDigitalOffer?.offer);
        formData.append('validity', addDigitalOffer?.validity);
        formData.append('offerDetails', content);
        formData.append('eligib', addDigitalOffer?.eligib);
        formData.append('contactNuber', addDigitalOffer?.contactNuber);
        formData.append('website', addDigitalOffer?.website);
        formData.append('facebookLink', addDigitalOffer?.facebookLink);
        formData.append('youtubeLink', addDigitalOffer?.youtubeLink);
        formData.append('instagramLink', addDigitalOffer?.instagramLink);
        formData.append('expiresDate', addDigitalOffer?.expiresDate);
        formData.append('image', addDigitalOffer?.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for (const value of formData.values()) {
            console.log(value);
        }

        axios
            .post(
                `${process.env.REACT_APP_host}/api/v1/digital-loyalty-offer`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    toast.success('Digital Offer added successfully');
                    navigate('/dashboard/digital-offer/all-loyalty');
                }
            })
            .catch((error) => {
                console.log(error);
                toast.error('Not added successfully');
            });
    };
    return (
        <div className="home-content">
            <div class="d-flex justify-content-end align-items-center">
                <Link
                    to="/dashboard/digital-offer/all-loyalty"
                    class="btn btn-sm btn-primary d-flex align-items-center gap-1 fw-semibold text-white"
                >
                    <FiEye />
                    <span>View Offer</span>
                </Link>
            </div>
            <div className="shadow rounded main-contents">
                <h1 className="dashboard-title text-center">
                    Add Digital Loyalty Offer
                </h1>
                <form onSubmit={handleAddOffer}>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">
                            Choose Category
                        </label>

                        <select
                            name="category"
                            className="form-select"
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            <option value="">Choose a Category</option>
                            {categoryList?.map((category) => (
                                <option
                                    key={category?.category}
                                    value={category?.category}
                                >
                                    {category?.category}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Name</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addDigitalOffer.name}
                            name="name"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label  fw-bold">Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            name="image"
                            onChange={handleImage}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">Discount</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addDigitalOffer.discount}
                            name="discount"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold"> Offer</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addDigitalOffer.offer}
                            name="offer"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">Validity</label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addDigitalOffer.validity}
                            name="validity"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Eligibility
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addDigitalOffer.eligib}
                            name="eligib"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Contact Number
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addDigitalOffer.contactNuber}
                            name="contactNuber"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            {' '}
                            Website Link
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addDigitalOffer.website}
                            name="website"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            {' '}
                            Facebook Link
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addDigitalOffer.facebookLink}
                            name="facebookLink"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            {' '}
                            Instagram Link
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addDigitalOffer.instagramLink}
                            name="instagramLink"
                            className="form-control"
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            {' '}
                            Youtube Link
                        </label>
                        <input
                            type="text"
                            onChange={handleChange}
                            value={addDigitalOffer.youtubeLink}
                            name="youtubeLink"
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            {' '}
                            Offer Description
                        </label>

                        <JoditEditor
                            ref={editor}
                            value={content}
                            // config={config}
                            // tabIndex={1} // tabIndex of textarea
                            onChange={(newContent) => {
                                setContent(newContent);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">
                            Expires Date
                        </label>
                        <input
                            type="date"
                            name="expiresDate"
                            onChange={handleChange}
                            value={addDigitalOffer.expiresDate}
                            className="form-control"
                        />
                    </div>

                    <button type="submit" className="btn btn-primary px-4">
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default DashboardDigitalLoyaltyOffer;
