import React from 'react';
import "./Award.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import awardImg1 from "../../../assets/award/1.jpg";
import awardImg2 from "../../../assets/award/2.jpg";
import awardImg3 from "../../../assets/award/3.jpg";
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";


const partnerSliders = [
    { id: 1, img: awardImg1 },
    { id: 2, img: awardImg2 },
    { id: 3, img: awardImg3 },
    // { id: 4, img: awardImg1 },
    // { id: 5, img: awardImg2 },
    // { id: 6, img: awardImg3 },
    // { id: 7, img: awardImg1 },
    // { id: 8, img: awardImg2 },
];

const Award = () => {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem("lang");
        i18n.changeLanguage(currentLang);
    }, []);
    return (
        <div className='award-container'>
            <div className="secondary-container body-container partnet-content py-0">
                <h1 className="title banner-title text-center">{t("easylifeAboutUsAwardTitle")}</h1>
                <div className="partnet-slider ">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        // autoplay={{ delay: 2000 }}
                        breakpoints={{
                            "@0.00": {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            "@0.75": {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            "@1.00": {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },
                            "@1.50": {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                        modules={[Navigation, Pagination, Autoplay]}
                        className="mySwiper awardSwiper"
                    >
                        {partnerSliders?.map((partnerSlider) => (
                            <SwiperSlide key={partnerSlider.id} className="slider-partner single-award py-2">
                                <div>
                                    <img src={partnerSlider.img} alt="" />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Award;