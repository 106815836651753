import React from "react";

function BannerCard({ bannersCardItem }) {
  return (
    <div className="cards">
      <div>
        <img src={bannersCardItem.img} alt="" />
      </div>
      <div className="card-info">
        <h4 className="">{bannersCardItem.title}</h4>
        <p>{bannersCardItem.info}</p>
      </div>
    </div>
  );
}

export default BannerCard;
