import React from "react";
import "./locateBranch.css";
import LocateBranchBanner from "./LocateBranchBanner";
import LocateBranchTable from "./LocateBranchTable";
import useTitle from "../../hooks/useTitle";

function LocateBranch() {
  useTitle("Locate Branch List");

  return (
    <>
      <LocateBranchBanner />
      <LocateBranchTable />
    </>
  );
}

export default LocateBranch;
