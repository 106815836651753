import React from 'react';
import './taxRebate.css';
import img1 from '../../assets/taxrebate/why_guardian_2.png';
import img2 from '../../assets/taxrebate/why_guardian_3.png';
import img3 from '../../assets/taxrebate/why_guardian_4.png';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useEffect } from 'react';

function TaxRebateWhyGuardian() {
    const { t } = useTranslation();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);

    return (
        <div className="my-5">
            <div className="body-container secondary-container">
                <h1 className="title title-service text-start">
                    {t(
                        "Why investing in Guardian Life's insurance policies is profitable?"
                    )}
                </h1>
                <ul className="tax-guardian-details mt-4">
                    <li>
                        <span className="fw-bold">{t('1')}.</span>{' '}
                        {t('More than 11 million lives protected')}
                    </li>
                    <li>
                        <span className="fw-bold">{t('2')}.</span>{' '}
                        {t('1300+ Crores paid in claims')}
                    </li>
                    <li>
                        <span className="fw-bold">{t('3')}.</span>{' '}
                        {t('98% claim payment ratio')}
                    </li>
                    <li>
                        <span className="fw-bold">{t('4')}.</span>{' '}
                        {t(
                            'Claim settlement within 3-5 days of the claim submission'
                        )}
                    </li>
                    <li>
                        <span className="fw-bold">{t('5')}.</span>{' '}
                        {t('Online claim submission through ACPS platform')}
                    </li>
                </ul>

                <div className="mt-5">
                    <h1 className="title title-service text-center">
                        {t('Achievements of Guardian Life')}
                    </h1>
                    <div className="tax-award-grid">
                        <div className="tax-award-img">
                            <img
                                className="w-100 h-100 object-fit-cover"
                                src={img1}
                            />
                        </div>
                        <div className="tax-award-img">
                            <img
                                className="w-100 h-100 object-fit-cover"
                                src={img2}
                            />
                        </div>
                        <div className="tax-award-img">
                            <img
                                className="w-100 h-100 object-fit-cover"
                                src={img3}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TaxRebateWhyGuardian;
