import React, { useContext, useEffect } from "react";
import { AiOutlineHome, AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { useForm } from "react-hook-form";
import Buttons from "../../components/Buttons/Buttons";
import contactUsImg from "../../assets/image/contact-us.jpg";
import { BookContext } from "../../context/BookingContext/BookingContext";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const CorporateBookNow = () => {
  const { handleAddMessage, handleChangeNumber, errorMessage, inputValue } =
    useContext(BookContext);

  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  return (
    <div className="service-container pt-0">
      <div className="secondary-container body-container contact-container py-0">
        <h1 className="title title-service corporate-book-now-title">
          {t("contactTitle")}
        </h1>
        <div className="contact-content-from flex-column flex-md-row">
          <div className="contact-left d-flex align-items-center">
            {/* <h1 className="title title-service text-start">
                        If you have any queries, feel free to contact us
                        </h1> */}
            <div className="corporate-book-now-img">
              <img src={contactUsImg} alt="contact" />
            </div>
          </div>

          {/* <div className="vertical-line"></div> */}

          <div className="contact-right">
            <h5>{t("form")}</h5>

            <form className="mt-5" onSubmit={handleAddMessage}>
              <div className="d-flex align-items-center gap-2">
                <input
                  className="form-control form-contact mb-4 w-100 py-2"
                  placeholder={`${t("name")}`}
                  name="name"
                  type="text"
                  required
                />
                <input
                  type="email"
                  className="form-control form-contact mb-4 w-100 py-2"
                  placeholder={`${t("email")}`}
                  name="email"
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  className="form-control form-contact number-arrow py-2"
                  placeholder={`${t("contactNumber")}`}
                  name="contactNumber"
                  value={inputValue}
                  onChange={handleChangeNumber}
                  maxLength={11}
                  required
                />
                {errorMessage && (
                  <span style={{ color: "red" }}>{errorMessage}</span>
                )}
              </div>
              <textarea
                className="form-control form-contact contact-area mb-4 "
                placeholder={`${t("message")}`}
                name="message"
                required
              ></textarea>

              <div>
                <Buttons classes="btns send-btn w-100">{t("send")}</Buttons>
                {/* <input cl type="submit" /> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateBookNow;
