import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { getImageUrl } from '../../../hooks/imageUpload';
import axios from 'axios';

const BoardOfDirectorEditModal = ({
    editModal,
    handleEditClose,
    updateDirector,
    setUpdateDirector,
    getPaginationList,
}) => {
    const handleEditChange = (e) => {
        setUpdateDirector({
            ...updateDirector,
            [e.target.name]: e.target.value,
        });
    };

    const handleEditImage = (e) => {
        setUpdateDirector({ ...updateDirector, image: e.target.files[0] });
    };

    const handleUpdateAction = async (e) => {
        e.preventDefault();
        handleEditClose();

        const formData = new FormData();
        formData.append('name', updateDirector.name);
        formData.append('nameBn', updateDirector.nameBn);
        formData.append('designation', updateDirector.designation);
        formData.append('designationBn', updateDirector.designationBn);
        formData.append('image', updateDirector.image);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        await axios
            .patch(
                `${process.env.REACT_APP_host}/api/v1/board-of-director/${updateDirector?._id}`,
                formData,
                config
            )
            .then((res) => {
                if (res.status) {
                    getPaginationList();
                    toast.success('Director updated successfully');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    return (
        <Modal
            show={editModal}
            onHide={handleEditClose}
            scrollable={true}
            //   size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="book-title ps-2"
                    id="contained-modal-title-vcenter"
                >
                    Edit Director
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 form-body">
                <form onSubmit={handleUpdateAction} className="row">
                    <div className="mb-3 col-12">
                        <label className="form-label">Name</label>
                        <input
                            type="text"
                            name="name"
                            onChange={handleEditChange}
                            defaultValue={updateDirector?.name}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Name(Bangla)</label>
                        <input
                            type="text"
                            name="nameBn"
                            onChange={handleEditChange}
                            defaultValue={updateDirector?.nameBn}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">Designation</label>
                        <input
                            type="text"
                            name="designation"
                            onChange={handleEditChange}
                            defaultValue={updateDirector?.designation}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-3 col-12">
                        <label className="form-label">
                            Designation(Bangla)
                        </label>
                        <input
                            type="text"
                            name="designationBn"
                            onChange={handleEditChange}
                            defaultValue={updateDirector?.designationBn}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="mb-5 col-12">
                        <label className="form-label">Image</label>
                        <input
                            type="file"
                            onChange={handleEditImage}
                            name="image"
                            className="form-control"
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default BoardOfDirectorEditModal;
