import React from 'react';
import { toast } from 'react-hot-toast';
import { BiEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';

const DashboardVideoViewTable = ({ newVideo, handleEditShow, handleViewShow, setUpdateVideo, getPaginationList, idx, setViewVideo }) => {
    const { _id, vedioTitle, videoLink } = newVideo;

    const handleDeleteAction = (id) => {
        const agree = window.confirm(`Are you sure, you want to delete: "${vedioTitle}?"`);
        if (agree) {
            fetch(`${process.env.REACT_APP_host}/api/v1/media-video/${id}`, {
                method: "DELETE",
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.status) {
                        getPaginationList();
                        toast.success(`"${vedioTitle}" is Deleted !`);
                    }
                });
        }
    };
    return (
        <>
            <tr>
                <td className="text-center align-middle table-text">{idx + 1}</td>
                <td className="text-center align-middle table-text">{vedioTitle}</td>
                <td className="text-center align-middle table-text">{videoLink}</td>
                <td className="text-center align-middle table-text">
                    <div className="d-flex align-items-center justify-content-center gap-2">
                        <button
                            onClick={() => {
                                handleEditShow();
                                setUpdateVideo(newVideo);
                            }}
                            className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center gap-1"
                        >
                            <BiEdit /> <span>Edit</span>
                        </button>
                        <button
                            onClick={() => handleDeleteAction(_id)}
                            className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center gap-1"
                        >
                            <MdDelete /> <span>Delete</span>
                        </button>
                    </div>
                </td>
            </tr>
        </>
    );
};

export default DashboardVideoViewTable;