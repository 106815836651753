import React from "react";

const HealthPlanTable = ({ data, banglatranslate }) => {
  return (
    <div className="d-flex justify-content-center adc-partner-channel-table">
      {banglatranslate === "bn" ? (
        <span
          dangerouslySetInnerHTML={{
            __html:
              data?.healthDescBn
          }}
        ></span>
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html:
              data?.healthDesc
          }}
        ></span>
      )}
    </div>
  );
};

export default HealthPlanTable;
