import React, { useEffect, useState } from "react";
import BgBannerImg from "../../../assets/BG/bgLeft.png";
import BannerImg from "../../../assets/image/claim-banner.jpg";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Buttons from "../../../components/Buttons/Buttons";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const BgStyle = {
  backgroundImage: `url(${BgBannerImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
};

const ClaimOverviewBanner = () => {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const bannerItems = [
    {
      id: 1,
      title: `${t("claimBannerTitle")}`,
      desc: `${t("claimBannerDetails")}`,
      img: BannerImg,
    },
  ];

  const [isShowMore, setShowMore] = useState(false);
  const handleShow = () => {
    setShowMore(!isShowMore);
  };
  return (
    <div style={BgStyle}>
      <div className="body-container secondary-container banner-container">
        {bannerItems.map((bannerItem) => (
          <div key={bannerItem.id} className="banner-content">
            <div className="left-content">
              <h1 className="title banner-title">{bannerItem?.title}</h1>
              <div className="text banner-text">
                {!isShowMore ? (
                  bannerItem?.desc.split("/")[0] + "..."
                ) : (
                  <div className="d-flex flex-column gap-3">
                    {bannerItem?.desc.split("/").map((dtls, i) => (
                      <p key={i}>{dtls}</p>
                    ))}
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center gap-3 mt-4">
                <Button
                  className="btn-book btns online-claim-btn"
                  onClick={handleShow}
                >
                  {isShowMore ? `${t("readless")}` : `${t("readmore")}`}
                </Button>
                {/* <Link to="http://ocs.myguardianbd.com/" target="_blank">
                  <Buttons classes="btn-learn online-claim-btn">
                    {t("onlineClaim")}
                  </Buttons>
                </Link> */}
              </div>
            </div>
            <div className="right-img">
              <img src={bannerItem?.img} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClaimOverviewBanner;
