import React from 'react'

function SinglePositionBanner({ data }) {
    return (
        <div className='career-banner'>
            <div className='career-banner-content'>
                <h1 className='text-capitalize'>{data?.positionName}</h1>
                <p className='position-type fw-light fs-6'>
                    <span>{data?.address}</span>
                    <span>|</span>
                    <span>{data?.jobType}</span>
                </p>
            </div>
        </div>
    )
}

export default SinglePositionBanner