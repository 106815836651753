import React from "react";
import "./bannersCard.css";
import { Link } from "react-router-dom";
import Buttons from "../Buttons/Buttons";
import Button from "react-bootstrap/Button";
import CheckItems from "../CheckItems/CheckItems";

function BannersCard({
  bannerItem,
  classes,
  btnName,
  handleShow,
  show,
  handleClose,
}) {
  const { textDesc } = bannerItem;
  return (
    <>
      <div className="left-content">
        <h1 className="title banner-title">{bannerItem?.title}</h1>
        <p className="text banner-text">{bannerItem?.desc}</p>
        {textDesc && <CheckItems textDesc={textDesc} />}

        {classes && btnName && (
          <div className="card-button">
            <Link to="/sub-catagory">
              <Buttons classes={classes}>{btnName}</Buttons>
            </Link>
          </div>
        )}
      </div>
      <div className="right-img">
        <img src={bannerItem?.img} alt="" />
      </div>
    </>
  );
}

export default BannersCard;
