import React from 'react';
import ourVisionImg from "../../../assets/easylife/easylife-vission.jpg";
import BannersCard from '../../../components/BannersCard/BannersCard';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";


const OurVision = () => {
  const { t } = useTranslation();

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem("lang");
    i18n.changeLanguage(currentLang);
  }, []);

  const visionDetails = [
    {
      id: 1,
      title: `${t("easylifeAboutUsVisionTitle")}`,
      desc: `${t("easylifeAboutUsVisionDetails")}`,
      img: ourVisionImg,
    },
  ];

  return (
    <div className="body-container secondary-container aboutEsayLife-container">
      <div className="plans-container">
        {visionDetails.map((bannerItem, i) => (
          <div key={i} className="plans-section">
            {i % 2 === 0 && (
              <div className="plans-banner-contents right-plans-content flex-column-reverse flex-lg-row">
                <BannersCard bannerItem={bannerItem} key={bannerItem.id} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurVision;