import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { BiEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import './dashboardDigitalLoyalty.css';

function DashboardDigitalLoyaltyList({
    idx,
    newOffer,
    handleEditShow,
    setUpdateOffer,
    getPaginationList,
}) {
    const { _id, img, name, offer, loyaltyStatus: status, category } = newOffer;

    const [isSwitchOn, setIsSwitchOn] = useState(status);

    const handleChange = (e) => {
        if (e.target.checked === true) {
            setIsSwitchOn(true);
        } else {
            setIsSwitchOn(false);
        }
    };

    // update status
    const handleUpdateActive = (id) => {
        const agree = window.confirm(
            `Are you sure, you want to status${
                status ? ' inactive' : ' active'
            }`
        );

        if (agree) {
            const updateStatus = {
                loyaltyStatus: !isSwitchOn,
            };

            fetch(
                `${process.env.REACT_APP_host}/api/v1/digital-loyalty-offer/${id}`,
                {
                    method: 'PATCH',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify(updateStatus),
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    if (data.status) {
                        getPaginationList();
                        toast.success(`Status update success`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(`Not update status`);
                });
        } else {
            setIsSwitchOn(status);
        }
    };

    const handleDeleteAction = (id) => {
        const agree = window.confirm(
            `Are you sure, you want to delete: "${name}?"`
        );
        if (agree) {
            fetch(
                `${process.env.REACT_APP_host}/api/v1/digital-loyalty-offer/${id}`,
                {
                    method: 'DELETE',
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    if (data.status) {
                        getPaginationList();
                        toast.success(`"${name}" is Deleted !`);
                    }
                });
        }
    };
    return (
        <tr>
            <td className="text-center align-middle table-text">{idx + 1}</td>
            <td className="text-center align-middle table-text">
                <div className="d-flex justify-content-center align-items-center">
                    <div className="offer-img">
                        <img src={img} alt="offer" />
                    </div>
                </div>
            </td>
            <td className="text-center align-middle table-text">{name}</td>
            <td className="text-center align-middle table-text">{category}</td>
            <td className="text-center align-middle table-text">
                <div className="form-check form-switch d-flex justify-content-center align-items-center gap-2 ">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        onChange={(e) => {
                            handleChange(e);
                            handleUpdateActive(_id);
                        }}
                        checked={isSwitchOn}
                    />
                    <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                    >
                        {isSwitchOn === true ? (
                            <span className="text-success fw-bold">
                                {' '}
                                Active
                            </span>
                        ) : (
                            <span className="text-danger fw-bold">
                                Inactive
                            </span>
                        )}
                    </label>
                </div>
            </td>
            <td className="text-center align-middle table-text">
                {offer.length > 30 ? offer.slice(0, 29) + '...' : offer}
            </td>
            <td className="text-center align-middle table-text">
                <div className="d-flex align-items-center justify-content-center gap-2">
                    <button
                        onClick={() => {
                            handleEditShow();
                            setUpdateOffer(newOffer);
                        }}
                        className="btn btn-sm btn-secondary table-btn fw-semibold d-flex align-items-center gap-1"
                    >
                        <BiEdit /> <span>Edit</span>
                    </button>
                    <button
                        onClick={() => handleDeleteAction(_id)}
                        className="btn btn-sm  btn-danger table-btn fw-semibold d-flex align-items-center gap-1"
                    >
                        <MdDelete /> <span>Delete</span>
                    </button>
                </div>
            </td>
        </tr>
    );
}

export default DashboardDigitalLoyaltyList;
