import React from 'react';
import './EasyLifeFooter.css';
import easyLifeLogo from '../../../../assets/logo/easylife-logo-svg.png';
import guardianLogo from '../../../../assets/logo/logo-1.png';
import PaymentLogo from '../../../../assets/image/payment.png';
import Apex from '../../../../assets/image/sponsor1.png';
import Brac from '../../../../assets/image/sponsor2.png';
import Square from '../../../../assets/image/sponsor3.png';
import EasyLife from '../../../../assets/image/easyLife.png';
import Guardian from '../../../../assets/image/btn-myguirdian.png';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const EasyLifeFooter = () => {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        //alert('Page is loaded successfully');
        //get the current language stored in the local storage
        let currentLang = localStorage.getItem('lang');
        i18n.changeLanguage(currentLang);
    }, []);
    return (
        <footer className="footer-container">
            <div className="secondary-container body-container">
                <div className="footer-grid ">
                    <div className="footer-left-logo">
                        <div>
                            <img src={easyLifeLogo} alt="" />
                        </div>
                        <p className="text">{t('easyLifeFooterText')}</p>
                        <div>
                            <img className="w-100" src={PaymentLogo} alt="" />
                        </div>
                    </div>
                    <div className="footer-middle">
                        <div className="footer-middle-text">
                            <h5 className="footer-items-title">
                                {t('footerLink')}
                            </h5>
                            <ul className="footer-item">
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="/easylife/home"
                                    >
                                        {t('home')}
                                    </Link>
                                </li>

                                {/* <li className="footer-list">
                  <Link
                    className="footer-link"
                    to="/easylife/products"
                  >
                    {t("products")}
                  </Link>
                </li> */}
                                <li className="footer-list">
                                    <Link
                                        target=""
                                        className="footer-link"
                                        to="/digital-loyalty-offer"
                                    >
                                        {t('digitalOffers')}
                                    </Link>
                                </li>
                                <li className="footer-list">
                                    <Link
                                        target="_blank"
                                        className="footer-link"
                                        to="https://acps.myguardianbd.com/easy_life/client_login/"
                                    >
                                        {t('claims')}
                                    </Link>
                                </li>
                                <li className="footer-list">
                                    <Link
                                        target="_blank"
                                        className="footer-link"
                                        to="https://saleslead.myguardianbd.com/tax-certificate/EASYLIFE"
                                    >
                                        {t('taxCertificate')}
                                    </Link>
                                </li>
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="/easylife/privacy-policy"
                                    >
                                        {t('privacy')}
                                    </Link>
                                </li>
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="/easylife/bmi-calculator"
                                    >
                                        {t('BMICalculator')}
                                    </Link>
                                </li>
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="http://easylife.myguardianbd.com/en/sign-in"
                                    >
                                        {t('login')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-middle-second-text">
                            <ul className="footer-item second-gird-quick">
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="/easylife/products/term-life-insurance"
                                    >
                                        {t('easylifeAboutUsServiceOffermenu1')}
                                    </Link>
                                </li>
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="/easylife/products/savings-plan"
                                    >
                                        {t('easylifeAboutUsServiceOffermenu2')}
                                    </Link>
                                </li>
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="/easylife/products/women's-savings-plan"
                                    >
                                        {t('easylifeAboutUsServiceOffermenu3')}
                                    </Link>
                                </li>
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="/easylife/easy-life-cancer-care-plan"
                                    >
                                        {t('easylifeAboutUsServiceOffermenu4')}
                                    </Link>
                                </li>
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="/easylife/guardian-accident-care"
                                    >
                                        {t('easylifeAboutUsServiceOffermenu6')}
                                    </Link>
                                </li>
                                {/* <li className="footer-list">
                  <Link
                    className="footer-link"
                    to="/easylife/adc"
                  >
                    {t("ADC")}
                  </Link>
                </li> */}
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="/easylife/faq"
                                    >
                                        FAQs
                                    </Link>
                                </li>
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="/easylife/contact"
                                    >
                                        {t('easyContact')}
                                    </Link>
                                </li>
                                <li className="footer-list">
                                    <Link
                                        className="footer-link"
                                        to="http://easylife.myguardianbd.com/en/sign-in"
                                    >
                                        {t('Signup')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className=" footer-right-logo">
                        <h5 className="footer-items-title">{t('sponsor')}</h5>
                        <div className="d-flex aling-items-center gap-4">
                            <div>
                                <img
                                    className="sponsor-logo"
                                    src={Apex}
                                    alt=""
                                />
                            </div>
                            <div>
                                <img
                                    className="sponsor-logo"
                                    src={Brac}
                                    alt=""
                                />
                            </div>
                            <div>
                                <img
                                    className="sponsor-logo"
                                    src={Square}
                                    alt=""
                                />
                            </div>
                        </div>

                        <h5 className="footer-items-title app-download">
                            {t('app')}
                        </h5>
                        <div className="d-flex aling-items-center gap-4">
                            <div className="app-img">
                                <Link
                                    to="https://play.google.com/store/apps/details?id=com.sslwireless.easylife"
                                    target="_blank"
                                >
                                    <img
                                        className="sponsor-logo"
                                        src={EasyLife}
                                        alt=""
                                    />
                                </Link>
                            </div>
                            <div className="app-img">
                                <Link
                                    to="https://play.google.com/store/apps/details?id=com.guardianlifeinsurance.myguardian"
                                    target="_blank"
                                >
                                    <img
                                        className="sponsor-logo"
                                        src={Guardian}
                                        alt=""
                                    />
                                </Link>
                            </div>
                        </div>

                        <div className="app-download d-flex align-items-baseline gap-3">
                            <h5 className="footer-items-title">
                                {t('powerBy')}
                            </h5>
                            <div className="powered-by-logo">
                                <img src={guardianLogo} alt="logo" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-line">
                    <p className="copy-text">
                        <span> © {t('easyCopy')}</span>{' '}
                        <span>{currentYear}</span> <span>{t('easyCopy2')}</span>
                        <Link
                            to="https://nextgenitltd.com/"
                            target="_blank"
                            className="text-white ms-1"
                        >
                            {t('development')}
                        </Link>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default EasyLifeFooter;
