import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";

function DashboardLoyaltyCategoryCategoryEdit({ editModal, handleEditClose, updateCategory, setUpdateCategory, getPaginationList }) {
  const handleChange = (e) => {
    setUpdateCategory({ ...updateCategory, [e.target.name]: e.target.value });
  };

  const handleUpdateAction = async (e) => {
    e.preventDefault();
    handleEditClose();

    const updatedCategory = {
      category: updateCategory.category,
      categoryBn: updateCategory.categoryBn,
    };
    fetch(`${process.env.REACT_APP_host}/api/v1/loyalty-category/${updateCategory?._id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedCategory),
    })
      .then((res) => {
        if (res.status) {
          console.log(res);
          getPaginationList();
          toast.success("Digital Category updated successfully!");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Not updated!");
      });
  };

  return (
    <Modal
      show={editModal}
      onHide={handleEditClose}
      scrollable={true}
      //   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="book-title ps-2" id="contained-modal-title-vcenter">
          Update Digital Loyalty Blog
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 form-body">
        <form onSubmit={handleUpdateAction} className="row">
          <div className="mb-3">
            <label className="form-label">Category</label>
            <input onChange={handleChange} defaultValue={updateCategory?.category} type="text" name="category" className="form-control" required />
          </div>
          <div className="mb-3">
            <label className="form-label">Category (Bangla)</label>
            <input onChange={handleChange} defaultValue={updateCategory?.categoryBn} type="text" name="categoryBn" className="form-control" required />
          </div>
          <button type="submit" className="mt-4 btn btn-primary">
            Submit
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default DashboardLoyaltyCategoryCategoryEdit;
